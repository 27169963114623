// Vendor
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Components
import { MultiTab } from 'src/components/molecules/MultiTab';
import {
  TabBody,
  TabContent,
  TabHeader,
  TabTitle
} from 'src/components/molecules/MultiTab/MultiTab';
import { ListView } from 'src/components/templates';
import InvitationsFilters from './components/InvitationsFilters';
import InvitationsList from './components/InvitationsList';
import InvitationsPagination from './components/InvitationsPagination';
import UsersFilters from './components/UserFilters';
import UsersList from './components/UsersList';
import UsersPagination from './components/UsersPagination';

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';
import useInvitations from 'src/features/invitation/hooks/useInvitations';

// Constants
import { LeasingTeam, SnapptTeam } from 'src/constants/roles';

// Redux
import { selectors } from 'src/features/invitation/invitationSlice';

// Types
import { LocationState } from 'src/ts/interfaces';

type tabsType = { SNAPPT_TEAM?: number; LEASING_TEAM?: number; INVITATIONS?: number };

const UsersPage: React.FC = () => {
  const {
    isSnapptTeam,
    isLeasingTeam,
    isAccountRepresentative,
    isAdmin,
    isAdminOrAccountRepresentative
  } = useRole();

  const showSnapptTeamTabBody = isSnapptTeam && !isAccountRepresentative;
  const showLeasingTeamTabBody = isAdminOrAccountRepresentative || isLeasingTeam;

  const Tabs = useMemo(() => {
    const tabs: tabsType = {
      SNAPPT_TEAM: undefined,
      LEASING_TEAM: undefined,
      INVITATIONS: undefined
    };

    if (showSnapptTeamTabBody) {
      tabs.SNAPPT_TEAM = 0;
      tabs.LEASING_TEAM = 1;
      tabs.INVITATIONS = 2;
    } else if (showLeasingTeamTabBody) {
      tabs.LEASING_TEAM = 0;
      tabs.INVITATIONS = 1;
    }

    return tabs;
  }, [showLeasingTeamTabBody, showSnapptTeamTabBody]);

  const location = useLocation();
  const { onResetCreate: onResetCreateInvitation } = useInvitations();

  const createInvitationIsCreated = useSelector(selectors.create.isCreated);
  const createInvitationError = useSelector(selectors.create.error);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (location.state && (createInvitationError || createInvitationIsCreated)) {
      if ((location.state as LocationState)?.from === 'InvitationFlowPage') {
        // Move to Invitation's tab
        setTabIndex(Tabs.INVITATIONS as number);
        onResetCreateInvitation();
      }
    }
  }, [
    Tabs,
    createInvitationError,
    createInvitationIsCreated,
    location.state,
    onResetCreateInvitation
  ]);

  // Create the filter for Leasing Team
  const getFilterQuery = useCallback(
    (currentTabIndex: number) => {
      if (isAdminOrAccountRepresentative && currentTabIndex === Tabs.SNAPPT_TEAM)
        return `role@>${SnapptTeam.join('|')}`;
      if (isAdminOrAccountRepresentative && currentTabIndex === Tabs.LEASING_TEAM)
        return `role@>${LeasingTeam.join('|')}`;

      return undefined;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [Tabs.LEASING_TEAM, Tabs.SNAPPT_TEAM, isAdminOrAccountRepresentative]
  );
  const [filter, setFilter] = useState(getFilterQuery(tabIndex));

  // Get filters
  const getFilters = useMemo(() => {
    // Users Filter
    if (tabIndex === Tabs.SNAPPT_TEAM) {
      return (
        <UsersFilters
          label={isAdminOrAccountRepresentative ? 'Snappt Team' : 'Users'}
          team={isAdminOrAccountRepresentative ? 'snappt' : undefined}
          filter={filter}
          setUserFilters={setFilter}
        />
      );
    }

    // Leasing Team Filter
    if (tabIndex === Tabs.LEASING_TEAM) {
      return (
        <UsersFilters
          label="Leasing Team"
          team="leasing"
          filter={filter}
          setUserFilters={setFilter}
        />
      );
    }

    // Invitations Filter
    if (tabIndex === Tabs.INVITATIONS) {
      return <InvitationsFilters filter={filter} setInvitationFilters={setFilter} />;
    }

    return null;
  }, [Tabs, tabIndex, isAdminOrAccountRepresentative, filter, setFilter]);

  return (
    <ListView title="Users" filters={getFilters}>
      <MultiTab
        name="userTab"
        selectedIndex={tabIndex}
        hasSpacing
        onSelect={(index) => {
          setTabIndex(index as number);
          setFilter(getFilterQuery(index));
        }}
      >
        <TabHeader aria-label="User Tab">
          {(isLeasingTeam || isAdmin) && <TabTitle>{isAdmin ? 'Snappt Team' : 'Users'}</TabTitle>}
          {isAdminOrAccountRepresentative && <TabTitle>Leasing Team</TabTitle>}
          <TabTitle>Invitations</TabTitle>
        </TabHeader>
        <TabBody>
          {/* Users */}
          {showSnapptTeamTabBody && (
            <TabContent>
              <UsersList filter={filter} />
              <UsersPagination filter={filter} />
            </TabContent>
          )}
          {/* Leasing Team */}
          {showLeasingTeamTabBody && (
            <TabContent>
              <UsersList filter={filter} />
              <UsersPagination filter={filter} />
            </TabContent>
          )}
          {/* Invitations */}
          <TabContent>
            <InvitationsList filter={filter} />
            <InvitationsPagination filter={filter} />
          </TabContent>
        </TabBody>
      </MultiTab>
    </ListView>
  );
};

export default UsersPage;
