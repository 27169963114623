import styled from 'styled-components';

interface IProps {
  height: string;
}
export const PDFViewerContainer = styled.div<IProps>`
  height: ${(props) => props.height} !important;
  width: 100%;
`;

export const Button = styled.button`
  outline: none;
  border: none;
  background: none;
  cursor: pointer;

  div {
    border: none !important;
    outline: none !important;
    margin-top: 0.25rem;
  }
`;

export const ButtonContainer = styled.div`
  border: none !important;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.25rem;
`;
