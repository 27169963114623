import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { ButtonRow, CarouselSlide, CarouselSlides, CarouselWrapper } from './styles';

interface IProps {
  children: JSX.Element[];
  routeOnLastPage: boolean;
  route: string;
  displayLastPageOnLoad: boolean;
  onPreviousClicked: (currentSlide: number) => void;
  onNextClicked: (currentSlide: number) => void;
}

const Carousel = ({
  children,
  routeOnLastPage,
  route,
  displayLastPageOnLoad,
  onPreviousClicked,
  onNextClicked
}: IProps) => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(displayLastPageOnLoad ? children.length - 1 : 0);

  const totalNumberOfSlides = routeOnLastPage ? children.length + 1 : children.length;

  const previousClicked = () => {
    const currSlide = (currentSlide - 1 + activeSlide.length) % activeSlide.length;
    setCurrentSlide(currSlide);

    onPreviousClicked(currSlide);
  };
  const nextClicked = () => {
    const currSlide = (currentSlide + 1) % activeSlide.length;
    if (routeOnLastPage && currentSlide === children.length - 1) {
      navigate(route);
    }

    setCurrentSlide(currSlide);
    onNextClicked(currSlide);
  };

  const previousDocumentDisabled = () => {
    return currentSlide === 0;
  };

  const nextDocumentDisabled = () => {
    return routeOnLastPage
      ? currentSlide === children.length
      : currentSlide === children.length - 1;
  };

  const pageIcons = children.map((child) => {
    if (child.props.currentDocument.index === currentSlide) {
      return (
        <Row.Col key={child.key}>
          <Icon key={child.key} icon="current-step-circle" />
        </Row.Col>
      );
    }
    return (
      <Row.Col key={child.key}>
        <Icon key={child.key} icon="step-circle" />
      </Row.Col>
    );
  });

  const activeSlide = children.map((slide, index) => (
    <CarouselSlide active={currentSlide === index} key={index}>
      {slide}
    </CarouselSlide>
  ));

  return (
    <div>
      <CarouselWrapper>
        <CarouselSlides currentSlide={currentSlide}>{activeSlide}</CarouselSlides>
      </CarouselWrapper>
      <ButtonRow>
        <Row.Col size={3}>
          <Button
            name="previous-page"
            variant={ButtonVariant.outline}
            className="carousel-buttons"
            onClick={previousClicked}
            isDisabled={previousDocumentDisabled()}
          >
            <Icon icon="left-arrow" />
          </Button>
        </Row.Col>
        <Row.Col alignSelf="center" size={6}>
          <Row justify="center" gap={0.15}>
            {pageIcons}
            {routeOnLastPage && (
              <Row.Col>
                <Icon icon="step-circle" />
              </Row.Col>
            )}
          </Row>
          <Row justify="center">
            <Row.Col>
              <Text variant={TextVariant.h4}>
                Step {currentSlide + 1} of {totalNumberOfSlides}
              </Text>
            </Row.Col>
          </Row>
        </Row.Col>
        <Row.Col size={3}>
          <Button
            name="next-page"
            variant={ButtonVariant.outline}
            className="carousel-buttons carousel-right-button"
            onClick={nextClicked}
            isDisabled={nextDocumentDisabled()}
          >
            <Icon icon="right-arrow" />
          </Button>
        </Row.Col>
      </ButtonRow>
    </div>
  );
};

export default Carousel;
