import { AxiosInstance } from 'axios';
import { configVars } from 'src/helpers';
import { ApplicantsApi, IDVerificationApi } from 'src/lib/internal/epa/gen';
import { defaultInstance } from './instance';

const commonParams: [undefined, string, AxiosInstance] = [
  undefined,
  configVars.epa_url,
  defaultInstance
];

const idvApi = new IDVerificationApi(...commonParams);
const applicantsApi = new ApplicantsApi(...commonParams);

export { applicantsApi, idvApi };
