export const propertyAgentsColumns = [
  { name: 'name', label: 'Name' },
  { name: 'email', label: 'Email' },
  { name: 'role', label: 'Role' },
  { name: 'delete_icon', label: '' }
];

export const unassignedAgentsColumns = [
  { name: 'checkbox', label: 'checkbox' },
  { name: 'name', label: 'Name' },
  { name: 'email', label: 'Email' },
  { name: 'properties', label: 'Properties' },
  { name: 'role', label: 'Role' }
];
