// Vendor
import { useState } from 'react';
import { useParams } from 'react-router-dom';

// Components
import Button, { ButtonVariant } from 'src/components/atoms/Button';
import ButtonLink, { ButtonLinkTarget, ButtonLinkVariant } from 'src/components/atoms/ButtonLink';
import Gap from 'src/components/atoms/Gap';
import { Line } from 'src/components/atoms/Line';
import { Loader } from 'src/components/atoms/Loader';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { ShowState } from 'src/components/molecules/ShowState';
import Modal, { ModalVariant } from 'src/components/organisms/Modal';
import PotentialMatchBox from 'src/components/organisms/PotentialMatchBox';
import { DetailView } from 'src/components/templates/DetailView';
import { LaunchIcon, StyledTextDescription } from './styles';

// Hooks
import { useMergeEntry } from 'src/features/entries/hooks';
import { useGetEntryAndMatchingEntryById } from 'src/features/matchingEntries/hooks';
import { useEntryAssignedToAnother } from 'src/features/myWork/hooks/useEntryAssignedToAnother';
import { useQueryParams } from 'src/hooks';

const PotentialMatchPage = () => {
  const { potentialId, applicationId } = useParams();
  const queryParam = useQueryParams('redirect_uri');

  const { entry, matchingEntry, isLoading, isError } = useGetEntryAndMatchingEntryById(
    applicationId as string,
    potentialId as string
  );
  const { onMergeEntry } = useMergeEntry();
  const { checkIsAssignedToAnotherUser } = useEntryAssignedToAnother();

  const [showModal, setIsShowModal] = useState(false);

  if (isLoading) {
    return <Loader />;
  }

  if (isError || !entry || !matchingEntry) {
    return <ShowState variant="error" type="application" message="Something went wrong" />;
  }

  const folder_uri = `/folder/${entry.folder_id}`;
  const folder_matched_uri = `/folder/${matchingEntry.folder_id}`;
  const redirect_uri = queryParam.isValid ? queryParam.result : folder_uri;

  const isAssignedToAnotherUser = checkIsAssignedToAnotherUser(
    matchingEntry?.review_assigned_to_id
  );

  return (
    <DetailView
      title="Potential Match"
      back={{
        label: 'Go Back',
        to: redirect_uri
      }}
    >
      <StyledTextDescription variant={TextVariant.normal} color={TextColor.gray600}>
        Snappt detected that this older application folder entry could potentially be from the same
        applicant. Please compare it with the newer one and, if they match, move them into the same
        folder.
      </StyledTextDescription>

      <Row justify="space-between">
        <Row direction="column" gap={1}>
          <Row justify="space-between" alignItems="center">
            <Text variant={TextVariant.normal} color={TextColor.gray600}>
              Older Potential Match
            </Text>

            <ButtonLink
              to={folder_matched_uri}
              name="application-entry-folder"
              variant={ButtonLinkVariant.ghost}
              target={ButtonLinkTarget.blank}
            >
              View Documents <LaunchIcon />
            </ButtonLink>
          </Row>

          <PotentialMatchBox entry={matchingEntry} />
        </Row>

        <Row direction="column" gap={1}>
          <Row justify="space-between" alignItems="center">
            <Text variant={TextVariant.normal} color={TextColor.gray600}>
              Newer Application entry
            </Text>

            <ButtonLink
              to={folder_uri}
              name="potential-match-folder"
              variant={ButtonLinkVariant.ghost}
              target={ButtonLinkTarget.blank}
            >
              View Documents <LaunchIcon />
            </ButtonLink>
          </Row>

          <PotentialMatchBox entry={entry} />
        </Row>
      </Row>

      <Line gap={1.75} />

      <Button
        onClick={() => setIsShowModal((prevState) => !prevState)}
        name="merge-entry"
        variant={ButtonVariant.contained}
      >
        Move to same folder
      </Button>

      {showModal && (
        <Modal
          variant={ModalVariant.primary}
          showModal={showModal}
          title="Move application to same folder"
          labelButtonConfirm="Yes, move to same folder"
          labelButtonCancel="Cancel"
          onConfirm={() => {
            setIsShowModal(false);
            onMergeEntry(potentialId, applicationId);
          }}
          onCancel={() => setIsShowModal(false)}
        >
          <Text color={TextColor.gray600} variant={TextVariant.normal}>
            You’re about to move the newer application entry into the same folder as the older one.
            If you proceed, Snappt will add the newer entry as a tab in the Applicant Review page
            and will include it in the FDE Inbox under the same folder.
          </Text>
          {isAssignedToAnotherUser && (
            <>
              <Gap />
              <Text color={TextColor.error} variant={TextVariant.small}>
                This application is going to be moved to a folder being reviewed by another user
              </Text>
            </>
          )}
        </Modal>
      )}
    </DetailView>
  );
};

export default PotentialMatchPage;
