// Vendor
import { useSelector } from 'react-redux';

// Components
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import Section from 'src/components/atoms/Section/Section';
import Text, { TextVariant } from 'src/components/atoms/Text';

// Redux
import { selectors as entryReportSelectors } from 'src/features/fde-report/fdeReportSlice';

const ApplicantDetail: React.FC = () => {
  const entryReport = useSelector(entryReportSelectors.getEntryReport.data);
  const { applicant, entry, property, company } = entryReport || {};

  const showPropertyNameAndAddress = ![
    'bdb360de-e04a-445d-ab91-55317fb60c59',
    'd5ce2f95-8f14-43ee-bffd-4355204861ff'
  ].includes(company?.id as string);

  return (
    <Section padding="2rem 3rem 1rem 3rem">
      <Row justify="space-between" gap={1}>
        <Row.Col>
          <Row direction="column" gap={1}>
            <Text isBold>Applicant</Text>
            <Text variant={TextVariant.h2}>{applicant?.full_name}</Text>
          </Row>
        </Row.Col>
        <Row.Col>
          <Row direction="column" gap={1}>
            <Text isBold>Contact Information</Text>
            <Text isBold variant={TextVariant.span}>
              P:&nbsp;
              <Text variant={TextVariant.span}>{applicant?.phone}</Text>
            </Text>
            <Text isBold variant={TextVariant.span}>
              E:&nbsp;
              <Text variant={TextVariant.span}>{applicant?.email}</Text>
            </Text>
          </Row>
        </Row.Col>
      </Row>
      <Gap height={2} />
      <Row direction="column" gap={2}>
        {showPropertyNameAndAddress && (
          <Row direction="column" gap={1}>
            <Text isBold>Property Name</Text>
            <Text>{property?.name}</Text>
          </Row>
        )}
        {showPropertyNameAndAddress && (
          <Row direction="column" gap={1}>
            <Text isBold>Property Address</Text>
            <Text>{property?.address}</Text>
          </Row>
        )}
        <Row direction="column" gap={1}>
          <Text isBold>Unit Number</Text>
          <Text>{entry?.unit || 'Not Specified'}</Text>
        </Row>
        <Row direction="column" gap={1}>
          <Text isBold>Application Notes</Text>
          <Text>{entry?.note || 'No notes'}</Text>
        </Row>
        <Row direction="column" gap={1}>
          <Text isBold>Fraud Summary</Text>
        </Row>
      </Row>
    </Section>
  );
};

export default ApplicantDetail;
