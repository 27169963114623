import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { NAME } from './constants';
import { getAllReducer, getOneReducer, initialState } from './reducers';

const resourcesSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getAllReducer(builder);
    getOneReducer(builder);
  }
});

export const selectors = {
  getAll: (state: RootState) => state[NAME].getAll,
  getOne: (state: RootState) => state[NAME].getOne
};

export const { reducer, actions } = resourcesSlice;
