import { LeasingTeam, SnapptTeam } from 'src/constants/roles';
import { NavItemIcons, NavItemTarget } from '../components/atoms/NavItem';
import configVars from '../helpers/configVars/configVars';
import { RoleEnum } from '../ts/enums';
import { INavOption } from '../ts/interfaces/navOption';

/**
 * To declare the names of each option of the navigation system
 */
export const ROUTE_NAMES = {
  GET_STARTED: 'GetStarted',
  MY_WORK: 'MyWork',
  MY_WORK_PROOF_DETAIL: 'MyWorkProofDetail',
  FDE_INBOX: 'FDEInbox',
  FDE_INBOX_REPORT: 'FDEInboxReport',
  IDENTITY_VERIFICATION_REPORT: 'IdentityVerificationReport',
  FR_FOLDER: 'FolderReview',
  FR_TEXT_INSERTION: 'TextInsertion',
  MATCHING: 'Matching',
  PROPERTIES: 'Properties',
  COMPANIES: 'Companies',
  MY_COMPANY: 'MyCompany',
  USERS: 'Users',
  ADD_USERS: 'AddUsers',
  ADD_USERS_LEASING: 'AddUsersLeasing',
  RESOURCES: 'Resources',
  SLA_TRACKING: 'SLATracking',
  USER_PROFILE: 'UserProfile',
  REPORTING: 'Reporting',
  DOCUMENT_LINKS: 'DocumentLinks',
  IDENTITY_VERIFICATION_LINKS: 'IdentityVerificationLinks',
  TEAMS: 'Teams',
  TEAMS_CREATE: 'CreateTeam',
  TEAMS_PERFORMANCE: 'TeamsPerformance',
  PROGRAMMATIC_REPORT: 'Programmatic Report'
};

/**
 * Setup each option of the navigation system
 */

export const getActiveRouteOptions = (
  identityVerificationEnabled?: boolean,
  isDocumentLinksTabEnabled?: boolean,
  isHelpAndResourcesUpdateEnabled?: boolean
): INavOption[] => [
  ...(identityVerificationEnabled
    ? [
        {
          name: ROUTE_NAMES.IDENTITY_VERIFICATION_LINKS,
          label: 'ID Verification Link',
          icon: NavItemIcons.identityVerification,
          to: '/idvlinks',
          target: NavItemTarget.self,
          isVisible: true,
          permissions: [
            RoleEnum.BuildingManager,
            RoleEnum.PropertyAdmin,
            RoleEnum.CompanyAdmin,
            RoleEnum.OnSiteRepresentative,
            RoleEnum.AccountRepresentative,
            RoleEnum.Admin
          ]
        }
      ]
    : []),
  ...(isDocumentLinksTabEnabled
    ? [
        {
          name: ROUTE_NAMES.DOCUMENT_LINKS,
          label: 'Document Link',
          icon: NavItemIcons.onboarding,
          to: '/duplinks',
          target: NavItemTarget.self,
          isVisible: true,
          permissions: [
            RoleEnum.BuildingManager,
            RoleEnum.PropertyAdmin,
            RoleEnum.OnSiteRepresentative
          ]
        }
      ]
    : []),
  {
    name: ROUTE_NAMES.FDE_INBOX,
    label: 'FDE Inbox',
    icon: NavItemIcons.inbox,
    to: '/applications',
    target: NavItemTarget.self,
    isVisible: true,
    permissions: [...LeasingTeam, ...SnapptTeam]
  },
  {
    name: ROUTE_NAMES.MY_WORK,
    label: 'My Work',
    icon: NavItemIcons.briefcase,
    to: '/my-work',
    target: NavItemTarget.self,
    isVisible: true,
    permissions: [RoleEnum.Admin, RoleEnum.FraudAnalyst, RoleEnum.SeniorFraudAnalyst]
  },
  //todo: convert to launch darkly feature flag in the future if feature is assigned priority
  // {
  //   name: ROUTE_NAMES.MY_WORK_PROOF_DETAIL,
  //   label: 'My Work',
  //   icon: NavItemIcons.briefcase,
  //   to: '/',
  //   target: NavItemTarget.self,
  //   isVisible: false,
  //   permissions: [RoleEnum.Admin, RoleEnum.FraudAnalyst, RoleEnum.SeniorFraudAnalyst]
  // },
  {
    name: ROUTE_NAMES.FDE_INBOX_REPORT,
    label: 'FDEInboxReport',
    icon: NavItemIcons.resource,
    to: '/',
    target: NavItemTarget.self,
    isVisible: false,
    permissions: [...LeasingTeam, ...SnapptTeam]
  },
  {
    name: ROUTE_NAMES.IDENTITY_VERIFICATION_REPORT,
    label: 'IdentityVerificationReport',
    icon: NavItemIcons.resource,
    to: '/',
    target: NavItemTarget.self,
    isVisible: false,
    permissions: [
      RoleEnum.PropertyAdmin,
      RoleEnum.BuildingManager,
      RoleEnum.AccountRepresentative,
      RoleEnum.Admin,
      RoleEnum.CompanyAdmin,
      RoleEnum.OnSiteRepresentative
    ]
  },
  {
    name: ROUTE_NAMES.MATCHING,
    label: 'Matching',
    icon: NavItemIcons.folder,
    to: '/',
    target: NavItemTarget.self,
    isVisible: false,
    permissions: [
      RoleEnum.Admin,
      RoleEnum.Reviewer,
      RoleEnum.FraudAnalyst,
      RoleEnum.FdeManager,
      RoleEnum.SeniorFraudAnalyst
    ]
  },

  {
    name: ROUTE_NAMES.FR_FOLDER,
    label: 'Folder Review',
    icon: NavItemIcons.folder,
    to: '/folder',
    target: NavItemTarget.self,
    isVisible: false,
    permissions: [
      RoleEnum.Admin,
      RoleEnum.Reviewer,
      RoleEnum.FraudAnalyst,
      RoleEnum.FdeManager,
      RoleEnum.SeniorFraudAnalyst,
      RoleEnum.AccountRepresentative
    ]
  },
  {
    name: ROUTE_NAMES.FR_TEXT_INSERTION,
    label: 'Text Insertion',
    icon: NavItemIcons.resource,
    to: '/',
    target: NavItemTarget.self,
    isVisible: false,
    permissions: [
      RoleEnum.Admin,
      RoleEnum.Reviewer,
      RoleEnum.FraudAnalyst,
      RoleEnum.FdeManager,
      RoleEnum.SeniorFraudAnalyst
    ]
  },
  {
    name: ROUTE_NAMES.GET_STARTED,
    label: 'Get Started',
    icon: NavItemIcons.getStarted,
    to: '/get-started',
    target: NavItemTarget.self,
    isVisible: true,
    permissions: [RoleEnum.Admin, RoleEnum.AccountRepresentative]
  },
  {
    name: ROUTE_NAMES.PROPERTIES,
    label: 'Properties',
    icon: NavItemIcons.property,
    to: '/properties',
    target: NavItemTarget.self,
    isVisible: true,
    permissions: [
      RoleEnum.CompanyAdmin,
      RoleEnum.Admin,
      RoleEnum.BuildingManager,
      RoleEnum.AccountRepresentative
    ]
  },
  {
    name: ROUTE_NAMES.COMPANIES,
    label: 'Companies',
    icon: NavItemIcons.company,
    to: '/companies',
    target: NavItemTarget.self,
    isVisible: true,
    permissions: [RoleEnum.Admin, RoleEnum.AccountRepresentative]
  },
  {
    name: ROUTE_NAMES.MY_COMPANY,
    label: 'My Company',
    icon: NavItemIcons.company,
    to: '/company',
    target: NavItemTarget.self,
    isVisible: true,
    permissions: []
  },
  {
    name: ROUTE_NAMES.USERS,
    label: 'Users',
    icon: NavItemIcons.user,
    to: '/users',
    target: NavItemTarget.self,
    isVisible: true,
    permissions: [
      RoleEnum.Admin,
      RoleEnum.CompanyAdmin,
      RoleEnum.BuildingManager,
      RoleEnum.AccountRepresentative
    ]
  },
  {
    name: ROUTE_NAMES.ADD_USERS,
    label: 'Add Users',
    icon: NavItemIcons.user,
    to: '/users/invitation/create',
    target: NavItemTarget.self,
    isVisible: false,
    permissions: [
      RoleEnum.Admin,
      RoleEnum.BuildingManager,
      RoleEnum.CompanyAdmin,
      RoleEnum.AccountRepresentative
    ]
  },
  {
    name: ROUTE_NAMES.ADD_USERS_LEASING,
    label: 'Add Users',
    icon: NavItemIcons.user,
    to: '/users/invitation/create',
    target: NavItemTarget.self,
    isVisible: false,
    permissions: [...LeasingTeam, RoleEnum.Admin, RoleEnum.AccountRepresentative]
  },
  {
    name: ROUTE_NAMES.RESOURCES,
    label: 'Help & Resources',
    icon: NavItemIcons.resource,
    to: '/resources',
    ...(isHelpAndResourcesUpdateEnabled
      ? {
          onClick: async () => {
            window.open(`${configVars.server_url}/clueso/auth`, '_blank', 'noopener noreferrer');
          }
        }
      : {}),
    target: NavItemTarget.self,
    isVisible: true,
    permissions: [...LeasingTeam, RoleEnum.Admin, RoleEnum.AccountRepresentative]
  },
  {
    name: ROUTE_NAMES.SLA_TRACKING,
    label: 'SLA Tracking',
    icon: NavItemIcons.tracking,
    to: '/sla-tracking',
    target: NavItemTarget.self,
    isVisible: true,
    permissions: [RoleEnum.Admin, RoleEnum.FdeManager, RoleEnum.SeniorFraudAnalyst]
  },
  {
    name: ROUTE_NAMES.REPORTING,
    label: 'Reporting',
    icon: NavItemIcons.report,
    to: '/reporting',
    target: NavItemTarget.self,
    isVisible: true,
    permissions: [...LeasingTeam, RoleEnum.Admin, RoleEnum.AccountRepresentative]
  },
  {
    name: ROUTE_NAMES.PROGRAMMATIC_REPORT,
    label: 'Programmatic Report',
    icon: NavItemIcons.programmaticReport,
    to: '/programmaticReporting',
    target: NavItemTarget.self,
    isVisible: true,
    permissions: [RoleEnum.Admin]
  },
  {
    name: ROUTE_NAMES.TEAMS,
    label: 'Teams',
    icon: NavItemIcons.teams,
    to: '/teams',
    target: NavItemTarget.self,
    isVisible: true,
    permissions: [RoleEnum.Admin, RoleEnum.FdeManager]
  },
  {
    name: ROUTE_NAMES.TEAMS_CREATE,
    label: 'Create Team',
    icon: NavItemIcons.teams,
    to: '/teams/create',
    target: NavItemTarget.self,
    isVisible: false,
    permissions: [RoleEnum.Admin, RoleEnum.FdeManager]
  },
  //todo: convert to launch darkly feature flag in the future if feature is assigned priority
  // {
  //   name: ROUTE_NAMES.TEAMS_PERFORMANCE,
  //   label: 'Team Performance',
  //   icon: NavItemIcons.teams,
  //   to: '/teams',
  //   target: NavItemTarget.self,
  //   isVisible: false,
  //   permissions: [
  //     RoleEnum.Admin,
  //     RoleEnum.FraudAnalyst,
  //     RoleEnum.SeniorFraudAnalyst,
  //     RoleEnum.FdeManager
  //   ]
  // },
  {
    name: ROUTE_NAMES.USER_PROFILE,
    label: 'User Profile',
    icon: NavItemIcons.none,
    to: '/user-profile',
    target: NavItemTarget.self,
    isVisible: false,
    permissions: [...LeasingTeam, ...SnapptTeam]
  }
];
