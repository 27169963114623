// Vendor
import { useEffect } from 'react';

// Components
import { TransferList } from 'src/components/molecules/TransferList';

// Types
import { CheckboxType } from 'src/components/atoms/Checkbox/types';
import { ITransferListContent } from 'src/components/molecules/TransferList/types';
import { IProperty, IPropertyId } from 'src/ts/interfaces';

// Hooks
import useSelectProperties from 'src/features/users/hooks/useSelectProperties';
import useUserUnassignedProperties from 'src/features/users/hooks/useUserUnassignedProperties';

type SelectPropertiesProps = {
  properties: IProperty[];
  companySelected: string;
  onGetProperties: (content: IPropertyId[]) => void;
};

const SelectProperties = (props: SelectPropertiesProps) => {
  const {
    getData,
    isLoadingUnassigned,
    isLoadingPage,
    hasError,
    unassigned,
    totalRows,
    assigned,
    unassignedScrollObserver
  } = useSelectProperties(props);

  const { onResetUnassignedProperties } = useUserUnassignedProperties();

  useEffect(() => {
    return () => {
      onResetUnassignedProperties();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSetLists = (data: ITransferListContent) => {
    getData({
      assignedProperties: data.secondList.data,
      q: data.firstList.q
    });
    const properties = data.secondList.data.map((property: CheckboxType) => {
      return { id: property.value };
    });
    props?.onGetProperties(properties);
  };

  return (
    <TransferList
      name="SelectProperties"
      showSearch
      showSelectChoices
      searchPlaceholder="Search"
      onGetData={onSetLists}
      firstList={{
        name: 'UnassignedProperties',
        title: 'Unassigned Properties',
        messageEmpty: 'There are no<br>properties to show',
        isLoading: isLoadingUnassigned,
        isLoadingPage: isLoadingPage,
        hasError,
        data: unassigned,
        totalRows,
        innerRef: unassignedScrollObserver,
        onSearch: (q) => {
          getData({
            assignedProperties: assigned,
            q
          });
        }
      }}
      secondList={{
        name: 'AssignedProperties',
        title: 'Assigned Properties',
        messageEmpty: 'There are no<br>properties to show',
        hasError,
        data: assigned
      }}
    />
  );
};

export default SelectProperties;
