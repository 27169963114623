import {
  createEntityAdapter,
  ActionReducerMapBuilder,
  createSlice,
  EntityState,
  createDraftSafeSelector
} from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { IMatchingEntry } from 'src/ts/interfaces';
import { getEntriesByFolderIdThunk } from '../entries/services';
import { NAME } from './constants';
import { getMatchingEntriesByEntryIdThunk } from './services';

interface MatchingEntry {
  entryId: string;
  matches: IMatchingEntry[];
}

interface StateData extends EntityState<MatchingEntry> {
  status: StatusTypes;
  error?: string | null;
}

export const matchingEntriesAdapter = createEntityAdapter<MatchingEntry>({
  selectId: ({ entryId }) => entryId
});

export const matchingEntriesSlice = createSlice({
  name: NAME,
  initialState: matchingEntriesAdapter.getInitialState({
    status: StatusTypes.IDLE,
    error: null
  }),
  reducers: {},
  extraReducers: (builder) => {
    getMatchingEntriesByEntryIdReducer(builder);
  }
});

const getMatchingEntriesByEntryIdReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getMatchingEntriesByEntryIdThunk.pending, (state) => {
    state.status = StatusTypes.LOADING;
    state.error = null;
  });
  builder.addCase(getMatchingEntriesByEntryIdThunk.fulfilled, (state, action) => {
    const entryId = action.meta.arg;
    const response = action.payload;
    const matches = response?.data as IMatchingEntry[];

    state.status = StatusTypes.SUCCESS;
    matchingEntriesAdapter.upsertOne(state, { entryId, matches });
  });
  builder.addCase(getMatchingEntriesByEntryIdThunk.rejected, (state, action) => {
    state.status = StatusTypes.ERROR;
    state.error = action.error?.message;
  });
  builder.addCase(getEntriesByFolderIdThunk.pending, (state) => {
    state.status = StatusTypes.LOADING;
  });
};

const selectAllByEntryId = createDraftSafeSelector(
  [matchingEntriesAdapter.getSelectors((state: RootState) => state[NAME]).selectById],
  (match) => match?.matches || []
);

export const selectors = {
  ...matchingEntriesAdapter.getSelectors((state: RootState) => state[NAME]),
  status: (state: RootState) => state[NAME].status,
  selectAllByEntryId
};

export const { reducer, actions } = matchingEntriesSlice;
