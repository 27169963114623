import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { Loader } from 'src/components/atoms/Loader';
import Section from 'src/components/atoms/Section/Section';
import { ShowState } from 'src/components/molecules/ShowState';
import { Onepage } from 'src/components/templates';
import { selectors as authSelectors } from 'src/features/auth/authSlice';
import { LayoutPage } from 'src/pages';
import { Roles, Status } from 'src/ts/enums';

type Props = {
  component: FC | React.ReactNode;
  rolesNames?: Roles[];
  template?: 'LayoutPage' | 'Onepage' | 'WidePage';
};

const PrivateRoute = ({
  component: Component,
  rolesNames,
  template = 'LayoutPage',
  ...rest
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const user = useSelector(authSelectors.user);
  const userStatus = useSelector(authSelectors.status);
  const isAuthorized = Boolean(user?.email) && userStatus === Status.SUCCESS;

  useEffect(() => {
    if (!rolesNames) {
      throw new Error(
        'PrivateRoute Component: you must define routeName: string or routesNames: Array<string>'
      );
    }
  }, [rolesNames]);

  const hasPermission =
    user && isAuthorized && Array.isArray(rolesNames) && rolesNames.includes(user.role);

  if (userStatus === Status.LOADING) return <Loader isFixed />;

  // TODO: Notify the user of this possible scenario. Does this happen when the user is assume to be unauthenticated?
  // E.g we could show a toast when this scenario happen.
  if ((userStatus === Status.SUCCESS || userStatus === Status.ERROR) && !user)
    return <Navigate to="/login" />;

  // TODO: Notify the user of this possibly scenario.
  if (hasPermission === null) return <></>;

  const PrintPage = () => {
    if (template === 'Onepage') {
      return (
        <Onepage>{typeof Component === 'function' ? <Component {...rest} /> : Component}</Onepage>
      );
    }

    return (
      <LayoutPage isWideRoute={template === 'WidePage'}>
        {typeof Component === 'function' ? <Component {...rest} /> : Component}
      </LayoutPage>
    );
  };

  return hasPermission ? (
    PrintPage()
  ) : (
    <Section>
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Go to Login"
        message="You do not have access to this page"
        onClick={() => navigate('/login')}
      />
    </Section>
  );
};

export default PrivateRoute;
