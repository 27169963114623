import { DupTypes } from 'src/features/DUP/ts/enums';
import theme from 'src/theme';

export const Color = {
  [DupTypes.APPLICANT]: {
    dark: theme.color.blue[700],
    light: theme.color.blue[70],
    icon: theme.color.blue[700],
    content: theme.color.gray[50]
  },
  [DupTypes.LEASING_TEAM]: {
    dark: theme.color.purple[700],
    light: theme.color.purple[100],
    icon: theme.color.purple[700],
    content: theme.color.purple[100]
  },
  [DupTypes.AOA]: {
    dark: theme.color.blue[700],
    light: theme.color.blue[70],
    icon: theme.color.blue[700],
    content: theme.color.gray[50]
  }
};
