/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import Button, { ButtonDisabledStyle, ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Section from 'src/components/atoms/Section/Section';

// Redux
import { selectors as entryReportSelectors } from 'src/features/fde-report/fdeReportSlice';

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';
import useFDEReport from 'src/features/fde-report/hooks/useFDEReport';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';

import { downloadDocumentPDF } from 'src/helpers';

// Enums
import { ApplicantReportPreset, EntryStatusEnum, FeatureFlagKey } from 'src/ts/enums';

// Types
import { useFeatureFlag } from 'src/hooks';
import { IApplicant, IEntry } from 'src/ts/interfaces';

const HeaderPrints: React.FC = () => {
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();
  const { isOnSiteRepresentative, isAdminOrAccountRepresentative } = useRole();
  const {
    onDownloadFcraReport,
    onResetGetDownloadFcraReport,
    onDownloadPaginatedReport,
    onResetGetDownloadPaginatedReport,
    onDownloadReport,
    onResetGetDownloadReport,
    onResetGetDownloadApplicantReport
  } = useFDEReport();

  const entryReportData = useSelector(entryReportSelectors.getEntryReport.data);

  const paginatedReportIsLoading = useSelector(
    entryReportSelectors.getDownloadPaginatedReport.isLoading
  );
  const paginatedReportError = useSelector(entryReportSelectors.getDownloadPaginatedReport.error);
  const paginatedReportFile = useSelector(entryReportSelectors.getDownloadPaginatedReport.file);
  const paginatedReportFileName = useSelector(
    entryReportSelectors.getDownloadPaginatedReport.fileName
  );

  const reportIsLoading = useSelector(entryReportSelectors.getDownloadReport.isLoading);
  const reportError = useSelector(entryReportSelectors.getDownloadReport.error);
  const reportFile = useSelector(entryReportSelectors.getDownloadReport.file);
  const reportFileName = useSelector(entryReportSelectors.getDownloadReport.fileName);

  const fcraReportIsLoading = useSelector(entryReportSelectors.getDownloadFcraReport.isLoading);
  const fcraReportError = useSelector(entryReportSelectors.getDownloadFcraReport.error);
  const fcraReportFile = useSelector(entryReportSelectors.getDownloadFcraReport.file);
  const fcraReportFileName = useSelector(entryReportSelectors.getDownloadFcraReport.fileName);

  const applicantReportIsLoading = useSelector(
    entryReportSelectors.getDownloadApplicantReport.isLoading
  );
  const applicantReportError = useSelector(entryReportSelectors.getDownloadApplicantReport.error);
  const applicantReportFile = useSelector(entryReportSelectors.getDownloadApplicantReport.file);
  const applicantReportFileName = useSelector(
    entryReportSelectors.getDownloadApplicantReport.fileName
  );
  const applicantReportPreset = useSelector(entryReportSelectors.getDownloadApplicantReport.preset);

  const newReportEndpointEnabled = useFeatureFlag(FeatureFlagKey.NEW_REPORT_ENDPOINT);

  const { company, entry, applicant } = entryReportData || {};

  useEffect(() => {
    if (!paginatedReportIsLoading && paginatedReportError === null && paginatedReportFile) {
      downloadDocumentPDF(paginatedReportFile, paginatedReportFileName);
      onResetGetDownloadPaginatedReport();
    }
  }, [
    paginatedReportIsLoading,
    paginatedReportError,
    paginatedReportFile,
    onResetGetDownloadPaginatedReport
  ]);

  useEffect(() => {
    if (paginatedReportError !== null) {
      showSnackbar(VariantType.error, paginatedReportError);
      onResetGetDownloadPaginatedReport();
    }
  }, [
    paginatedReportError,
    onResetGetDownloadPaginatedReport,
    showSnackbar,
    VariantType,
    SnackTypes
  ]);

  useEffect(() => {
    if (!reportIsLoading && reportError === null && reportFile) {
      downloadDocumentPDF(reportFile, reportFileName);
      onResetGetDownloadReport();
    }
  }, [reportIsLoading, reportError, reportFile, onResetGetDownloadReport]);

  useEffect(() => {
    if (reportError !== null) {
      showSnackbar(VariantType.error, reportError);
      onResetGetDownloadReport();
    }
  }, [reportError, onResetGetDownloadReport, showSnackbar, VariantType, SnackTypes]);

  useEffect(() => {
    if (!fcraReportIsLoading && fcraReportError === null && fcraReportFile) {
      downloadDocumentPDF(fcraReportFile, fcraReportFileName);
      onResetGetDownloadFcraReport();
    }
  }, [fcraReportIsLoading, fcraReportError, fcraReportFile, onResetGetDownloadFcraReport]);

  useEffect(() => {
    if (fcraReportError !== null) {
      showSnackbar(VariantType.error, fcraReportError);
      onResetGetDownloadFcraReport();
    }
  }, [fcraReportError, onResetGetDownloadFcraReport, showSnackbar, VariantType, SnackTypes]);

  useEffect(() => {
    if (!applicantReportIsLoading && applicantReportError === null && applicantReportFile) {
      downloadDocumentPDF(applicantReportFile, applicantReportFileName);
      onResetGetDownloadApplicantReport();
    }
  }, [
    applicantReportIsLoading,
    applicantReportError,
    applicantReportFile,
    onResetGetDownloadApplicantReport
  ]);

  useEffect(() => {
    if (applicantReportError !== null) {
      showSnackbar(VariantType.error, applicantReportError);
      onResetGetDownloadApplicantReport();
    }
  }, [
    applicantReportError,
    onResetGetDownloadApplicantReport,
    showSnackbar,
    VariantType,
    SnackTypes
  ]);

  const fcraLoading =
    newReportEndpointEnabled && applicantReportPreset === ApplicantReportPreset.Fcra
      ? applicantReportIsLoading
      : fcraReportIsLoading;
  const paginatedLoading =
    newReportEndpointEnabled && applicantReportPreset === ApplicantReportPreset.SummaryAndDocuments
      ? applicantReportIsLoading
      : paginatedReportIsLoading;
  const reportLoading =
    newReportEndpointEnabled && applicantReportPreset === ApplicantReportPreset.Summary
      ? applicantReportIsLoading
      : reportIsLoading;

  return (
    <Section padding="2rem 3rem 2rem 3rem">
      <Row justify="space-between" alignItems="center">
        <Row.Col>
          {company?.logo ? (
            <img src={company?.logo} alt={company?.name} style={{ maxWidth: '250px' }} />
          ) : (
            <></>
          )}
        </Row.Col>
        <Row.Col className="hiddenPrint">
          <Row justify="space-between">
            {isAdminOrAccountRepresentative && (
              <Button
                name={'download_report_fcra_button'}
                variant={ButtonVariant.ghost}
                isDisabled={fcraLoading}
                isLoading={fcraLoading}
                disabledStyle={ButtonDisabledStyle.white}
                onClick={() => onDownloadFcraReport(entry as IEntry, applicant as IApplicant)}
              >
                <Icon icon="download" />
                &nbsp;Download FCRA report
              </Button>
            )}
            {entry?.status !== EntryStatusEnum.Pending && !isOnSiteRepresentative && (
              <Button
                name={'download_report_paginated_button'}
                variant={ButtonVariant.ghost}
                isDisabled={paginatedLoading}
                isLoading={paginatedLoading}
                disabledStyle={ButtonDisabledStyle.white}
                onClick={() => onDownloadPaginatedReport(entry as IEntry, applicant as IApplicant)}
              >
                <Icon icon="download-paginated" />
                &nbsp;Download paginated report
              </Button>
            )}
            <Button
              name={'download_report_button'}
              variant={ButtonVariant.ghost}
              isDisabled={reportLoading}
              isLoading={reportLoading}
              disabledStyle={ButtonDisabledStyle.white}
              onClick={() => onDownloadReport(entry as IEntry, applicant as IApplicant)}
            >
              <Icon icon="download" />
              &nbsp;Download report
            </Button>
            <Button
              name={'print_report_button'}
              variant={ButtonVariant.ghost}
              onClick={() => window.print()}
            >
              <Icon icon="print" />
              &nbsp;Print report
            </Button>
          </Row>
        </Row.Col>
      </Row>
    </Section>
  );
};

export default HeaderPrints;
