import { announcementApi } from 'src/api';

const api = {
  getLast: async () => {
    return await announcementApi.getUserLatestAnnouncements();
  },
  markAsShown: async (announcementId: number) => {
    return await announcementApi.markAsShownUserAnnouncement(announcementId);
  }
};

export default api;
