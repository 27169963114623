import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

export const SidebarHeader = styled.div``;

export const SidebarBody = styled.div`
  flex-grow: 1;

  @media ${({ theme }) => theme.device.mobile} {
    flex-grow: 0;
  }
`;

export const SidebarFooter = styled.div``;

const getWidth = (isOpen: boolean) => {
  return isOpen ? 250 : 80;
};

export const Sidebar = styled.aside<{ isOpen: boolean }>`
  overflow: visible;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: 999;
  background: ${({ theme }) => theme.color.snappt[500]};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  transition: width 0.3s;
  width: ${({ isOpen }) => getWidth(isOpen)}px;

  a,
  ul[data-id='navProfile'] button {
    transition: width 0.3s;
    width: ${({ isOpen }) => getWidth(isOpen)}px;
    div {
      width: 2rem;
    }
    span {
      display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    }
  }
`;

export const Content = styled.main<{ isOpen: boolean }>`
  ${({ theme }) => theme.font.normal};
  width: calc(100% - ${({ isOpen }) => getWidth(isOpen)}px);
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.color.gray[100]};
  display: flex;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0;
  }
`;

export const Wrapper = styled.div<{ isWideRoute?: boolean }>`
  background: ${({ theme }) => theme.color.white};
  flex: 1;
  width: 100%;
  min-width: 330px;
  max-width: ${({ isWideRoute }) => (isWideRoute ? 1700 : 990)}px;
  margin: 0 auto;
  padding: 1.375rem 2rem;
`;

export const Logo = styled(ReactSVG)`
  @media (max-height: 500px) {
    margin: 0.5rem 0rem;
    & svg {
      height: 1.5rem;
    }
  }
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-right: 1.625rem;
  padding-left: 1.625rem;
`;

export const Button = styled.button`
  position: absolute;
  border: none;
  right: -1.1rem;
  width: 2rem;
  height: 2rem;
  top: 4rem;
  border-radius: 100%;
  background: ${({ theme }) => theme.color.white};
  padding: 0;
  margin: 0;
  line-height: 0;
  cursor: pointer;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 20%);
`;

export const ButtonIcon = styled(ReactSVG)``;

export const ItemList = styled.li``;

export const UnorderedList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  ${ItemList} {
  }
`;

export const Version = styled.div`
  margin-top: 2rem;
  padding-bottom: 1rem;
  @media (max-height: 440px) {
    display: none;
  }
`;

export const Policies = styled.div`
  padding-bottom: 0.25rem;
  a {
    color: ${({ theme }) => theme.color.white};
  }
`;
