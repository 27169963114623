// Vendor
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { ButtonColor, ButtonVariant } from 'src/components/atoms/Button';
import ButtonLink, {
  ButtonLinkColor,
  ButtonLinkDisabledStyle,
  ButtonLinkTarget,
  ButtonLinkVariant
} from 'src/components/atoms/ButtonLink';
import { Icon } from 'src/components/atoms/Icon';
import ToolTip, { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import {
  StyledButtonCopy,
  StyledButtons,
  StyledCopyButtonContainer,
  StyledCopyContainer
} from './styles';

// Hooks
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';

export type CopyButtonProps = {
  to: string;
  label: string;
  name?: string;
  colorLink?: ButtonLinkColor;
  colorCopy?: ButtonColor;
  isDisabled?: boolean;
  isExternal?: boolean;
  showOpenLinkButton?: boolean;
  showCopyButton?: boolean;
};

const CopyButton: React.FC<CopyButtonProps> = ({
  name,
  label,
  to,
  colorLink,
  colorCopy,
  isDisabled,
  showOpenLinkButton = true,
  showCopyButton
}) => {
  const { copy } = useCopyToClipboard();

  return (
    <StyledCopyButtonContainer>
      <StyledButtons>
        {showOpenLinkButton && (
          <ButtonLink
            variant={ButtonLinkVariant.outline}
            color={colorLink}
            to={to}
            name={`${name}_Link`}
            target={ButtonLinkTarget.blank}
            isDisabled={isDisabled}
            disabledStyle={ButtonLinkDisabledStyle.gray}
            isExternal
          >
            {label}&nbsp;&nbsp;
            <Icon icon="launch" />
          </ButtonLink>
        )}
        {showCopyButton && (
          <StyledCopyContainer>
            <ToolTip content="Copy" direction={ToolTipDirection.top}>
              <StyledButtonCopy
                variant={ButtonVariant.contained}
                color={colorCopy}
                name={`${name}_Copy`}
                onClick={() => copy(to)}
                isDisabled={isDisabled}
              >
                <Icon icon="filterNone" />
              </StyledButtonCopy>
            </ToolTip>
          </StyledCopyContainer>
        )}
      </StyledButtons>
    </StyledCopyButtonContainer>
  );
};

CopyButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  colorLink: PropTypes.oneOf<ButtonLinkColor>(Object.values(ButtonLinkColor)).isRequired,
  colorCopy: PropTypes.oneOf<ButtonColor>(Object.values(ButtonColor)).isRequired,
  isDisabled: PropTypes.bool,
  showCopyButton: PropTypes.bool
};

CopyButton.defaultProps = {
  name: 'CopyButton',
  label: 'Button',
  to: '/',
  colorLink: ButtonLinkColor.primary,
  colorCopy: ButtonColor.primary,
  isDisabled: false,
  isExternal: false,
  showCopyButton: false
};

export default CopyButton;
