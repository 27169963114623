// Vendor
import PropTypes from 'prop-types';

// Components
import { Checkbox } from 'src/components/atoms/Checkbox';
import Spinner from 'src/components/atoms/Spinner';
import { Container } from './styles';

// Types
import { CheckboxType } from 'src/components/atoms/Checkbox/types';

type onClickProps = {
  name: string;
  data: CheckboxType[];
};

export type CheckboxGroupProps = {
  onClick?: (obj: onClickProps) => void;
  onChange?: (arr: CheckboxType[]) => void;
  name: string;
  data: CheckboxType[];
  showLabel?: boolean;
  innerRef?: React.RefObject<HTMLDivElement>;
  isLoading?: boolean;
};
const CheckboxGroup: React.FC<CheckboxGroupProps> = (props: CheckboxGroupProps) => {
  const { onClick, onChange, name, data, showLabel, innerRef, isLoading } = props;

  const onChanging = (item: CheckboxType) => {
    const position = data.findIndex((p) => p.value === item.value);
    data[position] = item;
    onClick?.({ name, data });
    onChange?.(data);
  };

  return (
    <Container>
      {data?.map((item: CheckboxType, index: number) => {
        if (item.isVisible === false) return null;
        const config = data.length === index + 1 ? { ref: innerRef } : null;

        return (
          <div {...config} key={`${name}_${item.value}_${item.isChecked.toString()}`}>
            <Checkbox
              {...item}
              showLabel={showLabel}
              name={`${name}_${item.value}`}
              onClick={onChanging}
            />
          </div>
        );
      })}
      {isLoading && <Spinner size="small" color="primary" centered />}
    </Container>
  );
};

CheckboxGroup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      isChecked: PropTypes.bool.isRequired,
      isDisabled: PropTypes.bool,
      isNull: PropTypes.bool
    }).isRequired
  ).isRequired,
  name: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  innerRef: PropTypes.any,
  isLoading: PropTypes.bool
};

CheckboxGroup.defaultProps = {
  showLabel: true,
  name: 'CheckboxGroup',
  isLoading: false
};

export default CheckboxGroup;
