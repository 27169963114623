import Text from 'src/components/atoms/Text';
import styled from 'styled-components';
import { ModalVariant, ModalWidth } from '.';

type backgroundProp = {
  blurBackground?: boolean;
};
export const Background = styled.div<backgroundProp>`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.color.gray[900]}33;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000;
  backdrop-filter: blur(${({ blurBackground }) => (blurBackground ? '8px' : '0px')});
  .modal__container {
    max-height: 100%;
  }
`;

type StyleHeader = {
  variant: ModalVariant;
  width: ModalWidth;
};

export const Wrapper = styled.div<StyleHeader>`
  border-top: 0.3125rem solid
    ${({ theme, variant }) => {
      switch (variant) {
        case ModalVariant.danger:
          return theme.color.red[700];
        case ModalVariant.primary:
          return theme.color.blue[600];
        default:
          return theme.color.white;
      }
    }};
  min-width: 24.375rem;
  max-width: ${({ width }) =>
    width === ModalWidth.large
      ? '37.5rem'
      : width === ModalWidth.extraLarge
      ? '70rem'
      : '24.375rem'};
  padding: 1.5rem;
  background: ${({ theme }) => theme.color.white};
  display: grid;
  position: relative;
  z-index: 10;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.8;
  width: inherit;
`;

export const Container = styled.div`
  max-height: 30rem;
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
`;

export const Body = styled.div`
  p {
    line-height: 1.5rem;
  }
`;

type footerProps = {
  showCancelButton?: boolean;
};
export const Footer = styled.div<footerProps>`
  padding-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: left;

  button:last-of-type {
    margin-left: ${({ showCancelButton }) => (showCancelButton ? '1.2rem' : '0px')};
  }
`;

export const StyledText = styled(Text)`
  white-space: pre-line;
`;
