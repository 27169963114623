import { FC, useCallback } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import InputNumber, { InputNumberProps } from './InputNumber';

export interface ControlledInputNumberProps
  extends UseControllerProps,
    Omit<InputNumberProps, 'name' | 'value'> {}

const ControlledInputNumber: FC<ControlledInputNumberProps> = (props) => {
  const { name, defaultValue, control, rules, shouldUnregister, onChange, ...inputNumberProps } =
    props;

  const {
    field: { onChange: onChangeController }
  } = useController({ name, defaultValue, control, rules, shouldUnregister });

  const handleOnChange = useCallback(
    (value: number) => {
      onChangeController(value);
      onChange?.(value);
    },
    [onChange, onChangeController]
  );
  return (
    <InputNumber {...inputNumberProps} value={defaultValue} name={name} onChange={handleOnChange} />
  );
};

ControlledInputNumber.displayName = 'ControlledInputNumber';

export default ControlledInputNumber;
