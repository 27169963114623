import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { NAME } from './constants';

export const getAll = createAsyncThunk(`${NAME}/getAll`, async () => {
  try {
    return await api.getAll();
  } catch (error) {
    console.error(`${NAME}/getAll`, error);
    throw new Error('Error fetching the resources');
  }
});

export const getOne = createAsyncThunk(`${NAME}/getOne`, async (resourceId: string) => {
  try {
    return await api.getOne(resourceId);
  } catch (error) {
    console.error(`${NAME}/getOne`, error);
    throw new Error('Error getting the resource information');
  }
});
