// Vendor
import { useSelector } from 'react-redux';

// Components
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Section from 'src/components/atoms/Section/Section';
import Text, { TextVariant } from 'src/components/atoms/Text';

// Redux
import { selectors as entryReportSelectors } from 'src/features/fde-report/fdeReportSlice';

// Helpers
import { dateFormatWithDots } from 'src/helpers';

const HeaderDates: React.FC = () => {
  const getEntryReport = useSelector(entryReportSelectors.getEntryReport.data);
  const { entry } = getEntryReport || {};

  return (
    <Section padding="0 3rem">
      <Row justify="space-between">
        <Row.Col alignSelf="center">
          <Text variant={TextVariant.span} isBold>
            Report Date:&nbsp;
            <Text variant={TextVariant.span}>{dateFormatWithDots(entry?.submission_time)}</Text>
          </Text>
        </Row.Col>
        <Row.Col>
          <Row direction="column" gap={0.5}>
            <Text variant={TextVariant.small}>Powered by</Text>
            <Icon icon="logo-report" />
          </Row>
        </Row.Col>
      </Row>
    </Section>
  );
};

export default HeaderDates;
