import PropTypes from 'prop-types';
import { Dispatch, SetStateAction, useCallback, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import InputSearch from 'src/components/organisms/InputSearch';
import { SelectedSortData, Sort } from 'src/components/organisms/Sort';
import useRole from 'src/features/auth/hooks/useUserRoles';
import { selectors } from 'src/features/invitation';
import useInvitations from 'src/features/invitation/hooks/useInvitations';
import useUsers from 'src/features/users/hooks/useUsers';

import ModalCreate from './ModalCreate';
import Filter from './UserFilters/Filter';
import filterReducer, { filterData, initiateFilters } from './UserFilters/filterReducer';
import { StyledButton } from './UserFilters/styles';

const sortData = {
  dropdownItems: [
    {
      label: 'Sent date',
      value: 'inserted_at',
      isSelected: true
    }
  ],
  radioGroup: [
    {
      value: 'desc',
      label: 'Descending',
      isChecked: true
    },
    {
      value: 'asc',
      label: 'Ascending',
      isChecked: false
    }
  ]
};

const sortInitial = {
  field: sortData.dropdownItems[0].value,
  order: sortData.radioGroup[0].value
};

type InvitationFiltersProps = {
  filter?: string;
  setInvitationFilters?: Dispatch<SetStateAction<string | undefined>>;
};

const InvitationsFilters: React.FC<InvitationFiltersProps> = ({ filter, setInvitationFilters }) => {
  const navigate = useNavigate();
  const { allowAddUser } = useUsers();
  const { isAdmin, role } = useRole();
  const { onGetAll, onSearch, onSort } = useInvitations();

  const getInvitationsFilter = useSelector(selectors.getAll.filter);
  const getInvitationsIsToolbarDisabled = useSelector(selectors.getAll.isToolbarDisabled);
  const getInvitationsIsLoading = useSelector(selectors.getAll.isLoading);
  const getInvitationsError = useSelector(selectors.getAll.error);

  const [filterPanelData, dispatch] = useReducer(filterReducer, filterData, initiateFilters(role));

  const [selected, setSelected] = useState<SelectedSortData>(sortInitial);

  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);

  const onFiltering = useCallback(
    (data: string) => {
      dispatch({ type: 'SET_FILTER', filterData: data });

      const queryfilter = data ? `role@>${data}` : undefined;
      setInvitationFilters?.(queryfilter);
      onGetAll(undefined, undefined, queryfilter);
    },
    [onGetAll, setInvitationFilters]
  );

  const onSorting = (selected: SelectedSortData) => {
    const query = selected?.field
      ? `${selected?.field}:${selected?.order}`
      : `${sortInitial.field}:${sortInitial.order}`;
    setSelected(selected);
    onSort(query);
  };

  const onSortingClear = () => {
    onSorting(sortInitial);
  };

  const handleAddUser = useCallback(() => {
    if (isAdmin) {
      setIsModalCreateOpen(true);
    } else {
      navigate('/users/invitation/create');
    }
  }, [isAdmin, navigate]);

  return (
    <>
      <Row gap={1} justify="flex-end">
        <InputSearch
          onSearch={({ value }) => onSearch(value)}
          onClear={() => onSearch('')}
          name="invitationsSearch"
          value={(getInvitationsFilter.q && decodeURIComponent(getInvitationsFilter.q)) || ''}
          placeholder="Search in Invitations"
          isDisabled={getInvitationsIsToolbarDisabled}
        />

        <Filter
          data={filterPanelData}
          selected={filter || ''}
          onSelect={onFiltering}
          isDisabled={getInvitationsIsToolbarDisabled}
        />

        <Sort
          data={sortData}
          selected={selected}
          onSelect={onSorting}
          onClear={onSortingClear}
          isDisabled={getInvitationsIsToolbarDisabled}
        />

        {allowAddUser() && (
          <StyledButton
            name="add_user_button_invitation"
            variant={ButtonVariant.contained}
            onClick={handleAddUser}
            isDisabled={getInvitationsIsLoading || getInvitationsError !== null}
          >
            Add User&nbsp;&nbsp;
            <Icon icon="plus" />
          </StyledButton>
        )}
      </Row>
      <ModalCreate
        setShowModal={setIsModalCreateOpen}
        showModal={isModalCreateOpen}
        team="snappt"
      />
    </>
  );
};

InvitationsFilters.propTypes = {
  filter: PropTypes.string,
  setInvitationFilters: PropTypes.func
};

export default InvitationsFilters;
