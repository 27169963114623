//Remove oldColumns when Income_Verification feature flag is removed from code
export const oldColumns = [
  { name: 'unit', label: 'Unit', width: 150 },
  { name: 'received', label: 'Received', width: 120 },
  { name: 'status', label: 'Report Status', width: 200 },
  { name: 'doc', label: '', width: 170 },
  { name: 'result', label: 'Result', width: 270 },
  { name: 'doc_number', label: '', width: 120 },
  { name: 'doc_download', label: 'Docs', width: 60 }
];

export const columns = [
  { name: 'unit', label: 'Unit', width: 75 },
  { name: 'received', label: 'Received', width: 120 },
  { name: 'status', label: 'Report Status', width: 120 },
  { name: 'report', label: 'View', width: 50 },
  { name: 'result', label: 'Fraud Result', width: 220 },
  { name: 'monthly_gross_income', label: 'Monthly Gross Income', width: 200 },
  { name: 'documents', label: 'Documents', width: 100 }
];
