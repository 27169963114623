import { ThemeAttributes } from 'src/ts/interfaces/styled-components';
import styled from 'styled-components/macro';
import { ButtonIconProps } from '.';

const getBackground = (background: string, theme: ThemeAttributes, disabled?: boolean) => {
  const disabledBackground = {
    normal: theme.color.gray[300],
    hover: theme.color.gray[300]
  };

  switch (background) {
    case 'primary':
      return disabled
        ? disabledBackground
        : {
            normal: theme.color.blue[700],
            hover: theme.color.blue[600]
          };
    case 'secondary':
      return disabled
        ? disabledBackground
        : {
            normal: theme.color.gray[500],
            hover: theme.color.gray[300]
          };
    case 'none':
      return { hover: 'none', normal: 'none' };
    default:
      return { hover: 'none', normal: 'none' };
  }
};

export const StyledButton = styled.button<Omit<ButtonIconProps, 'icon'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  background: ${({ background, theme, disabled }) => {
    const bg = getBackground(background, theme, disabled);
    return bg.normal;
  }};
  border-radius: ${({ isCircle }) => (isCircle ? '50%' : '0.25rem')};
  padding-top: 0.2rem;
  padding-right: ${({ label = '' }) => (label.length > 0 ? '0.375rem' : '0.1rem')};
  padding-left: ${({ label = '' }) => (label.length > 0 ? '0.063rem' : '0.1rem')};
  height: 30px;
  width: 30px;
  &:hover {
    background: ${({ background, theme, disabled }) => {
      const bg = getBackground(background, theme, disabled);
      return bg.hover;
    }};
  }
  & svg {
    color: ${({ background, theme, disabled }) => {
      return disabled && background === 'none' ? theme.color.gray[300] : 'inherit';
    }};
  }
`;

export const StyledLabel = styled.span`
  font-family: 'Roboto';
  font-size: 0.875rem;
`;
