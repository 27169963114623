import styled from 'styled-components/macro';

export const StepsFlowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .Step__container {
    position: relative;
    flex: 1;
  }

  .Step__container::after {
    position: absolute;
    content: '';
    width: 100%;
    top: 12px;
    left: -50%;
    z-index: 0;
  }

  .Step__container_disabled::after,
  .Step__container_uncompleted::after {
    border-bottom: 2px solid ${({ theme }) => theme.color.gray[100]};
  }

  .Step__container_completed::after,
  .Step__container_current::after,
  .Step__container_disabled_active::after {
    border-bottom: 2px solid ${({ theme }) => theme.color.blue[700]};
  }

  .Step__container:first-child::before,
  .Step__container:first-child::after {
    content: none;
  }

  .Step__circle {
    z-index: 1;
  }
`;
