import { FC } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { ButtonDisabledStyle, ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { downloadUrlsToZip } from 'src/helpers';
import { StyledButton } from './styles';

export interface ButtonDownloadFilesProps {
  urls: string[];
  fileName: string;
  isDisabled: boolean;
  className?: string;
}

const ButtonDownloadFiles: FC<ButtonDownloadFilesProps> = (props) => {
  const { isDisabled, fileName, urls, className } = props;
  const { execute: downloadZip, loading: isLoading } = useAsyncCallback(downloadUrlsToZip);

  return (
    <StyledButton
      name={`download_files_${fileName}_button`}
      isDisabled={isLoading || isDisabled}
      isLoading={isLoading}
      onClick={() => !isDisabled && downloadZip(urls, fileName)}
      variant={ButtonVariant.ghost}
      disabledStyle={ButtonDisabledStyle.transparent}
      className={className}
    >
      {!isLoading && <Icon icon="download-icon" color="blue700" />}
    </StyledButton>
  );
};

export default ButtonDownloadFiles;
