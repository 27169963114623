import { FC, HTMLAttributes } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ButtonVariant } from 'src/components/atoms/Button';
import { Row } from 'src/components/atoms/Row';
import Text, { TextVariant } from 'src/components/atoms/Text';

import {
  ActionSection,
  CompanyIcon,
  PersonIcon,
  StyledButton,
  StyledCard,
  StyledInputText,
  StyledRowInfoSection
} from './styles';

interface FormValues {
  folderName?: string;
}

export interface FolderTileEditProps extends HTMLAttributes<HTMLDivElement> {
  onConfirm: SubmitHandler<FormValues>;
  onCancel: () => void;
  propertyName: string;
  defaultValues: FormValues;
}

const FolderTileEdit: FC<FolderTileEditProps> = (props) => {
  const { onConfirm, onCancel, propertyName, defaultValues, ...htmlAttrs } = props;
  const formProps = useForm({
    mode: 'onChange',
    defaultValues
  });

  const {
    handleSubmit,
    watch,
    formState: { isDirty }
  } = formProps;

  return (
    <FormProvider {...formProps}>
      <StyledCard rounded border {...htmlAttrs}>
        <StyledRowInfoSection>
          <Row alignItems="center">
            <PersonIcon />
            <StyledInputText name="folderName" placeholder="Folder Name" />
          </Row>
          <Row alignItems="center">
            <CompanyIcon />
            <Text variant={TextVariant.h4} isBold={false} color="gray500">
              {propertyName}
            </Text>
          </Row>
        </StyledRowInfoSection>

        <ActionSection>
          <StyledButton
            isDisabled={!isDirty && !watch('folderName')}
            onClick={handleSubmit(onConfirm)}
            name="confirm"
            variant={ButtonVariant.contained}
          >
            Confirm
          </StyledButton>
          <StyledButton onClick={onCancel} name="cancel" variant={ButtonVariant.outline}>
            Cancel
          </StyledButton>
        </ActionSection>
      </StyledCard>
    </FormProvider>
  );
};

export default FolderTileEdit;
