import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Buttons = styled.div`
  border: 0.063rem solid ${({ theme }) => theme.color.gray[300]};
  border-radius: 0.188rem;
  padding: 0.2rem;
  background: ${({ theme }) => theme.color.white};
  margin-top: 0.2rem;
  display: flex;
  width: max-content;

  button {
    margin-right: 0.5rem !important;
    &:last-of-type {
      margin-right: 0 !important;
    }
  }
`;
