import { TIME_ZONE } from './data/timezone';

const api = {
  getAll: () => {
    // In case we want to use an endpoint to get the Timezones just update the source
    return TIME_ZONE;
  }
};

export default api;
