import { useCallback } from 'react';

import { useAppDispatch } from 'src/store';
import { getPaginationRequest } from 'src/ts/interfaces';
import { getEntriesLogsThunk } from '../index';

const useGetEntriesLogs = () => {
  const dispatch = useAppDispatch();

  const onGetEntriesLogs = useCallback(
    (filters: getPaginationRequest) => dispatch(getEntriesLogsThunk(filters)),
    [dispatch]
  );

  return {
    onGetEntriesLogs
  };
};

export default useGetEntriesLogs;
