import { useParams, useNavigate } from 'react-router-dom';

import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import TextInsertionBlock from 'src/components/organisms/TextInsertionBlock';
import { useProofById } from 'src/features/proofs/hooks';
import { DetailView } from 'src/components/templates/DetailView';
import { Row } from 'src/components/atoms/Row';
import { ShowState } from 'src/components/molecules/ShowState';
import { Loader } from 'src/components/atoms/Loader';

const TextInsertionContent = () => {
  const { proofId } = useParams();
  const { proof, isLoading, isError } = useProofById(proofId as string);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Please try again"
        message="Something went wrong"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (!proof?.text_breakups || proof?.text_breakups?.length === 0) {
    return (
      <ShowState
        variant="empty"
        type="information"
        message="The document does not have information to show"
      />
    );
  }

  return (
    <Row direction="column" gap={1}>
      <Row.Col>
        {/*TODO: Show file name. Currently, there is no filename as part of the api response.*/}
        <Text color={TextColor.primary} variant={TextVariant.normal}>
          {proof.id}
        </Text>
      </Row.Col>
      <Row.Col>
        <Row direction="column" gap={2.5}>
          {proof.text_breakups.map((breakup, idx) => (
            <TextInsertionBlock key={idx} index={idx} breakup={breakup} proof={proof} />
          ))}
        </Row>
      </Row.Col>
    </Row>
  );
};

const TextInsertionPage = () => {
  const navigate = useNavigate();
  const { folderId } = useParams();

  return (
    <DetailView
      back={{
        label: 'Go Back',
        onClick: () => navigate(`/folder/${folderId}`)
      }}
      title="Text Insertion"
    >
      <TextInsertionContent />
    </DetailView>
  );
};

export default TextInsertionPage;
