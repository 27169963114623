import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { NAME } from './constants';
import { initialStateForm, StateDataForm } from './form/reducers';
import { IInformation } from './form/ts/interfaces';

const initialState = {
  information: initialStateForm.information,
  process: {
    ...initialStateForm.process
  }
};

const GetStartedSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setInformation: (state: StateDataForm, action: PayloadAction<IInformation>) => {
      state.information = action.payload;
    }
  }
});

export const selectors = {
  information: (state: RootState) => state.getStarted.information,
  getCurrentCompany: (state: RootState) => state.getStarted.process.getCurrentCompany,
  getCurrentUser: (state: RootState) => state.getStarted.process.getCurrentUser
};

export const { reducer, actions } = GetStartedSlice;
