// Components
import { FolderPageContent } from 'src/components/folders';
import { DetailView } from 'src/components/templates/DetailView';

const FolderPage = () => {
  return (
    <DetailView back={{ to: '/applications', label: 'Go Back' }} title="Folder">
      <FolderPageContent />
    </DetailView>
  );
};

export default FolderPage;
