import { combineReducers } from '@reduxjs/toolkit';
/**
 * RTK Query
 */
import { queryProofApi as QUERY_DUP_PROOF } from 'src/features/DUP/proofs/queryProof';

/**
 * RTK Slices
 */
import { reducer as DUPReducer, NAME as DUP_NAME } from 'src/features/DUP';
import {
  NAME as ANNOUNCEMENT_NAME,
  reducer as announcementReducer
} from 'src/features/announcement';
import { NAME as APP_VERSION_NAME, reducer as appVersionReducer } from 'src/features/appVersion';
import { NAME as AUTH_NAME, reducer as authReducer } from 'src/features/auth';
import { NAME as COMPANY_NAME, reducer as companyReducer } from 'src/features/company';
import { NAME as COUNTRY_NAME, reducer as countryReducer } from 'src/features/country';
import { NAME as ENTRIES_NAME, reducer as entriesReducer } from 'src/features/entries';
import { NAME as ENTRIES_LOGS_NAME, reducer as entriesLogsReducer } from 'src/features/entriesLogs';
import { NAME as FDE_REPORT_NAME, reducer as fdeReportReducer } from 'src/features/fde-report';
import { NAME as FDE_INBOX_NAME, reducer as fdeInboxReducer } from 'src/features/fdeInbox';
import { NAME as FEATURE_FLAG_NAME, reducer as featureFlagReducer } from 'src/features/featureFlag';
import { NAME as FOLDERS_NAME, reducer as foldersReducer } from 'src/features/folders';
import { NAME as FRAMEWORK_UI_NAME, reducer as frameworkUIReducer } from 'src/features/frameworkUI';
import { NAME as GET_STARTED_NAME, reducer as getStartedReducer } from 'src/features/getStartedUI';
import {
  NAME as IDENTITY_NAME,
  reducer as identityVerificationReducer
} from 'src/features/identityVerification';
import {
  NAME as INCOME_VERIFICATION_NAME,
  reducer as incomeVerificationReducer
} from 'src/features/incomeVerification';
import { NAME as INVITATION_NAME, reducer as invitationReducer } from 'src/features/invitation';
import {
  NAME as MATCHING_ENTRIES_NAME,
  reducer as matchingEntriesReducer
} from 'src/features/matchingEntries';
import { NAME as MY_WORK_NAME, reducer as myWorkReducer } from 'src/features/myWork';
import { NAME as PROOFS_NAME, reducer as proofsReducer } from 'src/features/proofs';
import { NAME as PROPERTY_NAME, reducer as propertyReducer } from 'src/features/property';
import {
  NAME as PROPERTY_FEATURE_NAME,
  reducer as propertyFeatureReducer
} from 'src/features/propertyFeature';
import { NAME as RESOURCES_NAME, reducer as resourcesReducer } from 'src/features/resources';
import { NAME as SERVER_INFO_NAME, reducer as serverInfoReducer } from 'src/features/serverInfo';
import { NAME as SETTING_NAME, reducer as settingReducer } from 'src/features/settings';
import { NAME as TEAMS_NAME, reducer as teamsReducer } from 'src/features/teams';
import { NAME as TIMEZONE_NAME, reducer as timezoneReducer } from 'src/features/timezone';
import { NAME as USER_NAME, reducer as userReducer } from 'src/features/user';
import { NAME as USERS_NAME, reducer as usersReducer } from 'src/features/users';

const rootReducer = combineReducers({
  [AUTH_NAME]: authReducer,
  [ANNOUNCEMENT_NAME]: announcementReducer,
  [SERVER_INFO_NAME]: serverInfoReducer,
  [APP_VERSION_NAME]: appVersionReducer,
  [FRAMEWORK_UI_NAME]: frameworkUIReducer,
  [DUP_NAME]: DUPReducer,
  [TEAMS_NAME]: teamsReducer,
  [FDE_INBOX_NAME]: fdeInboxReducer,
  [IDENTITY_NAME]: identityVerificationReducer,
  [INVITATION_NAME]: invitationReducer,
  [PROPERTY_NAME]: propertyReducer,
  [PROPERTY_FEATURE_NAME]: propertyFeatureReducer,
  [USER_NAME]: userReducer,
  [SETTING_NAME]: settingReducer,
  [TIMEZONE_NAME]: timezoneReducer,
  [COUNTRY_NAME]: countryReducer,
  [GET_STARTED_NAME]: getStartedReducer,
  [USERS_NAME]: usersReducer,
  [COMPANY_NAME]: companyReducer,
  [FEATURE_FLAG_NAME]: featureFlagReducer,
  [RESOURCES_NAME]: resourcesReducer,
  [MY_WORK_NAME]: myWorkReducer,
  [FOLDERS_NAME]: foldersReducer,
  [ENTRIES_NAME]: entriesReducer,
  [MATCHING_ENTRIES_NAME]: matchingEntriesReducer,
  [PROOFS_NAME]: proofsReducer,
  [FDE_REPORT_NAME]: fdeReportReducer,
  [QUERY_DUP_PROOF.reducerPath]: QUERY_DUP_PROOF.reducer,
  [ENTRIES_LOGS_NAME]: entriesLogsReducer,
  [INCOME_VERIFICATION_NAME]: incomeVerificationReducer
});

export default rootReducer;
