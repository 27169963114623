// Components
import { ListView } from 'src/components/templates';
import CompanyList from './components/CompanyList';
import CompanyListFilters from './components/CompanyListFilters';
import CompanyListPagination from './components/CompanyListPagination';

const CompanyListPage = (): JSX.Element => (
  <ListView title="Companies" filters={<CompanyListFilters />}>
    <CompanyList />
    <CompanyListPagination />
  </ListView>
);

export default CompanyListPage;
