import Gap from 'src/components/atoms/Gap';
import Grid from 'src/components/atoms/Grid';
import { Row } from 'src/components/atoms/Row';
import SkeletonPiece, {
  SkeletonPieceAlign,
  SkeletonPieceColor
} from 'src/components/atoms/SkeletonPiece';
import { TextColor, TextVariant } from 'src/components/atoms/Text';
import { StyledRoundedSkeleton, StyledText } from './styles';

const CompanyDetailsLoader: React.FC = () => {
  return (
    <div id="companyListLoader">
      <Row>
        <Row.Col size={1}>
          <StyledText color={TextColor.initial} variant={TextVariant.h2}>
            Company Details
          </StyledText>
        </Row.Col>
        <Row.Col size={0.25}>
          <SkeletonPiece width={96} height={44} color={SkeletonPieceColor.gray200} />
        </Row.Col>
      </Row>
      <Gap height={2} />
      <Grid justify="space-between">
        <Grid>
          <Grid direction="column">
            <SkeletonPiece width={185} height={20} color={SkeletonPieceColor.gray200} />
            <Gap height={0.5} />
            {Array(3)
              .fill(0)
              .map((_, i) => {
                return (
                  <div key={i}>
                    <Grid>
                      <SkeletonPiece
                        width={582}
                        height={56}
                        align={SkeletonPieceAlign.left}
                        color={SkeletonPieceColor.gray50}
                      />
                    </Grid>
                    <Gap height={0.5} />
                  </div>
                );
              })}
          </Grid>
          <Grid justify="center">
            <StyledRoundedSkeleton>
              <SkeletonPiece
                width={120}
                height={120}
                color={SkeletonPieceColor.gray200}
                isRounded
              />
            </StyledRoundedSkeleton>
          </Grid>
        </Grid>
      </Grid>
      <Gap height={1.375} />
    </div>
  );
};

export default CompanyDetailsLoader;
