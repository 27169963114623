import { useAppDispatch } from 'src/store';
import { useCallback } from 'react';
import { getIdentityImagesThunk } from 'src/features/identityVerification';

export const useGetIdentityImages = () => {
  const dispatch = useAppDispatch();

  const onGetIdentityImages = useCallback(
    (verificationId: string) => dispatch(getIdentityImagesThunk(verificationId)),
    [dispatch]
  );

  return {
    onGetIdentityImages
  };
};
