import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { InputText, InputTextType } from 'src/components/atoms/InputText';
import SkeletonPiece, { SkeletonPieceColor } from 'src/components/atoms/SkeletonPiece';
import { StyledButtonComponent, StyledComponent, StyledInputComponent } from './styles';

type InputTextOnChangeProps = {
  field: string;
  value: string;
};

export type InputSearchProps = {
  name: string;
  value: string;
  placeholder: string;
  onSearch: (e: InputTextOnChangeProps) => void;
  onChange?: (e: InputTextOnChangeProps) => void;
  onClear?: () => void;
  isDisabled?: boolean;
  className?: string;
  isLoading?: boolean;
};

const InputSearch: React.FC<InputSearchProps> = (props) => {
  const {
    onSearch,
    onChange: onChanging,
    onClear: onClearing,
    isDisabled,
    name,
    value,
    placeholder,
    className,
    isLoading
  } = props;

  const [refresh, setRefresh] = useState(false);
  const [valueInput, setValueInput] = useState(value);

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      [name]: value
    }
  });

  const onSubmit = (data: any) => {
    // Trimming for empty spaces on input submit.
    const result = Object.keys(data).map((e) => ({ field: e, value: data[e]?.trim() }));
    setRefresh(!refresh);
    onSearch(result[0]);
  };

  if (isLoading) {
    return (
      <SkeletonPiece
        width={220}
        height={40}
        color={SkeletonPieceColor.gray50}
        className="noPadding"
      />
    );
  }

  return (
    <FormProvider {...methods} key={`${name}__${refresh}`}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <StyledComponent className={className}>
          <StyledInputComponent>
            <InputText
              name={name}
              placeholder={placeholder}
              value={encodeURIComponent(valueInput)}
              isDisabled={isDisabled}
              onChange={(e) => {
                setValueInput(e.value.toString());
                onChanging && onChanging({ field: e.field, value: e.value.toString() });
              }}
              type={InputTextType.text}
              showLabel={false}
              endAndorment={
                valueInput && (
                  <Button
                    onClick={() => {
                      methods.setValue(name, '');
                      onSearch({ field: name, value: '' });
                      setValueInput('');
                      setRefresh(!refresh);
                      onClearing && onClearing();
                    }}
                    className="end-andorment"
                    variant={ButtonVariant.ghost}
                    name={`${name}__close_button`}
                    type={ButtonType.button}
                    isDisabled={isDisabled}
                  >
                    <Icon icon="icon-close" />
                  </Button>
                )
              }
            />
          </StyledInputComponent>
          <StyledButtonComponent>
            <Button
              name={`${name}__search_button`}
              color={ButtonColor.primary}
              variant={ButtonVariant.contained}
              size={ButtonSize.medium}
              isLoading={false}
              isDisabled={isDisabled}
              type={ButtonType.submit}
            >
              <Icon icon="icon-search" />
            </Button>
          </StyledButtonComponent>
        </StyledComponent>
      </form>
    </FormProvider>
  );
};

InputSearch.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool
};

InputSearch.defaultProps = {
  name: 'search',
  value: '',
  placeholder: 'Search'
};

export default InputSearch;
