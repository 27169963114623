import { createAsyncThunk } from '@reduxjs/toolkit';

import { IEntry } from 'src/ts/interfaces';
import api from './api';
import { NAME } from './constants';

export const getEntriesByFolderIdThunk = createAsyncThunk(
  `${NAME}/getEntriesByFolderId`,
  async (folderId: string) => {
    try {
      return api.getEntriesByFolderId(folderId);
    } catch (error) {
      console.error(`${NAME}/getEntriesByFolderId`, error);
      throw new Error('Failed to get entries');
    }
  }
);

export const getEntryByIdThunk = createAsyncThunk(
  `${NAME}/getEntryById`,
  async (entryId: string) => {
    try {
      return api.getEntryById(entryId);
    } catch (error) {
      console.error(`${NAME}/getEntryById`, error);
      throw new Error('Failed to get entry');
    }
  }
);

export const updateEntryThunk = createAsyncThunk(
  `${NAME}/updateEntry`,
  async ({ id, ...entry }: { id: string } & Partial<IEntry>) => {
    try {
      return api.updateEntry(id, entry);
    } catch (error) {
      console.error(`${NAME}/updateEntry`, error);
      throw new Error('Failed to update entry');
    }
  }
);

export const splitEntryThunk = createAsyncThunk(`${NAME}/splitEntry`, async (entryId: string) => {
  try {
    return api.splitEntry(entryId);
  } catch (error) {
    console.error(`${NAME}/splitEntry`, error);
    throw new Error('Failed to split entry');
  }
});

export const mergeEntryThunk = createAsyncThunk(
  `${NAME}/mergeEntry`,
  async ({ matchingEntryId, entryId }: { entryId: string; matchingEntryId: string }) => {
    try {
      return api.mergeEntry(matchingEntryId, entryId);
    } catch (error) {
      console.error(`${NAME}/mergeEntry`, error);
      throw new Error('Failed to merge entry');
    }
  }
);

export const sendReportThunk = createAsyncThunk(`${NAME}/sendReport`, async (entryId: string) => {
  try {
    return api.sendReport(entryId);
  } catch (error) {
    console.error(`${NAME}/sendReport`, error);
    throw new Error('Failed to send report');
  }
});

export const requestDocumentsThunk = createAsyncThunk(
  `${NAME}/requestDocuments`,
  async ({ id, message }: { id: string; message: string }) => {
    try {
      return api.requestDocuments(id, message);
    } catch (error) {
      console.error(`${NAME}/requestDocuments`, error);
      throw new Error('Failed to request documents');
    }
  }
);
