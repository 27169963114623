import querystring from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row } from 'src/components/atoms/Row';
import { LoaderResourceList } from 'src/components/molecules/LoaderResourceList';
import MultiTab, {
  TabBody,
  TabContent,
  TabHeader,
  TabTitle
} from 'src/components/molecules/MultiTab/MultiTab';
import { RowResourceList } from 'src/components/molecules/RowResourceList';
import { ShowState } from 'src/components/molecules/ShowState';
import useResources from 'src/features/resources/hooks/useResources';
import { IResource } from 'src/ts/interfaces/resource';

const ResourceList: React.FC = () => {
  const { onGetResources, getResources } = useResources();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabActive, setTabActive] = useState(0);

  useEffect(() => {
    if (getResources.isIdle) {
      onGetResources();
    }
    const queryParams = querystring.parse(location.search);
    const tab = queryParams.tab as string;
    if (tab === 'idv') {
      setTabActive(1);
    } else {
      setTabActive(0);
    }
  }, [onGetResources, getResources, location.search]);

  const handleSelect = (index: number) => {
    setTabActive(index);
    const newQueryParams = {
      ...querystring.parse(location.search),
      tab: index === 1 ? 'idv' : 'fraud-detection'
    };
    navigate({
      pathname: location.pathname,
      search: querystring.stringify(newQueryParams)
    });
  };

  if (getResources.error) {
    return (
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Please try again"
        message="Something went wrong"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (getResources.isLoading) {
    return <LoaderResourceList />;
  }

  const ResourceTab = (props: { data: IResource[] }) => {
    return (
      <Row columns={3} gap={1.5} wrap={'wrap'} justify="center">
        {props.data.map((resource: IResource) => {
          return (
            <RowResourceList
              key={resource.id}
              title={resource.title}
              date={resource.date}
              description={resource.description}
              file={resource.link}
              toPreview={`./${resource.id}`}
              isDownloadable={resource.type === 'pdf'}
            />
          );
        })}
      </Row>
    );
  };

  const FRAUD_DETECTION_IDS: IResource['id'][] = [
    'FDE_Results_Key',
    'DUP_Error_Messages',
    'Dispute_FAQ',
    'Snappt_FAQ',
    'How_to_properly_download_documents',
    'Snappt_Training_Video',
    'Snappt_Tips_Tricks'
  ];
  const IDV_IDS: IResource['id'][] = [
    'Snappt_IDV_FAQ',
    'Snappt_IDV_Terms_To_Know',
    'Snappt_IDV_Training_Video',
    'Snappt_IDV_Supported_Document_Types'
  ];

  return (
    <MultiTab name="resourcesTab" selectedIndex={tabActive} hasSpacing onSelect={handleSelect}>
      <TabHeader aria-label="Fraud Detection Tab">
        <TabTitle>Fraud Detection</TabTitle>
        <TabTitle>IDV</TabTitle>
      </TabHeader>
      <TabBody>
        <TabContent>
          <ResourceTab
            data={(getResources.data || []).filter((d) => FRAUD_DETECTION_IDS.includes(d.id))}
          />
        </TabContent>
      </TabBody>
      <TabBody>
        <TabContent>
          <ResourceTab data={(getResources.data || []).filter((d) => IDV_IDS.includes(d.id))} />
        </TabContent>
      </TabBody>
    </MultiTab>
  );
};

export default ResourceList;
