import PropTypes from 'prop-types';
import { FC, HTMLAttributes } from 'react';
import { TagComponent } from './styles';

export enum TagColor {
  blue = 'blue',
  green = 'green',
  red = 'red',
  yellow = 'yellow',
  gray = 'gray',
  snappt_blue = 'snappt_blue',
  white = 'white'
}

export interface TagProps extends HTMLAttributes<HTMLSpanElement> {
  label: string;
  showBackground: boolean;
  color: TagColor;
}

const Tag: FC<TagProps> = (props) => {
  const { label, showBackground, color, ...htmlAttrs } = props;
  return (
    <TagComponent {...htmlAttrs} showBackground={showBackground} color={color}>
      {label}
    </TagComponent>
  );
};

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  showBackground: PropTypes.bool.isRequired,
  color: PropTypes.oneOf<TagColor>(Object.values(TagColor)).isRequired,
  className: PropTypes.string
};

Tag.defaultProps = {
  label: 'This is a Label',
  showBackground: true,
  color: TagColor.blue,
  className: ''
};

export default Tag;
