import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { NAME } from './constants';
import {
  changeSettingNotificationReducer,
  getAllReducer,
  getByEmailReducer,
  getOneReducer,
  getUnassignedPropertiesReducer,
  initialState,
  removeReducer,
  StateData,
  updateReducer
} from './reducers';

const usersSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetGetAll: (state: StateData) => {
      state.getAll = initialState.getAll;
    },
    resetSave: (state: StateData) => {
      state.update = initialState.update;
    },
    resetRemove: (state: StateData) => {
      state.remove = initialState.remove;
    },
    resetGetByEmail: (state: StateData) => {
      state.getByEmail = initialState.getByEmail;
    },
    resetGetUnassignedProperties: (state: StateData) => {
      state.getUnassignedProperties = initialState.getUnassignedProperties;
    },
    resetGetOne: (state: StateData) => {
      state.getOne = initialState.getOne;
    }
  },
  extraReducers: (builder) => {
    getAllReducer(builder);
    getOneReducer(builder);
    getByEmailReducer(builder);
    updateReducer(builder);
    removeReducer(builder);
    changeSettingNotificationReducer(builder);
    getUnassignedPropertiesReducer(builder);
  }
});

export const selectors = {
  getByEmail: (state: RootState) => state[NAME].getByEmail,
  getUnassignedProperties: {
    isLoading: (state: RootState) =>
      state[NAME].getUnassignedProperties.status === StatusTypes.LOADING,
    isIdle: (state: RootState) => state[NAME].getUnassignedProperties.status === StatusTypes.IDLE,
    error: (state: RootState) => state[NAME].getUnassignedProperties.error,
    data: (state: RootState) => state[NAME].getUnassignedProperties.data || [],
    filter: (state: RootState) => state[NAME].getUnassignedProperties.filter,
    count: (state: RootState) => state[NAME].getUnassignedProperties.count || 0
  },
  getAll: {
    isLoading: (state: RootState) => state[NAME].getAll.status === StatusTypes.LOADING,
    isIdle: (state: RootState) => state[NAME].getAll.status === StatusTypes.IDLE,
    error: (state: RootState) => state[NAME].getAll.error,
    data: (state: RootState) => state[NAME].getAll.data || initialState.getAll.data,
    filter: (state: RootState) => state[NAME].getAll.filter || initialState.getAll.filter,
    count: (state: RootState) => state[NAME].getAll.count || 0,
    isToolbarDisabled: (state: RootState) => {
      const stateGetAll = state[NAME].getAll;
      return stateGetAll.status === StatusTypes.LOADING || stateGetAll.error !== null;
    }
  },
  getUser: {
    isLoading: (state: RootState) => state[NAME].getOne.status === StatusTypes.LOADING,
    error: (state: RootState) => state[NAME].getOne.error,
    data: (state: RootState) => state[NAME].getOne.data
  },
  update: {
    isLoading: (state: RootState) => state[NAME].update.status === StatusTypes.LOADING,
    isSaved: (state: RootState) => state[NAME].update.status === StatusTypes.SUCCESS,
    error: (state: RootState) => state[NAME].update.error
  },
  remove: {
    isLoading: (state: RootState) => state[NAME].remove.status === StatusTypes.LOADING,
    isRemoved: (state: RootState) => state[NAME].remove.status === StatusTypes.SUCCESS,
    error: (state: RootState) => state[NAME].remove.error
  }
};

export const { reducer, actions } = usersSlice;
