import styled from 'styled-components/macro';

export const Container = styled.fieldset`
  border: 0.125rem solid ${({ theme }) => theme.color.gray[200]};
  padding: 0.313rem;
  border-radius: 0.25rem;
  margin-bottom: 1.7rem;
  min-height: 57px;
`;

export const Label = styled.legend`
  ${({ theme }) => theme.font.small};
  ${({ theme }) => theme.font.colors.gray500};
`;

export const Content = styled.div<{ height?: number | string }>`
  ${({ theme }) => theme.font.normal};
  height: ${({ height }) =>
    typeof height === 'string' ? height : height ? height + 'rem' : '26px'};
  overflow: auto;
  margin-left: 0.12rem;
`;
