// Vendor
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import { Row } from 'src/components/atoms/Row';
import { MetadataModal } from 'src/components/folders/MetadataModal';
import CardSelectDoc from 'src/components/molecules/CardSelectDoc';
import LoaderCardSelectDoc from 'src/components/molecules/LoaderCardSelectDoc';
import NotesModal from './NotesModal';

// Redux
import { selectors as entriesSelectors } from 'src/features/entries/entriesSlice';
import { getProofTextBreakupThunk } from 'src/features/proofs';
import { RootState } from 'src/store';

// Enums

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';
import { useProofGetNote, useProofUpdateState } from 'src/features/proofs/hooks';

// Types
import { EntryResultType, IProof } from 'src/ts/interfaces';

type CardSelectDocListProps = {
  isReviewDisabled?: boolean;
  entryId: string;
  folderId: string;
  isLoading: boolean;
  proofs: IProof[];
  cleanProceedWithCautionFlagEnabled?: boolean;
};

const CardSelectDocList: FC<CardSelectDocListProps> = ({
  isReviewDisabled,
  isLoading,
  proofs,
  entryId,
  folderId,
  cleanProceedWithCautionFlagEnabled
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showModalNotes, setShowModalNotes] = useState(false);
  const [showModalMetadata, setShowModalMetadata] = useState(false);
  const [proofSelected, setProofSelected] = useState<IProof | undefined>(undefined);

  const { onCheckProof } = useProofUpdateState();
  const { onGetNote } = useProofGetNote();

  const { isAdmin, isFraudAnalyst, isSeniorFraudAnalyst } = useRole();

  // Effect to update the selected proof if the data changes
  // For example, when we get async data from the backend like the text breakups
  useEffect(() => {
    if (proofSelected) {
      const proof = proofs.find((proof) => proof.id === proofSelected.id);
      setProofSelected(proof);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proofs]);

  const canSeeProofDetail = isAdmin || isFraudAnalyst || isSeniorFraudAnalyst;

  //todo: convert to launch darkly feature flag in the future if feature gets assigned priority
  const flagProofDetailEnabled = false;

  const entry = useSelector((state: RootState) => entriesSelectors.selectById(state, entryId));

  if (isLoading) {
    return <LoaderCardSelectDoc />;
  }

  return (
    <Row gap={1.5} wrap="wrap" justify="flex-start">
      {proofs?.map((proof) => {
        const {
          id,
          result,
          thumb,
          file,
          isChecked,
          isLoading,
          fileName,
          short_id: shortId
        } = proof;
        return (
          <CardSelectDoc
            key={id}
            id={id}
            isReviewDisabled={isReviewDisabled}
            isChecked={Boolean(isChecked)}
            showDetail={canSeeProofDetail && flagProofDetailEnabled}
            onShowDetail={() => navigate(`/my-work/${folderId}/${entryId}/${id}`)}
            showMetadata
            onShowMetadata={() => {
              // Get text breakups if not already loaded
              if (!proof.text_breakups) {
                dispatch(
                  getProofTextBreakupThunk({
                    id: proof.id,
                    text_breakups_file: proof.text_breakups_file
                  })
                );
              }
              setProofSelected(proof);
              setShowModalMetadata(true);
            }}
            showNotes
            onShowNotes={() => {
              setProofSelected(proof);
              setShowModalNotes(true);
            }}
            showDownload
            isLoading={Boolean(isLoading)}
            result={result as EntryResultType}
            onGetSelected={({ id, isChecked }) => onCheckProof(id, isChecked)}
            thumbnailURL={thumb}
            fileURL={file}
            fileName={entry?.applicant.full_name + '_' + (fileName || '')}
            shortId={`Doc ID: ${shortId}`}
            cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
          />
        );
      })}
      {showModalMetadata && proofSelected && (
        <MetadataModal
          title="Document Extractions"
          labelExtractMetadata="Document Metadata"
          labelAutomatedTest="Steve Automated Tests"
          showTextInsertion={true}
          showModal={showModalMetadata}
          setShowModal={setShowModalMetadata}
          proof={proofSelected}
          extracted_meta={proofSelected?.extracted_meta}
          test_meta_data_flags={proofSelected?.test_meta_data_flags}
        />
      )}

      {showModalNotes && proofSelected && (
        <NotesModal
          title="Public Document Notes"
          showModal={showModalNotes}
          setShowModal={setShowModalNotes}
          defaultNote={onGetNote(proofSelected.result, proofSelected?.note) || ''}
          showCloseButton
          isReadonly
          showModalActions={false}
        />
      )}
    </Row>
  );
};

export default CardSelectDocList;
