import Text from 'src/components/atoms/Text';
import styled from 'styled-components';

export const StyledRoundedSkeleton = styled.div`
  margin-top: 1.5rem;
`;

export const StyledText = styled(Text)`
  padding-left: 0.8rem;
`;
