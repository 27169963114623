import { createAsyncThunk } from '@reduxjs/toolkit';

import api from './api';
import { NAME } from './constants';

export const getFeatureFlagThunk = createAsyncThunk(
  `${NAME}/getFeatureFlag`,
  async ({ featureFlagKey, filter }: { featureFlagKey: string; filter: string }) => {
    try {
      return api.getFeatureFlag(featureFlagKey, filter);
    } catch (error) {
      console.error(`${NAME}/getFeatureFlag`, error);
      throw new Error(`Failed to retrieve the feature flag ${featureFlagKey}`);
    }
  }
);

export const getUnauthenticatedFeatureFlagThunk = createAsyncThunk(
  `${NAME}/getUnauthenticatedFeatureFlag`,
  async ({ featureFlagKey, filter }: { featureFlagKey: string; filter: string }) => {
    try {
      return api.getUnauthenticatedFeatureFlag(featureFlagKey, filter);
    } catch (error) {
      console.error(`${NAME}/getUnauthenticatedFeatureFlag`, error);
      throw new Error(`Failed to retrieve the feature flag ${featureFlagKey}`);
    }
  }
);
