import { FC, HTMLAttributes } from 'react';
import { StyledTableRow } from './styles';

type TableRowProps = HTMLAttributes<HTMLTableRowElement>;

const TableRow: FC<TableRowProps> = (props) => {
  return <StyledTableRow {...props} />;
};

export default TableRow;
