import { useCallback } from 'react';
import { useAppDispatch } from 'src/store';
import { getFeatureFlagThunk, getUnauthenticatedFeatureFlagThunk } from '../index';

export const useGetFeatureFlag = () => {
  const dispatch = useAppDispatch();

  const onGetFeatureFlagValue = useCallback(
    (featureFlagKey: string, filter: string) =>
      dispatch(getFeatureFlagThunk({ featureFlagKey, filter })),
    [dispatch]
  );

  const onGetUnauthenticatedFeatureFlagValue = useCallback(
    (featureFlagKey: string, filter: string) =>
      dispatch(getUnauthenticatedFeatureFlagThunk({ featureFlagKey, filter })),
    [dispatch]
  );

  return {
    onGetFeatureFlagValue,
    onGetUnauthenticatedFeatureFlagValue
  };
};
