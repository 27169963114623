// Components
import { Checkbox } from 'src/components/atoms/Checkbox';
import Text, { TextColor } from 'src/components/atoms/Text/';
import { ChoicesContainer } from './styles';

// Types
import { CheckboxType } from 'src/components/atoms/Checkbox/types';

const ChoicesBox = ({
  name,
  isLoading,
  data,
  dataSelected,
  totalRows,
  onSelected
}: {
  name: string;
  isLoading: boolean;
  data: CheckboxType[];
  dataSelected: CheckboxType[];
  totalRows?: number;
  onSelected: (content: boolean) => void;
}) => {
  const totalAvailable = totalRows !== undefined ? totalRows : data.length;
  const isCheckAll =
    Boolean(data.length && dataSelected.length) && dataSelected.length === totalAvailable;

  return (
    <ChoicesContainer>
      <Checkbox
        name={`choices_${name}`}
        value="all"
        label="Choices"
        isNull={dataSelected.length > 0 && dataSelected.length !== data.length}
        isDisabled={data.length === 0 || isLoading}
        isChecked={isCheckAll}
        onClick={(obj) => {
          onSelected(obj.isChecked);
        }}
      />
      <Text color={TextColor.muted} className="textChoicesSelected">
        {`${dataSelected.length}/${totalRows || data.length} selected`}
      </Text>
    </ChoicesContainer>
  );
};

export default ChoicesBox;
