import { TagColor } from 'src/components/atoms/Tag';
import { IdentityApplicantsGet200ResponseInnerStatusEnum } from 'src/lib/internal/epa/gen';

export const getIdentityVerificationResultStatusColorTag = (
  status?: IdentityApplicantsGet200ResponseInnerStatusEnum
) => {
  switch (status) {
    case IdentityApplicantsGet200ResponseInnerStatusEnum.Pass:
      return TagColor.green;
    case IdentityApplicantsGet200ResponseInnerStatusEnum.NeedsReview:
    case IdentityApplicantsGet200ResponseInnerStatusEnum.Reviewing:
      return TagColor.yellow;
    case IdentityApplicantsGet200ResponseInnerStatusEnum.Fail:
      return TagColor.red;
    default:
      return TagColor.gray;
  }
};
