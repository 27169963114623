import { useSelector } from 'react-redux';
import { CheckboxData } from 'src/components/atoms/Checkbox/types';
import { selectors as authSelector } from 'src/features/auth/authSlice';
import useRole from 'src/features/auth/hooks/useUserRoles';
import { actions, selectors as teamsSelector } from 'src/features/teams/teamsSlice';
import store, { useAppDispatch } from 'src/store';
import { Roles as Role } from 'src/ts/enums';
import { ITeam, getPaginationRequest } from 'src/ts/interfaces';
import {
  create,
  getAll,
  getAllFA,
  getAllFDEManagers,
  getAllSFA,
  getAllTeamsPerformance,
  getOne,
  update
} from '../services';

const initialRequestData = {
  q: '',
  rowsPerPage: 300,
  page: 0
};

const useTeams = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(authSelector.user);
  const { canSeeTeams } = useRole();

  const onGetAll = () => {
    if (canSeeTeams) {
      const currentFilter = teamsSelector.getAll.filter(store.getState());
      dispatch(getAll(currentFilter));
    }
  };

  const onResetGetAll = () => {
    dispatch(actions.resetGetAll());
  };

  const onGetOne = (id?: string) => {
    if (!id) throw new Error("Is missing the user identifier to get the team's information");
    dispatch(getOne(id));
  };

  const onResetGetOne = () => {
    dispatch(actions.resetGetOne());
  };

  const onCreate = (dataForm: ITeam) => {
    dispatch(create(dataForm));
  };

  const onResetCreate = () => {
    dispatch(actions.resetCreate());
  };

  const onUpdate = (dataForm: ITeam) => {
    dispatch(update(dataForm));
  };

  const onResetUpdate = () => {
    dispatch(actions.resetUpdate());
  };

  const onGetAllTeamsPerformance = (args: getPaginationRequest) => {
    dispatch(getAllTeamsPerformance(args));
  };

  const onResetGetAllTeamsPerformance = () => {
    dispatch(actions.resetGetAllTeamsPerformance());
  };

  const allowCreateTeam = () => {
    if (!user?.role) return false;
    if ([Role.Admin, Role.FdeManager].includes(user?.role)) return true;
    return false;
  };

  const onGetAllFDEManagers = (q?: string) => {
    const search = q ? decodeURIComponent(q) : '';
    const filter = `role@>${Role.FdeManager}`;

    dispatch(getAllFDEManagers({ filter, q: search }));
  };

  const onResetGetAllFDEManagers = () => {
    dispatch(actions.resetGetFDEManagers());
  };

  const onGetAllSFA = (assigned: CheckboxData[], args?: typeof initialRequestData) => {
    dispatch(getAllSFA(getFilterMembers(assigned, Role.SeniorFraudAnalyst, args)));
  };

  const onResetGetAllSFA = () => {
    dispatch(actions.resetGetAllSFA());
  };

  const onGetAllFA = (assigned: CheckboxData[], args?: typeof initialRequestData) => {
    dispatch(getAllFA(getFilterMembers(assigned, Role.FraudAnalyst, args)));
  };

  const onResetGetAllFA = () => {
    dispatch(actions.resetGetAllFA());
  };

  const getFilterMembers = (
    assigned: CheckboxData[],
    memberType: Role.SeniorFraudAnalyst | Role.FraudAnalyst,
    args?: typeof initialRequestData
  ) => {
    const { q, rowsPerPage, page } = args ? args : initialRequestData;
    const search = q ? decodeURIComponent(q) : '';
    const getAssignedIds = () => {
      if (!assigned || assigned?.length === 0) return '';
      return `,id!@>${assigned.map((row) => row.value).join('|')}`;
    };

    const filter = `role@>${memberType}${getAssignedIds()}`;
    return { filter, q: search, rowsPerPage, page: search ? 0 : page };
  };

  return {
    onGetAll,
    onResetGetAll,
    onGetOne,
    onResetGetOne,
    onGetAllFDEManagers,
    onResetGetAllFDEManagers,
    onCreate,
    onResetCreate,
    onUpdate,
    onResetUpdate,
    allowCreateTeam,
    onGetAllSFA,
    onResetGetAllSFA,
    onGetAllFA,
    onResetGetAllFA,
    onGetAllTeamsPerformance,
    onResetGetAllTeamsPerformance
  };
};

export default useTeams;
