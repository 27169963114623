import {
  IncomeCalculationErrorResponse,
  IncomeCalculationWarningResponseResults
} from '@snapptinc/fraud-platform';

export type ReasonCodes =
  | IncomeCalculationErrorResponse['reason_codes']
  | IncomeCalculationWarningResponseResults['reason_codes'];

type IconColors = 'black' | 'red' | 'yellow';

const getIconColor = (
  reasonCodes: ReasonCodes,
  errorCodes: number[],
  warningCodes: number[] = []
): IconColors => {
  if (!reasonCodes || reasonCodes.length === 0) {
    return 'black';
  }
  if (reasonCodes.some((code) => errorCodes.includes(code))) {
    return 'red';
  }
  //todo: put back once we have decided on an accessible color post beta
  // if (reasonCodes.some((code) => warningCodes.includes(code))) {
  //   return 'yellow';
  // }
  return 'black';
};

export const getCalendarIconColor = (reasonCodes: ReasonCodes) =>
  getIconColor(reasonCodes, [3004, 3005, 3007], [2006]);

export const getPersonIconColor = (reasonCodes: ReasonCodes) =>
  getIconColor(reasonCodes, [3002, 3003]);

export const getGrosspayIconColor = (reasonCodes: ReasonCodes) =>
  getIconColor(reasonCodes, [3001], [2000]);

export const getIncomeSourceIconColor = (reasonCodes: ReasonCodes) =>
  getIconColor(reasonCodes, [], [2008]);
