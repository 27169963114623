// Vendor
import { useNavigate } from 'react-router-dom';

// Components
import Avatar from 'src/components/atoms/Avatar';
import ButtonIcon from 'src/components/molecules/ButtonIcon';
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor } from 'src/components/atoms/Text';

// Styles
import { StyledCard, StyledCompanyAddress, StyledPropertyCount, StyledReactSVG } from '../styles';

// Types
import { Company } from '@snapptinc/fraud-platform/api';

const getPropertyCountString = (propertyCount: number) => {
  return `${propertyCount} ${propertyCount > 1 ? 'properties' : 'property'}`;
};

const CompanyListRow = ({ company }: { company: Company }): JSX.Element => {
  const navigate = useNavigate();

  const { name, address, city, state, zip, logo, property_count: propertyCount } = company;
  const logoUrl = logo || `${process.env.PUBLIC_URL}/assets/images/default-logo.svg`;
  const propertySvg = `${process.env.PUBLIC_URL}/assets/images/property.svg`;

  const Properties = ({ propertyCount }: { propertyCount?: number }): JSX.Element => {
    return (
      <Row alignItems="center" style={{ height: '1.5rem' }}>
        <StyledReactSVG src={propertySvg} />
        {propertyCount ? (
          <StyledPropertyCount color={TextColor.gray700}>
            {getPropertyCountString(propertyCount)}
          </StyledPropertyCount>
        ) : (
          <StyledPropertyCount color={TextColor.gray300}>
            No properties assigned
          </StyledPropertyCount>
        )}
      </Row>
    );
  };

  return (
    <>
      <Line height="tiny" />
      <StyledCard>
        <Row>
          <Row.Col>
            <Avatar src={logoUrl} alt={name} />
          </Row.Col>
          <Row.Col data-testid="companies-list-company-name" style={{ flex: 1, marginLeft: 16 }}>
            <Text isBold>{name}</Text>
            <StyledCompanyAddress
              color={TextColor.gray700}
            >{`${address}, ${city}, ${state} ${zip}`}</StyledCompanyAddress>
            <Properties propertyCount={propertyCount} />
          </Row.Col>
          <Row.Col>
            <ButtonIcon
              background="none"
              name="gear icon"
              icon="gear"
              onClick={() => navigate(`/companies/${company.id}/edit`)}
              iconColor="primary"
            />
          </Row.Col>
        </Row>
      </StyledCard>
    </>
  );
};

export default CompanyListRow;
