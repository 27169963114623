import { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import DatePicker from './DatePicker';
import TypeableDatePicker from './TypeableDatePicker';
import { DatePickerProps } from './types';
interface ControlledDatePickerProps
  extends UseControllerProps,
    Omit<DatePickerProps, 'name' | 'onChange'> {}

const ControlledDatePicker: FC<ControlledDatePickerProps> = (props) => {
  const { name, control, defaultValue, rules, shouldUnregister, mode, ...datePickerProps } = props;

  const {
    field: { value, onChange }
  } = useController({ name, control, defaultValue, rules, shouldUnregister });

  if (mode === 'type') {
    return (
      <TypeableDatePicker {...datePickerProps} name={name} value={value} onChange={onChange} />
    );
  }

  return <DatePicker {...datePickerProps} name={name} value={value} onChange={onChange} />;
};

ControlledDatePicker.displayName = 'ControlledDatePicker';

export default ControlledDatePicker;
