import { useCallback } from 'react';

import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import { useAppDispatch } from 'src/store';
import { sendReportThunk } from '../services';

export const useSendReport = () => {
  const dispatch = useAppDispatch();
  const { SnackTypes, showSnackbar, VariantType } = useSnackbarProvider();

  const onSendReport = useCallback(
    async (entryId?: string) => {
      if (!entryId) {
        showSnackbar(
          VariantType.error,
          'Is missing the Entry Id to send the report',
          SnackTypes.none
        );

        return;
      }

      try {
        await dispatch(sendReportThunk(entryId));

        showSnackbar(VariantType.success, 'The report has been sent', SnackTypes.none);
      } catch (error) {
        showSnackbar(
          VariantType.error,
          'An error has occurred, please refresh and try again',
          SnackTypes.none
        );
      }
    },
    [SnackTypes.none, VariantType.error, VariantType.success, dispatch, showSnackbar]
  );

  return {
    onSendReport
  };
};
