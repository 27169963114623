import { ROLE_DESCRIPTION } from 'src/features/auth/constants';
import { RoleEnum } from 'src/ts/enums';
import { RoleType } from 'src/ts/interfaces';

/* Taking the enum and mapping it to an array of values. */
export const RolesValue = Object.entries(RoleEnum).map((key) => key[1]);

/* Filtering out the empty string values and then mapping the value and label to an object. */
export const RolesValueLabel = Object.entries(ROLE_DESCRIPTION)
  .filter(([_, label]) => label !== '')
  .map(([value, label]) => ({
    value,
    label
  }));

export const LeasingTeam: RoleType[] = [
  RoleEnum.CompanyAdmin,
  RoleEnum.BuildingManager,
  RoleEnum.PropertyAdmin,
  RoleEnum.OnSiteRepresentative
];

export const SnapptTeam: RoleType[] = [
  RoleEnum.Admin,
  RoleEnum.Reviewer,
  RoleEnum.FraudAnalyst,
  RoleEnum.FdeManager,
  RoleEnum.SeniorFraudAnalyst,
  RoleEnum.AccountRepresentative
];
