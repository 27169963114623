import PropTypes from 'prop-types';
import { Container, Content, Label } from './styles';

export type FieldsetProps = {
  children: React.ReactNode;
  legend: string;
  name: string;
  height?: number | string;
};

const Fieldset: React.FC<FieldsetProps> = (props) => {
  const { children, legend, name, height } = props;

  return (
    <Container name={name} aria-label={name}>
      <Label>{legend}</Label>
      <Content height={height}>{children}</Content>
    </Container>
  );
};

Fieldset.propTypes = {
  children: PropTypes.node.isRequired,
  legend: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Fieldset.defaultProps = {
  children: 'Content',
  legend: 'Legend',
  name: 'Fieldset'
};

export default Fieldset;
