export const NAME = 'proofs';

export const RESULT_INSUFFICIENT_REASON_LABEL = {
  NONE: '',
  SCANNED_DOCUMENTS:
    'The documents you submitted have been scanned and cannot be accepted. Please go directly to your financial institution or payroll provider’s website to download the original PDF version of these documents. Do not rename the file.',
  PRINT_TO_PDF:
    'The documents you submitted cannot be accepted because they have been downloaded using a process called “Print to PDF”. Please go directly to your financial institution or payroll provider’s website to download the original PDF version of these documents. Do not rename the file.',
  INVALID_DOCUMENT_TYPE:
    'The documents you submitted cannot be accepted because they are not an acceptable document type. The acceptable document types are Bank Statements (checking and/or saving account) and Payroll Statements. Please go directly to your financial institution or payroll provider’s website to download the original PDF version of these documents. Do not rename the file.',
  MERGED_DOCUMENT:
    'The documents you submitted cannot be accepted because they are merged amongst two or more statement periods. Please submit documents that contain a single period only, downloaded directly from your financial institution or payroll provider’s website. If you have multiple statement periods you would like to submit, include them as separate documents in your submission.',
  FOREIGN_ISSUED_DOCUMENT:
    'These documents are issued in a foreign country. Please resubmit documents issued in the US.',
  CROPPED_DOCUMENT:
    'The bank statements, while containing no obvious edits, are cropped down to a shorter statement than they should be, which is a sign of scanning / P2PDF.',
  CUSTOM: ''
};

export const RESULT_CLEAN_PROCEED_WITH_CAUTION_REASON_LABEL = {
  NONE: '',
  NEW_DOCUMENT:
    'This is a new document type or format (e.g., small bank branch, local credit union, new payroll company). Although this document has all the qualities of a clean document, we are actively gathering more data to move it through our full whitelisting process. If there are concerns about this document, we recommend requesting an additional document type from the applicant.',
  POSSIBLE_MALICIOUS:
    'This document is from a known legitimate producer, however the source of the document can be used with malicious intent (e.g a payroll producer who offers legitimate paid services but also has a free version available). We recommend asking the applicant to submit an alternative document type.',
  CUSTOM: ''
};
