import { StepProps, StepState } from 'src/components/atoms/Step/Step';
import useRole from 'src/features/auth/hooks/useUserRoles';

enum Steps {
  userInfo = 'userInfo',
  addTeam = 'addTeam',
  selectCompany = 'selectCompany',
  assignProperties = 'assignProperties',
  confirm = 'confirm'
}

export type StepType = keyof typeof Steps;

type IStep = {
  id: StepType;
  state: StepState;
  label: string;
};

const useInvitationsSteps = () => {
  const { isAdminOrFdeManager } = useRole();

  const initialSteps: IStep[] = [
    {
      id: Steps.userInfo,
      state: StepState.current,
      label: 'User Info'
    },
    ...(isAdminOrFdeManager
      ? [
          {
            id: Steps.addTeam,
            state: StepState.disabled,
            label: 'Add to Team'
          }
        ]
      : []),
    {
      id: Steps.selectCompany,
      state: StepState.disabled,
      label: 'Select Company'
    },
    {
      id: Steps.assignProperties,
      state: StepState.disabled,
      label: 'Assign Properties'
    },
    {
      id: Steps.confirm,
      state: StepState.disabled,
      label: 'Confirm'
    }
  ];

  const isStepDisabled = (step: StepType, steps: StepProps[]) => {
    return !!steps.find((el) => el.id === step && el.state === StepState.disabled);
  };

  return {
    initialSteps,
    Steps,
    isStepDisabled
  };
};

export default useInvitationsSteps;
