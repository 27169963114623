import PropTypes from 'prop-types';
import SkeletonPiece, { SkeletonPieceColor } from 'src/components/atoms/SkeletonPiece';
import Table from 'src/components/molecules/Table';
import { getColumnsData } from '../EntryLogsHistory/helpers';
import { Container } from './styles';

type LoaderEntryLogTableProps = {
  rows?: number;
};

const LoaderEntryLogTable: React.FC<LoaderEntryLogTableProps> = (props) => {
  const { rows } = props;

  const skeletonData = {
    folderName: <SkeletonPiece width={180} height={25} color={SkeletonPieceColor.gray50} />,
    result: <SkeletonPiece width={180} height={25} color={SkeletonPieceColor.gray50} />,
    submissionTime: <SkeletonPiece width={180} height={25} color={SkeletonPieceColor.gray50} />,
    rulingTime: <SkeletonPiece width={180} height={25} color={SkeletonPieceColor.gray50} />
  };

  const data = Array(rows).fill(skeletonData);
  return (
    <Container data-testid="LoaderEntryLogTable">
      <Table columns={getColumnsData()} data={data} />
    </Container>
  );
};

LoaderEntryLogTable.propTypes = {
  rows: PropTypes.number
};

LoaderEntryLogTable.defaultProps = {
  rows: 6
};

export default LoaderEntryLogTable;
