import { ITextKeys as ITextLangKeys } from 'src/context/Language/types';
import { DupTypes } from '../ts/enums';
import getDefaultInfo from './getDefaultTextLang';

/**
 * @param {DupTypes} type - DupTypes - this is the type of user that is using the form.
 * @param [translate] - (key: ITextLangKeys) => string
 * @returns an object with the following properties: name, title, title_form, check_previous_text,
 * instruction_upload_item_one, instruction_upload_item_two, tool_tip_message, email_placeholder,
 * url_terms_of_service, and url_privacy_policy
 */
const getInfo = (type: DupTypes, translate?: (key: ITextLangKeys) => string) => {
  const t = !translate ? getDefaultInfo : translate;

  const INFO = {
    APPLICANT: {
      name: 'APPLICANT',
      title: t('dup_info_applicant_title'),
      title_form: t('dup_info_applicant_title_form'),
      check_previous_text: t('dup_info_applicant_check_previous_text'),
      instruction_upload_item_one: t('dup_info_applicant_instruction_upload_item_one'),
      instruction_upload_item_two: t('dup_info_applicant_instruction_upload_item_two'),
      instruction_upload_item_three: t('dup_info_applicant_instruction_upload_item_three'),
      tool_tip_message: t('dup_info_applicant_tool_tip_message'),
      email_placeholder: t('dup_info_applicant_email_placeholder'),
      url_terms_of_service: t('dup_info_applicant_url_terms_of_service'),
      url_privacy_policy: t('dup_info_applicant_url_privacy_policy')
    },
    LEASING_TEAM: {
      name: 'LEASING_TEAM',
      title: t('dup_info_leasing_team_title'),
      title_form: t('dup_info_leasing_team_title_form'),
      check_previous_text: t('dup_info_leasing_team_check_previous_text'),
      instruction_upload_item_one: t('dup_info_leasing_team_instruction_upload_item_one'),
      instruction_upload_item_two: t('dup_info_leasing_team_instruction_upload_item_two'),
      instruction_upload_item_three: t('dup_info_leasing_team_instruction_upload_item_three'),
      tool_tip_message: t('dup_info_leasing_team_tool_tip_message'),
      email_placeholder: t('dup_info_leasing_team_email_placeholder'),
      url_terms_of_service: t('dup_info_leasing_team_url_terms_of_service'),
      url_privacy_policy: t('dup_info_leasing_team_url_privacy_policy')
    },
    AOA: {
      name: 'AOA',
      title: t('dup_info_aoa_title'),
      title_form: t('dup_info_aoa_title_form'),
      check_previous_text: t('dup_info_aoa_check_previous_text'),
      instruction_upload_item_one: t('dup_info_aoa_instruction_upload_item_one'),
      instruction_upload_item_two: t('dup_info_aoa_instruction_upload_item_two'),
      instruction_upload_item_three: t('dup_info_aoa_instruction_upload_item_three'),
      tool_tip_message: t('dup_info_aoa_tool_tip_message'),
      email_placeholder: t('dup_info_aoa_email_placeholder'),
      url_terms_of_service: t('dup_info_aoa_url_terms_of_service'),
      url_privacy_policy: t('dup_info_aoa_url_privacy_policy')
    }
  };
  return INFO[type];
};

export default getInfo;
