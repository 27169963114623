import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { IValueLabel } from 'src/ts/interfaces';
import {
  DropdownButton,
  DropdownButtonSize,
  DropdownListPosition
} from '../../atoms/DropdownButton';
import { Container, HelperText, OptionContainer } from './styles';

export type DropdownProps = {
  data: IValueLabel[];
  onChange?: (data: any) => void;
  name: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  size?: DropdownButtonSize;
  valueInitial?: string;
  labelInitial?: string;
  config?: RegisterOptions;
  innerRef?: any;
  itemRef?: any;
  showLabel?: boolean | null;
  listPosition?: DropdownListPosition;
};

/**
 * Use the event `onChange` for to get the item selected and the data updated.
 */
export const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
    clearErrors
  } = useFormContext();

  const {
    data,
    onChange,
    name,
    placeholder,
    size,
    valueInitial,
    labelInitial,
    config,
    itemRef,
    innerRef,
    showLabel,
    listPosition,
    isDisabled
  } = props;

  useWatch({
    control,
    name
  });

  const [localData, setLocalData] = useState<IValueLabel[]>(data);
  const [isOpenUpdated, setIsOpenUpdated] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState(valueInitial !== '');

  const onChanging = (value: string) => {
    const updatedData = localData?.map((row) => ({ ...row, isSelected: row.value === value }));
    const dataSelected = updatedData?.find((row) => row.isSelected);

    const item = dataSelected ?? { value: '', isPlaceHolder: false, isSelected: false, label: '' };

    setValue(name, value);
    setLocalData(updatedData);

    onChange?.({ [name]: { selected: item, data: updatedData } });
    setIsOpenUpdated(false);
  };

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  useEffect(() => {
    register(name, config);
  }, [config, name, register]);

  const getPlaceHolder = () => {
    const element = localData?.find((item) => item.isSelected || item.value === valueInitial);
    const label = element?.label || element?.labelComponent;

    return labelInitial || label || placeholder;
  };

  const onItemSelected = (value: any) => {
    clearErrors(name);
    onChanging(value);
    setIsItemSelected(true);
  };

  const errorWithIndicator = () => {
    if (errors && name.indexOf('.') > 0) {
      const [indicatorField, nameField] = name.split('.');
      const errorObj: any = errors[indicatorField];
      const errorField = errorObj?.[nameField];
      if (errorField) {
        const field = errorField;
        if (field?.message) {
          return field.message;
        }
      }
    }
    return '';
  };

  const errorWithOutIndicator = () => {
    const message = errors[name]?.message;
    return message !== '' ? message : config?.required;
  };

  let hasError = Object.prototype.hasOwnProperty.call(errors, name);
  if (!hasError) {
    hasError = errorWithIndicator() !== '';
  }

  const Item = ({ value, label }: { value: string; label?: string | JSX.Element }) => {
    return (
      <button
        type="button"
        id={value.toString()}
        onClick={() => onItemSelected(value)}
        role="option"
        aria-selected={true}
      >
        {label}
      </button>
    );
  };

  const errorLabel = hasError && (errorWithIndicator() || errorWithOutIndicator());

  return (
    <Container role={'dropdown'} onMouseLeave={() => setIsOpenUpdated(false)}>
      <DropdownButton
        {...props}
        // showLabel if provided on prop use prop showLabel, otherwise always pop showLabel
        // when item is selected.
        showLabel={showLabel || isItemSelected}
        hasError={hasError}
        isOpen={isOpenUpdated}
        label={placeholder}
        onOpened={() => setIsOpenUpdated(false)}
        onClosed={() => setIsOpenUpdated(true)}
        size={size}
      >
        {getPlaceHolder()}
      </DropdownButton>

      {isOpenUpdated && !isDisabled && (
        <OptionContainer
          listPosition={listPosition}
          rows={data.length}
          onMouseLeave={() => {
            setIsOpenUpdated(false);
          }}
        >
          <ul ref={innerRef}>
            {localData?.map(
              ({ value, label, labelComponent, isPlaceHolder, isSelected }, index) => {
                const selected = isSelected ? 'selected' : '';
                const placeholder = isPlaceHolder ? 'placeholder' : '';
                const config = data.length === index + 1 ? { ref: itemRef } : null;
                return (
                  <li {...config} key={value} className={`${selected} ${placeholder}`}>
                    <Item value={value} label={label || labelComponent} />
                  </li>
                );
              }
            )}
          </ul>
        </OptionContainer>
      )}
      {typeof errorLabel === 'string' && (
        <HelperText>
          <Text variant={TextVariant.small} color={TextColor.error}>
            {errorWithIndicator() !== '' ? errorWithIndicator() : errorWithOutIndicator()}
          </Text>
        </HelperText>
      )}
    </Container>
  );
};

Dropdown.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  name: PropTypes.string.isRequired,
  labelInitial: PropTypes.string,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  showLabel: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md'] as const),
  listPosition: PropTypes.oneOf(['top', 'bottom'] as const)
};

Dropdown.defaultProps = {
  name: 'Dropdown',
  size: 'sm',
  showLabel: undefined,
  listPosition: 'bottom'
};

const AdapterDropdown = React.forwardRef((props: DropdownProps, ref) => (
  <Dropdown {...props} innerRef={ref} />
));

export default AdapterDropdown;
