// Components
import Button, {
  ButtonColor,
  ButtonDisabledStyle,
  ButtonSize,
  ButtonVariant
} from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import Tag from 'src/components/atoms/Tag';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import FooterActions from 'src/components/folders/ProofList/components/FooterActions';
import ButtonDownloadFiles from 'src/components/organisms/ButtonDownloadFiles';
import { BulkSelectDoc } from '../BulkSelectDoc';
import ButtonAllNotesModal from '../ButtonAllNotesModal';
import CardSelectDocList from '../CardSelectDocList';
import MatchingApplicantButton from '../MatchingApplicantButton';

// Styles
import { SpacedRow } from '../../styles';

// Helpers
import {
  getResultStatusColorTag,
  startCase,
  transformResultToReadable
} from 'src/features/fdeInbox/helpers';
import { cleanFileName } from 'src/helpers';

// Context
import { useFolderPage } from 'src/components/folders/FolderPageContext';

// Types
import { IEntry } from 'src/ts/interfaces';

//Enums
import { FeatureFlagKey } from 'src/ts/enums';

// Hooks
import { useEntryUpdateNote } from 'src/features/entries/hooks';
import { useEntryAssignment } from 'src/features/myWork';
import { useEscalate } from 'src/features/myWork/hooks/useEscalate';
import { useProofsFilteredByEntryId, useProofUpdateState } from 'src/features/proofs/hooks';
import { useFeatureFlag } from 'src/hooks';

type EntryContentParams = {
  entry: IEntry;
  folderId: string;
  canEscalateEntry?: boolean;
  canSplitEntry?: boolean;
  isReviewDisabled?: boolean;
  showSubmissionRequestButton?: boolean;
  onSubmissionRequest?: () => void;
};

const EntryContent = ({
  entry,
  folderId,
  canEscalateEntry = false,
  canSplitEntry = false,
  isReviewDisabled = false,
  showSubmissionRequestButton = false,
  onSubmissionRequest = () => false
}: EntryContentParams): JSX.Element => {
  const { onEscalate, isLoading: isLoadingEscalate } = useEscalate();
  const { onUpdateNote: onUpdateEntryNote } = useEntryUpdateNote();
  const { setShowSplitEntryModal, selectedEntryId } = useFolderPage();
  const { requestEntry, isLoadingSubmissionRequest } = useEntryAssignment();

  const { onCheckProofs, getCheckedAll, onUpdateSelected, getIsLoading, getURLProofs } =
    useProofUpdateState();

  const { proofs, isLoading: isLoadingProofs } = useProofsFilteredByEntryId(
    selectedEntryId as string
  );

  const cleanProceedWithCautionFlagEnabled = useFeatureFlag(
    FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION
  );

  return (
    <>
      <SpacedRow justify="space-between">
        <Row alignItems="center" gap={1}>
          <Row alignItems="center" gap={0.5}>
            <Text variant={TextVariant.small} color={TextColor.gray700}>
              Status
            </Text>
            <Tag
              label={startCase(entry.status)}
              color={getResultStatusColorTag(entry.result, cleanProceedWithCautionFlagEnabled)}
              showBackground
            />
          </Row>
          <Row alignItems="center" gap={0.5}>
            <Text variant={TextVariant.small} color={TextColor.gray700}>
              Results
            </Text>
            <Tag
              label={transformResultToReadable(entry.result)}
              color={getResultStatusColorTag(entry.result, cleanProceedWithCautionFlagEnabled)}
              showBackground
            />
          </Row>
        </Row>
        <Row gap={1} alignItems="center">
          <Button
            name="button-split-entry"
            variant={ButtonVariant.outline}
            size={ButtonSize.medium}
            onClick={() => setShowSplitEntryModal?.(true)}
            isDisabled={!canSplitEntry || isLoadingProofs || getIsLoading(proofs)}
            disabledStyle={ButtonDisabledStyle.whiteWithBorder}
          >
            Remove from Folder
          </Button>
          <MatchingApplicantButton folderId={folderId} />
        </Row>
      </SpacedRow>
      <Gap height={2} />
      <Row justify="space-between" gap={1}>
        <Row alignItems="flex-start" gap={1}>
          <BulkSelectDoc
            isDisabled={isReviewDisabled || isLoadingProofs || getIsLoading(proofs)}
            markSelectAll={getCheckedAll(proofs)}
            onGetCheck={({ selectAll }) => onCheckProofs(selectAll)}
            onApply={(content) => onUpdateSelected(content, proofs)}
          />
        </Row>
        <Row alignItems="flex-start">
          <ButtonAllNotesModal
            isDisabled={isLoadingProofs}
            onSaveAllNotes={(content) => {
              onUpdateEntryNote(selectedEntryId, content.note);
            }}
            defaultNote={entry?.note || ''}
          />
          <ButtonDownloadFiles
            isDisabled={isLoadingProofs && !proofs?.length}
            urls={getURLProofs(proofs)}
            fileName={`${cleanFileName(entry.applicant.full_name)}_Document`}
          />
        </Row>
      </Row>
      <Gap />
      <CardSelectDocList
        isReviewDisabled={isReviewDisabled}
        isLoading={isLoadingProofs}
        proofs={proofs}
        entryId={entry?.id}
        folderId={folderId}
        cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
      />
      <Gap height={2} />
      <FooterActions
        proofs={proofs}
        isReviewDisabled={isReviewDisabled}
        canEscalate={canEscalateEntry && !isReviewDisabled && !showSubmissionRequestButton}
        onEscalate={() => onEscalate(entry.id)}
        isLoadingEscalate={isLoadingEscalate}
        isDisabled={isLoadingEscalate}
      />
      {showSubmissionRequestButton && (
        <>
          <Gap height={1} />
          <Button
            name="ask-for-submission"
            variant={ButtonVariant.contained}
            color={ButtonColor.primary}
            onClick={async () => {
              await requestEntry();
              onSubmissionRequest();
            }}
            isDisabled={isLoadingSubmissionRequest}
            isLoading={isLoadingSubmissionRequest}
          >
            Ask for submission
          </Button>
        </>
      )}
    </>
  );
};

export default EntryContent;
