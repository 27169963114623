import { EntryResultEnum } from '@snapptinc/fraud-platform';
import { useSelector } from 'react-redux';
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import Section from 'src/components/atoms/Section/Section';
import Text, { TextVariant } from 'src/components/atoms/Text';
import BoxStatus from 'src/components/molecules/BoxStatus';

import { selectors as entryReportSelectors } from 'src/features/fde-report/fdeReportSlice';
import { currencyFormat } from 'src/helpers/currencyFormat';
import { recipientNameCodeExists, statusCodeExists } from 'src/pages/ReportPage/helpers';

const IncomeVerificationResults = () => {
  const entryReport = useSelector(entryReportSelectors.getEntryReport.data);
  const { entry, income_calculation, applicant } = entryReport || {};
  const { monthly_income, documents, status_details } = income_calculation ?? {};

  const recipientName = documents?.at(0)?.applicant_name ?? '';

  if (income_calculation === null && entry?.result !== EntryResultEnum.Edited) {
    return <div></div>;
  }

  const allDocumentsDoNotContainGrossPay = statusCodeExists(status_details ?? [], [3001]);
  const notConsecutiveDays = statusCodeExists(status_details ?? [], [3004]);
  const datesTooOld = statusCodeExists(status_details ?? [], [3005]);

  const monthlyGrossIncomeSubTitle = () => {
    if (allDocumentsDoNotContainGrossPay) return 'Documents do not contain gross income';
    else if (monthly_income === 0 || entry?.result === EntryResultEnum.Edited)
      return 'Not Available';
    else return currencyFormat(monthly_income ?? 0);
  };

  const recipientNameSubTitle = () => {
    if (applicantNameMatches) {
      return recipientNameCodeExists(status_details ?? [])
        ? 'Does not match across documents'
        : recipientName;
    } else {
      return 'Does not match applicant name';
    }
  };

  const recipientNameType = () => {
    if (applicantNameMatches) {
      return recipientNameCodeExists(status_details ?? []) ? 'person-caution' : 'person';
    } else {
      return 'person-caution';
    }
  };

  const applicantNameMatches = documents?.every(
    (document) =>
      document.applicant_name.includes(applicant?.first_name ?? '') &&
      document.applicant_name.includes(applicant?.last_name ?? '')
  );

  return (
    <Section padding="0 3rem 3rem 3rem">
      <Text variant={TextVariant.h2}>Income Verification Results</Text>
      <Gap height={1} />
      <Row className="document_results" gap={1}>
        <BoxStatus
          key="montly-gross-income"
          type={
            monthly_income === 0 || entry?.result === EntryResultEnum.Edited
              ? 'money-caution'
              : 'money'
          }
          title="Monthly Gross Income"
          subTitle={monthlyGrossIncomeSubTitle()}
        />
        {entry?.result !== EntryResultEnum.Edited && (
          <BoxStatus
            key="recipient-name"
            type={recipientNameType()}
            title="Recipient Name"
            subTitle={recipientNameSubTitle()}
          />
        )}

        {entry?.result === EntryResultEnum.Edited && (
          <BoxStatus
            key="fraud-detection"
            type="document-icon-caution"
            title="Fraud Detection"
            subTitle="Document submission was ruled as edited"
          />
        )}

        {datesTooOld && (
          <BoxStatus
            key="new-date-requirements"
            type="calendar-caution"
            title="Date Requirements"
            subTitle="Pay period dates older than 90 days"
          />
        )}

        {notConsecutiveDays && (
          <BoxStatus
            key="consecutive-pay-requirements"
            type="calendar-caution"
            title="Date Requirements"
            subTitle="Documents do not cover 28 days consecutive pay"
          />
        )}
      </Row>
    </Section>
  );
};

export default IncomeVerificationResults;
