import styled from 'styled-components/macro';

export const StyledTableRow = styled.tr`
  width: 100%;
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;

  td {
    border-bottom: 0;
    min-height: 0;
    height: 40px;
    ${({ theme }) => theme.font.small}
  }
`;
