/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { SetStateAction, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Components
import Checkbox from 'src/components/atoms/Checkbox/Checkbox';
import Gap from 'src/components/atoms/Gap';
import InputText, {
  InputTextOnChangeProps,
  InputTextType
} from 'src/components/atoms/InputText/InputText';
import Row from 'src/components/atoms/Row/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import ToolTip, { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import { Container } from './styles';

// Redux
import { actions } from 'src/features/DUP/DUPSlice';
import { useAppDispatch } from 'src/store';

// Hooks
import useLanguage from 'src/context/Language/useLanguage';
import { FormValues } from 'src/features/DUP/form/hooks/useForm';

// Types
import { IInformation } from 'src/features/DUP/form/ts/interfaces';

// Enums
import { DupTypes } from 'src/features/DUP/ts/enums';

// Helpers
import { letterMatchingRegex, onlyCapsRegex } from 'src/helpers/formRegex';

export type FormApplicantProps = {
  type: DupTypes;
  information: Pick<
    IInformation,
    'title' | 'tool_tip_message' | 'title_form' | 'email_placeholder' | 'check_previous_text'
  >;
  dataForm: FormValues;
  onValidation: React.Dispatch<SetStateAction<boolean>>;
};

type FormKeys =
  | 'application'
  | 'property'
  | 'property.name'
  | 'application.phone'
  | 'application.unit'
  | 'application.notification_email'
  | 'application.email'
  | 'application.first_name'
  | 'application.has_previously_submitted'
  | 'property.unit_is_required';

const FormApplicant: React.FC<FormApplicantProps> = (props: FormApplicantProps) => {
  const { type, information, dataForm, onValidation } = props;

  const dispatch = useAppDispatch();

  const { translate: t, language } = useLanguage();

  const [havePreviousSubmitLabel, setHavePreviousSubmitLabel] = useState('');
  const [refresh, setRefresh] = useState(false);

  const methods = useForm<FormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      application: {
        unit: undefined,
        first_name: '',
        middle_initial: '',
        last_name: '',
        email: '',
        phone: '',
        notification_email: ''
      },
      property: {
        name: ''
      }
    }
  });

  useEffect(() => {
    if (dataForm.property && dataForm.application) {
      [dataForm.property].forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          methods.setValue(('property.' + key) as FormKeys, value ?? '');
        });
      });

      if (dataForm?.property?.name) {
        setHavePreviousSubmitLabel(
          information.check_previous_text.replace('{Property_name}', dataForm?.property?.name)
        );
      }
      onValidation(methods.formState.isValid);
    }
  }, [dataForm, information.check_previous_text, methods]);

  useEffect(() => {
    onValidation(methods.formState.isValid);
  }, [methods.formState.isValid, onValidation]);

  useEffect(() => {
    setRefresh(!refresh);
  }, [language]);

  useEffect(() => {
    if (methods.formState.isDirty) {
      methods.trigger('property');
      methods.trigger('application');
    }
  }, [methods, language]);

  const onChanging = (e: InputTextOnChangeProps) => {
    const [type, field] = e.field.split('.');
    if (type === 'application') {
      dispatch(actions.setFormApplication({ [field]: e.value }));
    } else {
      dispatch(actions.setFormProperty({ [field]: e.value }));
    }
  };

  const TitleNumApplicant = () => {
    if (type === DupTypes.APPLICANT) return null;

    return (
      <>
        <Row>
          <Text variant={TextVariant.h4} color={TextColor.initial}>
            {t('dup_form_title')}
          </Text>
        </Row>
        <Gap height={1.2} />
      </>
    );
  };

  return (
    <FormProvider {...methods}>
      <Container>
        <Row>
          <Text variant={TextVariant.h3} color={TextColor.initial}>
            {information.title_form}
          </Text>
        </Row>
        <Gap height={2} />
        {type !== DupTypes.AOA && (
          <Row gap={1}>
            <Row.Col size={2}>
              <InputText
                placeholder={t('dup_form_label_property_name')}
                name="property.name"
                type={InputTextType.text}
                isReadonly
              />
            </Row.Col>

            {dataForm.property?.unit_is_required && (
              <Row.Col size={1}>
                <InputText
                  placeholder={t('dup_form_label_unit_num')}
                  name="application.unit"
                  type={InputTextType.text}
                  showOptionalLabel={false}
                  onChange={(e) => {
                    if (String(e.value).length <= 8) {
                      onChanging(e);
                    }
                  }}
                  config={{
                    min: {
                      value: 1,
                      message: t('validation_should_be_#_or_more', '1')
                    },
                    maxLength: {
                      value: 8,
                      message: t('validation_should_be_less_than_#_characters', '8')
                    }
                  }}
                />
              </Row.Col>
            )}
            {type === DupTypes.LEASING_TEAM && (
              <Row.Col size={1}>
                <ToolTip
                  content={information.tool_tip_message}
                  direction={ToolTipDirection.bottom_fit}
                >
                  <InputText
                    placeholder={t('dup_form_label_email_notifications')}
                    name="application.notification_email"
                    type={InputTextType.email}
                    onChange={onChanging}
                    config={{
                      required: t('validation_complete_this_field')
                    }}
                  />
                </ToolTip>
              </Row.Col>
            )}
          </Row>
        )}
        <Gap height={0.5} />
        <TitleNumApplicant />

        <Row gap={1}>
          <Row.Col size={1}>
            <InputText
              placeholder={t('dup_form_label_first_name')}
              name="application.first_name"
              type={InputTextType.text}
              onChange={onChanging}
              config={{
                required: t('validation_complete_this_field'),
                maxLength: {
                  value: 25,
                  message: t('validation_#_characters_maximum', '25')
                },
                validate: {
                  noSpecialCharacters: (value) =>
                    letterMatchingRegex.test(value) ||
                    t('validation_should_not_contain_only_numbers_and_symbols')
                }
              }}
            />
          </Row.Col>

          <Row.Col size={0.5}>
            <InputText
              placeholder={t('dup_form_label_middle_initial')}
              name="application.middle_initial"
              type={InputTextType.text}
              showOptionalLabel={false}
              onChange={onChanging}
              config={{
                maxLength: {
                  value: 1,
                  message: t('validation_should_be_only_one_character')
                },
                validate: {
                  noSpecialCharacters: (value) =>
                    value
                      ? onlyCapsRegex.test(value) || t('validation_should_contain_a_capital_letter')
                      : undefined
                }
              }}
            />
          </Row.Col>

          <Row.Col size={1}>
            <InputText
              placeholder={t('dup_form_label_last_name')}
              name="application.last_name"
              type={InputTextType.text}
              onChange={onChanging}
              config={{
                required: t('validation_complete_this_field'),
                maxLength: {
                  value: 50,
                  message: t('validation_#_characters_maximum', '50')
                },
                validate: {
                  noSpecialCharacters: (value) =>
                    letterMatchingRegex.test(value) ||
                    t('validation_should_not_contain_only_numbers_and_symbols')
                }
              }}
            />
          </Row.Col>

          <Row.Col size={1}>
            <InputText
              placeholder={information.email_placeholder}
              name="application.email"
              type={InputTextType.email}
              onChange={onChanging}
              config={{
                required: t('validation_complete_this_field')
              }}
            />
          </Row.Col>

          {dataForm.property?.phone_is_required && (
            <Row.Col size={1}>
              {type !== DupTypes.AOA && (
                <InputText
                  placeholder={t('dup_form_label_phone')}
                  name="application.phone"
                  type={InputTextType.tel}
                  onChange={onChanging}
                  config={{
                    required: t('validation_complete_this_field')
                  }}
                />
              )}
            </Row.Col>
          )}
        </Row>

        {havePreviousSubmitLabel && (
          <Row key={`key_HavePreviousSubmit_${refresh}`}>
            <Checkbox
              name="HavePreviousSubmit"
              value="havePreviousSubmit"
              label={havePreviousSubmitLabel}
              isChecked={!!dataForm.application?.has_previously_submitted}
              onClick={({ isChecked }) => {
                onChanging({
                  field: 'application.has_previously_submitted',
                  value: isChecked
                });
              }}
            />
          </Row>
        )}
      </Container>
    </FormProvider>
  );
};

export default FormApplicant;
