// Components
import { ProofList } from 'src/components/folders';
import { useProofsFilteredByEntryId } from 'src/features/proofs/hooks';

// Hooks
import { useFolderPage } from '../FolderPageContext';

export const ProofListContainer = () => {
  const { selectedEntryId } = useFolderPage();

  const { proofs, isLoading } = useProofsFilteredByEntryId(selectedEntryId as string);

  if (isLoading) {
    return <p>Loading ...</p>;
  }
  return <ProofList proofs={proofs} />;
};
