import React from 'react';

// Vendor
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import { Loader } from 'src/components/atoms/Loader';
import Section from 'src/components/atoms/Section/Section';
import { ResultMessage } from 'src/components/molecules/ResultMessage';
import { ResultMessageType } from 'src/components/molecules/ResultMessage/ResultMessage';
import IdentityVerificationPrints from 'src/pages/IdentityVerificationReportPage/components/IdentityVerificationPrints';
import { IdentityVerificationApplicantDetail } from './components/IdentityVerificationApplicantDetail';
import { IdentityVerificationResults } from './components/IdentityVerificationResults';

// Redux
import { selectors as identitySelectors } from 'src/features/identityVerification/identityVerificationSlice';

// Hooks
import { useGetIdentityImages } from 'src/features/identityVerification/hooks';
import useIdentityVerificationReport from 'src/features/identityVerification/hooks/useIdentityVerificationReport';

const IdentityVerificationReportPage: React.FC = () => {
  const { identityVerificationId } = useParams();
  const { onGetApplicantByVerificationId, onResetApplicantByVerificationId } =
    useIdentityVerificationReport();

  const { onGetIdentityImages } = useGetIdentityImages();

  const identityReportIsIdle = useSelector(identitySelectors.getApplicantByVerificationId.isIdle);
  const identityReportIsLoading = useSelector(
    identitySelectors.getApplicantByVerificationId.isLoading
  );
  const identityReportError = useSelector(identitySelectors.getApplicantByVerificationId.error);

  useEffect(() => {
    if (identityReportIsIdle && identityVerificationId) {
      onGetApplicantByVerificationId(identityVerificationId);
      onGetIdentityImages(identityVerificationId);
    }
  }, [identityVerificationId, identityReportIsIdle, onGetApplicantByVerificationId]);

  useEffect(() => {
    return () => {
      onResetApplicantByVerificationId();
    };
  }, []);

  if (identityReportError) {
    return (
      <Section align="center">
        <ResultMessage
          type={ResultMessageType.error}
          title="Something was wrong with the report"
          message="We are sorry, please try again or contact support"
        />
      </Section>
    );
  }

  if (identityReportIsLoading) {
    return <Loader isFixed />;
  }

  return (
    <>
      <IdentityVerificationPrints />
      <IdentityVerificationApplicantDetail />
      <IdentityVerificationResults />
    </>
  );
};

export default IdentityVerificationReportPage;
