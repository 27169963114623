import { idvApi } from 'src/lib/internal/epa';
import { LinkAction } from 'src/ts/enums';
import { IdentityReportPreset } from 'src/ts/enums/identity';
import {
  getPaginationRequest,
  IdentityGenerateApplicantLinkPostRequestType
} from 'src/ts/interfaces';

const api = {
  generateApplicantLink: async (
    payload: IdentityGenerateApplicantLinkPostRequestType,
    linkAction: LinkAction
  ) => {
    const result = await idvApi.identityGenerateApplicantLinkPost(payload);
    return { data: result, linkAction };
  },
  getApplicants({ page, rowsPerPage, filter, q, sort }: getPaginationRequest) {
    return idvApi.identityApplicantsGet(rowsPerPage, page, sort, filter, q);
  },
  getApplicantByVerificationID(idVerificationId: string) {
    return idvApi.identityApplicantsIdVerificationIdGet(idVerificationId);
  },
  getIdentityReportPDF: async (
    idVerificationId: string,
    preset: IdentityReportPreset,
    fileName: string
  ) => {
    const result = await idvApi.identityApplicantsIdVerificationIdReportGet(
      idVerificationId,
      preset,
      {
        responseType: 'blob'
      }
    );
    return { data: result, fileName };
  },
  getIdentityImages: (idVerificationId: string) => {
    return idvApi.identityApplicantsIdVerificationIdImagesGet(idVerificationId);
  }
};

export default api;
