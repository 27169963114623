import Button, { ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import Tag, { TagColor } from 'src/components/atoms/Tag';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { cleanFileName, downloadUrlToPdf } from 'src/helpers';
import { colors } from 'src/theme/constants';
import { Container, Content, Description, Thumbnail, Title } from './styles';

export type TypeCard = 'upload' | 'success' | 'fail' | 'warning' | 'caution' | 'none';

export type CardPreviewProps = {
  type: TypeCard;
  fileName?: string;
  numDocument: number;
  title?: string;
  tag?: string;
  detail?: string;
  thumbnailURL?: string | null;
  fileURL?: string | null;
  cleanProceedWithCautionFlagEnabled?: boolean;
  children?: JSX.Element;
};

const getColor = (type: TypeCard, cleanProceedWithCautionFlagEnabled?: boolean) => {
  let color = colors.red[700];
  let tagColor = TagColor.blue;
  switch (type) {
    case 'upload':
      color = colors.blue[700];
      tagColor = TagColor.blue;
      break;
    case 'fail':
      color = colors.red[700];
      tagColor = TagColor.red;
      break;
    case 'success':
      color = colors.green[700];
      tagColor = TagColor.green;
      break;
    case 'warning':
      color = cleanProceedWithCautionFlagEnabled ? colors.blue[200] : colors.yellow[700];
      tagColor = cleanProceedWithCautionFlagEnabled ? TagColor.snappt_blue : TagColor.yellow;
      break;
    case 'caution':
      color = colors.yellow[700];
      tagColor = TagColor.yellow;
      break;
    case 'none':
      color = colors.white[700];
      tagColor = TagColor.white;
      break;
    default:
      break;
  }
  return { color, tagColor };
};

const CardPreview: React.FC<CardPreviewProps> = ({
  fileName,
  numDocument,
  type,
  tag,
  title,
  detail,
  thumbnailURL,
  fileURL,
  cleanProceedWithCautionFlagEnabled,
  children
}: CardPreviewProps) => {
  const { color, tagColor } = getColor(type, cleanProceedWithCautionFlagEnabled);
  const file = cleanFileName(fileName);

  return (
    <Container className="CardPreview" type={type} color={color}>
      <Content type={type} color={color}>
        <Thumbnail>
          {thumbnailURL ? (
            <img src={thumbnailURL} alt={title} />
          ) : (
            <Icon icon={'notPreviewDocument'} />
          )}
        </Thumbnail>
        {!children && (
          <Description>
            <Title>
              {title && (
                <Text variant={TextVariant.normal} isBold>
                  {title}
                </Text>
              )}
              {fileURL && (
                <Button
                  name={`download_button__${file}_Document${numDocument}`}
                  variant={ButtonVariant.ghost}
                  onClick={() => downloadUrlToPdf(fileURL, `${file}_Document${numDocument}.pdf`)}
                >
                  <Icon icon={'download'} />
                </Button>
              )}
            </Title>
            {tag && (
              <>
                <Gap />
                <Tag label={tag} showBackground color={tagColor} />
              </>
            )}
            <Gap />
            <Text variant={TextVariant.small} color={TextColor.gray700}>
              {detail}
            </Text>
          </Description>
        )}
        {children}
      </Content>
    </Container>
  );
};

CardPreview.defaultProps = {
  type: 'success',
  tag: 'Clean',
  title: 'Passed',
  detail:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet odio tortor. Proin ut rutrum mauris, sit amet dictum dolor.',
  thumbnailURL: null,
  fileURL: null
};

export default CardPreview;
