import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ListView } from 'src/components/templates';
import {
  IDENTITY_VERIFICATION_FILTER,
  selectors as propertySelectors
} from 'src/features/property';
import useProperty from 'src/features/property/hooks/useProperty';
import IdentityVerificationLinkList from 'src/pages/IdentityVerificationLinkPortal/components/IdentityVerificationLinkList';
import IdentityVerificationLinkPagination from 'src/pages/IdentityVerificationLinkPortal/components/IdentityVerificationLinkPagination';
import PropertyListFilters from 'src/pages/PropertyListPage/components/PropertyListFilters';

const IdentityVerificationLinkPortal: React.FC = () => {
  const filter = IDENTITY_VERIFICATION_FILTER;

  const { onGetAllIdentityVerificationEnabled: onGetIdentityVerificationProperties } =
    useProperty();

  const getPropertiesIsIdle = useSelector(
    propertySelectors.getAllIdentityVerificationEnabled.isIdle
  );
  useEffect(() => {
    if (getPropertiesIsIdle) {
      onGetIdentityVerificationProperties(0, 5, filter);
    }
  }, [filter, getPropertiesIsIdle, onGetIdentityVerificationProperties]);
  const getPropertiesCount = useSelector(propertySelectors.getAllIdentityVerificationEnabled.count);

  return (
    <ListView
      title="Identity Verification Properties"
      filters={<PropertyListFilters identityVerificationPropertySearch />}
    >
      <IdentityVerificationLinkList />
      {getPropertiesCount > 5 && <IdentityVerificationLinkPagination />}
    </ListView>
  );
};

export default IdentityVerificationLinkPortal;
