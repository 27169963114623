import { Container as Grid } from './styles';

export type DirectionType = 'row' | 'row-reverse' | 'column' | 'column-reverse';
export type WrapType = 'nowrap' | 'wrap' | 'wrap-reverse';
export type JustifyType = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
export type AlignType =
  | 'unset'
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'stretch';

export type GridProps = {
  children?: React.ReactNode;
  direction?: DirectionType;
  wrap?: WrapType;
  justify?: JustifyType;
  alignItems?: AlignType;
  alignContent?: AlignType;
};

Grid.defaultProps = {
  direction: 'row',
  wrap: 'nowrap',
  justify: 'flex-start',
  alignItems: 'unset',
  alignContent: 'flex-start'
};

export default Grid;
