import PropTypes from 'prop-types';
import { Container } from './styles';

export type OnepageProps = {
  children: React.ReactNode;
};

const Onepage: React.FC<OnepageProps> = (props: OnepageProps) => {
  const { children } = props;

  return <Container>{children}</Container>;
};

Onepage.propTypes = {
  children: PropTypes.node.isRequired
};

Onepage.defaultProps = {
  children: 'Content'
};

export default Onepage;
