import Image, { IconColor } from 'src/components/atoms/Icon/Icon';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { colors } from 'src/theme/constants';
import { Container, Description, Icon } from './styles';

export type TypeIcon =
  | 'upload'
  | 'success'
  | 'fail'
  | 'warning'
  | 'caution'
  | 'money'
  | 'money-caution'
  | 'person'
  | 'person-caution'
  | 'calendar-caution'
  | 'document-icon-caution';

export type BoxStatusProps = {
  type: TypeIcon;
  title: string;
  subTitle: string;
  cleanProceedWithCautionFlagEnabled?: boolean;
};

const getIcon = (type: TypeIcon, cleanProceedWithCautionFlagEnabled?: boolean) => {
  let icon = 'status-fail';
  let iconColor = IconColor.red;
  let color = colors.red[700];
  let strokeColor: IconColor = IconColor.none;
  switch (type) {
    case 'upload':
      icon = 'status-upload';
      color = colors.blue[700];
      iconColor = IconColor.blue700;
      break;
    case 'fail':
      icon = 'status-fail';
      color = colors.red[700];
      iconColor = IconColor.red;
      break;
    case 'success':
      icon = 'status-success';
      color = colors.green[700];
      iconColor = IconColor.green700;
      break;
    case 'warning':
      icon = cleanProceedWithCautionFlagEnabled ? 'status-insufficient' : 'status-warning';
      color = cleanProceedWithCautionFlagEnabled ? colors.snappt[700] : colors.yellow[700];
      iconColor = cleanProceedWithCautionFlagEnabled ? IconColor.snappt700 : IconColor.yellow700;
      break;
    case 'caution':
      icon = 'status-caution';
      color = colors.yellow[700];
      iconColor = IconColor.yellow700;
      break;
    case 'money':
      icon = 'money';
      color = colors.green[700];
      iconColor = IconColor.green700;
      strokeColor = IconColor.green700;
      break;
    case 'money-caution':
      icon = 'money';
      color = colors.yellow[700];
      iconColor = IconColor.yellow700;
      strokeColor = IconColor.yellow700;
      break;
    case 'person':
      icon = 'person';
      color = colors.green[700];
      iconColor = IconColor.green700;
      break;
    case 'person-caution':
      icon = 'person';
      color = colors.yellow[700];
      iconColor = IconColor.yellow700;
      break;
    case 'calendar-caution':
      icon = 'calendar';
      color = colors.yellow[700];
      iconColor = IconColor.yellow700;
      break;
    case 'document-icon-caution':
      icon = 'DocumentIcon';
      color = colors.yellow[700];
      iconColor = IconColor.yellow700;
      break;
    default:
      break;
  }
  return (
    <Icon color={color}>
      <Image icon={icon} color={iconColor} strokeColor={strokeColor} />
    </Icon>
  );
};

const BoxStatus: React.FC<BoxStatusProps> = ({
  type,
  title,
  subTitle,
  cleanProceedWithCautionFlagEnabled
}: BoxStatusProps) => {
  return (
    <Container className="BoxStatus" type={type}>
      {getIcon(type, cleanProceedWithCautionFlagEnabled)}
      <Description>
        <Text variant={TextVariant.normal} isBold>
          {title}
        </Text>
        <Text variant={TextVariant.normal} color={TextColor.gray700}>
          {subTitle}
        </Text>
      </Description>
    </Container>
  );
};

BoxStatus.defaultProps = {
  type: 'success',
  title: 'Passed',
  subTitle: '1 document',
  cleanProceedWithCautionFlagEnabled: true
};

export default BoxStatus;
