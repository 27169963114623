import { FC, HTMLAttributes } from 'react';
import { StyledTableHead } from './styles';

type TableHeadProps = HTMLAttributes<HTMLElement>;

const TableHead: FC<TableHeadProps> = (props) => {
  return <StyledTableHead {...props} />;
};

export default TableHead;
