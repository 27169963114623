import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPaginationRequest } from 'src/ts/interfaces';
import { NAME } from './constants';

import api from './api';

export const getEntriesLogsThunk = createAsyncThunk(
  `${NAME}/getEntriesLogs`,
  async (data: getPaginationRequest) => {
    try {
      return await api.getEntriesLogs(data);
    } catch (error) {
      console.error(`${NAME}/getEntriesLogs`, error);
      throw new Error('Error fetching the entry logs');
    }
  }
);
