// Vendor
import { useSelector } from 'react-redux';

// Components
import { Row } from 'src/components/atoms/Row';
import InputSearch from 'src/components/organisms/InputSearch';

// Hooks
import useCompany from 'src/features/company/hooks/useCompany';

// Redux
import { selectors as companySelector } from 'src/features/company/companySlice';

const CompanyListFilters: React.FC = () => {
  const { onSearch } = useCompany();

  const companiesIsLoading = useSelector(companySelector.getAll.isLoading);
  const companiesError = useSelector(companySelector.getAll.error);
  const companiesFilter = useSelector(companySelector.getAll.filter);

  const shouldDisableSearch = companiesIsLoading || Boolean(companiesError);

  return (
    <Row gap={1} justify="flex-end">
      <InputSearch
        onSearch={({ value }) => onSearch(value)}
        onClear={() => onSearch('')}
        name="CompanyListSearch"
        value={(companiesFilter.q && decodeURIComponent(companiesFilter?.q)) || ''}
        placeholder="Search"
        isDisabled={shouldDisableSearch}
      />
    </Row>
  );
};

export default CompanyListFilters;
