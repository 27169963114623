import { ICompany } from 'src/ts/interfaces';
import api from '../api';

export const updateCompanyLogo = async (
  createCompanyPreURLResponse?: ICompany,
  company?: ICompany
) => {
  if (!createCompanyPreURLResponse?.id) {
    throw new Error('Failed to create a company');
  }
  try {
    if (!company || !company.logo) {
      throw new Error('Could not get logo for the company');
    }

    if (!((company.logo as any) instanceof File)) {
      return;
    }

    const logoFile = company.logo as unknown as File;

    const companyId = createCompanyPreURLResponse?.id;
    const getCompanyURLResponse = await api.getCompanyURL(companyId);

    if (!getCompanyURLResponse?.presigned_url) {
      throw new Error('Could not get the presigned url for the company');
    }

    const { url, fields, method } = getCompanyURLResponse.presigned_url;

    if (!url || !fields || !method) {
      throw new Error('Could not get url, fields, metadata or method');
    }

    await api.saveCompanyFile({
      method,
      url,
      fields,
      type: 'image/png',
      file: logoFile
    });
    await api.setCompanyURL(companyId);
    return await api.getCompany(companyId);
  } catch (error: any) {
    console.error(error?.message);
  }
};
