import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { StyledInstructionCard } from './styles';

const IDVLinkInstruction: React.FC = () => {
  return (
    <StyledInstructionCard border alignContent="center">
      <Icon color="primary" icon="warning" />
      <Gap height={1.1875} />
      <Text color="gray700" variant={TextVariant.small}>
        Select the ‘Applicants Link’ button to copy the IDV link to your clipboard. From here, you
        can paste into an email or text to send to your applicant.
      </Text>
      <Gap height={2} />
      <Text color="gray700" variant={TextVariant.small}>
        Please note, each link is unique and a new one should be generated for each applicant. Only
        the individual whose ID is being verified should proceed through the verification workflow.
      </Text>
    </StyledInstructionCard>
  );
};

export default IDVLinkInstruction;
