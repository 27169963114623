import { Row } from 'src/components/atoms/Row';
import styled from 'styled-components';

export const StyledDiv = styled.div`
  @media ${({ theme }) => theme.device.xs}, ${({ theme }) => theme.device.mobile} {
    width: 50%;
  }
`;

export const StyledRow = styled(Row)`
  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
    margin-left: 0.625rem;
  }
`;
