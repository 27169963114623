import { ThemeAttributes } from 'src/ts/interfaces/styled-components';
import styled, { keyframes } from 'styled-components/macro';
import { SkeletonPieceAlign, SkeletonPieceColor, SkeletonPieceProps } from './';

const skeletonLoading = (color: string, shade: string, theme: ThemeAttributes) => keyframes`
  0% {
    background-color: ${theme.color.white};
  }
  25% {
    background-color: ${shade};
  }
  50% {
    background-color: ${color};
  }
  75% {
    background-color: ${shade};
  }
  100% {
    background-color:${theme.color.white}
  }
`;

const getColor = (color: SkeletonPieceColor, theme: ThemeAttributes) => {
  switch (color) {
    case SkeletonPieceColor.gray200:
      return theme.color.gray[color];
    case SkeletonPieceColor.gray100:
      return theme.color.gray[color];
    case SkeletonPieceColor.gray50:
      return theme.color.gray[color];
    default:
      return theme.color.gray[50];
  }
};

const getSize = (prop: string | number) => {
  if (typeof prop === 'string' && prop.includes('%')) {
    return prop;
  }
  return `calc((${prop}/16) * 1rem)`;
};

const getAlign = (align: SkeletonPieceAlign) => {
  switch (align) {
    case SkeletonPieceAlign.center:
      return 'center';
    case SkeletonPieceAlign.right:
      return 'flex-end';
    case SkeletonPieceAlign.left:
      return 'flex-start';
    default:
      return 'flex-start';
  }
};

export const StyledSkeletonPiece = styled.div<
  Pick<SkeletonPieceProps, 'width' | 'height' | 'isRounded' | 'color'>
>`
  background-color: ${({ theme, color }) => getColor(color, theme)};
  width: ${({ width }) => getSize(width)}}
  height: ${({ height }) => getSize(height)}}
  border-radius: ${({ isRounded }) => (isRounded ? '6.25rem' : '0.125rem')};
  animation: ${({ theme, color }) => skeletonLoading(getColor(color, theme), '#eef1f6', theme)}
    4s linear infinite;
`;

export const StyledContent = styled.div<Pick<SkeletonPieceProps, 'align'>>`
  border-bottom: none;
  display: flex;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  justify-content: ${({ align }) => align && getAlign(align)};

  &.noPadding {
    padding: 0;
  }
`;
