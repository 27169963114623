import styled from 'styled-components/macro';

import { Card } from 'src/components/atoms/Card';
import Modal from 'src/components/organisms/Modal';

export const StyledCard = styled(Card)`
  & > .CBody {
    padding: 1rem;
  }
`;

export const StyledModal = styled(Modal)`
  & > div > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    min-height: 60vh;
  }
`;
