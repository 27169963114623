import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from 'src/store';
import { Status } from 'src/ts/enums';
import { selectors } from '../matchingEntriesSlice';
import { getMatchingEntriesByEntryIdThunk } from '../services';

export const useMatchingEntriesByEntryId = (entryId: string) => {
  const dispatch = useAppDispatch();
  const matches = useSelector((state: RootState) => selectors.selectAllByEntryId(state, entryId));
  const status = useSelector(selectors.status);

  const onGetMatchingEntriesByEntryId = useCallback(
    (entryId: string) => {
      dispatch(getMatchingEntriesByEntryIdThunk(entryId));
    },
    [dispatch]
  );

  const isUninitialized = status === Status.IDLE;
  const isLoading = status === Status.LOADING;
  const isError = status === Status.ERROR;
  const isSuccess = status === Status.SUCCESS;

  return {
    matches,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onGetMatchingEntriesByEntryId
  };
};
