// Vendor
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Redux
import { selectors as myWorkSelectors, requestReviewEntryThunk } from 'src/features/myWork';
import { actions as proofsActions } from 'src/features/proofs';

// Hooks
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';

export const useEntryAssignment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { SnackTypes, showSnackbar, VariantType } = useSnackbarProvider();

  const assignedEntry = useSelector(myWorkSelectors.assignedEntry);
  const isLoadingSubmissionRequest = useSelector(myWorkSelectors.isLoadingSubmissionRequest);
  const [isEntryRequested, setIsEntryRequested] = useState(false);

  const requestEntry = () => {
    dispatch(proofsActions.reset());
    dispatch(requestReviewEntryThunk());
    setIsEntryRequested(true);
  };

  useEffect(() => {
    if (!isLoadingSubmissionRequest && isEntryRequested) {
      if (assignedEntry?.workflow === 'income_verification') {
        navigate(
          `/my-work/incomeVerification/${assignedEntry.income_verification_review_id}/${assignedEntry.id}`
        );
      } else if (assignedEntry) {
        navigate(`/my-work/${assignedEntry.folder_id}`);
      } else {
        showSnackbar(VariantType.warning, 'Nothing to review at this moment');
      }
      setIsEntryRequested(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SnackTypes.none, VariantType, isLoadingSubmissionRequest, isEntryRequested, showSnackbar]);

  return { requestEntry, isLoadingSubmissionRequest };
};
