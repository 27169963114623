import styled from 'styled-components/macro';
import Text from 'src/components/atoms/Text';

export const Content = styled.div`
  border: ${({ theme }) => `solid ${theme.color.gray[200]}`};
  border-width: 2px;
  padding: 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

export const StyledText = styled(Text)`
  width: 80%;
`;

export const StyledTittle = styled(Text)`
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
`;
