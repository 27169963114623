// Vendor
import styled from 'styled-components';

// Enums
import { DupTypes } from 'src/features/DUP/ts/enums';

// Constants
import { Color } from 'src/pages/DUPPage/constants';

export const Container = styled.div<{ type: DupTypes }>`
  border-top: 0.5rem solid ${({ type }) => Color[type].dark};
  background: ${({ type }) => Color[type].light};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3.125rem;
  text-align: center;
`;

export const Content = styled.div`
  max-width: 1280px;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
