import { actions } from 'src/features/identityVerification';
import { cleanFileName } from 'src/helpers';
import { useAppDispatch } from 'src/store';
import { IdentityApplicantsIdVerificationGetResponseExtended } from 'src/ts/interfaces';
import {
  getApplicantByVerificationIdThunk,
  getFcraIdentityReportPDFThunk,
  getIdentityReportPDFThunk
} from '../services';

const useIdentityVerificationReport = () => {
  const dispatch = useAppDispatch();

  const onGetApplicantByVerificationId = (id: string) => {
    dispatch(getApplicantByVerificationIdThunk(id));
  };

  const onResetApplicantByVerificationId = () => {
    dispatch(actions.resetApplicantByVerificationId());
  };

  const onDownloadFcraIdentityReport = async (
    applicant: IdentityApplicantsIdVerificationGetResponseExtended
  ) => {
    if (applicant.id === '' || applicant.id === undefined) {
      throw new Error('Missing the verification id to get the FCRA identityVerification report');
    }
    dispatch(
      getFcraIdentityReportPDFThunk({
        verificationId: applicant.id,
        fileName: `FCRA_Identity_Report_${cleanFileName(
          `${applicant.firstName} ${applicant.lastName}`
        )}.pdf`
      })
    );
  };

  const onResetGetDownloadFcraIdentityReport = () => {
    dispatch(actions.resetGetFcraIdentityReportPDF());
  };

  const onDownloadIdentityReport = async (
    applicant: IdentityApplicantsIdVerificationGetResponseExtended
  ) => {
    if (applicant.id === '' || applicant.id === undefined) {
      throw new Error('Missing the verification id to get the identityVerification report');
    }
    dispatch(
      getIdentityReportPDFThunk({
        verificationId: applicant.id,
        fileName: `Identity_Report_${cleanFileName(
          `${applicant.firstName} ${applicant.lastName}`
        )}.pdf`
      })
    );
  };

  const onResetGetDownloadIdentityReport = () => {
    dispatch(actions.resetGetIdentityReportPDF());
  };

  return {
    onGetApplicantByVerificationId,
    onResetApplicantByVerificationId,
    onDownloadFcraIdentityReport,
    onResetGetDownloadFcraIdentityReport,
    onDownloadIdentityReport,
    onResetGetDownloadIdentityReport
  };
};

export default useIdentityVerificationReport;
