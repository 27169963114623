// Vendor
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

// Components
import { DropdownMulticheck } from 'src/components/molecules/DropdownMulticheck';

// Hooks
import useCompaniesDDown from 'src/features/company/hooks/useCompaniesDDown';
import { useScrollObserver } from 'src/hooks';

// Helpers
import { calculatePagesCount, formatCheckboxData } from 'src/helpers';

// Types
import { CheckboxType } from 'src/components/atoms/Checkbox/types';

type CallbackParams = {
  ids: string;
  data: CheckboxType[];
  isCheckAll: boolean;
  q?: string;
};

type CompanyMultiSelectorProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<any>;
  onCallback: ({ ids, data, isCheckAll, q }: CallbackParams) => void;
};

const CompanyMultiSelector: React.FC<CompanyMultiSelectorProps> = ({ methods, onCallback }) => {
  const { onGetCompaniesDDown, getCompaniesDDown: companiesDDown } = useCompaniesDDown();

  const {
    data: companyData,
    isIdle: companyIsIdle,
    isLoading: companyIsLoading,
    count: companyCount,
    filter: companyFilter,
    error: companyError
  } = companiesDDown;

  useEffect(() => {
    if (companyIsIdle) {
      onGetCompaniesDDown(companyFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyIsIdle]);

  const { scrollObserver: getCompanyScrollObserver } = useScrollObserver({
    page: companyFilter.page,
    isLoading: companyIsLoading,
    callback: (page: number) => {
      onGetCompaniesDDown({
        ...companyFilter,
        page
      });
    },
    hasMore: companyFilter.page < calculatePagesCount(companyFilter.rowsPerPage, companyCount),
    dependency: [companyIsLoading, companyFilter]
  });

  const formattedData = useMemo(
    () =>
      formatCheckboxData({
        data: companyData || [],
        value: 'short_id',
        label: 'name'
      }),
    [companyData]
  );

  const handleCallback = useCallback(
    (params: CallbackParams) => {
      if (params.isCheckAll) {
        onGetCompaniesDDown({
          ...companyFilter,
          rowsPerPage: companyCount,
          page: 0,
          q: params.q
        });
      }

      onCallback(params);
    },
    [companyCount, companyFilter]
  );

  return (
    <Controller
      name="company"
      control={methods.control}
      render={({ field: { name } }) => (
        <DropdownMulticheck
          data={formattedData}
          totalRows={companyCount}
          onChange={handleCallback}
          name={name}
          placeholder="Company"
          size="md"
          itemRef={getCompanyScrollObserver}
          isLoading={companyIsLoading}
          isDisabled={companyError !== null}
          showSearch
          onSearch={(q) => {
            onGetCompaniesDDown({ ...companyFilter, page: 0, q });
          }}
          textSelectAll="All companies selected"
        />
      )}
    />
  );
};

export default CompanyMultiSelector;
