import { ThemeAttributes } from 'src/ts/interfaces/styled-components';
import styled from 'styled-components/macro';
import { TagColor, TagProps } from '.';

const getColor = (theme: ThemeAttributes, color: TagColor) => {
  switch (color) {
    case TagColor.snappt_blue:
      return theme.color.snappt[700];
    case TagColor.blue:
      return theme.color.blue[700];

    case TagColor.green:
      return theme.color.green[700];

    case TagColor.yellow:
      return theme.color.yellow[700];

    case TagColor.red:
      return theme.color.red[700];

    case TagColor.gray:
      return theme.color.gray[700];
    case TagColor.white:
      return theme.color.white;

    default:
      return theme.color.blue[700];
  }
};

const getBackground = (theme: ThemeAttributes, color: TagColor) => {
  switch (color) {
    case TagColor.snappt_blue:
      return theme.color.blue[200];

    case TagColor.blue:
      return theme.color.blue[300];

    case TagColor.green:
      return theme.color.green[100];

    case TagColor.yellow:
      return theme.color.yellow[100];

    case TagColor.red:
      return theme.color.red[100];

    case TagColor.gray:
      return theme.color.gray[200];

    default:
      return theme.color.blue[700];
  }
};

export const TagComponent = styled.span<Omit<TagProps, 'label'>>`
  display: inline-block;
  ${({ theme }) => theme.font.small}
  background: ${({ theme, showBackground, color }) =>
    showBackground && getBackground(theme, color)};
  color: ${({ theme, color }) => getColor(theme, color)};
  border-radius: 0.25rem;
  padding: 0.5rem;
  overflow: unset;
  width: fit-content;
  overflow-wrap: anywhere;
  align-self: center;
  white-space: nowrap;
`;
