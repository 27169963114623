import {
  EntryResultEnum,
  ProofResultCleanProceedWithCautionReasonEnum,
  ProofResultEditedReasonEnum,
  ProofResultInsufficientReasonEnum
} from 'src/ts/enums';
import { IValueLabel } from 'src/ts/interfaces/valueLabel';

export const results: IValueLabel[] = [
  {
    value: EntryResultEnum.Clean,
    label: 'Clean'
  },
  {
    value: EntryResultEnum.Edited,
    label: 'Edited'
  },
  {
    value: EntryResultEnum.Undetermined,
    label: 'Insufficient'
  },
  {
    value: EntryResultEnum.CleanProceedWithCaution,
    label: 'Clean - Proceed with Caution'
  }
];

export const reasonsCleanProceedWithCaution: IValueLabel[] = [
  {
    value: ProofResultCleanProceedWithCautionReasonEnum.NEW_DOCUMENT,
    label: 'New Document'
  },
  {
    value: ProofResultCleanProceedWithCautionReasonEnum.POSSIBLE_MALICIOUS,
    label: 'Possible Malicious'
  }
];

// TODO: The values should be handle by values and not by text sync with backend
export const reasons: IValueLabel[] = [
  {
    value: ProofResultInsufficientReasonEnum.SCANNED_DOCUMENTS,
    label: 'Scanned Documents'
  },
  {
    value: ProofResultInsufficientReasonEnum.PRINT_TO_PDF,
    label: 'Print to PDF Documents'
  },
  {
    value: ProofResultInsufficientReasonEnum.INVALID_DOCUMENT_TYPE,
    label: 'Invalid Document Type'
  },
  {
    value: ProofResultInsufficientReasonEnum.MERGED_DOCUMENT,
    label: 'Merged Document'
  },
  {
    value: ProofResultInsufficientReasonEnum.FOREIGN_ISSUED_DOCUMENT,
    label: 'Foreign Issued Document'
  },
  {
    value: ProofResultInsufficientReasonEnum.CROPPED_DOCUMENT,
    label: 'Cropped Document'
  },
  {
    value: ProofResultInsufficientReasonEnum.CUSTOM,
    label: 'Custom'
  }
];

// TODO: The values should be handle by values sync with backend
export const reasonsEdited: IValueLabel[] = [
  {
    value: ProofResultEditedReasonEnum.TEXT_INSERTION,
    label: 'Text Insertion'
  },
  {
    value: ProofResultEditedReasonEnum.FRAUDULENT_PDF_PRODUCER,
    label: 'Fraudulent PDF Producer'
  },
  {
    value: ProofResultEditedReasonEnum.FONT_FAIL,
    label: 'Font Fail'
  },
  {
    value: ProofResultEditedReasonEnum.CREATION_DATE,
    label: 'Creation Date'
  }
];
