import { FC } from 'react';

import { Row } from 'src/components/atoms/Row';
import { IProof } from 'src/ts/interfaces';
import { createExtractedData } from '../documentAndTestMetada';
import Field from './Field';

const DocumentMetadata: FC<Pick<IProof, 'extracted_meta'>> = ({ extracted_meta }) => {
  const extractedData = Object.entries(createExtractedData(extracted_meta));

  return (
    <Row direction="column" gap={0.5}>
      {extractedData.map(([key, value]) => (
        <Field key={key} title={key} content={value as string} />
      ))}
    </Row>
  );
};

export default DocumentMetadata;
