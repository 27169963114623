import { CheckboxData } from 'src/components/atoms/Checkbox/types';
import { actions } from 'src/features/users/usersSlice';
import { formatLabelValue } from 'src/helpers';
import { useAppDispatch } from 'src/store';
import { IProperty } from 'src/ts/interfaces';
import { getUnassignedProperties } from '../services';

export type IGetUnassignedProperties = {
  companyId: string;
  q?: string;
  rowsPerPage?: number;
  page?: number;
  assignedProperties?: CheckboxData[];
};

const useUserUnassignedProperties = () => {
  const dispatch = useAppDispatch();

  const getFormattedProperties = (properties?: IProperty[] | null) => {
    if (!properties) return [];

    const formated = formatLabelValue({
      data: properties,
      value: 'id',
      label: 'name'
    });

    return formated
      .map((e) => {
        return {
          ...e,
          isChecked: false,
          isVisible: true
        };
      })
      .sort((a, b) => {
        const x = a.label?.toString().toLowerCase() || '';
        const y = b.label?.toString().toLowerCase() || '';
        return x < y ? -1 : x > y ? 1 : 0;
      });
  };

  const onResetUnassignedProperties = () => {
    dispatch(actions.resetGetUnassignedProperties());
  };

  const onGetUnassignedProperties = (args: IGetUnassignedProperties) => {
    const { companyId, q, rowsPerPage, page, assignedProperties } = args;

    if (companyId) {
      const search = q ? decodeURIComponent(q) : '';
      const getAssignedPropertyIds = () => {
        if (!assignedProperties || assignedProperties?.length === 0) return '';
        return `,id!@>${assignedProperties.map((property) => property.value).join('|')}`;
      };

      dispatch(
        getUnassignedProperties({
          q: search,
          filter: `status=ACTIVE,company_id=${companyId}${getAssignedPropertyIds()}`,
          sort: 'name:asc',
          rowsPerPage,
          page: search ? 0 : page
        })
      );
    }
  };

  return {
    getFormattedProperties,
    onGetUnassignedProperties,
    onResetUnassignedProperties
  };
};

export default useUserUnassignedProperties;
