import React from 'react';

export type IFrameProps = { url: string; title?: string };
const IFrame: React.FC<IFrameProps> = (props: IFrameProps) => {
  const { url, title = '' } = props;
  return (
    <iframe title={title} src={url} frameBorder={0}>
      We are sorry. Your browser does not support iFrames.
    </iframe>
  );
};

export default IFrame;
