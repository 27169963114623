import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;

  .heading {
    margin-bottom: 1.5rem;
  }

  .listContainer {
    width: 100%;
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .Container {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  section {
    &.content {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

  .paginator__ListView {
    padding: 0.5rem 1rem;
    margin-bottom: -0.875rem;
  }
`;
