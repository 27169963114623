// Vendor
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Types
import { CheckboxData, CheckboxType } from 'src/components/atoms/Checkbox/types';
import { IProperty } from 'src/ts/interfaces';

// Hooks
import useUserUnassignedProperties, {
  IGetUnassignedProperties
} from 'src/features/users/hooks/useUserUnassignedProperties';
import { useScrollObserver } from 'src/hooks';

// Redux
import { selectors as usersSelectors } from 'src/features/users/usersSlice';

type useSelectPropertiesProps = {
  properties: IProperty[];
  companySelected: string;
};

const useSelectProperties = (props: useSelectPropertiesProps) => {
  const { properties, companySelected } = props;

  const { getFormattedProperties, onGetUnassignedProperties } = useUserUnassignedProperties();

  const getUnassignedData = useSelector(usersSelectors.getUnassignedProperties.data);
  const getUnassignedIsIdle = useSelector(usersSelectors.getUnassignedProperties.isIdle);
  const getUnassignedIsLoading = useSelector(usersSelectors.getUnassignedProperties.isLoading);
  const getUnassignedError = useSelector(usersSelectors.getUnassignedProperties.error);
  const getUnassignedFilter = useSelector(usersSelectors.getUnassignedProperties.filter);
  const getUnassignedCount = useSelector(usersSelectors.getUnassignedProperties.count);

  const [assigned, setAssigned] = useState<CheckboxType[]>([]);

  const [unassigned, setUnassigned] = useState<CheckboxType[]>([]);
  const [unassignedPage, setUnassignedPage] = useState(0);

  const getData = (args: Pick<IGetUnassignedProperties, 'q' | 'page' | 'assignedProperties'>) => {
    const { q, page, assignedProperties } = args;

    setAssigned(assignedProperties || []);

    onGetUnassignedProperties({
      companyId: companySelected,
      rowsPerPage: getUnassignedFilter.rowsPerPage,
      assignedProperties,
      q,
      page: page || 0
    });
  };

  useEffect(() => {
    if (getUnassignedIsIdle && properties) {
      const assigned = getFormattedProperties(properties);
      getData({
        assignedProperties: assigned as CheckboxData[]
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUnassignedIsIdle, properties]);

  useEffect(() => {
    if (!getUnassignedIsIdle && !getUnassignedIsLoading) {
      setUnassigned(getFormattedProperties(getUnassignedData) as CheckboxData[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUnassignedIsIdle, getUnassignedIsLoading]);

  const haveMoreUnassigned = () => {
    const rest = getUnassignedFilter.rowsPerPage * (unassignedPage + 1);
    return rest < getUnassignedCount;
  };

  const { scrollObserver: unassignedScrollObserver } = useScrollObserver({
    page: unassignedPage,
    isLoading: getUnassignedIsLoading,
    callback: (page: number) => {
      if (getUnassignedFilter && getUnassignedFilter.page >= 0) {
        setUnassignedPage(page);
        getData({
          assignedProperties: assigned,
          page
        });
      }
    },
    hasMore: haveMoreUnassigned(),
    dependency: [getUnassignedIsLoading, getUnassignedFilter]
  });

  return {
    isLoadingUnassigned: getUnassignedIsLoading && getUnassignedCount === 0,
    isLoadingPage: getUnassignedIsLoading && getUnassignedCount !== 0,
    hasError: getUnassignedError !== null,
    unassigned,
    totalRows: getUnassignedCount,
    assigned,
    unassignedScrollObserver,
    getData
  };
};

export default useSelectProperties;
