export const TIME_ZONE = [
  {
    value: 'Pacific/Niue',
    label: '(GMT-11:00) Niue Time'
  },
  {
    value: 'Pacific/Pago_Pago',
    label: '(GMT-11:00) Samoa Standard Time'
  },
  {
    value: 'Pacific/Rarotonga',
    label: '(GMT-10:00) Cook Islands Standard Time'
  },
  {
    value: 'Pacific/Honolulu',
    label: '(GMT-10:00) Hawaii-Aleutian Standard Time'
  },
  {
    value: 'Pacific/Tahiti',
    label: '(GMT-10:00) Tahiti Time'
  },
  {
    value: 'Pacific/Marquesas',
    label: '(GMT-09:30) Marquesas Time'
  },
  {
    value: 'Pacific/Gambier',
    label: '(GMT-09:00) Gambier Time'
  },
  {
    value: 'America/Adak',
    label: '(GMT-09:00) Hawaii-Aleutian Time (Adak) '
  },
  {
    value: 'America/Anchorage',
    label: '(GMT-08:00) Alaska Time - Anchorage'
  },
  {
    value: 'America/Juneau',
    label: '(GMT-08:00) Alaska Time - Juneau'
  },
  {
    value: 'America/Metlakatla',
    label: '(GMT-08:00) Alaska Time - Metlakatla'
  },
  {
    value: 'America/Nome',
    label: '(GMT-08:00) Alaska Time - Nome'
  },
  {
    value: 'America/Sitka',
    label: '(GMT-08:00) Alaska Time - Sitka'
  },
  {
    value: 'America/Yakutat',
    label: '(GMT-08:00) Alaska Time - Yakutat'
  },
  {
    value: 'Pacific/Pitcairn',
    label: '(GMT-08:00) Pitcairn Time'
  },
  {
    value: 'America/Hermosillo',
    label: '(GMT-07:00) Mexican Pacific Standard Time'
  },
  {
    value: 'America/Dawson_Creek',
    label: '(GMT-07:00) Mountain Standard Time - Dawson Creek'
  },
  {
    value: 'America/Fort_Nelson',
    label: '(GMT-07:00) Mountain Standard Time - Fort Nelson'
  },
  {
    value: 'America/Phoenix',
    label: '(GMT-07:00) Mountain Standard Time - Phoenix'
  },
  {
    value: 'America/Los_Angeles',
    label: '(GMT-07:00) Pacific Time - Los Angeles'
  },
  {
    value: 'America/Tijuana',
    label: '(GMT-07:00) Pacific Time - Tijuana'
  },
  {
    value: 'America/Vancouver',
    label: '(GMT-07:00) Pacific Time - Vancouver'
  },
  {
    value: 'America/Dawson',
    label: '(GMT-07:00) Yukon Time - Dawson'
  },
  {
    value: 'America/Whitehorse',
    label: '(GMT-07:00) Yukon Time - Whitehorse'
  },
  {
    value: 'America/Belize',
    label: '(GMT-06:00) Central Standard Time - Belize'
  },
  {
    value: 'America/Costa_Rica',
    label: '(GMT-06:00) Central Standard Time - Costa Rica'
  },
  {
    value: 'America/El_Salvador',
    label: '(GMT-06:00) Central Standard Time - El Salvador'
  },
  {
    value: 'America/Guatemala',
    label: '(GMT-06:00) Central Standard Time - Guatemala'
  },
  {
    value: 'America/Managua',
    label: '(GMT-06:00) Central Standard Time - Managua'
  },
  {
    value: 'America/Regina',
    label: '(GMT-06:00) Central Standard Time - Regina'
  },
  {
    value: 'America/Swift_Current',
    label: '(GMT-06:00) Central Standard Time - Swift Current'
  },
  {
    value: 'America/Tegucigalpa',
    label: '(GMT-06:00) Central Standard Time - Tegucigalpa'
  },
  {
    value: 'Pacific/Easter',
    label: '(GMT-06:00) Easter Island Time'
  },
  {
    value: 'Pacific/Galapagos',
    label: '(GMT-06:00) Galapagos Time'
  },
  {
    value: 'America/Chihuahua',
    label: '(GMT-06:00) Mexican Pacific Time - Chihuahua'
  },
  {
    value: 'America/Mazatlan',
    label: '(GMT-06:00) Mexican Pacific Time - Mazatlan'
  },
  {
    value: 'America/Boise',
    label: '(GMT-06:00) Mountain Time - Boise'
  },
  {
    value: 'America/Cambridge_Bay',
    label: '(GMT-06:00) Mountain Time - Cambridge Bay'
  },
  {
    value: 'America/Denver',
    label: '(GMT-06:00) Mountain Time - Denver'
  },
  {
    value: 'America/Edmonton',
    label: '(GMT-06:00) Mountain Time - Edmonton'
  },
  {
    value: 'America/Inuvik',
    label: '(GMT-06:00) Mountain Time - Inuvik'
  },
  {
    value: 'America/Ojinaga',
    label: '(GMT-06:00) Mountain Time - Ojinaga'
  },
  {
    value: 'America/Yellowknife',
    label: '(GMT-06:00) Mountain Time - Yellowknife'
  },
  {
    value: 'America/Eirunepe',
    label: '(GMT-05:00) Acre Standard Time - Eirunepe'
  },
  {
    value: 'America/Rio_Branco',
    label: '(GMT-05:00) Acre Standard Time - Rio Branco'
  },
  {
    value: 'America/Bahia_Banderas',
    label: '(GMT-05:00) Central Time - Bahia Banderas'
  },
  {
    value: 'America/North_Dakota/Beulah',
    label: '(GMT-05:00) Central Time - Beulah, North Dakota'
  },
  {
    value: 'America/North_Dakota/Center',
    label: '(GMT-05:00) Central Time - Center, North Dakota'
  },
  {
    value: 'America/Chicago',
    label: '(GMT-05:00) Central Time - Chicago'
  },
  {
    value: 'America/Indiana/Knox',
    label: '(GMT-05:00) Central Time - Knox, Indiana'
  },
  {
    value: 'America/Matamoros',
    label: '(GMT-05:00) Central Time - Matamoros'
  },
  {
    value: 'America/Menominee',
    label: '(GMT-05:00) Central Time - Menominee'
  },
  {
    value: 'America/Merida',
    label: '(GMT-05:00) Central Time - Merida'
  },
  {
    value: 'America/Mexico_City',
    label: '(GMT-05:00) Central Time - Mexico City'
  },
  {
    value: 'America/Monterrey',
    label: '(GMT-05:00) Central Time - Monterrey'
  },
  {
    value: 'America/North_Dakota/New_Salem',
    label: '(GMT-05:00) Central Time - New Salem, North Dakota'
  },
  {
    value: 'America/Rainy_River',
    label: '(GMT-05:00) Central Time - Rainy River'
  },
  {
    value: 'America/Rankin_Inlet',
    label: '(GMT-05:00) Central Time - Rankin Inlet'
  },
  {
    value: 'America/Resolute',
    label: '(GMT-05:00) Central Time - Resolute'
  },
  {
    value: 'America/Indiana/Tell_City',
    label: '(GMT-05:00) Central Time - Tell City, Indiana'
  },
  {
    value: 'America/Winnipeg',
    label: '(GMT-05:00) Central Time - Winnipeg'
  },
  {
    value: 'America/Bogota',
    label: '(GMT-05:00) Colombia Standard Time'
  },
  {
    value: 'America/Cancun',
    label: '(GMT-05:00) Eastern Standard Time - Cancun'
  },
  {
    value: 'America/Jamaica',
    label: '(GMT-05:00) Eastern Standard Time - Jamaica'
  },
  {
    value: 'America/Panama',
    label: '(GMT-05:00) Eastern Standard Time - Panama'
  },
  {
    value: 'America/Guayaquil',
    label: '(GMT-05:00) Ecuador Time'
  },
  {
    value: 'America/Lima',
    label: '(GMT-05:00) Peru Standard Time'
  },
  {
    value: 'America/Boa_Vista',
    label: '(GMT-04:00) Amazon Standard Time - Boa Vista'
  },
  {
    value: 'America/Campo_Grande',
    label: '(GMT-04:00) Amazon Standard Time - Campo Grande'
  },
  {
    value: 'America/Cuiaba',
    label: '(GMT-04:00) Amazon Standard Time - Cuiaba'
  },
  {
    value: 'America/Manaus',
    label: '(GMT-04:00) Amazon Standard Time - Manaus'
  },
  {
    value: 'America/Porto_Velho',
    label: '(GMT-04:00) Amazon Standard Time - Porto Velho'
  },
  {
    value: 'America/Barbados',
    label: '(GMT-04:00) Atlantic Standard Time - Barbados'
  },
  {
    value: 'America/Martinique',
    label: '(GMT-04:00) Atlantic Standard Time - Martinique'
  },
  {
    value: 'America/Puerto_Rico',
    label: '(GMT-04:00) Atlantic Standard Time - Puerto Rico'
  },
  {
    value: 'America/Santo_Domingo',
    label: '(GMT-04:00) Atlantic Standard Time - Santo Domingo'
  },
  {
    value: 'America/La_Paz',
    label: '(GMT-04:00) Bolivia Time'
  },
  {
    value: 'America/Santiago',
    label: '(GMT-04:00) Chile Time'
  },
  {
    value: 'America/Havana',
    label: '(GMT-04:00) Cuba Time'
  },
  {
    value: 'America/Detroit',
    label: '(GMT-04:00) Eastern Time - Detroit'
  },
  {
    value: 'America/Grand_Turk',
    label: '(GMT-04:00) Eastern Time - Grand Turk'
  },
  {
    value: 'America/Indiana/Indianapolis',
    label: '(GMT-04:00) Eastern Time - Indianapolis'
  },
  {
    value: 'America/Iqaluit',
    label: '(GMT-04:00) Eastern Time - Iqaluit'
  },
  {
    value: 'America/Kentucky/Louisville',
    label: '(GMT-04:00) Eastern Time - Louisville'
  },
  {
    value: 'America/Indiana/Marengo',
    label: '(GMT-04:00) Eastern Time - Marengo, Indiana'
  },
  {
    value: 'America/Kentucky/Monticello',
    label: '(GMT-04:00) Eastern Time - Monticello, Kentucky'
  },
  {
    value: 'America/New_York',
    label: '(GMT-04:00) Eastern Time - New York'
  },
  {
    value: 'America/Nipigon',
    label: '(GMT-04:00) Eastern Time - Nipigon'
  },
  {
    value: 'America/Pangnirtung',
    label: '(GMT-04:00) Eastern Time - Pangnirtung'
  },
  {
    value: 'America/Indiana/Petersburg',
    label: '(GMT-04:00) Eastern Time - Petersburg, Indiana'
  },
  {
    value: 'America/Port-au-Prince',
    label: '(GMT-04:00) Eastern Time - Port-au-Prince'
  },
  {
    value: 'America/Thunder_Bay',
    label: '(GMT-04:00) Eastern Time - Thunder Bay'
  },
  {
    value: 'America/Toronto',
    label: '(GMT-04:00) Eastern Time - Toronto'
  },
  {
    value: 'America/Indiana/Vevay',
    label: '(GMT-04:00) Eastern Time - Vevay, Indiana'
  },
  {
    value: 'America/Indiana/Vincennes',
    label: '(GMT-04:00) Eastern Time - Vincennes, Indiana'
  },
  {
    value: 'America/Indiana/Winamac',
    label: '(GMT-04:00) Eastern Time - Winamac, Indiana'
  },
  {
    value: 'America/Guyana',
    label: '(GMT-04:00) Guyana Time'
  },
  {
    value: 'America/Asuncion',
    label: '(GMT-04:00) Paraguay Time'
  },
  {
    value: 'America/Caracas',
    label: '(GMT-04:00) Venezuela Time'
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: '(GMT-03:00) Argentina Standard Time - Buenos Aires'
  },
  {
    value: 'America/Argentina/Catamarca',
    label: '(GMT-03:00) Argentina Standard Time - Catamarca'
  },
  {
    value: 'America/Argentina/Cordoba',
    label: '(GMT-03:00) Argentina Standard Time - Cordoba'
  },
  {
    value: 'America/Argentina/Jujuy',
    label: '(GMT-03:00) Argentina Standard Time - Jujuy'
  },
  {
    value: 'America/Argentina/La_Rioja',
    label: '(GMT-03:00) Argentina Standard Time - La Rioja'
  },
  {
    value: 'America/Argentina/Mendoza',
    label: '(GMT-03:00) Argentina Standard Time - Mendoza'
  },
  {
    value: 'America/Argentina/Rio_Gallegos',
    label: '(GMT-03:00) Argentina Standard Time - Rio Gallegos'
  },
  {
    value: 'America/Argentina/Salta',
    label: '(GMT-03:00) Argentina Standard Time - Salta'
  },
  {
    value: 'America/Argentina/San_Juan',
    label: '(GMT-03:00) Argentina Standard Time - San Juan'
  },
  {
    value: 'America/Argentina/San_Luis',
    label: '(GMT-03:00) Argentina Standard Time - San Luis'
  },
  {
    value: 'America/Argentina/Tucuman',
    label: '(GMT-03:00) Argentina Standard Time - Tucuman'
  },
  {
    value: 'America/Argentina/Ushuaia',
    label: '(GMT-03:00) Argentina Standard Time - Ushuaia'
  },
  {
    value: 'Atlantic/Bermuda',
    label: '(GMT-03:00) Atlantic Time - Bermuda'
  },
  {
    value: 'America/Glace_Bay',
    label: '(GMT-03:00) Atlantic Time - Glace Bay'
  },
  {
    value: 'America/Goose_Bay',
    label: '(GMT-03:00) Atlantic Time - Goose Bay'
  },
  {
    value: 'America/Halifax',
    label: '(GMT-03:00) Atlantic Time - Halifax'
  },
  {
    value: 'America/Moncton',
    label: '(GMT-03:00) Atlantic Time - Moncton'
  },
  {
    value: 'America/Thule',
    label: '(GMT-03:00) Atlantic Time - Thule'
  },
  {
    value: 'America/Araguaina',
    label: '(GMT-03:00) Brasilia Standard Time - Araguaina'
  },
  {
    value: 'America/Bahia',
    label: '(GMT-03:00) Brasilia Standard Time - Bahia'
  },
  {
    value: 'America/Belem',
    label: '(GMT-03:00) Brasilia Standard Time - Belem'
  },
  {
    value: 'America/Fortaleza',
    label: '(GMT-03:00) Brasilia Standard Time - Fortaleza'
  },
  {
    value: 'America/Maceio',
    label: '(GMT-03:00) Brasilia Standard Time - Maceio'
  },
  {
    value: 'America/Recife',
    label: '(GMT-03:00) Brasilia Standard Time - Recife'
  },
  {
    value: 'America/Santarem',
    label: '(GMT-03:00) Brasilia Standard Time - Santarem'
  },
  {
    value: 'America/Sao_Paulo',
    label: '(GMT-03:00) Brasilia Standard Time - Sao Paulo'
  },
  {
    value: 'Atlantic/Stanley',
    label: '(GMT-03:00) Falkland Islands Standard Time'
  },
  {
    value: 'America/Cayenne',
    label: '(GMT-03:00) French Guiana Time'
  },
  {
    value: 'Antarctica/Palmer',
    label: '(GMT-03:00) Palmer Time'
  },
  {
    value: 'America/Punta_Arenas',
    label: '(GMT-03:00) Punta Arenas Time'
  },
  {
    value: 'Antarctica/Rothera',
    label: '(GMT-03:00) Rothera Time'
  },
  {
    value: 'America/Paramaribo',
    label: '(GMT-03:00) Suriname Time'
  },
  {
    value: 'America/Montevideo',
    label: '(GMT-03:00) Uruguay Standard Time'
  },
  {
    value: 'America/St_Johns',
    label: '(GMT-02:30) Newfoundland Time'
  },
  {
    value: 'America/Noronha',
    label: '(GMT-02:00) Fernando de Noronha Standard Time'
  },
  {
    value: 'Atlantic/South_Georgia',
    label: '(GMT-02:00) South Georgia Time'
  },
  {
    value: 'America/Miquelon',
    label: '(GMT-02:00) St. Pierre &amp; Miquelon Time'
  },
  {
    value: 'America/Nuuk',
    label: '(GMT-02:00) West Greenland Time'
  },
  {
    value: 'Atlantic/Cape_Verde',
    label: '(GMT-01:00) Cape Verde Standard Time'
  },
  {
    value: 'Atlantic/Azores',
    label: '(GMT+00:00) Azores Time'
  },
  {
    value: 'UTC',
    label: '(GMT+00:00) Coordinated Universal Time'
  },
  {
    value: 'America/Scoresbysund',
    label: '(GMT+00:00) East Greenland Time'
  },
  {
    value: 'Etc/GMT',
    label: '(GMT+00:00) Greenwich Mean Time'
  },
  {
    value: 'Africa/Abidjan',
    label: '(GMT+00:00) Greenwich Mean Time - Abidjan'
  },
  {
    value: 'Africa/Bissau',
    label: '(GMT+00:00) Greenwich Mean Time - Bissau'
  },
  {
    value: 'America/Danmarkshavn',
    label: '(GMT+00:00) Greenwich Mean Time - Danmarkshavn'
  },
  {
    value: 'Africa/Monrovia',
    label: '(GMT+00:00) Greenwich Mean Time - Monrovia'
  },
  {
    value: 'Atlantic/Reykjavik',
    label: '(GMT+00:00) Greenwich Mean Time - Reykjavik'
  },
  {
    value: 'Africa/Sao_Tome',
    label: '(GMT+00:00) Greenwich Mean Time - São Tomé'
  },
  {
    value: 'Africa/Algiers',
    label: '(GMT+01:00) Central European Standard Time - Algiers'
  },
  {
    value: 'Africa/Tunis',
    label: '(GMT+01:00) Central European Standard Time - Tunis'
  },
  {
    value: 'Europe/Dublin',
    label: '(GMT+01:00) Ireland Time'
  },
  {
    value: 'Africa/Casablanca',
    label: '(GMT+01:00) Morocco Time'
  },
  {
    value: 'Europe/London',
    label: '(GMT+01:00) United Kingdom Time'
  },
  {
    value: 'Africa/Lagos',
    label: '(GMT+01:00) West Africa Standard Time - Lagos'
  },
  {
    value: 'Africa/Ndjamena',
    label: '(GMT+01:00) West Africa Standard Time - Ndjamena'
  },
  {
    value: 'Atlantic/Canary',
    label: '(GMT+01:00) Western European Time - Canary'
  },
  {
    value: 'Atlantic/Faroe',
    label: '(GMT+01:00) Western European Time - Faroe'
  },
  {
    value: 'Europe/Lisbon',
    label: '(GMT+01:00) Western European Time - Lisbon'
  },
  {
    value: 'Atlantic/Madeira',
    label: '(GMT+01:00) Western European Time - deira'
  },
  {
    value: 'Africa/El_Aaiun',
    label: '(GMT+01:00) Western Sahara Time'
  },
  {
    value: 'Africa/Juba',
    label: '(GMT+02:00) Central Africa Time - Juba'
  },
  {
    value: 'Africa/Khartoum',
    label: '(GMT+02:00) Central Africa Time - Khartoum'
  },
  {
    value: 'Africa/Maputo',
    label: '(GMT+02:00) Central Africa Time - Maputo'
  },
  {
    value: 'Africa/Windhoek',
    label: '(GMT+02:00) Central Africa Time - Windhoek'
  },
  {
    value: 'Europe/Amsterdam',
    label: '(GMT+02:00) Central European Time - Amsterdam'
  },
  {
    value: 'Europe/Andorra',
    label: '(GMT+02:00) Central European Time - Andorra'
  },
  {
    value: 'Europe/Belgrade',
    label: '(GMT+02:00) Central European Time - Belgrade'
  },
  {
    value: 'Europe/Berlin',
    label: '(GMT+02:00) Central European Time - Berlin'
  },
  {
    value: 'Europe/Brussels',
    label: '(GMT+02:00) Central European Time - Brussels'
  },
  {
    value: 'Europe/Budapest',
    label: '(GMT+02:00) Central European Time - Budapest'
  },
  {
    value: 'Africa/Ceuta',
    label: '(GMT+02:00) Central European Time - Ceuta'
  },
  {
    value: 'Europe/Copenhagen',
    label: '(GMT+02:00) Central European Time - Copenhagen'
  },
  {
    value: 'Europe/Gibraltar',
    label: '(GMT+02:00) Central European Time - Gibraltar'
  },
  {
    value: 'Europe/Luxembourg',
    label: '(GMT+02:00) Central European Time - Luxembourg'
  },
  {
    value: 'Europe/Madrid',
    label: '(GMT+02:00) Central European Time - Madrid'
  },
  {
    value: 'Europe/Malta',
    label: '(GMT+02:00) Central European Time - Malta'
  },
  {
    value: 'Europe/Monaco',
    label: '(GMT+02:00) Central European Time - Monaco'
  },
  {
    value: 'Europe/Oslo',
    label: '(GMT+02:00) Central European Time - Oslo'
  },
  {
    value: 'Europe/Paris',
    label: '(GMT+02:00) Central European Time - Paris'
  },
  {
    value: 'Europe/Prague',
    label: '(GMT+02:00) Central European Time - Prague'
  },
  {
    value: 'Europe/Rome',
    label: '(GMT+02:00) Central European Time - Rome'
  },
  {
    value: 'Europe/Stockholm',
    label: '(GMT+02:00) Central European Time - Stockholm'
  },
  {
    value: 'Europe/Tirane',
    label: '(GMT+02:00) Central European Time - Tirane'
  },
  {
    value: 'Europe/Vienna',
    label: '(GMT+02:00) Central European Time - Vienna'
  },
  {
    value: 'Europe/Warsaw',
    label: '(GMT+02:00) Central European Time - Warsaw'
  },
  {
    value: 'Europe/Zurich',
    label: '(GMT+02:00) Central European Time - Zurich'
  },
  {
    value: 'Africa/Cairo',
    label: '(GMT+02:00) Eastern European Standard Time - Cairo'
  },
  {
    value: 'Europe/Kaliningrad',
    label: '(GMT+02:00) Eastern European Standard Time - Kaliningrad'
  },
  {
    value: 'Africa/Tripoli',
    label: '(GMT+02:00) Eastern European Standard Time - Tripoli'
  },
  {
    value: 'Africa/Johannesburg',
    label: '(GMT+02:00) South Africa Standard Time'
  },
  {
    value: 'Antarctica/Troll',
    label: '(GMT+02:00) Troll Time'
  },
  {
    value: 'Asia/Baghdad',
    label: '(GMT+03:00) Arabian Standard Time - Baghdad'
  },
  {
    value: 'Asia/Qatar',
    label: '(GMT+03:00) Arabian Standard Time - Qatar'
  },
  {
    value: 'Asia/Riyadh',
    label: '(GMT+03:00) Arabian Standard Time - Riyadh'
  },
  {
    value: 'Africa/Nairobi',
    label: '(GMT+03:00) East Africa Time'
  },
  {
    value: 'Asia/Amman',
    label: '(GMT+03:00) Eastern European Time - Amman'
  },
  {
    value: 'Europe/Athens',
    label: '(GMT+03:00) Eastern European Time - Athens'
  },
  {
    value: 'Asia/Beirut',
    label: '(GMT+03:00) Eastern European Time - Beirut'
  },
  {
    value: 'Europe/Bucharest',
    label: '(GMT+03:00) Eastern European Time - Bucharest'
  },
  {
    value: 'Europe/Chisinau',
    label: '(GMT+03:00) Eastern European Time - Chisinau'
  },
  {
    value: 'Asia/Damascus',
    label: '(GMT+03:00) Eastern European Time - Damascus'
  },
  {
    value: 'Asia/Gaza',
    label: '(GMT+03:00) Eastern European Time - Gaza'
  },
  {
    value: 'Asia/Hebron',
    label: '(GMT+03:00) Eastern European Time - Hebron'
  },
  {
    value: 'Europe/Helsinki',
    label: '(GMT+03:00) Eastern European Time - Helsinki'
  },
  {
    value: 'Europe/Kiev',
    label: '(GMT+03:00) Eastern European Time - Kyiv'
  },
  {
    value: 'Asia/Nicosia',
    label: '(GMT+03:00) Eastern European Time -Nicosia'
  },
  {
    value: 'Europe/Riga',
    label: '(GMT+03:00) Eastern European Time - Riga'
  },
  {
    value: 'Europe/Sofia',
    label: '(GMT+03:00) Eastern European Time - Sofia'
  },
  {
    value: 'Europe/Tallinn',
    label: '(GMT+03:00) Eastern European Time - Tallinn'
  },
  {
    value: 'Europe/Uzhgorod',
    label: '(GMT+03:00) Eastern European Time - Uzhhorod'
  },
  {
    value: 'Europe/Vilnius',
    label: '(GMT+03:00) Eastern European Time - Vilnius'
  },
  {
    value: 'Europe/Zaporozhye',
    label: '(GMT+03:00) Eastern European Time - Zaporozhye'
  },
  {
    value: 'Asia/Famagusta',
    label: '(GMT+03:00) Famagusta Time'
  },
  {
    value: 'Asia/Jerusalem',
    label: '(GMT+03:00) Israel Time'
  },
  {
    value: 'Europe/Kirov',
    label: '(GMT+03:00) Kirov Time'
  },
  {
    value: 'Europe/Minsk',
    label: '(GMT+03:00) Moscow Standard Time - Minsk'
  },
  {
    value: 'Europe/Moscow',
    label: '(GMT+03:00) Moscow Standard Time - Moscow'
  },
  {
    value: 'Europe/Simferopol',
    label: '(GMT+03:00) Moscow Standard Time - Simferopol'
  },
  {
    value: 'Europe/Istanbul',
    label: '(GMT+03:00) Turkey Time'
  },
  {
    value: 'Europe/Volgograd',
    label: '(GMT+03:00) Volgograd Standard Time'
  },
  {
    value: 'Asia/Yerevan',
    label: '(GMT+04:00) Armenia Standard Time'
  },
  {
    value: 'Europe/Astrakhan',
    label: '(GMT+04:00) Astrakhan Time'
  },
  {
    value: 'Asia/Baku',
    label: '(GMT+04:00) Azerbaijan Standard Time'
  },
  {
    value: 'Asia/Tbilisi',
    label: '(GMT+04:00) Georgia Standard Time'
  },
  {
    value: 'Asia/Dubai',
    label: '(GMT+04:00) Gulf Standard Time'
  },
  {
    value: 'Indian/Mauritius',
    label: '(GMT+04:00) Mauritius Standard Time'
  },
  {
    value: 'Indian/Reunion',
    label: '(GMT+04:00) Réunion Time'
  },
  {
    value: 'Europe/Samara',
    label: '(GMT+04:00) Samara Standard Time'
  },
  {
    value: 'Europe/Saratov',
    label: '(GMT+04:00) Saratov Time'
  },
  {
    value: 'Indian/Mahe',
    label: '(GMT+04:00) Seychelles Time'
  },
  {
    value: 'Europe/Ulyanovsk',
    label: '(GMT+04:00) Ulyanovsk Time'
  },
  {
    value: 'Asia/Kabul',
    label: '(GMT+04: 30) Afghanistan Time'
  },
  {
    value: 'Asia/Tehran',
    label: '(GMT+04: 30) Iran Time'
  },
  {
    value: 'Indian/Kerguelen',
    label: '(GMT+05:00) French Southern &amp; Antarctic Time'
  },
  {
    value: 'Indian/Maldives',
    label: '(GMT+05:00) Maldives Time'
  },
  {
    value: 'Antarctica/Mawson',
    label: '(GMT+05:00) Mawson Time'
  },
  {
    value: 'Asia/Karachi',
    label: '(GMT+05:00) Pakistan Standard Time'
  },
  {
    value: 'Asia/Dushanbe',
    label: '(GMT+05:00) Tajikistan Time'
  },
  {
    value: 'Asia/Ashgabat',
    label: '(GMT+05:00) Turkmenistan Standard Time'
  },
  {
    value: 'Asia/Samarkand',
    label: '(GMT+05:00) Uzbekistan Standard Time - Samarkand'
  },
  {
    value: 'Asia/Tashkent',
    label: '(GMT+05:00) Uzbekistan Standard Time - Tashkent'
  },
  {
    value: 'Asia/Aqtau',
    label: '(GMT+05:00) West Kazakhstan Time - Aqtau'
  },
  {
    value: 'Asia/Aqtobe',
    label: '(GMT+05:00) West Kazakhstan Time - Aqtobe'
  },
  {
    value: 'Asia/Atyrau',
    label: '(GMT+05:00) West Kazakhstan Time - Atyrau'
  },
  {
    value: 'Asia/Oral',
    label: '(GMT+05:00) West Kazakhstan Time - Oral'
  },
  {
    value: 'Asia/Qyzylorda',
    label: '(GMT+05:00) West Kazakhstan Time - Qyzylorda'
  },
  {
    value: 'Asia/Yekaterinburg',
    label: '(GMT+05:00) Yekaterinburg Standard Time'
  },
  {
    value: 'Asia/Colombo',
    label: '(GMT+05:30) India Standard Time - Colombo'
  },
  {
    value: 'Asia/Kolkata',
    label: '(GMT+05:30) India Standard Time - Kolkata'
  },
  {
    value: 'Asia/Kathmandu',
    label: '(GMT+05:45) Nepal Time'
  },
  {
    value: 'Asia/Dhaka',
    label: '(GMT+06:00) Bangladesh Standard Time'
  },
  {
    value: 'Asia/Thimphu',
    label: '(GMT+06:00) Bhutan Time'
  },
  {
    value: 'Asia/Almaty',
    label: '(GMT+06:00) East Kazakhstan Time - Almaty'
  },
  {
    value: 'Asia/Qostanay',
    label: '(GMT+06:00) East Kazakhstan Time - Kostanay'
  },
  {
    value: 'Indian/Chagos',
    label: '(GMT+06:00) Indian Ocean Time'
  },
  {
    value: 'Asia/Bishkek',
    label: '(GMT+06:00) Kyrgyzstan Time'
  },
  {
    value: 'Asia/Omsk',
    label: '(GMT+06:00) Omsk Standard Time'
  },
  {
    value: 'Asia/Urumqi',
    label: '(GMT+06:00) Urumqi Time'
  },
  {
    value: 'Antarctica/Vostok',
    label: '(GMT+06:00) Vostok Time'
  },
  {
    value: 'Indian/Cocos',
    label: '(GMT+06:30) Cocos Islands Time'
  },
  {
    value: 'Asia/Yangon',
    label: '(GMT+06:30) Myanmar Time'
  },
  {
    value: 'Asia/Barnaul',
    label: '(GMT+07:00) Barnaul Time'
  },
  {
    value: 'Indian/Christmas',
    label: '(GMT+07:00) Christmas Island Time'
  },
  {
    value: 'Antarctica/Davis',
    label: '(GMT+07:00) Davis Time'
  },
  {
    value: 'Asia/Hovd',
    label: '(GMT+07:00) Hovd Standard Time'
  },
  {
    value: 'Asia/Bangkok',
    label: '(GMT+07:00) Indochina Time - Bangkok'
  },
  {
    value: 'Asia/Ho_Chi_Minh',
    label: '(GMT+07:00) Indochina Time - Ho Chi Minh City'
  },
  {
    value: 'Asia/Krasnoyarsk',
    label: '(GMT+07:00) Krasnoyarsk Standard Time - Krasnoyarsk'
  },
  {
    value: 'Asia/Novokuznetsk',
    label: '(GMT+07:00) Krasnoyarsk Standard Time - Novokuznetsk'
  },
  {
    value: 'Asia/Novosibirsk',
    label: '(GMT+07:00) Novosibirsk Standard Time'
  },
  {
    value: 'Asia/Tomsk',
    label: '(GMT+07:00) Tomsk Time'
  },
  {
    value: 'Asia/Jakarta',
    label: '(GMT+07:00) Western Indonesia Time - Jakarta'
  },
  {
    value: 'Asia/Pontianak',
    label: '(GMT+07:00) Western Indonesia Time - Pontianak'
  },
  {
    value: 'Australia/Perth',
    label: '(GMT+08:00) Australian Western Standard Time'
  },
  {
    value: 'Asia/Brunei',
    label: '(GMT+08:00) Brunei Darussalam Time'
  },
  {
    value: 'Asia/Makassar',
    label: '(GMT+08:00) Central Indonesia Time'
  },
  {
    value: 'Asia/Macau',
    label: '(GMT+08:00) China Standard Time - Macao'
  },
  {
    value: 'Asia/Shanghai',
    label: '(GMT+08:00) China Standard Time - Shanghai'
  },
  {
    value: 'Asia/Hong_Kong',
    label: '(GMT+08:00) Hong Kong Standard Time'
  },
  {
    value: 'Asia/Irkutsk',
    label: '(GMT+08:00) Irkutsk Standard Time'
  },
  {
    value: 'Asia/Kuala_Lumpur',
    label: '(GMT+08:00) Malaysia Time - Kuala Lumpur'
  },
  {
    value: 'Asia/Kuching',
    label: '(GMT+08:00) Malaysia Time - Kuching'
  },
  {
    value: 'Asia/Manila',
    label: '(GMT+08:00) Philippine Standard Time'
  },
  {
    value: 'Asia/Singapore',
    label: '(GMT+08:00) Singapore Standard Time'
  },
  {
    value: 'Asia/Taipei',
    label: '(GMT+08:00) Taipei Standard Time'
  },
  {
    value: 'Asia/Choibalsan',
    label: '(GMT+08:00) Ulaanbaatar Standard Time - Choibalsan'
  },
  {
    value: 'Asia/Ulaanbaatar',
    label: '(GMT+08:00) Ulaanbaatar Standard Time - Ulaanbaatar'
  },
  {
    value: 'Australia/Eucla',
    label: '(GMT+08: 45) Australian Central Western Standard Time'
  },
  {
    value: 'Asia/Dili',
    label: '(GMT+09:00) East Timor Time'
  },
  {
    value: 'Asia/Jayapura',
    label: '(GMT+09:00) Eastern Indonesia Time'
  },
  {
    value: 'Asia/Tokyo',
    label: '(GMT+09:00) Japan Standard Time'
  },
  {
    value: 'Asia/Pyongyang',
    label: '(GMT+09:00) Korean Standard Time - Pyongyang'
  },
  {
    value: 'Asia/Seoul',
    label: '(GMT+09:00) Korean Standard Time - Seoul'
  },
  {
    value: 'Pacific/Palau',
    label: '(GMT+09:00) Palau Time'
  },
  {
    value: 'Asia/Chita',
    label: '(GMT+09:00) Yakutsk Standard Time - Chita'
  },
  {
    value: 'Asia/Khandyga',
    label: '(GMT+09:00) Yakutsk Standard Time - Khandyga'
  },
  {
    value: 'Asia/Yakutsk',
    label: '(GMT+09:00) Yakutsk Standard Time - Yakutsk'
  },
  {
    value: 'Australia/Darwin',
    label: '(GMT+09:30) Australian Central Standard Time'
  },
  {
    value: 'Australia/Adelaide',
    label: '(GMT+09:30) Central Australia Time - Adelaide'
  },
  {
    value: 'Australia/Broken_Hill',
    label: '(GMT+09:30) Central Australia Time - Broken Hill'
  },
  {
    value: 'Australia/Brisbane',
    label: '(GMT+10:00) Australian Eastern Standard Time - Brisbane'
  },
  {
    value: 'Australia/Lindeman',
    label: '(GMT+10:00) Australian Eastern Standard Time - Lindeman'
  },
  {
    value: 'Pacific/Guam',
    label: '(GMT+10:00) Chamorro Standard Time'
  },
  {
    value: 'Pacific/Chuuk',
    label: '(GMT+10:00) Chuuk Time'
  },
  {
    value: 'Australia/Hobart',
    label: '(GMT+10:00) Eastern Australia Time - Hobart'
  },
  {
    value: 'Antarctica/Macquarie',
    label: '(GMT+10:00) Eastern Australia Time - Macquarie'
  },
  {
    value: 'Australia/Melbourne',
    label: '(GMT+10:00) Eastern Australia Time - Melbourne'
  },
  {
    value: 'Australia/Sydney',
    label: '(GMT+10:00) Eastern Australia Time - Sydney'
  },
  {
    value: 'Pacific/Port_Moresby',
    label: '(GMT+10:00) Papua New Guinea Time'
  },
  {
    value: 'Asia/Ust-Nera',
    label: '(GMT+10:00) Vladivostok Standard Time - Ust-Nera'
  },
  {
    value: 'Asia/Vladivostok',
    label: '(GMT+10:00) Vladivostok Standard Time - Vladivostok'
  },
  {
    value: 'Australia/Lord_Howe',
    label: '(GMT+10:30) Lord Howe Time'
  },
  {
    value: 'Pacific/Bougainville',
    label: '(GMT+11:00) Bougainville Time'
  },
  {
    value: 'Antarctica/Casey',
    label: '(GMT+11:00) Casey Time'
  },
  {
    value: 'Pacific/Kosrae',
    label: '(GMT+11:00) Kosrae Time'
  },
  {
    value: 'Asia/Magadan',
    label: '(GMT+11:00) Magadan Standard Time'
  },
  {
    value: 'Pacific/Noumea',
    label: '(GMT+11:00) New Caledonia Standard Time'
  },
  {
    value: 'Pacific/Norfolk',
    label: '(GMT+11:00) Norfolk Island Time'
  },
  {
    value: 'Pacific/Pohnpei',
    label: '(GMT+11:00) Ponape Time'
  },
  {
    value: 'Asia/Sakhalin',
    label: '(GMT+11:00) Sakhalin Standard Time'
  },
  {
    value: 'Pacific/Guadalcanal',
    label: '(GMT+11:00) Solomon Islands Time'
  },
  {
    value: 'Asia/Srednekolymsk',
    label: '(GMT+11:00) Srednekolymsk Time'
  },
  {
    value: 'Pacific/Efate',
    label: '(GMT+11:00) Vanuatu Standard Time'
  },
  {
    value: 'Asia/Anadyr',
    label: '(GMT+12:00) Anadyr Standard Time'
  },
  {
    value: 'Pacific/Fiji',
    label: '(GMT+12:00) Fiji Time'
  },
  {
    value: 'Pacific/Tarawa',
    label: '(GMT+12:00) Gilbert Islands Time'
  },
  {
    value: 'Pacific/Kwajalein',
    label: '(GMT+12:00) Marshall Islands Time - Kwajalein'
  },
  {
    value: 'Pacific/Majuro',
    label: '(GMT+12:00) Marshall Islands Time - Majuro'
  },
  {
    value: 'Pacific/Nauru',
    label: '(GMT+12:00) Nauru Time'
  },
  {
    value: 'Pacific/Auckland',
    label: '(GMT+12:00) New Zealand Time'
  },
  {
    value: 'Asia/Kamchatka',
    label: '(GMT+12:00) Petropavlovsk-Kamchatski Standard Time'
  },
  {
    value: 'Pacific/Funafuti',
    label: '(GMT+12:00) Tuvalu Time'
  },
  {
    value: 'Pacific/Wake',
    label: '(GMT+12:00) Wake Island Time'
  },
  {
    value: 'Pacific/Wallis',
    label: '(GMT+12:00) Wallis &amp; Futuna Time'
  },
  {
    value: 'Pacific/Chatham',
    label: '(GMT+12:45) Chatham Time'
  },
  {
    value: 'Pacific/Apia',
    label: '(GMT+13:00) Apia Standard Time'
  },
  {
    value: 'Pacific/Kanton',
    label: '(GMT+13:00) Phoenix Islands Time'
  },
  {
    value: 'Pacific/Fakaofo',
    label: '(GMT+13:00) Tokelau Time'
  },
  {
    value: 'Pacific/Tongatapu',
    label: '(GMT+13:00) Tonga Standard Time'
  },
  {
    value: 'Pacific/Kiritimati',
    label: '(GMT+14:00) Line Islands Time'
  }
];
