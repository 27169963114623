import { useEffect, useRef } from 'react';
import { Icon } from 'src/components/atoms/Icon';
import { cleanFileName, configVars } from 'src/helpers';
import { useScript } from 'src/hooks';
import ViewSDKClient from 'src/pdfViewer/ViewSDKClient';
import { EmbedMode } from 'src/ts/enums/pdfViewer';
import { Button, ButtonContainer, PDFViewerContainer } from './styles';

export interface PDFDocument {
  key: string;
  id: string;
  file: string;
  fileName: string;
}

interface IProps {
  id: string;
  file: string;
  fileName: string;
  embedMode: EmbedMode;
  height: string;
  showZoomControl?: boolean;
}
const PDFViewer = ({ id, file, fileName, embedMode, height, showZoomControl = false }: IProps) => {
  useScript('https://documentservices.adobe.com/view-sdk/viewer.js');

  const viewSDKClient = useRef(new ViewSDKClient());

  const onGeneratePDF = (
    id: string,
    file: string,
    fileName = '',
    idContainer = '',
    embedMode = EmbedMode.SIZED_CONTAINER
  ) => {
    viewSDKClient.current.ready().then(() => {
      viewSDKClient.current.previewFile(
        idContainer,
        {
          embedMode
        },
        file,
        cleanFileName(fileName),
        id,
        configVars.adobe.pdf_token
      );
    });
  };

  useEffect(() => {
    onGeneratePDF(id, file, fileName, `pdf-viewer-container-${id}`, embedMode);
  }, [file, fileName, embedMode]);

  const handleZoomIn = () => {
    viewSDKClient.current.zoomIn();
  };

  const handleZoomOut = () => {
    viewSDKClient.current.zoomOut();
  };

  return (
    <>
      <PDFViewerContainer id={`pdf-viewer-container-${id}`} height={height}></PDFViewerContainer>
      {showZoomControl && (
        <ButtonContainer>
          <Button onClick={handleZoomIn}>
            <Icon icon="zoom-in" />
          </Button>
          <Button onClick={handleZoomOut}>
            <Icon icon="zoom-out" />
          </Button>
        </ButtonContainer>
      )}
    </>
  );
};

export default PDFViewer;
