import { FC } from 'react';
import { SkeletonPieceColor } from 'src/components/atoms/SkeletonPiece';

import { Divider, StyledCard, StyledSkeletonPiece } from './styles';
export type LoaderMatchingTileProps = { isModal?: boolean };

const LoaderMatchingTile: FC<LoaderMatchingTileProps> = ({ isModal }) => {
  const rows = isModal ? 5 : 2;
  return (
    <StyledCard rounded border={!isModal} isModal={isModal}>
      {!isModal && (
        <Divider marginBottom={30}>
          <StyledSkeletonPiece height={20} width={140} color={SkeletonPieceColor.gray200} />
        </Divider>
      )}
      <>
        {Array(rows)
          .fill(0)
          .map((_, i) => {
            return (
              <Divider marginBottom={20} key={i}>
                <StyledSkeletonPiece height={24} width={200} color={SkeletonPieceColor.gray100} />
                <StyledSkeletonPiece
                  height={38}
                  width={isModal ? 70 : 50}
                  color={SkeletonPieceColor.gray100}
                />
                <StyledSkeletonPiece
                  height={38}
                  width={isModal ? 70 : 50}
                  color={SkeletonPieceColor.gray100}
                />
              </Divider>
            );
          })}
      </>
    </StyledCard>
  );
};

export default LoaderMatchingTile;
