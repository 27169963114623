import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPaginationRequest, ICompany } from 'src/ts/interfaces';
import api from './api';
import { NAME } from './constants';
import { updateCompanyLogo } from './utils';

export const getAll = createAsyncThunk(`${NAME}/getAll`, async (data: getPaginationRequest) => {
  try {
    const response = await api.getAll(data);
    return { ...(response || {}), q: data.q };
  } catch (error) {
    console.error(`${NAME}/getAll`, error);
    throw new Error('Error fetching the companies');
  }
});

export const getOne = createAsyncThunk(`${NAME}/getOne`, async (id: string) => {
  try {
    return await api.getCompany(id);
  } catch (error) {
    console.error(`${NAME}/getOne`, error);
    throw new Error('Error fetching the company');
  }
});

export const update = createAsyncThunk(
  `${NAME}/update`,
  async ({ companyId, data }: { companyId: string; data: ICompany }) => {
    try {
      const updateCompanyResponse = await api.updateCompany(companyId, { company: data });
      await updateCompanyLogo(updateCompanyResponse.data, data);
      return updateCompanyResponse;
    } catch (error) {
      console.error(`${NAME}/update`, error);
      throw new Error('Error updating the company');
    }
  }
);

export const getCompaniesDDown = createAsyncThunk(
  `${NAME}/getCompaniesDDown`,
  async (data: getPaginationRequest) => {
    try {
      return await api.getAll(data);
    } catch (error) {
      console.error(`${NAME}/getCompaniesDDown`, error);
      throw new Error('Error fetching the companies for the edit user information');
    }
  }
);
