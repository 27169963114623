// Vendor
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { getPaginationRequest, IEntryLog, IRejectedAction } from 'src/ts/interfaces';
import { NAME } from './constants';
import { getEntriesLogsThunk } from './services';

export type EntriesLogsState = {
  status: StatusTypes;
  data: IEntryLog[];
  error?: string | null;
  count: number;
  filters: getPaginationRequest;
};

const initialState: EntriesLogsState = {
  status: StatusTypes.IDLE,
  data: [],
  error: null,
  count: 0,
  filters: {
    page: 0,
    rowsPerPage: 10,
    filter: '',
    sort: 'inserted_at:desc'
  }
};

export const entriesLogsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    filtering: (state: EntriesLogsState, action) => {
      state.filters.filter = action.payload.filter;
      state.filters.page = action?.payload?.page;
      state.filters.rowsPerPage = action?.payload?.rowsPerPage;
    },
    pagination: (state: EntriesLogsState, action) => {
      state.filters.page = action?.payload?.page;
      state.filters.rowsPerPage = action?.payload?.rowsPerPage;
    },
    search: (state: EntriesLogsState, action) => {
      state.filters.q = action.payload.q;
      state.filters.page = action?.payload?.page;
      state.filters.rowsPerPage = action?.payload?.rowsPerPage;
    },
    sorting: (state: EntriesLogsState, action) => {
      state.filters.sort = action.payload.sort;
      state.filters.page = action?.payload?.page;
      state.filters.rowsPerPage = action?.payload?.rowsPerPage;
    }
  },
  extraReducers: (builder) => {
    getEntriesLogsReducer(builder);
  }
});

const getEntriesLogsReducer = (builder: ActionReducerMapBuilder<EntriesLogsState>) => {
  // Pending
  builder.addCase(getEntriesLogsThunk.pending, (state: EntriesLogsState) => {
    state.status = StatusTypes.LOADING;
    state.error = null;
  });

  // Rejected
  builder.addCase(
    getEntriesLogsThunk.rejected,
    (state: EntriesLogsState, action: IRejectedAction) => {
      state.status = StatusTypes.ERROR;
      state.error = action.error?.message || null;
    }
  );

  // Fulfilled
  builder.addCase(getEntriesLogsThunk.fulfilled, (state: EntriesLogsState, action: any) => {
    const { data, count } = action.payload;

    state.status = StatusTypes.SUCCESS;
    state.data = data as IEntryLog[];
    state.error = null;
    state.count = count;
  });
};

export const selectors = (state: RootState) => ({
  status: state[NAME].status,
  data: state[NAME].data,
  error: state[NAME].error,
  count: state[NAME].count,
  filters: state[NAME].filters
});

export const { reducer, actions } = entriesLogsSlice;
