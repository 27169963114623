import Row from 'src/components/atoms/Row/Row';
import SkeletonPiece, { SkeletonPieceColor } from 'src/components/atoms/SkeletonPiece';
import { Container } from './styles';

const LoaderCallBox: React.FC = () => {
  return (
    <Container data-testid="LoaderCallBox">
      <Row justify="space-between" alignItems="center">
        <Row gap={1} direction="column" justify="center">
          <SkeletonPiece width={325} height={36} color={SkeletonPieceColor.gray50} />
          <SkeletonPiece width={413} height={24} color={SkeletonPieceColor.gray100} />
        </Row>
        <Row>
          <SkeletonPiece width={160} height={44} color={SkeletonPieceColor.gray200} />
        </Row>
      </Row>
    </Container>
  );
};

export default LoaderCallBox;
