const replaceCommaByPipe = (str?: string) => {
  return (
    str
      ?.trim()
      .replace(/,+/g, '|')
      .replace(/\s*,\s*/g, '|') || ''
  );
};

export default replaceCommaByPipe;
