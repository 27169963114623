import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/store';
import { Status } from 'src/ts/enums';

import { selectors } from '../foldersSlice';
import { getFolderByIdThunk } from '../services';

export const useFolderById = (folderId: string) => {
  const dispatch = useDispatch();
  const folder = useSelector((state: RootState) => selectors.selectById(state, folderId));
  const status = useSelector(selectors.status);

  useEffect(() => {
    if (folderId && !folder) {
      dispatch(getFolderByIdThunk(folderId));
    }
  }, [folderId, folder, dispatch]);

  const isUninitialized = status === Status.IDLE;
  const isLoading = status === Status.LOADING;
  const isError = status === Status.ERROR;
  const isSuccess = status === Status.SUCCESS;

  return {
    folder,
    isUninitialized,
    isLoading,
    isError,
    isSuccess
  };
};
