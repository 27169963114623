// Vendor
import PropTypes from 'prop-types';

// Components
import { Icon as Logo } from 'src/components/atoms/Icon';
import Text, { TextAlign, TextColor, TextVariant } from 'src/components/atoms/Text';
import { LanguageSelector } from 'src/components/organisms/LanguageSelector';
import { Container, Content } from './styles';

// Helpers
import getInfo from 'src/features/DUP/helpers/getInfo';

// Types
import { IInformation } from 'src/features/DUP/form/ts/interfaces';

// Enums
import { DupTypes } from 'src/features/DUP/ts/enums';

export type HeaderProps = { type: DupTypes; information: IInformation };

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { type, information } = props;

  return (
    <Container type={type}>
      <Content>
        <Logo icon="logo-dup" />
        <Text color={TextColor.initial} variant={TextVariant.h3} align={TextAlign.center} isBold>
          {information.title}
        </Text>
        <LanguageSelector type={type} />
      </Content>
    </Container>
  );
};

Header.propTypes = {
  type: PropTypes.oneOf<DupTypes>(Object.values(DupTypes)).isRequired,
  information: PropTypes.shape({
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title_form: PropTypes.string.isRequired,
    check_previous_text: PropTypes.string.isRequired,
    instruction_upload_item_one: PropTypes.string.isRequired,
    instruction_upload_item_two: PropTypes.string.isRequired,
    instruction_upload_item_three: PropTypes.string.isRequired,
    tool_tip_message: PropTypes.string.isRequired,
    email_placeholder: PropTypes.string.isRequired,
    url_terms_of_service: PropTypes.string.isRequired,
    url_privacy_policy: PropTypes.string.isRequired
  }).isRequired
};

Header.defaultProps = {
  type: DupTypes.APPLICANT,
  information: getInfo(DupTypes.APPLICANT)
};

export default Header;
