/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Gap from 'src/components/atoms/Gap';
import { Loader } from 'src/components/atoms/Loader';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { ShowState } from 'src/components/molecules/ShowState';
import { DetailView } from 'src/components/templates/DetailView';
import useResources from 'src/features/resources/hooks/useResources';

const ResourcesPreviewPage: React.FC = () => {
  const { resourceId } = useParams();
  const navigate = useNavigate();

  const { onGetResource, getResource } = useResources();

  useEffect(() => {
    if (resourceId) {
      onGetResource(resourceId);
    }
  }, [resourceId]);

  if (getResource.error) {
    return (
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Please try again"
        message="Something went wrong"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (getResource.isLoading) {
    return <Loader />;
  }

  if (!getResource.isLoading && !getResource.data?.link) {
    return (
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Go back"
        message="Something went wrong<br/>The resource has not a file related"
        onClick={() => navigate('../resources')}
      />
    );
  }

  return (
    <DetailView back={{ to: '/resources', label: 'Go Back' }} title={getResource.data?.title || ''}>
      <div className="Container">
        {getResource.data?.description && (
          <>
            <Text variant={TextVariant.normal}>{getResource.data?.description}</Text>
            <Gap height={1} />
          </>
        )}
        <iframe title={getResource.data?.title} src={getResource.data?.link}>
          We sorry, your browser do not support iFrames
        </iframe>
      </div>
    </DetailView>
  );
};

export default ResourcesPreviewPage;
