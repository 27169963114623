// Vendor
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

// Helpers
import mergeArrayByKey from 'src/helpers/mergeArrayByKey';

// Enums
import { Status as StatusTypes } from 'src/ts/enums';

// Types
import { CheckboxData } from 'src/components/atoms/Checkbox/types';
import {
  getPaginationRequest,
  ICompany,
  IMetaAction,
  IPaginationRequest,
  IPropertiesResponseWithPage,
  IProperty,
  IRejectedAction,
  IUser,
  IUserResponse,
  IUsersResponseWithPage
} from 'src/ts/interfaces';

// Redux
import {
  changeSettingNotification,
  getAll,
  getByEmail,
  getOne,
  getUnassignedProperties,
  remove,
  update
} from './services';

export type StateData = {
  getAll: {
    status: StatusTypes;
    data?: IUser[] | null;
    error?: string | null;
    count?: number;
    filter: IPaginationRequest;
  };
  getOne: {
    status: StatusTypes;
    error?: string | null;
    data?: IUser | null;
  };
  getByEmail: {
    status: StatusTypes;
    data?: IUser[] | null;
    error?: string | null;
    count?: number;
    filter: getPaginationRequest;
  };
  update: {
    status: StatusTypes;
    error?: string | null;
    data?: IUser | null;
  };
  remove: {
    status: StatusTypes;
    error?: string | null;
  };
  getUnassignedProperties: {
    status: StatusTypes;
    data?: IProperty[] | null;
    error?: string | null;
    count?: number;
    filter: IPaginationRequest;
  };
  getCompaniesDDown: {
    status: StatusTypes;
    data?: ICompany[] | null;
    error?: string | null;
    count?: number;
    filter: IPaginationRequest;
  };
  unassignedPropertiesSelected: CheckboxData[] | [];
  assignedPropertiesSelected: CheckboxData[] | [];
};

export const initialState: StateData = {
  getAll: {
    status: StatusTypes.IDLE,
    data: [],
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 20,
      sort: 'name:asc'
    }
  },
  getOne: {
    status: StatusTypes.IDLE,
    data: null,
    error: null
  },
  getByEmail: {
    status: StatusTypes.IDLE,
    data: null,
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 1,
      sort: 'name:asc'
    }
  },
  update: {
    status: StatusTypes.IDLE,
    data: null,
    error: null
  },
  remove: {
    status: StatusTypes.IDLE,
    error: null
  },
  getUnassignedProperties: {
    status: StatusTypes.IDLE,
    data: null,
    error: null,
    count: 0,
    filter: {
      q: '',
      filter: '',
      page: 0,
      rowsPerPage: 20,
      sort: 'name:asc'
    }
  },
  getCompaniesDDown: {
    status: StatusTypes.IDLE,
    data: null,
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 20,
      sort: 'name:asc'
    }
  },
  unassignedPropertiesSelected: [],
  assignedPropertiesSelected: []
};

export const getAllReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getAll.pending, (state: StateData) => {
    state.getAll.status = StatusTypes.LOADING;
    state.getAll.error = null;
  });
  builder.addCase(getAll.rejected, (state: StateData, action: IRejectedAction) => {
    state.getAll.status = StatusTypes.ERROR;
    state.getAll.error = action.error?.message || null;
  });
  builder.addCase(
    getAll.fulfilled,
    (state: StateData, action: PayloadAction<IUsersResponseWithPage> & IMetaAction) => {
      const { data, count, page, rowsPerPage } = action.payload;
      const users = data as IUser[];

      if (action.meta?.arg?.isDDown) {
        state.getAll.data =
          users.length > 0 ? mergeArrayByKey(state.getAll.data || [], users, 'id') : [];
      } else {
        state.getAll.data = users;
      }

      state.getAll.status = StatusTypes.SUCCESS;
      state.getAll.count = count;
      state.getAll.filter.page = page
        ? page > 0
          ? page - 1
          : page
        : initialState.getAll.filter.page;
      state.getAll.filter.rowsPerPage = rowsPerPage || initialState.getAll.filter.rowsPerPage;
      state.getAll.filter.q = action?.meta?.arg.q || '';
      state.getAll.filter.sort = action?.meta?.arg.sort || '';
    }
  );
};

export const getOneReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getOne.pending, (state: StateData) => {
    state.getOne.status = StatusTypes.LOADING;
    state.getOne.error = null;
  });
  builder.addCase(getOne.rejected, (state: StateData, action: IRejectedAction) => {
    state.getOne.status = StatusTypes.ERROR;
    state.getOne.error = action.error.message;
  });
  builder.addCase(getOne.fulfilled, (state: StateData, action: PayloadAction<AxiosResponse>) => {
    state.getOne.status = StatusTypes.SUCCESS;
    state.getOne.data = action.payload.data as IUser;
  });
};

export const getByEmailReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getByEmail.pending, (state: StateData) => {
    state.getByEmail.status = StatusTypes.LOADING;
    state.getByEmail.error = null;
  });
  builder.addCase(getByEmail.rejected, (state: StateData, action: IRejectedAction) => {
    state.getByEmail.status = StatusTypes.ERROR;
    state.getByEmail.error = action.error.message;
  });
  builder.addCase(
    getByEmail.fulfilled,
    (state: StateData, action: PayloadAction<IUsersResponseWithPage>) => {
      state.getByEmail.status = StatusTypes.SUCCESS;
      state.getByEmail.data = action.payload.data as IUser[];
    }
  );
};

export const updateReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(update.pending, (state: StateData) => {
    state.update.status = StatusTypes.LOADING;
    state.update.error = null;
  });
  builder.addCase(update.rejected, (state: StateData, action: IRejectedAction) => {
    state.update.status = StatusTypes.ERROR;
    state.update.error = action.error.message;
  });
  builder.addCase(update.fulfilled, (state: StateData, action: PayloadAction<AxiosResponse>) => {
    state.update.status = StatusTypes.SUCCESS;
    state.update.data = action.payload.data as IUser;
  });
};

export const removeReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(remove.pending, (state: StateData) => {
    state.remove.status = StatusTypes.LOADING;
    state.remove.error = null;
  });
  builder.addCase(remove.rejected, (state: StateData, action: IRejectedAction) => {
    state.remove.status = StatusTypes.ERROR;
    state.remove.error = action.error.message;
  });
  builder.addCase(remove.fulfilled, (state: StateData) => {
    state.remove.status = StatusTypes.SUCCESS;
  });
};

export const changeSettingNotificationReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(changeSettingNotification.pending, (state: StateData, action) => {
    const userId = action.meta.arg.id;
    state.getAll.data?.map((user: IUser) => {
      if (user?.id === userId) {
        user.isLoading = true;
        user.isLoadingSettingNotification = true;
      }
      return user;
    });
  });
  builder.addCase(
    changeSettingNotification.rejected,
    (state: StateData, action: IRejectedAction) => {
      const userId = action.meta.arg.id;
      state.getAll.data?.map((user: IUser) => {
        if (user?.id === userId) {
          user.isLoading = false;
          user.isLoadingSettingNotification = false;
        }
        return user;
      });
    }
  );
  builder.addCase(
    changeSettingNotification.fulfilled,
    (state: StateData, action: PayloadAction<AxiosResponse<IUserResponse>>) => {
      const data = action.payload.data as IUser;
      state.getAll.data?.map((user: IUser) => {
        if (user?.id === data?.id) {
          user.isLoading = false;
          user.isLoadingSettingNotification = false;
          user.settings_notification = data?.settings_notification;
        }
        return user;
      });
    }
  );
};

export const getUnassignedPropertiesReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getUnassignedProperties.pending, (state: StateData) => {
    state.getUnassignedProperties.status = StatusTypes.LOADING;
    state.getUnassignedProperties.error = null;
  });
  builder.addCase(getUnassignedProperties.rejected, (state: StateData, action: IRejectedAction) => {
    state.getUnassignedProperties.status = StatusTypes.ERROR;
    state.getUnassignedProperties.error = action.error?.message || null;
  });
  builder.addCase(
    getUnassignedProperties.fulfilled,
    (state: StateData, action: PayloadAction<IPropertiesResponseWithPage> & IMetaAction) => {
      const { data, count, page, rowsPerPage } = action.payload;
      state.getUnassignedProperties.status = StatusTypes.SUCCESS;

      const aggregatedProperties =
        data && data.length > 0
          ? mergeArrayByKey(state.getUnassignedProperties.data || [], data, 'id')
          : [];
      state.getUnassignedProperties.data = aggregatedProperties;
      state.getUnassignedProperties.count = count;
      state.getUnassignedProperties.filter.page = page
        ? page > 0
          ? page - 1
          : page
        : initialState.getUnassignedProperties.filter.page;
      state.getUnassignedProperties.filter.rowsPerPage =
        rowsPerPage || initialState.getUnassignedProperties.filter.rowsPerPage;
      state.getUnassignedProperties.filter.q = action?.meta?.arg.q || '';
      state.getUnassignedProperties.filter.sort = action?.meta?.arg.sort || '';
    }
  );
};
