import { configVars } from 'src/helpers';

export interface IFetch {
  put(path: string): Promise<Response>;
  get(path: string): Promise<Response>;
  post(path: string, body?: object): Promise<Response>;
  delete(path: string): Promise<Response>;
  patch(path: string): Promise<Response>;
}

export class Fetching implements IFetch {
  async put(path: string): Promise<Response> {
    return fetch(`${configVars.api_url}/${path}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async get(path: string): Promise<Response> {
    return fetch(`${configVars.api_url}/${path}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async post(path: string, body: object): Promise<Response> {
    return fetch(`${configVars.api_url}/${path}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
  }

  async delete(path: string): Promise<Response> {
    return fetch(`${configVars.api_url}/${path}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async patch(path: string): Promise<Response> {
    return fetch(`${configVars.api_url}/${path}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}
