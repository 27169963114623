import { useLocation } from 'react-router-dom';
import { configVars } from 'src/helpers';

const useQueryParams = (param: string) => {
  const search = useLocation().search;
  const result = new URLSearchParams(search).get(param)?.replace(configVars.frontend_url, '');
  const isValid = typeof result === 'string';

  return {
    isValid,
    param,
    result: (isValid && result) || '',
    uri: isValid ? `?${param}=${result}` : ''
  };
};

export default useQueryParams;
