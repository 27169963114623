import PropTypes from 'prop-types';
import { Icon, IconColor } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import Spinner from '../Spinner';
import { Container, Content, FLoatLabel, SubContainer } from './styles';
import { DropdownButtonProps } from './types';

const DropdownButton = (props: DropdownButtonProps) => {
  const {
    onOpened,
    onClosed,
    label,
    name,
    isOpen,
    isDisabled,
    isLoading,
    size,
    hasError,
    showLabel,
    children
  } = props;

  const handleClick = () => {
    if (!isDisabled) {
      isOpen ? onOpened() : onClosed();
    }
  };

  return (
    <Container
      className="DDButton"
      id={name}
      onClick={handleClick}
      onBlur={onClosed}
      aria-expanded={String(isOpen)}
      aria-label={name}
      aria-disabled={isDisabled}
      role="button"
      hasError={hasError}
    >
      <SubContainer size={size} isDisabled={isDisabled} className="DDSubContainer">
        <Row justify="space-between" alignItems="center" gap={0.75} className="DDButtonContain">
          {showLabel && label !== children && (
            <FLoatLabel aria-invalid={hasError}>
              <Text variant={TextVariant.small} color={TextColor.gray700}>
                {label}
              </Text>
            </FLoatLabel>
          )}
          <Content className="DDContent">
            {children} {isLoading && <Spinner size="small" color="primary" />}
          </Content>
          <Icon color={IconColor.gray600} icon={`key-arrow-${isOpen ? 'up' : 'down'}`} />
        </Row>
      </SubContainer>
    </Container>
  );
};

DropdownButton.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onOpened: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md'] as const)
};

DropdownButton.defaultProps = {
  name: 'Dropdown',
  isOpen: false,
  size: 'md',
  isDisabled: false,
  isLoading: false,
  hasError: false,
  showLabel: true,
  label: ''
};

export default DropdownButton;
