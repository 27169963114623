import { FC } from 'react';
import Text from 'src/components/atoms/Text';

import { FormProvider, useForm } from 'react-hook-form';
import Gap from 'src/components/atoms/Gap';
import { InputText, InputTextType } from 'src/components/atoms/InputText';
import { ModalVariant } from 'src/components/organisms/Modal';
import { ModalStyle } from './styles';

export type IFormValues = {
  note: string;
};

export type NotesModalProps = {
  title: string;
  defaultNote: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSaveNote?: (content: IFormValues) => void;
  showCloseButton?: boolean;
  isReadonly?: boolean;
  showModalActions?: boolean;
};

const NotesModal: FC<NotesModalProps> = ({
  title,
  defaultNote,
  showModal,
  setShowModal,
  onSaveNote,
  showCloseButton,
  isReadonly,
  showModalActions
}) => {
  const methods = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues: {
      note: defaultNote
    }
  });

  const { note } = methods.watch();

  const onConfirm = () => {
    methods.handleSubmit(onSubmit)();
  };

  const onSubmit = (dataForm: IFormValues) => {
    onSaveNote?.(dataForm);
  };

  return (
    <ModalStyle
      className="NotesModal"
      variant={ModalVariant.none}
      title={title}
      showModal={showModal}
      setShowModal={setShowModal}
      showCloseButton={showCloseButton}
      showModalActions={showModalActions}
      onConfirm={onConfirm}
      isBackClosable={false}
      isEscapeClosable={false}
      labelButtonConfirm="Save"
      isDisabledButtonConfirm={note === defaultNote}
    >
      <Text>Note entered below will be included as part of the customer report.</Text>
      <Gap />
      <FormProvider {...methods}>
        <InputText
          name="note"
          isReadonly={isReadonly}
          placeholder={note.length === 0 ? 'Notes Section' : 'Note'}
          type={InputTextType.text}
          showOptionalLabel={false}
          multiline={true}
          rows={4}
        />
      </FormProvider>
    </ModalStyle>
  );
};

export default NotesModal;
