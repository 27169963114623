// Vendor
import { FC, useMemo } from 'react';

// Hooks
import { useFolderPage } from 'src/components/folders/FolderPageContext';
import { useEntryById } from 'src/features/entries/hooks';

// Components
import { ProofListFooterActions } from 'src/components/folders/ProofListFooterActions';
import { ProofListTableProps } from 'src/components/folders/ProofListTable';

// Enums
import { ProofResultEnum } from 'src/ts/enums';

type FooterActionsProps = Pick<ProofListTableProps, 'proofs'> & {
  showRequestDocumentsButton?: boolean;
  isReviewDisabled?: boolean;
  onEscalate?: () => void;
  canEscalate?: boolean;
  isLoadingEscalate?: boolean;
  isDisabled?: boolean;
};

export const FooterActions: FC<FooterActionsProps> = (props: FooterActionsProps) => {
  const {
    proofs,
    showRequestDocumentsButton,
    isReviewDisabled,
    onEscalate,
    canEscalate,
    isLoadingEscalate,
    isDisabled
  } = props;

  const { selectedEntryId, setShowSendReportModal, setShowRequestDocumentsModal } = useFolderPage();
  const { entry } = useEntryById(selectedEntryId as string);

  const allProofsHaveResult = useMemo(
    () => proofs?.every((proof) => proof.result !== ProofResultEnum.Pending),
    [proofs]
  );

  const allProofsIsUndetermined = useMemo(
    () => proofs?.every((proof) => proof.result === ProofResultEnum.Undetermined),
    [proofs]
  );

  return (
    <ProofListFooterActions
      entry={entry}
      isSendReportDisabled={isReviewDisabled || !(allProofsHaveResult && !allProofsIsUndetermined)}
      isRequestDocumentsDisabled={!allProofsIsUndetermined || isReviewDisabled}
      onSendReport={() => setShowSendReportModal?.(true)}
      onRequestDocuments={() => setShowRequestDocumentsModal?.(true)}
      showRequestDocumentsButton={showRequestDocumentsButton}
      canEscalate={canEscalate}
      onEscalate={onEscalate}
      isLoadingEscalate={isLoadingEscalate}
      isDisabled={isDisabled}
    />
  );
};

export default FooterActions;
