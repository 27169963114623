/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from 'react';

interface IUseScrollObserver {
  page: number;
  isLoading: boolean;
  callback: any;
  dependency: unknown[];
  hasMore: boolean;
}

const useScrollObserver = ({
  page = 0,
  isLoading,
  callback,
  dependency,
  hasMore
}: IUseScrollObserver) => {
  const observer = useRef<any>(null);
  const scrollObserver = useCallback((node: any) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isLoading && hasMore) {
        callback(page + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, dependency);

  return { scrollObserver };
};

export default useScrollObserver;
