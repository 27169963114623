import { COMPANY_INFO } from 'src/features/getStartedUI/constants';
import useCompanyAndUser from 'src/features/user/hooks/useCompanyAndUser';
import { RegisterCompanyForm } from 'src/pages/GetStartedPage/components/RegisterCompanyForm';

const GetStartedPage = () => {
  const { onCreateUserAndCompany, isCreatingUserAndCompany } = useCompanyAndUser();

  return (
    <RegisterCompanyForm
      isLoading={isCreatingUserAndCompany}
      information={COMPANY_INFO}
      handleOnSubmit={onCreateUserAndCompany}
    />
  );
};

export default GetStartedPage;
