import { FC } from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { DotColor, DotProps } from './types';

const Dot: FC<DotProps> = (props) => <Container {...props} />;

Dot.propTypes = {
  color: PropTypes.oneOf<DotColor>(['primary', 'secondary', 'error', 'success', 'warning'])
    .isRequired,
  size: PropTypes.number.isRequired
};

Dot.defaultProps = {
  size: 10
};

export default Dot;
