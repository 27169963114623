import styled from 'styled-components/macro';

export const Container = styled.div`
  div[aria-invalid='false'] {
    margin-bottom: 1.7rem;
  }

  div[aria-invalid='true'] {
    margin-bottom: 0.72rem;
  }

  position: relative;

  .input-form {
    margin-bottom: 0;
  }

  .HelperText {
    margin-bottom: 0;
    small {
      margin-top: 1.7rem !important;
    }
  }
`;

export const OptionContainer = styled.div<{ rows: number }>`
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  background: #fff;
  margin: 0;
  margin-top: -0.099rem;
  border: 0.063rem solid ${({ theme }) => theme.color.gray[200]};
  border-radius: 0 0 0.188rem 0.188rem;
  height: ${({ rows }) => (rows > 6 ? '12.5rem' : 'auto')};
  top: 50px;
  overflow-y: ${({ rows }) => (rows > 6 ? 'scroll' : 'hidden')};
  ul {
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
      border-bottom: 0.063rem solid ${({ theme }) => theme.color.gray[100]};
      &:last-of-type {
        border-bottom: none;
      }
      &:hover {
        background: ${({ theme }) => theme.color.blue[100]};
        button {
          svg {
            color: ${({ theme }) => theme.color.blue[700]};
          }
        }
      }
      button {
        display: block;
        padding: 0.625rem 0.75rem;
        text-decoration: none;
        width: 100%;
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-align: left;
        ${({ theme }) => theme.font.normal};
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          margin-left: 0.25rem;
        }
      }
      &.selected {
        button {
          ${({ theme }) => theme.font.bold};
          color: ${({ theme }) => theme.color.blue[700]};
          svg {
            color: ${({ theme }) => theme.color.blue[700]};
          }
        }
      }
    }
  }
`;

export const StateMessage = styled.div`
  padding: 1rem 0.5rem;
  ${({ theme }) => theme.font.small};
`;
