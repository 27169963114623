// Vendor
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import Grid from 'src/components/atoms/Grid';
import DocumentLinkLoader from 'src/components/molecules/DocumentLinkLoader/DocumentListLoader';
import { InstructionLoader } from 'src/components/molecules/InstructionLoader';
import { ShowState } from 'src/components/molecules/ShowState';
import { DocumentLinkRow } from 'src/components/organisms/DocumentLinkRow';
import { DocumentLinkInstructions } from './DocumentLinkInstructions';
import { StyledGrid, StyledInstructionContainer } from './styles';

// Hooks
import useProperty from 'src/features/property/hooks/useProperty';

// Redux
import { selectors as propertySelectors } from 'src/features/property/propertySlice';

// Types
import { IProperty } from 'src/ts/interfaces';

const filter = 'status=ACTIVE';
const DocumentLinkList: React.FC = () => {
  const { onGetAll: onGetProperties } = useProperty();

  const getPropertiesData = useSelector(propertySelectors.getAll.data);
  const getPropertiesIsIdle = useSelector(propertySelectors.getAll.isIdle);
  const getPropertiesIsLoading = useSelector(propertySelectors.getAll.isLoading);
  const getPropertiesError = useSelector(propertySelectors.getAll.error);

  useEffect(() => {
    if (getPropertiesIsIdle) {
      onGetProperties(0, 5, filter);
    }
  }, [onGetProperties, getPropertiesIsIdle]);

  if (getPropertiesError) {
    return (
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Please, try again"
        message="Something went wrong"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (getPropertiesIsLoading) {
    return (
      <Grid justify="space-between">
        <DocumentLinkLoader />
        <InstructionLoader />
      </Grid>
    );
  }

  if (getPropertiesData?.length === 0) {
    return (
      <ShowState message="There are no properties <br/> to show" type="property" variant="empty" />
    );
  }

  return (
    <>
      <Grid>
        <StyledGrid direction="column">
          {getPropertiesData?.map((property: IProperty) => {
            return (
              <DocumentLinkRow
                key={property.id}
                name={property.name}
                address={property.address}
                city={property.city}
                company_short_id={property.company_short_id}
                short_id={property.short_id}
                state={property.state}
                zip={property.zip}
                is_linked_to_yardi={property.is_linked_to_yardi || false}
                disable_dup_fd={property.disable_dup_fd}
              />
            );
          })}
        </StyledGrid>
        <StyledInstructionContainer>
          <DocumentLinkInstructions />
        </StyledInstructionContainer>
      </Grid>
    </>
  );
};
export default DocumentLinkList;
