// Vendor
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import Button, {
  ButtonDisabledStyle,
  ButtonSize,
  ButtonVariant
} from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import Table from 'src/components/atoms/Table';
import TableBody from 'src/components/atoms/TableBody';
import TableCell from 'src/components/atoms/TableCell';
import TableContainer from 'src/components/atoms/TableContainer';
import TableHead from 'src/components/atoms/TableHead';
import TableRow from 'src/components/atoms/TableRow';
import { TextColor, TextVariant } from 'src/components/atoms/Text';
import { Content, Header, HeaderInfo, StyledText } from './styles';

// Constants
import { columns } from './constants';
import { IdentityVerificationResult, RoleType } from 'src/ts/interfaces';
import { StyledTag } from 'src/components/organisms/IdentityVerificationRow/styles';
import { parseTimeToYear, parseTimeDistance } from 'src/helpers';

export interface IdentityVerificationRowProps {
  result: IdentityVerificationResult | null;
  role: RoleType;
}

const BasicInfo = ({ name, company }: { name: string; company: string }) => {
  return (
    <Header>
      <HeaderInfo>
        <Icon icon="person-icon" color="gray900" />
        <StyledText variant={TextVariant.h4} color={TextColor.gray700}>
          {name}
        </StyledText>
      </HeaderInfo>
      <HeaderInfo>
        <Icon icon="company-model-icon" color="gray700" />
        <StyledText name="property-name" variant={TextVariant.h4} color={TextColor.gray700}>
          {company}
        </StyledText>
      </HeaderInfo>
    </Header>
  );
};

const IdentityVerificationRow: FC<IdentityVerificationRowProps> = (props) => {
  const { result } = props;
  const navigate = useNavigate();

  return (
    <Content>
      <BasicInfo
        name={`${result?.firstName as string} ${result?.lastName as string}`}
        company={result?.property.name as string}
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(({ label, width, name }, idx) => {
                return (
                  <TableCell key={idx} width={width}>
                    {label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={result?.id}>
              <TableCell>{parseTimeDistance(result?.sessionCreatedAt as string)}</TableCell>
              <TableCell>{parseTimeDistance(result?.sessionClosedAt as string)}</TableCell>
              <TableCell>{parseTimeToYear(result?.licenseExpiresAt as string)}</TableCell>
              <TableCell>{result?.status && <StyledTag label={result?.status} />}</TableCell>
              <TableCell>
                <Button
                  onClick={() => navigate(`/identityVerification/${result?.id}/report`)}
                  name="view_report_button"
                  variant={ButtonVariant.ghost}
                  disabledStyle={ButtonDisabledStyle.transparent}
                  size={ButtonSize.normal}
                >
                  View&nbsp;&nbsp;
                  <Icon icon="document-search-icon" color="blue700" />
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Content>
  );
};

export default IdentityVerificationRow;
