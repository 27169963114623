import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'src/store';
import { Status } from 'src/ts/enums';

import { invitationGetByIdThunk, selectors } from '../index';

const useInvitation = (id?: string) => {
  const dispatch = useAppDispatch();
  const status = useSelector(selectors.getOne.status);
  const invitation = useSelector(selectors.getOne.data);

  useEffect(() => {
    if (id && status === Status.IDLE) {
      dispatch(invitationGetByIdThunk(id));
    }
  }, [status, dispatch, id]);

  const isUninitialized = status === Status.IDLE;
  const isLoading = status === Status.LOADING || status === Status.IDLE;
  const isError = status === Status.ERROR;
  const isSuccess = status === Status.SUCCESS;

  return { data: invitation, isUninitialized, isLoading, isError, isSuccess };
};

export default useInvitation;
