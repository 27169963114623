/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * "Given two objects, return an object with a boolean indicating if there are differences and an
 * object with the differences."
 *
 * The function takes three parameters:
 *
 * obj1: The first object to compare.
 * obj2: The second object to compare.
 * skipFields: An array of fields to skip.
 * The function returns an object with two properties:
 *
 * hasDifferences: A boolean indicating if there are differences.
 * differences: An object with the differences.
 * The function uses the Object.keys() method to get an array of the keys in obj1. It then loops
 * through the keys and compares the values of obj1 and obj2. If the values are different, it sets
 * hasDifferences to true and adds the difference to the differences object
 * @param [obj1] - The first object to compare
 * @param [obj2] - The object to compare against.
 * @param {string[]} [skipFields] - An array of fields to skip when comparing the objects.
 * @returns An object with two properties: hasDifferences and differences.
 */
const getDifferences = (
  obj1?: { [key: string]: any },
  obj2?: { [key: string]: any },
  skipFields?: string[]
): {
  hasDifferences: boolean;
  differences: { [key: string]: [string | number, string | number] };
} => {
  if (!obj1 || !obj2) return { hasDifferences: false, differences: {} };

  let hasDifferences = false;
  const differences: { [key: string]: [string | number, string | number] } = {};
  Object.keys(obj1).forEach((prop: string) => {
    if (skipFields?.includes(prop)) return; // skip the specified fields
    if (obj1[prop] !== obj2[prop]) {
      hasDifferences = true;
      differences[prop] = [obj1[prop], obj2[prop]];
    }
  });

  return { hasDifferences, differences };
};

export default getDifferences;
