// Vendor
import PropTypes from 'prop-types';
import { createContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import { Loader } from 'src/components/atoms/Loader';
import Section from 'src/components/atoms/Section/Section';
import { ShowState } from 'src/components/molecules/ShowState';
import { ListView } from 'src/components/templates';

// Hooks
import useProperty from 'src/features/property/hooks/useProperty';

// Redux
import { selectors as propertySelectors } from 'src/features/property/propertySlice';

type ProviderProps = {
  children: React.ReactNode;
  title: string;
  checkable?: boolean;
};

export const HasPropertyContext = createContext({});

const HasPropertyProvider: React.FC<ProviderProps> = ({ children, title, checkable }) => {
  const { onGetAll: onGetProperties } = useProperty();

  const getPropertiesCount = useSelector(propertySelectors.getAll.count);
  const getPropertiesIsIdle = useSelector(propertySelectors.getAll.isIdle);
  const getPropertiesIsLoading = useSelector(propertySelectors.getAll.isLoading);
  const getPropertiesError = useSelector(propertySelectors.getAll.error);

  useEffect(() => {
    if (checkable && getPropertiesIsIdle) {
      onGetProperties();
    }
  }, [checkable, onGetProperties, getPropertiesIsIdle]);

  if (getPropertiesError !== null) {
    return (
      <ListView title={title}>
        <Section align="center">
          <ShowState
            type="information"
            variant="error"
            message={getPropertiesError?.message}
            buttonLabel="Reload"
            onClick={() => window.location.reload()}
          />
        </Section>
      </ListView>
    );
  }

  if (checkable && getPropertiesIsLoading) {
    return <Loader />;
  }

  if (checkable && getPropertiesCount === 0) {
    return (
      <ListView title={title}>
        <Section align="center">
          <ShowState
            type="property"
            variant="error"
            message="There are currently aren’t any properties assigned to your account.<br/>Contact your
        administrator to have those properties assigned."
          />
        </Section>
      </ListView>
    );
  }

  return <HasPropertyContext.Provider value={{}}>{children}</HasPropertyContext.Provider>;
};

HasPropertyProvider.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  checkable: PropTypes.bool
};

export default HasPropertyProvider;
