import PropTypes from 'prop-types';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button, { ButtonColor, ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import { InputText } from 'src/components/atoms/InputText';
import { InputTextType } from 'src/components/atoms/InputText/InputText';
import { Row } from 'src/components/atoms/Row';
import { validateName } from 'src/helpers';

export type ChangeLoginFormProps = {
  firstName: string;
  lastName: string;
  onSubmit: (data: object) => void;
  onCancel: () => void;
};

type ChangeLoginFormInputs = {
  firstName: string;
  lastName: string;
};

const ChangeLoginForm: React.FC<ChangeLoginFormProps> = (props: ChangeLoginFormProps) => {
  const { firstName, lastName, onCancel, onSubmit } = props;
  const form = useForm<ChangeLoginFormInputs>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      firstName,
      lastName
    }
  });

  const onSubmitEvent = form.handleSubmit((data) => {
    form.reset();
    onSubmit(data);
  });

  const onCancelEvent = () => {
    form.reset();
    form.clearErrors();
    onCancel();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmitEvent}>
        <Row gap={1}>
          <Row.Col>
            <InputText
              name={'firstName'}
              defaultValue={firstName}
              type={InputTextType.text}
              placeholder={'First Name'}
              isDisabled={false}
              config={{
                required: 'Complete this field',
                maxLength: {
                  value: 100,
                  message: 'First Name must be at most 100 characters'
                },
                validate: {
                  noSpecialCharacters: (value) => validateName(value)
                }
              }}
            />
          </Row.Col>
          <Row.Col>
            <InputText
              name={'lastName'}
              defaultValue={lastName}
              type={InputTextType.text}
              placeholder={'Last Name'}
              isDisabled={false}
              config={{
                required: 'Complete this field',
                maxLength: {
                  value: 100,
                  message: 'Last Name must be at most 100 characters'
                },
                validate: {
                  noSpecialCharacters: (value) => validateName(value)
                }
              }}
            />
          </Row.Col>
          <Row.Col>
            <Button
              name="submit"
              size={ButtonSize.normal}
              color={ButtonColor.primary}
              variant={ButtonVariant.contained}
              onClick={onSubmitEvent}
            >
              Save
            </Button>
          </Row.Col>
          <Row.Col>
            <Button
              name="cancel"
              size={ButtonSize.normal}
              color={ButtonColor.cancel}
              variant={ButtonVariant.outline}
              onClick={onCancelEvent}
            >
              Cancel
            </Button>
          </Row.Col>
        </Row>
      </form>
    </FormProvider>
  );
};

ChangeLoginForm.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ChangeLoginForm;
