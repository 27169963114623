import styled from 'styled-components';

export const Container = styled.div<{ color: string }>`
  border-left: 8px solid ${({ color }) => color};
  border-radius: 4px 0 0 4px;
  background: #fff;
  width: 263px;
  @media (max-width: 1367px) {
    width: 302px;
  }
`;

export const Content = styled.div`
  align-items: start;
  flex-direction: row;
  padding: 15px 17px 15px 17px;
  border-radius: 0px 4px 4px 0px;
  background-color: ${({ theme }) => theme.color.gray[50]};
  border-left: none;
  height: 100%;

  span[role='spinner'] {
    margin: 0;
  }
`;

export const Thumbnail = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  img,
  svg {
    height: 222px;
    width: 100%;
  }
`;
