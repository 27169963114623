import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoaderUserList } from 'src/components/molecules/LoaderUserList';
import { RowUserList } from 'src/components/molecules/RowUserList';
import { ShowState } from 'src/components/molecules/ShowState';
import Modal, { ModalVariant, ModalWidth } from 'src/components/organisms/Modal';
import useUsers from 'src/features/users/hooks/useUsers';
import { selectors } from 'src/features/users/usersSlice';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import { IProperty, IUser } from 'src/ts/interfaces';

type UsersListProps = {
  searchQuery?: string;
  filter?: string;
};

const UsersList: React.FC<UsersListProps> = ({ filter }) => {
  const {
    onGetAll: onGetUsers,
    onRemove: onRemoveUser,
    onResetRemove: onResetRemoveUser,
    onHandleSettingNotifications: onHandleSettingNotificationsUser,
    showRemoveFromRole,
    showCompanyInformation,
    showPropertyInformation,
    extractPropertyNames,
    currentFilter
  } = useUsers();

  const getUsersData = useSelector(selectors.getAll.data);
  const getUsersIsLoading = useSelector(selectors.getAll.isLoading);
  const getUsersFilter = useSelector(selectors.getAll.filter);
  const getUsersError = useSelector(selectors.getAll.error);

  const removeUserError = useSelector(selectors.remove.error);
  const removeUserIsRemoved = useSelector(selectors.remove.isRemoved);
  const removeUserIsLoading = useSelector(selectors.remove.isLoading);

  const [showModalRemoveUser, setShowModalRemoveUser] = useState(false);
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();

  const [usersLoaded, setUsersLoaded] = useState(false);

  const [userSelected, setUserSelected] = useState({ userId: '' });

  useEffect(() => {
    if (!usersLoaded) {
      onGetUsers(undefined, currentFilter?.rowsPerPage, filter);
      setUsersLoaded(true);
    }
  }, [currentFilter?.rowsPerPage, filter, onGetUsers, usersLoaded]);

  useEffect(() => {
    if (removeUserError) {
      closeRemoveUser();
      showSnackbar(VariantType.error, removeUserError, SnackTypes.none);
      onResetRemoveUser(undefined, currentFilter?.rowsPerPage, filter);
    } else {
      if (removeUserIsRemoved && !removeUserIsLoading) {
        closeRemoveUser();
        showSnackbar(
          VariantType.success,
          'The user has been removed successfully',
          SnackTypes.none
        );
        onResetRemoveUser(undefined, currentFilter?.rowsPerPage, filter);
      }
    }
  }, [
    onRemoveUser,
    removeUserError,
    removeUserIsRemoved,
    removeUserIsLoading,
    onResetRemoveUser,
    showSnackbar,
    VariantType,
    SnackTypes,
    currentFilter?.rowsPerPage,
    filter
  ]);

  if (getUsersError) {
    return (
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Please try again"
        message="Something went wrong"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (getUsersIsLoading) {
    return <LoaderUserList />;
  }

  if (getUsersData?.length === 0) {
    if (getUsersFilter.q) {
      return (
        <ShowState
          message="Try adjusting your search or filter to find<br/> what you are looking for"
          searchText={decodeURIComponent(getUsersFilter.q)}
          variant="search"
          type="searchEmpty"
        />
      );
    }
    return <ShowState message="There are no users yet" type="user" variant="empty" />;
  }

  const handleRemoveUser = (userId?: string) => {
    if (!userId) return null;
    setUserSelected({ userId });
    setShowModalRemoveUser(true);
  };

  const closeRemoveUser = () => {
    setUserSelected({ userId: '' });
    setShowModalRemoveUser(false);
  };

  return (
    <div>
      {getUsersData?.map((user: IUser) => (
        <RowUserList
          key={user.id}
          fullName={user.first_name + ' ' + user.last_name}
          email={user.email}
          role={user.role}
          settingNotification={user.settings_notification}
          isLoadingSettingNotification={user.isLoadingSettingNotification}
          onNotification={() => onHandleSettingNotificationsUser(user)}
          onEdit={`/user/${user.id}`}
          onDelete={() => handleRemoveUser(user.id)}
          isDisabledOnNotification={false}
          isDisabledOnEdit={false}
          isDisabledOnDelete={false}
          isLoading={user.isLoading}
          showOnDelete={showRemoveFromRole(user.role, user?.company_id)}
          companyName={user?.company?.name}
          properties={extractPropertyNames(user?.properties as IProperty[])}
          showCompanyInformation={showCompanyInformation(user.role)}
          showPropertyInformation={showPropertyInformation(user.role)}
        />
      ))}

      <Modal
        showModal={showModalRemoveUser}
        setShowModal={setShowModalRemoveUser}
        title="Remove User"
        labelButtonCancel="Cancel"
        labelButtonConfirm="Yes, remove"
        isLoadingButtonConfirm={removeUserIsLoading}
        isDisabledButtonConfirm={removeUserIsLoading}
        isDisabledButtonCancel={removeUserIsLoading}
        variant={ModalVariant.danger}
        width={ModalWidth.small}
        isBackClosable={false}
        isEscapeClosable={false}
        onConfirm={() => onRemoveUser(userSelected.userId)}
        onCancel={closeRemoveUser}
        text="Are you sure you want to remove this user? They will not be able to login to Snappt and
          they will disappear from the users tab."
      />
    </div>
  );
};

UsersList.propTypes = {
  searchQuery: PropTypes.string,
  filter: PropTypes.string
};

export default UsersList;
