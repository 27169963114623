import PropTypes from 'prop-types';
import Button, { ButtonColor, ButtonVariant } from 'src/components/atoms/Button';
import ButtonLink, { ButtonLinkColor, ButtonLinkVariant } from 'src/components/atoms/ButtonLink';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { Content } from './styles';

interface IBack {
  label: string;
  to?: string;
  onClick?: () => void;
}

export type DetailViewProps = {
  children: React.ReactNode;
  title?: string;
  back: IBack;
  rightComponent?: React.ReactNode;
};

const DetailView: React.FC<DetailViewProps> = (props) => {
  const { title, children, back, rightComponent } = props;

  return (
    <>
      {back.to && (
        <ButtonLink
          color={ButtonLinkColor.primary}
          variant={ButtonLinkVariant.ghost}
          to={back.to}
          name="GoBack_Button"
        >
          <Icon icon="icon-goback" />
          <Text color={TextColor.primary}>{back.label}</Text>
        </ButtonLink>
      )}
      {back.onClick && (
        <Button
          color={ButtonColor.primary}
          variant={ButtonVariant.ghost}
          onClick={back.onClick}
          name="GoBack_Button"
        >
          <Icon icon="icon-goback" />
          <Text color={TextColor.primary}>{back.label}</Text>
        </Button>
      )}
      <Gap height={1.5} />
      <Row justify="space-between" alignItems="center">
        {title && <Text variant={TextVariant.h3}>{title}</Text>}

        {rightComponent ? rightComponent : null}
      </Row>
      <Content>{children}</Content>
    </>
  );
};

DetailView.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  rightComponent: PropTypes.node,
  back: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired
    }).isRequired,
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    }).isRequired
  ]).isRequired
};

DetailView.defaultProps = {
  children: null,
  back: {
    label: 'Go Back',
    to: '/'
  }
};

export default DetailView;
