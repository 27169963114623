// Vendor
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Components
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from 'src/components/atoms/Button';
import { CheckboxData } from 'src/components/atoms/Checkbox/types';
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import { TransferList } from 'src/components/molecules/TransferList';
import { ITransferListContent } from 'src/components/molecules/TransferList/types';

// Types
import { IProperty, IUser } from 'src/ts/interfaces';

// Hooks
import useSelectProperties from 'src/features/users/hooks/useSelectProperties';
import useUserUnassignedProperties from 'src/features/users/hooks/useUserUnassignedProperties';

// Enums
import { RoleEnum } from 'src/ts/enums';

type AssignPropertiesFormProps = {
  defaultValues: Partial<IUser>;
  onSave: (data: IUser) => void;
  onCancel: (data: Partial<IUser>) => void;
};

const AssignPropertiesForm: React.FC<AssignPropertiesFormProps> = (
  props: AssignPropertiesFormProps
) => {
  const { defaultValues, onSave, onCancel } = props;

  const {
    getData,
    isLoadingUnassigned,
    isLoadingPage,
    hasError,
    unassigned,
    totalRows,
    assigned,
    unassignedScrollObserver
  } = useSelectProperties({
    properties: (defaultValues.properties as IProperty[]) || [],
    companySelected: defaultValues.company_id || ''
  });

  const { onResetUnassignedProperties } = useUserUnassignedProperties();

  useEffect(() => {
    return () => {
      onResetUnassignedProperties();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSetLists = (data: ITransferListContent) => {
    getData({ assignedProperties: data.secondList.data, q: data.firstList.q });
    const properties = data.secondList.data.map(({ value, label }: CheckboxData) => {
      return { id: value, name: label };
    });
    methods.setValue('properties', properties);
  };

  const onCancelling = () => {
    onCancel({ ...defaultValues, properties: undefined });
  };

  const methods = useForm<IUser>({
    mode: 'onSubmit',
    defaultValues
  });

  const checkIsDisabled = () => {
    const rolesNotAllowed = [
      RoleEnum.BuildingManager,
      RoleEnum.OnSiteRepresentative,
      RoleEnum.PropertyAdmin
    ].includes(methods.getValues('role'));

    return (
      hasError ||
      isLoadingUnassigned ||
      (rolesNotAllowed &&
        (!methods.getValues('properties') || methods.getValues('properties')?.length === 0))
    );
  };

  return (
    <FormProvider {...methods}>
      {defaultValues.company_id && (
        <TransferList
          name="SelectProperties"
          showSearch
          showSelectChoices
          searchPlaceholder="Search"
          onGetData={onSetLists}
          firstList={{
            name: 'UnassignedProperties',
            title: 'Unassigned Properties',
            messageEmpty: 'There are no<br>properties to show',
            isLoading: isLoadingUnassigned,
            isLoadingPage: isLoadingPage,
            hasError,
            data: unassigned,
            totalRows,
            innerRef: unassignedScrollObserver,
            onSearch: (q) => {
              getData({
                assignedProperties: assigned,
                q
              });
            }
          }}
          secondList={{
            name: 'AssignedProperties',
            title: 'Assigned Properties',
            messageEmpty: 'There are no<br>properties to show',
            hasError,
            data: assigned
          }}
        />
      )}

      <Gap height={2} />
      <Row gap={1}>
        <Button
          name="continue_button"
          variant={ButtonVariant.contained}
          color={ButtonColor.primary}
          size={ButtonSize.medium}
          type={ButtonType.submit}
          isDisabled={checkIsDisabled()}
          onClick={methods.handleSubmit(onSave)}
        >
          Continue
        </Button>
        <Button
          name="cancel_button"
          variant={ButtonVariant.outline}
          color={ButtonColor.primary}
          size={ButtonSize.medium}
          onClick={onCancelling}
        >
          Cancel
        </Button>
      </Row>
    </FormProvider>
  );
};

export default AssignPropertiesForm;
