import PropTypes from 'prop-types';

import { TextVariant } from 'src/components/atoms/Text';
import { Icon } from 'src/components/atoms/Icon';
import { StyledText, StyledGrid } from './styles';

const YardiLogo = ({ isYardiActivated }: { isYardiActivated: boolean }): JSX.Element | null => {
  if (!isYardiActivated) {
    return null;
  }

  return (
    <StyledGrid alignItems="center">
      <Icon icon="yardi" />
      <StyledText variant={TextVariant.small} color={'gray700'}>
        Activated
      </StyledText>
    </StyledGrid>
  );
};

YardiLogo.propTypes = {
  isYardiActivated: PropTypes.bool
};

export default YardiLogo;
