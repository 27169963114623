import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { IRejectedAction } from 'src/ts/interfaces';
import { NAME } from './constants';
import { fetchServerInfo } from './services';

import packageJson from '../../../package.json';

type StateData = {
  backVersion: null | string;
  frontVersion: string;
  sdkVersion: null | string;
  status: StatusTypes;
  error?: string | null;
};

const initialState: StateData = {
  backVersion: null,
  sdkVersion: packageJson.dependencies['@snapptinc/fraud-platform'],
  frontVersion: packageJson.version,
  status: StatusTypes.IDLE,
  error: null
};

const serverInfoSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchServerInfoReducer(builder);
  }
});

const fetchServerInfoReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(fetchServerInfo.pending, (state) => {
    state.status = StatusTypes.LOADING;
    state.error = null;
  });
  builder.addCase(fetchServerInfo.rejected, (state, action: IRejectedAction) => {
    state.status = StatusTypes.ERROR;
    state.error = action.error?.message;
  });
  builder.addCase(
    fetchServerInfo.fulfilled,
    (state, action: PayloadAction<{ back_version: string }>) => {
      const {
        payload: { back_version }
      } = action;

      if (back_version !== state.frontVersion) {
        console.warn(
          `Warning! Frontend, API SDK and Backend versions should be in sync. Please update dependencies. Frontend version: ${state.frontVersion} / SDK Version: ${state.sdkVersion} / Backend Version: ${back_version}`
        );
      }

      state.status = StatusTypes.SUCCESS;
      state.backVersion = back_version;
    }
  );
};

export const selectors = {
  backVersion: (state: RootState) => state[NAME].backVersion,
  status: (state: RootState) => state[NAME].status,
  error: (state: RootState) => state[NAME].error
};

export const { reducer, actions } = serverInfoSlice;
