import Modal from 'src/components/organisms/Modal';
import { Container, Content, Wrapper as StyleWrapper } from 'src/components/organisms/Modal/styles';
import styled from 'styled-components/macro';

export const StyledModal = styled(Modal)`
  position: absolute;

  & .modal__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75vw;
    height: 90vh;
  }

  & ${StyleWrapper} {
    width: 100%;
    height: 100%;
  }

  & ${Content} {
    width: 100%;
    height: 100%;
  }

  & ${Container} {
    max-height: none;
  }
`;
