import { FC } from 'react';
import { Row } from 'src/components/atoms/Row';
import { ShowState } from 'src/components/molecules/ShowState';
import TextInsertionBlock from 'src/components/organisms/TextInsertionBlock';
import { IProof } from 'src/ts/interfaces';

const TextInsertionMetadata: FC<{ proof: IProof }> = ({ proof }) => {
  if (!proof?.text_breakups || proof?.text_breakups?.length === 0) {
    return (
      <ShowState
        variant="empty"
        type="information"
        message="The document does not have information to show"
      />
    );
  }

  return (
    <Row direction="column" gap={2.5}>
      {proof?.text_breakups?.map((breakup, idx) => (
        <TextInsertionBlock key={idx} index={idx} breakup={breakup} proof={proof} />
      ))}
    </Row>
  );
};

export default TextInsertionMetadata;
