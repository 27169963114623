// Vendor
import { useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import Button, { ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import { ToolTip } from 'src/components/atoms/ToolTip';
import { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import {
  EntryMetadataModal,
  RequestDocumentsModal,
  SendReportModal,
  SplitEntryModal
} from 'src/components/folders/modals';
import EntriesMultiTabView from 'src/components/organisms/EntriesMultiTabView';
import ButtonHistoryModal from '../ButtonHistoryModal';
import { TimeSLA } from '../TimeSLA';
import EntryContent from './EntryContent';

// Context
import { useFolderPage } from 'src/components/folders/FolderPageContext';

// Redux
import { selectors as authSelectors } from 'src/features/auth/authSlice';

// Enums
import { FeatureFlagKey } from 'src/ts/enums';

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';
import {
  useEntriesByFolderId,
  useEntryById,
  useRequestDocuments
} from 'src/features/entries/hooks';
import { useEntryAssignedToAnother } from 'src/features/myWork/hooks/useEntryAssignedToAnother';
import { useFeatureFlag } from 'src/hooks';

// Types
import { IEntry } from 'src/ts/interfaces';

const EntriesManager = ({ folderId }: { folderId: string }) => {
  const [isSubmissionRequestButtonVisible, setIsSubmissionRequestButtonVisible] = useState(false);
  const user = useSelector(authSelectors.user);

  // Custom hooks
  const { entries, isUninitialized } = useEntriesByFolderId(folderId);
  const { isAdmin, isFraudAnalyst, isSeniorFraudAnalyst, isFDEManager } = useRole();
  const { checkIsAssignedToAnotherUser } = useEntryAssignedToAnother();
  const { onRequestDocuments } = useRequestDocuments();
  const {
    selectedEntryId,
    setShowEntryMetadataModal,
    showRequestDocumentsModal,
    setShowRequestDocumentsModal,
    onChangeSelectedEntry
  } = useFolderPage();
  const { entry } = useEntryById((selectedEntryId || entries?.[0]?.id) as string);
  const cleanProceedWithCautionFlagEnabled = useFeatureFlag(
    FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION
  );

  //todo: convert to launch darkly feature flag in the future if this feature gets assigned priority
  const flagEscalationEnabled = false;
  const isAssignedToAnotherUser = checkIsAssignedToAnotherUser(entry?.review_assigned_to_id);
  const canSplitEntry = entries.length > 1;
  const isReviewDisabled =
    entry?.review_assigned_to_id !== user?.id || entry?.review_status !== 'ASSIGNED';
  const canSeeButtonHistoryModal =
    isAdmin || isFraudAnalyst || isSeniorFraudAnalyst || isFDEManager;

  const canEscalate = flagEscalationEnabled && isFraudAnalyst && !isAssignedToAnotherUser;

  // The tooltip to show the entry metadata. We add this component to the tab label
  const MetadataTooltip = (): JSX.Element => (
    <ToolTip content="Entry metadata" direction={ToolTipDirection.top}>
      <Button
        variant={ButtonVariant.ghost}
        onClick={() => setShowEntryMetadataModal?.(true)}
        name="metadata_button"
        size={ButtonSize.medium}
      >
        <Icon icon="icon-info" color="blue700" />
      </Button>
    </ToolTip>
  );

  const EntryModals = (): JSX.Element => (
    <>
      <SplitEntryModal />

      <SendReportModal
        onConfirm={() => {
          setIsSubmissionRequestButtonVisible(true);
        }}
      />

      <RequestDocumentsModal
        showModal={showRequestDocumentsModal}
        setShowModal={setShowRequestDocumentsModal}
        onConfirm={async (message: string) => {
          setShowRequestDocumentsModal?.(false);

          await onRequestDocuments(selectedEntryId, message);
          setIsSubmissionRequestButtonVisible(true);
        }}
      />
      {entry && <EntryMetadataModal entryData={entry} />}
    </>
  );

  const SLATime = ({ entry }: { entry: IEntry }): JSX.Element => (
    <Row alignItems="center" justify="flex-end" gap={0.5}>
      <TimeSLA entry={entry} />
      {canSeeButtonHistoryModal && <ButtonHistoryModal isDisabled={false} entryId={entry.id} />}
    </Row>
  );

  if (isUninitialized || !entry) {
    // This shouldn't happen but is better to be covered in the cases we get data inconsistencies
    if (!isUninitialized && !entry) {
      console.error('Entry not found in state!');
    }
    return null;
  }

  return (
    <>
      <EntriesMultiTabView
        entries={entries}
        dateFormatString="MM/dd/yyyy hh:mmaaa"
        tabLabelAdditionalContent={<MetadataTooltip />}
        cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
        onTabSelect={(index) => onChangeSelectedEntry?.(entries?.[index]?.id)}
        tabContentChildren={(entry) => (
          <>
            <SLATime entry={entry} />
            <EntryContent
              entry={entry}
              folderId={folderId}
              canEscalateEntry={canEscalate}
              canSplitEntry={canSplitEntry}
              isReviewDisabled={isReviewDisabled}
              showSubmissionRequestButton={isSubmissionRequestButtonVisible}
              onSubmissionRequest={() => setIsSubmissionRequestButtonVisible(false)}
            />
          </>
        )}
      />

      <EntryModals />
    </>
  );
};

export default EntriesManager;
