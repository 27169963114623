import { actions } from 'src/features/country/countrySlice';
import { useAppDispatch } from 'src/store';
import { COUNTRY } from '../data/country';
import { getAll } from '../services';

export const onGetLabel = (id?: number) => {
  if (id) return COUNTRY.find((country) => country.value === id)?.label;
};

const useCountry = () => {
  const dispatch = useAppDispatch();

  const onGetAll = () => {
    dispatch(getAll());
  };

  const onResetGetAll = () => {
    dispatch(actions.resetGetAll());
  };

  const onSearch = (q: string) => {
    const search = decodeURIComponent(q);
    if (search.length >= 3) dispatch(actions.search({ q: search }));
  };

  return {
    onGetAll,
    onResetGetAll,
    onSearch,
    onGetLabel
  };
};

export default useCountry;
