// Constants
import { LeasingTeam } from 'src/constants/roles';

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';

// Redux
import { actions, selectors as invitationSelectors } from 'src/features/invitation/invitationSlice';
import store, { useAppDispatch } from 'src/store';
import { create, getAll, remove, resendInvitation } from '../services';

// Components
import { PartialUser } from 'src/pages/InvitationFlowPage/InvitationFlowPage';

// Enums
import { RoleEnum } from 'src/ts/enums';

// Types
import { IInvitationRequest, IProperty, RoleType } from 'src/ts/interfaces';

const useInvitations = () => {
  const dispatch = useAppDispatch();
  const { isAdmin, isCompanyAdmin, isBuildingManager, isAccountRepresentative } = useRole();

  const onGetAll = (page?: number, rowsPerPage?: number, filter?: string) => {
    const currentFilter = invitationSelectors.getAll.filter(store.getState());
    const pages = !page ? currentFilter.page : page;
    const rowsPerPages = !rowsPerPage ? currentFilter.rowsPerPage : rowsPerPage;

    const rolesToShow = isAccountRepresentative ? `role@>${LeasingTeam.join('|')}` : undefined;

    let filters = filter || currentFilter?.filter;

    if (rolesToShow) {
      filters = filters ? `${filters},${rolesToShow}` : rolesToShow;
    }

    dispatch(getAll({ ...currentFilter, filter: filters, page: pages, rowsPerPage: rowsPerPages }));
  };

  const onRemove = (invitationId?: string) => {
    if (!invitationId) throw new Error('Is missing the user identifier to remove the invitation');
    dispatch(remove(invitationId));
  };

  const onResetRemove = () => {
    dispatch(actions.resetRemove());
    onGetAll();
  };

  const showCompanyInformation = (role: RoleType) => {
    if (isAdmin || isCompanyAdmin || isBuildingManager || isAccountRepresentative) {
      if (
        [
          RoleEnum.CompanyAdmin,
          RoleEnum.BuildingManager,
          RoleEnum.PropertyAdmin,
          RoleEnum.OnSiteRepresentative
        ].includes(role)
      )
        return true;
    }
    return false;
  };

  const showPropertyInformation = (role: RoleType) => {
    if (isAdmin || isCompanyAdmin || isBuildingManager) {
      if (
        [RoleEnum.BuildingManager, RoleEnum.PropertyAdmin, RoleEnum.OnSiteRepresentative].includes(
          role
        )
      )
        return true;
    }
    return false;
  };

  const extractPropertyNames = (properties: IProperty[]) => {
    return properties.map((property: IProperty) => property.name).join(', ');
  };

  const onSearch = (q: string) => {
    const currentFilter = invitationSelectors.getAll.filter(store.getState());
    const search = encodeURIComponent(q);
    dispatch(getAll({ ...currentFilter, page: 0, q: search }));
  };

  const onSort = (query: string) => {
    const currentFilter = invitationSelectors.getAll.filter(store.getState());
    dispatch(getAll({ ...currentFilter, page: 0, sort: query }));
  };

  const onHandleSendInvitation = (invitationId?: string) => {
    if (!invitationId)
      throw new Error('Is missing the invitation identifier to send the invitation');
    dispatch(resendInvitation(invitationId));
  };

  const onCreate = (data: PartialUser) => {
    dispatch(create({ invitation: data } as IInvitationRequest));
  };

  const onResetCreate = () => {
    dispatch(actions.resetCreate());
  };

  const onResetGetAll = () => {
    dispatch(actions.resetGetAll());
  };

  return {
    onGetAll,
    onResetGetAll,
    onRemove,
    onResetRemove,
    extractPropertyNames,
    showCompanyInformation,
    showPropertyInformation,
    onSearch,
    onSort,
    onHandleSendInvitation,
    onCreate,
    onResetCreate
  };
};

export default useInvitations;
