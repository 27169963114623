// Vendor
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

// Redux
import { actions, selectors as propertySelectors } from 'src/features/property/propertySlice';
import { useAppDispatch } from 'src/store';
import { getPropertiesDDownThunk } from '../services';

// Enums
import { Status as StatusEnum } from 'src/ts/enums';

// Types
import { IPaginationRequest } from 'src/ts/interfaces';

const usePropertiesDDown = () => {
  const dispatch = useAppDispatch();
  const getPropertiesDDownResponse = useSelector(propertySelectors.getPropertiesDDown);

  const onGetPropertiesDDown = useCallback(
    (args: IPaginationRequest) => {
      dispatch(getPropertiesDDownThunk(args));
    },
    [dispatch]
  );

  const onResetGetPropertiesDDown = () => {
    dispatch(actions.resetGetPropertiesDDown());
  };

  return {
    onGetPropertiesDDown,
    onResetGetPropertiesDDown,
    getPropertiesDDown: {
      isLoading: getPropertiesDDownResponse.status === StatusEnum.LOADING,
      isIdle: getPropertiesDDownResponse.status === StatusEnum.IDLE,
      isError: getPropertiesDDownResponse.status === StatusEnum.ERROR,
      error: getPropertiesDDownResponse.error,
      data: getPropertiesDDownResponse.data || [],
      filter: getPropertiesDDownResponse.filter,
      count: getPropertiesDDownResponse.count || 0
    }
  };
};

export default usePropertiesDDown;
