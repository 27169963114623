import { resourceApi } from 'src/api';

const api = {
  getAll: async () => {
    return await resourceApi.getResources();
  },

  getOne: async (resourceId: string) => {
    return await resourceApi.getResource(resourceId);
  }
};

export default api;
