// Vendor
import { FC, useState } from 'react';

// Components
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import Text from 'src/components/atoms/Text';
import { ButtonGroup } from 'src/components/molecules/ButtonGroup';
import { ModalVariant } from 'src/components/organisms/Modal';
import AutomatedMetadata from './components/AutomatedMetadata';
import DocumentMetadata from './components/DocumentMetadata';
import TextInsertionMetadata from './components/TextInsertionMetadata';
import { StyledCard, StyledModal } from './styles';

// Constants
import { buttons } from './constants';

//Redux

// Types
import { MetadataModalProps } from './types';

const MetadataModal: FC<MetadataModalProps> = ({
  title,
  extracted_meta,
  test_meta_data_flags,
  proof,
  showModal,
  setShowModal,
  onConfirm,
  labelExtractMetadata,
  showExtractMetadata = true,
  labelAutomatedTest,
  showAutomatedTest = true,
  labelTextInsertion,
  showTextInsertion = false
}) => {
  const buttonGroup = buttons
    .map((button) => {
      if (button.value === 'EXTRACTED_METADATA' && labelExtractMetadata) {
        button.label = labelExtractMetadata;
      }
      if (button.value === 'AUTOMATED_TEST' && labelAutomatedTest) {
        button.label = labelAutomatedTest;
      }
      if (button.value === 'TEXT_INSERTION' && labelTextInsertion) {
        button.label = labelTextInsertion;
      }
      return button;
    })
    .filter((button) => {
      return (
        (button.value === 'EXTRACTED_METADATA' && showExtractMetadata) ||
        (button.value === 'AUTOMATED_TEST' && showAutomatedTest) ||
        (button.value === 'TEXT_INSERTION' && showTextInsertion)
      );
    });

  const [selectedButton, setSelectedButton] = useState(buttonGroup[0]);

  return (
    <StyledModal
      className="MetadataModal"
      variant={ModalVariant.none}
      title={title ? title : 'Metadata'}
      showModal={showModal}
      setShowModal={setShowModal}
      onConfirm={onConfirm}
      onCancel={() => setSelectedButton(buttons[0])}
      isBackClosable={false}
      isEscapeClosable={false}
      showCloseButton
      showModalActions={false}
    >
      <Row direction="column" gap={1}>
        <Row.Col size={1} alignSelf="center">
          <ButtonGroup buttons={buttonGroup} onClick={(selected) => setSelectedButton(selected)} />
        </Row.Col>
        <Row.Col size={1}>
          <StyledCard border>
            <Row direction="column" gap={1}>
              <Row.Col size={1} alignSelf="center">
                <Text isBold>{selectedButton.label}</Text>
              </Row.Col>
              <Line />
              {selectedButton.value === 'EXTRACTED_METADATA' && (
                <DocumentMetadata extracted_meta={extracted_meta} />
              )}
              {selectedButton.value === 'AUTOMATED_TEST' && (
                <AutomatedMetadata test_meta_data_flags={test_meta_data_flags} />
              )}
              {selectedButton.value === 'TEXT_INSERTION' && proof && (
                <TextInsertionMetadata proof={proof} />
              )}
            </Row>
          </StyledCard>
        </Row.Col>
      </Row>
    </StyledModal>
  );
};

export default MetadataModal;
