import { formatLabelValue } from 'src/helpers';
import { IUser } from 'src/ts/interfaces/user';

const getMembersFormatted = (users?: IUser[] | null) => {
  if (!users) return [];

  const formated = formatLabelValue({
    data: users,
    value: 'id',
    label: 'first_name'
  });

  return formated
    .map((e) => {
      return {
        ...e,
        isChecked: false,
        isVisible: users.filter((p) => p.id === e.value).map((p) => p.isVisible)[0] || true
      };
    })
    .sort((a, b) => {
      const x = a.label.toLowerCase();
      const y = b.label.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
};

export default getMembersFormatted;
