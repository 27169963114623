// Vendor
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

// Components
import Button, { ButtonColor, ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import ButtonLink, {
  ButtonLinkColor,
  ButtonLinkSize,
  ButtonLinkVariant
} from 'src/components/atoms/ButtonLink';
import Gap from 'src/components/atoms/Gap';
import InputText, { InputTextType } from 'src/components/atoms/InputText/InputText';
import { Row } from 'src/components/atoms/Row';
import Dropdown from 'src/components/molecules/Dropdown/Dropdown';
import { ShowState } from 'src/components/molecules/ShowState';
import SelectProperties from './SelectProperties';

// Redux
import { selectors as teamsSelectors } from 'src/features/teams';
import { selectors as userSelectors } from 'src/features/users/usersSlice';

// Helpers
import { calculatePagesCount, formatLabelValue, validateName } from 'src/helpers';

// Enums
import { RoleEnum } from 'src/ts/enums';

// Types
import { ICompany, IProperty, IUser, IValueLabel } from 'src/ts/interfaces';

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';
import useCompaniesDDown from 'src/features/company/hooks/useCompaniesDDown';
import useTeam from 'src/features/teams/hooks/useTeams';
import useUserUnassignedProperties from 'src/features/users/hooks/useUserUnassignedProperties';
import useUsers from 'src/features/users/hooks/useUsers';
import useScrollObserver from 'src/hooks/useScrollObserver';

type UserDetailFormProps = {
  defaultValues: IUser;
  roles: IValueLabel[];
  onSave: (data: IUser) => void;
  isSaving: boolean;
};

const UserDetailForm: React.FC<UserDetailFormProps> = ({
  defaultValues,
  roles,
  onSave,
  isSaving
}: UserDetailFormProps) => {
  const { showCompanySelectorFromRole, showTeamSelector, showPropertySelectorFromRole } =
    useUsers();
  const { isAdminOrFdeManager } = useRole();

  const { onResetUnassignedProperties } = useUserUnassignedProperties();
  const usersData = useSelector(userSelectors.getUser.data);

  const { onGetCompaniesDDown: onGetCompanies, getCompaniesDDown } = useCompaniesDDown();

  const [roleSelected, setRoleSelected] = useState(defaultValues.role);
  const [companySelected, setCompanySelected] = useState(defaultValues.company_id);
  const [initialCompanyLabel, setInitialCompanyLabel] = useState(usersData?.company?.name);
  const [initialTeamLabel, SetInitialTeamLabel] = useState('');

  const [companies, setCompanies] = useState<ICompany[] | []>([]);
  const [properties, setProperties] = useState(defaultValues.properties);

  const { onGetAll: onGetTeams } = useTeam();

  const getTeamsData = useSelector(teamsSelectors.getAll.data);
  const getTeamsIsIdle = useSelector(teamsSelectors.getAll.isIdle);
  const getTeamsIsLoading = useSelector(teamsSelectors.getAll.isLoading);
  const getTeamsError = useSelector(teamsSelectors.getAll.error);

  useEffect(() => {
    if (getTeamsData?.length) {
      const team = getTeamsData.find((team) => team.id === defaultValues.team_id);
      SetInitialTeamLabel(team?.name || '');
    }
  }, [defaultValues.team_id, getTeamsData]);

  useEffect(() => {
    if (getCompaniesDDown.isIdle) {
      onGetCompanies(getCompaniesDDown.filter);
    } else {
      if (!getCompaniesDDown.error && getCompaniesDDown.data && !getCompaniesDDown.isLoading) {
        setCompanies(getCompaniesDDown.data);
      }
    }
  }, [getCompaniesDDown, onGetCompanies, companies]);

  useEffect(() => {
    if (getTeamsIsIdle) {
      onGetTeams();
    }
  }, [onGetTeams, getTeamsIsIdle]);

  const { scrollObserver: getCompaniesScrollObserver } = useScrollObserver({
    page: getCompaniesDDown.filter.page,
    isLoading: getCompaniesDDown.isLoading,
    callback: (page: number) => {
      onGetCompanies({ ...getCompaniesDDown.filter, page });
    },
    hasMore:
      getCompaniesDDown.filter.page <
      calculatePagesCount(getCompaniesDDown.filter.rowsPerPage, getCompaniesDDown.count),
    dependency: [getCompaniesDDown]
  });

  const methods = useForm<IUser>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues
  });

  const handleSave = (data: IUser) => {
    const currentRole = defaultValues.role;

    // When we change the role of an user we should reset the properties except when changing between the roles below.
    // In that case we should keep the all the relations (no change on the properties or the company).
    const relatedRoles = [
      RoleEnum.PropertyAdmin,
      RoleEnum.BuildingManager,
      RoleEnum.OnSiteRepresentative
    ];
    const shouldResetProperties = !(
      relatedRoles.includes(currentRole) && relatedRoles.includes(data.role)
    );

    if (shouldResetProperties) {
      setProperties([]);
      methods.setValue('properties', []);
    }

    onSave(data);
  };

  if (getCompaniesDDown.error) {
    return (
      <ShowState
        type="information"
        variant="error"
        message={getCompaniesDDown.error}
        buttonLabel="Try again"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (getTeamsError) {
    return (
      <ShowState
        type="information"
        variant="error"
        message={getTeamsError}
        buttonLabel="Try again"
        onClick={() => window.location.reload()}
      />
    );
  }

  const checkIsDisabled = () => {
    const rolesNotAllowed = [
      RoleEnum.BuildingManager,
      RoleEnum.OnSiteRepresentative,
      RoleEnum.PropertyAdmin
    ].includes(methods.getValues('role'));

    return (
      isSaving ||
      (rolesNotAllowed &&
        (!methods.getValues('properties') || methods.getValues('properties')?.length === 0))
    );
  };

  return (
    <FormProvider {...methods}>
      <InputText
        placeholder="First Name"
        name="first_name"
        defaultValue={defaultValues.first_name}
        type={InputTextType.text}
        config={{
          required: 'Complete this field',
          maxLength: {
            value: 100,
            message: '100 Characters Maximum'
          },
          validate: {
            noSpecialCharacters: (value) => validateName(value)
          }
        }}
      />
      <InputText
        placeholder="Last Name"
        name="last_name"
        defaultValue={defaultValues.last_name}
        type={InputTextType.text}
        config={{
          required: 'Complete this field',
          maxLength: {
            value: 100,
            message: '100 Characters Maximum'
          },
          validate: {
            noSpecialCharacters: (value) => validateName(value)
          }
        }}
      />
      <InputText
        placeholder="Email"
        name="email"
        defaultValue={defaultValues.email}
        type={InputTextType.email}
        isReadonly
        config={{
          required: 'Complete this field'
        }}
      />
      <Controller
        name="role"
        control={methods.control}
        defaultValue={defaultValues.role}
        rules={{ required: 'Complete this field' }}
        render={({ field: { name, value, onChange } }) => (
          <Dropdown
            valueInitial={value}
            data={roles}
            onChange={({ [name]: selectedOption }) => {
              const { value } = selectedOption.selected;
              onChange(value);
              setRoleSelected(value);
            }}
            name={name}
            placeholder="Role"
            size="md"
          />
        )}
      />

      {isAdminOrFdeManager && showTeamSelector(roleSelected) && (
        <>
          <Gap height={1.8} />
          <Controller
            name="team_id"
            control={methods.control}
            defaultValue={defaultValues.team_id}
            render={({ field: { name, value, onChange } }) => (
              <Dropdown
                valueInitial={value}
                labelInitial={initialTeamLabel}
                data={
                  formatLabelValue({
                    data: getTeamsData,
                    value: 'id',
                    label: 'name'
                  }) as IValueLabel[]
                }
                onChange={({ [name]: selectedOption }) => {
                  const { value, label } = selectedOption.selected;
                  methods.setValue('team_id', value);
                  onChange(value);
                  SetInitialTeamLabel(label);
                }}
                name={name}
                placeholder="Team"
                size="md"
                itemRef={getCompaniesScrollObserver}
                isLoading={getTeamsIsLoading}
              />
            )}
          />
        </>
      )}

      {showCompanySelectorFromRole(roleSelected) && (
        <>
          <Gap height={1.8} />
          <Controller
            name="company_id"
            control={methods.control}
            defaultValue={defaultValues.company_id}
            rules={{ required: 'Complete this field' }}
            render={({ field: { name, value, onChange } }) => (
              <Dropdown
                valueInitial={value}
                labelInitial={initialCompanyLabel}
                data={
                  formatLabelValue({ data: companies, value: 'id', label: 'name' }) as IValueLabel[]
                }
                onChange={({ [name]: selectedOption }) => {
                  const { value, label } = selectedOption.selected;
                  methods.setValue('company', { id: value, name: label });
                  setProperties([]);
                  methods.setValue('properties', []);
                  onChange(value);
                  setCompanySelected(value);
                  setInitialCompanyLabel(label);
                  onResetUnassignedProperties();
                }}
                name={name}
                placeholder="Company"
                size="md"
                itemRef={getCompaniesScrollObserver}
                isLoading={getCompaniesDDown.isLoading}
              />
            )}
          />
        </>
      )}

      {showPropertySelectorFromRole(roleSelected) && companySelected && (
        <>
          <Gap height={2} />
          <SelectProperties
            properties={properties as IProperty[]}
            companySelected={companySelected}
            onGetProperties={(properties) => {
              setProperties(properties);
              methods.setValue('properties', properties);
            }}
          />
        </>
      )}

      <Gap height={2} />
      <Row gap={1}>
        <Button
          name="save_button"
          variant={ButtonVariant.contained}
          color={ButtonColor.primary}
          size={ButtonSize.medium}
          onClick={methods.handleSubmit(handleSave)}
          isDisabled={checkIsDisabled()}
          isLoading={isSaving}
        >
          Save
        </Button>
        <ButtonLink
          name="cancel_button"
          variant={ButtonLinkVariant.outline}
          color={ButtonLinkColor.primary}
          size={ButtonLinkSize.medium}
          to="../users"
          isDisabled={isSaving}
        >
          Cancel
        </ButtonLink>
      </Row>
    </FormProvider>
  );
};

export default UserDetailForm;
