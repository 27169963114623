import PropTypes from 'prop-types';
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import SkeletonPiece, { SkeletonPieceColor } from 'src/components/atoms/SkeletonPiece';
import { Content } from './styles';

export type UserTabLoaderProps = {
  rows?: number;
};

const UserTabLoader: React.FC<UserTabLoaderProps> = (props) => {
  const { rows } = props;
  return (
    <>
      {Array(rows)
        .fill(0)
        .map((_, i) => {
          return (
            <Content key={i}>
              <Gap />
              <Row justify="space-around">
                <SkeletonPiece width={140} height={20} color={SkeletonPieceColor.gray200} />
                <Gap />
                <SkeletonPiece width={140} height={20} color={SkeletonPieceColor.gray200} />
                <Gap />
                <SkeletonPiece width={140} height={20} color={SkeletonPieceColor.gray200} />
              </Row>
              <Gap />
            </Content>
          );
        })}
    </>
  );
};

UserTabLoader.propTypes = {
  rows: PropTypes.number
};

UserTabLoader.defaultProps = {
  rows: 10
};

export default UserTabLoader;
