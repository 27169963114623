import PropTypes from 'prop-types';
import Gap from 'src/components/atoms/Gap';
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import SkeletonPiece, {
  SkeletonPieceAlign,
  SkeletonPieceColor
} from 'src/components/atoms/SkeletonPiece';
import { Container } from './styles';

export type PropertyListLoaderProps = {
  rows?: number;
  showCopyButtonApplicant?: boolean;
  showCopyButtonLeasingTeam?: boolean;
};

const PropertyListLoader: React.FC<PropertyListLoaderProps> = (props: PropertyListLoaderProps) => {
  const { rows, showCopyButtonApplicant, showCopyButtonLeasingTeam } = props;
  return (
    <div id="propertyListLoader">
      {Array(rows)
        .fill(0)
        .map((_, i) => {
          return (
            <div key={i} id="properties">
              <Line height="tiny" />
              <Container>
                <Row justify="space-between">
                  <Row>
                    <Row>
                      <SkeletonPiece
                        width={40}
                        height={40}
                        color={SkeletonPieceColor.gray200}
                        isRounded
                      />
                    </Row>
                    <Row direction="column">
                      <SkeletonPiece width={185} height={20} color={SkeletonPieceColor.gray200} />
                      <Gap height={0.5} />
                      <SkeletonPiece width={339} height={15} color={SkeletonPieceColor.gray50} />
                      <Gap height={0.9375} />
                      <SkeletonPiece width={245} height={15} color={SkeletonPieceColor.gray100} />
                      <Gap height={0.5} />
                      <Row>
                        <Row>
                          <SkeletonPiece
                            width={127}
                            height={36}
                            align={SkeletonPieceAlign.left}
                            color={SkeletonPieceColor.gray50}
                          />
                          {showCopyButtonApplicant && (
                            <SkeletonPiece
                              width={50}
                              height={36}
                              align={SkeletonPieceAlign.left}
                              color={SkeletonPieceColor.gray50}
                              className="NoPaddingLeft"
                            />
                          )}
                        </Row>

                        <Row>
                          <SkeletonPiece
                            width={127}
                            height={36}
                            align={SkeletonPieceAlign.left}
                            color={SkeletonPieceColor.gray200}
                          />
                          {showCopyButtonLeasingTeam && (
                            <SkeletonPiece
                              width={50}
                              height={36}
                              align={SkeletonPieceAlign.left}
                              color={SkeletonPieceColor.gray200}
                              className="NoPaddingLeft"
                            />
                          )}
                        </Row>
                      </Row>
                    </Row>
                  </Row>
                  <Row>
                    <SkeletonPiece width={60} height={20} color={SkeletonPieceColor.gray200} />
                  </Row>
                </Row>
              </Container>
            </div>
          );
        })}
    </div>
  );
};
PropertyListLoader.propTypes = {
  rows: PropTypes.number,
  showCopyButtonApplicant: PropTypes.bool,
  showCopyButtonLeasingTeam: PropTypes.bool
};

PropertyListLoader.defaultProps = {
  rows: 10,
  showCopyButtonApplicant: false,
  showCopyButtonLeasingTeam: false
};

export default PropertyListLoader;
