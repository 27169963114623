import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { Status as StatusTypes } from 'src/ts/enums';
import { IEntryReport, IEntryReportData, IRejectedAction } from 'src/ts/interfaces';
import {
  getApplicantReportPDF,
  getEntryReportById,
  getFcraReportPDF,
  getPaginatedReportPDF,
  getReportPDF,
  getReportsByEntryId
} from './services';

export type StateData = {
  getEntryReportById: {
    status: StatusTypes;
    error?: string | null;
    data?: IEntryReportData | null;
  };
  getPaginatedReportPDF: {
    status: StatusTypes;
    error?: string | null;
    data?: void;
    fileName?: string;
  };
  getReportPDF: {
    status: StatusTypes;
    error?: string | null;
    data?: void;
    fileName?: string;
  };
  getFcraReportPDF: {
    status: StatusTypes;
    error?: string | null;
    data?: void;
    fileName?: string;
  };
  getApplicantReportPDF: {
    status: StatusTypes;
    error?: string | null;
    data?: void;
    fileName?: string;
    preset?: string;
  };
  getReportsByEntryId: {
    status: StatusTypes;
    error?: string | null;
    data?: IEntryReport[];
  };
};

export const initialState: StateData = {
  getEntryReportById: {
    status: StatusTypes.IDLE,
    data: null,
    error: null
  },
  getPaginatedReportPDF: {
    status: StatusTypes.IDLE,
    data: undefined,
    error: null,
    fileName: undefined
  },
  getReportPDF: {
    status: StatusTypes.IDLE,
    data: undefined,
    error: null,
    fileName: undefined
  },
  getFcraReportPDF: {
    status: StatusTypes.IDLE,
    error: null
  },
  getApplicantReportPDF: {
    status: StatusTypes.IDLE,
    data: undefined,
    error: null,
    fileName: undefined
  },
  getReportsByEntryId: {
    status: StatusTypes.IDLE,
    data: [],
    error: null
  }
};

export const getEntryReportByIdReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getEntryReportById.pending, (state: StateData) => {
    state.getEntryReportById.status = StatusTypes.LOADING;
    state.getEntryReportById.error = null;
  });
  builder.addCase(getEntryReportById.rejected, (state: StateData, action: IRejectedAction) => {
    state.getEntryReportById.status = StatusTypes.ERROR;
    state.getEntryReportById.error = action.error?.message;
  });
  builder.addCase(getEntryReportById.fulfilled, (state: StateData, action: PayloadAction<any>) => {
    state.getEntryReportById.status = StatusTypes.SUCCESS;
    state.getEntryReportById.data = action.payload.data;
  });
};

export const getPaginatedReportPDFReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getPaginatedReportPDF.pending, (state: StateData) => {
    state.getPaginatedReportPDF.status = StatusTypes.LOADING;
    state.getPaginatedReportPDF.error = null;
  });
  builder.addCase(getPaginatedReportPDF.rejected, (state: StateData, action: IRejectedAction) => {
    state.getPaginatedReportPDF.status = StatusTypes.ERROR;
    state.getPaginatedReportPDF.error = action.error?.message;
  });
  builder.addCase(
    getPaginatedReportPDF.fulfilled,
    (state: StateData, action: PayloadAction<any>) => {
      state.getPaginatedReportPDF.status = StatusTypes.SUCCESS;
      state.getPaginatedReportPDF.data = action.payload.data;
      state.getPaginatedReportPDF.fileName = action.payload.fileName;
    }
  );
};

export const getReportPDFReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getReportPDF.pending, (state: StateData) => {
    state.getReportPDF.status = StatusTypes.LOADING;
    state.getReportPDF.error = null;
  });
  builder.addCase(getReportPDF.rejected, (state: StateData, action: IRejectedAction) => {
    state.getReportPDF.status = StatusTypes.ERROR;
    state.getReportPDF.error = action.error?.message;
  });
  builder.addCase(getReportPDF.fulfilled, (state: StateData, action: PayloadAction<any>) => {
    state.getReportPDF.status = StatusTypes.SUCCESS;
    state.getReportPDF.data = action.payload.data;
    state.getReportPDF.fileName = action.payload.fileName;
  });
};

export const getFcraReportPDFReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getFcraReportPDF.pending, (state: StateData) => {
    state.getFcraReportPDF.status = StatusTypes.LOADING;
    state.getFcraReportPDF.error = null;
  });
  builder.addCase(getFcraReportPDF.rejected, (state: StateData, action: IRejectedAction) => {
    state.getFcraReportPDF.status = StatusTypes.ERROR;
    state.getFcraReportPDF.error = action.error?.message;
  });
  builder.addCase(getFcraReportPDF.fulfilled, (state: StateData, action: PayloadAction<any>) => {
    state.getFcraReportPDF.status = StatusTypes.SUCCESS;
    state.getFcraReportPDF.data = action.payload.data;
    state.getFcraReportPDF.fileName = action.payload.fileName;
  });
};

export const getApplicantReportPDFReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getApplicantReportPDF.pending, (state: StateData, action: PayloadAction<any>) => {
    state.getApplicantReportPDF.status = StatusTypes.LOADING;
    state.getApplicantReportPDF.error = null;
    state.getApplicantReportPDF.preset = (action as any).meta.arg.preset;
  });
  builder.addCase(getApplicantReportPDF.rejected, (state: StateData, action: IRejectedAction) => {
    state.getApplicantReportPDF.status = StatusTypes.ERROR;
    state.getApplicantReportPDF.error = action.error?.message;
  });
  builder.addCase(
    getApplicantReportPDF.fulfilled,
    (state: StateData, action: PayloadAction<any>) => {
      state.getApplicantReportPDF.status = StatusTypes.SUCCESS;
      state.getApplicantReportPDF.data = action.payload.data;
      state.getApplicantReportPDF.fileName = action.payload.fileName;
      state.getApplicantReportPDF.preset = action.payload.preset;
    }
  );
};

export const getReportsByEntryIdReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getReportsByEntryId.pending, (state: StateData) => {
    state.getReportsByEntryId.status = StatusTypes.LOADING;
    state.getReportsByEntryId.error = null;
  });
  builder.addCase(getReportsByEntryId.rejected, (state: StateData, action: IRejectedAction) => {
    state.getReportsByEntryId.status = StatusTypes.ERROR;
    state.getReportsByEntryId.error = action.error?.message;
  });
  builder.addCase(getReportsByEntryId.fulfilled, (state: StateData, action: PayloadAction<any>) => {
    state.getReportsByEntryId.status = StatusTypes.SUCCESS;
    state.getReportsByEntryId.data = action.payload.data;
  });
};
