// Vendor
import { subMonths } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// Components
import Button, { ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { ButtonGroup } from 'src/components/molecules/ButtonGroup';
import { ControlledDatePicker } from 'src/components/molecules/DatePicker';
import { ListView } from 'src/components/templates';
import { CompanyMultiSelector } from './components/CompanyMultiSelector';
import { PropertyMultiSelector } from './components/PropertyMultiSelector';
import { ReportDetailsSection } from './components/ReportDetailsSection';

// Constants
import { minDate } from 'src/constants/metabase';
import { defaultDate, TIMEFRAME_OPTIONS } from 'src/features/property/constants';

// Helpers
import { encode, getDateByLastDays, replaceCommaByPipe, today } from 'src/helpers';

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';
import useCompaniesDDown from 'src/features/company/hooks/useCompaniesDDown';
import usePropertiesDDown from 'src/features/property/hooks/usePropertiesDDown';

export type IFormValues = {
  timeframe: string;
  dateFrom: string;
  dateTo: string;
  company?: string;
  companyNames?: string;
  property?: string;
  propertyNames?: string;
};

export type IReportArg = {
  date_from: string;
  date_to: string;
  company: string;
  company_names: string;
  property: string;
  property_names: string;
};

const ReportingPage: React.FC = () => {
  const { isAdmin, isFraudAnalyst, isReviewer, isAccountRepresentative, user } = useRole();

  const canSelectCompany = isAdmin || isFraudAnalyst || isReviewer || isAccountRepresentative;

  const { onResetGetCompaniesDDown } = useCompaniesDDown();
  const { onResetGetPropertiesDDown } = usePropertiesDDown();

  const navigate = useNavigate();

  const [companySelected, setCompanySelected] = useState<undefined | string>(undefined);
  const sixMonthsAgo = subMonths(new Date(), 6).toDateString();

  const methods = useForm<IFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      timeframe: defaultDate,
      dateFrom: defaultDate,
      dateTo: today,
      company: undefined,
      companyNames: undefined,
      property: undefined,
      propertyNames: undefined
    }
  });
  const { dateTo, dateFrom, timeframe } = methods.watch();

  const onSubmit: SubmitHandler<IFormValues> = ({
    dateFrom,
    dateTo,
    company,
    companyNames,
    property,
    propertyNames
  }) => {
    const params: IReportArg = {
      date_from: dateFrom,
      date_to: dateTo,
      company: replaceCommaByPipe(company),
      company_names: replaceCommaByPipe(companyNames),
      property: replaceCommaByPipe(property),
      property_names: replaceCommaByPipe(propertyNames)
    };
    navigate(`/reporting/${encode(JSON.stringify(params))}`);
  };

  useEffect(() => {
    return () => {
      onResetGetCompaniesDDown();
      onResetGetPropertiesDDown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (methods && user && !canSelectCompany) {
      methods.setValue('company', user.company?.short_id);
      methods.setValue('companyNames', user.company?.name);
      setCompanySelected(user.company?.short_id || '');
    }
  }, [methods, user, canSelectCompany]);

  return (
    <ListView title="Reporting">
      <section className="content">
        <FormProvider {...methods}>
          <Gap height={2} />
          <Text variant={TextVariant.h3}>Select filters</Text>
          <Gap height={2} />
          <Row alignItems="center" gap={1}>
            <Row direction="column">
              <Text>Timeframe</Text>
              <ButtonGroup
                buttons={TIMEFRAME_OPTIONS}
                onClick={({ value }) => {
                  methods.setValue('timeframe', value);
                  methods.setValue(
                    'dateFrom',
                    value === 'custom' ? sixMonthsAgo : getDateByLastDays(value)
                  );
                  methods.setValue('dateTo', today);
                }}
              />
              <Gap height={2} />
            </Row>
            {timeframe === 'custom' && (
              <>
                <Row direction="column">
                  <Text>From</Text>
                  <ControlledDatePicker
                    name="dateFrom"
                    selectsStart
                    minDate={new Date(minDate)}
                    maxDate={new Date()}
                    placeholderText={minDate}
                    mode="type"
                  />
                </Row>
                <Row direction="column">
                  <Text>To</Text>
                  <ControlledDatePicker
                    name="dateTo"
                    selectsEnd
                    startDate={new Date(dateFrom)}
                    endDate={new Date(dateTo)}
                    minDate={new Date(dateFrom)}
                    placeholderText={today}
                    mode="type"
                  />
                </Row>
              </>
            )}
          </Row>
          <Text variant={TextVariant.normal} color="gray500">
            Reports available starting from December 1, 2021
          </Text>
          <Gap height={2} />
          <Line />
          <Gap height={2} />
          <Row direction="column">
            {canSelectCompany && (
              <>
                <CompanyMultiSelector
                  methods={methods}
                  onCallback={({ ids, data: companies, isCheckAll }) => {
                    onResetGetPropertiesDDown();

                    if (!ids) {
                      methods.resetField('property');
                      methods.resetField('propertyNames');
                    }

                    setCompanySelected(isCheckAll ? '' : ids !== '' ? ids : undefined);

                    methods.setValue('company', ids);
                    methods.setValue(
                      'companyNames',
                      isCheckAll
                        ? 'All companies'
                        : companies?.map((company) => company.label).join(',')
                    );

                    methods.clearErrors('property');
                  }}
                />
                <Gap height={2} />
              </>
            )}
            <PropertyMultiSelector
              methods={methods}
              company={companySelected}
              isDisabled={!companySelected || (companySelected as string).indexOf(',') > 0}
              onCallback={({ ids, data: properties, isCheckAll }) => {
                methods.setValue('property', ids);
                methods.setValue(
                  'propertyNames',
                  isCheckAll
                    ? 'All properties'
                    : properties?.map((property) => property.label).join(',')
                );
              }}
            />
          </Row>
          <Gap height={2} />
          <Line />
          <Gap height={2} />
          <ReportDetailsSection />
          <Gap height={2} />
          <Button
            name="createReport"
            variant={ButtonVariant.contained}
            onClick={methods.handleSubmit(onSubmit)}
            isDisabled={companySelected === undefined}
          >
            Continue
          </Button>
        </FormProvider>
      </section>
    </ListView>
  );
};

export default ReportingPage;
