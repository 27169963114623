import { useCallback } from 'react';

import { canSelectDocType } from 'src/features/myWork/helpers';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import { IFormValues } from 'src/pages/MyWorkProofDetailPage/components/AsideProofActions';
import { useAppDispatch } from 'src/store';
import { updateProofThunk } from '../services';

export const useProofUpdateAside = () => {
  const dispatch = useAppDispatch();

  const { SnackTypes, showSnackbar, VariantType } = useSnackbarProvider();

  const onUpdateProof = useCallback(
    async (proofId?: string, formValues?: IFormValues) => {
      if (!proofId) {
        showSnackbar(VariantType.error, 'Is missing the Id to update the Proof', SnackTypes.none);

        return;
      }

      const canSaveDocType = canSelectDocType(
        formValues?.result,
        formValues?.resultInsufficientReason
      );
      const type = canSaveDocType ? { type: formValues?.document } : {};
      const result = formValues?.result ? { result: formValues?.result } : {};
      const note = formValues?.note;

      try {
        await dispatch(
          updateProofThunk({
            id: proofId,
            result_insufficient_reason: formValues?.resultInsufficientReason,
            result_edited_reason: formValues?.resultEditedReason,
            note,
            ...result,
            ...type
          })
        );

        showSnackbar(VariantType.success, 'The proof has been updated', SnackTypes.none);
      } catch (error) {
        showSnackbar(VariantType.error, 'Failed to update the proof', SnackTypes.none);
      }
    },
    [SnackTypes.none, VariantType.error, VariantType.success, dispatch, showSnackbar]
  );

  return {
    onUpdateProof
  };
};
