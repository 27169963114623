// Vendor
import PropTypes from 'prop-types';

// Components
import Button, { ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import Row from 'src/components/atoms/Row/Row';
import Text, { TextColor } from 'src/components/atoms/Text';

// Enums
import { DupTypes } from 'src/features/DUP/ts/enums';

// Hooks
import useLanguage from 'src/context/Language/useLanguage';
import useForm from 'src/features/DUP/form/hooks/useForm';

export type LanguageSelectorProps = { type: DupTypes };

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ type }) => {
  const { onSetLanguage } = useForm();
  const { translate: t, language } = useLanguage();
  const isEnglish = language === 'EN';

  return (
    <Button
      name={`LanguageSelector_${language}`}
      onClick={() => {
        onSetLanguage(isEnglish ? 'ES' : 'EN', type);
      }}
      variant={ButtonVariant.ghost}
    >
      <Row gap={0.5} alignItems="center">
        <Text color={TextColor.primary} isBold>
          {isEnglish ? t('language_es') : t('language_en')}
        </Text>
        <Icon icon="language" />
      </Row>
    </Button>
  );
};

LanguageSelector.propTypes = {
  type: PropTypes.oneOf<DupTypes>(Object.values(DupTypes)).isRequired
};

LanguageSelector.defaultProps = {
  type: DupTypes.APPLICANT
};

export default LanguageSelector;
