import { getDateByLastDays } from 'src/helpers';

export const NAME = 'property';
export const IDENTITY_VERIFICATION_FILTER = 'status=ACTIVE,identity_verification_enabled=true';

export const TIMEFRAME_OPTIONS = [
  { value: 'last_60_days', label: 'Last 60 days' },
  { value: 'last_30_days', label: 'Last 30 days', isSelected: true },
  { value: 'custom', label: 'Custom' }
];

export const IDENTITY_VERIFICATION_REPORT_IMAGE_TYPES = [
  { value: 'selfie', label: 'Selfie' },
  { value: 'document', label: 'Document' },
  { value: 'none', label: 'None' }
];

export const defaultDate = getDateByLastDays(
  TIMEFRAME_OPTIONS.find((date) => date.isSelected)?.value
);
