import styled from 'styled-components/macro';

export const Constraints = styled.ul`
  margin-top: 0.25rem;
  margin-bottom: 1.7rem;
  padding: 0;

  li {
    margin-left: 1.5rem;
  }
`;

export const TOSPolicyText = styled.span`
  margin-top: 0.15rem;
  margin-left: 0.3rem;
  margin-bottom: 0.3rem;
`;
