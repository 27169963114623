/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Gap from 'src/components/atoms/Gap';
import { StepProps, StepState } from 'src/components/atoms/Step/Step';
import { StepsFlow } from 'src/components/molecules/StepsFlow';
import { DetailView } from 'src/components/templates/DetailView';
import useCountry from 'src/features/country/hooks/useCountry';
import useTeams from 'src/features/teams/hooks/useTeams';
import useTeamsSteps, { IStep } from 'src/features/teams/hooks/useTeamsSteps';
import { selectors as teamSelectors } from 'src/features/teams/teamsSlice';
import useTimezone from 'src/features/timezone/hooks/useTimezone';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import { ITeam } from 'src/ts/interfaces';
import TeamsCreateFormAssigning from './components/TeamsCreateFormAssigning';
import TeamsCreateFormConfirm from './components/TeamsCreateFormConfirm';
import TeamsCreateFormInformation from './components/TeamsCreateFormInformation';

const defaultValues: ITeam = {
  name: '',
  country: {
    id: undefined,
    label: undefined
  },
  timezones: {
    id: undefined,
    label: undefined
  },
  fdeManager: {
    id: undefined,
    label: undefined
  },
  membersSeniorFraudAnalyst: { data: [], count: undefined },
  membersFraudAnalyst: { data: [], count: undefined }
};

const TeamsCreatePage: React.FC = () => {
  const navigate = useNavigate();
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();

  const { initialSteps, Steps, stepsUpdated } = useTeamsSteps();
  const [currentStep, setCurrentStep] = useState<keyof typeof Steps>(Steps.teamInformation);
  const [steps, setSteps] = useState<IStep[]>(initialSteps);

  const { onCreate, onResetCreate, onGetAll: onGetTeams, onResetGetAllFDEManagers } = useTeams();
  const createIsSaved = useSelector(teamSelectors.create.isSaved);
  const createIsLoading = useSelector(teamSelectors.create.isLoading);
  const createError = useSelector(teamSelectors.create.error);

  const { onResetGetAll: onResetGetAllTimezone } = useTimezone();
  const { onResetGetAll: onResetGetAllCountries } = useCountry();

  const [formValues, setFormValues] = useState<ITeam>(defaultValues);

  useEffect(() => {
    if (createIsSaved) {
      onGetTeams();
      navigate('/teams');
      showSnackbar(VariantType.success, 'Team has been created successfully', SnackTypes.none);
    }
  }, [showSnackbar, VariantType, SnackTypes, createIsSaved]);

  useEffect(() => {
    return () => {
      onResetCreate();
      onResetGetAllFDEManagers();
      onResetGetAllTimezone();
      onResetGetAllCountries();
    };
  }, []);

  useEffect(() => {
    if (createError !== null) {
      showSnackbar(VariantType.error, createError as string, SnackTypes.none);
    }
  }, [showSnackbar, VariantType, SnackTypes, createError]);

  const onSetForm = (formData: ITeam, step: keyof typeof Steps) => {
    setFormValues(formData);
    setCurrentStep(step);
    setSteps(stepsUpdated(step, steps));
  };

  const onSave = () => {
    onCreate(formValues);
  };

  const handleStepClick = (step: StepProps) => {
    if (![StepState.completed, StepState.current].includes(step.state)) {
      return null;
    }

    if (currentStep !== step.id) {
      onSetForm(formValues, step.id as keyof typeof Steps);
    }
  };

  const methods = useForm<ITeam>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues
  });

  if (currentStep === Steps.teamInformation) {
    return (
      <DetailView back={{ to: '/teams', label: 'Go Back' }} title="Create Team">
        <StepsFlow steps={steps} onClickStep={handleStepClick} />
        <Gap height={2} />
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit((formData) => onSetForm(formData, Steps.assignMembers))}
          >
            <TeamsCreateFormInformation
              methods={methods}
              defaultValues={formValues}
              showButtons
              onBack={() => navigate('/teams')}
            />
          </form>
        </FormProvider>
      </DetailView>
    );
  }

  if (currentStep === Steps.assignMembers) {
    return (
      <DetailView
        title="Create Team"
        back={{
          onClick: () => onSetForm(formValues, Steps.teamInformation),
          label: 'Previous Step'
        }}
      >
        <StepsFlow steps={steps} onClickStep={handleStepClick} />
        <Gap height={2} />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit((formData) => onSetForm(formData, Steps.confirm))}>
            <TeamsCreateFormAssigning
              methods={methods}
              defaultValues={formValues}
              showButtons
              onBack={() => onSetForm(formValues, Steps.teamInformation)}
              process="create"
            />
          </form>
        </FormProvider>
      </DetailView>
    );
  }

  if (currentStep === Steps.confirm) {
    return (
      <DetailView
        title="Create Team"
        back={{
          onClick: () => onSetForm(formValues, Steps.assignMembers),
          label: 'Previous Step'
        }}
      >
        <FormProvider {...methods}>
          <TeamsCreateFormConfirm
            defaultValues={formValues}
            onSave={onSave}
            onBack={() => onSetForm(formValues, Steps.assignMembers)}
            isSaving={createIsLoading}
            steps={steps}
            handleStepClick={handleStepClick}
          />
        </FormProvider>
      </DetailView>
    );
  }
  return null;
};

export default TeamsCreatePage;
