// Vendor
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Redux
import { actions, selectors } from 'src/features/announcement/announcementSlice';
import { getLastThunk, markAsShownThunk } from 'src/features/announcement/services';
import { useAppDispatch } from 'src/store';

const useAnnouncement = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const announcementData = useSelector(selectors.getLast.data);
  const announcementIdle = useSelector(selectors.getLast.isIdle);
  const announcementIsLoading = useSelector(selectors.getLast.isLoading);

  const markAsShownIsLoading = useSelector(selectors.markAsShown.isLoading);
  const markAsShownIsSaved = useSelector(selectors.markAsShown.isSaved);

  const [show, setShow] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const onClose = useCallback(() => {
    setShow(false);
    dispatch(actions.resetGetLast());
    dispatch(actions.resetMarkAsShown());
  }, [dispatch]);

  const onGetLast = useCallback(() => {
    dispatch(getLastThunk());
  }, [dispatch]);

  /**
   * It dispatches an action to mark the announcement as shown
   */
  const onMarkAsShown = () => {
    const announcementId = announcementData?.length && announcementData[0]?.id;
    if (announcementId) dispatch(markAsShownThunk(announcementId));
  };

  // Checking the permission in order to get the announcements
  useEffect(() => {
    if (!show && announcementIdle) {
      onGetLast();
    }
  }, [show, announcementIdle, onGetLast]);

  // Show the modal when we have some announcements loaded
  useEffect(() => {
    if (!show && !announcementIsLoading && announcementData?.length) {
      setShow(true);
    }
  }, [show, announcementIsLoading, announcementData]);

  // Hide the modal after saving the announcement
  useEffect(() => {
    if (show && announcementData?.length && markAsShownIsSaved) {
      onClose();

      if (isConfirm && announcementData[0].goToConfirm) navigate(announcementData[0].goToConfirm);
    }
  }, [show, announcementData, markAsShownIsSaved, onClose, isConfirm, navigate]);

  return {
    onClose: onMarkAsShown,
    onConfirm: () => {
      onMarkAsShown();
      setIsConfirm(true);
    },
    show,
    setShow,
    announcement: announcementData && announcementData[0],
    isSaving: markAsShownIsLoading
  };
};

export default useAnnouncement;
