import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { getPaginationRequest, ICompany, IInvitation, IRejectedAction } from 'src/ts/interfaces';
import { NAME } from './constants';
import {
  create,
  getAll,
  getByEmail,
  invitationCreateUserThunk,
  invitationGetByIdThunk,
  remove,
  resendInvitation,
  searchGetAllCompany
} from './services';

type StateData = {
  getOne: { data?: IInvitation | null; status: StatusTypes; error?: string | null };
  searchGetAllCompany: {
    status: StatusTypes;
    data?: ICompany[] | null;
    error?: string | null;
    count?: number;
    filter: getPaginationRequest;
  };
  getAll: {
    status: StatusTypes;
    data?: IInvitation[] | null;
    error?: string | null;
    count?: number;
    filter: getPaginationRequest;
  };
  getByEmail: {
    status: StatusTypes;
    data?: IInvitation[] | null;
    error?: string | null;
    count?: number;
    filter: getPaginationRequest;
  };
  remove: {
    status: StatusTypes;
    error?: string | null;
  };
  create: {
    status: StatusTypes;
    error?: string | null;
  };
};

export const initialState: StateData = {
  getOne: { data: null, status: StatusTypes.IDLE, error: null },
  searchGetAllCompany: {
    status: StatusTypes.IDLE,
    data: [],
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 20,
      sort: 'name:asc'
    }
  },
  getAll: {
    status: StatusTypes.IDLE,
    data: [],
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 20,
      sort: 'inserted_at:desc'
    }
  },
  getByEmail: {
    status: StatusTypes.IDLE,
    data: null,
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 1,
      sort: 'inserted_at:asc'
    }
  },
  remove: {
    status: StatusTypes.IDLE,
    error: null
  },
  create: {
    status: StatusTypes.IDLE,
    error: null
  }
};

const invitationSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetGetAll: (state: StateData) => {
      state.getAll = initialState.getAll;
    },
    resetRemove: (state: StateData) => {
      state.remove = initialState.remove;
    },
    resetCreate: (state: StateData) => {
      state.create = initialState.create;
    },
    resetGetByEmail: (state: StateData) => {
      state.getByEmail = initialState.getByEmail;
    },
    searchCompany: (state: StateData, action: PayloadAction<getPaginationRequest>) => {
      state.searchGetAllCompany.filter.q = action.payload.q;
    },
    resetSearchCompany: (state: StateData) => {
      state.searchGetAllCompany = initialState.searchGetAllCompany;
    }
  },
  extraReducers: (builder) => {
    invitationGetByIdThunkReducer(builder);
    invitationCreateUserThunkReducer(builder);
    removeReducer(builder);
    getAllReducer(builder);
    getByEmailReducer(builder);
    resendInvitationReducer(builder);
    createReducer(builder);
    searchGetAllCompanyReducer(builder);
  }
});

const invitationGetByIdThunkReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(invitationGetByIdThunk.pending, (state: StateData) => {
    state.getOne.status = StatusTypes.LOADING;
    state.getOne.error = null;
  });

  builder.addCase(invitationGetByIdThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.getOne.status = StatusTypes.ERROR;
    state.getOne.error = action.error?.message;
  });

  builder.addCase(invitationGetByIdThunk.fulfilled, (state: StateData, action) => {
    state.getOne.status = StatusTypes.SUCCESS;
    state.getOne.data = action?.payload;
  });
};

const invitationCreateUserThunkReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(invitationCreateUserThunk.pending, (state: StateData) => {
    state.getOne.status = StatusTypes.LOADING;
    state.getOne.error = null;
  });

  builder.addCase(
    invitationCreateUserThunk.rejected,
    (state: StateData, action: IRejectedAction) => {
      state.getOne.status = StatusTypes.ERROR;
      state.getOne.error = action.error?.message;
    }
  );

  builder.addCase(invitationCreateUserThunk.fulfilled, (state: StateData) => {
    state.getOne.status = StatusTypes.SUCCESS;
  });
};

export const removeReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(remove.pending, (state: StateData) => {
    state.remove.status = StatusTypes.LOADING;
    state.remove.error = null;
  });
  builder.addCase(remove.rejected, (state: StateData, action: IRejectedAction) => {
    state.remove.status = StatusTypes.ERROR;
    state.remove.error = action.error.message;
  });
  builder.addCase(remove.fulfilled, (state: StateData) => {
    state.remove.status = StatusTypes.SUCCESS;
  });
};

export const createReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(create.pending, (state: StateData) => {
    state.create.status = StatusTypes.LOADING;
    state.create.error = null;
  });
  builder.addCase(create.rejected, (state: StateData, action: IRejectedAction) => {
    state.create.status = StatusTypes.ERROR;
    state.create.error = action.error.message;
  });
  builder.addCase(create.fulfilled, (state: StateData) => {
    state.create.status = StatusTypes.SUCCESS;
  });
};

export const getAllReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getAll.pending, (state: StateData) => {
    state.getAll.status = StatusTypes.LOADING;
    state.getAll.error = null;
  });
  builder.addCase(getAll.rejected, (state: StateData, action: IRejectedAction) => {
    state.getAll.status = StatusTypes.ERROR;
    state.getAll.error = action.error?.message || null;
  });
  builder.addCase(getAll.fulfilled, (state: StateData, action: any) => {
    const { data, count, page, rowsPerPage } = action.payload;

    const seen = new Set();
    const uniqueListResults = data?.filter((invitation: IInvitation) => {
      if (seen.has(invitation.id)) {
        return false;
      }
      seen.add(invitation.id);
      return true;
    });

    state.getAll.status = StatusTypes.SUCCESS;
    state.getAll.data = uniqueListResults;
    state.getAll.count = count;
    state.getAll.filter.page = page > 0 ? page - 1 : page;
    state.getAll.filter.rowsPerPage = rowsPerPage;
    state.getAll.filter.q = action?.meta?.arg.q || '';
  });
};

export const getByEmailReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getByEmail.pending, (state: StateData) => {
    state.getByEmail.status = StatusTypes.LOADING;
    state.getByEmail.error = null;
  });
  builder.addCase(getByEmail.rejected, (state: StateData, action: IRejectedAction) => {
    state.getByEmail.status = StatusTypes.ERROR;
    state.getByEmail.error = action.error?.message || null;
  });
  builder.addCase(getByEmail.fulfilled, (state: StateData, action: PayloadAction<any>) => {
    const { data, count } = action.payload;
    state.getByEmail.status = StatusTypes.SUCCESS;
    state.getByEmail.data = data;
    state.getByEmail.count = count;
  });
};

export const resendInvitationReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(resendInvitation.pending, (state: StateData, action) => {
    const invitationId = action.meta.arg;
    state.getAll.data?.map((invitation: IInvitation) => {
      if (invitation?.id === invitationId) {
        invitation.isLoading = true;
        invitation.isLoadingInvitationSent = true;
        invitation.isInvitationSent = false;
      }
      return invitation;
    });
  });
  builder.addCase(resendInvitation.rejected, (state: StateData, action: IRejectedAction) => {
    const invitationId = action.meta.arg.id;
    state.getAll.data?.map((invitation: IInvitation) => {
      if (invitation?.id === invitationId) {
        invitation.isLoading = false;
        invitation.isLoadingInvitationSent = false;
        invitation.isInvitationSent = false;
      }
      return invitation;
    });
  });
  builder.addCase(resendInvitation.fulfilled, (state: StateData, action: PayloadAction<any>) => {
    state.getAll.data?.map((invitation: IInvitation) => {
      if (invitation?.id === action.payload.invitationId) {
        invitation.isLoading = false;
        invitation.isLoadingInvitationSent = false;
        invitation.isInvitationSent = true;
      }
      return invitation;
    });
  });
};

export const searchGetAllCompanyReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(searchGetAllCompany.pending, (state: StateData) => {
    state.searchGetAllCompany.status = StatusTypes.LOADING;
    state.searchGetAllCompany.error = null;
  });
  builder.addCase(searchGetAllCompany.rejected, (state: StateData, action: IRejectedAction) => {
    state.searchGetAllCompany.status = StatusTypes.ERROR;
    state.searchGetAllCompany.error = action.error.message;
  });
  builder.addCase(searchGetAllCompany.fulfilled, (state: StateData, action: PayloadAction<any>) => {
    state.searchGetAllCompany.status = StatusTypes.SUCCESS;
    state.searchGetAllCompany.data = action.payload.data;
    state.searchGetAllCompany.count = action.payload.count;
  });
};

export const selectors = {
  getOne: {
    data: (state: RootState) => state[NAME].getOne.data,
    status: (state: RootState) => state[NAME].getOne.status,
    error: (state: RootState) => state[NAME].getOne.error
  },
  getAll: {
    isLoading: (state: RootState) => state[NAME].getAll.status === StatusTypes.LOADING,
    isIdle: (state: RootState) => state[NAME].getAll.status === StatusTypes.IDLE,
    error: (state: RootState) => state[NAME].getAll.error,
    data: (state: RootState) => state[NAME].getAll.data || initialState.getAll.data,
    filter: (state: RootState) => state[NAME].getAll.filter || initialState.getAll.filter,
    count: (state: RootState) => state[NAME].getAll.count || 0,
    isToolbarDisabled: (state: RootState) => {
      const stateGetAll = state[NAME].getAll;
      return (
        stateGetAll.status === StatusTypes.LOADING ||
        stateGetAll.error !== null ||
        (stateGetAll.count === 0 && stateGetAll.filter.q === '')
      );
    }
  },
  searchGetAllCompany: (state: RootState) => state[NAME].searchGetAllCompany,
  getByEmail: (state: RootState) => state[NAME].getByEmail,
  delete: {
    isLoading: (state: RootState) => state[NAME].remove.status === StatusTypes.LOADING,
    isRemoved: (state: RootState) => state[NAME].remove.status === StatusTypes.SUCCESS,
    error: (state: RootState) => state[NAME].remove.error
  },
  create: {
    isLoading: (state: RootState) => state[NAME].create.status === StatusTypes.LOADING,
    isCreated: (state: RootState) => state[NAME].create.status === StatusTypes.SUCCESS,
    error: (state: RootState) => state[NAME].create.error
  }
};

export const { reducer, actions } = invitationSlice;
