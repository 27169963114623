import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useFolderPage } from 'src/components/folders/FolderPageContext';
import { useMatchingEntriesByEntryId } from 'src/features/matchingEntries/hooks';
import { PotentialMatchingTile } from 'src/components/folders';
import { selectors as folderSelectors } from 'src/features/folders/foldersSlice';
import { useSelector } from 'react-redux';

export const PotentialMatchingTileContainer = () => {
  const { folderId } = useParams();
  const { selectedEntryId } = useFolderPage();
  const { onGetMatchingEntriesByEntryId, matches, isLoading } = useMatchingEntriesByEntryId(
    selectedEntryId as string
  );
  const isFolderLoading = useSelector(folderSelectors.isLoading);

  // The folderId dependency forces re-fetch when the FRP changes after merging or splitting an entry.
  useEffect(() => {
    if (selectedEntryId) {
      onGetMatchingEntriesByEntryId(selectedEntryId);
    }
  }, [folderId, selectedEntryId]);

  return (
    <PotentialMatchingTile
      isLoading={isLoading || isFolderLoading}
      matches={matches}
      entryId={selectedEntryId}
    />
  );
};
