/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { useSelector } from 'react-redux';

// Components
import { Card } from 'src/components/atoms/Card';
import Gap from 'src/components/atoms/Gap';
import { ListView } from 'src/components/templates';
import LoginInformation from './components/LoginInformation';
import NotificationSettingsForm from './components/NotificationSettingsForm';

// Redux
import { selectors as apiUserSelectors } from 'src/features/auth/authSlice';

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';
import useUser from 'src/features/user/hooks/useUser';

// Types
import { IUserRequest } from 'src/ts/interfaces';

const UserProfilePage: React.FC = () => {
  const user = useSelector(apiUserSelectors.user);
  const { onUpdateMyUser, onResetPassword, isToggleActive, setIsToggleActive } = useUser();

  const { isAdmin, isFDEManager, isLeasingTeam } = useRole();
  const canEdit = isAdmin || isFDEManager || isLeasingTeam || false;

  const handleOnSubmit = (data: { firstName?: string; lastName?: string }) => {
    const payload = {
      user: {
        first_name: data.firstName,
        last_name: data.lastName
      }
    };
    onUpdateMyUser(payload as IUserRequest);
  };

  const handleOnToggle = () => {
    const notificationStatus = !isToggleActive;
    setIsToggleActive(notificationStatus);
    const payload = {
      user: {
        settings_notification: Boolean(notificationStatus)
      }
    };
    onUpdateMyUser(payload as IUserRequest);
  };

  return (
    <ListView title="Login Information">
      <Card>
        <LoginInformation
          firstName={user?.first_name || ''}
          lastName={user?.last_name || ''}
          email={user?.email || ''}
          handleOnSubmit={handleOnSubmit}
          handleChangePassword={onResetPassword}
          canEdit={canEdit}
        />
        <Gap height={2} />
        <NotificationSettingsForm
          onClick={handleOnToggle}
          isToggleActive={isToggleActive}
          toggleName="notification"
          isToggleDisabled={false}
        />
      </Card>
    </ListView>
  );
};

export default UserProfilePage;
