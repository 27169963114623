import { isAxiosError } from 'src/api/instance';

const getErrorMessageThunk = (error?: object | unknown, key?: string) => {
  if (error && key) {
    if (isAxiosError(error)) {
      return error.response?.data[key] || "Impossible to get the error's key";
    }
  }
  return 'Something was wrong';
};

export default getErrorMessageThunk;
