/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { memo, useState } from 'react';

// Components
import { Line } from 'src/components/atoms/Line';
import Text from 'src/components/atoms/Text';
import { Box, ListContainer } from '../styles';
import ChoicesBox from './ChoicesBox';
import SearchBox from './SearchBox';
import ShowBox from './ShowBox';

// Reducer
import { IListContentState } from '../reducer';

// Types
import { CheckboxType } from 'src/components/atoms/Checkbox/types';
import { listProps } from '../types';

export type ListBoxProps = {
  list: listProps;
  state: IListContentState;
  showSearch?: boolean;
  searchPlaceholder?: string;
  showSelectChoices?: boolean;
  onSearch: (q: string) => void;
  onSelectAll: (isChecked: boolean) => void;
  onSelect: (content: CheckboxType[]) => void;
};

const ListBox: React.FC<ListBoxProps> = (props: ListBoxProps) => {
  const {
    list,
    state,
    showSelectChoices,
    showSearch,
    searchPlaceholder,
    onSearch,
    onSelectAll,
    onSelect
  } = props;

  const [search, setSearch] = useState('');

  return (
    <>
      <Text className="title_box">{list.title}</Text>
      <Box>
        {showSearch && (
          <SearchBox
            list={list}
            data={state.data}
            placeholder={searchPlaceholder}
            onSearch={(content) => {
              const q = content as string;
              onSearch(q);
              list.onSearch?.(q);
              setSearch(q);
            }}
          />
        )}
        {showSelectChoices && !list.hasError && (
          <ChoicesBox
            list={list}
            data={state.data}
            dataSelected={state.selected}
            onSelected={onSelectAll}
          />
        )}
        {(showSelectChoices || showSearch) && !list.hasError && <Line height="tiny" gap={1} />}
        <ListContainer>
          <ShowBox list={list} data={state.data} searchText={search} onUpdate={onSelect} />
        </ListContainer>
      </Box>
    </>
  );
};

export default memo(ListBox);
