// Vendor
import { FC, useState } from 'react';

// Components
import { Checkbox } from 'src/components/atoms/Checkbox';
import { Row } from 'src/components/atoms/Row';
import TableCell from 'src/components/atoms/TableCell';
import TableRow from 'src/components/atoms/TableRow';
import Tag from 'src/components/atoms/Tag';
import Text, { TextAlign, TextVariant } from 'src/components/atoms/Text';
import ButtonIcon, { ButtonIconBackgroundColor } from 'src/components/molecules/ButtonIcon';
import CollapsibleTableRowContent from './CollapsibleTableRowContent';

// Helpers
import {
  getResultStatusColorTag,
  transformDocumentTypeToReadable,
  transformResultToReadable
} from 'src/features/fdeInbox/helpers';

// Constants
import { columns } from '../constants';

// Types
import { CollapsibleTableRowProps } from '../types';

const CollapsibleTableRow: FC<CollapsibleTableRowProps> = ({
  entry,
  proof,
  onCheckbox,
  onDownload,
  onTextFields,
  onVisibility,
  onSaveNote,
  isAccountRepresentative
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          {!isAccountRepresentative && (
            <Checkbox
              name={`checkbox-proof_${proof.id}`}
              value={proof.id}
              label={proof.result}
              isChecked={proof?.isChecked || false}
              onClick={onCheckbox}
              showLabel={false}
            />
          )}
        </TableCell>
        <TableCell>
          <Text variant={TextVariant.small}>{proof.short_id}</Text>
        </TableCell>
        <TableCell textAlign="center">
          <Tag
            label={transformResultToReadable(proof.result)}
            color={getResultStatusColorTag(proof.result)}
            showBackground
          />
        </TableCell>
        <TableCell textAlign="center">
          <Text variant={TextVariant.small} align={TextAlign.center}>
            {transformDocumentTypeToReadable(proof.type)}
          </Text>
        </TableCell>
        <TableCell textAlign="right">
          <Row justify="flex-end" alignItems="center" gap={1}>
            <ButtonIcon
              name="button-visibility"
              icon="visibility"
              background={ButtonIconBackgroundColor.none}
              iconColor="primary"
              onClick={() => onVisibility?.(proof.id)}
            />
            <ButtonIcon
              name="button-download"
              icon="download"
              background={ButtonIconBackgroundColor.none}
              iconColor="primary"
              onClick={() =>
                onDownload?.(proof?.file, entry?.applicant?.full_name, proof?.fileName)
              }
            />
            <ButtonIcon
              name="button-arrow"
              icon={`keyboard-arrow-${open ? 'up' : 'down'}`}
              background={ButtonIconBackgroundColor.none}
              iconColor="primary"
              onClick={() => setOpen(!open)}
            />
          </Row>
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell
            style={{ backgroundColor: '#fff', paddingTop: 0, paddingBottom: 0 }}
            colSpan={columns.length}
          >
            <CollapsibleTableRowContent proof={proof} onSaveNote={onSaveNote} />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default CollapsibleTableRow;
