import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import InputSearch from 'src/components/organisms/InputSearch';
import useProperty from 'src/features/property/hooks/useProperty';
import { selectors as propertySelectors } from 'src/features/property/propertySlice';
import { StyledDiv, StyledRow } from './styles';
import PropTypes from 'prop-types';

export interface PropertyListFilterProps {
  identityVerificationPropertySearch?: boolean;
}
const PropertyListFilters: React.FC<PropertyListFilterProps> = (props) => {
  const navigate = useNavigate();

  const { identityVerificationPropertySearch } = props;

  const { onSearch, onSearchIdentityVerificationEnabled, allowCreateProperties } = useProperty();

  const getPropertiesIsLoading = useSelector(propertySelectors.getAll.isLoading);
  const getPropertiesError = useSelector(propertySelectors.getAll.error);
  const getPropertiesFilter = useSelector(propertySelectors.getAll.filter);
  const getPropertiesIsToolbarDisabled = useSelector(propertySelectors.getAll.isToolbarDisabled);

  const getIdentityVerificationEnabledPropertiesFilter = useSelector(
    propertySelectors.getAllIdentityVerificationEnabled.filter
  );
  const getIdentityVerificationEnabledPropertiesIsToolbarDisabled = useSelector(
    propertySelectors.getAllIdentityVerificationEnabled.isToolbarDisabled
  );

  return (
    <StyledRow gap={1} justify="flex-end">
      {!identityVerificationPropertySearch && (
        <InputSearch
          onSearch={({ value }) => onSearch(value)}
          onClear={() => onSearch('')}
          name="PropertyListSearch"
          value={(getPropertiesFilter.q && decodeURIComponent(getPropertiesFilter?.q)) || ''}
          placeholder="Search"
          isDisabled={getPropertiesIsToolbarDisabled}
        />
      )}
      {identityVerificationPropertySearch && (
        <InputSearch
          onSearch={({ value }) => onSearchIdentityVerificationEnabled(value)}
          onClear={() => onSearchIdentityVerificationEnabled('')}
          name="IdentityVerificationEnabledPropertyListSearch"
          value={
            (getIdentityVerificationEnabledPropertiesFilter.q &&
              decodeURIComponent(getIdentityVerificationEnabledPropertiesFilter?.q)) ||
            ''
          }
          placeholder="Search"
          isDisabled={getIdentityVerificationEnabledPropertiesIsToolbarDisabled}
        />
      )}

      {!identityVerificationPropertySearch && allowCreateProperties() && (
        <StyledDiv>
          <Button
            name="add_property_button"
            variant={ButtonVariant.contained}
            onClick={() => navigate(`/properties/create`)}
            isDisabled={getPropertiesIsLoading || getPropertiesError !== null}
          >
            Add property&nbsp;&nbsp;
            <Icon icon="plus" />
          </Button>
        </StyledDiv>
      )}
    </StyledRow>
  );
};

PropertyListFilters.propTypes = {
  identityVerificationPropertySearch: PropTypes.bool
};
export default PropertyListFilters;
