import { useSelector } from 'react-redux';
import { Row } from 'src/components/atoms/Row';
import CardPreview from 'src/components/molecules/CardPreview';

import Section from 'src/components/atoms/Section/Section';
import { selectors as entryReportSelectors } from 'src/features/fde-report/fdeReportSlice';
import IncomeVerificationProofResultDetail from 'src/pages/ReportPage/components/IncomeVerificationProofResultDetail/IncomeVerificationProofResultDetail';

const IncomeVerificationProofResults = () => {
  const entryReport = useSelector(entryReportSelectors.getEntryReport.data);

  const { proofs, income_calculation } = entryReport || {};
  const { documents, status_details } = income_calculation ?? {};
  const getProof = (proofId: string) => {
    return proofs?.find((proof) => proof.id === proofId);
  };

  if (documents === undefined) {
    return <div></div>;
  }

  return (
    <Section padding="0 3rem 3rem 3rem">
      <Row direction="column" gap={2}>
        {documents?.map((document, index) => {
          return (
            <Row.Col key={document.document_id}>
              <CardPreview
                type="none"
                numDocument={index + 1}
                title="test"
                thumbnailURL={getProof(document.document_id ?? '')?.thumb}
              >
                <IncomeVerificationProofResultDetail
                  document={document}
                  statusDetails={status_details ?? []}
                ></IncomeVerificationProofResultDetail>
              </CardPreview>
            </Row.Col>
          );
        })}
      </Row>
    </Section>
  );
};

export default IncomeVerificationProofResults;
