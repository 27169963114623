// Vendor
import { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

//Components
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import InputText, { InputTextType } from 'src/components/atoms/InputText/InputText';
import { Row } from 'src/components/atoms/Row';
import { AutoSuggest } from 'src/components/molecules/AutoSuggest';

// Redux
import { selectors as countrySelectors } from 'src/features/country/countrySlice';
import { selectors as timezoneSelectors } from 'src/features/timezone/timezoneSlice';

// Hooks
import useCountry from 'src/features/country/hooks/useCountry';
import useTimezone from 'src/features/timezone/hooks/useTimezone';
import useDebounce from 'src/hooks/useDebounce';

// Helpers
import { formatLabelValue, validateName } from 'src/helpers';

// Types
import { ITeam, IValueLabel } from 'src/ts/interfaces';

type TeamsCreateFormInformationProps = {
  methods: UseFormReturn<ITeam>;
  defaultValues?: ITeam;
  showButtons: boolean;
  onBack?: () => void;
};

const TeamsCreateFormInformation: React.FC<TeamsCreateFormInformationProps> = ({
  methods,
  defaultValues,
  showButtons,
  onBack
}: TeamsCreateFormInformationProps) => {
  /**
   * Handle the Countries
   */
  const { onSearch: onSearchCountry, onGetLabel: onGetLabelCountry } = useCountry();
  const getCountryData = useSelector(countrySelectors.data);

  const [searchCountry, setSearchCountry] = useState('');

  const debouncedSearchCountry = useDebounce(searchCountry, 800);
  useEffect(() => {
    if (debouncedSearchCountry.length) {
      onSearchCountry(debouncedSearchCountry.trim());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchCountry]);

  /**
   * Handle the Timezones
   */
  const { onSearch: onSearchTimezone, onGetLabel: onGetLabelTimezone } = useTimezone();
  const getTimezoneData = useSelector(timezoneSelectors.data);

  const [searchTimezone, setSearchTimezone] = useState('');

  const debouncedSearchTimezone = useDebounce(searchTimezone, 800);
  useEffect(() => {
    if (debouncedSearchTimezone.length) {
      onSearchTimezone(debouncedSearchTimezone.trim());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTimezone]);

  const onSetCountrySelected = ({ id, label }: { id?: number; label?: string }) => {
    methods.setValue('country.label', onGetLabelCountry(id));
    methods.setValue('country.id', id);
    methods.trigger('country.label');
  };

  const onSetTimezoneSelected = ({ id, label }: { id?: string; label?: string }) => {
    methods.setValue('timezones.label', onGetLabelTimezone(id));
    methods.setValue('timezones.id', id);
    methods.trigger('timezones.label');
  };

  useEffect(() => {
    if (defaultValues) {
      methods.setValue('name', defaultValues.name);
      methods.setValue('country', defaultValues.country);
      methods.setValue('timezones', defaultValues.timezones);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <>
      <InputText
        placeholder="Team Alias"
        name="name"
        defaultValue={defaultValues?.name}
        type={InputTextType.text}
        config={{
          required: 'Complete this field',
          validate: {
            noSpecialCharacters: (value) => validateName(value)
          }
        }}
      />
      <Controller
        name="country.label"
        control={methods.control}
        defaultValue={defaultValues?.country?.label}
        render={({ field: { name } }) => (
          <AutoSuggest
            name={name}
            data={
              formatLabelValue({
                data: getCountryData,
                value: 'value',
                label: 'label'
              }) as IValueLabel[]
            }
            placeholder="Country"
            initialLabel={defaultValues?.country?.label}
            onChange={({ value }) => {
              methods.setValue('country.id', undefined);
              setSearchCountry(value.toString());
            }}
            onBlur={() => methods.trigger('country.label')}
            onSelect={({ value, label }) => onSetCountrySelected({ id: Number(value), label })}
            onClear={() => onSetCountrySelected({})}
            isLoading={false}
            hasError={false}
            config={{
              validate: () => (!methods.watch('country.id') ? 'Find a valid country' : undefined),
              required: 'Complete this field'
            }}
          />
        )}
      />
      <Controller
        name="timezones.label"
        control={methods.control}
        defaultValue={defaultValues?.timezones?.label}
        render={({ field: { name } }) => (
          <AutoSuggest
            name={name}
            data={formatLabelValue({ data: getTimezoneData, value: 'value', label: 'label' })}
            placeholder="Timezone"
            initialLabel={defaultValues?.timezones?.label}
            onChange={({ value }) => {
              methods.setValue('timezones.id', undefined);
              setSearchTimezone(value.toString());
            }}
            onBlur={() => methods.trigger('timezones.label')}
            onSelect={({ value, label }) => onSetTimezoneSelected({ id: value, label })}
            onClear={() => onSetTimezoneSelected({})}
            isLoading={false}
            hasError={false}
            config={{
              validate: () =>
                !methods.watch('timezones.id') ? 'Find a valid timezone' : undefined,
              required: 'Complete this field'
            }}
          />
        )}
      />
      <Gap height={1.5} />
      {showButtons && (
        <Row gap={1}>
          <Button
            name="continue_to_assigning_button"
            variant={ButtonVariant.contained}
            color={ButtonColor.primary}
            size={ButtonSize.medium}
            type={ButtonType.submit}
          >
            Continue
          </Button>
          <Button
            name="cancel_button"
            variant={ButtonVariant.outline}
            color={ButtonColor.primary}
            size={ButtonSize.medium}
            onClick={onBack}
          >
            Cancel
          </Button>
        </Row>
      )}
    </>
  );
};

export default TeamsCreateFormInformation;
