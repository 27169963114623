import PropTypes from 'prop-types';
import { ChangeEvent, useEffect, useState } from 'react';
import { IconColor } from 'src/components/atoms/Icon/Icon';
import ButtonIcon, { ButtonIconBackgroundColor } from 'src/components/molecules/ButtonIcon';
import { Container, Input } from './styles';

export type InputNumberProps = {
  name: string;
  value: number;
  minValue?: number;
  maxValue?: number;
  onChange?: (n: number) => void;
};

const InputNumber: React.FC<InputNumberProps> = (props: InputNumberProps) => {
  const { name, value, minValue = 0, maxValue = 100, onChange } = props;

  const [valueUpdated, setValueUpdated] = useState<number>(value);
  const [disabledRemove, setDisabledRemove] = useState(false);
  const [disabledAdd, setDisabledAdd] = useState(false);

  useEffect(() => {
    if (valueUpdated === minValue) setDisabledRemove(true);
    if (valueUpdated === maxValue) setDisabledAdd(true);
    if (valueUpdated > minValue && valueUpdated < maxValue) {
      setDisabledAdd(false);
      setDisabledRemove(false);
    }
  }, [maxValue, minValue, valueUpdated]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(Number(e.target.value));
  };

  useEffect(() => {
    onChange?.(Number(valueUpdated));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueUpdated]);

  return (
    <Container>
      <ButtonIcon
        icon="removeCircle"
        name="Remove"
        onClick={() => setValueUpdated(valueUpdated - 1)}
        disabled={disabledRemove}
        background={ButtonIconBackgroundColor.none}
        iconColor={IconColor.primary}
        ariaLabel={`${name} with value ${valueUpdated}`}
      />
      <Input onChange={handleOnChange} type="number" value={valueUpdated} readOnly />
      <ButtonIcon
        icon="addCircle"
        name="Add"
        onClick={() => setValueUpdated(valueUpdated + 1)}
        disabled={disabledAdd}
        background={ButtonIconBackgroundColor.none}
        iconColor={IconColor.primary}
        ariaLabel={`${name} with value ${valueUpdated}`}
      />
    </Container>
  );
};

InputNumber.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  onChange: PropTypes.func
};

InputNumber.defaultProps = {
  minValue: 0,
  maxValue: 100,
  onChange: () => null
};

export default InputNumber;
