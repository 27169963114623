/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import { Icon } from 'src/components/atoms/Icon';
import { Loader } from 'src/components/atoms/Loader';
import { Row } from 'src/components/atoms/Row';
import Tag from 'src/components/atoms/Tag';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { ToolTip } from 'src/components/atoms/ToolTip';
import { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import { ShowState } from 'src/components/molecules/ShowState';
import { ModalVariant, ModalWidth } from 'src/components/organisms/Modal';
import {
  CenteredContainer,
  Container,
  ModalStyle,
  RowData,
  RowHeader,
  RowItem,
  RowTable
} from './styles';

// Redux
import { selectors as entryReportSelectors } from 'src/features/fde-report/fdeReportSlice';

//Enums
import { FeatureFlagKey } from 'src/ts/enums';

// Hooks
import useFDEReport from 'src/features/fde-report/hooks/useFDEReport';
import { useFeatureFlag } from 'src/hooks';

// Helpers
import {
  getEntryStatusColorTag,
  getResultStatusColorTag,
  startCase,
  transformResultToReadable
} from 'src/features/fdeInbox/helpers';
import { dateFormatWithHourAndTimeZone } from 'src/helpers/date';
import { getAttrEntryResult } from 'src/helpers/getEntryStatusIcon';

// Types
import { EntryResultType, EntryStatusType, IEntryReport, IProof } from 'src/ts/interfaces';

export type HistoryModalProps = {
  title: string;
  entryId: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const HistoryModal: FC<HistoryModalProps> = ({ title, entryId, showModal, setShowModal }) => {
  const { onGetReportsByEntryId, onResetGetReportsByEntryId } = useFDEReport();

  const entryReportData = useSelector(entryReportSelectors.getReportsByEntryId.data);
  const entryReportIsIdle = useSelector(entryReportSelectors.getReportsByEntryId.isIdle);
  const entryReportIsLoading = useSelector(entryReportSelectors.getReportsByEntryId.isLoading);
  const entryReportError = useSelector(entryReportSelectors.getReportsByEntryId.error);

  const cleanProceedWithCautionFlagEnabled = useFeatureFlag(
    FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION
  );

  useEffect(() => {
    if (entryReportIsIdle && entryId) {
      onGetReportsByEntryId(entryId);
    }
  }, [entryReportIsIdle, entryId]);

  const ProofItem = ({ proof, numDocument }: { proof: IProof; numDocument: number }) => {
    if (!proof.result) return null;

    const { icon: resultIcon, name: resultName } = getAttrEntryResult(
      proof.result,
      cleanProceedWithCautionFlagEnabled
    );

    return (
      <Row gap={0.2} alignItems="center">
        <Text variant={TextVariant.span}>{`Doc ${numDocument}`}</Text>
        <ToolTip direction={ToolTipDirection.top} content={resultName}>
          <Icon icon={resultIcon} />
        </ToolTip>
      </Row>
    );
  };

  const EntryReport = ({ report }: { report: IEntryReport }) => {
    const { entry_result, entry_status, inserted_at, proofs, reviewer } = report;
    return (
      <RowTable>
        {inserted_at && (
          <RowItem>
            <RowHeader>Date Generated:</RowHeader>
            <RowData>{dateFormatWithHourAndTimeZone(inserted_at)}</RowData>
          </RowItem>
        )}
        {entry_status && (
          <RowItem alignItems="center">
            <RowHeader>Entry Status:</RowHeader>
            <RowData>
              <Tag
                label={startCase(entry_status)}
                color={getEntryStatusColorTag(entry_status as EntryStatusType)}
                showBackground
              />
            </RowData>
          </RowItem>
        )}
        {entry_result && (
          <RowItem alignItems="center">
            <RowHeader>Entry Result:</RowHeader>
            <RowData>
              <Tag
                label={transformResultToReadable(entry_result as EntryResultType)}
                color={getResultStatusColorTag(
                  entry_result as EntryResultType,
                  cleanProceedWithCautionFlagEnabled
                )}
                showBackground
              />
            </RowData>
          </RowItem>
        )}
        <RowItem>
          <RowHeader>Document Amount:</RowHeader>
          <RowData>{proofs?.length || 0}</RowData>
        </RowItem>
        {proofs && (
          <RowItem>
            <RowHeader>Document Results:</RowHeader>
            <RowData>
              <Row wrap="wrap" gap={1}>
                {proofs.map((proof, index) => {
                  return (
                    <ProofItem
                      key={index}
                      proof={proof as unknown as IProof}
                      numDocument={index + 1}
                    />
                  );
                })}
              </Row>
            </RowData>
          </RowItem>
        )}
        {reviewer && (
          <RowItem>
            <RowHeader>Reviewed by:</RowHeader>
            <RowData>{`${reviewer?.first_name} ${reviewer?.last_name}`}</RowData>
          </RowItem>
        )}
      </RowTable>
    );
  };

  const onCloseModal = () => {
    onResetGetReportsByEntryId();
    setShowModal(false);
  };

  return (
    <ModalStyle
      className="HistoryModal"
      variant={ModalVariant.none}
      width={ModalWidth.large}
      title={title}
      showModal={showModal}
      setShowModal={setShowModal}
      isBackClosable
      isEscapeClosable
      showCloseButton
      showModalActions={false}
      onCancel={onResetGetReportsByEntryId}
    >
      <>
        {entryReportError !== null && (
          <ShowState
            variant="error"
            type="information"
            buttonLabel="Close"
            message={entryReportError}
            onClick={onCloseModal}
          />
        )}

        {entryReportIsLoading && (
          <CenteredContainer>
            <Loader />
          </CenteredContainer>
        )}

        {!entryReportIsLoading && entryReportData?.length === 0 && (
          <ShowState
            variant="empty"
            type="information"
            buttonLabel="Close"
            message="There is no historical information for this entry."
            onClick={onCloseModal}
          />
        )}

        <Container>
          {entryReportData?.map((report, index) => {
            return <EntryReport key={index} report={report} />;
          })}
        </Container>
      </>
    </ModalStyle>
  );
};

export default HistoryModal;
