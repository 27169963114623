import { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import CheckboxGroup, { CheckboxGroupProps } from './CheckboxGroup';

interface ControlledCheckboxGroupProps
  extends UseControllerProps,
    Omit<CheckboxGroupProps, 'name' | 'data'> {}

const ControlledCheckboxGroup: FC<ControlledCheckboxGroupProps> = (props) => {
  const { name, defaultValue, control, rules, shouldUnregister, onChange, ...checkGroupProps } =
    props;

  const {
    field: { value, onChange: onChangeController }
  } = useController({ name, defaultValue, control, rules, shouldUnregister });

  return (
    <CheckboxGroup
      {...checkGroupProps}
      data={value}
      name={name}
      onChange={(data) => {
        onChangeController(data);
        onChange?.(data);
      }}
    />
  );
};

ControlledCheckboxGroup.displayName = 'ControlledCheckboxGroup';

export default ControlledCheckboxGroup;
