// Vendor
import { useEffect, useState } from 'react';

// Components
import Gap from 'src/components/atoms/Gap';
import { Pagination } from 'src/components/molecules/Pagination';
import { ShowState } from 'src/components/molecules/ShowState';
import Table, { TableProps } from 'src/components/molecules/Table';
import { LoaderEntryLogTable } from '../LoaderEntryLogTable';

// Helpers
import { getTableData } from '../EntryLogsHistory/helpers';

//Enums
import { FeatureFlagKey } from 'src/ts/enums';

// Hooks
import { useEntriesLogsData, useSetEntriesLogs } from 'src/features/entriesLogs';
import { useFeatureFlag } from 'src/hooks';
import theme from 'src/theme';

const EntryLogTable = () => {
  const [tableData, setTableData] = useState<TableProps>({ columns: [], data: [] });
  const { data, filters, count, isLoading } = useEntriesLogsData();
  const { paginating } = useSetEntriesLogs();

  const cleanProceedWithCautionFlagEnabled = useFeatureFlag(
    FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION
  );

  // Modify entry logs data to be displayed in table
  useEffect(() => {
    // No data for current filters
    if (!data.length) {
      setTableData({ columns: [], data: [] });
      return;
    }

    const tableData = getTableData(data, cleanProceedWithCautionFlagEnabled);
    setTableData(tableData);
  }, [data, cleanProceedWithCautionFlagEnabled]);

  const getRowStyle = (row: any): React.CSSProperties => {
    return {
      backgroundColor:
        row.workflowType === 'Income Verification' ? theme.color.blue[100] : theme.color.purple[100]
    };
  };

  if (isLoading) return <LoaderEntryLogTable />;

  if (!data.length)
    return (
      <ShowState
        message="There are no records <br /> available to show"
        type="information"
        variant="empty"
      />
    );

  return (
    <>
      <Table columns={tableData.columns} data={tableData.data} getRowStyle={getRowStyle} />
      <Gap />
      <Pagination
        name="EntryLogsHistoryPagination"
        rowsPerPage={[10, 20, 50, 100]}
        pageInit={filters?.page}
        rowsPerPageInit={filters?.rowsPerPage || 10}
        listPosition="top"
        totalRows={count}
        onNext={(page, rowsPerPage) => paginating(page, rowsPerPage)}
        onPrev={(page, rowsPerPage) => paginating(page, rowsPerPage)}
        onRowsPerPage={paginating}
      />
    </>
  );
};

export default EntryLogTable;
