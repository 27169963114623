import styled from 'styled-components/macro';
import { AvatarProps } from '.';

export const StyledAvatar = styled.div<AvatarProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  border-radius: 50%;
  overflow: hidden;

  & > img {
    text-align: center;
    object-fit: cover;
    color: transparent;
    text-indent: 10000px;
    height: 100%;
  }
`;

export const StyledImg = styled.img<Pick<AvatarProps, 'isDisabled'>>`
  opacity: ${({ isDisabled }) => (isDisabled ? '50%' : '100%')};
`;
