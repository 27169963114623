// Components
import DUPPage from './DUPPage';

// Context
import LanguageProvider from 'src/context/Language/LanguageProvider';

const DUPPageProvider: React.FC = () => {
  return (
    <LanguageProvider>
      <DUPPage />
    </LanguageProvider>
  );
};

export default DUPPageProvider;
