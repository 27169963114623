// Vendor
import PropTypes from 'prop-types';

// Components
import { NavItemIcons, NavItemTarget } from 'src/components/atoms/NavItem';
import Modal, { ModalVariant } from 'src/components/organisms/Modal';
import NavSidebar, { UserInformation } from 'src/components/organisms/NavSidebar/NavSidebar';
import { Content, Wrapper } from 'src/components/organisms/NavSidebar/styles';
import { Container } from './styles';

// Hooks
import useAnnouncement from 'src/features/announcement/hooks/useAnnouncement';
import { useFrameworkUI } from 'src/features/frameworkUI/hooks';

// Router
import { getActiveRouteOptions } from 'src/router/navOptions';

// Types
import { INavOption } from 'src/ts/interfaces';

export type FrameworkProps = {
  children: React.ReactNode;
  menu: INavOption[];
  version: string;
  user: UserInformation;
  onLogout: () => void;
  isWide?: boolean;
};

const Framework: React.FC<FrameworkProps> = (props: FrameworkProps) => {
  const { menu, version, user, onLogout, children, isWide } = props;

  const { isOpenSidebar } = useFrameworkUI();

  const {
    show: showAnnouncement,
    setShow: setShowAnnouncement,
    onClose: onCloseAnnouncement,
    onConfirm: onConfirmAnnouncement,
    announcement,
    isSaving: isSavingAnnouncement
  } = useAnnouncement();

  return (
    <Container>
      {announcement && (
        <Modal
          title={announcement.title}
          text={announcement.description}
          variant={ModalVariant.none}
          showModal={showAnnouncement}
          setShowModal={setShowAnnouncement}
          labelButtonConfirm={announcement.labelButtonConfirm || 'Confirm'}
          labelButtonCancel={announcement.labelButtonClose}
          showCancelButton={!!announcement.labelButtonClose}
          onConfirm={onConfirmAnnouncement}
          onCancel={onCloseAnnouncement}
          isBackClosable={false}
          isEscapeClosable={false}
          isDisabledButtonConfirm={isSavingAnnouncement}
          isDisabledButtonCancel={isSavingAnnouncement}
          blurBackground
        />
      )}
      <NavSidebar menuItems={menu} version={version} user={user} onLogout={onLogout} />
      <Content isOpen={isOpenSidebar}>
        <Wrapper isWideRoute={isWide}>{children}</Wrapper>
      </Content>
    </Container>
  );
};

Framework.propTypes = {
  children: PropTypes.node.isRequired,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.oneOf<NavItemIcons>(Object.values(NavItemIcons)).isRequired,
      to: PropTypes.string.isRequired,
      target: PropTypes.oneOf<NavItemTarget>(Object.values(NavItemTarget)).isRequired,
      isVisible: PropTypes.bool.isRequired,
      permissions: PropTypes.array.isRequired
    }).isRequired
  ).isRequired,
  version: PropTypes.string.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired
  }).isRequired,
  onLogout: PropTypes.func.isRequired,
  isWide: PropTypes.bool
};

Framework.defaultProps = {
  children: 'Content',
  version: '2.0.0',
  user: {
    name: "Name's Company",
    logo: '/'
  },
  menu: getActiveRouteOptions(),
  onLogout: () => null,
  isWide: false
};

export default Framework;
