import { FC, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Modal, { ModalVariant, ModalWidth } from 'src/components/organisms/Modal';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { InputText, InputTextType } from 'src/components/atoms/InputText';
import { RadioButtonGroup } from 'src/components/molecules/RadioButtonGroup';
import { Row } from 'src/components/atoms/Row';
import { reasons } from './constants';
import { RequestDocumentsData, RequestDocumentsModalProps } from './types';

export const RequestDocument: FC<{ showForm: boolean; note?: string }> = ({ note, showForm }) => {
  if (showForm) {
    return (
      <InputText
        placeholder="Enter custom note"
        name="note"
        type={InputTextType.text}
        config={{
          required: 'Note is required',
          validate: {
            isEmpty: (value) => value?.trim().length || 'Note contains only whitespace'
          }
        }}
        showLabel={false}
        multiline
      />
    );
  }

  return (
    <Text color={TextColor.gray600} variant={TextVariant.h4}>
      An email will be sent to the applicant with the following message:
      <br /> <br />
      {note}
    </Text>
  );
};

const RequestDocumentsModal: FC<RequestDocumentsModalProps> = ({
  showModal,
  setShowModal,
  isLoadingButtonConfirm,
  onConfirm,
  onCancel
}) => {
  const [data, setData] = useState(reasons);
  const [step, setStep] = useState<'selectReason' | 'requestDocument'>('selectReason');
  const methods = useForm<RequestDocumentsData>({
    mode: 'onChange',
    defaultValues: {
      note: ''
    }
  });

  const reason = useMemo(() => data?.find((item) => item?.isChecked)?.value, [data]);
  const showForm = reason === 'Custom';

  const resetAll = () => {
    methods.reset();
    methods.clearErrors();
    setStep('selectReason');
    setData(reasons);
  };

  const modalProps = {
    selectReason: {
      title: 'Please select a reason',
      labelButtonConfirm: 'Continue',
      isDisabledButtonConfirm: !reason,
      onConfirm: () => setStep('requestDocument')
    },
    requestDocument: {
      title: 'Are you sure you want to request additional documents?',
      labelButtonConfirm: 'Send Email',
      isDisabledButtonConfirm: showForm && !methods.formState.isDirty && !methods.watch('note'),
      isLoadingButtonConfirm,
      onConfirm: () => {
        if (showForm) {
          methods.handleSubmit(({ note }) => onConfirm?.(note))();
        } else {
          onConfirm?.(reason);
        }
        resetAll();
      }
    }
  };

  return (
    <Modal
      variant={ModalVariant.none}
      labelButtonCancel="Cancel"
      width={ModalWidth.small}
      showModal={showModal}
      setShowModal={setShowModal}
      isBackClosable={false}
      {...modalProps[step]}
      onCancel={() => {
        resetAll();
        onCancel?.();
      }}
    >
      <Row direction="column" gap={1}>
        {step === 'selectReason' ? (
          <>
            <Text color={TextColor.gray600}>
              Indicates to the applicant the reason why additional documents are being requested.
            </Text>
            <RadioButtonGroup name="checkbox-group-reason" data={data} onChange={setData} />
          </>
        ) : (
          <FormProvider {...methods}>
            <RequestDocument note={reason} showForm={showForm} />
          </FormProvider>
        )}
      </Row>
    </Modal>
  );
};

export default RequestDocumentsModal;
