import { useSelector } from 'react-redux';

import { useAppDispatch } from 'src/store';
import { actions, selectors } from '../index';
import { useCallback } from 'react';
import { getPaginationRequest } from 'src/ts/interfaces';
import { getApplicantsThunk } from 'src/features/identityVerification';

export const useGetIdentityApplicants = () => {
  const dispatch = useAppDispatch();
  const filters = useSelector(selectors.getIdentityApplicants.filters);

  const onGetIdentityApplicantsResults = useCallback(
    (filters: getPaginationRequest) => dispatch(getApplicantsThunk(filters)),
    [dispatch]
  );

  const filtering = (filter?: string) => {
    dispatch(
      actions.identityVerificationFiltering({ filter, page: 0, rowsPerPage: filters?.rowsPerPage })
    );
  };

  const sorting = (sort?: string) => {
    dispatch(
      actions.identityVerificationSorting({ sort, page: 0, rowsPerPage: filters?.rowsPerPage })
    );
  };

  const searching = (q?: string) => {
    dispatch(actions.identityVerificationSearch({ q, page: 0, rowsPerPage: filters?.rowsPerPage }));
  };

  const paginating = (page: number, rowsPerPage: number) => {
    dispatch(actions.identityVerificationPagination({ page, rowsPerPage }));
  };

  return {
    filtering,
    sorting,
    searching,
    paginating,
    onGetIdentityApplicantsResults
  };
};
