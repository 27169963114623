import styled from 'styled-components';

import Text from 'src/components/atoms/Text';

export const StyledText = styled(Text)`
  text-decoration: underline;
`;

export const StyledImg = styled.img`
  border: 0.0625rem solid ${({ theme }) => theme.color.gray[200]};
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ContentContainer = styled.div`
  padding: 2.5rem 1.5rem;
`;

export const StyledContainer = styled.div`
  & .input-form {
    min-height: 300px;
    display: flex;
  }

  & .input-form > textarea {
    flex: 1;
  }
`;
