import Button from 'src/components/atoms/Button';
import { Row } from 'src/components/atoms/Row';
import styled from 'styled-components/macro';

export const StyledRow = styled(Row)`
  background: ${({ theme }) => theme.color.gray[100]};
  padding: 0.5rem 1rem;
`;

export const StyledButton = styled(Button)`
  margin-left: 0.5rem;
`;
