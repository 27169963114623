import { FC, HTMLAttributes } from 'react';
import { StyledTableBody } from './styles';

type TableBodyProps = HTMLAttributes<HTMLElement>;

const TableBody: FC<TableBodyProps> = (props) => {
  return <StyledTableBody {...props} />;
};

export default TableBody;
