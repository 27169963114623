import {
  AnnouncementApi,
  ApplicationUnauthenticatedApi,
  AppVersionApi,
  AuthApi,
  CompanyApi,
  EntryApi,
  EntryLogApi,
  FDEInboxApi,
  FolderApi,
  IncomeVerificationApi,
  InvitationApi,
  MetabaseApi,
  ProofApi,
  PropertyApi,
  PropertyFeatureApi,
  // TODO:  Update to a singular name ResourcesApi => ResourceApi
  ResourcesApi as ResourceApi,
  SettingsApi,
  TeamApi,
  UserApi
} from '@snapptinc/fraud-platform';
import { AxiosInstance } from 'axios';
import { configVars } from 'src/helpers';
import { defaultInstance, unauthenticatedSessionInstance } from './instance';

const commonParams: [undefined, string, AxiosInstance] = [
  undefined,
  configVars.server_url,
  defaultInstance
];

const authApi = new AuthApi(...commonParams);
const appUnauthenticatedApi = new ApplicationUnauthenticatedApi(
  undefined,
  configVars.server_url,
  unauthenticatedSessionInstance
);
const teamApi = new TeamApi(...commonParams);
const appVersionApi = new AppVersionApi(...commonParams);
const fdeInboxApi = new FDEInboxApi(...commonParams);
const fdeFolderApi = new FolderApi(...commonParams);
const fdeEntryApi = new EntryApi(...commonParams);
const invitationApi = new InvitationApi(...commonParams);
const propertyApi = new PropertyApi(...commonParams);
const userApi = new UserApi(...commonParams);
const companyApi = new CompanyApi(...commonParams);
const resourceApi = new ResourceApi(...commonParams);
const proofApi = new ProofApi(...commonParams);
const metabaseApi = new MetabaseApi(...commonParams);
const settingApi = new SettingsApi(...commonParams);
const entryLogApi = new EntryLogApi(...commonParams);
const announcementApi = new AnnouncementApi(...commonParams);
const propertyFeatureApi = new PropertyFeatureApi(...commonParams);
const incomeVerificationApi = new IncomeVerificationApi(...commonParams);

export {
  announcementApi,
  appUnauthenticatedApi,
  appVersionApi,
  authApi,
  companyApi,
  entryLogApi,
  fdeEntryApi,
  fdeFolderApi,
  fdeInboxApi,
  incomeVerificationApi,
  invitationApi,
  metabaseApi,
  proofApi,
  propertyApi,
  propertyFeatureApi,
  resourceApi,
  settingApi,
  teamApi,
  userApi
};
