import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RadioButtonData } from 'src/components/atoms/RadioButton';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { RadioButtonGroup } from 'src/components/molecules/RadioButtonGroup';
import Modal, { ModalVariant } from 'src/components/organisms/Modal';

const enum userType {
  'leasing',
  'snappt'
}

type ModalCreateProps = {
  team?: keyof typeof userType;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalCreate: React.FC<ModalCreateProps> = ({
  team,
  showModal,
  setShowModal
}: ModalCreateProps) => {
  const navigate = useNavigate();

  const [typeUserSelected, setTypeUserSelected] = useState(team || 'snappt');

  const userData = [
    {
      value: 'snappt',
      label: 'Snappt Team',
      isChecked: team === 'snappt'
    },
    {
      value: 'leasing',
      label: 'Leasing Team',
      isChecked: team === 'leasing'
    }
  ];

  // Selects the type of user to add using the information provided by RadioButtonGroup
  const handleAddUserSelect = (data: RadioButtonData[]) => {
    data.forEach((item) => {
      if (item.isChecked && item.value) {
        setTypeUserSelected(item.value as keyof typeof userType);
      }
    });
  };

  const handleAddUserConfirm = () => {
    navigate(`/users/invitation/create/${typeUserSelected}`);
  };

  if (!showModal) return null;

  return (
    <Modal
      labelButtonConfirm="Continue"
      setShowModal={setShowModal}
      showModal={showModal}
      title="Please select a Type of User"
      variant={ModalVariant.none}
      onConfirm={handleAddUserConfirm}
    >
      <Row direction="column" gap={1}>
        <Text color={TextColor.gray600} variant={TextVariant.normal}>
          Please indicate the type of user that will be invited.
        </Text>
        <RadioButtonGroup
          name="type-user-checkbox"
          data={userData}
          onChange={handleAddUserSelect}
        />
      </Row>
    </Modal>
  );
};

export default ModalCreate;
