import { FC, HTMLAttributes } from 'react';
import { StyledTable } from './styles';

type TableProps = HTMLAttributes<HTMLTableElement>;

const Table: FC<TableProps> = (props) => {
  return <StyledTable {...props} />;
};

export default Table;
