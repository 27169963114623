import { Content, StyledText, StyledTittle } from './styles';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SettingResponseData } from '@snapptinc/fraud-platform';

import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import Toggle from 'src/components/atoms/Toggle';
import Modal, { ModalVariant } from 'src/components/organisms/Modal';
import useSetting from 'src/features/settings/hooks/useSetting';
import { selectors as settingSelector } from 'src/features/settings/settingSlice';
import { Status } from 'src/ts/enums';

const PROGRAMATIC_SETTING = 'report_generation';
const ProgrammaticReport = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);

  const { onUpdateSetting, onGetSetting } = useSetting();
  const setting = useSelector(settingSelector.data) as SettingResponseData;
  const error = useSelector(settingSelector.error);
  const status = useSelector(settingSelector.status);

  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();
  useEffect(() => {
    if (status === Status.ERROR && error) {
      showSnackbar(
        VariantType.error,
        'An error has ocurred, please refresh and try again',
        SnackTypes.none
      );
    }
  }, [SnackTypes.none, VariantType.error, VariantType.success, error, showSnackbar, status]);

  useEffect(() => {
    if (status === Status.SUCCESS && isSuccessSnackbarOpen) {
      showSnackbar(
        VariantType.success,
        `Programmatic Report has been ${setting.enabled ? 'activated' : 'deactivated'} correctly`,
        SnackTypes.none
      );
      setIsSuccessSnackbarOpen(false);
    }
  }, [
    SnackTypes.none,
    VariantType.success,
    isSuccessSnackbarOpen,
    setting.enabled,
    showSnackbar,
    status
  ]);

  useEffect(() => {
    onGetSetting(PROGRAMATIC_SETTING);
  }, [onGetSetting]);

  return (
    <>
      <StyledTittle>Programmatic Report Generation</StyledTittle>
      <Content>
        <StyledText>
          Programmatic Report Ruling will generate reports for submissions that pass all
          programmatic tests. Activated companies will receive a email once the report is available
          to view.
        </StyledText>
        <Toggle
          name="programmaticReport"
          isActive={setting.enabled || false}
          onClick={() => {
            if (setting?.enabled) setIsModalOpen(true);
            else if (!setting.enabled) {
              onUpdateSetting({
                settingName: PROGRAMATIC_SETTING,
                settingRequest: { setting: { enabled: !setting.enabled } }
              });
              setIsSuccessSnackbarOpen(true);
            }
          }}
        />
      </Content>
      <Modal
        title="Are you sure you want to deactivate the Programmatic Report Generation"
        variant={ModalVariant.danger}
        showModal={isModalOpen}
        labelButtonConfirm="Yes, deactivate"
        onCancel={() => setIsModalOpen(false)}
        onConfirm={() => {
          onUpdateSetting({
            settingName: PROGRAMATIC_SETTING,
            settingRequest: { setting: { enabled: !setting.enabled } }
          });
          setIsModalOpen(false);
          setIsSuccessSnackbarOpen(true);
        }}
      >
        Reports will no longer be generated programmatically based on the Programmatic Tests Results
        for Clean documents and submissions.
      </Modal>
    </>
  );
};

export default ProgrammaticReport;
