// Vendor
import React from 'react';

// Components
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { StyledCol } from './styles';

const ReportDetailsSection: React.FC = () => {
  return (
    <section>
      <Text variant={TextVariant.h3}>Details</Text>
      <Gap height={1.5} />
      <Row gap={2}>
        <StyledCol alignContent="left">
          <Text>This report will contain:</Text>
          <ul>
            <li>Lifetime total applicants</li>
            <li>Lifetime reviewed applicants</li>
            <li>Lifetime clean applicants</li>
            <li>Lifetime clean rate</li>
            <li>Lifetime edited applicants</li>
            <li>Lifetime edited rate</li>
          </ul>
        </StyledCol>
        <StyledCol>
          <Text>Plus for selected companies or properties:</Text>
          <ul>
            <li>Total number clean</li>
            <li>Clean rate</li>
            <li>Total number edited</li>
            <li>Edited rate</li>
          </ul>
        </StyledCol>
      </Row>
    </section>
  );
};

export default ReportDetailsSection;
