import useAnnouncementNew from './useAnnouncement';

const useAnnouncement = () => {
  const { show, setShow, onClose, onConfirm, announcement, isSaving } = useAnnouncementNew();

  return {
    onClose,
    onConfirm,
    show,
    setShow,
    announcement,
    isSaving
  };
};

export default useAnnouncement;
