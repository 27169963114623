/* eslint-disable @typescript-eslint/no-explicit-any */
// Vendor
import { useState } from 'react';
import { useSelector } from 'react-redux';

// Helpers
import { generateUUID, readerFile } from 'src/helpers';
import { getDocumentTypes } from '../../helpers/getDocumentTypes';
import getProofErrorMessage, { PROOF_ERROR_KEYS } from '../../helpers/getProofErrorMessage';

// Enums
import { Status as StatusTypes } from 'src/ts/enums';

// Redux
import { useAppDispatch } from 'src/store';
import { actions, selectors } from '../../DUPSlice';

// Constants
import {
  FILES_TO_UPLOAD,
  POLLING,
  PRINT_TO_PDF_CONTEXTS,
  STATUS_PROCESSING_PROOF,
  STATUS_PROCESSING_UPLOAD
} from '../constants';

// Services
import {
  changeProofType,
  createProofPreURL,
  getProofToRemove,
  removeProof,
  reUploadProof
} from '../services';

// Types
import { IProofDUP, UnauthenticateSessionProofType } from '../ts/interfaces';

// Hooks
import useLanguage from 'src/context/Language/useLanguage';

const useProofs = () => {
  const dispatch = useAppDispatch();
  const { translate: t } = useLanguage();

  const [documentTypeSelected, setDocumentTypeSelected] = useState(
    getDocumentTypes(t)[0].value as UnauthenticateSessionProofType
  );

  const createProofPreURLResponse = useSelector(selectors.createProofPreURL);
  const getProofsResponse = useSelector(selectors.getProofs);
  const removeProofResponse = useSelector(selectors.removeProof);
  const changeProofTypeResponse = useSelector(selectors.changeProofType);

  const isValidFile = async (file: File, id: string, type: string, action: any) => {
    if (!file.name) return false;
    const [extension] = file.name.split('.').reverse();
    if (FILES_TO_UPLOAD.NOT_ALLOW_DOC_EXTENSIONS.includes(extension)) {
      dispatch(
        action({
          id,
          type,
          jobs_error: [
            {
              message: getProofErrorMessage('WordDocument', t),
              name: PROOF_ERROR_KEYS.WordDocument
            }
          ],
          process_status: STATUS_PROCESSING_PROOF.FAILED,
          isCustom: true
        })
      );
      return false;
    }

    if (!FILES_TO_UPLOAD.ALLOW_TYPES.includes(file.type)) {
      dispatch(
        action({
          id,
          type,
          jobs_error: [
            {
              message: getProofErrorMessage('UnsupportedFileType', t),
              name: PROOF_ERROR_KEYS.UnsupportedFileType
            }
          ],
          process_status: STATUS_PROCESSING_PROOF.FAILED,
          isCustom: true
        })
      );
      return false;
    }

    if (!file.name.match(FILES_TO_UPLOAD.NAME_REGX)) {
      dispatch(
        action({
          id,
          type,
          jobs_error: [
            {
              message: getProofErrorMessage('FileNameRegx', t),
              name: PROOF_ERROR_KEYS.FileNameRegx
            }
          ],
          process_status: STATUS_PROCESSING_PROOF.FAILED,
          isCustom: true
        })
      );
      return false;
    }

    if (file.name.length > FILES_TO_UPLOAD.MAX_FILE_NAME_LENGTH) {
      dispatch(
        action({
          id,
          type,
          jobs_error: [
            {
              message: getProofErrorMessage('FileNameLength', t),
              name: PROOF_ERROR_KEYS.FileNameLength
            }
          ],
          process_status: STATUS_PROCESSING_PROOF.FAILED,
          isCustom: true
        })
      );
      return false;
    }

    const fileText: any = await readerFile(file);
    const fileTextLowercase = fileText.toLowerCase();
    for (const creator of PRINT_TO_PDF_CONTEXTS) {
      if (fileTextLowercase.includes(creator)) {
        dispatch(
          action({
            id,
            type,
            jobs_error: [
              {
                message: getProofErrorMessage('PrintToPdf', t),
                name: PROOF_ERROR_KEYS.PrintToPdf
              }
            ],
            process_status: STATUS_PROCESSING_PROOF.FAILED,
            isCustom: true
          })
        );
        return false;
      }
    }

    return true;
  };

  const setMaxAttemptState = (id?: string, type?: string) => {
    if (id && type) {
      dispatch(
        actions.reUploadProofTemp({
          id,
          type,
          jobs_error: [
            {
              message: getProofErrorMessage('MaxAttempts', t),
              name: PROOF_ERROR_KEYS.MaxAttempts
            }
          ],
          process_status: STATUS_PROCESSING_PROOF.FAILED,
          isCustom: true
        })
      );
    } else {
      throw new Error('Is missing the identifier to find the Proof');
    }
  };

  const onReUploadFile = async (e: React.ChangeEvent<HTMLInputElement>, proof?: IProofDUP) => {
    if (e.target.files && proof && proof.id) {
      const file = e.target.files[0];

      if (await isValidFile(file, proof.id, proof.type, actions.reUploadProofTemp)) {
        dispatch(
          actions.reUploadProofTemp({
            id: proof.id,
            type: proof.type,
            status: StatusTypes.LOADING
          })
        );
        dispatch(reUploadProof({ file, proof, isTempFile: proof?.isCustom || false }));
      }
    } else {
      console.error('Selected file not found for reupload', e.target.files);
    }
  };

  const onUploadFile = async (file: File) => {
    const tempId = generateUUID();

    if (await isValidFile(file, tempId, documentTypeSelected, actions.addProofFailed)) {
      dispatch(
        actions.addProofTemp({
          id: tempId,
          type: documentTypeSelected,
          status: StatusTypes.LOADING
        })
      );
      dispatch(createProofPreURL({ type: documentTypeSelected, file, tempId }));
    }
  };

  const onRemove = async ({ id, isCustom }: Pick<IProofDUP, 'id' | 'isCustom'>) => {
    if (id) {
      if (isCustom) {
        await dispatch(getProofToRemove(id));
        await dispatch(actions.removeProof(id));
      } else {
        dispatch(removeProof(id));
      }
    } else {
      throw new Error('Is missing the identifier to delete the Proof');
    }
  };

  const onChangeProofType = (id?: string, type?: UnauthenticateSessionProofType) => {
    if (id && type) {
      dispatch(actions.changingProofType(id));
      dispatch(changeProofType({ id, type }));
    } else {
      throw new Error('Is missing the identifier to change the Proof type');
    }
  };

  const setProofState = (data: IProofDUP) => {
    dispatch(actions.reUploadProofTemp(data));
  };

  const getNameType = (type?: string) => {
    const existType = getDocumentTypes(t).filter((types) => types.value === type);
    if (existType.length > 0) return existType[0].label;
    return type || t('dup_proof_processing');
  };

  const isPolling = (proof?: IProofDUP) => {
    return !POLLING.STATUS_TO_STOP.includes(proof?.process_status || '');
  };

  const findProofState = (id?: string) => {
    if (id) {
      return getProofsResponse.data.find((proof) => proof.id === id);
    } else {
      throw new Error('Is missing the identifier to find the Proof');
    }
  };

  return {
    proofs: getProofsResponse.data,
    isCreating: createProofPreURLResponse.status === StatusTypes.LOADING,
    isRemoving: removeProofResponse.status === StatusTypes.LOADING,
    isChangingType: changeProofTypeResponse.status === StatusTypes.LOADING,
    isAbleToSubmit:
      createProofPreURLResponse.status !== StatusTypes.LOADING &&
      removeProofResponse.status !== StatusTypes.LOADING &&
      changeProofTypeResponse.status !== StatusTypes.LOADING &&
      getProofsResponse.data?.length > 0,
    isProofsIsProcessing: getProofsResponse.data?.some((proof) =>
      STATUS_PROCESSING_UPLOAD.includes(proof?.process_status || STATUS_PROCESSING_PROOF.IN_QUEUE)
    ),
    isProofsHasErrors: getProofsResponse.data?.some(
      (proof) => proof?.process_status === STATUS_PROCESSING_PROOF.FAILED
    ),
    setDocumentTypeSelected,
    onUploadFile,
    onReUploadFile,
    onRemove,
    onChangeProofType,
    getNameType,
    setProofState,
    isPolling,
    findProofState,
    setMaxAttemptState
  };
};

export default useProofs;
