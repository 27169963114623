import { format, formatISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SelectedFilterData } from 'src/components/organisms/Filter';
import { SelectedSortData } from 'src/components/organisms/Sort';
import { selectors } from '../entriesLogsSlice';
import { filterQueryOptions, selectedFilter, selectedSort } from '../constants';

const { results, startDate, endDate } = filterQueryOptions;

// This hook is based on the useGetSelectedData hook from the FDEInboxToolbar component.
// TODO: Refactor the useGetSelectedData hook to be more generic and reusable.
const useGetSelectedData = (): {
  selectedFilterData: SelectedFilterData;
  selectedSortData: SelectedSortData;
  buildFilterQuery: (selected: SelectedFilterData) => string;
} => {
  const { filters } = useSelector(selectors);
  const [selectedFilterData, setSelectedFilterData] = useState<SelectedFilterData>(selectedFilter);
  const [selectedSortData, setSelectedSortData] = useState<SelectedSortData>(selectedSort);

  useEffect(() => {
    const [field, order] = filters?.sort?.split(':') || Object.values(selectedSort);

    setSelectedSortData({ field, order });

    const selectedFilterData =
      filters?.filter?.split(',')?.reduce<Record<string, string>>((result, value) => {
        if (value.includes(results.field)) {
          const [, resultsOptions] = value.split(results.operator);
          result.results = resultsOptions;
        }

        if (value.includes(`${startDate.field}${startDate.operator}`)) {
          const [, startDate] = value.split('>');
          result.startDate = format(new Date(startDate), 'MM/dd/yyyy');
        }

        if (value.includes(`${endDate.field}${endDate.operator}`)) {
          const [, endDate] = value.split('<');
          const endDateMinus1 = new Date(endDate);
          endDateMinus1.setDate(endDateMinus1.getDate() - 1);
          result.endDate = format(endDateMinus1, 'MM/dd/yyyy');
        }

        return result;
      }, {}) || selectedFilter;

    setSelectedFilterData(selectedFilterData);
  }, [filters]);

  const buildFilterQuery = useCallback(({ results, startDate, endDate }: SelectedFilterData) => {
    let endDateParam = undefined;
    if (endDate) {
      const endDatePlus1 = new Date(endDate);
      endDatePlus1.setDate(endDatePlus1.getDate() + 1);
      endDateParam = formatISO(endDatePlus1);
    }

    const params = Object.entries({
      results,
      startDate: startDate ? formatISO(new Date(startDate)) : undefined,
      endDate: endDateParam
    }).reduce<Record<string, string>>(
      (result, [key, value]) => (value ? ((result[key] = value), result) : result),
      {}
    );

    const query = Object.entries(params)
      .map(
        ([key, value]) =>
          `${filterQueryOptions[key]?.field}${filterQueryOptions[key]?.operator}${value}`
      )
      .join(',');

    return decodeURIComponent(query);
  }, []);

  return { selectedFilterData, selectedSortData, buildFilterQuery };
};

export default useGetSelectedData;
