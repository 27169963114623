import { EntryResultEnum, EntryStatusEnum, EntrySuggestedRulingEnum } from 'src/ts/enums';

export const MockedEntries = [
  {
    applicant: {
      email: 'alexis+dd1@opticpower.com',
      full_name: 'Ophelia S Poodle',
      id: '59758407-dbd5-4417-9cc8-442c5902753d',
      phone: '(787) 391-9953'
    },
    folder: {
      id: '41d4d614-163a-4c48-a50f-577c94328fa2',
      property_name: 'Ferdies Cabanas'
    },
    folder_id: '41d4d614-163a-4c48-a50f-577c94328fa2',
    has_previously_submitted: false,
    id: '3b413bf0-7def-407c-b40a-274fcef557c9',
    inserted_at: '2021-12-21T21:28:00',
    note: '',
    result: EntryResultEnum.Clean,
    short_id: 'M31KTuSxsq',
    status: EntryStatusEnum.Ready,
    submission_time: '2021-12-21T21:27:59',
    suggested_ruling: EntrySuggestedRulingEnum.Edited,
    unit: '',
    updated_at: '2022-01-27T16:51:28'
  },
  {
    applicant: {
      email: 'alexis+dd1@opticpower.com',
      full_name: 'Ophelia S Poodle',
      id: 'f1eb45ab-66c3-467b-8dda-f2daf3ffe9a2',
      phone: '(787) 391-9953'
    },
    folder: {
      id: '41d4d614-163a-4c48-a50f-577c94328fa2',
      property_name: 'Ferdies Cabanas'
    },
    folder_id: '41d4d614-163a-4c48-a50f-577c94328fa2',
    has_previously_submitted: false,
    id: 'b89a4d42-a7f1-49a3-b27b-199ba943448b',
    inserted_at: '2021-12-21T17:27:41',
    result: EntryResultEnum.Edited,
    short_id: 'Edl5DD7mgq',
    status: EntryStatusEnum.Ready,
    submission_time: '2021-12-21T17:27:40',
    suggested_ruling: EntrySuggestedRulingEnum.Edited,
    unit: '',
    updated_at: '2022-01-27T16:51:10'
  },
  {
    applicant: {
      email: 'alexis+dd1@opticpower.com',
      full_name: 'Ophelia S Poodle',
      id: 'f3ee19b2-e223-4ebf-8ae8-fda335a1b667',
      phone: '(787) 391-9953'
    },
    folder: {
      id: '41d4d614-163a-4c48-a50f-577c94328fa2',
      property_name: 'Ferdies Cabanas'
    },
    folder_id: '41d4d614-163a-4c48-a50f-577c94328fa2',
    has_previously_submitted: false,
    id: 'e27aae70-2f68-49b2-97f4-1f66caab1da1',
    inserted_at: '2021-12-20T21:06:38',
    note: 'The documents you submitted cannot be accepted because they are not an acceptable document type. The acceptable document types are Bank Statements (checking and/or saving account) and Payroll Statements. Please go directly to your financial institution or payroll provider’s website to download the original PDF version of these documents. Do not rename the file.',
    result: EntryResultEnum.Undetermined,
    short_id: '6ndMMXcoE0',
    status: EntryStatusEnum.Ready,
    submission_time: '2021-12-20T21:06:35',
    suggested_ruling: EntrySuggestedRulingEnum.Edited,
    unit: '',
    updated_at: '2022-01-27T16:51:25'
  },
  {
    applicant: {
      email: 'alexis+dd1@opticpower.com',
      full_name: 'Ophelia S Poodle',
      id: '3820852d-e56d-4b35-a5d4-7eb2ebf29bc3',
      phone: '(787) 391-9953'
    },
    folder: {
      id: '41d4d614-163a-4c48-a50f-577c94328fa2',
      property_name: 'Ferdies Cabanas'
    },
    folder_id: '41d4d614-163a-4c48-a50f-577c94328fa2',
    has_previously_submitted: false,
    id: 'c12c640b-b1fd-4797-85ec-a306955eeeb7',
    inserted_at: '2021-12-06T21:28:06',
    note: '',
    result: EntryResultEnum.Pending,
    short_id: 'QFyswS6r3J',
    status: EntryStatusEnum.Pending,
    submission_time: '2021-12-06T21:28:05',
    suggested_ruling: EntrySuggestedRulingEnum.NotRun,
    unit: '',
    updated_at: '2021-12-22T00:01:07'
  }
];
