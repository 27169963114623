// Vendor
import { FC, HTMLAttributes } from 'react';

// Components
import IdentityVerificationRow from 'src/components/organisms/IdentityVerificationRow';
import { Container } from './styles';

// Types
import { IdentityVerificationResult, RoleType } from 'ts/interfaces';

export interface IdentityVerificationTableProps extends HTMLAttributes<HTMLDivElement> {
  result: IdentityVerificationResult | null;
  isLoading: boolean;
  role: RoleType;
}

const IdentityVerificationTable: FC<IdentityVerificationTableProps> = (props) => {
  const { role, result } = props;

  return (
    <Container>
      <IdentityVerificationRow result={result} role={role} />
    </Container>
  );
};

export default IdentityVerificationTable;
