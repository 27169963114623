import { useCallback } from 'react';

import { useAppDispatch } from 'src/store';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import { requestDocumentsThunk } from '../services';

export const useRequestDocuments = () => {
  const dispatch = useAppDispatch();
  const { SnackTypes, showSnackbar, VariantType } = useSnackbarProvider();

  const onRequestDocuments = useCallback(
    async (entryId?: string, message?: string) => {
      if (!entryId || !message) {
        showSnackbar(VariantType.error, 'Failed to request documents', SnackTypes.none);

        return;
      }

      try {
        await dispatch(requestDocumentsThunk({ id: entryId, message }));

        showSnackbar(VariantType.success, 'Your email request has been sent', SnackTypes.none);
      } catch (error) {
        showSnackbar(
          VariantType.error,
          'An error has occurred, please refresh and try again',
          SnackTypes.none
        );
      }
    },
    [SnackTypes.none, VariantType.error, VariantType.success, dispatch, showSnackbar]
  );

  return {
    onRequestDocuments
  };
};
