import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { Status as StatusTypes } from 'src/ts/enums';
import { IRejectedAction } from 'src/ts/interfaces';
import { getAll } from './services';
import { ICountry } from './ts/interfaces';

export type StateData = {
  status: StatusTypes;
  error?: string | null;
  data?: ICountry[] | null;
};

export const initialState: StateData = {
  status: StatusTypes.IDLE,
  data: [],
  error: null
};

export const getAllReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getAll.pending, (state: StateData) => {
    state.status = StatusTypes.LOADING;
    state.error = null;
  });
  builder.addCase(getAll.rejected, (state: StateData, action: IRejectedAction) => {
    state.status = StatusTypes.ERROR;
    state.error = action.error.message;
  });
  builder.addCase(getAll.fulfilled, (state: StateData, action: PayloadAction<any>) => {
    state.status = StatusTypes.SUCCESS;
    state.data = action.payload;
  });
};
