// Vendor
import { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';

// Components
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import InputText, { InputTextType } from 'src/components/atoms/InputText/InputText';
import { Row } from 'src/components/atoms/Row';
import { AutoSuggest } from 'src/components/molecules/AutoSuggest';
import TeamsAssigningFAModal from './TeamsAssigningFAModal';
import TeamsAssigningSFAModal from './TeamsAssigningSFAModal';

// Helpers
import getMembersCountLabel from 'src/features/teams/helpers/getMembersCountLabel';
import { formatLabelValue } from 'src/helpers';

// Hooks
import useTeams from 'src/features/teams/hooks/useTeams';
import useDebounce from 'src/hooks/useDebounce';

// Redux
import { selectors as teamsSelectors } from 'src/features/teams/teamsSlice';

// Types
import { CheckboxData } from 'src/components/atoms/Checkbox/types';
import { ITeam, IValueLabel } from 'src/ts/interfaces';

type TeamsCreateFormAssigningProps = {
  methods: UseFormReturn<ITeam>;
  defaultValues?: ITeam;
  showButtons: boolean;
  onBack?: () => void;
  process: 'create' | 'edit';
};

const TeamsCreateFormAssigning: React.FC<TeamsCreateFormAssigningProps> = ({
  methods,
  defaultValues,
  showButtons,
  onBack,
  process
}: TeamsCreateFormAssigningProps) => {
  /**
   * Handle the FDE Managers
   */
  const { onGetAllFDEManagers: onSearchFDEManagers } = useTeams();
  const getFDEManagersData = useSelector(teamsSelectors.getAllFDEManagers.data);
  const getFDEManagersIsLoading = useSelector(teamsSelectors.getAllFDEManagers.isLoading);
  const getFDEManagersError = useSelector(teamsSelectors.getAllFDEManagers.error);

  const [searchFDEManagers, setSearchFDEManagers] = useState('');

  const debouncedSearchFDEManagers = useDebounce(searchFDEManagers, 800);
  useEffect(() => {
    if (debouncedSearchFDEManagers.length) {
      onSearchFDEManagers(debouncedSearchFDEManagers.trim());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchFDEManagers]);

  const onSetFDEManagerSelected = ({ id, label }: { id?: string; label?: string }) => {
    methods.setValue('fdeManager.label', label);
    methods.setValue('fdeManager.id', id || null);
    methods.trigger('fdeManager.label');
  };

  /**
   * Handle the SFA
   */
  const [showAssignSFAModal, setShowAssignSFAModal] = useState(false);
  const getSFAMembers = (data: CheckboxData[] | []) => {
    methods.setValue('membersSeniorFraudAnalyst.data', data);
    methods.setValue(
      'membersSeniorFraudAnalyst.count',
      getMembersCountLabel(data.length.toString())
    );
    methods.trigger('membersSeniorFraudAnalyst.count');
  };

  /**
   * Handle the SFA
   */
  const [showAssignFAModal, setShowAssignFAModal] = useState(false);
  const getFAMembers = (data: CheckboxData[]) => {
    methods.setValue('membersFraudAnalyst.data', data);
    methods.setValue('membersFraudAnalyst.count', getMembersCountLabel(data.length.toString()));
    methods.trigger('membersFraudAnalyst.count');
  };

  useEffect(() => {
    if (defaultValues) {
      methods.setValue('fdeManager', defaultValues.fdeManager);
      methods.setValue('membersSeniorFraudAnalyst', defaultValues.membersSeniorFraudAnalyst);
      methods.setValue('membersFraudAnalyst', defaultValues.membersFraudAnalyst);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <>
      <Controller
        name="fdeManager.label"
        control={methods.control}
        defaultValue={defaultValues?.fdeManager?.label}
        render={({ field: { name } }) => (
          <AutoSuggest
            name={name}
            data={
              formatLabelValue({
                data: getFDEManagersData,
                value: 'id',
                label: 'first_name'
              }) as IValueLabel[]
            }
            placeholder="FDE Manager"
            initialLabel={defaultValues?.fdeManager?.label}
            onChange={({ value }) => {
              methods.setValue('fdeManager.id', undefined);
              setSearchFDEManagers(value.toString());
            }}
            onSelect={({ value, label }) => onSetFDEManagerSelected({ id: value, label })}
            onClear={() => {
              setSearchFDEManagers('');
              onSetFDEManagerSelected({});
            }}
            isLoading={getFDEManagersIsLoading}
            hasError={getFDEManagersError !== null}
            config={{
              validate: () => {
                if (searchFDEManagers && !methods.getValues('fdeManager.id')) {
                  return 'Find a valid FDE Manager';
                }
                return undefined;
              }
            }}
          />
        )}
      />
      <InputText
        placeholder="Senior Fraud Analyst"
        name="membersSeniorFraudAnalyst.count"
        defaultValue={getMembersCountLabel(defaultValues?.membersSeniorFraudAnalyst?.count)}
        type={InputTextType.text}
        isReadonly
        readOnlyColor="initial"
        onClick={() => setShowAssignSFAModal(true)}
      />
      <InputText
        placeholder="Fraud Analyst"
        name="membersFraudAnalyst.count"
        defaultValue={getMembersCountLabel(defaultValues?.membersFraudAnalyst?.count)}
        type={InputTextType.text}
        isReadonly
        readOnlyColor="initial"
        onClick={() => setShowAssignFAModal(true)}
      />
      <Gap height={1.5} />
      {showButtons && (
        <Row gap={1}>
          <Button
            name="continue_to_confirm_button"
            variant={ButtonVariant.contained}
            color={ButtonColor.primary}
            size={ButtonSize.medium}
            type={ButtonType.submit}
          >
            Continue
          </Button>
          <Button
            name="gobBack_to_information_button"
            variant={ButtonVariant.outline}
            color={ButtonColor.primary}
            size={ButtonSize.medium}
            onClick={onBack}
          >
            Cancel
          </Button>
        </Row>
      )}
      {showAssignSFAModal && (
        <TeamsAssigningSFAModal
          showModal={showAssignSFAModal}
          setShowModal={setShowAssignSFAModal}
          assigned={methods.watch('membersSeniorFraudAnalyst.data')}
          onSave={getSFAMembers}
        />
      )}
      {showAssignFAModal && (
        <TeamsAssigningFAModal
          showModal={showAssignFAModal}
          setShowModal={setShowAssignFAModal}
          assigned={methods.watch('membersFraudAnalyst.data')}
          onSave={getFAMembers}
        />
      )}
    </>
  );
};

export default TeamsCreateFormAssigning;
