import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { StyledCard } from './styles';

const DocumentLinkInstructions: React.FC = () => {
  return (
    <StyledCard border alignContent="center">
      <Icon color="primary" icon="warning" />
      <Gap height={1.1875} />
      <Text color="gray700" variant={TextVariant.small}>
        Please refer to your organization&apos;s policies and procedures before proceeding. If you
        would like your applicants to upload their own documents, email the Applicant Link directly
        to them.
      </Text>
      <Gap height={2} />
      <Text color="gray700" variant={TextVariant.small}>
        If you would like to upload documents on behalf of your applicant, use the Leasing Team Link
        to begin uploading. Applicants applying to Properties an active Yardi Integration will not
        be automatically uploaded if the link found here is used.
      </Text>
    </StyledCard>
  );
};

export default DocumentLinkInstructions;
