// Vendor
import styled, { css } from 'styled-components';

// Enums
import { DupTypes } from 'src/features/DUP/ts/enums';

// Constants
import { Color } from 'src/pages/DUPPage/constants';

const StyleIsCell = (isCell: boolean, type: DupTypes) => {
  if (!isCell) {
    return;
  }

  return css`
    border: 2px dashed ${Color[type].dark};
    padding: 2rem;
    width: 100%;
    min-height: 21.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .SelectFile {
      flex-direction: column;
      width: 100%;
      > div {
        align-self: center;
        margin-bottom: 0.5rem;
        .ButtonGroup,
        .inputFileButton {
          margin: 0;
        }
      }
    }
  `;
};

export const Container = styled.div<{ isCell?: boolean; type: DupTypes }>`
  ${({ isCell = false, type }) => StyleIsCell(isCell, type)};

  div[role='dropdown'] {
    min-width: 18rem;
    height: 3.25rem;

    div[role='button'] {
      height: 100%;
      .DDSubContainer {
        height: 100%;
        .DDButtonContain {
          height: 100%;
        }
      }
    }

    .DDContent,
    span,
    ul li button {
      font-size: 0.95rem;
    }
  }

  .ButtonGroup {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    button {
      color: ${({ type }) => Color[type].dark};
      border-color: ${({ type }) => Color[type].light};
      :hover {
        background: ${({ type }) => Color[type].light};
        color: ${({ type }) => Color[type].dark};
      }

      &.selected {
        color: ${({ type }) => Color[type].light};
        border-color: ${({ type }) => Color[type].dark};
        background: ${({ type }) => Color[type].dark};
        :hover {
          background: ${({ type }) => Color[type].dark};
          color: ${({ type }) => Color[type].light};
        }
      }
    }
  }
  .inputFile {
    width: 0.006rem;
    height: 0.006rem;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .inputFileButton {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    border: 0.063rem solid ${({ type }) => Color[type].dark};
    border-radius: 0.188rem;
    padding: 0.2rem;
    background: #fff;
    justify-content: center;
    outline: none;
    cursor: pointer;
    height: 3.25rem;
    min-width: 18rem;
    svg {
      margin-left: 0.5rem;
      path {
        fill: ${({ type }) => Color[type].icon};
      }
    }
    p {
      color: ${({ type }) => Color[type].dark};
    }
  }
  .inputFile + label:hover {
    background: transparent;
  }
  .inputFile:disabled + label {
    background: ${({ theme }) => theme.color.gray[90]};
    color: ${({ theme }) => theme.color.gray[600]};
    cursor: default;
    span,
    p {
      color: ${({ type }) => Color[type].dark};
    }
    svg {
      path {
        fill: ${({ theme }) => theme.color.gray[600]};
      }
    }
  }
`;

export const IconBorder = styled.div<{ type: DupTypes }>`
  background: ${({ type }) => Color[type].icon};
  border-radius: 50%;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  svg {
    margin-top: 0.3rem;
    color: #fff;
  }
`;
