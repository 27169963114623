import PropTypes from 'prop-types';
import { ToolTip as StyleToolTip, ToolTipWrapper as StyleToolTipWrapper } from './styles';

export enum ToolTipDirection {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
  bottom_fit = 'bottom_fit',
  right_center = 'right_center'
}

export type ToolTipProps = {
  direction: ToolTipDirection;
  children: React.ReactNode;
  content?: string | object;
  className?: string;
  hidden?: boolean;
};

const ToolTip: React.FC<ToolTipProps> = (props: ToolTipProps) => {
  const { direction, children, content, hidden, className = '' } = props;

  if (hidden) {
    return <>{children}</>;
  }

  return (
    <StyleToolTipWrapper className={`toolTip ${className}`}>
      {children}
      <StyleToolTip direction={direction} className={`toolTipDirection ${direction}`}>
        <>{content}</>
      </StyleToolTip>
    </StyleToolTipWrapper>
  );
};

ToolTip.propTypes = {
  children: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  direction: PropTypes.oneOf<ToolTipDirection>(Object.values(ToolTipDirection)).isRequired,
  hidden: PropTypes.bool
};

ToolTip.defaultProps = {
  direction: ToolTipDirection.bottom,
  content: 'Content',
  hidden: false
};

export default ToolTip;
