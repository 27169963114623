import { useCallback } from 'react';
import * as language from 'src/i18n/en';

// Constants
import { DOCUMENT_TYPES as documentTypes } from 'src/features/DUP/constants';

// Enums
import { FeatureFlagKey } from 'src/ts/enums';

// Hooks
import { useFeatureFlag, useSnackbarProvider } from 'src/hooks';
import { useAppDispatch } from 'src/store';
import { ProofType } from 'src/ts/interfaces';
import { updateProofThunk } from '../services';

type docTypeItem = {
  key: string;
  value: string;
  label: string;
};

export const useProofDocumentTypes = () => {
  const dispatch = useAppDispatch();
  const { SnackTypes, showSnackbar, VariantType } = useSnackbarProvider();

  const extendedFADocumentTypesFlagEnabled = useFeatureFlag(
    FeatureFlagKey.EXTENDED_FA_DOCUMENT_TYPES
  );

  const paystubAnEarningsRenameEnabled = useFeatureFlag(FeatureFlagKey.PAYSTUB_AND_EARNINGS_RENAME);

  const proofDocumentTypes = documentTypes.reduce((acc: docTypeItem[], docType: docTypeItem) => {
    // if the document type is PAYSTUB and the feature flag is enabled, rename the document label
    if (docType.value === 'PAYSTUB' && paystubAnEarningsRenameEnabled) {
      return [
        ...acc,
        {
          ...docType,
          label: language.default.dup_document_type_paystub_and_earnings
        }
      ];
    }

    // if the extended documents feature flag is enabled, return all document types
    if (extendedFADocumentTypesFlagEnabled) {
      return [...acc, docType];
    }

    // Return only PAYSTUB and BANK_STATEMENT document types if the flag s off
    return ['PAYSTUB', 'BANK_STATEMENT'].includes(docType.value) ? [...acc, docType] : acc;
  }, []);

  const onUpdateProofType = useCallback(
    async (proofId: string, documentType?: ProofType) => {
      try {
        await dispatch(
          updateProofThunk({
            id: proofId,
            type: documentType
          })
        );
      } catch (error) {
        showSnackbar(VariantType.error, 'Failed to update the proof', SnackTypes.none);
      }
    },
    [SnackTypes.none, VariantType.error, dispatch, showSnackbar]
  );

  return { proofDocumentTypes, onUpdateProofType };
};
