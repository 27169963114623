import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { configVars } from 'src/helpers';

const instancePDF: AxiosInstance = axios.create({
  withCredentials: true,
  headers: { 'content-type': 'application/pdf' },
  responseType: 'blob'
});

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // NOTE: By sending response.data we are missing extra information of each request like
  // `headers`, status, statusText. This is a `wontFix` for now because we need to change the
  // redux slices

  // TODO: In order to send the Axios `response` each API file, inside the feature folder, need to return `response.data`(The line below) instead of only response
  return response.data;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  // A console error can be added if we want an alert in datadog for every response failure
  // sending the complete axios Error without losing context of the http error
  return Promise.reject(error);
};

instancePDF.interceptors.response.use(onResponse, onResponseError);
const isAxiosError = axios.isAxiosError;
export { isAxiosError };
export type { AxiosError };
export default instancePDF;

export const configInstancePDF: [undefined, string, AxiosInstance] = [
  undefined,
  configVars.server_url,
  instancePDF
];
