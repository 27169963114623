import { FC, HTMLAttributes } from 'react';

import { StyledTableBodyCollapsable } from './styles';

type TableBodyCollapsableProps = HTMLAttributes<HTMLElement>;

const TableBodyCollapsable: FC<TableBodyCollapsableProps> = (props) => (
  <StyledTableBodyCollapsable {...props} />
);

export default TableBodyCollapsable;
