import { ListView } from 'src/components/templates';
import PropertyList from './components/PropertyList';
import PropertyListFilters from './components/PropertyListFilters';
import PropertyListPagination from './components/PropertyListPagination';

const PropertyListPage = () => {
  return (
    <ListView title="Properties" filters={<PropertyListFilters />}>
      <PropertyList />
      <PropertyListPagination />
    </ListView>
  );
};

export default PropertyListPage;
