import PropTypes from 'prop-types';
import React from 'react';
import { LinkComponent } from './styles';

export enum ButtonLinkTarget {
  blank = '_blank',
  self = '_self'
}

export enum ButtonLinkVariant {
  contained = 'contained',
  outline = 'outline',
  ghost = 'ghost'
}

export enum ButtonLinkColor {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  cancel = 'cancel',
  disabled = 'disabled'
}

export enum ButtonLinkSize {
  big = 'big',
  normal = 'normal',
  medium = 'medium'
}

export enum ButtonLinkDisabledStyle {
  white = 'white',
  gray = 'gray'
}

export type ButtonLinkProps = {
  name: string;
  to: string;
  children: React.ReactNode;
  variant: ButtonLinkVariant;
  color?: ButtonLinkColor;
  size?: ButtonLinkSize;
  label?: string;
  download?: boolean;
  target?: ButtonLinkTarget;
  isDisabled?: boolean;
  dataId?: string;
  disabledStyle?: ButtonLinkDisabledStyle;
  isExternal?: boolean;
};

const ButtonLink: React.FC<ButtonLinkProps> = (props: ButtonLinkProps) => {
  const {
    name,
    variant,
    size,
    isDisabled,
    to,
    download,
    children,
    color,
    target,
    label,
    dataId,
    disabledStyle,
    isExternal
  } = props;

  const downloadable = download ? { download } : null;

  const config = {
    name,
    id: name,
    variant,
    size,
    color,
    'data-disabledstyle': disabledStyle,
    target: isDisabled ? ButtonLinkTarget.self : target,
    role: 'button',
    'aria-label': label,
    'aria-pressed': true,
    'data-id': dataId || label,
    'data-disabled': isDisabled
  };

  if (isExternal) {
    return (
      <LinkComponent
        {...config}
        {...downloadable}
        rel="noopener noreferrer"
        as="a"
        href={isDisabled ? '#' : to}
      >
        {children}
      </LinkComponent>
    );
  }

  return (
    <LinkComponent {...config} to={isDisabled ? '#' : to}>
      {children}
    </LinkComponent>
  );
};

export const ButtonPropTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  variant: PropTypes.oneOf<ButtonLinkVariant>(Object.values(ButtonLinkVariant)).isRequired,
  color: PropTypes.oneOf<ButtonLinkColor>(Object.values(ButtonLinkColor)).isRequired,
  size: PropTypes.oneOf<ButtonLinkSize>(Object.values(ButtonLinkSize)).isRequired,
  isDisabled: PropTypes.bool,
  disabledStyle: PropTypes.oneOf<ButtonLinkDisabledStyle>(Object.values(ButtonLinkDisabledStyle)),
  to: PropTypes.string.isRequired,
  download: PropTypes.bool,
  isExternal: PropTypes.bool,
  target: PropTypes.oneOf<ButtonLinkTarget>(Object.values(ButtonLinkTarget))
};

export const ButtonDefaultProps = {
  name: 'ButtonLink',
  variant: ButtonLinkVariant.contained,
  color: ButtonLinkColor.primary,
  target: ButtonLinkTarget.self,
  size: ButtonLinkSize.normal,
  isDisabled: false,
  isExternal: false,
  to: '/',
  label: 'ButtonLink'
};

ButtonLink.propTypes = ButtonPropTypes;
ButtonLink.defaultProps = ButtonDefaultProps;

export default ButtonLink;
