import { FC } from 'react';
import { Icon } from '../Icon';

import { Container, Wrapper } from './styles';

const Header: FC = () => {
  return (
    <Container data-testid="Header">
      <Wrapper>
        <Icon icon="logo-white" />
      </Wrapper>
    </Container>
  );
};

export default Header;
