import { TagColor } from 'src/components/atoms/Tag';
import Theme from 'src/theme';

import {
  DocumentReviewEntryResultEnum,
  EntryResultEnum,
  EntryStatusEnum,
  EntrySuggestedRulingEnum,
  ProofSuggestedRulingEnum
} from 'src/ts/enums';
import {
  EntryResultType,
  EntryStatusType,
  EntrySuggestedRulingType,
  ProofSuggestedRulingType
} from 'src/ts/interfaces';

export const getStatusColor = ({
  theme,
  status
}: {
  theme: typeof Theme;
  status: EntryStatusType;
}) => {
  switch (status) {
    case EntryStatusEnum.Ready:
      return theme.color.green[700];
    case EntryStatusEnum.Pending:
      return theme.color.yellow[500];
    case EntryStatusEnum.Viewed:
      return theme.color.gray[500];
    default:
      return theme.color.gray[300];
  }
};

export const getEntryStatusColorTag = (status: EntryStatusType) => {
  switch (status) {
    case EntryStatusEnum.Ready:
      return TagColor.green;
    case EntryStatusEnum.Pending:
      return TagColor.yellow;
    case EntryStatusEnum.Viewed:
    default:
      return TagColor.gray;
  }
};

export const getResultStatusColorTag = (
  status?: EntryResultType,
  cleanProceedWithCautionFlagEnabled?: boolean
) => {
  switch (status) {
    case EntryResultEnum.Clean:
      return TagColor.green;
    case EntryResultEnum.Edited:
      return TagColor.red;
    case EntryResultEnum.Undetermined: {
      if (cleanProceedWithCautionFlagEnabled) return TagColor.snappt_blue;
      else return TagColor.yellow;
    }
    case EntryResultEnum.Pending:
      return TagColor.gray;
    case EntryResultEnum.CleanProceedWithCaution:
      return TagColor.yellow;
    default:
      return TagColor.gray;
  }
};

export const getDocumentReviewResultStatusColorTag = (status?: DocumentReviewEntryResultEnum) => {
  switch (status) {
    case DocumentReviewEntryResultEnum.Clean:
      return TagColor.green;
    case DocumentReviewEntryResultEnum.Edited:
      return TagColor.red;
    case DocumentReviewEntryResultEnum.Undetermined: {
      return TagColor.yellow;
    }
    default:
      return TagColor.gray;
  }
};

export const getSuggestedRulingColorTag = (suggestedRuling: EntrySuggestedRulingType) => {
  switch (suggestedRuling) {
    case EntrySuggestedRulingEnum.Clean:
      return TagColor.green;
    case EntrySuggestedRulingEnum.Edited:
      return TagColor.red;
    case EntrySuggestedRulingEnum.NotRun:
    case EntrySuggestedRulingEnum.NoDocuments:
    case EntrySuggestedRulingEnum.Flagged:
    default:
      return TagColor.gray;
  }
};

export const getProofSuggestedRulingIcon = (suggestedRuling?: ProofSuggestedRulingType) => {
  switch (suggestedRuling) {
    case ProofSuggestedRulingEnum.Clean:
      return 'check';
    case ProofSuggestedRulingEnum.Edited:
      return 'clear';
    case ProofSuggestedRulingEnum.NotRun:
    case ProofSuggestedRulingEnum.Flagged:
    default:
      return 'status-warning';
  }
};

export const getSuggestedRulingIcon = (suggestedRuling?: EntrySuggestedRulingType) => {
  switch (suggestedRuling) {
    case EntrySuggestedRulingEnum.Clean:
      return 'check';
    case EntrySuggestedRulingEnum.Edited:
      return 'clear';
    case EntrySuggestedRulingEnum.NotRun:
    case EntrySuggestedRulingEnum.NoDocuments:
    case EntrySuggestedRulingEnum.Flagged:
    default:
      return 'status-warning';
  }
};
