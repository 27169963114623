import { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import RadioButtonGroup from './RadioButtonGroup';
import { RadioButtonGroupProps } from './types';

interface ControlledRadioButtonGroupProps
  extends UseControllerProps,
    Omit<RadioButtonGroupProps, 'name' | 'onChange' | 'data'> {}

const ControlledRadioButtonGroup: FC<ControlledRadioButtonGroupProps> = (props) => {
  const { name, control, defaultValue, rules, shouldUnregister, ...radioButtonGroupProps } = props;

  const {
    field: { value, onChange }
  } = useController({ name, control, defaultValue, rules, shouldUnregister });

  return (
    <RadioButtonGroup {...radioButtonGroupProps} name={name} data={value} onChange={onChange} />
  );
};

ControlledRadioButtonGroup.displayName = 'ControlledRadioButtonGroup';

export default ControlledRadioButtonGroup;
