import PropTypes from 'prop-types';
import { StyledContent, StyledSkeletonPiece } from './styles';

export enum SkeletonPieceColor {
  gray200 = '200',
  gray100 = '100',
  gray50 = '50'
}

export enum SkeletonPieceAlign {
  left = 'left',
  center = 'center',
  right = 'right'
}

export type SkeletonPieceProps = {
  width: number | string;
  height: number | string;
  color: SkeletonPieceColor;
  isRounded?: boolean;
  align?: SkeletonPieceAlign;
  className?: string;
};
const SkeletonPiece: React.FC<SkeletonPieceProps> = (props: SkeletonPieceProps) => {
  const { width, height, color, isRounded, align, className } = props;
  return (
    <StyledContent className={`SkeletonPiece ${className}`} align={align}>
      <StyledSkeletonPiece width={width} height={height} color={color} isRounded={isRounded} />
    </StyledContent>
  );
};

SkeletonPiece.propTypes = {
  color: PropTypes.oneOf<SkeletonPieceColor>(Object.values(SkeletonPieceColor)).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isRounded: PropTypes.bool,
  align: PropTypes.oneOf<SkeletonPieceAlign>(Object.values(SkeletonPieceAlign)),
  className: PropTypes.string
};

SkeletonPiece.defaultProps = {
  color: SkeletonPieceColor.gray200,
  height: 100,
  width: 100,
  isRounded: false,
  align: SkeletonPieceAlign.left,
  className: ''
};

export default SkeletonPiece;
