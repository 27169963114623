import { useState } from 'react';
import { useIdleTimer, EventsType } from 'react-idle-timer';

const SECONDS = 120;
const PROMPT_TIMEOUT = SECONDS * 1000;
const OBSERVED_EVENT: EventsType[] = [
  'click',
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange'
];

/**The purpose of this hook is to convert an event based library to a stateful one so react can
 * update the values on every event. If I don't do this then the log out modal would never show because react
 * will never update the initial values because useIdleTimer do not update any state locally.
 */
const useIdleTimerCallback = (
  timeout: number,
  onActioncallback: () => void,
  onIdleCallback: () => void,
  debounceTime?: number
) => {
  // The use of the sates is to force a re-run of the hook so it export a new reference of the Idle instance
  // with new value like getRemaining time, isPrompted etc
  const [, setIsPrompt] = useState(false);

  // log out user
  const onIdle = () => {
    onIdleCallback();
    setIsPrompt((state) => !state);
  };
  // inactive user
  const onPrompt = () => {
    setIsPrompt((state) => !state);
  };
  const onActive = () => {
    // cause a re-render of the hook to update the idle ref exported
    setIsPrompt((state) => !state);
  };
  const onAction = () => {
    setIsPrompt((state) => !state);
    onActioncallback();
  };

  return useIdleTimer({
    onIdle,
    onActive,
    onAction,
    crossTab: true,
    timeout,
    promptTimeout: PROMPT_TIMEOUT,
    onPrompt,
    debounce: debounceTime || 500, // half a second
    events: OBSERVED_EVENT
  });
};

export default useIdleTimerCallback;
