import { useSelector } from 'react-redux';

import { selectors as authSelectors } from 'src/features/auth/authSlice';

/**
 * This function checks if the entry is assigned to another user.
 * @returns An object with a function called checkIsAssignedToAnotherUser
 */
export const useEntryAssignedToAnother = () => {
  const user = useSelector(authSelectors.user);

  /**
   * If the review is assigned to another user, return true.
   * @param {string | null} [review_assigned_to_id] - The ID of the user assigned to the review.
   * @returns A boolean value.
   */
  const checkIsAssignedToAnotherUser = (review_assigned_to_id?: string | null) => {
    const isAssignedToAnotherUser = Boolean(
      review_assigned_to_id && review_assigned_to_id !== user?.id
    );

    return isAssignedToAnotherUser;
  };

  return { checkIsAssignedToAnotherUser };
};
