import React from 'react';
import PDFViewer, { PDFDocument } from 'src/components/molecules/PDFViewer/PDFViewer';
import { ModalVariant, ModalWidth } from 'src/components/organisms/Modal';
import { EmbedMode } from 'src/ts/enums/pdfViewer';
import { StyledModal } from './styles';

interface PDFModalProps {
  document: PDFDocument;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PDFModal = (props: PDFModalProps) => {
  const { document, showModal, setShowModal } = props;
  return (
    <StyledModal
      variant={ModalVariant.none}
      width={ModalWidth.extraLarge}
      showModal={showModal}
      setShowModal={setShowModal}
      showModalActions={false}
      isEscapeClosable
      showCloseButton
      title=""
    >
      <PDFViewer
        key={document.key}
        id={document.id}
        file={document.file}
        fileName={document.fileName}
        embedMode={EmbedMode.SIZED_CONTAINER}
        height="80vh"
      ></PDFViewer>
    </StyledModal>
  );
};

export default PDFModal;
