import { fdeEntryApi, incomeVerificationApi } from 'src/api';

const api = {
  calculateIncome: (submissionId: string, documents: any) => {
    return fdeEntryApi.calculateIncome(submissionId, documents);
  },
  sendReport: (submissionId: string) => {
    return incomeVerificationApi.completeIncomeVerificationReview(submissionId);
  },
  appealCalculation: (entryId: string) => {
    return incomeVerificationApi.assignIncomeVerificationDispute(entryId);
  },

  getAppealDocuments: async (entryId: string) => {
    return incomeVerificationApi.showIncomeVerificationDispute(entryId);
  }
};

export default api;
