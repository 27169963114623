import type { Company } from '@snapptinc/fraud-platform';
import { isValidPhoneNumber } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import { InputText } from 'src/components/atoms/InputText';
import { InputTextType } from 'src/components/atoms/InputText/InputText';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { Dropdown } from 'src/components/molecules/Dropdown';
import { FileInputField } from 'src/components/molecules/FileInputField';
import { STATES } from 'src/constants/states';
import { CompanyDetailsFormInputs } from 'src/features/company/form/ts/interfaces';
import { onlyRequestedSymbols } from 'src/helpers';
import useUserRoles from 'src/features/auth/hooks/useUserRoles';

export type CompanyDetailsFormProps = {
  company: Company | any;
  handleOnSubmit: (data: any) => void;
  isSaving?: boolean;
};

const CompanyDetailsForm: React.FC<CompanyDetailsFormProps> = (props: CompanyDetailsFormProps) => {
  const { company, isSaving, handleOnSubmit } = props;

  const [isEditable, setIsEditable] = useState(false);

  const { isAdmin } = useUserRoles();
  const form = useForm<CompanyDetailsFormInputs | any>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues: {
      company: {
        name: company.name,
        address: company.address,
        city: company.city,
        state: company.state,
        zip: company.zip,
        phone: company.phone,
        website: company.website,
        logo: company.logo
      }
    }
  });

  const {
    reset,
    formState: { errors },
    handleSubmit,
    control
  } = form;

  const onCancel = () => {
    setIsEditable(false);
    reset();
  };

  const onSubmit = (data: CompanyDetailsFormInputs) => {
    handleOnSubmit(data.company);
  };

  return (
    <FormProvider {...form}>
      <Row>
        <Row.Col size={1}>
          <Text color={TextColor.initial} variant={TextVariant.h2}>
            Company Details
          </Text>
        </Row.Col>
        <Row.Col size={0.25}>
          <Button
            name={'edit-btn'}
            color={ButtonColor.primary}
            variant={ButtonVariant.contained}
            size={ButtonSize.normal}
            onClick={() => setIsEditable(!isEditable)}
          >
            Edit&nbsp;&nbsp;
            <Icon icon="edit" />
          </Button>
        </Row.Col>
      </Row>

      <Gap height={2} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <Row gap={2}>
            <Row.Col size={1}>
              <Gap height={1} />

              <Row>
                <Row.Col size={1}>
                  <InputText
                    placeholder="Company Name"
                    name="company.name"
                    defaultValue={company.name}
                    type={InputTextType.text}
                    isReadonly={!isEditable || !isAdmin}
                    config={{
                      required: 'Complete this field',
                      validate: {
                        noSpecialCharacters: (value) => onlyRequestedSymbols(value)
                      },
                      maxLength: { value: 200, message: 'Company name too long' }
                    }}
                  />
                </Row.Col>
              </Row>

              <Row gap={1}>
                <Row.Col size={1}>
                  <InputText
                    placeholder="Address"
                    name="company.address"
                    defaultValue={company.address}
                    type={InputTextType.text}
                    isReadonly={!isEditable}
                    showOptionalLabel={false}
                    config={{
                      required: 'Complete this field',
                      maxLength: 120
                    }}
                  />
                </Row.Col>

                <Row.Col size={1}>
                  <InputText
                    placeholder="City"
                    name="company.city"
                    defaultValue={company.city}
                    type={InputTextType.text}
                    isReadonly={!isEditable}
                    config={{
                      required: 'Complete this field',
                      maxLength: 50
                    }}
                  />
                </Row.Col>
              </Row>

              <Row gap={1}>
                <Row.Col size={1}>
                  {isEditable ? (
                    <Controller
                      name="company.state"
                      control={control}
                      defaultValue={company.state}
                      rules={{ required: 'Complete this field' }}
                      render={({ field: { name, value, onChange } }) => (
                        <Dropdown
                          valueInitial={value}
                          data={[...STATES]}
                          onChange={({ [name]: selectedOption }) => {
                            onChange(selectedOption.selected.value);
                          }}
                          name="company.state"
                          placeholder="State"
                          size="md"
                        />
                      )}
                    />
                  ) : (
                    <InputText
                      placeholder="State"
                      name="company.state"
                      defaultValue={company.state}
                      type={InputTextType.text}
                      isReadonly={!isEditable}
                    />
                  )}
                </Row.Col>

                <Row.Col size={1}>
                  <InputText
                    placeholder="ZIP Code"
                    name="company.zip"
                    defaultValue={company.zip}
                    type={InputTextType.text}
                    isReadonly={!isEditable}
                    config={{
                      required: 'Complete this field',
                      validate: {
                        validZip: (zip) =>
                          zip && !/^\d{5}$/.test(zip) ? 'Invalid ZIP code format' : undefined
                      }
                    }}
                  />
                </Row.Col>
              </Row>

              <Row gap={1}>
                <Row.Col size={1}>
                  <InputText
                    placeholder={'Phone number'}
                    name="company.phone"
                    defaultValue={company.phone}
                    type={InputTextType.text}
                    isReadonly={!isEditable}
                    config={{
                      validate: {
                        validPhoneNumber: (value) =>
                          value && !isValidPhoneNumber(value, 'US')
                            ? 'Invalid phone number'
                            : undefined
                      }
                    }}
                  />
                </Row.Col>

                <Row.Col size={1}>
                  <InputText
                    placeholder="Website"
                    name="company.website"
                    defaultValue={company.website}
                    type={InputTextType.text}
                    isReadonly={!isEditable}
                  />
                </Row.Col>
              </Row>

              {isEditable && (
                <Row gap={1}>
                  <Row.Col>
                    <Button
                      name="SubmitInvitationDetails"
                      variant={ButtonVariant.contained}
                      color={ButtonColor.primary}
                      size={ButtonSize.medium}
                      isLoading={isSaving}
                      isDisabled={isSaving || Boolean(Object.keys(errors || {}).length)}
                      type={ButtonType.submit}
                    >
                      {isSaving ? 'Saving' : 'Save'}
                    </Button>
                  </Row.Col>
                  <Row.Col>
                    <Button
                      name="SubmitInvitationDetails"
                      variant={ButtonVariant.outline}
                      color={ButtonColor.primary}
                      size={ButtonSize.medium}
                      type={ButtonType.button}
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                  </Row.Col>
                </Row>
              )}
            </Row.Col>
            <Row.Col alignContent="center" size={0.5}>
              <FileInputField isDisabled={!isEditable} name="company.logo" title="Company Logo" />
            </Row.Col>
          </Row>
        </>
      </form>
    </FormProvider>
  );
};

CompanyDetailsForm.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    website: PropTypes.string,
    logo: PropTypes.string
  }).isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool
};

CompanyDetailsForm.defaultProps = {
  isSaving: false
};
export default CompanyDetailsForm;
