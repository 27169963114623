import React from 'react';
import { useSelector } from 'react-redux';
import Gap from 'src/components/atoms/Gap';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { selectors as propertySelectors } from 'src/features/property/propertySlice';
import DocumentLinkList from './components/DocumentLinkList';
import DocumentLinkPagination from './components/DocumentLinkPagination';

const DocumentLinkPortal: React.FC = () => {
  const getPropertiesCount = useSelector(propertySelectors.getAll.count);

  return (
    <>
      <Text variant={TextVariant.h3}>Welcome to the Snappt Portal!</Text>
      <Gap height={2.5625} />
      <DocumentLinkList />
      {getPropertiesCount > 5 && <DocumentLinkPagination />}
    </>
  );
};

export default DocumentLinkPortal;
