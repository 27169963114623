import styled from 'styled-components/macro';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  &:nth-child(even) {
    background: ${({ theme }) => theme.color.gray[50]};
  }
  &:nth-child(odd) {
    background: ${({ theme }) => theme.color.gray[100]};
  }
`;
