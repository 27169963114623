import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAppVersionResponse } from 'src/ts/interfaces';
import api from './api';
import { NAME } from './constants';

export const fetchAppVersion = createAsyncThunk(`${NAME}/fetchAppVersion`, async () => {
  try {
    return (await api.getAppVersion()) as IAppVersionResponse;
  } catch (error) {
    console.error(`${NAME}/fetchAppVersion`, error);
    throw new Error('Error fetching the version');
  }
});
