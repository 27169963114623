/* tslint:disable */
/* eslint-disable */
/**
 * Feature Flag API
 * Welcome to the Feature Flag API! API endpoints require a Snappt cookie or API Key passed.
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    error: string;
}
/**
 * The request payload or query string parameter you passed was not valid
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse400
     */
    error: string;
}
/**
 * The Authorization header or Snappt applicant session token you passed was not valid
 * @export
 * @interface InlineResponse401
 */
export interface InlineResponse401 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse401
     */
    error: string;
}
/**
 * The item you requested could not be found
 * @export
 * @interface InlineResponse404
 */
export interface InlineResponse404 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse404
     */
    error: string;
}

/**
 * FeatureFlagApi - axios parameter creator
 * @export
 */
export const FeatureFlagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve feature flag evaluation
         * @param {'fraud-platform' | 'enterprise-partner-api'} projectName 
         * @param {string} featureFlagKey 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureFlagProjectNameGet: async (projectName: 'fraud-platform' | 'enterprise-partner-api', featureFlagKey: string, filter?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('featureFlagProjectNameGet', 'projectName', projectName)
            // verify required parameter 'featureFlagKey' is not null or undefined
            assertParamExists('featureFlagProjectNameGet', 'featureFlagKey', featureFlagKey)
            const localVarPath = `/feature-flag/{projectName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (featureFlagKey !== undefined) {
                localVarQueryParameter['featureFlagKey'] = featureFlagKey;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve feature flag evaluation
         * @param {'fraud-platform' | 'enterprise-partner-api'} projectName 
         * @param {string} featureFlagKey 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unauthenticatedFeatureFlagProjectNameGet: async (projectName: 'fraud-platform' | 'enterprise-partner-api', featureFlagKey: string, filter?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('unauthenticatedFeatureFlagProjectNameGet', 'projectName', projectName)
            // verify required parameter 'featureFlagKey' is not null or undefined
            assertParamExists('unauthenticatedFeatureFlagProjectNameGet', 'featureFlagKey', featureFlagKey)
            const localVarPath = `/unauthenticated/feature-flag/{projectName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication snapptSessionAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-unauthenticated-session-token", configuration)

            if (featureFlagKey !== undefined) {
                localVarQueryParameter['featureFlagKey'] = featureFlagKey;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureFlagApi - functional programming interface
 * @export
 */
export const FeatureFlagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureFlagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve feature flag evaluation
         * @param {'fraud-platform' | 'enterprise-partner-api'} projectName 
         * @param {string} featureFlagKey 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featureFlagProjectNameGet(projectName: 'fraud-platform' | 'enterprise-partner-api', featureFlagKey: string, filter?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featureFlagProjectNameGet(projectName, featureFlagKey, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve feature flag evaluation
         * @param {'fraud-platform' | 'enterprise-partner-api'} projectName 
         * @param {string} featureFlagKey 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unauthenticatedFeatureFlagProjectNameGet(projectName: 'fraud-platform' | 'enterprise-partner-api', featureFlagKey: string, filter?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unauthenticatedFeatureFlagProjectNameGet(projectName, featureFlagKey, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeatureFlagApi - factory interface
 * @export
 */
export const FeatureFlagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureFlagApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve feature flag evaluation
         * @param {'fraud-platform' | 'enterprise-partner-api'} projectName 
         * @param {string} featureFlagKey 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featureFlagProjectNameGet(projectName: 'fraud-platform' | 'enterprise-partner-api', featureFlagKey: string, filter?: string, options?: any): AxiosPromise<void> {
            return localVarFp.featureFlagProjectNameGet(projectName, featureFlagKey, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve feature flag evaluation
         * @param {'fraud-platform' | 'enterprise-partner-api'} projectName 
         * @param {string} featureFlagKey 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unauthenticatedFeatureFlagProjectNameGet(projectName: 'fraud-platform' | 'enterprise-partner-api', featureFlagKey: string, filter?: string, options?: any): AxiosPromise<void> {
            return localVarFp.unauthenticatedFeatureFlagProjectNameGet(projectName, featureFlagKey, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureFlagApi - object-oriented interface
 * @export
 * @class FeatureFlagApi
 * @extends {BaseAPI}
 */
export class FeatureFlagApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve feature flag evaluation
     * @param {'fraud-platform' | 'enterprise-partner-api'} projectName 
     * @param {string} featureFlagKey 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagApi
     */
    public featureFlagProjectNameGet(projectName: 'fraud-platform' | 'enterprise-partner-api', featureFlagKey: string, filter?: string, options?: any) {
        return FeatureFlagApiFp(this.configuration).featureFlagProjectNameGet(projectName, featureFlagKey, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve feature flag evaluation
     * @param {'fraud-platform' | 'enterprise-partner-api'} projectName 
     * @param {string} featureFlagKey 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureFlagApi
     */
    public unauthenticatedFeatureFlagProjectNameGet(projectName: 'fraud-platform' | 'enterprise-partner-api', featureFlagKey: string, filter?: string, options?: any) {
        return FeatureFlagApiFp(this.configuration).unauthenticatedFeatureFlagProjectNameGet(projectName, featureFlagKey, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


