/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { resetPasswordThunk, updateMyUserThunk } from '../../services';
import { IUserRequest } from 'src/ts/interfaces';
import { actions as authActions, selectors as apiUserSelectors } from 'src/features/auth/authSlice';
import { actions as userActions } from 'src/features/user/userSlice';
import { selectors } from '../../index';
import { useEffect, useState } from 'react';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';

const useUser = () => {
  const dispatch = useAppDispatch();
  const apiUser = useSelector(apiUserSelectors.user);

  const [isToggleActive, setIsToggleActive] = useState(Boolean(apiUser?.settings_notification));
  const { user, status, passwordResetStatus } = useSelector(selectors);
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();

  const onUpdateMyUser = (payload: IUserRequest) => dispatch(updateMyUserThunk(payload));

  const onResetPassword = () => dispatch(resetPasswordThunk());

  useEffect(() => {
    if (status === StatusTypes.SUCCESS) {
      showSnackbar(VariantType.success, 'User profile updated successfully.', SnackTypes.none);
      dispatch(authActions.setCurrentUser(user));
      dispatch(userActions.resetInitialState());
    } else if (status === StatusTypes.ERROR) {
      showSnackbar(VariantType.error, 'Something went wrong.', SnackTypes.none);
      dispatch(userActions.resetInitialState());
      setTimeout(() => {
        setIsToggleActive(Boolean(apiUser?.settings_notification));
      }, 250);
    }
  }, [status, user, dispatch, apiUser, isToggleActive, setIsToggleActive]);

  useEffect(() => {
    if (passwordResetStatus === StatusTypes.SUCCESS) {
      showSnackbar(
        VariantType.success,
        'Check your email. We’ve sent you a link to change your password',
        SnackTypes.none
      );
      dispatch(userActions.resetPasswordState());
    } else if (passwordResetStatus === StatusTypes.ERROR) {
      showSnackbar(VariantType.error, 'Something went wrong.', SnackTypes.none);
      dispatch(userActions.resetPasswordState());
    }
  }, [passwordResetStatus]);

  return {
    isToggleActive,
    setIsToggleActive,
    onUpdateMyUser,
    onResetPassword
  };
};

export default useUser;
