import { settingApi } from 'src/api';
import { ISettingRequest } from './ts/interface';

const api = {
  getSetting(setting: string) {
    return settingApi.getSetting(setting);
  },
  updateSetting(setting: string, settingRequest: ISettingRequest) {
    return settingApi.updateSetting(setting, settingRequest);
  }
};

export default api;
