// Helpers
import { dateFormatWithHourAndTimeZone } from 'src/helpers';

// Components
import Button, { ButtonColor, ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';

// Types
import { IEntry } from 'src/ts/interfaces';

//Hooks
import useUserRoles from 'src/features/auth/hooks/useUserRoles';

export type ProofListFooterActionsProps = {
  entry?: Pick<IEntry, 'report_complete_time' | 'reviewer'>;
  isSendReportDisabled?: boolean;
  isRequestDocumentsDisabled?: boolean;
  onSendReport?: () => void;
  onRequestDocuments?: () => void;
  onEscalate?: () => void;
  showRequestDocumentsButton?: boolean;
  canEscalate?: boolean;
  isLoadingEscalate?: boolean;
  isDisabled?: boolean;
};

const API_KEY_USER_EMAIL = 'api_key_user@snappt.com';

const getReviewerName = (entry: Pick<IEntry, 'is_automatic_review' | 'reviewer'>) => {
  if (
    entry?.is_automatic_review &&
    (!entry?.reviewer || entry?.reviewer?.email === API_KEY_USER_EMAIL)
  ) {
    return 'Automated System Ruling';
  }

  if (!entry?.reviewer) {
    return 'Deleted User';
  }

  return `${entry.reviewer.first_name} ${entry.reviewer.last_name}`;
};

const ProofListFooterActions: React.FC<ProofListFooterActionsProps> = (
  props: ProofListFooterActionsProps
) => {
  const {
    entry,
    isSendReportDisabled = false,
    isRequestDocumentsDisabled = false,
    onSendReport,
    onRequestDocuments,
    showRequestDocumentsButton = true,
    onEscalate,
    canEscalate = false,
    isLoadingEscalate = false,
    isDisabled = false
  } = props;

  const { isAccountRepresentative } = useUserRoles();

  return (
    <Row justify="space-between" alignItems="center">
      <Row.Col>
        <Row gap={1}>
          {!isAccountRepresentative && (
            <Button
              name="button-send-report"
              onClick={onSendReport}
              size={ButtonSize.normal}
              color={ButtonColor.primary}
              variant={ButtonVariant.contained}
              isDisabled={isSendReportDisabled || isDisabled}
            >
              Send Report
            </Button>
          )}

          {canEscalate && onEscalate && (
            <Button
              name="button-escalate"
              onClick={onEscalate}
              size={ButtonSize.normal}
              variant={ButtonVariant.outline}
              color={ButtonColor.primary}
              isDisabled={isDisabled}
              isLoading={isLoadingEscalate}
            >
              Escalate
            </Button>
          )}
          {!isAccountRepresentative && showRequestDocumentsButton && onRequestDocuments && (
            <Button
              name="button-request-documents"
              onClick={onRequestDocuments}
              size={ButtonSize.normal}
              variant={ButtonVariant.outline}
              color={ButtonColor.primary}
              isDisabled={isRequestDocumentsDisabled || isDisabled}
            >
              Request Documents
            </Button>
          )}
        </Row>
      </Row.Col>
      {entry?.report_complete_time && (
        <Row.Col>
          <Row direction="column" justify="flex-end">
            <Row justify="flex-end" gap={0.5}>
              <Text color={TextColor.gray700} variant={TextVariant.small} isBold>
                Report Generated on:
              </Text>
              <Text color={TextColor.gray700} variant={TextVariant.small}>
                {dateFormatWithHourAndTimeZone(entry.report_complete_time)}
              </Text>
            </Row>
            <Row justify="flex-end" gap={0.5}>
              <Text color={TextColor.gray700} variant={TextVariant.small} isBold>
                Report Generated by:
              </Text>
              <Text color={TextColor.gray700} variant={TextVariant.small}>
                {getReviewerName(entry)}
              </Text>
            </Row>
          </Row>
        </Row.Col>
      )}
    </Row>
  );
};

export default ProofListFooterActions;
