// Vendor
import { ThemeAttributes } from 'src/ts/interfaces/styled-components';
import styled, { css } from 'styled-components';

// Enums
import { DupTypes } from 'src/features/DUP/ts/enums';

// Constants
import { Color } from 'src/pages/DUPPage/constants';

export const Container = styled.div``;

export const Loading = styled.div`
  div {
    margin-top: 2rem;
    position: relative;
  }
`;

const getErrorStyleBody = (theme: ThemeAttributes, hasError?: boolean) => {
  if (hasError) {
    return css`
      border: 2px solid ${theme.color.red[700]};
      padding: 1rem;
      svg {
        path {
          fill: ${theme.color.red[700]};
        }
      }
    `;
  }
  return css`
    border: 1px solid ${theme.color.gray[200]};
  `;
};

export const Body = styled.div<{ hasError?: boolean }>`
  height: 15rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.white};
  ${({ theme, hasError }) => getErrorStyleBody(theme, hasError)}
`;

export const Footer = styled.div`
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray[200]};
  border-bottom: none;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
`;

export const Buttons = styled.div<{ type: DupTypes }>`
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray[200]};
  border-top: none;
  padding: 1rem;
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  button {
    border: transparent;
    ${({ theme }) => theme.font.normal};
    color: ${({ type }) => Color[type].dark};
    :hover {
      background: ${({ type }) => Color[type].light};
      color: ${({ type }) => Color[type].dark};
    }
    &:disabled {
      background: transparent;
      border: transparent;
      color: ${({ theme }) => theme.color.gray[600]};
    }
  }

  .inputFile {
    width: 0.006rem;
    height: 0.006rem;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .inputFileButton {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
    border: transparent;
    justify-content: center;
    outline: none;
    cursor: pointer;
    p {
      color: ${({ type }) => Color[type].dark};
    }
  }
  .inputFile + label:hover {
    background: ${({ type }) => Color[type].light};
  }
  .inputFile:disabled + label:hover {
    background: transparent;
  }
  .inputFile:disabled + label {
    color: ${({ theme }) => theme.color.gray[600]};
    cursor: default;
    span,
    p {
      color: ${({ theme }) => theme.color.gray[600]};
    }
  }
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const SelectorContainer = styled.div`
  position: absolute;
  left: 0;
  max-height: 250px;
  overflow: auto;
  z-index: 10;
  width: max-content;
  border: 1px solid #e2e8f0;
`;

export const Selector = styled.div`
  position: relative;
  background: #fff;
  padding: 0.5rem;
  flex-direction: column;
`;

export const SelectorItem = styled.div`
  button {
    width: 100%;
  }
`;

export const EditButtonContainer = styled.div`
  position: relative;
`;
