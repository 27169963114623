import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { SubmissionsTab, SubmissionsTabLoader } from 'src/components/folders';
import { useEntriesByFolderId } from 'src/features/entries/hooks';
import usePropertyFeature from 'src/features/propertyFeature/hooks/usePropertyFeature';

export const SubmissionsTabContainer = ({ propertyId }: { propertyId?: string }) => {
  const { folderId } = useParams();
  const { entries, isLoading } = useEntriesByFolderId(folderId as string);

  const {
    onGetAll: onGetPropertyFeatures,
    onResetGetAll,
    propertyFeatureIsIdle
  } = usePropertyFeature();

  useEffect(() => {
    if (propertyId && propertyFeatureIsIdle) {
      onGetPropertyFeatures(propertyId);
    }
  }, [propertyId, propertyFeatureIsIdle, onGetPropertyFeatures]);

  useEffect(() => {
    return () => {
      // Resets the property features state when the component unmounts
      // This is necessary to avoid showing the previous property features when the user navigates to a different folder/property
      onResetGetAll();
    };
  }, [onResetGetAll]);

  if (isLoading) {
    return <SubmissionsTabLoader />;
  }

  return <SubmissionsTab entries={entries} />;
};
