import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'src/api/instance';
import getInfo from 'src/features/DUP/helpers/getInfo';
import { Status as StatusTypes } from 'src/ts/enums';
import { IRejectedAction, ISerializedError } from 'src/ts/interfaces';
import { DupTypes } from '../ts/enums';
import {
  createApplication,
  getCurrentApplication,
  getCurrentProperty,
  submitApplication,
  updateCurrentApplication
} from './services';
import { IApplication, ICreateApplicationResponse, IInformation, IProperty } from './ts/interfaces';

export type StateDataForm = {
  type: DupTypes;
  information: IInformation;
  process: {
    createApplication: {
      status: StatusTypes;
      error: string | null;
      data: ICreateApplicationResponse;
    };
    getCurrentApplication: {
      status: StatusTypes;
      error: string | null;
      data: IApplication;
    };
    getCurrentProperty: {
      status: StatusTypes;
      error: string | null;
      data: IProperty;
    };
    updateCurrentApplication: {
      status: StatusTypes;
      error: string | null;
      data: IApplication;
    };
    submitApplication: {
      status: StatusTypes;
      error: ISerializedError | AxiosError | string | null;
      data: IApplication;
    };
  };
};

export const initialStateForm: StateDataForm = {
  type: DupTypes.APPLICANT,
  information: getInfo(DupTypes.APPLICANT),
  process: {
    createApplication: {
      status: StatusTypes.IDLE,
      data: {},
      error: null
    },
    getCurrentApplication: {
      status: StatusTypes.IDLE,
      data: {},
      error: null
    },
    getCurrentProperty: {
      status: StatusTypes.IDLE,
      data: {},
      error: null
    },
    updateCurrentApplication: {
      status: StatusTypes.IDLE,
      data: {},
      error: null
    },
    submitApplication: {
      status: StatusTypes.IDLE,
      data: {},
      error: null
    }
  }
};

export const createApplicationReducer = (builder: ActionReducerMapBuilder<StateDataForm>) => {
  builder.addCase(createApplication.pending, (state: StateDataForm) => {
    state.process.createApplication.status = StatusTypes.LOADING;
    state.process.createApplication.error = null;
  });
  builder.addCase(createApplication.rejected, (state: StateDataForm, action: IRejectedAction) => {
    state.process.createApplication.status = StatusTypes.ERROR;
    state.process.createApplication.error = action.error.message || null;
  });
  builder.addCase(
    createApplication.fulfilled,
    (state: StateDataForm, action: PayloadAction<any>) => {
      state.process.createApplication.status = StatusTypes.SUCCESS;
      state.process.createApplication.data = action.payload.data;
    }
  );
};

export const getCurrentApplicationReducer = (builder: ActionReducerMapBuilder<StateDataForm>) => {
  builder.addCase(getCurrentApplication.pending, (state: StateDataForm) => {
    state.process.getCurrentApplication.status = StatusTypes.LOADING;
    state.process.getCurrentApplication.error = null;
  });
  builder.addCase(
    getCurrentApplication.rejected,
    (state: StateDataForm, action: IRejectedAction) => {
      state.process.getCurrentApplication.status = StatusTypes.ERROR;
      state.process.getCurrentApplication.error = action.error.message || null;
    }
  );
  builder.addCase(
    getCurrentApplication.fulfilled,
    (state: StateDataForm, action: PayloadAction<any>) => {
      state.process.getCurrentApplication.status = StatusTypes.SUCCESS;
      state.process.getCurrentApplication.data = action.payload.data;
    }
  );
};

export const getCurrentPropertyReducer = (builder: ActionReducerMapBuilder<StateDataForm>) => {
  builder.addCase(getCurrentProperty.pending, (state: StateDataForm) => {
    state.process.getCurrentProperty.status = StatusTypes.LOADING;
    state.process.getCurrentProperty.error = null;
  });
  builder.addCase(getCurrentProperty.rejected, (state: StateDataForm, action: IRejectedAction) => {
    state.process.getCurrentProperty.status = StatusTypes.ERROR;
    state.process.getCurrentProperty.error = action.error.message || null;
  });
  builder.addCase(
    getCurrentProperty.fulfilled,
    (state: StateDataForm, action: PayloadAction<any>) => {
      state.process.getCurrentProperty.status = StatusTypes.SUCCESS;
      state.process.getCurrentProperty.data = action.payload.data;
    }
  );
};

export const updateApplicationReducer = (builder: ActionReducerMapBuilder<StateDataForm>) => {
  builder.addCase(updateCurrentApplication.pending, (state: StateDataForm) => {
    state.process.updateCurrentApplication.status = StatusTypes.LOADING;
    state.process.updateCurrentApplication.error = null;
  });
  builder.addCase(
    updateCurrentApplication.rejected,
    (state: StateDataForm, action: IRejectedAction) => {
      state.process.updateCurrentApplication.status = StatusTypes.ERROR;
      state.process.updateCurrentApplication.error = action.error.message || null;
    }
  );
  builder.addCase(updateCurrentApplication.fulfilled, (state: StateDataForm) => {
    state.process.updateCurrentApplication.status = StatusTypes.SUCCESS;
  });
};

export const submitApplicationReducer = (builder: ActionReducerMapBuilder<StateDataForm>) => {
  builder.addCase(submitApplication.pending, (state: StateDataForm) => {
    state.process.submitApplication.status = StatusTypes.LOADING;
    state.process.submitApplication.error = null;
  });
  builder.addCase(submitApplication.rejected, (state: StateDataForm, action: IRejectedAction) => {
    const error = (action.payload as AxiosError).response?.data;
    const errorForm = error.errors?.application ? 'Something is missing in the Form' : null;

    state.process.submitApplication.status = StatusTypes.ERROR;
    state.process.submitApplication.error = errorForm || error.errors?.proofs || null;
  });
  builder.addCase(submitApplication.fulfilled, (state: StateDataForm) => {
    state.process.submitApplication.status = StatusTypes.SUCCESS;
  });
};
