import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ButtonColor, ButtonVariant } from 'src/components/atoms/Button';
import { Row } from 'src/components/atoms/Row';
import { TextColor, TextVariant } from 'src/components/atoms/Text';
import { useFolderUpdateName } from 'src/features/folders/hooks';
import { letterMatchingRegex } from 'src/helpers/formRegex';
import {
  CompanyIcon,
  EditIcon,
  FolderIcon,
  StyledButton,
  StyledIconButtonBlue,
  StyledIconButtonRed,
  StyledInputText,
  StyledRow,
  StyledText
} from '../styles';

interface FolderDetailsProps {
  id?: string;
  name?: string;
  propertyName?: string;
}

const FolderDetails = ({ id, name, propertyName }: FolderDetailsProps): JSX.Element => {
  const { onUpdateName } = useFolderUpdateName();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const formProps = useForm({
    mode: 'onChange',
    defaultValues: {
      folderName: name
    }
  });

  const { handleSubmit, watch, setValue } = formProps;
  const isSubmitDisabled = name === watch('folderName') || !watch('folderName');

  const onSubmit = useCallback(
    ({ folderName }: any) => {
      setIsEditMode(false);
      onUpdateName({ id: id as string, name: folderName });
    },
    [id, onUpdateName]
  );

  const onCancell = useCallback(() => {
    setValue('folderName', name);
    setIsEditMode(false);
  }, [name, setValue]);

  return (
    <FormProvider {...formProps}>
      <StyledRow alignItems="center">
        <FolderIcon />
        {isEditMode ? (
          <>
            <StyledInputText
              name="folderName"
              placeholder="Folder Name"
              config={{
                required: 'Complete this field',
                maxLength: {
                  value: 50,
                  message: '50 Characters Maximum'
                },
                validate: {
                  noSpecialCharacters: (value) =>
                    letterMatchingRegex.test(value) || 'Should not contain only numbers and symbols'
                }
              }}
            />
            <StyledIconButtonBlue
              name="confirm-folder-name"
              background="none"
              icon="check"
              iconColor={isSubmitDisabled ? 'gray600' : 'blue700'}
              disabled={isSubmitDisabled}
              onClick={handleSubmit(onSubmit)}
              isCircle={false}
            />
            <StyledIconButtonRed
              name="cancel-folder-name"
              background="none"
              icon="delete"
              iconColor="red"
              disabled={false}
              isCircle={false}
              onClick={onCancell}
            />
          </>
        ) : (
          <>
            <StyledText variant={TextVariant.span} color={TextColor.gray700}>
              {name}
            </StyledText>
            <StyledButton
              onClick={() => setIsEditMode(true)}
              name="Edit"
              variant={ButtonVariant.outline}
              color={ButtonColor.disabled}
            >
              <EditIcon />
            </StyledButton>
          </>
        )}
      </StyledRow>
      <Row>
        <CompanyIcon />
        <StyledText variant={TextVariant.span} color={TextColor.gray700}>
          {propertyName}
        </StyledText>
      </Row>
    </FormProvider>
  );
};

export default FolderDetails;
