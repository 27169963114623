import PropTypes from 'prop-types';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import SkeletonPiece, { SkeletonPieceColor } from 'src/components/atoms/SkeletonPiece';
import { Container, Content, Thumbnail } from './styles';

export type LoaderCardSelectDocProps = {
  rows?: number;
};

const LoaderCardSelectDoc: React.FC<LoaderCardSelectDocProps> = (props) => {
  const { rows } = props;
  return (
    <Row gap={1.5} wrap={'wrap'} justify="flex-start" data-testid="LoaderCardSelectDoc">
      {Array(rows)
        .fill(0)
        .map((_, i) => {
          return (
            <Container key={i}>
              <Content>
                <Row justify="space-between">
                  <SkeletonPiece width={98} height={24} color={SkeletonPieceColor.gray200} />
                  <SkeletonPiece width={24} height={24} color={SkeletonPieceColor.gray200} />
                </Row>
                <Row justify="center">
                  <Thumbnail>
                    <Icon icon={'notPreviewDocument'} />
                  </Thumbnail>
                </Row>
                <Row gap={1.5}>
                  <SkeletonPiece width={24} height={24} color={SkeletonPieceColor.gray200} />
                  <SkeletonPiece width={24} height={24} color={SkeletonPieceColor.gray200} />
                  <SkeletonPiece width={24} height={24} color={SkeletonPieceColor.gray200} />
                  <SkeletonPiece width={24} height={24} color={SkeletonPieceColor.gray200} />
                </Row>
              </Content>
            </Container>
          );
        })}
    </Row>
  );
};

LoaderCardSelectDoc.propTypes = {
  rows: PropTypes.number
};

LoaderCardSelectDoc.defaultProps = {
  rows: 4
};

export default LoaderCardSelectDoc;
