import { useState } from 'react';
import Button, { ButtonDisabledStyle, ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { ToolTip } from 'src/components/atoms/ToolTip';
import { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import NotesModal, { IFormValues } from './NotesModal';

type ButtonAllNotesModalProps = {
  isDisabled: boolean;
  onSaveAllNotes: (content: IFormValues) => void;
  defaultNote: string;
};

const ButtonAllNotesModal: React.FC<ButtonAllNotesModalProps> = ({
  isDisabled,
  onSaveAllNotes,
  defaultNote
}: ButtonAllNotesModalProps) => {
  const [showModalNotes, setShowModalNotes] = useState(false);

  return (
    <>
      <ToolTip direction={ToolTipDirection.top} content="Public Application Notes">
        <Button
          name="notes_button"
          variant={ButtonVariant.ghost}
          onClick={() => setShowModalNotes(true)}
          isDisabled={isDisabled}
          disabledStyle={ButtonDisabledStyle.transparent}
        >
          <Icon icon="cardSelectDoc_note" />
        </Button>
      </ToolTip>
      {showModalNotes && (
        <NotesModal
          title="Application Report Notes"
          showModal={showModalNotes}
          setShowModal={setShowModalNotes}
          onSaveNote={onSaveAllNotes}
          defaultNote={defaultNote}
        />
      )}
    </>
  );
};
export default ButtonAllNotesModal;
