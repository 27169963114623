import { createAsyncThunk } from '@reduxjs/toolkit';

import { getPaginationRequest } from 'src/ts/interfaces';
import api from './api';
import { NAME } from './constants';

export const fdeInboxGetAllThunk = createAsyncThunk(
  `${NAME}/getAll`,
  async (payload: getPaginationRequest) => {
    try {
      return api.getAll(payload);
    } catch (error) {
      console.error(`${NAME}/getAll`, error);
      throw new Error('Error fetching fde inboxes');
    }
  }
);

// Todo: Need to make this call epa to retrieve results
export const fdeInboxIdentityVerificationResultsGetAllThunk = createAsyncThunk(
  `${NAME}/getAllIdentityVerificationResults`,
  async (payload: getPaginationRequest) => {
    try {
      return api.getAll(payload);
    } catch (error) {
      console.error(`${NAME}/getAll`, error);
      throw new Error('Error fetching fde inboxes');
    }
  }
);
