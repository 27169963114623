// Vendor
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import CompanyDetails from './components/CompanyDetails';

// Hooks
import useCompany from 'src/features/company/hooks/useCompany';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';

// Redux
import { selectors as companySelector } from 'src/features/company/companySlice';

const CompanyPage = (): JSX.Element => {
  const { onGetOne, onGetOneById, onSave, onResetUpdate } = useCompany();
  const { showSnackbar, VariantType } = useSnackbarProvider();
  const { companyId } = useParams();

  const companyData = useSelector(companySelector.getOne.data);
  const companyIsLoading = useSelector(companySelector.getOne.isLoading);
  const companyIsIdle = useSelector(companySelector.getOne.isIdle);

  const companyUpdatedIsSaved = useSelector(companySelector.update.isSaved);
  const companyUpdatedIsLoading = useSelector(companySelector.update.isLoading);
  const companyUpdatedError = useSelector(companySelector.update.error);

  useEffect(() => {
    if (companyId) {
      onGetOneById(companyId);
    } else {
      onGetOne();
    }
  }, [onGetOne, onGetOneById, companyId]);

  useEffect(() => {
    if (companyUpdatedIsSaved) {
      onResetUpdate();
      showSnackbar(VariantType.success, 'The information has been edited successfully');
    }
    if (companyUpdatedError) {
      onResetUpdate();
      showSnackbar(VariantType.error, 'An error has ocurred, please refresh and try again');
    }
  }, [
    companyUpdatedIsSaved,
    companyUpdatedError,
    VariantType.success,
    VariantType.error,
    onResetUpdate,
    showSnackbar
  ]);

  return (
    <CompanyDetails
      company={companyData}
      isLoading={companyIsLoading || companyIsIdle}
      isSaving={companyUpdatedIsLoading}
      onSave={onSave}
    />
  );
};

export default CompanyPage;
