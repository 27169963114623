// Vendor
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

// Enums
import { Status as StatusEnum } from 'src/ts/enums';

// Types
import { IRejectedAction, StatusType } from 'src/ts/interfaces';
import { AnnouncementResponseType, AnnouncementType } from 'src/ts/interfaces/announcement';

// Redux
import { getLastThunk, markAsShownThunk } from './services';

export type StateData = {
  getLast: {
    status: StatusType;
    error?: string | null;
    data?: AnnouncementType[] | null;
  };
  markAsShown: {
    status: StatusType;
    error?: string | null;
  };
};

export const initialState: StateData = {
  getLast: {
    status: StatusEnum.IDLE,
    data: null,
    error: null
  },
  markAsShown: {
    status: StatusEnum.IDLE,
    error: null
  }
};

export const getLastReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getLastThunk.pending, (state: StateData) => {
    state.getLast.status = StatusEnum.LOADING;
    state.getLast.error = null;
  });
  builder.addCase(getLastThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.getLast.status = StatusEnum.ERROR;
    state.getLast.error = action.error.message;
  });
  builder.addCase(
    getLastThunk.fulfilled,
    (state: StateData, action: PayloadAction<AxiosResponse>) => {
      state.getLast.status = StatusEnum.SUCCESS;
      state.getLast.data = action.payload.data?.map((item: AnnouncementResponseType) => {
        const {
          id,
          title,
          description,
          close_button_label,
          confirm_button_label,
          image_url: imageUrl,
          redirect_to: goToConfirm,
          is_active: isActive
        } = item;
        return {
          id,
          title,
          description,
          labelButtonClose: close_button_label,
          labelButtonConfirm: confirm_button_label,
          imageUrl,
          goToConfirm,
          isActive
        };
      });
    }
  );
};

export const markAsShownReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(markAsShownThunk.pending, (state: StateData) => {
    state.markAsShown.status = StatusEnum.LOADING;
    state.markAsShown.error = null;
  });
  builder.addCase(markAsShownThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.markAsShown.status = StatusEnum.ERROR;
    state.markAsShown.error = action.error.message;
  });
  builder.addCase(markAsShownThunk.fulfilled, (state: StateData) => {
    state.markAsShown.status = StatusEnum.SUCCESS;
    state.markAsShown.error = null;
  });
};
