// Vendor
import styled from 'styled-components';

// Enums
import { DupTypes } from 'src/features/DUP/ts/enums';

// Constants
import { Color } from 'src/pages/DUPPage/constants';

export const Container = styled.div<{ type: DupTypes }>`
  background: ${({ type }) => Color[type].content};
`;

export const Content = styled.div`
  padding: 3rem;
  max-width: 1280px;
  margin: 0 auto;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.5rem;
  }
`;
