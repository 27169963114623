import PropTypes from 'prop-types';
import Step, { StepProps, StepState } from 'src/components/atoms/Step/Step';
import { StepsFlowContainer } from './styles';

export type StepsFlowProps = {
  steps: StepProps[];
  onClickStep?: (step: StepProps) => void;
};

const StepsFlow: React.FC<StepsFlowProps> = (props) => {
  const { steps, onClickStep } = props;

  return (
    <StepsFlowContainer>
      {steps.map((step, idx) => {
        const cls = () => {
          if (
            idx > 0 &&
            step.state === StepState.disabled &&
            [StepState.completed].includes(steps[idx - 1].state)
          ) {
            return 'Step__container_disabled_active';
          }
        };

        return (
          <Step
            className={cls()}
            key={`StepsFlow__${idx}`}
            {...step}
            onClick={() => {
              if (onClickStep) {
                onClickStep(step);
              } else {
                return null;
              }
            }}
          />
        );
      })}
    </StepsFlowContainer>
  );
};

StepsFlow.propTypes = {
  steps: PropTypes.arrayOf<StepProps>(
    PropTypes.shape({
      state: PropTypes.oneOf<StepState>(Object.values(StepState)).isRequired,
      label: PropTypes.string.isRequired,
      helpText: PropTypes.string
    }).isRequired
  ).isRequired,
  onClickStep: PropTypes.func
};

export default StepsFlow;
