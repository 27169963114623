import { useCallback } from 'react';
import { useAppDispatch } from 'src/store';
import { getSetting, updateSetting } from '../services';
import { settingPayload } from '.././ts/interface';

const useSetting = () => {
  const dispatch = useAppDispatch();

  const onGetSetting = useCallback(
    (setting: string) => {
      dispatch(getSetting(setting));
    },
    [dispatch]
  );

  const onUpdateSetting = useCallback(
    (payload: settingPayload) => {
      dispatch(updateSetting(payload));
    },
    [dispatch]
  );

  return {
    onGetSetting,
    onUpdateSetting
  };
};

export default useSetting;
