// Vendor
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// Components
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Text, { TextAlign, TextColor, TextVariant } from 'src/components/atoms/Text';
import { ButtonGroup } from 'src/components/molecules/ButtonGroup';
import { Container, IconBorder } from './styles';

// Constants
import { FILES_TO_UPLOAD } from 'src/features/DUP/proofs/constants';

// Hooks
import useLanguage from 'src/context/Language/useLanguage';
import useProofs from 'src/features/DUP/proofs/hooks/useProofs';

// Helpers
import { getOldDocumentTypes } from 'src/features/DUP/helpers/getDocumentTypes';

// Enums
import { DupTypes } from 'src/features/DUP/ts/enums';

import { UnauthenticateSessionProofType } from 'src/features/DUP/proofs/ts/interfaces';

export type UploadFileProps = {
  type: DupTypes;
  isCell?: boolean;
};

const UploadFile: React.FC<UploadFileProps> = ({
  isCell = false,
  type = DupTypes.APPLICANT
}: UploadFileProps) => {
  const { translate: t } = useLanguage();
  const { onUploadFile, setDocumentTypeSelected } = useProofs();

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [keyFile, setKeyFile] = useState(Date.now());

  const handleUploadFiles = (files: File[]) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length <= FILES_TO_UPLOAD.MAX_FILES) setFileLimit(true);
        if (uploaded.length > FILES_TO_UPLOAD.MAX_FILES) {
          alert(t('dup_proof_max_files', FILES_TO_UPLOAD.MAX_FILES.toString()));
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  useEffect(() => {
    if (fileLimit) {
      uploadedFiles.forEach((file) => {
        onUploadFile(file);
      });
      setUploadedFiles([]);
      setFileLimit(false);
      setKeyFile(Date.now());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileLimit]);

  const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const TextBox = () => {
    if (isCell) {
      return (
        <Row>
          <Text color={TextColor.initial} variant={TextVariant.h3} isBold align={TextAlign.center}>
            {t('dup_proof_another_file_title')}
          </Text>
        </Row>
      );
    }

    return (
      <>
        <Row>
          <Row.Col size={1} alignContent="center">
            <IconBorder type={type}>
              <Icon icon="icon-folder-open" />
            </IconBorder>
          </Row.Col>
        </Row>
        <Row>
          <Row.Col size={1}>
            <Gap height={1} />
            <Text
              color={TextColor.initial}
              variant={TextVariant.h3}
              isBold
              align={TextAlign.center}
            >
              {t('dup_proof_no_files_yet')}
            </Text>
            <Gap height={1} />
            <Text color={TextColor.initial} align={TextAlign.center}>
              {t('dup_proof_upload', FILES_TO_UPLOAD.ALLOW_FORMAT)}
            </Text>
          </Row.Col>
        </Row>
      </>
    );
  };

  return (
    <Row justify="center">
      <Container type={type} data-type={type} isCell={isCell}>
        <TextBox />
        <Gap height={2} />
        <Row className="SelectFile">
          <Row.Col size={1} alignSelf="flex-end">
            <ButtonGroup
              label={t('dup_proof_button_select_doc_type')}
              buttons={getOldDocumentTypes(t)}
              onClick={({ value }) =>
                setDocumentTypeSelected(value as UnauthenticateSessionProofType)
              }
            />
          </Row.Col>
          <Row.Col size={1} alignSelf="flex-end">
            <input
              type="file"
              name="file"
              id="file"
              data-testid="upload_button"
              className="inputFile"
              accept={FILES_TO_UPLOAD.ALLOW_FORMAT}
              onChange={handleFileEvent}
              multiple
              key={keyFile}
            />
            <label htmlFor="file" className="inputFileButton">
              <Text color={TextColor.primary}>{t('dup_proof_button_upload')} </Text>
              <Icon icon="icon-cloud-upload" data-testid="upload-icon" />
            </label>
          </Row.Col>
        </Row>
      </Container>
    </Row>
  );
};

UploadFile.propTypes = {
  type: PropTypes.oneOf<DupTypes>(Object.values(DupTypes)).isRequired,
  isCell: PropTypes.bool
};

export default UploadFile;
