import { useCallback, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Loader } from 'src/components/atoms/Loader';
import { Carousel } from 'src/components/molecules/Carousel';
import { useEntryById } from 'src/features/entries/hooks';
import { useDisputeDocuments } from 'src/features/incomeVerification/hooks';
import { useProofsFilteredByEntryId } from 'src/features/proofs/hooks';
import IncomeVerificationForm from 'src/pages/IncomeVerificationFlowPage/components/IncomeVerificationForm/IncomeVerificationForm';
import { IncomeVerificationEnum } from 'src/ts/enums/session';
import { IProof } from 'src/ts/interfaces';
import { IncomeVerification } from 'src/ts/interfaces/incomeVerification';

export const eligibleDocuments = [
  'PAYSTUB',
  'CASH_APP_STATEMENT',
  'DEPARTMENT_OF_VETERANS_AFFAIRS_BENEFIT_LETTER',
  'SOCIAL_SECURITY_BENEFITS_LETTER',
  'SOCIAL_SECURITY_STATEMENT'
];

function savePreviousDocumentsToSession(
  documents: IncomeVerification[],
  incomeVerificationReviewId: string
) {
  window.sessionStorage.setItem(
    `${IncomeVerificationEnum.INCOME_VERIFICATION_DOCUMENTS}${incomeVerificationReviewId}`,
    JSON.stringify(documents)
  );
}
/**
 * Makes sure that the documents have data needed from the proofs to render the preview correctly
 *
 * @param proofs
 * @param documents
 */
function mergeProofsAndDocuments(
  proofs: IProof[],
  documents: IncomeVerification[]
): IncomeVerification[] {
  const proofsAndDocuments = proofs.reduce((acc: IncomeVerification[], proof: IProof) => {
    const document = documents.find((doc: IncomeVerification) => doc.id === proof.id);
    if (document) {
      acc.push({
        ...document,
        docId: proof.short_id || '',
        file: proof.file || '',
        fileName: proof.fileName || '',
        type: proof.type
      });
    }
    return acc;
  }, []);
  return proofsAndDocuments;
}

const IncomeVerificationFlowPage = () => {
  const { entryId, incomeVerificationReviewId } = useParams();
  const [searchParams] = useSearchParams();
  const documentsFromSession = window.sessionStorage.getItem(
    `${IncomeVerificationEnum.INCOME_VERIFICATION_DOCUMENTS}${incomeVerificationReviewId}`
  );

  // When the "Disputes" workflow is triggered, the redirectPath is provided to return the user to folder page
  const redirectPath = searchParams.get('redirectPath');
  useEntryById(entryId ?? '');
  const { proofs, isLoading: isLoadingProofs } = useProofsFilteredByEntryId(entryId ?? '', true);
  const [documents, isLoadingAppeal] = useDisputeDocuments({
    entryId: entryId ?? '',
    // If the redirectPath is provided, this indicates that the documents should be fetched because we are in the disputes workflow
    shouldFetchDocuments: Boolean(redirectPath) && !documentsFromSession
  });

  const isLoading = isLoadingAppeal || isLoadingProofs;
  useEffect(() => {
    if (documents && documents.length > 0 && incomeVerificationReviewId && proofs.length > 0) {
      // Make sure that essential data from the proofs is present in the documents object so that the preview renders correctly
      const proofsInDocuments = mergeProofsAndDocuments(proofs, documents);
      savePreviousDocumentsToSession(proofsInDocuments, incomeVerificationReviewId);
    }
  }, [documents, incomeVerificationReviewId, proofs]);

  const route = `/my-work/calculateIncome/${incomeVerificationReviewId}/${entryId}${
    redirectPath ? `?redirectPath=${redirectPath}` : ''
  }`;
  const getInitialDocuments = () => {
    const documents: IncomeVerification[] = [];

    proofs.forEach((proof, index) => {
      if (proof.short_id) {
        documents.push({
          index: index,
          id: proof.id,
          docId: proof.short_id,
          file: proof.file || '',
          fileName: proof.fileName || '',
          payPeriodStartDate: '',
          payPeriodEndDate: '',
          payPeriodDateRange: '',
          recipientName: '',
          grossPay: '',
          incomeSource: '',
          type: proof.type,
          originalType: proof.type
        });
      }
    });
    return documents;
  };

  const displayLastPageFromSession = window.sessionStorage.getItem(
    `${IncomeVerificationEnum.INCOME_VERIFICATION_DOCUMENTS_DISPLAY_LAST_PAGE}${incomeVerificationReviewId}`
  );

  const displayLastPage =
    displayLastPageFromSession !== null ? displayLastPageFromSession === 'true' : false;
  const incomeVerificationDocuments: IncomeVerification[] =
    documentsFromSession === null ? getInitialDocuments() : JSON.parse(documentsFromSession);

  if (proofs.length > 0) {
    window.sessionStorage.setItem(
      `${IncomeVerificationEnum.INCOME_VERIFICATION_DOCUMENTS}${incomeVerificationReviewId}`,
      JSON.stringify(incomeVerificationDocuments)
    );
  }

  const handleOnSave = useCallback(
    (data: IncomeVerification) => {
      incomeVerificationDocuments[data.index] = {
        ...data,
        payPeriodDateRange:
          data.payPeriodStartDate !== '' || data.payPeriodEndDate !== ''
            ? `${data.payPeriodStartDate} - ${data.payPeriodEndDate}`
            : ''
      };

      window.sessionStorage.setItem(
        `${IncomeVerificationEnum.INCOME_VERIFICATION_DOCUMENTS}${incomeVerificationReviewId}`,
        JSON.stringify(incomeVerificationDocuments)
      );
    },
    [incomeVerificationDocuments, incomeVerificationReviewId]
  );

  const previousClicked = (currentSlide: number) => {
    window.sessionStorage.setItem(
      `${IncomeVerificationEnum.INCOME_VERIFICATION_DOCUMENTS_DISPLAY_LAST_PAGE}${incomeVerificationReviewId}`,
      'false'
    );
  };
  const nextClicked = (currentSlide: number) => {
    window.sessionStorage.setItem(
      `${IncomeVerificationEnum.INCOME_VERIFICATION_DOCUMENTS_DISPLAY_LAST_PAGE}${incomeVerificationReviewId}`,
      'false'
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Carousel
      routeOnLastPage={true}
      route={route}
      displayLastPageOnLoad={displayLastPage}
      onPreviousClicked={previousClicked}
      onNextClicked={nextClicked}
    >
      {incomeVerificationDocuments.map((document) => {
        return (
          <IncomeVerificationForm
            key={document.docId}
            currentDocument={document}
            totalNumberOfDocuments={incomeVerificationDocuments.length}
            onSave={handleOnSave}
          ></IncomeVerificationForm>
        );
      })}
    </Carousel>
  );
};

export default IncomeVerificationFlowPage;
