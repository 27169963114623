export const websiteRegex =
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%.+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
export const onlyNumbersRegex = /^[0-9]+$/;
export const onlyCapsRegex = /^[A-Z]+$/;
export const noWhiteSpacesRegex = /^[^\s]+(\s+[^\s]+)*$/;
export const noSpecialCharactersRegex = /^[a-zA-Z0-9# ]*$/;
export const onlySpecialCharactersRegex = /^[a-zA-Z0-9]+/;
export const onlySpecialCharactersAndNumbersRegex = /^[a-zA-Z]+/;
export const letterMatchingRegex = /[a-zA-Z]+/;
export const onlySomeSpecialCharactersRegex = /[!"^§£$[\]/\\*#=@,;:><+$~?¿|%&/)(_]+/gm;
export const usZipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
export const onlyEmailRegex = /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@([A-Z0-9-]+\.)+[A-Z]{2,}$/i;
export const onlyFormatDateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
export const hasSpacesBeginAndEnd = /^\s+|\s+$/gim;
export const hasManyEmptySpaces = /\s{2,}/gm;
export const usPhoneRegex = /^(\(\s?[0-9]+\s?\)|[0-9]+)\s?[0-9]+\s?-?\s?[0-9]+$/;
export const phoneSpecialCharacters = /[\s\-()]/g;
