import { IEnvironment } from 'src/ts/interfaces';

export const envVars: IEnvironment = {
  production: {
    env: 'production',
    data_dog: {
      token: 'pubb6f5fd39e674dc4d3fbc0524e3b83d6d',
      url: 'https://logs.browser-intake-datadoghq.com/api'
    },
    server_url: 'https://fraud-api.snappt.com',
    document_portal: 'https://documentportal.info',
    frontend_url: 'https://fraud.snappt.com',
    api_url: 'https://fraud-api.snappt.com/v2',
    epa_url: 'https://enterprise-api.snappt.com',
    feature_flag_api_url: 'https://feature-flag-api.snappt.com',
    bucket_url: 'https://fraud-images-production.s3.us-west-2.amazonaws.com',
    metabase: {
      url: 'https://snappt.metabaseapp.com',
      dashboards: {
        my_work_performance: {
          id: 101,
          report_type: 'my_work_performance'
        },
        team_performance: {
          id: 140,
          report_type: 'team_performance'
        },
        reporting_tab: {
          id: 85,
          report_type: 'reporting_tab'
        },
        reporting_sla: {
          id: 158,
          report_type: 'reporting_sla'
        }
      }
    },
    adobe: {
      pdf_token: '1d3acc97f42347228047f8a47ef00726'
    },
    heap: {
      app_id: '2637664708'
    },
    userflow: {
      token: 'ct_zzjrd4mv2jc4dlijmmezhtgium'
    }
  },
  development: {
    env: 'development',
    data_dog: {
      token: 'pub1352fde5b374961a984cb9b33a60f641',
      url: 'https://logs.browser-intake-datadoghq.com/api'
    },
    server_url: 'http://localhost:4000',
    document_portal: 'https://documentportal.info',
    frontend_url: 'http://localhost:3000',
    api_url: 'http://localhost:4000/v2',
    epa_url: 'http://localhost:3500',
    feature_flag_api_url: 'http://localhost:3600',
    bucket_url: 'http://localstack:4566/fraud-images-dev',
    metabase: {
      url: 'https://snappt.metabaseapp.com',
      dashboards: {
        my_work_performance: {
          id: 109,
          report_type: 'my_work_performance'
        },
        team_performance: {
          id: 127,
          report_type: 'team_performance'
        },
        reporting_tab: {
          id: 112,
          report_type: 'reporting_tab'
        },
        reporting_sla: {
          id: 156,
          report_type: 'reporting_sla'
        }
      }
    },
    adobe: {
      pdf_token: 'a2bf3267699b4cb89d6b9166846ff2ff'
    },
    heap: {
      app_id: '2509813560'
    },
    userflow: {
      token: 'ct_wrf4dht3fje3li25xomm6ss26e'
    }
  },
  wintheknifefightDev: {
    env: 'development',
    data_dog: {
      token: 'pub1352fde5b374961a984cb9b33a60f641',
      url: 'https://logs.browser-intake-datadoghq.com/api'
    },
    server_url: 'http://wintheknifefight:4000',
    document_portal: 'https://documentportal.info',
    frontend_url: 'http://wintheknifefight:3000',
    api_url: 'http://wintheknifefight:4000/v2',
    epa_url: 'http://localhost:3500',
    feature_flag_api_url: 'http://localhost:3600',
    bucket_url: 'http://localstack:4566/fraud-images-dev',
    metabase: {
      url: 'https://snappt.metabaseapp.com',
      dashboards: {
        my_work_performance: {
          id: 109,
          report_type: 'my_work_performance'
        },
        team_performance: {
          id: 127,
          report_type: 'team_performance'
        },
        reporting_tab: {
          id: 112,
          report_type: 'reporting_tab'
        },
        reporting_sla: {
          id: 156,
          report_type: 'reporting_sla'
        }
      }
    },
    adobe: {
      pdf_token: 'a2bf3267699b4cb89d6b9166846ff2ff'
    },
    heap: {
      app_id: '2509813560'
    },
    userflow: {
      token: 'ct_wrf4dht3fje3li25xomm6ss26e'
    }
  },
  ralph: {
    env: 'ralph',
    data_dog: {
      token: 'pubd9703d48afec87ab3b6f41172798069f',
      url: 'https://logs.browser-intake-datadoghq.com/api'
    },
    server_url: 'https://fraud-api-ralph.snappt.com',
    document_portal: 'https://ralph.documentportal.info',
    frontend_url: 'https://fraud-ralph.snappt.com',
    api_url: 'https://fraud-api-ralph.snappt.com/v2',
    epa_url: 'https://ralph-enterprise-api.snappt.com',
    feature_flag_api_url: 'https://feature-flag-api-ralph.snappt.com',
    bucket_url: 'https://fraud-images-ralph.s3.us-west-2.amazonaws.com',
    metabase: {
      url: 'https://snappt.metabaseapp.com',
      dashboards: {
        my_work_performance: {
          id: 109,
          report_type: 'my_work_performance'
        },
        team_performance: {
          id: 127,
          report_type: 'team_performance'
        },
        reporting_tab: {
          id: 112,
          report_type: 'reporting_tab'
        },
        reporting_sla: {
          id: 156,
          report_type: 'reporting_sla'
        }
      }
    },
    adobe: {
      pdf_token: '1d3acc97f42347228047f8a47ef00726'
    },
    heap: {
      app_id: '2509813560'
    },
    userflow: {
      token: 'ct_wrf4dht3fje3li25xomm6ss26e'
    }
  },
  demo: {
    env: 'demo',
    data_dog: {
      token: 'pubf32334de813e2f15f84246421efcc923',
      url: 'https://logs.browser-intake-datadoghq.com/api'
    },
    server_url: 'https://fraud-api-demo.snappt.com',
    document_portal: 'https://demo.documentportal.info',
    frontend_url: 'https://fraud-demo.snappt.com',
    api_url: 'https://fraud-api-demo.snappt.com/v2',
    epa_url: 'https://demo-enterprise-api.snappt.com',
    feature_flag_api_url: 'https://feature-flag-api-demo.snappt.com',
    bucket_url: 'https://fraud-images-demo.s3.us-west-2.amazonaws.com',
    metabase: {
      url: 'https://snappt.metabaseapp.com',
      dashboards: {
        my_work_performance: {
          id: 109,
          report_type: 'my_work_performance'
        },
        team_performance: {
          id: 127,
          report_type: 'team_performance'
        },
        reporting_tab: {
          id: 112,
          report_type: 'reporting_tab'
        },
        reporting_sla: {
          id: 156,
          report_type: 'reporting_sla'
        }
      }
    },
    adobe: {
      pdf_token: '1d3acc97f42347228047f8a47ef00726'
    },
    heap: {
      app_id: '2509813560'
    },
    userflow: {
      token: 'ct_wrf4dht3fje3li25xomm6ss26e'
    }
  },
  sandbox: {
    env: 'sandbox',
    data_dog: {
      token: 'pubf32334de813e2f15f84246421efcc923',
      url: 'https://logs.browser-intake-datadoghq.com/api'
    },
    server_url: 'https://fraud-api-sandbox.snappt.com',
    document_portal: 'https://sandbox.documentportal.info',
    frontend_url: 'https://fraud-sandbox.snappt.com',
    api_url: 'https://fraud-api-sandbox.snappt.com/v2',
    epa_url: 'https://enterprise-api-sandbox.snappt.com',
    feature_flag_api_url: 'https://feature-flag-api-sandbox.snappt.com',
    bucket_url: 'https://fraud-images-sandbox.s3.us-west-2.amazonaws.com',
    metabase: {
      url: 'https://snappt.metabaseapp.com',
      dashboards: {
        my_work_performance: {
          id: 110,
          report_type: 'my_work_performance'
        },
        team_performance: {
          id: 139,
          report_type: 'team_performance'
        },
        reporting_tab: {
          id: 113,
          report_type: 'reporting_tab'
        },
        reporting_sla: {
          id: 157,
          report_type: 'reporting_sla'
        }
      }
    },
    adobe: {
      pdf_token: '1d3acc97f42347228047f8a47ef00726'
    },
    heap: {
      app_id: '2509813560'
    },
    userflow: {
      token: 'ct_wrf4dht3fje3li25xomm6ss26e'
    }
  }
};
