import { appUnauthenticatedApi } from 'src/api';
import {
  ICreateProofRequest,
  IProofDUP,
  IProofGetPresignedUrlResponse,
  ISaveProofFileRequest,
  UnauthenticateSessionProofType
} from './ts/interfaces';

const api = {
  getProofs: async () => {
    const response = await appUnauthenticatedApi.getUnauthenticatedSessionProofs();
    if (!response) {
      console.error(`getProofs: ${response}`);
      throw new Error('Error fetching the proofs');
    }
    return response;
  },
  getProof: async (id: string, tempId?: string) => {
    const response = await appUnauthenticatedApi.getUnauthenticatedSessionProof(id);
    if (!response) {
      console.error(`getProof: ${response}`);
      throw new Error('Error fetching the proof');
    }
    return { ...response, tempId };
  },
  createProofPreURL: async (data: ICreateProofRequest) => {
    const response = await appUnauthenticatedApi.createUnauthenticatedSessionProof(data);
    if (!response) {
      console.error(`createProofPreURL: ${response}`);
      throw new Error('Error creating the Pre-URL of the proof');
    }
    return { ...(response.data || {}) } as IProofDUP;
  },
  getProofURL: async (id: string) => {
    const response = await appUnauthenticatedApi.getUnauthenticatedSessionProofFileUrl(id);
    if (!response) {
      console.error(`getProofURL: ${response}`);
      throw new Error('Error fetching the proof');
    }
    return { ...(response.data || {}) } as IProofGetPresignedUrlResponse;
  },
  setProofURL: async (id: string) => {
    //TODO: Should return the response from the server
    const response = await appUnauthenticatedApi.setUnauthenticatedSessionProofFileUrl(id);
    if (!response) {
      return {};
    }
    console.error(`getProofURL: ${response}`);
    throw new Error('Error fetching the proof');
  },
  saveProofFile: async ({ method, url, fields, type, file }: ISaveProofFileRequest) => {
    try {
      if (file) {
        let body: FormData | File = file;
        if (method === 'POST') {
          body = new FormData();
          body.append('Content-Type', type);
          Object.entries(fields).forEach(([key, value]) => {
            (<FormData>body).append(key, value);
          });
          body.append('file', file);
        }

        const response = await fetch(url, {
          body: body,
          method: method
        });

        if (response?.ok && response.status >= 200 && response.status < 300) {
          return response;
        }

        console.error(`saveProofFile: ${response}`);
        throw new Error(response.statusText);
      }
    } catch (e) {
      console.error(e);
      throw new Error(
        'Something went wrong trying to upload the file. Please try again or contact to Support.'
      );
    }
  },
  removeProof: async (id: string) => {
    //TODO: Should return the response from the server
    const response = await appUnauthenticatedApi.deleteUnauthenticatedSessionProof(id);
    if (!response) {
      return {};
    }
    console.error(`removeProof: ${response}`);
    throw new Error('Error removing the proof');
  },
  changeProofType: async ({ id, type }: { id: string; type: UnauthenticateSessionProofType }) => {
    const response = await appUnauthenticatedApi.updateUnauthenticatedSessionProof(id, {
      proof: { type }
    });
    if (!response) {
      console.error(`changeProofType: ${response}`);
      throw new Error('Error changing the proof type');
    }
    return response;
  }
};

export default api;
