import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { UNAUTHENTICATED_SESSION_TOKEN_KEY } from 'src/features/DUP/constants';
import { configVars } from 'src/helpers';
import { IProofDUP } from './ts/interfaces';

export const queryProofApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${configVars.api_url}/unauthenticated/applications`,
    credentials: 'include',
    prepareHeaders: (headers) => {
      headers.set(
        'x-unauthenticated-session-token',
        localStorage.getItem(UNAUTHENTICATED_SESSION_TOKEN_KEY) || ''
      );
      return headers;
    }
  }),
  reducerPath: 'QueryProofDUP',
  tagTypes: ['ProofDUP'],
  endpoints: (builder) => ({
    getProof: builder.query({
      query({ proof_id }) {
        return `current/proofs/${proof_id}`;
      },
      transformResponse: (response: { data: IProofDUP }) => response.data,
      providesTags: (result, error, id) => [{ type: 'ProofDUP', id }]
    })
  })
});

export const { useGetProofQuery } = queryProofApi;
