// Vendor
import { useEffect, useState } from 'react';

// Components
import Button, { ButtonDisabledStyle, ButtonVariant } from 'src/components/atoms/Button';
import { Checkbox } from 'src/components/atoms/Checkbox';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Spinner from 'src/components/atoms/Spinner';
import ToolTip, { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import { Container, Content, Thumbnail } from './styles';

// Helpers
import { cleanFileName, downloadUrlToPdf } from 'src/helpers';
import checkIfImageExists from 'src/helpers/checkIfImageExists';
import { getAttrEntryResult } from 'src/helpers/getEntryStatusIcon';

// Constants
import { colors } from 'src/theme/constants';

// Enums
import { EntryResultEnum } from 'src/ts/enums';

// Types
import { EntryResultType } from 'src/ts/interfaces';

export type CardSelectDocProps = {
  id: string;
  isChecked: boolean;
  showDetail?: boolean;
  onShowDetail?: () => void;
  showMetadata: boolean;
  onShowMetadata?: () => void;
  showNotes: boolean;
  onShowNotes?: () => void;
  showDownload: boolean;
  onGetSelected?: ({ isChecked, id }: { isChecked: boolean; id: string }) => void;
  thumbnailURL?: string | null;
  fileURL?: string | null;
  fileName: string;
  shortId: string;
  isLoading: boolean;
  result: EntryResultType;
  isReviewDisabled?: boolean;
  cleanProceedWithCautionFlagEnabled?: boolean;
};

const getColor = (result: EntryResultType, cleanProceedWithCautionFlagEnabled?: boolean) => {
  let color = colors.gray[200];
  switch (result) {
    case EntryResultEnum.Edited:
      color = colors.red[700];
      break;
    case EntryResultEnum.Clean:
      color = colors.green[700];
      break;
    case EntryResultEnum.Undetermined:
      color = cleanProceedWithCautionFlagEnabled ? colors.snappt[700] : colors.yellow[700];
      break;
    case EntryResultEnum.CleanProceedWithCaution:
      color = colors.yellow[700];
      break;
    default:
      break;
  }
  return { color };
};

const tempImage = `${process.env.PUBLIC_URL}/assets/images/notPreviewDocument.svg`;

type IImageState = {
  url: string;
  isLoading: boolean;
  hasError: undefined | boolean;
};

const CardSelectDoc: React.FC<CardSelectDocProps> = ({
  id,
  isChecked,
  showDetail,
  onShowDetail,
  showMetadata,
  onShowMetadata,
  showNotes,
  onShowNotes,
  showDownload,
  onGetSelected,
  thumbnailURL,
  fileURL,
  fileName,
  shortId,
  isLoading,
  result,
  isReviewDisabled,
  cleanProceedWithCautionFlagEnabled
}: CardSelectDocProps) => {
  const { color } = getColor(result, cleanProceedWithCautionFlagEnabled);
  const file = cleanFileName(fileName);
  const { icon: resultIcon, name: resultName } = getAttrEntryResult(
    result,
    cleanProceedWithCautionFlagEnabled
  );

  const [thumbnail, setThumbnail] = useState<IImageState>({
    url: tempImage,
    isLoading: true,
    hasError: undefined
  });

  const getImage = (image: string) => {
    checkIfImageExists(image, (exists) => {
      if (exists) {
        setThumbnail({ url: image, hasError: false, isLoading: false });
      } else {
        setThumbnail({
          url: tempImage,
          hasError: true,
          isLoading: false
        });
      }
    });
  };

  useEffect(() => {
    if (thumbnailURL && thumbnail.isLoading) {
      getImage(thumbnailURL);
    }
  }, [thumbnailURL, thumbnail]);

  return (
    <Container className="CardSelectDoc" color={color}>
      <Content>
        <Row justify="space-between">
          <Checkbox
            name={`CardSelectDoc_${id}`}
            value={id}
            label={shortId}
            isChecked={isChecked}
            isDisabled={isReviewDisabled || isLoading}
            onClick={({ isChecked, value }) => {
              onGetSelected?.({ isChecked, id: value });
            }}
          />
          {isLoading ? (
            <Spinner size="normal" color="disabled" />
          ) : (
            <ToolTip direction={ToolTipDirection.top} content={resultName}>
              <Icon icon={resultIcon} />
            </ToolTip>
          )}
        </Row>
        <Row justify="center">
          <Thumbnail>
            <img src={thumbnail.url} alt={file} />
          </Thumbnail>
        </Row>
        <Row>
          {showDetail && onShowDetail && (
            <Button
              name="detail_button"
              variant={ButtonVariant.ghost}
              onClick={onShowDetail}
              isDisabled={isLoading}
              disabledStyle={ButtonDisabledStyle.transparent}
            >
              <Icon icon="cardSelectDoc_detail" />
            </Button>
          )}
          {showMetadata && onShowMetadata && (
            <ToolTip direction={ToolTipDirection.top} content="Document Extractions">
              <Button
                name="metadata_button"
                variant={ButtonVariant.ghost}
                onClick={onShowMetadata}
                isDisabled={isLoading}
                disabledStyle={ButtonDisabledStyle.transparent}
              >
                <Icon icon="cardSelectDoc_metadata" />
              </Button>
            </ToolTip>
          )}
          {showNotes && onShowNotes && (
            <ToolTip direction={ToolTipDirection.top} content="Public Document Notes">
              <Button
                name="note_button"
                variant={ButtonVariant.ghost}
                onClick={onShowNotes}
                isDisabled={isLoading}
                disabledStyle={ButtonDisabledStyle.transparent}
              >
                <Icon icon="cardSelectDoc_note" />
              </Button>
            </ToolTip>
          )}
          {showDownload && fileURL && (
            <ToolTip direction={ToolTipDirection.top} content="Download">
              <Button
                name={`download_${fileName}_button`}
                variant={ButtonVariant.ghost}
                onClick={() => downloadUrlToPdf(fileURL, `${fileName}.pdf`)}
                isDisabled={isLoading}
                disabledStyle={ButtonDisabledStyle.transparent}
              >
                <Icon icon="cardSelectDoc_download" />
              </Button>
            </ToolTip>
          )}
        </Row>
      </Content>
    </Container>
  );
};

CardSelectDoc.defaultProps = {
  id: '1',
  isChecked: false,
  showDetail: true,
  showMetadata: true,
  showNotes: true,
  showDownload: true,
  thumbnailURL: null,
  fileName: 'Document',
  shortId: '',
  fileURL: null,
  isLoading: false,
  result: EntryResultEnum.Clean
};

export default CardSelectDoc;
