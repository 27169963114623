import dup from 'src/components/DUP/i18n/es.json';
const TEXTS = {
  language_en: 'English',
  language_es: 'Spanish',

  'validation_should_be_#_or_more': 'Debe ser %1 o más carácteres',
  'validation_should_be_less_than_#_characters': 'Debe tener menos de %1 carácteres',
  validation_complete_this_field: 'Completa este campo',
  'validation_#_characters_maximum': '%1 carácteres como máximo',
  validation_should_not_contain_only_numbers_and_symbols:
    'No debe contener solo números y símbolos',
  validation_should_be_only_one_character: 'Debería ser un solo carácter',
  validation_should_contain_a_capital_letter: 'Debe contener una letra mayúscula',

  message_Yes: 'Sí',
  message_No: 'No',

  ...dup
};

export default TEXTS;
