import PropTypes from 'prop-types';
import Gap from 'src/components/atoms/Gap';
import Row from '../../atoms/Row/Row';
import SkeletonPiece, { SkeletonPieceColor } from '../../atoms/SkeletonPiece';
import { Container } from './styles';

export type LoaderResourceListProps = {
  rows?: number;
};

const LoaderResourceList: React.FC<LoaderResourceListProps> = (props) => {
  const { rows } = props;
  return (
    <Row columns={3} gap={1.5} wrap={'wrap'} justify="center" data-testid="LoaderResourceList">
      {Array(rows)
        .fill(0)
        .map((_, i) => {
          return (
            <Container data-testid={'row'} key={i}>
              <Row justify="space-between">
                <Row.Col size={'80%'}>
                  <SkeletonPiece width={70} height={12} color={SkeletonPieceColor.gray100} />
                  <Gap height={1} />
                  <SkeletonPiece width={'100%'} height={20} color={SkeletonPieceColor.gray200} />
                  <Gap height={1} />
                  <SkeletonPiece width={'80%'} height={20} color={SkeletonPieceColor.gray50} />
                  <Gap height={1} />
                  <SkeletonPiece width={'100%'} height={16} color={SkeletonPieceColor.gray100} />
                  <Gap height={1} />
                  <SkeletonPiece width={'85%'} height={16} color={SkeletonPieceColor.gray100} />
                  <Gap height={1} />
                  <SkeletonPiece width={'100%'} height={16} color={SkeletonPieceColor.gray100} />
                  <Gap height={2.5} />
                  <Row className="Skeleton___Buttons" gap={1}>
                    <SkeletonPiece width={20} height={20} color={SkeletonPieceColor.gray200} />
                    <SkeletonPiece width={20} height={20} color={SkeletonPieceColor.gray200} />
                  </Row>
                </Row.Col>
              </Row>
            </Container>
          );
        })}
    </Row>
  );
};
LoaderResourceList.propTypes = {
  rows: PropTypes.number
};

LoaderResourceList.defaultProps = {
  rows: 6
};
export default LoaderResourceList;
