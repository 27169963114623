import { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import { Dropdown, DropdownProps } from './Dropdown';

interface ControlledDropdownProps
  extends UseControllerProps,
    Omit<DropdownProps, 'name' | 'valueInitial'> {}

const ControlledDropdown: FC<ControlledDropdownProps> = (props) => {
  const {
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
    onChange: customOnChange,
    ...dropdownProps
  } = props;

  const {
    field: { value, onChange }
  } = useController({ name, control, defaultValue, rules, shouldUnregister });

  return (
    <Dropdown
      {...dropdownProps}
      name={name}
      valueInitial={value}
      onChange={(data) => {
        customOnChange?.(data);
        onChange(data?.[name]?.selected?.value);
      }}
    />
  );
};

ControlledDropdown.displayName = 'ControlledDropdown';

export default ControlledDropdown;
