/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { actions, selectors } from 'src/features/DUP/DUPSlice';
import { UNAUTHENTICATED_SESSION_TOKEN_KEY } from 'src/features/DUP/constants';
import useForm from 'src/features/DUP/form/hooks/useForm';
import {
  getCurrentApplication,
  getCurrentProperty,
  submitApplication
} from 'src/features/DUP/form/services';
import { ISubmitUnauthenticatedApplicationRequest } from 'src/features/DUP/form/ts/interfaces';
import { getProofs } from 'src/features/DUP/proofs/services';
import { useAppDispatch } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';

const useDUP = () => {
  const { companyId, propertyId } = useParams();
  const { search } = useLocation();

  const applicantIdentifier = new URLSearchParams(search).get('applicantIdentifier');

  const dispatch = useAppDispatch();

  const { onSetType, type, information, onCreateApplication } = useForm();

  const createApplicationResponse = useSelector(selectors.createApplication);
  const getCurrentApplicationResponse = useSelector(selectors.getCurrentApplication);
  const getCurrentPropertyResponse = useSelector(selectors.getCurrentProperty);
  const getProofsResponse = useSelector(selectors.getProofs);
  const updateApplicationResponse = useSelector(selectors.updateApplication);
  const submitApplicationResponse = useSelector(selectors.submitApplication);

  useEffect(() => {
    if (companyId && propertyId) {
      onCreateApplication(companyId, propertyId);
    }
  }, [companyId, propertyId]);

  useEffect(() => {
    if (
      createApplicationResponse.status === StatusTypes.SUCCESS &&
      createApplicationResponse.error === null
    ) {
      localStorage.setItem(
        UNAUTHENTICATED_SESSION_TOKEN_KEY,
        createApplicationResponse.data.token || ''
      );
      dispatch(getCurrentApplication());
    }
  }, [createApplicationResponse.status]);

  useEffect(() => {
    if (
      getCurrentApplicationResponse.status === StatusTypes.SUCCESS &&
      getCurrentApplicationResponse.error === null &&
      getCurrentApplicationResponse.data !== undefined
    ) {
      dispatch(getCurrentProperty());
    }
  }, [getCurrentApplicationResponse.status]);

  useEffect(() => {
    if (
      getCurrentPropertyResponse.status === StatusTypes.SUCCESS &&
      getCurrentPropertyResponse.error === null &&
      getCurrentPropertyResponse.data !== undefined
    ) {
      onSetType(getCurrentPropertyResponse.data);
      dispatch(getProofs());
    }
  }, [getCurrentPropertyResponse.status]);

  useEffect(() => {
    if (updateApplicationResponse.status === StatusTypes.SUCCESS) {
      const data: ISubmitUnauthenticatedApplicationRequest = {
        applicant_identifier: applicantIdentifier ? applicantIdentifier : undefined
      };
      dispatch(submitApplication(data));
    }
  }, [updateApplicationResponse.status]);

  const onResetErrorOfSubmit = () => {
    dispatch(actions.removeSubmit());
  };

  const isLoading =
    createApplicationResponse.status !== StatusTypes.SUCCESS ||
    getCurrentApplicationResponse.status !== StatusTypes.SUCCESS ||
    getCurrentPropertyResponse.status !== StatusTypes.SUCCESS ||
    getProofsResponse.status !== StatusTypes.SUCCESS;

  const error =
    createApplicationResponse.error !== null ||
    getCurrentApplicationResponse.error !== null ||
    getCurrentPropertyResponse.error !== null ||
    getCurrentPropertyResponse.error !== null ||
    getProofsResponse.error !== null;

  const isErrorUnauthorized = createApplicationResponse.error?.includes('401');

  const isSubmitting = submitApplicationResponse.status === StatusTypes.LOADING;
  const isSubmitted = submitApplicationResponse.status === StatusTypes.SUCCESS;
  const submitError = submitApplicationResponse.error !== null;

  return {
    proofs: getProofsResponse.data,
    isLoading,
    error,
    isErrorUnauthorized,
    information,
    type,
    dataForm: {
      application: getCurrentApplicationResponse.data,
      property: getCurrentPropertyResponse.data
    },
    submitError,
    isSubmitted,
    isSubmitting,
    errorOfSubmit: submitApplicationResponse.error?.toString() || null,
    onResetErrorOfSubmit
  };
};

export default useDUP;
