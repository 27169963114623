import { addHours, Duration, intervalToDuration } from 'date-fns';

// Calculates the SLA time left for an entry
const getSLATimeLeft = (entrySubmissionTime: string): Duration => {
  const SLAHours = 24; // This is based on Snappt SLA
  const SLA = addHours(new Date(entrySubmissionTime), SLAHours);
  const timeLeft = intervalToDuration({ start: new Date(), end: SLA });

  // Since the SLA is 24 hours (1 day), entries with an interval greater are old and we should not return the time left
  if (timeLeft.days && timeLeft.days >= 1) {
    return { hours: 0, minutes: 0 };
  }

  return timeLeft;
};
export default getSLATimeLeft;
