// Vendor
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

// Components
import { DropdownMulticheck } from 'src/components/molecules/DropdownMulticheck';

// Hooks
import usePropertiesDDown from 'src/features/property/hooks/usePropertiesDDown';
import { useScrollObserver } from 'src/hooks';

// Helpers
import { calculatePagesCount, formatCheckboxData } from 'src/helpers';

// Types
import { CheckboxType } from 'src/components/atoms/Checkbox/types';
import { IPaginationRequest } from 'src/ts/interfaces';

type CallbackParams = {
  ids: string;
  data: CheckboxType[];
  isCheckAll: boolean;
  q?: string;
};

type PropertyMultiSelectorProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<any>;
  isDisabled: boolean;
  onCallback: ({ ids, data, isCheckAll, q }: CallbackParams) => void;
  company?: string;
};

const PropertyMultiSelector: React.FC<PropertyMultiSelectorProps> = ({
  methods,
  isDisabled,
  onCallback,
  company
}) => {
  const { onGetPropertiesDDown, getPropertiesDDown: propertiesDDown } = usePropertiesDDown();

  const {
    data: propertyData,
    isIdle: propertyIsIdle,
    isLoading: propertyIsLoading,
    count: propertyCount,
    filter: propertyFilter,
    error: propertyError
  } = propertiesDDown;

  const dataFilter: IPaginationRequest = useMemo(
    () => ({
      ...propertyFilter,
      page: 0,
      filter: `company_short_id=${company}`
    }),
    [company, propertyFilter]
  );

  useEffect(() => {
    if (!isDisabled && propertyIsIdle) {
      onGetPropertiesDDown(dataFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, propertyIsIdle]);

  const { scrollObserver: getPropertyScrollObserver } = useScrollObserver({
    page: propertyFilter.page,
    isLoading: propertyIsLoading,
    callback: (page: number) => {
      onGetPropertiesDDown({ ...dataFilter, page });
    },
    hasMore: propertyFilter.page < calculatePagesCount(propertyFilter.rowsPerPage, propertyCount),
    dependency: [propertyIsLoading, propertyFilter]
  });

  const formattedData = useMemo(
    () =>
      formatCheckboxData({
        data: propertyData || [],
        value: 'short_id',
        label: 'name'
      }),
    [propertyData]
  );

  const handleCallback = useCallback(
    (params: CallbackParams) => {
      if (params.isCheckAll) {
        onGetPropertiesDDown({
          ...dataFilter,
          rowsPerPage: propertyCount,
          q: params.q
        });
      }

      onCallback(params);
    },
    [propertyCount, dataFilter, onCallback, onGetPropertiesDDown]
  );

  return (
    <Controller
      key={`property_dropdown`}
      name="property"
      control={methods.control}
      render={({ field: { name } }) => (
        <DropdownMulticheck
          data={formattedData}
          totalRows={propertyCount}
          onChange={handleCallback}
          name={name}
          placeholder="Property"
          size="md"
          itemRef={getPropertyScrollObserver}
          isLoading={propertyIsLoading}
          isDisabled={isDisabled || propertyError !== null}
          showSearch
          onSearch={(q) => {
            onGetPropertiesDDown({ ...dataFilter, q });
          }}
          textSelectAll="All properties selected"
        />
      )}
    />
  );
};

export default PropertyMultiSelector;
