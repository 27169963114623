// Components
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Text from 'src/components/atoms/Text';
import { MultiTab } from 'src/components/molecules/MultiTab';
import {
  TabBody,
  TabContent,
  TabHeader,
  TabTitle
} from 'src/components/molecules/MultiTab/MultiTab';
import { dateFormat, getEntryStatusIcon } from 'src/helpers';

// Types
import { IEntry } from 'src/ts/interfaces';

type EntriesMultiTabViewProps = {
  entries: IEntry[];
  dateFormatString?: string;
  tabLabelAdditionalContent?: React.ReactNode;
  cleanProceedWithCautionFlagEnabled?: boolean;
  onTabSelect: (index: number) => void;
  tabContentChildren: (entry: IEntry[][number]) => React.ReactNode;
};

/**
 * The EntriesMultiTabView component renders entries in a multi-tab view.
 * Labels are generated based on the submission date of the entry and the result.
 * Optional props for date formatting and additional content in the tab label are available.
 *
 * ## Usage
 * ```js
 * import React from 'react';
 * import EntriesMultiTabView from 'src/components/organisms/EntriesMultiTabView';
 *
 * const MyComponent = () => {
 *   const entries = [
 *     {
 *       id: "29408bee-e68c-4cd9-b6cb-7e91ad0216de",
 *       submission_time: "2022-11-14T20:42:54Z",
 *       result: 'PENDING'
 *     },
 *     {
 *       id: "29408bee-e68c-4cd9-b6cb-7e91ad0216de",
 *       submission_time: "2022-11-14T20:42:54Z",
 *       result: 'PENDING'
 *     }
 *   ]
 *
 *   return (
 *     <EntriesMultiTabView
 *       entries={entries}
 *       onTabSelect={(index) => handleTabChange(index)}
 *       tabContentChildren={(entry) => <EntryContent entry={entry} />}
 *     />
 *   )
 * }
 *
 * export default MyComponent;
 * ```
 */

const EntriesMultiTabView = ({
  entries,
  dateFormatString,
  tabLabelAdditionalContent,
  cleanProceedWithCautionFlagEnabled,
  onTabSelect,
  tabContentChildren
}: EntriesMultiTabViewProps): JSX.Element => {
  return (
    <MultiTab name="submissions-by-date" onSelect={onTabSelect}>
      <TabHeader aria-label="Submissions by Date">
        {entries?.map((entry) => (
          <TabTitle key={entry.id}>
            <Row alignItems="center" gap={0.5}>
              <Row.Col>
                <Text isBold>{dateFormat(entry.submission_time, dateFormatString)}</Text>
              </Row.Col>
              <Row.Col>
                <Icon icon={getEntryStatusIcon(entry.result, cleanProceedWithCautionFlagEnabled)} />
              </Row.Col>
              {tabLabelAdditionalContent && <Row.Col>{tabLabelAdditionalContent}</Row.Col>}
            </Row>
          </TabTitle>
        ))}
      </TabHeader>
      <TabBody>
        {entries?.map((entry) => (
          <TabContent key={entry.id}>{tabContentChildren(entry)}</TabContent>
        ))}
      </TabBody>
    </MultiTab>
  );
};

export default EntriesMultiTabView;
