import { Status as StatusTypes } from 'src/ts/enums';
import { IUser } from 'src/ts/interfaces';
import { ICompany } from 'src/ts/interfaces/company';
import { COMPANY_INFO } from '../constants';
import { IInformation } from './ts/interfaces';

export type StateDataForm = {
  information: IInformation;
  process: {
    createUserAndCompany: {
      status: StatusTypes;
      error: string | null;
    };
    getCurrentCompany: {
      status: StatusTypes;
      error: string | null;
      data: Pick<ICompany, 'address' | 'city' | 'name' | 'state' | 'zip'>;
    };
    getCurrentUser: {
      status: StatusTypes;
      error: string | null;
      data: Pick<IUser, 'email' | 'first_name' | 'last_name'>;
    };
  };
};

export const initialStateForm: StateDataForm = {
  information: COMPANY_INFO,
  process: {
    createUserAndCompany: {
      status: StatusTypes.IDLE,
      error: null
    },
    getCurrentCompany: {
      status: StatusTypes.IDLE,
      data: {
        address: '',
        city: '',
        name: '',
        state: '',
        zip: ''
      },
      error: null
    },
    getCurrentUser: {
      status: StatusTypes.IDLE,
      data: {
        email: '',
        first_name: '',
        last_name: ''
      },
      error: null
    }
  }
};
