import Modal from 'src/components/organisms/Modal';
import styled from 'styled-components/macro';

export const StyledModal = styled(Modal)`
  & .matching-tile {
    & > :first-child {
      padding: 0rem 0rem 1rem 0rem;
      height: 20.3125rem;
      justify-content: center;
    }
  }

  & .modal__container {
    min-width: 580px;
  }
`;
