// Vendor
import PropTypes from 'prop-types';

// Components
import Button, { ButtonType, ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { StyledContainer } from './styles';

export enum ShowStateVariant {
  search = 'search',
  empty = 'empty',
  error = 'error'
}

export enum ShowStateType {
  user = 'user',
  application = 'application',
  property = 'property',
  information = 'information',
  searchEmpty = 'searchEmpty',
  team = 'team'
}

export enum ShowStateColor {
  muted = 'muted',
  primary = 'primary'
}

export type ShowStateProps = React.HTMLAttributes<HTMLButtonElement> & {
  className?: string;
  variant: keyof typeof ShowStateVariant;
  type: keyof typeof ShowStateType;
  searchText?: string;
  color?: keyof typeof ShowStateColor;
  buttonLabel?: string;
  onClick?: () => void;
  message?: string;
};

const ShowState: React.FC<ShowStateProps> = (props) => {
  const { type, variant, searchText, buttonLabel, color, onClick, message, className } = props;

  const ShowEmpty = () => {
    if (message) {
      return (
        <>
          <Text variant={TextVariant.h4} color={color}>
            <span dangerouslySetInnerHTML={{ __html: message }} className="message__text" />
          </Text>
          {onClick && (
            <>
              <Gap height={1.5} />
              <Button
                name="emptyStateButton"
                variant={ButtonVariant.outline}
                type={ButtonType.button}
                onClick={onClick}
              >
                {buttonLabel}
              </Button>
            </>
          )}
        </>
      );
    }

    const getCopy = (type: string) => {
      switch (type) {
        case 'user':
          return 'users assigned';
        case 'application':
          return 'applications';
        case 'property':
          return 'properties';
        default:
          return 'items here';
      }
    };

    return (
      <>
        <Text variant={TextVariant.h4} color={color}>
          There are no
        </Text>
        <Text variant={TextVariant.h4} color={color}>
          {`${getCopy(type)} yet`}
        </Text>
      </>
    );
  };

  const ShowSearch = () => {
    return (
      <>
        <Text variant={TextVariant.normal} color={color}>
          No results found for
        </Text>
        <Text variant={TextVariant.normal} color={color}>
          {searchText ? ` "${searchText}"` : ' your search'}
        </Text>
        {message && (
          <>
            <Gap height={1} />
            <Text variant={TextVariant.normal} color={color}>
              <span dangerouslySetInnerHTML={{ __html: message }} className="message__text" />
            </Text>
          </>
        )}
      </>
    );
  };

  const ShowError = () => {
    return (
      <>
        <Text variant={TextVariant.h4} color={color}>
          <span
            dangerouslySetInnerHTML={{ __html: message ? message : 'There was an error' }}
            className="message__text"
          />
        </Text>
        {onClick && (
          <>
            <Gap height={1.5} />
            <Button
              name="errorStateButton"
              variant={ButtonVariant.outline}
              type={ButtonType.button}
              onClick={onClick}
            >
              {buttonLabel}
            </Button>
          </>
        )}
      </>
    );
  };

  return (
    <StyledContainer className={`${className} ShowState`}>
      <Icon color={color} icon={variant === 'search' && type !== 'searchEmpty' ? 'search' : type} />
      <Gap height={0.5} />
      {variant === 'empty' && <ShowEmpty />}
      {variant === 'search' && <ShowSearch />}
      {variant === 'error' && <ShowError />}
    </StyledContainer>
  );
};

ShowState.propTypes = {
  variant: PropTypes.oneOf<keyof typeof ShowStateVariant>(Object.values(ShowStateVariant))
    .isRequired,
  type: PropTypes.oneOf<keyof typeof ShowStateType>(Object.values(ShowStateType)).isRequired,
  searchText: PropTypes.string,
  color: PropTypes.oneOf<keyof typeof ShowStateColor>(Object.values(ShowStateColor)).isRequired,
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
  message: PropTypes.string,
  className: PropTypes.string
};

ShowState.defaultProps = {
  className: '',
  variant: 'error',
  type: 'information',
  buttonLabel: 'Retry',
  color: ShowStateColor.muted
};

export default ShowState;
