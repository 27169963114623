/* eslint-disable @typescript-eslint/no-explicit-any */
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

export const LinkComponent = styled(NavLink)<any>`
  text-decoration: none;
  width: max-content;
  ${({ theme }) => theme.button};
  ${({ theme, size }) => theme.button.sizes[size]};
  ${({ theme, color }) => theme.button.colors[color]};
  ${({ theme, variant, color }) => theme.button.colors[color].variants[variant]};
  border-radius: ${({ isRounded }) => (isRounded ? '50%' : '0.125rem')};
  :hover {
    ${({ theme, variant, color }) => theme.button.colors[color].variants[variant].hover};
  }

  &[data-disabled='true'] {
    &[data-disabledstyle='white'] {
      cursor: default;
      color: ${({ theme }) => theme.color.gray[300]};
      background: ${({ theme }) => theme.color.white};
      border-color: ${({ theme }) => theme.color.white};
      svg {
        path {
          fill: ${({ theme }) => theme.color.gray[300]};
        }
      }
      &:hover {
        border-color: ${({ theme }) => theme.color.white};
      }
    }

    &[data-disabledstyle='gray'] {
      cursor: default;
      color: ${({ theme }) => theme.color.gray[300]};
      background: ${({ theme }) => theme.color.white};
      border-color: ${({ theme }) => theme.color.gray};
      svg {
        path {
          fill: ${({ theme }) => theme.color.gray[300]};
        }
      }
      &:hover {
        border-color: ${({ theme }) => theme.color.gray[300]};
      }
    }

    ${({ theme }) => theme.button.disabled};
  }

  div {
    display: flex;
  }
`;
