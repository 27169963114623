import { createAsyncThunk } from '@reduxjs/toolkit';
import { InvitationUserRequest } from '@snapptinc/fraud-platform/api';
import authApi from 'src/features/auth/api';
import { getPaginationRequest, IInvitation, IInvitationRequest } from 'ts/interfaces';
import apiCompany from '../company/api';
import api from './api';
import { NAME } from './constants';

export const invitationGetByIdThunk = createAsyncThunk(`${NAME}/getById`, async (id: string) => {
  try {
    const { data } = await api.getById(id);

    return data as IInvitation;
  } catch (error) {
    console.error(`${NAME}/getById`, error);
    throw new Error('Invitation not found');
  }
});

export const invitationCreateUserThunk = createAsyncThunk(
  `${NAME}/createUser`,
  async ({ id, input }: { id: string; input: InvitationUserRequest }) => {
    try {
      await api.createUser(id, input);

      return authApi.login();
    } catch (error) {
      console.error(`${NAME}/createUser`, error);
      throw new Error("Error creating the user's invitation");
    }
  }
);

export const getAll = createAsyncThunk(`${NAME}/getAll`, async (data: getPaginationRequest) => {
  try {
    return await api.getAll(data);
  } catch (error) {
    console.error(`${NAME}/getAll`, error);
    throw new Error('Error fetching the invitations');
  }
});

export const getByEmail = createAsyncThunk(
  `${NAME}/getByEmail`,
  async (data: getPaginationRequest) => {
    try {
      return await api.getAll(data);
    } catch (error) {
      console.error(`${NAME}/getByEmail`, error);
      throw new Error('Error fetching the invitation by email');
    }
  }
);

export const remove = createAsyncThunk(`${NAME}/delete`, async (invitationId: string) => {
  try {
    return await api.delete(invitationId);
  } catch (error) {
    console.error(`${NAME}/delete`, error);
    throw new Error('Error deleting the invitation');
  }
});

export const resendInvitation = createAsyncThunk(
  `${NAME}/resendInvitation`,
  async (invitationId: string) => {
    try {
      await api.resendInvitation(invitationId);
      return { invitationId };
    } catch (error) {
      console.error(`${NAME}/resendInvitation`, error);
      throw new Error('Error resending the invitation');
    }
  }
);

export const create = createAsyncThunk(`${NAME}/create`, async (data: IInvitationRequest) => {
  try {
    return await api.create(data);
  } catch (error) {
    console.error(`${NAME}/create`, error);
    throw new Error('Error creating the invitation');
  }
});

export const searchGetAllCompany = createAsyncThunk(
  `${NAME}/searchGetAllCompany`,
  async (data: getPaginationRequest) => {
    try {
      return await apiCompany.getAll(data);
    } catch (error) {
      console.error(`${NAME}/searchGetAllCompany`, error);
      throw new Error('Error fetching the companies from searchGetAllCompany');
    }
  }
);
