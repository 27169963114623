import { fdeInboxApi } from 'src/api';
import { getPaginationRequest } from 'src/ts/interfaces';

const api = {
  getAll({ page, rowsPerPage, filter, sort, q }: getPaginationRequest) {
    // TODO: page and rowsPerPage should come as numbers
    const pageNum = page?.toString();
    const rowsPerPageNum = rowsPerPage?.toString();
    return fdeInboxApi.getFDEInboxes(pageNum, rowsPerPageNum, filter, sort, q);
  }
};

export default api;
