// Components
import { CheckboxGroup } from '../../CheckboxGroup';
import { LoaderTransferList } from '../../LoaderTransferList';
import { ShowState } from '../../ShowState';

// Types
import { CheckboxType } from 'src/components/atoms/Checkbox/types';
import { listProps } from '../types';

const ShowBox = ({
  list,
  searchText,
  data,
  onUpdate,
  minLength = 1
}: {
  list: listProps;
  searchText: string;
  data: CheckboxType[];
  onUpdate: (content: CheckboxType[]) => void;
  minLength?: number;
}) => {
  if (list.hasError) {
    return (
      <ShowState
        className="ShowState"
        type="information"
        variant="error"
        buttonLabel="Please try again"
        message="Something went wrong"
        onClick={() => list.onError?.() || window.location.reload()}
      />
    );
  }

  if (list.isLoading) {
    return <LoaderTransferList />;
  }

  if (searchText?.length) {
    if (searchText?.length < minLength) {
      return (
        <ShowState
          className="ShowState"
          type="searchEmpty"
          variant="search"
          message={`You should type in more than ${minLength} characters to search for results`}
        />
      );
    }

    const totalNoVisible = data.filter((item) => item.isVisible === false).length;
    if (totalNoVisible === list.data.length) {
      return (
        <ShowState
          className="ShowState"
          type="searchEmpty"
          variant="search"
          searchText={searchText}
        />
      );
    }
  }

  if (data.length === 0) {
    return (
      <ShowState
        className="ShowState"
        type="information"
        variant="empty"
        message={list.messageEmpty}
      />
    );
  }

  return (
    <CheckboxGroup
      name={list.name}
      data={data}
      onClick={({ data }) => onUpdate(data)}
      innerRef={list.innerRef}
      isLoading={list.isLoadingPage}
    />
  );
};

export default ShowBox;
