import { LandingLogin } from 'src/components/templates';
import { actions as authActions } from 'src/features/auth/authSlice';
import { useAppDispatch } from 'src/store';

const LogoutPage: React.FC = () => {
  const dispatch = useAppDispatch();

  setTimeout(() => dispatch(authActions.logout()), 1000);
  return (
    <LandingLogin
      image="/assets/images/income-verification-login.png"
      title="Logging you out..."
      showButton={false}
    />
  );
};

export default LogoutPage;
