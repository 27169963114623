const getBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default getBase64;

export const encode = (str?: string) => {
  if (!str) return '';
  return window.btoa(str);
};

export const decode = (str?: string) => {
  if (!str) return '';
  return window.atob(str);
};
