import { useMemo } from 'react';

import Modal, { ModalVariant } from 'src/components/organisms/Modal';

import { useSplitEntry } from 'src/features/entries/hooks';
import { useFolderPage } from '../FolderPageContext';

const SplitEntryModal = (): JSX.Element => {
  const { selectedEntryId, showSplitEntryModal, setShowSplitEntryModal } = useFolderPage();
  const { onSplitEntry } = useSplitEntry();

  const splitEntryModalProps = useMemo(
    () => ({
      title: 'Remove this entry from the application folder',
      labelButtonConfirm: 'Yes, remove it',
      text: 'You’re about to move this entry into a new application folder.\n This is reversible action. Are you sure you want to proceed?',
      showModal: showSplitEntryModal,
      setShowModal: setShowSplitEntryModal,
      onConfirm: () => {
        setShowSplitEntryModal?.(false);

        return onSplitEntry(selectedEntryId);
      }
    }),
    [onSplitEntry, selectedEntryId, setShowSplitEntryModal, showSplitEntryModal]
  );

  return <Modal {...splitEntryModalProps} variant={ModalVariant.none} labelButtonCancel="Cancel" />;
};

export default SplitEntryModal;
