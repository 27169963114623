// Vendor
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Hooks
import { useEntriesByFolderId } from 'src/features/entries/hooks';

// Redux
import { getEntriesByFolderIdThunk } from 'src/features/entries/services';
import { getProofsByEntryIdThunk, getProofTextBreakupThunk } from 'src/features/proofs/services';

// Helpers
import { cleanFileName, downloadUrlToPdf } from 'src/helpers';

// Types
import { IEntry, IProof } from 'src/ts/interfaces';

const useEntryModalsState = () => {
  const [showRequestDocumentsModal, setShowRequestDocumentsModal] = useState(false);
  const [showSendReportModal, setShowSendReportModal] = useState(false);
  const [showSplitEntryModal, setShowSplitEntryModal] = useState(false);
  const [showEntryMetadataModal, setShowEntryMetadataModal] = useState(false);

  return {
    showRequestDocumentsModal,
    showSendReportModal,
    showSplitEntryModal,
    setShowRequestDocumentsModal,
    setShowSendReportModal,
    setShowSplitEntryModal,
    showEntryMetadataModal,
    setShowEntryMetadataModal
  };
};

const useProofState = (entryId: string) => {
  const dispatch = useDispatch();
  const { folderId } = useParams();
  const navigate = useNavigate();
  const [selectedProofId, setSelectedProofId] = useState<string>();
  const [showMetadataModal, setShowMetadataModal] = useState(false);

  const handleDownload = (url?: string, name?: string, fileName?: string) => {
    if (!url || !name || !fileName) {
      console.warn('Is missing some arguments to download the file.');
      return null;
    }

    downloadUrlToPdf(url, `${cleanFileName(name)}_${fileName}.pdf`);
  };

  const handleTextFields = useCallback(
    ({ id, text_breakups_file, text_breakups }: Partial<IProof>) => {
      // Download text breakup if it does not exist
      const shouldDownloadTextBreakups =
        text_breakups_file && (!text_breakups || text_breakups.length === 0);

      if (shouldDownloadTextBreakups) {
        dispatch(getProofTextBreakupThunk({ id, text_breakups_file }));
      }
      navigate(`/folder/${folderId}/proof/${id}`);
    },
    [navigate, folderId, dispatch]
  );

  const handleVisibility = (id: string) => {
    setSelectedProofId(id);
    setShowMetadataModal(true);
  };

  return {
    selectedProofId,
    showMetadataModal,
    setShowMetadataModal,
    handleDownload,
    handleTextFields,
    handleVisibility
  };
};

const getDefaultEntry = (
  entryId?: string,
  firstEntryId?: string,
  folderId?: string,
  assignedEntry?: IEntry | null
) => {
  if (entryId) {
    return entryId;
  }

  if (assignedEntry?.folder_id === folderId) {
    return assignedEntry?.id;
  }

  return firstEntryId;
};

export const useFolderPageProvider = ({ assignedEntry }: { assignedEntry?: IEntry | null }) => {
  const dispatch = useDispatch();
  const { folderId, entryId } = useParams();

  const [selectedEntryId, setSelectedEntryId] = useState<string | undefined>(undefined);

  const entryModalsState = useEntryModalsState();
  const proofState = useProofState(selectedEntryId as string);
  const { entries } = useEntriesByFolderId(folderId as string);

  const getEntries = useCallback(() => {
    if (folderId) {
      dispatch(getEntriesByFolderIdThunk(folderId));
    }
  }, [dispatch, folderId]);

  const getProofs = useCallback(() => {
    if (selectedEntryId) {
      dispatch(getProofsByEntryIdThunk(selectedEntryId));
    }
  }, [dispatch, selectedEntryId]);

  useEffect(() => {
    getEntries();
  }, [getEntries]);

  useEffect(() => {
    const defaultEntryId = getDefaultEntry(entryId, entries?.[0]?.id, folderId, assignedEntry);

    if (!selectedEntryId && defaultEntryId) {
      setSelectedEntryId(defaultEntryId);
    }
  }, [entryId, entries, assignedEntry, folderId, selectedEntryId]);

  useEffect(() => {
    getProofs();
  }, [getProofs]);

  const onChangeSelectedEntry = useCallback((entryId: string) => {
    setSelectedEntryId(entryId);
  }, []);

  return {
    ...entryModalsState,
    ...proofState,
    selectedEntryId,
    onChangeSelectedEntry
  };
};
