/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Components
import Button, {
  ButtonColor,
  ButtonDisabledStyle,
  ButtonSize,
  ButtonVariant
} from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import SkeletonPiece, { SkeletonPieceColor } from 'src/components/atoms/SkeletonPiece';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { FilterPanel } from './FilterPanel';
import { ButtonContainer, Wrapper } from './styles';

// Types
import { DataChecked, FilterData, FilterProps, FilterStatus } from './types';

const getCheckedValues = (items: DataChecked) =>
  items
    ?.filter((item) => item.isChecked)
    ?.map((item) => item.value)
    ?.join('|');

const compareCheckedValues = (initialValue: DataChecked, value: DataChecked) => {
  const current = getCheckedValues(initialValue);
  const selected = getCheckedValues(value);

  if (current === selected || (!current && !selected)) return false;

  return !!((!current && selected) || (current && !selected) || (current && selected));
};

const getSelectedData = ({ radioGroup, checkboxGroup, startDate, endDate }: FilterData) => ({
  status: getCheckedValues(radioGroup),
  results: getCheckedValues(checkboxGroup),
  startDate,
  endDate
});

const Filter: FC<FilterProps> = ({
  filterText,
  dateFilterTitle,
  data,
  selected,
  onSelect,
  isDisabled,
  isLoading
}) => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<FilterStatus>('normal');
  const [value, setValue] = useState(data);

  useEffect(() => {
    setValue(data);
  }, [data]);

  const formProps = useForm<FilterData>({
    mode: 'onSubmit',
    values: value,
    defaultValues: data
  });

  const values = formProps.watch();

  const checkboxGroupIsNotDirty = useCallback(
    () => compareCheckedValues(value?.checkboxGroup, values?.checkboxGroup),
    [value, values]
  );

  const radioButtonGroupIsNotDirty = useCallback(
    () => compareCheckedValues(value?.radioGroup, values?.radioGroup),
    [value, values]
  );

  const dateRangeIsNotDirty = useCallback(() => {
    const currentStartDate = value?.startDate;
    const currentEndDate = value?.endDate ?? '';

    const selectedStartDate = values?.startDate;
    const selectedEndDate = values?.endDate ?? '';

    const isEqualStartDate = currentStartDate === selectedStartDate;
    const isEqualEndDate = currentEndDate === selectedEndDate;

    const isEmptyStartDate = !currentStartDate && !selectedStartDate;
    const isEmptyEndDate = !currentEndDate && !selectedEndDate;

    if (!isEqualStartDate || !isEqualEndDate) return true;
    if (isEmptyStartDate && isEmptyEndDate) return false;

    return false;
  }, [value, values]);

  const enabled = useMemo(
    () => checkboxGroupIsNotDirty() || radioButtonGroupIsNotDirty() || dateRangeIsNotDirty(),
    [values, selected]
  );

  const active = useMemo(
    () => !!Object.values(selected || {})?.filter((value) => Boolean(value))?.length,
    [selected]
  );

  useEffect(() => {
    if (open) {
      setStatus(active ? 'full' : 'selected');
    } else {
      setStatus(active ? 'active' : 'normal');
    }
  }, [open, active]);

  useEffect(() => {
    const radioGroup = values?.radioGroup?.map((item) => ({
      ...item,
      isChecked: item.value === selected?.status
    }));

    const checkboxGroup = values?.checkboxGroup?.map((item) => ({
      ...item,
      isChecked: selected?.results?.split('|')?.includes(item.value) || false
    }));

    const startDate = selected?.startDate || '';
    const endDate = selected?.endDate || '';

    formProps.setValue('radioGroup', radioGroup);
    formProps.setValue('checkboxGroup', checkboxGroup);
    formProps.setValue('startDate', startDate);
    formProps.setValue('endDate', endDate);

    setValue({ radioGroup, checkboxGroup, startDate, endDate });
  }, [open, selected]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleApply = (value: FilterData) => {
    const selected = getSelectedData(value);

    onSelect(selected);
    handleClose();
  };

  const handleClear = () => {
    onSelect({});
    formProps.reset();
    formProps.clearErrors();
    handleClose();
  };

  const handleCancel = () => {
    const selected = getSelectedData(value);

    onSelect(selected);
    formProps.reset();
    formProps.clearErrors();
    handleClose();
  };

  if (isLoading) {
    return (
      <SkeletonPiece
        width={120}
        height={40}
        color={SkeletonPieceColor.gray50}
        className="noPadding"
      />
    );
  }

  return (
    <Wrapper>
      <ButtonContainer className={status}>
        <Button
          name="button-filter"
          color={ButtonColor.tertiary}
          variant={ButtonVariant.outline}
          size={ButtonSize.normal}
          onClick={() => (open ? handleClose() : handleOpen())}
          isDisabled={isDisabled}
          disabledStyle={ButtonDisabledStyle.gray}
        >
          <Row justify="space-between" alignItems="center" gap={0.75}>
            <Text color="inherit" variant={TextVariant.small}>
              Filter
            </Text>
            <Icon icon="filter-list" />
          </Row>
        </Button>
      </ButtonContainer>
      {open && (
        <FormProvider {...formProps}>
          <FilterPanel
            filterText={filterText}
            dateFilterTitle={dateFilterTitle}
            enabledApply={enabled}
            onApply={formProps.handleSubmit(handleApply)}
            onCancel={handleCancel}
            onClear={handleClear}
          />
        </FormProvider>
      )}
    </Wrapper>
  );
};

export default Filter;
