const mergeArrayByKey = <T>(A: T[], B: T[], key: keyof T) => {
  const cleanData = B.map((t1) => ({
    ...t1,
    ...(A || []).find((t2) => t2[key] === t1[key])
  }));

  const ids = new Set(A.map((d) => d[key]));
  return [...A, ...cleanData.filter((d) => !ids.has(d[key]))];
};

export default mergeArrayByKey;
