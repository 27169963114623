import { configVars } from 'src/helpers';
import { URLS_SLUG } from '../constants';

const getPublicUrl = () => {
  if (window.location.origin.includes('localhost')) return window.location.origin;
  return configVars.document_portal;
};

const generateLink = (
  baseUrl: string,
  prefixUrl: string,
  propertyId: string,
  companyId: string
) => {
  if (companyId && propertyId && companyId !== '' && propertyId !== '') {
    return `${baseUrl}${prefixUrl}${companyId}/${propertyId}`;
  } else throw new Error('Company and/or property id is missing');
};

const getPrivateUrl = () => {
  if (window.location.origin.includes('localhost')) return window.location.origin;
  return configVars.frontend_url;
};

export const getPublicLink = (propertyId: string, companyId: string) => {
  return generateLink(getPublicUrl(), URLS_SLUG.applicant.link, propertyId, companyId);
};

export const getPrivateLink = (propertyId: string, companyId: string) => {
  return generateLink(getPrivateUrl(), URLS_SLUG.leasing_team.link, propertyId, companyId);
};
