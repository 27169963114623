// Vendor
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import { MultiTab } from 'src/components/molecules/MultiTab';
import {
  TabBody,
  TabContent,
  TabHeader,
  TabTitle
} from 'src/components/molecules/MultiTab/MultiTab';
import { ListView } from 'src/components/templates';
import { TeamsPerformancePage } from '../TeamsPerformancePage';
import TeamList from './components/TeamList';
import TeamsFilters from './components/TeamsFilters';

// Types
import { LocationState } from 'src/ts/interfaces';

const TeamsPage: React.FC = () => {
  const location = useLocation();
  const [tabActive, setTabActive] = useState(0);

  //todo: convert to launch darkly feature flag in the future if this feature gets assigned priority
  const flagTeamPerformanceEnabled = false;

  useEffect(() => {
    if ((location.state as LocationState)?.from === 'TeamsPerformanceReportPage') {
      // Move to Team Performance tab
      setTabActive(1);
    }
  }, [location.state]);

  return (
    <ListView title="Teams" filters={<TeamsFilters />}>
      <MultiTab
        name="teamsTab"
        selectedIndex={tabActive}
        hasSpacing
        onSelect={(index) => setTabActive(index as number)}
      >
        <TabHeader aria-label="Teams Tab">
          <TabTitle>Active</TabTitle>
          {flagTeamPerformanceEnabled && <TabTitle>Team Performance</TabTitle>}
        </TabHeader>
        <TabBody>
          <TabContent>
            <TeamList />
          </TabContent>
          {flagTeamPerformanceEnabled && (
            <TabContent>
              <TeamsPerformancePage />
            </TabContent>
          )}
        </TabBody>
      </MultiTab>
    </ListView>
  );
};

export default TeamsPage;
