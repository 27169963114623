import { useSelector } from 'react-redux';

import { useAppDispatch } from 'src/store';
import { actions, selectors } from '../index';
import { fdeInboxTabs } from 'ts/enums';

const useSetFdeInbox = () => {
  const dispatch = useAppDispatch();
  const { filters } = useSelector(selectors);

  const filtering = (filter?: string) => {
    dispatch(actions.filtering({ filter, page: 0, rowsPerPage: filters?.rowsPerPage }));
  };

  const sorting = (sort?: string) => {
    dispatch(actions.sorting({ sort, page: 0, rowsPerPage: filters?.rowsPerPage }));
  };

  const searching = (q?: string) => {
    dispatch(actions.search({ q, page: 0, rowsPerPage: filters?.rowsPerPage }));
  };

  const paginating = (page: number, rowsPerPage: number) => {
    dispatch(actions.pagination({ page, rowsPerPage }));
  };

  const setSelectedTab = (selectedTab: fdeInboxTabs) => {
    dispatch(actions.setSelectedTab(selectedTab));
  };

  return {
    filtering,
    sorting,
    searching,
    paginating,
    setSelectedTab
  };
};

export default useSetFdeInbox;
