// Vendor
import PropTypes from 'prop-types';

// Components
import { Row } from 'src/components/atoms/Row';
import BoxProof from '../BoxProof/BoxProof';
import { UploadFile } from '../UploadFile';
import { Container, Content } from './styles';

// Types
import { IProofDUP } from 'src/features/DUP/proofs/ts/interfaces';

// Enums
import { DupTypes } from 'src/features/DUP/ts/enums';

export type UploadFilesProps = {
  type: DupTypes;
  proofs: IProofDUP[];
};
const UploadFiles: React.FC<UploadFilesProps> = ({ type = DupTypes.APPLICANT, proofs = [] }) => {
  return (
    <Container type={type}>
      <Content>
        {proofs.length > 0 ? (
          <Row columns={3} gap={3} wrap="wrap">
            {proofs.map((proof: IProofDUP, index: number) => {
              return (
                <Row.Col size={1} key={proof.id}>
                  <BoxProof num={index + 1} proof={proof} type={type} />
                </Row.Col>
              );
            })}
            <Row.Col size={1}>
              <UploadFile type={type} isCell />
            </Row.Col>
          </Row>
        ) : (
          <UploadFile type={type} />
        )}
      </Content>
    </Container>
  );
};

UploadFiles.propTypes = {
  type: PropTypes.oneOf<DupTypes>(Object.values(DupTypes)).isRequired,
  proofs: PropTypes.any.isRequired
};

export default UploadFiles;
