import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  text-wrap: nowrap;
`;

export const Container = styled.div`
  min-width: 14rem;
  padding-top: 0.5rem;
  position: absolute;
  right: 0;
  z-index: 1;

  .CBody {
    padding: 1rem;
  }
`;

export const DDButtonContainer = styled.div`
  [aria-label='dropdown-button-sort'] {
    > div {
      display: flex;
      height: 2.55rem;
      width: 100%;
    }
  }

  &.active {
    .DDButton {
      color: ${({ theme }) => theme.color.blue[700]};
      border-color: ${({ theme }) => theme.color.gray[200]};
    }
  }

  &.selected {
    .DDButton {
      border-color: ${({ theme }) => theme.color.blue[700]};
    }
  }

  &.full {
    .DDButton {
      color: ${({ theme }) => theme.color.blue[700]};
      border-color: ${({ theme }) => theme.color.blue[700]};
    }
  }

  .DDButton {
    color: ${({ theme }) => theme.color.gray[600]};
    min-width: 13.5rem;
    border-color: ${({ theme }) => theme.color.gray[200]};
    border-radius: 0.125rem;

    small {
      font-weight: 500;
    }

    &[aria-disabled='false'] {
      :hover {
        background: ${({ theme }) => theme.color.gray[100]};
        color: ${({ theme }) => theme.color.gray[600]};
        border-color: ${({ theme }) => theme.color.gray[600]};
      }
    }

    &[aria-disabled='true'] {
      cursor: default;
    }
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: auto;
  padding-inline-start: 0;
  list-style: none;
  margin: 0 -1rem;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
  ${({ theme }) => theme.font.small};
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: ${({ theme }) => theme.color.gray[900]};

  &.selected {
    ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.color.blue[700]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.blue[100]};
  }

  & svg {
    color: ${({ theme }) => theme.color.blue[700]};
  }
`;
