import { createAsyncThunk } from '@reduxjs/toolkit';
import getErrorMessageThunk from 'src/helpers/getErrorMessageThunk';
import { getPaginationRequest, ITeam } from 'src/ts/interfaces';
import apiUsers from '../users/api';
import api from './api';
import { NAME } from './constants';

export const getAll = createAsyncThunk(`${NAME}/getAll`, async (data: getPaginationRequest) => {
  try {
    return await api.getAll(data);
  } catch (error) {
    console.error(`${NAME}/getAll`, error);
    throw new Error('Error fetching the teams');
  }
});

export const getOne = createAsyncThunk(`${NAME}/getOne`, async (teamId: string) => {
  try {
    return await api.getOne(teamId);
  } catch (error) {
    console.error(`${NAME}/getOne`, error);
    throw new Error('Error getting the team information');
  }
});

export const getAllFDEManagers = createAsyncThunk(
  `${NAME}/getAllFDEManagers`,
  async (data: getPaginationRequest) => {
    try {
      return await apiUsers.getAll(data);
    } catch (error) {
      console.error(`${NAME}/getAllFDEManagers`, error);
      throw new Error('Error fetching the FDEManagers');
    }
  }
);

export const create = createAsyncThunk(`${NAME}/create`, async (data: ITeam) => {
  try {
    return await api.create(data);
  } catch (error) {
    console.error(`${NAME}/create`, error);
    const errors = getErrorMessageThunk(error, 'errors');
    throw new Error(
      errors?.members
        ? 'Some members are already in other teams, please check once again'
        : "Team couldn't be created, please try again"
    );
  }
});

export const update = createAsyncThunk(`${NAME}/update`, async (data: ITeam) => {
  try {
    return await api.update(data);
  } catch (error) {
    console.error(`${NAME}/update`, error);
    const errors = getErrorMessageThunk(error, 'errors');
    throw new Error(
      errors?.members
        ? 'Some members are already in other teams, please check once again'
        : "Team couldn't be edited, please try again."
    );
  }
});

export const getAllSFA = createAsyncThunk(
  `${NAME}/getAllSFA`,
  async (data: getPaginationRequest) => {
    try {
      return await apiUsers.getAll(data);
    } catch (error) {
      console.error(`${NAME}/getAllSFA`, error);
      throw new Error('Error fetching the Senior Fraud Analysts');
    }
  }
);

export const getAllFA = createAsyncThunk(`${NAME}/getAllFA`, async (data: getPaginationRequest) => {
  try {
    return await apiUsers.getAll(data);
  } catch (error) {
    console.error(`${NAME}/getAllFA`, error);
    throw new Error('Error fetching the Fraud Analysts');
  }
});

export const getAllTeamsPerformance = createAsyncThunk(
  `${NAME}/getAllTeamsPerformance`,
  async (data: getPaginationRequest) => {
    try {
      return await api.getAll(data);
    } catch (error) {
      console.error(`${NAME}/getAllTeamsPerformance`, error);
      throw new Error('Error fetching the teams for performance');
    }
  }
);
