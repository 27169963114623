import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { NAME } from './constants';
import {
  createReducer,
  getAllFAReducer,
  getAllFDEManagersReducer,
  getAllReducer,
  getAllSFAReducer,
  getAllTeamsPerformanceReducer,
  getOneReducer,
  initialState,
  StateData,
  updateReducer
} from './reducers';

const teamsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetGetAll: (state: StateData) => {
      state.getAll = initialState.getAll;
    },
    resetGetOne: (state: StateData) => {
      state.getOne = initialState.getOne;
    },
    resetGetFDEManagers: (state: StateData) => {
      state.getAllFDEManagers = initialState.getAllFDEManagers;
    },
    resetCreate: (state: StateData) => {
      state.create = initialState.create;
    },
    resetUpdate: (state: StateData) => {
      state.update = initialState.update;
    },
    resetGetAllSFA: (state: StateData) => {
      state.getAllSFA = initialState.getAllSFA;
    },
    resetGetAllFA: (state: StateData) => {
      state.getAllFA = initialState.getAllFA;
    },
    resetGetAllTeamsPerformance: (state: StateData) => {
      state.getAllTeamsPerformance = initialState.getAllTeamsPerformance;
    }
  },
  extraReducers: (builder) => {
    getAllReducer(builder);
    getOneReducer(builder);
    getAllFDEManagersReducer(builder);
    createReducer(builder);
    updateReducer(builder);
    getAllSFAReducer(builder);
    getAllFAReducer(builder);
    getAllTeamsPerformanceReducer(builder);
  }
});

export const selectors = {
  getAll: {
    isLoading: (state: RootState) => state[NAME].getAll.status === StatusTypes.LOADING,
    isIdle: (state: RootState) => state[NAME].getAll.status === StatusTypes.IDLE,
    filter: (state: RootState) => state[NAME].getAll.filter,
    error: (state: RootState) => state[NAME].getAll.error,
    data: (state: RootState) => state[NAME].getAll.data || initialState.getAll.data,
    count: (state: RootState) => state[NAME].getAll.count || 0
  },
  getOne: {
    isLoading: (state: RootState) => state[NAME].getOne.status === StatusTypes.LOADING,
    isIdle: (state: RootState) => state[NAME].getOne.status === StatusTypes.IDLE,
    error: (state: RootState) => state[NAME].getOne.error,
    data: (state: RootState) => state[NAME].getOne.data
  },
  getAllFDEManagers: {
    isLoading: (state: RootState) => state[NAME].getAllFDEManagers.status === StatusTypes.LOADING,
    isIdle: (state: RootState) => state[NAME].getAllFDEManagers.status === StatusTypes.IDLE,
    error: (state: RootState) => state[NAME].getAllFDEManagers.error,
    data: (state: RootState) =>
      state[NAME].getAllFDEManagers.data || initialState.getAllFDEManagers.data,
    count: (state: RootState) => state[NAME].getAllFDEManagers.count || 0
  },
  create: {
    isLoading: (state: RootState) => state[NAME].create.status === StatusTypes.LOADING,
    isSaved: (state: RootState) => state[NAME].create.status === StatusTypes.SUCCESS,
    error: (state: RootState) => state[NAME].create.error
  },
  update: {
    isLoading: (state: RootState) => state[NAME].update.status === StatusTypes.LOADING,
    isUpdated: (state: RootState) => state[NAME].update.status === StatusTypes.SUCCESS,
    error: (state: RootState) => state[NAME].update.error
  },
  getAllSFA: {
    isLoading: (state: RootState) => state[NAME].getAllSFA.status === StatusTypes.LOADING,
    isIdle: (state: RootState) => state[NAME].getAllSFA.status === StatusTypes.IDLE,
    filter: (state: RootState) => state[NAME].getAllSFA.filter,
    error: (state: RootState) => state[NAME].getAllSFA.error,
    data: (state: RootState) => state[NAME].getAllSFA.data || initialState.getAllSFA.data || [],
    count: (state: RootState) => state[NAME].getAllSFA.count || 0
  },
  getAllFA: {
    isLoading: (state: RootState) => state[NAME].getAllFA.status === StatusTypes.LOADING,
    isIdle: (state: RootState) => state[NAME].getAllFA.status === StatusTypes.IDLE,
    filter: (state: RootState) => state[NAME].getAllFA.filter,
    error: (state: RootState) => state[NAME].getAllFA.error,
    data: (state: RootState) => state[NAME].getAllFA.data || initialState.getAllFA.data,
    count: (state: RootState) => state[NAME].getAllFA.count || 0
  },
  getAllTeamsPerformance: {
    isLoading: (state: RootState) =>
      state[NAME].getAllTeamsPerformance.status === StatusTypes.LOADING,
    isIdle: (state: RootState) => state[NAME].getAllTeamsPerformance.status === StatusTypes.IDLE,
    filter: (state: RootState) => state[NAME].getAllTeamsPerformance.filter,
    error: (state: RootState) => state[NAME].getAllTeamsPerformance.error,
    data: (state: RootState) =>
      state[NAME].getAllTeamsPerformance.data || initialState.getAllTeamsPerformance.data,
    count: (state: RootState) => state[NAME].getAllTeamsPerformance.count || 0
  }
};

export const { reducer, actions } = teamsSlice;
