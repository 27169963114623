import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;

  @media ${({ theme }) => theme.device.desktop} {
    align-items: center;
  }
`;

export const Wrapper = styled.div`
  margin: 0;
  padding: 0;

  @media ${({ theme }) => theme.device.desktop} {
    margin: auto;
    width: 38.625rem;
  }
`;

export const Content = styled.div`
  background: ${(props) => props.theme.color.white};
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.05), 0 1.5625rem 2.1875rem rgba(0, 0, 0, 0.03);
  padding: 1.5rem;
`;
