// Vendor
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Redux
import { selectors as apiUserSelectors } from 'src/features/auth/authSlice';
import { actions, selectors as companySelector } from 'src/features/company/companySlice';
import store, { useAppDispatch } from 'src/store';
import { getAll, getOne, update } from '../services';

// Types
import { getPaginationRequest, ICompany } from 'src/ts/interfaces';

const useCompany = () => {
  const { companyId } = useParams();
  const dispatch = useAppDispatch();
  const apiUser = useSelector(apiUserSelectors.user);

  const onGetAll = useCallback((args: getPaginationRequest) => dispatch(getAll(args)), [dispatch]);

  const onResetGetAll = () => {
    dispatch(actions.resetGetAll());
  };

  const onGetOne = useCallback(
    () => dispatch(getOne(apiUser?.company_id ?? '')),
    [apiUser?.company_id, dispatch]
  );

  const onGetOneById = useCallback(
    async (id: string) => {
      if (!id) throw new Error('The company id is missing');
      await dispatch(getOne(id));
    },
    [dispatch]
  );

  const onSave = (dataForm: ICompany) => {
    const id = companyId || apiUser?.company_id;
    if (!id) throw new Error('The company id is missing');

    dispatch(update({ companyId: id, data: dataForm }));
  };

  const onResetUpdate = () => {
    dispatch(actions.resetSave());
    if (companyId) {
      onGetOneById(companyId);
    } else {
      onGetOne();
    }
  };

  const onSearch = (q: string, filter?: string) => {
    const currentFilter = companySelector.getAll.filter(store.getState());
    const search = encodeURIComponent(q);
    dispatch(getAll({ ...currentFilter, filter, page: 0, q: search }));
  };

  return {
    onGetAll,
    onResetGetAll,
    onGetOne,
    onGetOneById,
    onSave,
    onResetUpdate,
    onSearch
  };
};

export default useCompany;
