import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { IAppVersion, IRejectedAction } from 'src/ts/interfaces';
import { NAME } from './constants';
import { fetchAppVersion } from './services';

type AppVersionState = {
  appVersion: IAppVersion | null;
  status: StatusTypes;
  error?: string | null;
};

const initialState: AppVersionState = {
  appVersion: null,
  status: StatusTypes.IDLE,
  error: null
};

const appVersionSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    fetchAppVersionReducer(builder);
  }
});

const fetchAppVersionReducer = (builder: ActionReducerMapBuilder<AppVersionState>) => {
  builder.addCase(fetchAppVersion.pending, (state) => {
    state.status = StatusTypes.LOADING;
    state.error = null;
  });
  builder.addCase(fetchAppVersion.rejected, (state, action: IRejectedAction) => {
    state.status = StatusTypes.ERROR;
    state.error = action.error?.message;
  });
  builder.addCase(fetchAppVersion.fulfilled, (state, action) => {
    state.status = StatusTypes.SUCCESS;
    state.appVersion = action?.payload;
  });
};

export const selectors = {
  appVersion: (state: RootState) => state[NAME].appVersion,
  status: (state: RootState) => state[NAME].status,
  error: (state: RootState) => state[NAME].error
};

export const { reducer, actions } = appVersionSlice;
