import { createAsyncThunk } from '@reduxjs/toolkit';

import api from './api';
import { NAME } from './constants';

export const getMatchingEntriesByEntryIdThunk = createAsyncThunk(
  `${NAME}/getMatchingEntriesByEntryId`,
  async (entryId: string) => {
    try {
      return await api.getMatchingEntriesByEntryId(entryId);
    } catch (error) {
      console.error(`${NAME}/getMatchingEntriesByEntryId`, error);
      throw new Error('Failed to get matching entries');
    }
  }
);
