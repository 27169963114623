import styled from 'styled-components/macro';

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.color.gray[100]};
  width: 100%;
  border-radius: 4px;
  padding: 16px;
`;

export const SubContainer = styled.div`
  padding-top: 0.2rem;
  padding-left: 2rem;
`;
