import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  > div:first-of-type {
    padding-left: 0;
  }

  .SubmissionsTabLoader__Container {
    > div {
      &:nth-child(even) {
        background: ${({ theme }) => theme.color.gray[100]};
      }

      &:nth-child(odd) {
        background: ${({ theme }) => theme.color.gray[50]};
      }
    }
  }

  .SubmissionsTabLoader__Item {
    flex-direction: row !important;
    padding: 0.875rem 2.5rem;
    background-color: transparent;

    > div {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .SubmissionsTabLoader__Buttons {
    flex-direction: row !important;
    margin: 0;

    > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;
