import { AxiosInstance } from 'axios';
import { configVars } from 'src/helpers';
import { FeatureFlagApi } from 'src/lib/internal/feature-flag-api/gen';
import { defaultInstance, unauthenticatedSessionInstance } from './instance';

const commonParams: [undefined, string, AxiosInstance] = [
  undefined,
  configVars.feature_flag_api_url,
  defaultInstance
];

export const featureFlagApi = new FeatureFlagApi(...commonParams);

export const unauthenticatedFeatureFlagApi = new FeatureFlagApi(
  undefined,
  configVars.feature_flag_api_url,
  unauthenticatedSessionInstance
);
