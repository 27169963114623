// Vendor
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import Text from 'src/components/atoms/Text';
import { ShowState } from 'src/components/molecules/ShowState';
import Modal, { ModalVariant } from 'src/components/organisms/Modal';
import { PropertyListLoader } from './PropertyListLoader';
import { PropertyRow } from './PropertyRow';

// Hooks
import useProperty from 'src/features/property/hooks/useProperty';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';

// Redux
import { selectors as propertySelectors } from 'src/features/property/propertySlice';

// Enums
import { PropertyStatus } from 'src/ts/enums';

// Types
import { IPropertyForList } from 'src/ts/interfaces';

const PropertyList: React.FC = () => {
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();

  const [showModalUpdateStatus, setShowModalUpdateStatus] = useState(false);
  const [propertySelected, setPropertySelected] = useState({ id: '', status: '' });

  const {
    onGetAll: onGetProperties,
    onResetUpdateStatus,
    onUpdatePropertyStatus,
    formatStatusPayload
  } = useProperty();

  const getPropertiesData = useSelector(propertySelectors.getAll.data);
  const getPropertiesIsIdle = useSelector(propertySelectors.getAll.isIdle);
  const getPropertiesIsLoading = useSelector(propertySelectors.getAll.isLoading);
  const getPropertiesError = useSelector(propertySelectors.getAll.error);
  const getPropertiesFilter = useSelector(propertySelectors.getAll.filter);

  const updateStatusIsSuccessful = useSelector(propertySelectors.updateStatus.isUpdated);
  const updateStatusIsLoading = useSelector(propertySelectors.updateStatus.isLoading);
  const updateStatusError = useSelector(propertySelectors.updateStatus.error);

  useEffect(() => {
    if (getPropertiesIsIdle) {
      onGetProperties();
    }
  }, [onGetProperties, getPropertiesIsIdle]);

  useEffect(() => {
    if (updateStatusError !== null) {
      onResetUpdateStatus();
      setShowModalUpdateStatus(false);
      showSnackbar(VariantType.error, 'Something was wrong trying to update the status');
    } else {
      if (!updateStatusIsLoading && updateStatusIsSuccessful) {
        onResetUpdateStatus();
        setShowModalUpdateStatus(false);
        showSnackbar(VariantType.success, 'Property has been updated successfully');
      }
    }
  }, [
    SnackTypes,
    VariantType,
    onResetUpdateStatus,
    showSnackbar,
    updateStatusIsLoading,
    updateStatusIsSuccessful,
    updateStatusError
  ]);

  const onUpdateStatus = (property: IPropertyForList) => {
    if (!property.id) return null;
    setPropertySelected({ id: property.id, status: property.status });
    setShowModalUpdateStatus(true);
  };

  const onUpdateStatusCancel = () => {
    setPropertySelected({ id: '', status: '' });
    setShowModalUpdateStatus(false);
  };

  if (getPropertiesError) {
    return (
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Please try again"
        message="Something went wrong"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (getPropertiesIsLoading) {
    return <PropertyListLoader showCopyButtonApplicant />;
  }

  if (getPropertiesData?.length === 0) {
    if (getPropertiesFilter.q) {
      return (
        <ShowState
          message="Try adjusting your search or filter to find<br/> what you are looking for"
          searchText={decodeURIComponent(getPropertiesFilter.q)}
          variant="search"
          type="searchEmpty"
        />
      );
    }
    return <ShowState message="There are no properties yet" type="user" variant="empty" />;
  }

  return (
    <>
      {getPropertiesData?.map((property: IPropertyForList) => (
        <PropertyRow
          key={property.id}
          id={property.id}
          short_id={property.short_id}
          company_short_id={property.company_short_id}
          name={property.name}
          company_name={property.company_name}
          address={property.address}
          unit={property.unit}
          status={property.status}
          city={property.city}
          state={property.state}
          zip={property.zip}
          logo={property.logo}
          is_linked_to_yardi={property.is_linked_to_yardi}
          onUpdateStatus={() => onUpdateStatus(property)}
          isDupDisabled={property.disable_dup_fd}
        />
      ))}
      <Modal
        title={
          propertySelected.status === PropertyStatus.ACTIVE ? 'Disable Property' : 'Enable Property'
        }
        labelButtonCancel="Cancel"
        labelButtonConfirm={
          propertySelected.status === PropertyStatus.ACTIVE ? 'Disable' : 'Enable'
        }
        showModal={showModalUpdateStatus}
        isBackClosable={false}
        isEscapeClosable={false}
        isDisabledButtonCancel={updateStatusIsLoading}
        isDisabledButtonConfirm={updateStatusIsLoading}
        isLoadingButtonConfirm={updateStatusIsLoading}
        setShowModal={setShowModalUpdateStatus}
        variant={ModalVariant.danger}
        onConfirm={() => {
          const payload = formatStatusPayload(
            propertySelected.status === PropertyStatus.ACTIVE
              ? PropertyStatus.DISABLED
              : PropertyStatus.ACTIVE,
            propertySelected.id
          );
          onUpdatePropertyStatus(payload);
        }}
        onCancel={onUpdateStatusCancel}
      >
        {propertySelected.status === PropertyStatus.ACTIVE ? (
          <Text>
            Applicants will not be able to access the DUP. Are you sure you want to disable this
            property?
          </Text>
        ) : (
          <Text>
            Applicants will be able to access the DUP. Are you sure you want to enable this
            property?
          </Text>
        )}
      </Modal>
    </>
  );
};

export default PropertyList;
