// Vendor
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

// Components
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import { AutoSuggest } from 'src/components/molecules/AutoSuggest';

// Redux
import { selectors } from 'src/features/teams';

// Hooks
import useTeam from 'src/features/teams/hooks/useTeams';

// Helpers
import { formatLabelValue } from 'src/helpers';

// Types
import { ITeam, IValueLabel } from 'src/ts/interfaces';
import { PartialUser } from '../InvitationFlowPage';

type AddTeamFormProps = {
  onSave: (data: PartialUser) => void;
  onCancel: (data: PartialUser) => void;
  defaultValues: PartialUser;
  isDisabled?: boolean;
};

const AddTeamForm: React.FC<AddTeamFormProps> = ({
  onSave,
  onCancel,
  defaultValues,
  isDisabled
}: AddTeamFormProps) => {
  const [teamsSearch, setTeamsSearch] = useState<ITeam[]>([]);
  const [teamSelected, setTeamSelected] = useState<{ id: string; name: string } | null>(null);
  const { onGetAll: onGetTeams } = useTeam();

  const getTeamsData = useSelector(selectors.getAll.data);
  const getTeamsIsIdle = useSelector(selectors.getAll.isIdle);
  const getTeamsIsLoading = useSelector(selectors.getAll.isLoading);
  const getTeamsError = useSelector(selectors.getAll.error);

  useEffect(() => {
    if (getTeamsIsIdle) {
      onGetTeams();
    }
  }, [onGetTeams, getTeamsIsIdle]);

  useEffect(() => {
    if (getTeamsData) {
      setTeamsSearch(getTeamsData);
    }
  }, [getTeamsData]);

  const methods = useForm({
    reValidateMode: 'onSubmit',
    defaultValues
  });

  const onFormSubmit = (data: PartialUser) => {
    const updatedData = { ...data, team_id: teamSelected?.id, team_name: teamSelected?.name };
    onSave(updatedData);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onFormSubmit)}>
        <Controller
          name="team_id"
          control={methods.control}
          render={({ field: { name, value } }) => (
            <AutoSuggest
              name={name}
              data={
                formatLabelValue({ data: teamsSearch, value: 'id', label: 'name' }) as IValueLabel[]
              }
              placeholder="Team"
              initialLabel={
                defaultValues?.team_id
                  ? getTeamsData?.find((team) => team.id === defaultValues?.team_id)?.name
                  : ''
              }
              onChange={({ value }) => {
                if (typeof value !== 'string') {
                  return;
                }
                // Search teams by name
                const filteredTeams = getTeamsData?.filter((team) =>
                  team?.name?.toLowerCase().includes(value.toLowerCase())
                );
                if (filteredTeams) {
                  setTeamsSearch(filteredTeams);
                }
              }}
              onSelect={({ value: id, label: name }) => {
                setTeamSelected({ id, name });
              }}
              onClear={() => {
                setTeamsSearch(getTeamsData || []);
              }}
              isLoading={getTeamsIsLoading}
              hasError={getTeamsError !== null}
            />
          )}
        />
        <Gap height={1} />
        <Row gap={1}>
          <Button
            name="continue_button"
            variant={ButtonVariant.contained}
            color={ButtonColor.primary}
            size={ButtonSize.medium}
            type={ButtonType.submit}
          >
            Continue
          </Button>
          <Button
            name="cancel_button"
            variant={ButtonVariant.outline}
            color={ButtonColor.primary}
            size={ButtonSize.medium}
            type={ButtonType.button}
            onClick={() => onCancel(defaultValues)}
          >
            Cancel
          </Button>
        </Row>
      </form>
    </FormProvider>
  );
};

export default AddTeamForm;
