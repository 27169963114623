import Button from 'src/components/atoms/Button';
import { Card } from 'src/components/atoms/Card';
import { Icon } from 'src/components/atoms/Icon';
import Text from 'src/components/atoms/Text';
import styled from 'styled-components/macro';

export const StyledCard = styled(Card)`
  & > div {
    padding: 1.5rem;
  }
`;

export const HeaderTitleBox = styled.div`
  & > div:first-child {
    margin-bottom: 1rem;
  }
`;

export const EditActionBox = styled.div`
  margin-left: auto;
`;

export const StyledButton = styled(Button)`
  height: 2.5rem;
  width: 5rem;
  color: ${({ theme }) => theme.color.blue[700]};
  border: unset;

  &:hover {
    background: ${({ theme }) => theme.color.gray[200]};
    color: ${({ theme }) => theme.color.blue[700]};
  }
`;

export const StyledText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline;
  max-width: 250px;
`;

export const PersonIcon = styled(Icon).attrs((attrs) => ({
  icon: 'person-icon',
  color: 'muted'
}))`
  width: 1.875rem;
`;

export const CompanyIcon = styled(Icon).attrs((attrs) => ({
  icon: 'company-model-icon',
  color: 'muted'
}))`
  width: 1.875rem;
`;

export const EditIcon = styled(Icon).attrs((attrs) => ({
  icon: 'edit',
  color: 'primary'
}))`
  width: 1.875rem;
  justify-content: flex-end;
`;
