/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import InputText, { InputTextType } from 'src/components/atoms/InputText/InputText';
import { Row } from 'src/components/atoms/Row';
import Dropdown from 'src/components/molecules/Dropdown/Dropdown';
import useInvitationGetByEmail from 'src/features/invitation/hooks/useInvitationGetByEmail';
import useUserGetByEmail from 'src/features/users/hooks/useUserGetByEmail';
import { validateName } from 'src/helpers';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import { Roles as Role } from 'src/ts/enums';
import { IValueLabel } from 'src/ts/interfaces';
import { PartialUser } from '../InvitationFlowPage';

type UserInfoFormProps = {
  onSave: (data: PartialUser) => void;
  roles: IValueLabel[] | [];
  defaultValues: PartialUser;
  onGetRoleSelected: (role: Role) => void;
};

const UserInfoForm: React.FC<UserInfoFormProps> = ({
  onSave,
  roles,
  defaultValues,
  onGetRoleSelected
}: UserInfoFormProps) => {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues
  });

  const { formState, setError, getValues, handleSubmit, control, setValue } = methods;

  const {
    getUserByEmail: getByEmailUser,
    onGetByEmail: onGetByEmailUser,
    onResetGetByEmail: onResetGetByEmailUser
  } = useUserGetByEmail();
  const {
    getByEmail: getByEmailInvitation,
    onGetByEmail: onGetByEmailInvitation,
    onResetGetByEmail: onResetGetByEmailInvitation
  } = useInvitationGetByEmail();
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();
  const [validationPassed, setValidationsPassed] = useState({ users: false, invitations: false });

  const handleSave = (data: PartialUser) => {
    setValidationsPassed({ users: false, invitations: false });

    onGetByEmailUser(data.email || '');
  };

  useEffect(() => {
    if (!formState.isSubmitted) return;

    if (getByEmailUser.isSuccessful) {
      if (getByEmailUser.data?.length) {
        setError('email', { type: 'custom', message: 'User exists' });
        showSnackbar(
          VariantType.error,
          'A user with this email address already exists',
          SnackTypes.none
        );
        onResetGetByEmailUser();
      } else {
        setValidationsPassed({ ...validationPassed, users: true });
        const formData = getValues();
        onGetByEmailInvitation(formData.email || '');
      }
    }
  }, [getByEmailUser.data, getByEmailUser.isSuccessful, formState.isSubmitted]);

  useEffect(() => {
    if (!formState.isSubmitted) return;

    if (getByEmailInvitation.isSuccessful) {
      if (getByEmailInvitation.data?.length) {
        setError('email', { type: 'custom', message: 'Invitation exists' });
        showSnackbar(
          VariantType.error,
          'An invitation with this email address already exists',
          SnackTypes.none
        );
        onResetGetByEmailInvitation();
      } else {
        setValidationsPassed({ ...validationPassed, invitations: true });
      }
    }
  }, [getByEmailInvitation.data, getByEmailInvitation.isSuccessful, formState.isSubmitted]);

  useEffect(() => {
    if (Object.values(validationPassed).every((val) => val === true)) {
      onSave({
        ...getValues(),
        company_id: defaultValues.company_id,
        company: defaultValues.company
      });
    }
  }, [validationPassed]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)} noValidate>
        <Controller
          name="role"
          control={control}
          rules={{ required: 'Complete this field' }}
          defaultValue={defaultValues.role}
          render={({ field: { name, value, onChange } }) => (
            <Dropdown
              valueInitial={value}
              data={roles}
              onChange={({ [name]: selectedOption }) => {
                onChange(selectedOption.selected.value);
                onGetRoleSelected(selectedOption.selected.value);
                setValue('company', undefined);
                setValue('company_id', undefined);
                setValue('properties', undefined);
              }}
              name="role"
              placeholder="Role"
              size="md"
            />
          )}
        />
        <Gap height={1.5} />

        <InputText
          placeholder="First Name"
          name="first_name"
          type={InputTextType.text}
          defaultValue={defaultValues.first_name}
          config={{
            required: 'Complete this field',
            maxLength: {
              value: 100,
              message: '100 Characters Maximum'
            },
            validate: {
              noSpecialCharacters: (value) => validateName(value)
            }
          }}
        />

        <InputText
          placeholder="Last Name"
          name="last_name"
          type={InputTextType.text}
          defaultValue={defaultValues.last_name}
          config={{
            required: 'Complete this field',
            maxLength: {
              value: 100,
              message: '100 Characters Maximum'
            },
            validate: {
              noSpecialCharacters: (value) => validateName(value)
            }
          }}
        />

        <InputText
          placeholder="Email"
          name="email"
          type={InputTextType.email}
          defaultValue={defaultValues.email}
          config={{
            required: 'Complete this field'
          }}
        />

        <Row gap={1}>
          <Button
            name="continue_button"
            variant={ButtonVariant.contained}
            color={ButtonColor.primary}
            size={ButtonSize.medium}
            type={ButtonType.submit}
            isDisabled={
              getByEmailUser.isLoading ||
              getByEmailInvitation.isLoading ||
              Boolean(Object.keys(formState.errors).length)
            }
            isLoading={getByEmailUser.isLoading || getByEmailInvitation.isLoading}
          >
            {getByEmailUser.isLoading || getByEmailInvitation.isLoading ? 'Checking' : 'Continue'}
          </Button>
        </Row>
      </form>
    </FormProvider>
  );
};

export default UserInfoForm;
