// Vendor
import { Duration } from 'date-fns';
import { useEffect, useState } from 'react';

// Components
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';

// Helpers
import { getSLATimeLeft } from 'src/helpers';
import { IEntry } from 'src/ts/interfaces';

type TimeSLAProps = {
  entry: IEntry;
};

const TimeSLA: React.FC<TimeSLAProps> = (props: TimeSLAProps) => {
  const { entry } = props;

  const [timeLeft, setTimeLeft] = useState<Duration>({
    hours: 0,
    minutes: 0
  });

  // Effect to get the SLA time left and update it every minute
  useEffect(() => {
    if (entry && entry.submission_time) {
      const sla = getSLATimeLeft(entry.submission_time);
      setTimeLeft(sla);

      const timeLeftInterval = setInterval(() => {
        const sla = getSLATimeLeft(entry.submission_time);
        // remove interval if no time left
        const noTimeLeft = sla?.minutes && sla.minutes <= 0 && sla?.seconds && sla.seconds <= 0;
        if (noTimeLeft) {
          clearInterval(timeLeftInterval);
        }
        setTimeLeft(sla);
      }, 60000);

      return () => {
        clearInterval(timeLeftInterval);
      };
    }
  }, [entry]);

  return (
    <Row alignItems="center" gap={0.5}>
      <Text variant={TextVariant.span} color={TextColor.gray700} isBold>
        Time to SLA:
      </Text>
      <Text variant={TextVariant.span} color={TextColor.gray700}>
        {`${timeLeft.hours}h ${timeLeft.minutes}m`}
      </Text>
    </Row>
  );
};

export default TimeSLA;
