import PropTypes from 'prop-types';
import React from 'react';
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import Toggle from 'src/components/atoms/Toggle';
import Text, { TextColor, TextVariant } from '../../../../components/atoms/Text';

export type NotificationSettingsFormProps = {
  toggleName: string;
  onClick: () => void;
  isToggleDisabled: boolean;
  isToggleActive: boolean;
};

const NotificationSettingsForm: React.FC<NotificationSettingsFormProps> = (
  props: NotificationSettingsFormProps
) => {
  const { toggleName, isToggleDisabled, isToggleActive, onClick } = props;
  return (
    <>
      <Text variant={TextVariant.h3}>Notification Settings</Text>
      <Gap height={2} />
      <Row gap={1}>
        <Row.Col size="50%">
          <Text variant={TextVariant.h4} color={TextColor.initial}>
            Receive application notifications
          </Text>
        </Row.Col>
        <Row.Col size="50%">
          <Toggle
            name={toggleName}
            isActive={isToggleActive}
            isDisabled={isToggleDisabled}
            onClick={onClick}
          />
        </Row.Col>
      </Row>
    </>
  );
};

NotificationSettingsForm.propTypes = {
  toggleName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isToggleActive: PropTypes.bool.isRequired,
  isToggleDisabled: PropTypes.bool.isRequired
};

NotificationSettingsForm.defaultProps = {
  toggleName: 'notification',
  isToggleActive: false,
  isToggleDisabled: false
};

export default NotificationSettingsForm;
