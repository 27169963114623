// Vendor
import {
  ActionReducerMapBuilder,
  createDraftSafeSelector,
  createEntityAdapter,
  createSlice,
  Draft,
  EntityState,
  PayloadAction
} from '@reduxjs/toolkit';
import { EntrySendReportResponse } from '@snapptinc/fraud-platform';
import { EntrySendReport } from '@snapptinc/fraud-platform/api';
import { AxiosResponse } from 'axios';
import { compareDesc } from 'date-fns';

// Constants
import { NAME as FOLDERS_NAME } from 'src/features/folders/constants';
import { NAME } from './constants';

// Redux
import { foldersAdapter } from 'src/features/folders/foldersSlice';
import { getPendingReviewEntryThunk, requestReviewEntryThunk } from 'src/features/myWork/services';
import { RootState } from 'src/store';
import {
  getEntriesByFolderIdThunk,
  getEntryByIdThunk,
  mergeEntryThunk,
  requestDocumentsThunk,
  sendReportThunk,
  splitEntryThunk,
  updateEntryThunk
} from './services';

// Enums
import { Status as StatusEnum } from 'src/ts/enums';

// Types
import { IEntry, IRejectedAction, StatusType } from 'src/ts/interfaces';

interface StateData extends EntityState<IEntry> {
  status: StatusType;
  error?: string | null;
}

export const entriesAdapter = createEntityAdapter<IEntry>({
  selectId: (entry) => entry.id,
  sortComparer: (a, b) => compareDesc(new Date(a.submission_time), new Date(b.submission_time))
});

const initialState = entriesAdapter.getInitialState({
  status: StatusEnum.IDLE,
  error: null
});

const entriesSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    reset: () => {
      return {
        ...initialState
      };
    }
  },
  extraReducers: (builder) => {
    getEntriesByFolderIdReducer(builder);
    getEntryByIdReducer(builder);
    updateEntryReducer(builder);
    splitEntryReducer(builder);
    mergeEntryReducer(builder);
    reportReducer(builder);

    // Side effects to update entry references when using the my-work tab feature
    builder.addCase(getPendingReviewEntryThunk.fulfilled, (state: StateData, action) => {
      const entry = action.payload as IEntry;

      if (entry) {
        entriesAdapter.upsertOne(state, entry);
      }
    });

    builder.addCase(requestReviewEntryThunk.fulfilled, (state: StateData, action) => {
      const entry = action.payload as IEntry;

      if (entry) {
        entriesAdapter.upsertOne(state, entry);
      }
    });
  }
});

const getEntriesByFolderIdReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getEntriesByFolderIdThunk.pending, (state: StateData) => {
    state.status = StatusEnum.LOADING;
    state.error = null;
  });
  builder.addCase(getEntriesByFolderIdThunk.fulfilled, (state: StateData, action) => {
    const response = action.payload;
    const entries = (response?.data || []) as IEntry[];

    state.status = StatusEnum.SUCCESS;
    entriesAdapter.upsertMany(state, entries);
  });
  builder.addCase(
    getEntriesByFolderIdThunk.rejected,
    (state: StateData, action: IRejectedAction) => {
      state.status = StatusEnum.ERROR;
      state.error = action.error?.message;
    }
  );
};

const getEntryByIdReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getEntryByIdThunk.pending, (state: StateData) => {
    state.status = StatusEnum.LOADING;
    state.error = null;
  });
  builder.addCase(getEntryByIdThunk.fulfilled, (state: StateData, action) => {
    const response = action.payload;
    const entry = (response?.data || {}) as IEntry;

    entriesAdapter.upsertOne(state, entry);
    state.status = StatusEnum.SUCCESS;
  });
  builder.addCase(getEntryByIdThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.status = StatusEnum.ERROR;
    state.error = action.error?.message;
  });
};

const updateEntryReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(updateEntryThunk.pending, (state: StateData) => {
    state.error = null;
  });
  builder.addCase(updateEntryThunk.fulfilled, (state: StateData, action) => {
    const response = action.payload;
    const entry = response?.data as IEntry;

    entriesAdapter.upsertOne(state, entry || {});
    state.status = StatusEnum.SUCCESS;
  });
  builder.addCase(updateEntryThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.status = StatusEnum.ERROR;
    state.error = action.error?.message;
  });
};

const splitEntryReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(splitEntryThunk.pending, (state: StateData) => {
    state.error = null;
  });
  builder.addCase(splitEntryThunk.fulfilled, (state: StateData, action) => {
    const response = action.payload;
    const entry = response?.data as IEntry;

    state.status = StatusEnum.SUCCESS;
    entriesAdapter.upsertOne(state, entry);
  });
  builder.addCase(splitEntryThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.status = StatusEnum.ERROR;
    state.error = action.error?.message;
  });
};

const mergeEntryReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(mergeEntryThunk.pending, (state) => {
    state.error = null;
  });
  builder.addCase(mergeEntryThunk.fulfilled, (state, action) => {
    const response = action.payload;
    const entry = response?.data as IEntry;

    state.status = StatusEnum.SUCCESS;
    entriesAdapter.upsertOne(state, entry);
  });
  builder.addCase(mergeEntryThunk.rejected, (state, action: IRejectedAction) => {
    state.status = StatusEnum.ERROR;
    state.error = action.error?.message;
  });
};

const reportReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  const upsertEntry = (
    state: Draft<StateData>,
    action: PayloadAction<AxiosResponse<EntrySendReportResponse>>
  ) => {
    const response = action.payload;
    const { entry } = response?.data as EntrySendReport;

    entriesAdapter.upsertOne(state, (entry as IEntry) || {});
  };

  builder.addCase(sendReportThunk.pending, (state: StateData) => {
    state.error = null;
  });
  builder.addCase(sendReportThunk.fulfilled, upsertEntry);
  builder.addCase(sendReportThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.error = action.error?.message;
  });

  builder.addCase(requestDocumentsThunk.pending, (state: StateData) => {
    state.error = null;
  });
  builder.addCase(requestDocumentsThunk.fulfilled, upsertEntry);
  builder.addCase(requestDocumentsThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.error = action.error?.message;
  });
};

const selectAllByFolderId = createDraftSafeSelector(
  [
    entriesAdapter.getSelectors((state: RootState) => state[NAME]).selectAll,
    foldersAdapter.getSelectors((state: RootState) => state[FOLDERS_NAME]).selectById
  ],
  (entries, folder) => entries?.filter((entry) => entry.folder_id === folder?.id) || []
);

export const selectors = {
  ...entriesAdapter.getSelectors((state: RootState) => state[NAME]),
  selectAllByFolderId,
  status: (state: RootState) => state[NAME].status
};

export const { reducer, actions } = entriesSlice;
