import styled from 'styled-components/macro';

import Text from 'src/components/atoms/Text';

export const Head = styled.div`
  padding: 0.313rem 0.438rem;
  background: ${({ theme }) => theme.color.gray[200]};
`;

export const Body = styled.div`
  background: ${({ theme }) => theme.color.gray[50]};
  border: 0.125rem solid ${({ theme }) => theme.color.gray[200]};
  height: 22.5rem;
  padding: 1rem;
  overflow: auto;
`;

export const StyledText = styled(Text)`
  & > b {
    margin-right: 4px;
  }
`;
