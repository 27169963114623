// Vendor
import styled from 'styled-components';

type ErrorWarningMessageProps = {
  status: 'warning' | 'error';
};

export const Content = styled.div`
  padding: 1rem;
  max-width: 1700px;
  margin: 0 auto;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.5rem;
  }

  .income-verification-buttons {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    border-color: #1d4ed8;
    justify-content: center;
  }
`;
export const IncomeVerificationTiles = styled.div`
  padding: 1rem;
  max-width: 1700px;
  margin: 0 auto;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.5rem;
  }
  border: 11px solid ${({ theme }) => theme.color.blue[200]};
`;

export const IncomeResult = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  justify-content: center;
  display: flex;
`;

export const IncomeDataSummary = styled.div`
  text-align: center;
  justify-content: center;
  margin-bottom: 3rem;

  h2 {
    margin-bottom: 1.5rem;
  }
`;

export const ErrorWarningMessage = styled.div<ErrorWarningMessageProps>`
  color: ${({ theme, status }) =>
    status === 'warning' ? theme.color.black : theme.color.red[700]};
  max-width: 500px;
  margin: 0 auto;
`;
