import styled from 'styled-components/macro';

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;

  .Step__circle,
  .Step__label {
    display: flex;
    justify-content: center;
  }

  &[data-disabled='false'] {
    .Step__circle {
      cursor: pointer;
    }
  }

  .Step__helpText {
    .toolTip {
      z-index: 9999;
      .toolTipDirection {
        margin-bottom: -1rem;
        margin-left: 1rem;
      }
    }
  }
`;

export const HelpTextIcon = styled.div`
  display: block;
  ${({ theme }) => theme.font.small};
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.gray[300]};
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 50%;
  line-height: 1rem;
  margin-left: 0.2rem;

  &::before {
    content: '?';
  }
`;
