import { createAsyncThunk } from '@reduxjs/toolkit';
import { PropertyFeatureUpdatePayload } from 'src/ts/interfaces/propertyFeature';
import api from './api';
import { NAME } from './constants';

export const getAll = createAsyncThunk(`${NAME}/getAll`, async (propertyId: string) => {
  try {
    return await api.getAll(propertyId);
  } catch (error) {
    console.error(`${NAME}/getAll`, error);
    throw new Error('Error fetching the property features');
  }
});

export const propertyFeatureUpdateThunk = createAsyncThunk(
  `${NAME}/propertyFeatureUpdateThunk`,
  async (payload: PropertyFeatureUpdatePayload) => {
    const { propertyId, updatedFeatures } = payload;
    try {
      const calls = updatedFeatures.map((feature) => {
        return api.updateFeature(propertyId, feature);
      });
      return await Promise.all(calls);
    } catch (error) {
      console.error(`${NAME}/getAll`, error);
      throw new Error('Error Updating the property features');
    }
  }
);
