import { entryLogApi } from 'src/api';
import { getPaginationRequest } from 'src/ts/interfaces';

const api = {
  getEntriesLogs: ({ page, rowsPerPage, filter, sort, q }: getPaginationRequest) => {
    const pageNum = page?.toString();
    const rowsPerPageNum = rowsPerPage?.toString();
    return entryLogApi.getEntryLogs(pageNum, rowsPerPageNum, filter, sort, q);
  }
};

export default api;
