import { EntryResultEnum, ProofResultInsufficientReasonEnum } from 'src/ts/enums';
import { ProofResultInsufficientReasonType, ProofResultType } from 'src/ts/interfaces';

/**
 * We shouldn't be able to select doc type if the Result is "UNDETERMINED" and the Reason is "Invalid Document Type".
 * This function checks that
 */
export const canSelectDocType = (
  result?: ProofResultType,
  resultInsufficientReason?: ProofResultInsufficientReasonType
): boolean => {
  const invalidDocTypeSelected =
    resultInsufficientReason === ProofResultInsufficientReasonEnum.INVALID_DOCUMENT_TYPE;

  return !(invalidDocTypeSelected && result === EntryResultEnum.Undetermined);
};
