import { fdeFolderApi } from 'src/api';
import { FolderRequest } from '@snapptinc/fraud-platform';
import { IFolder } from 'src/ts/interfaces';

const api = {
  getFolderById(folderId: string) {
    return fdeFolderApi.getFolder(folderId);
  },
  updateFolder(folderId: string, folder: Partial<IFolder>) {
    return fdeFolderApi.updateFolder(folderId, { folder } as FolderRequest);
  }
};

export default api;
