import Text from 'src/components/atoms/Text';
import styled from 'styled-components/macro';

export const TextName = styled(Text)`
  overflow-wrap: anywhere;
`;

export const Container = styled.div`
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.color.gray[200]};
  padding: 18px 20px;

  .buttons {
    svg {
      width: 1.3rem;
    }
  }

  @media ${({ theme }) => theme.device.xs} {
    min-width: 100%;
  }
`;
