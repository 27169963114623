// Vendor
import { FC } from 'react';

// Components
import { Checkbox } from 'src/components/atoms/Checkbox';
import { Row } from 'src/components/atoms/Row';
import Tag from 'src/components/atoms/Tag';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { ButtonAction, StyledCard, StyledRowDivider, UpperInformation } from './styles';

// Helpers
import {
  getEntryStatusColorTag,
  getResultStatusColorTag,
  startCase,
  transformResultToReadable
} from 'src/features/fdeInbox/helpers';
import { dateFormat } from 'src/helpers';

// Types
import { IEntry } from 'src/ts/interfaces';

//Enums
import { FeatureFlagKey } from 'src/ts/enums';

//Hooks
import { useFeatureFlag } from 'src/hooks';

export interface PotentialMatchBoxProps {
  entry: Pick<
    IEntry,
    | 'folder_id'
    | 'folder'
    | 'applicant'
    | 'unit'
    | 'result'
    | 'status'
    | 'has_previously_submitted'
    | 'submission_time'
  >;
}

const PotentialMatchBox: FC<PotentialMatchBoxProps> = (props) => {
  const {
    entry: { folder, applicant, unit, result, status, has_previously_submitted, submission_time }
  } = props;

  const cleanProceedWithCautionFlagEnabled = useFeatureFlag(
    FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION
  );

  if (!applicant) {
    // TODO: Render Loading state for a specific matching box
    return null;
  }

  return (
    <StyledCard rounded border>
      <UpperInformation>
        <StyledRowDivider direction="column">
          <Row alignItems="center" justify="space-between">
            <Text variant={TextVariant.h3} isBold>
              {applicant.full_name}
            </Text>
            <Text variant={TextVariant.normal} color={TextColor.gray600}>
              {dateFormat(submission_time)}
            </Text>
          </Row>
          <Text variant={TextVariant.normal} color={TextColor.gray600}>
            {applicant.email}
          </Text>
          {applicant.phone && (
            <Text variant={TextVariant.normal} color={TextColor.gray600}>
              {applicant.phone}
            </Text>
          )}
        </StyledRowDivider>

        <StyledRowDivider direction="column">
          <Text variant={TextVariant.h4}>{folder?.property?.name}</Text>
          <Text variant={TextVariant.normal} color={TextColor.gray600}>
            Unit # {unit}
          </Text>
        </StyledRowDivider>

        <StyledRowDivider direction="column">
          <Text className="label-tag" variant={TextVariant.normal} color={TextColor.gray600}>
            Entry status
          </Text>
          <div className="tag-space">
            <Tag label={startCase(status)} color={getEntryStatusColorTag(status)} showBackground />
          </div>
          <Text className="label-tag" variant={TextVariant.normal} color={TextColor.gray600}>
            Entry results
          </Text>
          <div>
            <Tag
              label={transformResultToReadable(result)}
              color={getResultStatusColorTag(result, cleanProceedWithCautionFlagEnabled)}
              showBackground
            />
          </div>
        </StyledRowDivider>
      </UpperInformation>

      <ButtonAction>
        <Checkbox
          label="Has applied before"
          name="applied"
          value="true"
          isChecked={has_previously_submitted as boolean}
          isDisabled
        />
      </ButtonAction>
    </StyledCard>
  );
};

export default PotentialMatchBox;
