import { Checkbox } from 'src/components/atoms/Checkbox';
import { CheckboxData } from 'src/components/atoms/Checkbox/types';
import Text, { TextColor } from 'src/components/atoms/Text/';
import { ChoicesContainer } from '../styles';
import { listProps } from '../types';

const ChoicesBox = ({
  list,
  data,
  dataSelected,
  onSelected
}: {
  list: listProps;
  data: CheckboxData[];
  dataSelected: CheckboxData[];
  onSelected: (content: boolean) => void;
}) => {
  if (list.hasError) return null;

  return (
    <ChoicesContainer>
      <Checkbox
        name={`choices_${list.name}`}
        value="all"
        label="Choices"
        isNull={dataSelected.length > 0 && dataSelected.length !== data.length}
        isDisabled={data.length === 0 || list.isLoading || list.hasError}
        isChecked={data.length > 0 && dataSelected.length === data.length}
        onClick={(obj) => {
          onSelected(obj.isChecked);
        }}
      />
      <Text color={TextColor.muted} className="textChoicesSelected">
        {`${dataSelected.length}/${list.totalRows || data.length} selected`}
      </Text>
    </ChoicesContainer>
  );
};

export default ChoicesBox;
