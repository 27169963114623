import styled from 'styled-components/macro';

export const Container = styled.header`
  background: ${({ theme }) => theme.color.snappt[500]};
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
`;
