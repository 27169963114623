export const sliceArray = <T>(arr: T[], slices: number): T[][] => {
  const arrSliced: T[][] = [];

  for (let i = 0; i < arr.length; i += slices) {
    const slice = arr.slice(i, i + slices);
    arrSliced.push(slice);
  }

  return arrSliced;
};
