import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import Button, { ButtonColor, ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { ButtonRow } from 'src/components/molecules/Carousel/styles';
import useUserRoles from 'src/features/auth/hooks/useUserRoles';
import {
  calculateIncomeThunk,
  selectors as incomeVerificationSelectors
} from 'src/features/incomeVerification';
import { useProofDocumentTypes } from 'src/features/proofs/hooks';
import { useFeatureFlag } from 'src/hooks';
import IncomeCalculationTileForm from 'src/pages/IncomeCalculationPage/components/IncomeCalculationTileForm/IncomeCalculationTileForm';
import { FeatureFlagKey } from 'src/ts/enums';
import { IncomeVerificationEnum } from 'src/ts/enums/session';
import { ProofType } from 'src/ts/interfaces';
import { IncomeVerification } from 'src/ts/interfaces/incomeVerification';
import SendReportModal from './components/SendReportModal';
import {
  Content,
  ErrorWarningMessage,
  IncomeDataSummary,
  IncomeResult,
  IncomeVerificationTiles
} from './styles';

const getIncomeDocumentsFromSession = (
  incomeVerificationReviewId: string
): IncomeVerification[] => {
  const documentsFromSession = window.sessionStorage.getItem(
    `${IncomeVerificationEnum.INCOME_VERIFICATION_DOCUMENTS}${incomeVerificationReviewId}`
  );
  return documentsFromSession === null ? [] : JSON.parse(documentsFromSession);
};

const IncomeCalculationPage = () => {
  const { entryId, incomeVerificationReviewId } = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAccountRepresentative } = useUserRoles();
  const calculateIncomeIsLoading = useSelector(incomeVerificationSelectors.isLoading);
  const calculateIncomeResult = useSelector(incomeVerificationSelectors.selectCalculateIncomeData);
  const [showSendReportModal, setShowSendReportModal] = useState(false);
  const redirectPath = searchParams.get('redirectPath');
  const { onUpdateProofType } = useProofDocumentTypes();

  const isDocumentTypeDropdownActive = useFeatureFlag(
    FeatureFlagKey.DOCUMENT_TYPE_IV_FLOW_DROPDOWN
  );

  const documents: IncomeVerification[] = getIncomeDocumentsFromSession(
    incomeVerificationReviewId as string
  );

  const calculateIncome = useDebouncedCallback(() => {
    const documents = getIncomeDocumentsFromSession(incomeVerificationReviewId as string);
    if (incomeVerificationReviewId && documents && !calculateIncomeIsLoading) {
      const incomeDocuments = documents.map((document) => ({
        document_id: document.id,
        applicant_name: document.recipientName,
        document_start_date: document.payPeriodStartDate,
        document_end_date: document.payPeriodEndDate,
        gross_income: parseFloat(
          document.grossPay == null || document.grossPay === ''
            ? '0'
            : document.grossPay.replace(/[$,]/g, '')
        ),
        income_source: document.incomeSource
      }));
      dispatch(
        calculateIncomeThunk({
          submissionId: incomeVerificationReviewId,
          documents: incomeDocuments
        })
      );
    }
  }, 500);

  useEffect(() => {
    calculateIncome();
  }, []);

  const handleOnSave = (data: IncomeVerification) => {
    documents[data.index] = data;
    window.sessionStorage.setItem(
      `${IncomeVerificationEnum.INCOME_VERIFICATION_DOCUMENTS}${incomeVerificationReviewId}`,
      JSON.stringify(documents)
    );
    calculateIncome();
  };

  const onSendReport = () => {
    setShowSendReportModal(true);
  };

  const storeDocumentTypeChanges = useCallback(async () => {
    await Promise.all(
      documents
        .filter((document) => document.type !== document.originalType)
        .map(async (document) => {
          await onUpdateProofType(document.id, document.type as ProofType);
        })
    );
  }, []);

  const previousClicked = () => {
    window.sessionStorage.setItem(
      `${IncomeVerificationEnum.INCOME_VERIFICATION_DOCUMENTS_DISPLAY_LAST_PAGE}${incomeVerificationReviewId}`,
      'true'
    );
    navigate(-1);
  };

  return (
    <>
      <Content>
        <IncomeDataSummary>
          <h2>Income Data Summary</h2>
          {calculateIncomeResult?.reasons.map((reason, i) => (
            <ErrorWarningMessage status={reason.type} key={`${reason.type}-${i}`}>
              {reason.message}
            </ErrorWarningMessage>
          ))}
          {calculateIncomeResult?.reasons && calculateIncomeResult?.reasons.length > 0 && (
            <ErrorWarningMessage
              status={
                calculateIncomeResult.reasons.every((reason) => reason.type === 'warning')
                  ? 'warning'
                  : 'error'
              }
            >
              Verify the data summary is correct before sending the report.
            </ErrorWarningMessage>
          )}
        </IncomeDataSummary>
        <IncomeVerificationTiles>
          <Row columns={3} wrap="wrap" justify={'space-between'}>
            {documents.map((document: IncomeVerification) => {
              if (document.index > -1) {
                return (
                  <Row.Col size={1} key={document.docId}>
                    <IncomeCalculationTileForm
                      reason_codes={calculateIncomeResult?.reason_codes ?? []}
                      document={document}
                      onSave={handleOnSave}
                    />
                  </Row.Col>
                );
              }
              return null;
            })}
          </Row>
          <IncomeResult>
            {calculateIncomeResult?.status === 'error' ? (
              'Monthly Income Not Available'
            ) : (
              <>
                Monthly Income:&nbsp;{calculateIncomeIsLoading && <span>calculating...</span>}
                {!calculateIncomeIsLoading && calculateIncomeResult?.data && (
                  <span>${calculateIncomeResult?.data}</span>
                )}
              </>
            )}
          </IncomeResult>
        </IncomeVerificationTiles>
        <Gap height={2} />
        <Row>
          <Row.Col size={1} alignContent="center">
            <Row justify="center">
              {!isAccountRepresentative && (
                <Button
                  name="button-send-report"
                  onClick={onSendReport}
                  size={ButtonSize.normal}
                  color={ButtonColor.primary}
                  variant={ButtonVariant.contained}
                  isDisabled={calculateIncomeIsLoading}
                >
                  Send Report
                </Button>
              )}
            </Row>
          </Row.Col>
        </Row>
        <Gap height={5} />
        <ButtonRow>
          <Row.Col size={3}>
            <Button
              name="button-back-to-income-verification-flow"
              variant={ButtonVariant.outline}
              className="income-verification-buttons"
              onClick={previousClicked}
            >
              <Icon icon="left-arrow" />
            </Button>
          </Row.Col>
          <Row.Col alignContent="center" size={6}>
            <Row justify="center" gap={0.15}>
              {documents.map((document) => {
                return (
                  <Row.Col key={document.docId}>
                    <Icon key={document.docId} icon="step-circle" />
                  </Row.Col>
                );
              })}
              <Row.Col>
                <Icon icon="current-step-circle" />
              </Row.Col>
            </Row>
            <Row justify="center">
              <Row.Col>
                <Text variant={TextVariant.h4}>
                  Step {documents.length + 1} of {documents.length + 1}
                </Text>
              </Row.Col>
            </Row>
          </Row.Col>
          <Row.Col size={3}>
            <div></div>
          </Row.Col>
        </ButtonRow>
      </Content>
      <SendReportModal
        selectedEntryId={entryId as string}
        showSendReportModal={showSendReportModal}
        setShowSendReportModal={setShowSendReportModal}
        onConfirm={async () => {
          if (isDocumentTypeDropdownActive) {
            await storeDocumentTypeChanges();
          }
          const route = redirectPath || '/my-work';
          navigate(route);
        }}
      />
    </>
  );
};

export default IncomeCalculationPage;
