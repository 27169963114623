// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';

// Redux
import { actions, selectors as propertySelectors } from 'src/features/property/propertySlice';
import store, { useAppDispatch } from 'src/store';
import {
  getAll,
  getAllIdentityVerificationEnabled,
  getIdentityVerificationPropertyEnabled,
  propertyCreateAgentsThunk,
  propertyCreateThunk,
  propertyDeleteInvitationRelationThunk,
  propertyDeleteUserRelationThunk,
  propertyGetAgentsThunk,
  propertyGetByIdThunk,
  propertyUpdateStatusThunk,
  propertyUpdateThunk
} from '../services';

// Helpers
import { mergeAndSort } from 'src/helpers';

// Enums
import { RoleEnum } from 'src/ts/enums';

// Types
import {
  Agent,
  IInvitation,
  IProperty,
  IPropertyAgents,
  IPropertyAgentsRequest,
  IPropertyRequest,
  IPropertyStatusChangeRequest,
  IUser,
  RoleType,
  updateByIdRequest
} from 'src/ts/interfaces';

const useProperty = () => {
  const dispatch = useAppDispatch();
  const { role } = useRole();

  const onGetAll = (page?: number, rowsPerPage?: number, filter?: string) => {
    const currentFilter = propertySelectors.getAll.filter(store.getState());
    const filters = filter === undefined ? currentFilter.filter : filter;
    const pages = page === undefined ? currentFilter.page : page;
    const rowsPerPages = rowsPerPage === undefined ? currentFilter.rowsPerPage : rowsPerPage;

    dispatch(getAll({ ...currentFilter, filter: filters, page: pages, rowsPerPage: rowsPerPages }));
  };

  const onResetGetAll = () => {
    dispatch(actions.resetGetAll());
  };

  const onSearch = (q: string) => {
    const currentFilter = propertySelectors.getAll.filter(store.getState());
    const search = encodeURIComponent(q);
    dispatch(getAll({ ...currentFilter, page: 0, q: search }));
  };

  const onGetAllIdentityVerificationEnabled = (
    page?: number,
    rowsPerPage?: number,
    filter?: string
  ) => {
    const currentFilter = propertySelectors.getAllIdentityVerificationEnabled.filter(
      store.getState()
    );
    const filters = filter === undefined ? currentFilter.filter : filter;
    const pages = page === undefined ? currentFilter.page : page;
    const rowsPerPages = rowsPerPage === undefined ? currentFilter.rowsPerPage : rowsPerPage;

    dispatch(
      getAllIdentityVerificationEnabled({
        ...currentFilter,
        filter: filters,
        page: pages,
        rowsPerPage: rowsPerPages
      })
    );
  };

  const onGetIdentityVerificationPropertyEnabled = () => {
    dispatch(getIdentityVerificationPropertyEnabled());
  };

  const onSearchIdentityVerificationEnabled = (q: string) => {
    const currentFilter = propertySelectors.getAllIdentityVerificationEnabled.filter(
      store.getState()
    );
    const search = encodeURIComponent(q);
    dispatch(getAllIdentityVerificationEnabled({ ...currentFilter, page: 0, q: search }));
  };

  const onCreate = (property: IProperty) => dispatch(propertyCreateThunk({ property }));

  const allowCreateProperties = () => {
    return [RoleEnum.Admin, RoleEnum.AccountRepresentative].includes(role as RoleType);
  };

  const onGetById = (id?: string) => {
    if (!id) throw new Error('The property id is missing');
    dispatch(propertyGetByIdThunk(id));
  };

  const onGetPropertyAgents = (id?: string) => {
    if (!id) throw new Error('No property id provided to fetch agents');
    dispatch(propertyGetAgentsThunk(id));
  };

  const onCreatePropertyAgents = (id?: string, payload?: IPropertyAgentsRequest) => {
    if (!id) throw new Error('No property id provided to create agents for the property');
    if (!payload)
      throw new Error('No property information provided to create agents for the property');
    dispatch(propertyCreateAgentsThunk({ id, payload }));
  };

  const formatStatusPayload = (newStatus: string, id?: string) => {
    return {
      id,
      request: {
        property: {
          status: newStatus
        }
      }
    } as updateByIdRequest<IPropertyStatusChangeRequest>;
  };

  const onResetUpdateStatus = () => {
    dispatch(actions.resetUpdateStatus());
  };

  const onResetUpdate = () => {
    dispatch(actions.resetUpdate());
  };

  const onResetGetById = () => {
    dispatch(actions.resetGetById());
  };

  const onUpdatePropertyStatus = (payload: updateByIdRequest<IPropertyStatusChangeRequest>) => {
    dispatch(propertyUpdateStatusThunk(payload));
  };

  const onUpdate = (payload: updateByIdRequest<IPropertyRequest>) => {
    dispatch(propertyUpdateThunk(payload));
  };

  const onGetUnassignedUsers = (
    propertyAgents: IPropertyAgents,
    companyUsers?: IUser[] | null,
    companyInvitations?: IInvitation[] | null
  ) => {
    const users = mergeAndSort(companyUsers, companyInvitations, 'first_name');
    const assignedUsers = mergeAndSort(
      propertyAgents.invitations,
      propertyAgents.users,
      'first_name'
    );
    // Return the difference aka unassigned users
    return users?.filter(
      (user) => !assignedUsers?.some((assignedUser) => assignedUser.id === user.id)
    );
  };

  const onGetSortedAgentsArray = (
    agents: IPropertyAgents,
    setAgentsList: React.Dispatch<React.SetStateAction<Agent[]>>
  ) => {
    if (agents) {
      const sortedAgentsArray = mergeAndSort(agents.invitations, agents.users, 'first_name');
      return setAgentsList(sortedAgentsArray as Array<Agent>);
    }
  };

  const onRemoveUser = (id?: string, user?: Agent) => {
    if (!user?.id) throw new Error('Missing the user id to remove from the property');
    if (!id) throw new Error('Missing property id to remove agent from property');
    const isInvitation = (user: Agent) => {
      // Users and invitations are merged into the same list on a property
      // so this is a hack to check its type
      return Object.keys(user).includes('skip_email_sending');
    };

    if (isInvitation(user)) {
      dispatch(propertyDeleteInvitationRelationThunk({ id, invitationId: user?.id }));
    } else {
      dispatch(propertyDeleteUserRelationThunk({ id, userId: user?.id }));
    }
  };

  const onResetRemoveUser = () => {
    dispatch(actions.resetRemove());
  };

  const onResetCreate = () => {
    dispatch(actions.resetCreate());
  };

  const onResetCreateAgent = () => {
    dispatch(actions.resetCreateAgent());
  };

  return {
    onGetAll,
    onResetGetAll,
    onGetAllIdentityVerificationEnabled,
    onGetIdentityVerificationPropertyEnabled,
    onSearchIdentityVerificationEnabled,
    onGetById,
    onResetGetById,
    onUpdate,
    onResetUpdate,
    onSearch,
    onCreate,
    onResetCreate,
    allowCreateProperties,
    onGetPropertyAgents,
    onCreatePropertyAgents,
    onResetCreateAgent,
    onGetUnassignedUsers,
    formatStatusPayload,
    onUpdatePropertyStatus,
    onResetUpdateStatus,
    onGetSortedAgentsArray,
    onRemoveUser,
    onResetRemoveUser
  };
};

export default useProperty;
