import { useCallback } from 'react';

import { IFolder } from 'src/ts/interfaces';
import { useAppDispatch } from 'src/store';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import { updateFolderThunk } from '../services';

export const useFolderUpdateName = () => {
  const dispatch = useAppDispatch();
  const { SnackTypes, showSnackbar, VariantType } = useSnackbarProvider();

  const onUpdateName = useCallback(
    async (folder: { id: string } & Partial<IFolder>) => {
      try {
        await dispatch(updateFolderThunk(folder));

        showSnackbar(VariantType.success, 'The folder name has been changed', SnackTypes.none);
      } catch (error) {
        showSnackbar(
          VariantType.error,
          'Failed to update the folder name, please try again',
          SnackTypes.none
        );
      }
    },
    [SnackTypes.none, VariantType.error, VariantType.success, dispatch, showSnackbar]
  );

  return {
    onUpdateName
  };
};
