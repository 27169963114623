// Vendor
import styled from 'styled-components/macro';

// Components
import { Card } from 'src/components/atoms/Card';

export const Container = styled.div`
  .toolTip {
    margin-top: 0.4rem;
    display: inline-block;
  }
`;

export const CardContainer = styled(Card)`
  &[data-hasdata='true'] {
    .CBody {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
    }
  }
`;

export const CardTagListContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: row;

  & .tag {
    margin-right: 0.4rem;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .icon {
    margin-right: 0.4rem;
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const StyledTitleNoData = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon {
    margin-bottom: 0.5rem;
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
