import { fdeEntryApi } from 'src/api';
import { EntryReviewEscalationRequestType } from 'src/ts/interfaces';

const api = {
  getPendingReviewEntry: () => {
    return fdeEntryApi.getReviewPendingEntry();
  },
  assignEntryRequest: () => {
    return fdeEntryApi.getEntryForReview();
  },
  escalate(entryId: string, data: EntryReviewEscalationRequestType) {
    return fdeEntryApi.escalateEntry(entryId, data);
  }
};

export default api;
