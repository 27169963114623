/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import InputText, { InputTextType } from 'src/components/atoms/InputText/InputText';
import Row from 'src/components/atoms/Row/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { Dropdown } from 'src/components/molecules/Dropdown';
import { FileInputField } from 'src/components/molecules/FileInputField';
import { STATES } from 'src/constants/states';
import { FormValues, IInformation } from 'src/features/getStartedUI/form/ts/interfaces';
import { onlyRequestedSymbols, validateName } from 'src/helpers';
import { formatPhone } from 'src/helpers/formatPhone';
import { hasManyEmptySpaces, letterMatchingRegex, websiteRegex } from 'src/helpers/formRegex';

export type RegisterCompanyFormProps = {
  information: Pick<
    IInformation,
    | 'title'
    | 'title_form'
    | 'company_section_title'
    | 'user_account_title'
    | 'create_company_user_title'
  >;
  handleOnSubmit: (data: any) => void;
  isLoading?: boolean;
};
const RegisterCompanyForm: React.FC<RegisterCompanyFormProps> = (props) => {
  const { information, isLoading, handleOnSubmit } = props;

  const methods = useForm<FormValues | any>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues: {
      company: {
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        website: '',
        logo: null
      },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        password: ''
      }
    }
  });
  const { reset, handleSubmit, control, formState } = methods;
  const onSubmit = (data: FormValues) => {
    handleOnSubmit({
      ...data,
      company: {
        ...data.company,
        phone: formatPhone(data?.company),
        logo: data.company?.logo
      }
    });
    reset();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <>
          <Row>
            <Row.Col size={1}>
              <Text variant={TextVariant.h3} color={TextColor.initial}>
                {information.title_form}
              </Text>
            </Row.Col>
          </Row>

          <Gap height={2} />
          <Row gap={2}>
            <Row.Col size={1}>
              <Row>
                <Row.Col size={1}>
                  <Text variant={TextVariant.normal} color={TextColor.initial}>
                    {information.company_section_title}
                  </Text>
                </Row.Col>
              </Row>

              <Gap height={1} />

              <Row>
                <Row.Col size={1}>
                  <InputText
                    placeholder="Company Name"
                    name="company.name"
                    type={InputTextType.text}
                    config={{
                      required: 'Complete this field',
                      validate: {
                        noSpecialCharacters: (value) => onlyRequestedSymbols(value)
                      },
                      maxLength: { value: 200, message: 'Company name too long' }
                    }}
                  />
                </Row.Col>
              </Row>

              <Row gap={1}>
                <Row.Col size={1}>
                  <InputText
                    placeholder="Address"
                    name="company.address"
                    type={InputTextType.text}
                    showOptionalLabel={false}
                    config={{
                      required: 'Complete this field',
                      validate: {
                        onlySpecialCharacters: (value) =>
                          letterMatchingRegex.test(value) ||
                          'Should not contain only numbers and symbols',
                        hasMultipleSpaces: (value) =>
                          !hasManyEmptySpaces.test(value) || 'Should not contain many empty spaces'
                      },
                      maxLength: { value: 120, message: 'Address too long' }
                    }}
                  />
                </Row.Col>

                <Row.Col size={1}>
                  <InputText
                    placeholder="City"
                    name="company.city"
                    type={InputTextType.text}
                    config={{
                      required: 'Complete this field',
                      validate: {
                        onlySpecialCharacters: (value) =>
                          letterMatchingRegex.test(value) ||
                          'Should not contain only numbers and symbols',
                        isNameValid: (value) => validateName(value)
                      },
                      maxLength: { value: 50, message: 'City name too long' }
                    }}
                  />
                </Row.Col>
              </Row>

              <Row gap={1}>
                <Row.Col size={1}>
                  <Controller
                    name="company.state"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Complete this field' }}
                    render={({ field: { name, value, onChange } }) => (
                      <Dropdown
                        valueInitial={value}
                        data={[...STATES]}
                        onChange={({ [name]: selectedOption }) => {
                          onChange(selectedOption.selected.value);
                        }}
                        name="company.state"
                        placeholder="State"
                        size="md"
                      />
                    )}
                  />
                </Row.Col>

                <Row.Col size={1}>
                  <InputText
                    placeholder="ZIP Code"
                    name="company.zip"
                    type={InputTextType.text}
                    config={{
                      required: 'Complete this field',
                      validate: {
                        validZip: (zip) =>
                          zip && !/^\d{5}$/.test(zip) ? 'Invalid ZIP code format' : undefined
                      }
                    }}
                  />
                </Row.Col>
              </Row>

              <Row gap={1}>
                <Row.Col size={1}>
                  <InputText
                    placeholder={'Phone number'}
                    name="company.phone"
                    type={InputTextType.tel}
                  />
                </Row.Col>

                <Row.Col size={1}>
                  <InputText
                    placeholder="Website"
                    name="company.website"
                    type={InputTextType.text}
                    config={{
                      validate: {
                        invalidWebsiteString: (v) =>
                          v ? websiteRegex.test(v) || 'Invalid website' : undefined
                      }
                    }}
                  />
                </Row.Col>
              </Row>

              <Gap height={2} />

              <Row>
                <Row.Col size={1}>
                  <Text color={TextColor.initial}>{information.user_account_title}</Text>
                </Row.Col>
              </Row>

              <Gap height={1} />

              <Row gap={1}>
                <Row.Col size={1}>
                  <InputText
                    placeholder={'First Name'}
                    name="user.first_name"
                    type={InputTextType.text}
                    config={{
                      required: 'Complete this field',
                      maxLength: { value: 120, message: 'First name too long' },
                      validate: {
                        noSpecialCharacters: (v) =>
                          letterMatchingRegex.test(v) ||
                          'Should not contain only numbers and symbols',
                        isNameValid: (value) => validateName(value)
                      }
                    }}
                  />
                </Row.Col>

                <Row.Col size={1}>
                  <InputText
                    placeholder="Last Name"
                    name="user.last_name"
                    type={InputTextType.text}
                    config={{
                      required: 'Complete this field',
                      validate: {
                        noSpecialCharacters: (v) =>
                          letterMatchingRegex.test(v) ||
                          'Should not contain only numbers and symbols',
                        isNameValid: (value) => validateName(value)
                      },
                      maxLength: { value: 120, message: 'Last name too long' }
                    }}
                  />
                </Row.Col>
              </Row>

              <Row gap={1}>
                <Row.Col size={1}>
                  <InputText
                    placeholder={'Email'}
                    name="user.email"
                    type={InputTextType.email}
                    config={{
                      required: 'Complete this field'
                    }}
                  />
                </Row.Col>

                <Row.Col size={1}>
                  <InputText
                    placeholder="Password"
                    name="user.password"
                    type={InputTextType.password}
                    config={{
                      required: 'Complete this field',
                      validate: {
                        between8And256CharacterLength: (value) =>
                          /^(?=\S{8,256}$)/.test(value) ||
                          'Must be between 8 and 256 characters in length',
                        bothUpperAndLowerCaseCharacters: (value) =>
                          /^(?=.*?[A-Z])(?=.*?[a-z])/.test(value) ||
                          'Must contain both upper and lower case characters',
                        leastOneSpecialCharacter: (value) =>
                          /^(?=(.*[\W]){1,})/.test(value) ||
                          'Must contain at least one non-alphanumeric character',
                        leastOneNumber: (value) =>
                          /^(?=(.*[\d]){1,})/.test(value) || 'Must contain at least one number'
                      }
                    }}
                  />
                </Row.Col>
              </Row>

              <Gap height={1} />
              <Button
                name="SubmitInvitationDetails"
                variant={ButtonVariant.contained}
                color={ButtonColor.primary}
                size={ButtonSize.medium}
                isLoading={isLoading}
                isDisabled={
                  isLoading ||
                  Boolean(Object.keys(formState.errors || {}).length) ||
                  !formState.isDirty
                }
                type={ButtonType.submit}
              >
                {information.create_company_user_title}
              </Button>
            </Row.Col>
            <Row.Col alignContent="center" size={0.5}>
              <FileInputField name="company.logo" title="Company Logo" />
            </Row.Col>
          </Row>
        </>
      </form>
    </FormProvider>
  );
};

RegisterCompanyForm.propTypes = {
  information: PropTypes.any.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

RegisterCompanyForm.defaultProps = {
  isLoading: false
};

export default RegisterCompanyForm;
