import { FC, ReactNode, useState } from 'react';
import { Container, Content, Item, StyledButton, StyledIcon } from './styles';

import Text, { TextVariant } from 'src/components/atoms/Text';
import { ButtonVariant } from 'src/components/atoms/Button';

export interface TreeProps {
  rows: string[];
  children?: ReactNode;
  titleShow: string;
  titleHide: string;
  isOpen: boolean;
}

const Tree: FC<TreeProps> = ({ isOpen, rows, titleShow, titleHide, children }) => {
  const [setActive, setActiveState] = useState(isOpen ? 'active' : '');

  const Title = () => {
    return (
      <StyledButton
        name="hiding"
        variant={ButtonVariant.ghost}
        onClick={() => setActiveState(setActive === '' ? 'active' : '')}
      >
        <StyledIcon icon={setActive === '' ? 'addCircle' : 'removeCircle'} color="primary" />{' '}
        {setActive === 'active' ? titleHide : titleShow}
      </StyledButton>
    );
  };

  const Body = () => {
    if (children) {
      return (
        <Text variant={TextVariant.normal} color="secondary">
          {children}
        </Text>
      );
    }

    return (
      <>
        {rows.map((row, index) => {
          return (
            <Item key={index}>
              <Text variant={TextVariant.small} color="secondary">
                &quot;{row}&quot;
              </Text>
            </Item>
          );
        })}
      </>
    );
  };

  return (
    <Container className={setActive}>
      <Title />
      <Content className={setActive}>
        <Body />
      </Content>
    </Container>
  );
};

Tree.defaultProps = {
  isOpen: false,
  rows: [],
  titleShow: 'Show',
  titleHide: 'Hide'
};

export default Tree;
