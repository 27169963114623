import { metabaseApi } from 'src/api';
import { IFetchIFrameRequest } from 'src/ts/interfaces';

const useReporting = () => {
  const fetchIframeUrl = (request: IFetchIFrameRequest) => {
    /* We're using the list of short_ids to generate the metabase report, please if you delete the short_id modify metabase */
    if (request.payload.filter !== '' || !request.isFiltered) {
      const result = metabaseApi.getEmbedding(request.id, request.payload);
      return result;
    } else {
      throw new Error('no filter added');
    }
  };

  const getCompany = (
    selectedCompanies: string[],
    isAllCompaniesSelected: boolean,
    isAdminOrReviewerOrFraudAnalyst?: boolean,
    userCompanyShortId?: string
  ) => {
    if (!isAdminOrReviewerOrFraudAnalyst) {
      return userCompanyShortId;
    }
    if (isAllCompaniesSelected) {
      return null;
    }

    return selectedCompanies.join('|');
  };

  return {
    fetchIframeUrl,
    getCompany
  };
};

export default useReporting;
