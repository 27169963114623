import { format, formatISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SelectedFilterData } from 'src/components/organisms/Filter';
import { SelectedSortData } from 'src/components/organisms/Sort';
import { selectors } from 'src/features/identityVerification/identityVerificationSlice';
import { filterQueryOptions, selectedFilter, selectedSort } from './constants';

const useGetSelectedData = (): {
  selectedFilterData: SelectedFilterData;
  selectedSortData: SelectedSortData;
  buildFilterQuery: (selected: SelectedFilterData) => string;
} => {
  const filters = useSelector(selectors.getIdentityApplicants.filters);
  const [selectedFilterData, setSelectedFilterData] = useState<SelectedFilterData>(selectedFilter);
  const [selectedSortData, setSelectedSortData] = useState<SelectedSortData>(selectedSort);

  useEffect(() => {
    const [field, order] = filters?.sort?.split(':') || Object.values(selectedSort);

    setSelectedSortData({ field, order });

    const selectedFilterData =
      filters?.filter?.split(',')?.reduce<Record<string, string>>((result, value) => {
        if (value.includes('status')) {
          const [, results] = value.split('@>');
          result.results = results;
        }

        if (value.includes('sessionClosedAt>')) {
          const [, startDate] = value.split('>');
          result.startDate = format(new Date(startDate), 'MM/dd/yyyy');
        }

        if (value.includes('sessionClosedAt<')) {
          const [, endDate] = value.split('<');
          const endDateMinus1 = new Date(endDate);
          endDateMinus1.setDate(endDateMinus1.getDate() - 1);
          result.endDate = format(endDateMinus1, 'MM/dd/yyyy');
        }

        return result;
      }, {}) || selectedFilter;

    setSelectedFilterData(selectedFilterData);
  }, [filters]);

  const buildFilterQuery = useCallback(
    ({ status, results, startDate, endDate }: SelectedFilterData) => {
      let endDateParam = undefined;
      if (endDate) {
        const endDatePlus1 = new Date(endDate);
        endDatePlus1.setDate(endDatePlus1.getDate() + 1);
        endDateParam = formatISO(endDatePlus1);
      }

      const params = Object.entries({
        results,
        status,
        startDate: startDate ? formatISO(new Date(startDate)) : undefined,
        endDate: endDateParam
      }).reduce<Record<string, string>>(
        (result, [key, value]) => (value ? ((result[key] = value), result) : result),
        {}
      );

      const query = Object.entries(params)
        .map(
          ([key, value]) =>
            `${filterQueryOptions[key]?.field}${filterQueryOptions[key]?.operator}${value}`
        )
        .join(',');

      return decodeURIComponent(query);
    },
    []
  );

  return { selectedFilterData, selectedSortData, buildFilterQuery };
};
export default useGetSelectedData;
