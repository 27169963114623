// Vendor
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

// Types
import { CheckboxData } from 'src/components/atoms/Checkbox/types';
import {
  getPaginationRequest,
  IPaginationRequest,
  IRejectedAction,
  ITeam,
  IUser,
  StatusType
} from 'src/ts/interfaces';

// Helpers
import getMembersCountLabel from 'src/features/teams/helpers/getMembersCountLabel';
import { formatCheckboxData, mergeArrayByKey } from 'src/helpers';
import getMembersFormatted from './helpers/getMembersFormatted';

// Hooks
import { onGetLabel as onGetLabelTimezone } from 'src/features/timezone/hooks/useTimezone';

// Enums
import { Roles, Status as StatusEnum } from 'src/ts/enums';

// Redux
import {
  create,
  getAll,
  getAllFA,
  getAllFDEManagers,
  getAllSFA,
  getAllTeamsPerformance,
  getOne,
  update
} from './services';

export type StateData = {
  getAll: {
    status: StatusType;
    data?: ITeam[] | null;
    error?: string | null;
    count?: number;
    filter: getPaginationRequest;
  };
  getOne: {
    status: StatusType;
    error?: string | null;
    data?: ITeam | null;
  };
  getAllFDEManagers: {
    status: StatusType;
    data?: IUser[] | null;
    error?: string | null;
    count?: number;
  };
  create: {
    status: StatusType;
    data?: ITeam | null;
    error?: string | null;
  };
  update: {
    status: StatusType;
    data?: ITeam | null;
    error?: string | null;
  };
  getAllSFA: {
    status: StatusType;
    data?: CheckboxData[] | [] | null;
    error?: string | null;
    count?: number;
    filter: getPaginationRequest;
  };
  getAllFA: {
    status: StatusType;
    data?: CheckboxData[] | [] | null;
    error?: string | null;
    count?: number;
    filter: getPaginationRequest;
  };
  getAllTeamsPerformance: {
    status: StatusType;
    data?: CheckboxData[] | null;
    error?: string | null;
    count?: number;
    filter: IPaginationRequest;
  };
};

export const initialState: StateData = {
  getAll: {
    status: StatusEnum.IDLE,
    data: [],
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 200,
      sort: 'inserted_at:desc'
    }
  },
  getOne: {
    status: StatusEnum.IDLE,
    data: null,
    error: null
  },
  getAllFDEManagers: {
    status: StatusEnum.IDLE,
    data: [],
    error: null,
    count: 0
  },
  create: {
    status: StatusEnum.IDLE,
    data: null,
    error: null
  },
  update: {
    status: StatusEnum.IDLE,
    data: null,
    error: null
  },
  getAllSFA: {
    status: StatusEnum.IDLE,
    data: [],
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 20,
      sort: 'first_name:asc'
    }
  },
  getAllFA: {
    status: StatusEnum.IDLE,
    data: [],
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 20,
      sort: 'first_name:asc'
    }
  },
  getAllTeamsPerformance: {
    status: StatusEnum.IDLE,
    data: [],
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 8,
      sort: 'inserted_at:desc'
    }
  }
};

export const getAllReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getAll.pending, (state: StateData) => {
    state.getAll.status = StatusEnum.LOADING;
    state.getAll.error = null;
  });
  builder.addCase(getAll.rejected, (state: StateData, action: IRejectedAction) => {
    state.getAll.status = StatusEnum.ERROR;
    state.getAll.error = action.error?.message || null;
  });
  builder.addCase(getAll.fulfilled, (state: StateData, action: any) => {
    const { data, count, page, rowsPerPage } = action.payload;
    state.getAll.status = StatusEnum.SUCCESS;
    state.getAll.data = data?.map((team: ITeam) => {
      team.timezones = {
        id: team.timezone,
        label: onGetLabelTimezone(team.timezone)
      };
      if (team.fde_manager) {
        team.fdeManager = {
          id: team.fde_manager?.id,
          label: `${team.fde_manager?.first_name} ${team.fde_manager?.last_name}`
        };
      }
      team.numMembers = team.members?.length;
      return team;
    });
    state.getAll.count = count;
    state.getAll.filter.page = page > 0 ? page - 1 : page;
    state.getAll.filter.rowsPerPage = rowsPerPage;
    state.getAll.filter.q = action?.meta?.arg.q || '';
  });
};

export const getOneReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getOne.pending, (state: StateData) => {
    state.getOne.status = StatusEnum.LOADING;
    state.getOne.error = null;
  });
  builder.addCase(getOne.rejected, (state: StateData, action: IRejectedAction) => {
    state.getOne.status = StatusEnum.ERROR;
    state.getOne.error = action.error.message;
  });
  builder.addCase(getOne.fulfilled, (state: StateData, action: PayloadAction<any>) => {
    state.getOne.status = StatusEnum.SUCCESS;
    const team = action.payload.data as ITeam;
    team.country = {
      ...team.country,
      label: team.country?.name
    };
    team.timezones = {
      id: team.timezone,
      label: onGetLabelTimezone(team.timezone)
    };
    team.fdeManager = undefined;
    if (team.fde_manager) {
      team.fdeManager = {
        id: team.fde_manager?.id,
        label: `${team.fde_manager?.first_name} ${team.fde_manager?.last_name}`
      };
    }
    team.numMembers = team.members?.length;

    const membersSeniorFraudAnalyst = team.members
      ?.filter((user) => user.role === Roles.SeniorFraudAnalyst)
      .map(({ id: value, first_name, last_name }) => {
        return { value, label: `${first_name} ${last_name}` };
      }) as CheckboxData[];
    team.membersSeniorFraudAnalyst = {
      data: membersSeniorFraudAnalyst,
      count: getMembersCountLabel(membersSeniorFraudAnalyst.length.toString())
    };

    const membersFraudAnalyst = team.members
      ?.filter((user) => user.role === Roles.FraudAnalyst)
      .map(({ id: value, first_name, last_name }) => {
        return { value, label: `${first_name} ${last_name}` };
      }) as CheckboxData[];
    team.membersFraudAnalyst = {
      data: membersFraudAnalyst,
      count: getMembersCountLabel(membersFraudAnalyst.length.toString())
    };
    state.getOne.data = team;
  });
};

export const getAllFDEManagersReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getAllFDEManagers.pending, (state: StateData) => {
    state.getAllFDEManagers.status = StatusEnum.LOADING;
    state.getAllFDEManagers.error = null;
  });
  builder.addCase(getAllFDEManagers.rejected, (state: StateData, action: IRejectedAction) => {
    state.getAllFDEManagers.status = StatusEnum.ERROR;
    state.getAllFDEManagers.error = action.error?.message || null;
  });
  builder.addCase(getAllFDEManagers.fulfilled, (state: StateData, action: any) => {
    const { data, count } = action.payload;
    state.getAllFDEManagers.status = StatusEnum.SUCCESS;
    state.getAllFDEManagers.data = data;
    state.getAllFDEManagers.count = count;
  });
};

export const createReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(create.pending, (state: StateData) => {
    state.create.status = StatusEnum.LOADING;
    state.create.error = null;
  });
  builder.addCase(create.rejected, (state: StateData, action: IRejectedAction) => {
    state.create.status = StatusEnum.ERROR;
    state.create.error = action.error?.message || null;
  });
  builder.addCase(create.fulfilled, (state, action) => {
    state.create.status = StatusEnum.SUCCESS;
    state.create.data = action.payload.data as ITeam;
  });
};

export const updateReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(update.pending, (state: StateData) => {
    state.update.status = StatusEnum.LOADING;
    state.update.error = null;
  });
  builder.addCase(update.rejected, (state: StateData, action: IRejectedAction) => {
    state.update.status = StatusEnum.ERROR;
    state.update.error = action.error?.message || null;
  });
  builder.addCase(update.fulfilled, (state, action) => {
    state.update.status = StatusEnum.SUCCESS;
    state.update.data = action.payload.data as ITeam;
  });
};

export const getAllSFAReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getAllSFA.pending, (state: StateData) => {
    state.getAllSFA.status = StatusEnum.LOADING;
    state.getAllSFA.error = null;
  });
  builder.addCase(getAllSFA.rejected, (state: StateData, action: IRejectedAction) => {
    state.getAllSFA.status = StatusEnum.ERROR;
    state.getAllSFA.error = action.error?.message || null;
  });
  builder.addCase(getAllSFA.fulfilled, (state: StateData, action: any) => {
    const { data, count, page, rowsPerPage } = action.payload;
    state.getAllSFA.status = StatusEnum.SUCCESS;
    state.getAllSFA.data = count > 0 ? getMembersFormatted(data) : [];
    state.getAllSFA.count = count;
    state.getAllSFA.filter.page = page > 0 ? page - 1 : page;
    state.getAllSFA.filter.rowsPerPage = rowsPerPage;
    state.getAllSFA.filter.q = action?.meta?.arg.q || '';
  });
};

export const getAllFAReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getAllFA.pending, (state: StateData) => {
    state.getAllFA.status = StatusEnum.LOADING;
    state.getAllFA.error = null;
  });
  builder.addCase(getAllFA.rejected, (state: StateData, action: IRejectedAction) => {
    state.getAllFA.status = StatusEnum.ERROR;
    state.getAllFA.error = action.error?.message || null;
  });
  builder.addCase(getAllFA.fulfilled, (state: StateData, action: any) => {
    const { data, count, page, rowsPerPage } = action.payload;
    state.getAllFA.status = StatusEnum.SUCCESS;
    state.getAllFA.data = count > 0 ? getMembersFormatted(data) : [];
    state.getAllFA.count = count;
    state.getAllFA.filter.page = page > 0 ? page - 1 : page;
    state.getAllFA.filter.rowsPerPage = rowsPerPage;
    state.getAllFA.filter.q = action?.meta?.arg.q || '';
  });
};

export const getAllTeamsPerformanceReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getAllTeamsPerformance.pending, (state: StateData) => {
    state.getAllTeamsPerformance.status = StatusEnum.LOADING;
    state.getAllTeamsPerformance.error = null;
  });
  builder.addCase(getAllTeamsPerformance.rejected, (state: StateData, action: IRejectedAction) => {
    state.getAllTeamsPerformance.status = StatusEnum.ERROR;
    state.getAllTeamsPerformance.error = action.error?.message || null;
  });
  builder.addCase(getAllTeamsPerformance.fulfilled, (state: StateData, action: any) => {
    const { data, count, page, rowsPerPage } = action.payload;
    state.getAllTeamsPerformance.status = StatusEnum.SUCCESS;

    const dataTeams = formatCheckboxData({
      data,
      value: 'id',
      label: 'name'
    });
    state.getAllTeamsPerformance.data =
      data.length > 0
        ? mergeArrayByKey(state.getAllTeamsPerformance.data || [], dataTeams, 'value')
        : [];
    state.getAllTeamsPerformance.count = count;
    state.getAllTeamsPerformance.filter.page = page > 0 ? page - 1 : page;
    state.getAllTeamsPerformance.filter.rowsPerPage = rowsPerPage;
    state.getAllTeamsPerformance.filter.q = action?.meta?.arg.q || '';
  });
};
