import { Card } from 'src/components/atoms/Card';
import { Row } from 'src/components/atoms/Row';
import styled from 'styled-components/macro';

export const StyledCard = styled(Card)`
  width: 28rem;
  height: 26.5rem;

  & > .CBody {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
  }
`;

export const StyledRowDivider = styled(Row)`
  margin-bottom: 16px;

  & > div,
  p,
  h4 {
    margin-bottom: 8px;
  }

  & > p.label-tag {
    margin-bottom: 0px;
  }

  & > .tag-space {
    margin-bottom: 18px;
  }
`;

export const UpperInformation = styled.div`
  padding: 18px;

  & > div:last-child {
    margin-bottom: unset;
  }
`;

export const ButtonAction = styled.div`
  background: ${({ theme }) => theme.color.gray[100]};
  padding: 19px;
  margin-top: auto;
`;
