// Vendor
import { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

// Components
import Button, { ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import { Checkbox } from 'src/components/atoms/Checkbox';
import { Row } from 'src/components/atoms/Row';
import { ControlledDropdown } from 'src/components/molecules/Dropdown';
import { DropdownMulticheck } from 'src/components/molecules/DropdownMulticheck';
import { Container, ContentCheck } from './styles';

// Constants
import {
  reasons as insufficientReasonTypes,
  reasonsCleanProceedWithCaution,
  reasonsEdited as reasonsEditedType,
  results as resultTypes
} from './constants';

// Helpers
import { canSelectDocType } from 'src/features/myWork/helpers';
import { formatCheckboxData } from 'src/helpers';

// Enums
import { EntryResultEnum, FeatureFlagKey } from 'src/ts/enums';

// Types
import {
  IProof,
  ProofResultCleanProceedWithCautionReasonType,
  ProofResultEditedReasonType,
  ProofResultInsufficientReasonType,
  ProofResultType,
  ProofType
} from 'src/ts/interfaces';

// Hooks
import { useProofDocumentTypes } from 'src/features/proofs/hooks';
import { useFeatureFlag } from 'src/hooks';

export type IFormValues = {
  result?: ProofResultType;
  resultEditedReason?: ProofResultEditedReasonType[];
  resultInsufficientReason?: ProofResultInsufficientReasonType;
  resultCleanProceedWithCautionReason?: ProofResultCleanProceedWithCautionReasonType;
  document?: ProofType;
  note: IProof['note'];
};

export type BulkSelectDocProps = {
  onApply: (content: IFormValues) => void;
  isDisabled: boolean;
  markSelectAll: boolean | null;
  onGetCheck?: ({ selectAll }: { selectAll: boolean | null }) => void;
};

const BulkSelectDoc: FC<BulkSelectDocProps> = ({
  onApply,
  isDisabled,
  markSelectAll,
  onGetCheck
}) => {
  const methods = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues: {
      result: undefined,
      resultEditedReason: [],
      resultInsufficientReason: undefined,
      document: undefined,
      note: ''
    }
  });

  const { result, resultEditedReason, resultInsufficientReason } = methods.watch();

  const { proofDocumentTypes } = useProofDocumentTypes();

  const cleanProceedWithCautionFlagEnabled = useFeatureFlag(
    FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION
  );

  const resultTypesFiltered = cleanProceedWithCautionFlagEnabled
    ? resultTypes
    : resultTypes.filter(
        (resultType) => resultType.value !== EntryResultEnum.CleanProceedWithCaution
      );

  const handleSubmit = (formData: IFormValues) => {
    onApply(formData);
  };

  const onResultChange = (data: { result: { selected: { value: EntryResultEnum } } }) => {
    const selection = data.result.selected.value;
    if (selection === EntryResultEnum.Undetermined) {
      methods.resetField('resultEditedReason');
      methods.resetField('resultCleanProceedWithCautionReason');
    } else if (selection === EntryResultEnum.Edited) {
      methods.resetField('resultInsufficientReason');
      methods.resetField('resultCleanProceedWithCautionReason');
    } else if (selection === EntryResultEnum.CleanProceedWithCaution) {
      methods.resetField('resultEditedReason');
      methods.resetField('resultInsufficientReason');
    } else {
      methods.resetField('resultCleanProceedWithCautionReason');
      methods.resetField('resultInsufficientReason');
      methods.resetField('resultEditedReason');
    }
  };

  return (
    <Container>
      <FormProvider {...methods}>
        <Row alignItems="flex-start" gap={1}>
          <ContentCheck>
            <Checkbox
              name="select-all"
              label="Select all"
              value="all"
              isNull={markSelectAll === null}
              isDisabled={isDisabled}
              isChecked={markSelectAll === null ? false : markSelectAll}
              showLabel={false}
              onClick={({ isChecked }) => {
                onGetCheck?.({ selectAll: isChecked });
              }}
            />
          </ContentCheck>
          <ControlledDropdown
            name="result"
            data={resultTypesFiltered}
            placeholder={result ? 'Result' : 'Select Result'}
            showLabel={false}
            isDisabled={isDisabled}
            size="sm"
            config={{ required: 'Complete this field' }}
            onChange={onResultChange}
          />
          {result === EntryResultEnum.Undetermined && (
            <ControlledDropdown
              name="resultInsufficientReason"
              data={insufficientReasonTypes}
              placeholder={insufficientReasonTypes ? 'Reason' : 'Select a reason'}
              showLabel={false}
              isDisabled={isDisabled}
              size="sm"
              config={{ required: 'Complete this field' }}
            />
          )}
          {result === EntryResultEnum.Edited && (
            <Controller
              name="resultEditedReason"
              control={methods.control}
              rules={{ required: 'Complete this field' }}
              render={({ field: { name } }) => (
                <DropdownMulticheck
                  data={formatCheckboxData({
                    data: reasonsEditedType,
                    value: 'value',
                    label: 'label'
                  })}
                  onChange={({ ids }) => {
                    methods.setValue(
                      'resultEditedReason',
                      ids?.split(',') as ProofResultEditedReasonType[]
                    );
                  }}
                  name={name}
                  placeholder={resultEditedReason ? 'Reason' : 'Select a reason'}
                  size="sm"
                  isDisabled={isDisabled}
                  textSelectAll="All reasons selected"
                />
              )}
            />
          )}
          {result === EntryResultEnum.CleanProceedWithCaution && (
            <ControlledDropdown
              name="resultCleanProceedWithCautionReason"
              data={reasonsCleanProceedWithCaution}
              placeholder={reasonsCleanProceedWithCaution ? 'Reason' : 'Select a reason'}
              showLabel={false}
              isDisabled={isDisabled}
              size="sm"
              config={{ required: 'Complete this field' }}
            />
          )}

          {canSelectDocType(result, resultInsufficientReason) && (
            <ControlledDropdown
              name="document"
              data={proofDocumentTypes}
              placeholder="Document Type"
              showLabel={false}
              isDisabled={isDisabled}
              size="sm"
              config={{ required: 'Complete this field' }}
            />
          )}

          <Button
            name="update_selected_button"
            variant={ButtonVariant.contained}
            size={ButtonSize.medium}
            isDisabled={!methods.formState.isDirty || markSelectAll === false || isDisabled}
            onClick={methods.handleSubmit(handleSubmit)}
          >
            Update Selected
          </Button>
        </Row>
      </FormProvider>
    </Container>
  );
};

BulkSelectDoc.defaultProps = {
  onApply: (content) => console.log(content),
  isDisabled: false,
  markSelectAll: false
};

export default BulkSelectDoc;
