import styled from 'styled-components/macro';

export const Container = styled.div`
  padding-top: 0.5rem;
  position: absolute;
  right: 0;
  z-index: 1;

  .CBody {
    padding: 1rem;
  }
`;
