import { useCallback } from 'react';

import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import { useAppDispatch } from 'src/store';
import { updateEntryThunk } from '../services';

export const useEntryUpdateNote = () => {
  const dispatch = useAppDispatch();
  const { SnackTypes, showSnackbar, VariantType } = useSnackbarProvider();

  const onUpdateNote = useCallback(
    async (entryId?: string, note?: string) => {
      if (!entryId) {
        showSnackbar(
          VariantType.error,
          'Is missing the Entry Id to update the note',
          SnackTypes.none
        );

        return;
      }

      try {
        await dispatch(updateEntryThunk({ id: entryId, note }));

        showSnackbar(VariantType.success, 'The note has been updated', SnackTypes.none);
      } catch (error) {
        showSnackbar(VariantType.error, 'Failed to update the note', SnackTypes.none);
      }
    },
    [SnackTypes.none, VariantType.error, VariantType.success, dispatch, showSnackbar]
  );

  return {
    onUpdateNote
  };
};
