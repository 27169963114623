import { createAsyncThunk } from '@reduxjs/toolkit';
import { NAME } from '../constants';
import api from './api';
import { IProofDUP, UnauthenticateSessionProofType } from './ts/interfaces';

export const getProofs = createAsyncThunk(`${NAME}/getProofs`, async () => {
  try {
    return await api.getProofs();
  } catch (error) {
    console.error(`${getProofs}/getProofs: ${error}`);
    throw new Error('Error throw getProofs');
  }
});

export const reUploadProof = createAsyncThunk(
  `${NAME}/reUploadProof`,
  async ({ file, proof, isTempFile }: { file: File; proof: IProofDUP; isTempFile: boolean }) => {
    try {
      if (proof.id) {
        if (!isTempFile) {
          await api.removeProof(proof.id);
        }
        const proofType = {
          proof: { type: proof.type }
        };
        const createProofPreURLResponse = await api.createProofPreURL(proofType);
        if (createProofPreURLResponse?.id) {
          const getProofURLResponse = await api.getProofURL(createProofPreURLResponse.id);
          if (getProofURLResponse?.presigned_url) {
            const { url, fields, method } = getProofURLResponse.presigned_url;

            if (url && fields && method) {
              await api.saveProofFile({
                method,
                url,
                fields,
                type: file.type,
                file
              });
              const setProofURLResponse = await api.setProofURL(createProofPreURLResponse.id);
              if (setProofURLResponse) {
                return api.getProof(createProofPreURLResponse.id, proof.id);
              }
            }
          }
        }
      }
      return null;
    } catch (error) {
      console.error(`${getProofs}/createProofPreURL: ${error}`);
      throw new Error('Error throw createProofPreURL');
    }
  }
);

export const createProofPreURL = createAsyncThunk(
  `${NAME}/createProofPreURL`,
  async ({
    type,
    file,
    tempId
  }: {
    type: UnauthenticateSessionProofType;
    file: File;
    tempId: string;
  }) => {
    try {
      const proof = { proof: { type } };
      const createProofPreURLResponse = await api.createProofPreURL(proof);
      if (createProofPreURLResponse?.id) {
        const getProofURLResponse = await api.getProofURL(createProofPreURLResponse.id);
        if (getProofURLResponse?.presigned_url) {
          const { url, fields, method } = getProofURLResponse.presigned_url;

          if (url && fields && method) {
            await api.saveProofFile({
              method,
              url,
              fields,
              type: file.type,
              file
            });
            const setProofURLResponse = await api.setProofURL(createProofPreURLResponse.id);
            if (setProofURLResponse) {
              return api.getProof(createProofPreURLResponse.id, tempId);
            }
          }
        }
      }
      return null;
    } catch (error) {
      console.error(`${getProofs}/createProofPreURL: ${error}`);
      throw new Error('Error throw createProofPreURL');
    }
  }
);

export const removeProof = createAsyncThunk(`${NAME}/removeProof`, async (id: string) => {
  try {
    return await api.removeProof(id);
  } catch (error) {
    console.error(`${removeProof}/removeProof: ${error}`);
    throw new Error('Error throw removeProof');
  }
});

export const changeProofType = createAsyncThunk(
  `${NAME}/changeProofType`,
  async ({ id, type }: { id: string; type: UnauthenticateSessionProofType }) => {
    try {
      return await api.changeProofType({ id, type });
    } catch (error) {
      console.error(`${changeProofType}/changeProofType: ${error}`);
      throw new Error('Error throw changeProofType');
    }
  }
);

export const getProofToRemove = createAsyncThunk(`${NAME}/getProofToRemove`, async (id: string) => {
  try {
    const data = await api.getProof(id);
    if (data) {
      return await api.removeProof(id);
    }
    return false;
  } catch (error) {
    return false;
  }
});
