import { useSelector } from 'react-redux';
import { actions, selectors } from 'src/features/users/usersSlice';
import { useAppDispatch } from 'src/store';
import { Status } from 'src/ts/enums';
import { getByEmail } from '../services';

const useUserGetByEmail = () => {
  const dispatch = useAppDispatch();

  const getByEmailResponse = useSelector(selectors.getByEmail);

  const onGetByEmail = (email: string) => {
    dispatch(
      getByEmail({
        filter: encodeURI(`email=${email}`),
        page: 0,
        rowsPerPage: 1
      })
    );
  };

  const onResetGetByEmail = () => {
    dispatch(actions.resetGetByEmail());
  };

  return {
    onGetByEmail,
    getUserByEmail: {
      isLoading: getByEmailResponse.status === Status.LOADING,
      isSuccessful: getByEmailResponse.status === Status.SUCCESS,
      error: getByEmailResponse.error,
      data: getByEmailResponse.data
    },
    onResetGetByEmail
  };
};

export default useUserGetByEmail;
