import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
`;

export const ButtonContainer = styled.div`
  &.active {
    button {
      color: ${({ theme }) => theme.color.blue[700]};
      border-color: ${({ theme }) => theme.color.gray[200]};
    }
  }

  &.selected {
    button {
      border-color: ${({ theme }) => theme.color.blue[700]};
    }
  }

  &.full {
    button {
      color: ${({ theme }) => theme.color.blue[700]};
      border-color: ${({ theme }) => theme.color.blue[700]};
    }
  }

  button {
    color: ${({ theme }) => theme.color.gray[600]};
    min-width: 5.75rem;
    border: 0.125rem solid ${({ theme }) => theme.color.gray[200]};

    small {
      font-weight: 500;
    }

    &[aria-disabled='false'] {
      &:hover {
        color: ${({ theme }) => theme.color.gray[600]};
        border-color: ${({ theme }) => theme.color.gray[600]};
      }
    }

    &[aria-disabled='true'] {
      background: ${({ theme }) => theme.color.gray[50]};
      &:hover {
        border-color: ${({ theme }) => theme.color.gray[200]};
      }
    }

    svg {
      margin-left: 0.75rem;
    }
  }
`;
