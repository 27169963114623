import { CheckboxType } from 'src/components/atoms/Checkbox/types';

export type IListContentState = {
  data: CheckboxType[] | [];
  selected: CheckboxType[] | [];
  q: string;
};

export type ITransferListContentState = {
  firstList: IListContentState;
  secondList: IListContentState;
};

export const initialState: ITransferListContentState = {
  firstList: {
    data: [],
    selected: [],
    q: ''
  },
  secondList: {
    data: [],
    selected: [],
    q: ''
  }
};

export type ActionType =
  | { type: 'load'; payload: ITransferListContentState }
  | { type: 'toLeft' }
  | { type: 'toRight' }
  | { type: 'selectAllLeft'; payload: boolean }
  | { type: 'selectAllRight'; payload: boolean }
  | { type: 'selectedLeft'; payload: { selected: CheckboxType[] | [] } }
  | { type: 'selectedRight'; payload: { selected: CheckboxType[] | [] } }
  | { type: 'searchInLeft'; payload: { q: string } }
  | { type: 'searchInRight'; payload: { q: string } };

const search = (q: string, where: string) => {
  const re = new RegExp(q, 'ig');
  return where.search(re) !== -1;
};

const selectAll = (
  state: ITransferListContentState,
  listKey: 'firstList' | 'secondList',
  action: { payload: boolean }
): ITransferListContentState => {
  const list = state[listKey];
  const { q, data } = list;

  const dataSelected = !action.payload ? [] : q ? data.filter((e) => e.isVisible) : data;

  const updatedData = data.map((item) => ({
    ...item,
    isChecked: q ? item.isVisible && action.payload : action.payload
  }));

  return {
    ...state,
    [listKey]: {
      ...list,
      data: [...updatedData],
      selected: [...dataSelected]
    }
  };
};

export const TransferReducer = (state: ITransferListContentState, action: ActionType) => {
  switch (action.type) {
    case 'load': {
      return {
        firstList: {
          data: action.payload.firstList.data
            .filter((item) => {
              return !state.secondList.data.some((k) => k.value === item.value);
            })
            .map((item) => {
              return {
                ...item,
                isChecked: state.firstList.selected.some((k) => k.value === item.value),
                isVisible: search(state.firstList.q, item.label)
              };
            }),
          selected: state.firstList.selected,
          q: state.firstList.q || ''
        },
        secondList: {
          data: action.payload.secondList.data
            .filter((item) => {
              return !state.firstList.data.some((k) => k.value === item.value);
            })
            .map((item) => {
              return {
                ...item,
                isChecked: state.secondList.selected.some((k) => k.value === item.value),
                isVisible: search(state.secondList.q, item.label)
              };
            }),
          selected: state.secondList.selected,
          q: state.secondList.q || ''
        }
      };
    }
    case 'toRight': {
      return {
        secondList: {
          data: [
            ...state.secondList.data,
            ...state.firstList.selected.map((item) => {
              return { ...item, isChecked: false };
            })
          ],
          selected: [],
          q: state.secondList.q
        },
        firstList: {
          data: state.firstList.data.filter((item) => !item.isChecked),
          selected: [],
          q: state.firstList.q
        }
      };
    }
    case 'toLeft': {
      return {
        firstList: {
          data: [
            ...state.firstList.data,
            ...state.secondList.selected.map((item) => {
              return { ...item, isChecked: false };
            })
          ],
          selected: [],
          q: state.firstList.q
        },
        secondList: {
          data: state.secondList.data.filter((item) => !item.isChecked),
          selected: [],
          q: state.secondList.q
        }
      };
    }
    case 'selectedRight':
      return {
        ...state,
        secondList: {
          ...state.secondList,
          selected: action.payload.selected
        }
      };
    case 'selectedLeft':
      return {
        ...state,
        firstList: {
          ...state.firstList,
          selected: action.payload.selected
        }
      };
    case 'selectAllRight':
      return selectAll(state, 'secondList', action);
    case 'selectAllLeft':
      return selectAll(state, 'firstList', action);
    case 'searchInLeft':
      return {
        ...state,
        firstList: {
          ...state.firstList,
          q: action.payload.q,
          data: [
            ...state.firstList.data.map((e) => {
              return { ...e, isVisible: search(action.payload.q, e.label) };
            })
          ]
        }
      };
    case 'searchInRight':
      return {
        ...state,
        secondList: {
          ...state.secondList,
          q: action.payload.q,
          data: [
            ...state.secondList.data.map((e) => {
              return { ...e, isVisible: search(action.payload.q, e.label) };
            })
          ]
        }
      };
    default:
      return state;
  }
};
