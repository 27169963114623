import { FC } from 'react';
import PropTypes from 'prop-types';

import Gap from 'src/components/atoms/Gap';
import Row from 'src/components/atoms/Row/Row';
import SkeletonPiece, {
  SkeletonPieceAlign,
  SkeletonPieceColor
} from 'src/components/atoms/SkeletonPiece';

import { Wrapper } from './styles';
import { SubmissionsTabLoaderProps } from './types';

const SubmissionsTabLoader: FC<SubmissionsTabLoaderProps> = ({ rows }) => (
  <Wrapper data-testid="SubmissionsTabLoader">
    <SkeletonPiece
      width={180}
      height={20}
      align={SkeletonPieceAlign.left}
      color={SkeletonPieceColor.gray200}
    />
    <Gap height={1.5} />
    <Row className="SubmissionsTabLoader__Container" direction="column">
      {Array(rows)
        .fill(0)
        .map((_, i) => {
          return (
            <Row
              className="SubmissionsTabLoader__Item"
              key={i}
              gap={2.75}
              justify="flex-start"
              alignItems="center"
            >
              <SkeletonPiece width={140} height={20} color={SkeletonPieceColor.gray200} />
              <SkeletonPiece width={140} height={20} color={SkeletonPieceColor.gray200} />
              <SkeletonPiece width={140} height={20} color={SkeletonPieceColor.gray200} />
              <SkeletonPiece width={140} height={20} color={SkeletonPieceColor.gray200} />
              <Row className="SubmissionsTabLoader__Buttons" direction="row" gap={1.5}>
                <SkeletonPiece width={20} height={20} color={SkeletonPieceColor.gray200} />
                <SkeletonPiece width={20} height={20} color={SkeletonPieceColor.gray200} />
                <SkeletonPiece width={20} height={20} color={SkeletonPieceColor.gray200} />
              </Row>
            </Row>
          );
        })}
    </Row>
  </Wrapper>
);

SubmissionsTabLoader.propTypes = {
  rows: PropTypes.number
};

SubmissionsTabLoader.defaultProps = {
  rows: 5
};

export default SubmissionsTabLoader;
