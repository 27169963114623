import PropTypes from 'prop-types';
import React from 'react';
import MultiTab, { MultiTabProps } from 'src/components/molecules/MultiTab/MultiTab';
import { TabBody, TabContent, TabTitle } from 'src/components/molecules/MultiTab/MultiTab';
import { StyledTabHeader } from './styles';

export type PropertyMultitabProps = {
  propertyInfoTab?: React.ReactNode;
  usersTab?: React.ReactNode;
  integrationsTab?: React.ReactNode;
  selectedIndex?: number;
  onSelect?: MultiTabProps['onSelect'];
};
const PropertyMultitab: React.FC<PropertyMultitabProps> = ({
  propertyInfoTab,
  usersTab,
  integrationsTab,
  selectedIndex,
  onSelect
}) => {
  const controlledOptions = selectedIndex !== undefined && { selectedIndex, onSelect };
  return (
    <MultiTab name="property-tabs" {...controlledOptions}>
      <StyledTabHeader aria-label="User Tab">
        <TabTitle>Property Info</TabTitle>
        <TabTitle disabled={!usersTab}>Users</TabTitle>
        <TabTitle disabled={!integrationsTab}>Integrations</TabTitle>
      </StyledTabHeader>
      <TabBody>
        <TabContent>{propertyInfoTab}</TabContent>
        <TabContent>{usersTab}</TabContent>
        <TabContent>{integrationsTab}</TabContent>
      </TabBody>
    </MultiTab>
  );
};
PropertyMultitab.propTypes = {
  propertyInfoTab: PropTypes.node,
  usersTab: PropTypes.node,
  integrationsTab: PropTypes.node,
  selectedIndex: PropTypes.number
};

export default PropertyMultitab;
