import { FC } from 'react';
import { ModalVariant, ModalWidth } from 'src/components/organisms/Modal';
import { IMatchingEntry } from 'src/ts/interfaces';
import PotentialMatchingTile from '../PotentialMatchingTile';
import { StyledModal } from './styles';

export interface PotentialMatchingModalProps {
  matches: IMatchingEntry[];
  isLoading?: boolean;
  folderId?: string;
  entryId?: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PotentialMatchingModal: FC<PotentialMatchingModalProps> = (props) => {
  const { isLoading, folderId, entryId, matches, showModal, setShowModal } = props;

  return (
    <>
      <StyledModal
        variant={ModalVariant.none}
        width={ModalWidth.extraLarge}
        showModal={showModal}
        setShowModal={setShowModal}
        showModalActions={false}
        isEscapeClosable
        showCloseButton
        title="Potential Matching Applicants"
      >
        <PotentialMatchingTile
          className="matching-tile"
          matches={matches}
          folderId={folderId}
          entryId={entryId}
          isLoading={isLoading}
          isModal
        />
      </StyledModal>
    </>
  );
};

export default PotentialMatchingModal;
