import styled from 'styled-components/macro';

import { ThemeAttributes } from 'src/ts/interfaces/styled-components';
import { DotProps, DotColor } from './types';

const getBackgroundColor = (theme: ThemeAttributes, color: DotColor) => {
  switch (color) {
    case 'primary':
      return theme.color.blue[700];
    case 'secondary':
      return theme.color.purple[700];
    case 'error':
      return theme.color.red[700];
    case 'success':
      return theme.color.green[700];
    case 'warning':
      return theme.color.yellow[700];
    default:
      return theme.color.gray[600];
  }
};

export const Container = styled.div<DotProps>`
  background-color: ${({ theme, color }) => getBackgroundColor(theme, color)};
  color: ${({ theme }) => theme.color.white};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
`;
