// Vendor
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import { ShowState } from 'src/components/molecules/ShowState';
import { PropertyForm } from 'src/components/organisms/PropertyForm';
import { PropertyMultitab } from 'src/components/organisms/PropertyMultitab';
import { DetailView } from 'src/components/templates';

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';
import useCompany from 'src/features/company/hooks/useCompany';
import useProperty from 'src/features/property/hooks/useProperty';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';

// Redux
import { selectors as companySelector } from 'src/features/company/companySlice';
import { selectors as propertySelectors } from 'src/features/property/propertySlice';

// Enums
import { PropertyStatus } from 'src/ts/enums';

// Types
import { IProperty } from 'src/ts/interfaces';

const PropertyCreatePage: React.FC = () => {
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();
  const navigate = useNavigate();

  const { isAdmin, isAccountRepresentative } = useRole();
  const { onGetOne: onGetCompany, onResetGetAll: onResetGetAllCompany } = useCompany();

  const companyData = useSelector(companySelector.getOne.data);
  const companyIsIdle = useSelector(companySelector.getOne.isIdle);
  const companyError = useSelector(companySelector.getOne.error);

  const {
    onCreate: onCreateProperty,
    onGetAll: onGetAllProperty,
    onResetCreate: onResetCreateProperty
  } = useProperty();

  const createPropertyData = useSelector(propertySelectors.create.data);
  const createPropertyIsSuccessful = useSelector(propertySelectors.create.isSuccessful);
  const createPropertyError = useSelector(propertySelectors.create.error);

  useEffect(() => {
    return () => {
      onGetAllProperty();
      onResetCreateProperty();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyIsIdle) {
      onGetCompany();
    }
    return () => onResetGetAllCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onGetCompany, companyIsIdle]);

  useEffect(() => {
    if (createPropertyError) {
      showSnackbar(
        VariantType.error,
        'There was an error creating the property. Make sure the information provided is correct'
      );
    }
  }, [SnackTypes, VariantType, createPropertyError, showSnackbar]);

  useEffect(() => {
    if (createPropertyIsSuccessful && createPropertyData?.id) {
      navigate(`/properties/${createPropertyData?.id}/edit`, { state: { from: 'fromCreatePage' } });
    }
  }, [
    createPropertyIsSuccessful,
    createPropertyData,
    navigate,
    SnackTypes,
    VariantType,
    showSnackbar
  ]);

  if (companyError) {
    return (
      <ShowState
        type="information"
        variant="error"
        message="There was an error fetching the company"
        buttonLabel="Reload"
        onClick={() => window.location.reload()}
      />
    );
  }

  const handleOnSave = (formData: IProperty) => {
    onCreateProperty({ ...formData, status: PropertyStatus.ACTIVE });
  };

  return (
    <DetailView back={{ to: '/properties', label: 'Go Back' }} title="Create Property">
      <PropertyMultitab
        propertyInfoTab={
          <PropertyForm
            company={companyData}
            onSave={handleOnSave}
            isCreateForm
            isAdmin={isAdmin || isAccountRepresentative}
          />
        }
      />
    </DetailView>
  );
};

export default PropertyCreatePage;
