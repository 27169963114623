// Vendor
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { CompanyResponse } from '@snapptinc/fraud-platform';

// Helpers
import { mergeArrayByKey } from 'src/helpers';

// Enums
import { Status as StatusTypes } from 'src/ts/enums';

// Types
import {
  ICompaniesResponseWithPage,
  ICompany,
  IPaginationRequest,
  IRejectedAction
} from 'src/ts/interfaces';

// Redux
import { getAll, getCompaniesDDown, getOne, update } from './services';

export type StateData = {
  getCompaniesDDown: {
    status: StatusTypes;
    data?: ICompany[] | null;
    error?: string | null;
    count?: number;
    filter: IPaginationRequest;
  };
  getAll: {
    status: StatusTypes;
    data?: ICompany[] | null;
    error?: string | null;
    count?: number;
    filter: IPaginationRequest;
  };
  getOne: {
    status: StatusTypes;
    data?: ICompany | null;
    error?: string | null;
  };
  update: {
    status: StatusTypes;
    error?: string | null;
    data?: ICompany | null;
  };
};

export const initialState: StateData = {
  getCompaniesDDown: {
    status: StatusTypes.IDLE,
    data: null,
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 20,
      sort: 'name:asc'
    }
  },
  getAll: {
    status: StatusTypes.IDLE,
    data: null,
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 20,
      sort: 'name:asc'
    }
  },
  getOne: {
    status: StatusTypes.IDLE,
    data: null,
    error: null
  },
  update: {
    status: StatusTypes.IDLE,
    data: null,
    error: null
  }
};

export const getAllReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getAll.pending, (state: StateData) => {
    state.getAll.status = StatusTypes.LOADING;
    state.getAll.error = null;
  });
  builder.addCase(getAll.rejected, (state: StateData, action: IRejectedAction) => {
    state.getAll.status = StatusTypes.ERROR;
    state.getAll.error = action.error.message;
  });
  builder.addCase(getAll.fulfilled, (state: StateData, action: PayloadAction<any>) => {
    const { data, count, page, rowsPerPage, q } = action.payload;
    state.getAll.status = StatusTypes.SUCCESS;
    state.getAll.data = data;
    state.getAll.count = count;
    state.getAll.filter.q = q;
    state.getAll.filter.page = page > 0 ? page - 1 : page;
    state.getAll.filter.rowsPerPage = rowsPerPage;
  });
};

export const getOneReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getOne.pending, (state: StateData) => {
    state.getOne.status = StatusTypes.LOADING;
    state.getOne.error = null;
  });
  builder.addCase(getOne.rejected, (state: StateData, action: IRejectedAction) => {
    state.getOne.status = StatusTypes.ERROR;
    state.getOne.error = action.error.message;
  });
  builder.addCase(getOne.fulfilled, (state: StateData, action: PayloadAction<CompanyResponse>) => {
    state.getOne.status = StatusTypes.SUCCESS;
    state.getOne.data = action.payload.data;
  });
};

export const updateReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(update.pending, (state: StateData) => {
    state.update.status = StatusTypes.LOADING;
    state.update.error = null;
  });
  builder.addCase(update.rejected, (state: StateData, action: IRejectedAction) => {
    state.update.status = StatusTypes.ERROR;
    state.update.error = action.error.message;
  });
  builder.addCase(update.fulfilled, (state: StateData, action: PayloadAction<any>) => {
    state.update.status = StatusTypes.SUCCESS;
    state.update.data = action.payload.data;
  });
};

export const getCompaniesDDownReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getCompaniesDDown.pending, (state: StateData) => {
    state.getCompaniesDDown.status = StatusTypes.LOADING;
    state.getCompaniesDDown.error = null;
  });
  builder.addCase(getCompaniesDDown.rejected, (state: StateData, action: IRejectedAction) => {
    state.getCompaniesDDown.status = StatusTypes.ERROR;
    state.getCompaniesDDown.error = action.error.message;
  });
  builder.addCase(
    getCompaniesDDown.fulfilled,
    (state: StateData, action: PayloadAction<ICompaniesResponseWithPage>) => {
      const { data, count, page, rowsPerPage } = action.payload;
      state.getCompaniesDDown.status = StatusTypes.SUCCESS;
      state.getCompaniesDDown.data = mergeArrayByKey(
        state.getCompaniesDDown.data || [],
        data || [],
        'id'
      );
      state.getCompaniesDDown.count = count;
      state.getCompaniesDDown.filter.page = page
        ? page > 0
          ? page - 1
          : page
        : initialState.getCompaniesDDown.filter.page;
      state.getCompaniesDDown.filter.rowsPerPage =
        rowsPerPage || initialState.getCompaniesDDown.filter.rowsPerPage;
    }
  );
};
