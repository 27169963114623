import { useState, useEffect } from 'react';

export const useAsync = (asyncFn: any, initialVal: any) => {
  const [result, setResult] = useState(initialVal);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [isMounted, setIsMounted] = useState(true);
  const callAsyncFn = async (...params: any) => {
    setLoading(true);
    setError(undefined);
    try {
      const response = await asyncFn(...params);
      setResult(response);
      setLoading(false);
      return response;
    } catch (err: any) {
      if (!isMounted) {
        return null;
      }
      setError(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);
  return [{ result, isLoading, error }, callAsyncFn] as Array<any>;
};

export default useAsync;
