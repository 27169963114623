// Vendor
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import { Loader } from 'src/components/atoms/Loader';
import { ShowState } from 'src/components/molecules/ShowState';
import { IFrame } from 'src/components/organisms/IFrame';
import { CenteredContainer } from './styles';

// Helpers
import { configVars, today, todayLessDays } from 'src/helpers';
import generateURLQuery from 'src/helpers/generateURLQuery';

// Hooks
import { useAsync } from 'src/hooks';
import useReporting from '../ReportingPage/hooks/useReporting';

// Redux
import { selectors as apiUserSelectors } from 'src/features/auth/authSlice';

type IReportArg = {
  date_from: string;
  date_to: string;
  team_ids?: string;
  user_ids?: string;
};

const todayMinus7Days = todayLessDays(7);

const MyWorkPerformancePage: React.FC = () => {
  const user = useSelector(apiUserSelectors.user);
  const userIsIdle = useSelector(apiUserSelectors.isIdle);
  const userIsLoading = useSelector(apiUserSelectors.isLoading);

  const { fetchIframeUrl } = useReporting();

  const generateReport = async () => {
    const params: IReportArg = {
      date_from: todayMinus7Days,
      date_to: today,
      team_ids: user?.team_id,
      user_ids: user?.id
    };

    const reportParams = configVars.metabase?.dashboards?.my_work_performance;

    if (reportParams) {
      const { id, report_type } = reportParams;
      const result = await fetchIframeUrl({
        id,
        payload: {
          filter: generateURLQuery(params),
          report_type
        },
        isFiltered: true
      });
      return result;
    }
  };

  const [{ result, error }, callGenerateReport] = useAsync(generateReport, '');

  useEffect(() => {
    if (!userIsIdle && !userIsLoading) callGenerateReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsIdle]);

  if (error) {
    return (
      <ShowState
        type="information"
        variant="error"
        message={error as string}
        buttonLabel="Reload"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (!result.data?.iframe_url) {
    return (
      <CenteredContainer>
        <Loader />
      </CenteredContainer>
    );
  }

  return <IFrame url={result.data?.iframe_url} title="My Work Performance" />;
};

export default MyWorkPerformancePage;
