/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  StyledContainer,
  StyledUploadContainer,
  StyledUploadLabel,
  StyledIconExternal,
  StyledImageContainer,
  StyledImage,
  HelperTextOptional
} from './styles';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { Controller, RegisterOptions } from 'react-hook-form';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import Gap from 'src/components/atoms/Gap';

export type FileInputFieldProps = {
  title: string;
  name: string;
  inputFileName: string;
  config?: RegisterOptions;
  isDisabled?: boolean;
};
const PUBLIC_URL = process.env.PUBLIC_URL;
const MAX_IMAGE_SIZE = 5; // MB
const ALLOWED_IMAGES_FORMAT_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];
const MB_TO_BYTE = Math.pow(2, 20);

const FileInputField = (props: FileInputFieldProps) => {
  const { title, inputFileName, name, config, isDisabled } = props;
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();
  const [url, setUrl] = useState<string | null>();
  const [fileUploadError, setFileUploadError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const onChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
    onChangeController: (value: any) => void
  ) => {
    if (evt.target.files) {
      const fileUploaded = evt.target.files[0];

      if (!fileUploaded) return;
      if (
        ALLOWED_IMAGES_FORMAT_TYPES.includes(fileUploaded.type) &&
        fileUploaded.size <= MAX_IMAGE_SIZE * MB_TO_BYTE
      ) {
        const reader = new FileReader();
        reader.addEventListener('load', (e) => {
          const result = e?.target?.result;
          if (typeof result === 'string') {
            setUrl(result);
          } else {
            setUrl(result?.toString() ?? '');
          }
        });
        reader.readAsDataURL(fileUploaded);
        onChangeController(fileUploaded);
      } else {
        setFileUploadError(true);
        setErrorMsg(
          `Upload failed. The only accepted file types are .jpg, .jpeg or .png up to ${MAX_IMAGE_SIZE} MB. Please, try again.`
        );
        onChangeController('');
      }
    }
  };

  const resetError = () => {
    setFileUploadError(false);
    setErrorMsg('');
  };

  useEffect(() => {
    if (fileUploadError) showSnackbar(VariantType.error, errorMsg, SnackTypes.none);
  }, [SnackTypes.none, VariantType.error, errorMsg, fileUploadError, showSnackbar]);

  const getLogoImage = (value: any) => {
    if (value instanceof File) {
      return url;
    }
    return value || `${PUBLIC_URL}/assets/images/default-logo.svg`;
  };

  return (
    <Controller
      render={({ field: { onChange: onChangeController, value } }) => {
        return (
          <StyledContainer>
            <Text color={TextColor.gray700} variant={TextVariant.normal}>
              {title}
            </Text>
            <StyledImageContainer>
              <StyledImage src={getLogoImage(value)} alt={title} />
            </StyledImageContainer>
            <StyledUploadContainer>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id={inputFileName}
                type="file"
                onChange={(evt) => {
                  onChange(evt, onChangeController);
                }}
                //empty value before trying to add a file again
                onClick={(evt: any) => (evt.target.value = null)}
                name={inputFileName}
              />
              {!isDisabled && (
                <label htmlFor={inputFileName} onClick={resetError}>
                  <StyledUploadLabel>
                    <Text color={TextColor.primary} variant={TextVariant.normal}>
                      {value ? 'Replace' : 'Upload'}
                    </Text>
                    {/* TODO: REPLACE ICON BELOW WITH ICON ATOM AND PASS ONLY ICON NAME */}
                    <StyledIconExternal src={`${PUBLIC_URL}/assets/images/icon-cloud-upload.svg`} />
                  </StyledUploadLabel>
                </label>
              )}
            </StyledUploadContainer>
            {!config?.required && (
              <HelperTextOptional>
                <Text variant={TextVariant.small} color={TextColor.muted}>
                  Optional
                </Text>
                <Gap height={0.5} />
                <Text variant={TextVariant.small} color={TextColor.muted}>
                  Max size: {MAX_IMAGE_SIZE}MB
                </Text>
              </HelperTextOptional>
            )}
          </StyledContainer>
        );
      }}
      name={name}
      defaultValue=""
      rules={config}
    />
  );
};

FileInputField.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  inputFileName: PropTypes.string.isRequired,
  config: PropTypes.object,
  isDisabled: PropTypes.bool
};

FileInputField.defaultProps = {
  name: 'FileInputField',
  title: 'FormInputField Logo',
  inputFileName: 'update-logo-image',
  config: {},
  isDisabled: false
};

export default FileInputField;
