// Vendor
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import Link, { LinkTarget } from 'src/components/atoms/Link';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { ToolTip } from 'src/components/atoms/ToolTip';
import { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import LoaderMatchingTile from 'src/components/molecules/LoaderMatchingTile';
import { ShowState } from 'src/components/molecules/ShowState';
import { List, PersonIcon, StyledCard, StyledTag, StyleRow } from './styles';

// Types
import { IMatchingEntry } from 'src/ts/interfaces';

// Helpers
import { Icon } from 'src/components/atoms/Icon';
import { configVars, dateFormat } from 'src/helpers';

// Hooks
import { useEntryAssignedToAnother } from 'src/features/myWork/hooks/useEntryAssignedToAnother';
import useUserRoles from 'src/features/auth/hooks/useUserRoles';

export interface PotentialMatchingTileProps {
  matches: IMatchingEntry[];
  entryId?: string;
  folderId?: string;
  isLoading?: boolean;
  isModal?: boolean;
  className?: string;
}

const PotentialMatchingTile: FC<PotentialMatchingTileProps> = (props) => {
  const { matches, entryId, folderId, isModal, className, isLoading } = props;
  const location = useLocation();

  const { checkIsAssignedToAnotherUser } = useEntryAssignedToAnother();

  const { isAccountRepresentative } = useUserRoles();

  const redirect_uri =
    location.pathname.indexOf('/my-work/') >= 0
      ? `?redirect_uri=${configVars.frontend_url}/my-work/${folderId}`
      : '';

  if (isLoading) {
    return <LoaderMatchingTile isModal={isModal || false} />;
  }

  if (!matches.length) {
    return (
      <StyledCard rounded border className={className}>
        <ShowState variant="empty" type="user" message="No potential <br /> Matching applicants" />
      </StyledCard>
    );
  }

  return (
    <StyledCard rounded border={!isModal} className={className}>
      {!isModal && (
        <Text variant={TextVariant.h3} color={TextColor.gray500}>
          Potential Matching Applicants
        </Text>
      )}
      <List data-testid="list">
        {matches.map((match) => {
          const isAssignedToAnotherUser = checkIsAssignedToAnotherUser(
            match?.review_assigned_to_id
          );
          return (
            <StyleRow key={match.entry_id}>
              <Row.Col size={6} alignSelf="center">
                <Row alignItems="center" gap={0.5}>
                  {isAssignedToAnotherUser && (
                    <ToolTip
                      direction={ToolTipDirection.right}
                      content="This entry is being reviewed"
                    >
                      <Icon icon="status-warning" />
                    </ToolTip>
                  )}
                  <PersonIcon />
                  <Link
                    to={`/entries/${entryId}/merge/${match.entry_id}${redirect_uri}`}
                    target={LinkTarget.self}
                    isDisabled={isAccountRepresentative}
                  >
                    {match.applicant_full_name}
                  </Link>
                </Row>
              </Row.Col>
              <Row.Col size={3} alignSelf="center">
                <Text>{dateFormat(match.submission_time)}</Text>
              </Row.Col>
              <Row.Col size={3} alignSelf="center">
                <StyledTag data-testid="tag" label={`${match.percentage}` as string} />
              </Row.Col>
            </StyleRow>
          );
        })}
      </List>
    </StyledCard>
  );
};

export default PotentialMatchingTile;
