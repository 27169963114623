import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import { ToolTip } from 'src/components/atoms/ToolTip';
import { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import { Filter, SelectedFilterData } from 'src/components/organisms/Filter';
import InputSearch from 'src/components/organisms/InputSearch';
import { SelectedSortData, Sort } from 'src/components/organisms/Sort';
import { useGetIdentityApplicants } from 'src/features/identityVerification/hooks';
import { selectors as identityApplicantsSelectors } from 'src/features/identityVerification/identityVerificationSlice';
import { defaultValues } from './constants';
import { Container } from './styles';
import useGetSelectedData from './useGetSelectedData';

const FDEInboxIdentityVerificationToolbar: FC = () => {
  const filters = useSelector(identityApplicantsSelectors.getIdentityApplicants.filters);
  const { filtering, sorting, searching } = useGetIdentityApplicants();
  const { selectedFilterData, selectedSortData, buildFilterQuery } = useGetSelectedData();

  const handleSearch = (value?: string) => {
    searching(value || undefined);
  };

  const handleFilter = (selected: SelectedFilterData) => {
    const query = buildFilterQuery(selected) || undefined;

    filtering(query);
  };

  const handleSort = (selected: SelectedSortData) => {
    const query = selected?.field ? `${selected?.field}:${selected?.order}` : undefined;

    sorting(query);
  };

  return (
    <Container>
      <Row alignItems="center" gap={1.5}>
        <InputSearch
          name="search"
          value={filters?.q || ''}
          placeholder="Search Identity"
          onSearch={({ value }) => handleSearch(value as string)}
          onChange={({ value }) => value === '' && handleSearch()}
          onClear={handleSearch}
          isDisabled={false}
        />
        <Filter
          filterText="Filter will be applied in all results."
          dateFilterTitle="By Results Received Date"
          data={defaultValues.filterPanel}
          selected={selectedFilterData}
          onSelect={handleFilter}
          isDisabled={false}
        />
        <Sort
          data={defaultValues.sortPanel}
          selected={selectedSortData}
          onSelect={handleSort}
          isDisabled={false}
        />
        <ToolTip
          direction={ToolTipDirection.bottom_fit}
          content="Sorting and filtering will be applied in all results."
        >
          <Icon icon="help" />
        </ToolTip>
      </Row>
    </Container>
  );
};

export default FDEInboxIdentityVerificationToolbar;
