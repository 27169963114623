import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import { Pagination } from 'src/components/molecules/Pagination';
import { useGetIdentityApplicants } from 'src/features/identityVerification/hooks';
import { useSelector } from 'react-redux';
import { selectors } from 'src/features/identityVerification/identityVerificationSlice';

const FolderListPagination = () => {
  const filters = useSelector(selectors.getIdentityApplicants.filters);
  const data = useSelector(selectors.getIdentityApplicants.data);
  const isError = useSelector(selectors.getIdentityApplicants.isError);

  const { paginating } = useGetIdentityApplicants();

  if (isError || data?.length === 0) return null;

  return (
    <>
      <Line height="tiny" />
      <Row direction="column" className="paginator__ListView">
        <Pagination
          name="FDEInboxIdentityVerificationPagination"
          rowsPerPage={[5, 10, 20, 50, 100, 250, 500]}
          pageInit={filters?.page}
          rowsPerPageInit={filters?.rowsPerPage || 50}
          listPosition="top"
          rows={data?.length}
          onNext={(page, rowsPerPage) => paginating(page, rowsPerPage)}
          onPrev={(page, rowsPerPage) => paginating(page, rowsPerPage)}
          onRowsPerPage={paginating}
        />
      </Row>
    </>
  );
};

export default FolderListPagination;
