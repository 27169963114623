// Vendor
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import SkeletonPiece, { SkeletonPieceColor } from 'src/components/atoms/SkeletonPiece';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { Filter, SelectedFilterData } from 'src/components/organisms/Filter';
import { EntryLogTable } from '../EntryLogTable';
import { StyledInputSearch } from './styles';

// Types
import { defaultValues } from 'src/features/entriesLogs';

// Enums
import { EntryResultEnum, FeatureFlagKey } from 'src/ts/enums';

// Redux
import { selectors as userSelectors } from 'src/features/auth/authSlice';

// Hooks
import { useEntriesLogsData, useGetEntriesLogs, useSetEntriesLogs } from 'src/features/entriesLogs';
import useGetSelectedData from 'src/features/entriesLogs/hooks/useGetSelectedData';
import { useFeatureFlag } from 'src/hooks';

// Component to show reviewer entries logs history
const EntryLogsHistory = (): JSX.Element => {
  const user = useSelector(userSelectors.user);

  const { filters, isToolbarDisabled, isLoading } = useEntriesLogsData();
  const { onGetEntriesLogs } = useGetEntriesLogs();
  const { filtering, searching: handleSearch } = useSetEntriesLogs();
  const { selectedFilterData, buildFilterQuery } = useGetSelectedData();

  const cleanProceedWithCautionFlagEnabled = useFeatureFlag(
    FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION
  );

  const filterPanelData = {
    ...defaultValues.filterPanel,
    checkboxGroup: cleanProceedWithCautionFlagEnabled
      ? defaultValues.filterPanel.checkboxGroup
      : defaultValues.filterPanel.checkboxGroup.filter(
          (option) => option.value !== EntryResultEnum.CleanProceedWithCaution
        )
  };

  // Effect to handle filters change and query the API to update data
  useEffect(() => {
    // Since we only want to retrieve users's logs for submitted entries, we need to add the query for that.
    const baseQuery = `type=SUBMITTED,reviewer_id=${user?.id}`;
    const filterWithBaseQuery = filters.filter ? `${baseQuery},${filters.filter}` : baseQuery;
    onGetEntriesLogs({ ...filters, filter: filterWithBaseQuery });
  }, [onGetEntriesLogs, filters, user?.id]);

  // Handle filtering
  const handleFilterChange = (filter: SelectedFilterData) => {
    const query = buildFilterQuery(filter || undefined);
    filtering(query);
  };

  const Title = ({ isLoading }: { isLoading: boolean }) => {
    return isLoading ? (
      <SkeletonPiece
        width={150}
        height={40}
        color={SkeletonPieceColor.gray100}
        className="noPadding"
      />
    ) : (
      <Text variant={TextVariant.h4}>History</Text>
    );
  };

  return (
    <>
      <Gap />
      <Row alignItems="center" justify="space-between">
        <Title isLoading={isLoading} />
        <Row gap={1}>
          <StyledInputSearch
            placeholder="Search by Entries"
            name="search"
            value={filters.q || ''}
            onSearch={({ value }) => handleSearch(value || undefined)}
            onChange={({ value }) => value === '' && handleSearch()}
            onClear={handleSearch}
            isDisabled={isToolbarDisabled}
            isLoading={isLoading}
          />

          <Filter
            dateFilterTitle="By Ruling Date"
            data={filterPanelData}
            selected={selectedFilterData}
            onSelect={handleFilterChange}
            isDisabled={isToolbarDisabled}
            isLoading={isLoading}
          />
        </Row>
      </Row>
      <Gap />
      <EntryLogTable />
    </>
  );
};

export default EntryLogsHistory;
