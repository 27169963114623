import { useEffect } from 'react';

import { useEntryById } from 'src/features/entries/hooks';
import { getEntryByIdThunk } from 'src/features/entries/services';
import { useAppDispatch } from 'src/store';

export const useGetEntryAndMatchingEntryById = (matchingEntryId: string, entryId: string) => {
  const dispatch = useAppDispatch();
  const { entry, isLoading, isError } = useEntryById(entryId);
  const {
    entry: matchingEntry,
    isLoading: isLoadingMatchingEntry,
    isError: isErrorMatchingEntry
  } = useEntryById(matchingEntryId);

  useEffect(() => {
    // If Applicant Entry exist on reducer, get only the potential Entry
    if (!matchingEntry) {
      if (!entry) {
        dispatch(getEntryByIdThunk(entryId));
      }
      dispatch(getEntryByIdThunk(matchingEntryId));
    }
  }, [dispatch, entry, entryId, matchingEntry, matchingEntryId]);

  return {
    entry,
    matchingEntry,
    isLoading: isLoading || isLoadingMatchingEntry,
    isError: isError || isErrorMatchingEntry
  };
};
