// Components
import { Card } from 'src/components/atoms/Card';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import Link, { LinkTarget } from 'src/components/atoms/Link';
import List, { ListItemProps, ListTypes } from 'src/components/atoms/List/List';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { Container, TextContainer } from './styles';

// Hooks
import useLanguage from 'src/context/Language/useLanguage';
import { FormValues } from 'src/features/DUP/form/hooks/useForm';

// Types
import { ITextKeys as ITextLangKeys } from 'src/context/Language/types';
import {
  IInformation,
  ISessionPropertySupportedDoctypes
} from 'src/features/DUP/form/ts/interfaces';

// Enums
import { getOldDocumentTypes } from 'src/features/DUP/helpers/getDocumentTypes';
import { DupTypes } from 'src/features/DUP/ts/enums';

export type UploadInstructionsProps = {
  type: DupTypes;
  information: Pick<
    IInformation,
    'instruction_upload_item_one' | 'instruction_upload_item_two' | 'instruction_upload_item_three'
  >;
  dataForm: Pick<FormValues, 'property'>;
};

const getTextInstructions = (
  dataForm: Pick<FormValues, 'property'>,
  type: DupTypes,
  t: (key: ITextLangKeys, ...args: string[]) => string
) => {
  if (!dataForm?.property) return '';
  const supported_doctypes = dataForm.property.supported_doctypes || {};

  let head = t('dup_instructions_without_amount');
  let text = '';

  for (const kdoctype in supported_doctypes) {
    const doctype = kdoctype as keyof ISessionPropertySupportedDoctypes;
    const count = supported_doctypes[doctype];

    const oldDocumentTypes = getOldDocumentTypes().map(({ key }) => key);

    if (!oldDocumentTypes.includes(doctype)) {
      continue;
    }
    if (count && count > 0) {
      head = t('dup_instructions_with_amount');

      const amountStr = `${count}`;
      const suffix = count > 1 ? 's' : '';
      const message = t(
        `dup_instructions_most_recent${suffix}`,
        amountStr,
        t(`dup_document_type_${doctype}${suffix}`)
      );
      text += `<li>${message}</li>`;
    }
  }

  return `${head}<ul>${text}</ul>`;
};

const UploadInstructions: React.FC<UploadInstructionsProps> = (props: UploadInstructionsProps) => {
  const { type, information, dataForm } = props;
  const { translate: t } = useLanguage();

  if (!dataForm.property?.id) return null;

  const listItems: ListItemProps[] = [
    {
      text: information.instruction_upload_item_one
    },
    {
      text: information.instruction_upload_item_two
    },
    ...(information.instruction_upload_item_three
      ? [
          {
            text: information.instruction_upload_item_three
          }
        ]
      : [])
  ];

  return (
    <Container>
      <Row justify="space-between" alignItems="center">
        <Text variant={TextVariant.h3} color={TextColor.initial}>
          {t('dup_instructions_title')}
        </Text>
        {type !== DupTypes.AOA && (
          <Link to="https://vimeo.com/645372425" target={LinkTarget.blank} isExternal>
            <Row gap={0.5} alignItems="center">
              <Text color="primary" isBold variant={TextVariant.small}>
                {t('dup_instructions_how_to')}
              </Text>
              <Icon icon="launch" color="primary" />
            </Row>
          </Link>
        )}
      </Row>
      <Gap height={2} />
      <TextContainer type={type}>
        <Row columns={2} gap={3}>
          <Row.Col size={1}>
            <Card border alignContent="center">
              <Icon icon="icon-cloud-upload" />
              <div
                dangerouslySetInnerHTML={{
                  __html: getTextInstructions(dataForm, type, t)
                }}
                className="alignLeft"
              />
            </Card>
          </Row.Col>
          <Row.Col size={1} className="items" data-type={type}>
            <Card background="blue">
              <List type={ListTypes.UNORDERED} items={listItems} />
            </Card>
          </Row.Col>
        </Row>
      </TextContainer>
    </Container>
  );
};

export default UploadInstructions;
