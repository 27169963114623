import dup from 'src/components/DUP/i18n/en.json';
const TEXTS = {
  language_en: 'Inglés',
  language_es: 'Español',

  'validation_should_be_#_or_more': 'Should be %1 or more',
  'validation_should_be_less_than_#_characters': 'Should be less than %1 characters',
  validation_complete_this_field: 'Complete this field',
  'validation_#_characters_maximum': '%1 Characters Maximum',
  validation_should_not_contain_only_numbers_and_symbols:
    'Should not contain only numbers and symbols',
  validation_should_be_only_one_character: 'Should be only one character',
  validation_should_contain_a_capital_letter: 'Should contain a capital letter',

  message_Yes: 'Yes',
  message_No: 'No',

  ...dup
};

export default TEXTS;
