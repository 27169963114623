import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { IRejectedAction, IUser } from 'src/ts/interfaces';
import { ICompany } from 'src/ts/interfaces/company';
import { NAME } from './constants';
import {
  createUserAndCompanyThunk,
  resetPasswordThunk,
  updateAnnouncementThunk,
  updateMyUserThunk
} from './services';

type StateData = {
  user?: IUser | null;
  company?: ICompany | null;
  status: StatusTypes;
  passwordResetStatus: StatusTypes;
  error?: string | null;
  count?: number | null;
  page?: number;
  rowsPerPage?: number | null;
  process: any;
};

type StateDataForm = {
  process: {
    createUserAndCompany: {
      status: StatusTypes;
      error: string | null;
    };
    updateAnnouncement: {
      status: StatusTypes;
      error: string | null;
    };
  };
};

export const initialStateForm: StateDataForm = {
  process: {
    createUserAndCompany: {
      status: StatusTypes.IDLE,
      error: null
    },
    updateAnnouncement: {
      status: StatusTypes.IDLE,
      error: null
    }
  }
};

const initialState = {
  user: null,
  company: null,
  status: StatusTypes.IDLE,
  passwordResetStatus: StatusTypes.IDLE,
  error: null,
  process: {
    ...initialStateForm.process
  }
};

const loadingHandler = (state: StateData) => {
  state.status = StatusTypes.LOADING;
  state.error = null;
};

const errorHandler = (state: StateData, action: IRejectedAction) => {
  state.status = StatusTypes.ERROR;
  state.error = action.error?.message;
};

const passwordResetLoadingHandler = (state: StateData) => {
  state.passwordResetStatus = StatusTypes.LOADING;
  state.error = null;
};

const passwordErrorHandler = (state: StateData, action: IRejectedAction) => {
  state.passwordResetStatus = StatusTypes.ERROR;
  state.error = action.error?.message;
};

const updateMyUserReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(updateMyUserThunk.pending, loadingHandler);
  builder.addCase(updateMyUserThunk.rejected, errorHandler);
  builder.addCase(updateMyUserThunk.fulfilled, (state, action) => {
    const { payload } = action;
    state.status = StatusTypes.SUCCESS;
    state.user = payload as IUser;
  });
};

const createUserAndCompanyReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(createUserAndCompanyThunk.pending, (state: StateDataForm) => {
    state.process.createUserAndCompany.status = StatusTypes.LOADING;
    state.process.createUserAndCompany.error = null;
  });
  builder.addCase(
    createUserAndCompanyThunk.rejected,
    (state: StateDataForm, action: IRejectedAction) => {
      state.process.createUserAndCompany.status = StatusTypes.ERROR;
      state.process.createUserAndCompany.error = action.error.message || null;
    }
  );
  builder.addCase(createUserAndCompanyThunk.fulfilled, (state) => {
    state.process.createUserAndCompany.status = StatusTypes.SUCCESS;
  });
};

const resetPasswordReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(resetPasswordThunk.pending, passwordResetLoadingHandler);
  builder.addCase(resetPasswordThunk.rejected, passwordErrorHandler);
  builder.addCase(resetPasswordThunk.fulfilled, (state) => {
    state.passwordResetStatus = StatusTypes.SUCCESS;
  });
};

const updateAnnouncementReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(updateAnnouncementThunk.pending, (state: StateDataForm) => {
    state.process.updateAnnouncement.status = StatusTypes.LOADING;
    state.process.updateAnnouncement.error = null;
  });
  builder.addCase(
    updateAnnouncementThunk.rejected,
    (state: StateDataForm, action: IRejectedAction) => {
      state.process.updateAnnouncement.status = StatusTypes.ERROR;
      state.process.updateAnnouncement.error = action.error.message || null;
    }
  );
  builder.addCase(updateAnnouncementThunk.fulfilled, (state) => {
    state.process.updateAnnouncement.status = StatusTypes.SUCCESS;
  });
};

const userSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetInitialState: (state) => {
      state.status = StatusTypes.IDLE;
    },
    resetPasswordState: (state) => {
      state.passwordResetStatus = StatusTypes.IDLE;
    },
    resetCompanyAndUserState: (state) => {
      state.process.createUserAndCompany = initialStateForm.process.createUserAndCompany;
    },
    resetUpdateAnnouncement: (state) => {
      state.process.updateAnnouncement = initialStateForm.process.updateAnnouncement;
    }
  },
  extraReducers: (builder) => {
    updateMyUserReducer(builder);
    createUserAndCompanyReducer(builder);
    resetPasswordReducer(builder);
    updateAnnouncementReducer(builder);
  }
});

export const selectors = (state: RootState) => ({
  user: state[NAME].user,
  status: state[NAME].status,
  passwordResetStatus: state[NAME].passwordResetStatus,
  error: state[NAME].error,
  createUserAndCompany: state[NAME].process.createUserAndCompany,
  updateAnnouncement: state[NAME].process.updateAnnouncement
});

export const { reducer, actions } = userSlice;
