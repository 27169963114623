import styled from 'styled-components';
import { TypeIcon } from '.';

export const Container = styled.div<{ type: TypeIcon }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 15px 17px 15px 17px;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.color.gray[200]};
  background: #fff;
`;

export const Icon = styled.div<{ color: string }>`
  margin-right: 10px;
  border: 3px solid ${({ color }) => color};
  border-radius: 50%;
  height: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-top: 4px;
  }
`;

export const Description = styled.div`
  p {
    white-space: nowrap;
  }
`;
