import { useMemo } from 'react';

import Modal, { ModalVariant } from 'src/components/organisms/Modal';

import { useSendReport } from 'src/features/entries/hooks';
import { useFolderPage } from '../FolderPageContext';

const SendReportModal = ({ onConfirm }: { onConfirm?: () => void }): JSX.Element => {
  const { selectedEntryId, showSendReportModal, setShowSendReportModal } = useFolderPage();
  const { onSendReport } = useSendReport();

  const sendReportModalProps = useMemo(
    () => ({
      title: 'Send Report',
      labelButtonConfirm: 'Send Report',
      text: 'Are you sure you want to send this report?',
      showModal: showSendReportModal,
      setShowModal: setShowSendReportModal,
      onConfirm: async () => {
        setShowSendReportModal?.(false);

        await onSendReport(selectedEntryId);
        if (onConfirm) {
          onConfirm();
        }
      }
    }),
    [onSendReport, selectedEntryId, setShowSendReportModal, showSendReportModal, onConfirm]
  );

  return <Modal {...sendReportModalProps} variant={ModalVariant.none} labelButtonCancel="Cancel" />;
};

export default SendReportModal;
