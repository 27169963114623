import { invitationApi } from 'src/api';
import {
  getPaginationRequest,
  IInvitationRequest,
  IInvitationUserRequest
} from 'src/ts/interfaces';

const api = {
  getAll: async ({ page, rowsPerPage, filter, sort, q }: getPaginationRequest) => {
    // TODO: page and rowsPerPage should comes as number
    const pageNum = page?.toString();
    const rowsPerPageNum = rowsPerPage?.toString();
    return await invitationApi.getInvitations(pageNum, rowsPerPageNum, filter, sort, q);
  },
  delete: async (invitationId: string) => {
    // TODO: Should return something
    return await invitationApi.deleteInvitation(invitationId);
  },
  resendInvitation: async (invitationId: string) => {
    // TODO: Should return something
    return await invitationApi.resendInvitation(invitationId);
  },
  getById: (id: string) => invitationApi.getInvitation(id),
  createUser: (id: string, input: IInvitationUserRequest) =>
    invitationApi.createUserFromInvitation(id, input),
  create: (data: IInvitationRequest) => invitationApi.createInvitation(data)
};

export default api;
