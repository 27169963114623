import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import { Pagination } from 'src/components/molecules/Pagination';
import { selectors, useInvitations } from 'src/features/invitation';

const InvitationsPagination: React.FC<{ filter?: string }> = ({ filter }) => {
  const { onGetAll: onGetInvitations } = useInvitations();

  const getInvitationsData = useSelector(selectors.getAll.data);
  const getInvitationsError = useSelector(selectors.getAll.error);
  const getInvitationsFilter = useSelector(selectors.getAll.filter);
  const getInvitationsCount = useSelector(selectors.getAll.count);

  if (getInvitationsError) return null;
  if (!getInvitationsData?.length) return null;

  return (
    <>
      <Line height="tiny" />
      <Row direction="column" className="paginator__ListView">
        <Pagination
          name="InvitationsPagination"
          rowsPerPage={[5, 10, 20, 50, 100, 250, 500]}
          listPosition="top"
          pageInit={getInvitationsFilter.page}
          rowsPerPageInit={getInvitationsFilter.rowsPerPage}
          totalRows={getInvitationsCount}
          onNext={(page, rowsPerPage) => onGetInvitations(page, rowsPerPage, filter)}
          onPrev={(page, rowsPerPage) => onGetInvitations(page, rowsPerPage, filter)}
          onRowsPerPage={(page, rowsPerPage) => onGetInvitations(page, rowsPerPage, filter)}
        />
      </Row>
    </>
  );
};

InvitationsPagination.propTypes = {
  filter: PropTypes.string
};

export default InvitationsPagination;
