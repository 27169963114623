import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { NAME } from './constants';

type StateData = {
  isOpenSidebar: boolean;
};

const initialState: StateData = {
  isOpenSidebar: true
};

const frameworkUI = createSlice({
  name: NAME,
  initialState,
  reducers: {
    ui: (state: StateData, action: PayloadAction<StateData>) => {
      state.isOpenSidebar = !action.payload.isOpenSidebar;
    }
  }
});

export const selectors = {
  ui: (state: RootState) => state[NAME]
};

export const { reducer, actions } = frameworkUI;
