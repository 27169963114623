import styled from 'styled-components/macro';

export const Container = styled.div`
  .header_spacing_option_true ul[role='tablist'] {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .react-tabs__tab-list {
    border-color: transparent;
    margin-bottom: 1rem;
  }

  .react-tabs__tab-panel--selected {
    ${({ theme }) => theme.font.normal};
    min-height: 10rem;
  }

  .react-tabs__tab {
    border: none;
    bottom: -0.125rem;
    ${({ theme }) => theme.font.normal};
    &:focus {
      box-shadow: none;
      border-bottom: 0.125rem solid ${({ theme }) => theme.color.blue[600]};
      &:after {
        bottom: 0;
      }
    }
  }

  .react-tabs__tab--selected {
    color: ${({ theme }) => theme.color.black.normal};
    border-radius: 0;
    border-bottom: 0.125rem solid ${({ theme }) => theme.color.blue[600]};
  }

  .react-tabs__tab--disabled {
    color: ${({ theme }) => theme.color.gray[200]};
  }
`;

export const Content = styled.div`
  margin-top: 1rem;
`;
