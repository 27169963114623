import styled from 'styled-components/macro';

export const StyledContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledTitleContainer = styled.div<any>`
  display: flex;
  align-items: center;
  width: 25%;
  justify-content: space-between;
`;

export const StyledContent = styled.div<any>`
  width: 100%;
  margin-top: 1.875rem;
  padding: 1rem;
`;

export const StyledCompanyInfo = styled.div<any>`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const StyledDetailsRow = styled.div<any>`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 1.5rem;
`;

export const StyledDetailsContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  width: 50%;
  min-width: 50%;
  max-width: 100%;
`;

export const StyledLogoContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
