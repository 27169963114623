// Vendor
import { format } from 'date-fns';
import styled from 'styled-components';

// Components
import Tag from 'src/components/atoms/Tag';

// Helpers
import { getResultStatusColorTag, transformResultToReadable } from 'src/features/fdeInbox/helpers';

// Types
import { TableProps } from 'src/components/molecules/Table';
import { EntryResultType, IEntryLog } from 'src/ts/interfaces';

type TableEntryLog = {
  folderName?: string;
  workflowType?: string;
  result?: EntryResultType;
  submissionTime?: string;
  rulingTime?: string;
};

const columnNames = {
  folderName: 'Folder Name',
  workflowType: 'Review Type',
  result: 'Result',
  rulingTime: 'Ruling Time',
  submissionTime: 'Submission Time'
} as const;

// Format submission entries timestamps to readable format
const formatEntriesTimestamps = (entryLogs: TableEntryLog[]): TableEntryLog[] => {
  return entryLogs.map((entryLog) => {
    const { submissionTime, rulingTime } = entryLog;
    const formattedSubmissionTime = submissionTime
      ? format(new Date(submissionTime), 'MM/dd/yyyy HH:mm')
      : 'No Date';
    const formattedRulingTime = rulingTime
      ? format(new Date(rulingTime), 'MM/dd/yyyy HH:mm')
      : 'No Date';

    return {
      ...entryLog,
      submissionTime: formattedSubmissionTime,
      rulingTime: formattedRulingTime
    };
  });
};

const StyledTag = styled(Tag)`
  height: 32px;
`;

// Add the Tags components to the submission entries data
const addResultTags = (
  entryLogs: TableEntryLog[],
  cleanProceedWithCautionFlagEnabled?: boolean
) => {
  return entryLogs.map((entryLog) => {
    if (entryLog.workflowType === 'Income Verification') {
      return entryLog;
    }
    const ResultTag = (
      <StyledTag
        label={transformResultToReadable(entryLog?.result)}
        color={getResultStatusColorTag(entryLog?.result, cleanProceedWithCautionFlagEnabled)}
        showBackground
      />
    );
    return {
      ...entryLog,
      result: ResultTag
    };
  });
};

// Format columns data to be displayed in table
export const getColumnsData = (): TableProps['columns'] => {
  const columns = Object.keys(columnNames).map((key) => {
    const label = columnNames[key as keyof TableEntryLog] || key;
    if (key === 'result') return { label, field: key, width: 240 };
    return { label, field: key };
  });
  return columns;
};

// Given a list of entry logs, return the data required for the table
export const getTableData = (
  entryLogs: IEntryLog[],
  cleanProceedWithCautionFlagEnabled?: boolean
): TableProps => {
  const tableData: TableEntryLog[] = entryLogs.map((log) => ({
    folderName: log?.entry?.folder?.name,
    workflowType:
      log?.workflow === 'income_verification' ? 'Income Verification' : 'Fraud Detection',
    result: log?.entry_result as EntryResultType,
    submissionTime: log?.inserted_at,
    rulingTime: log.entry?.folder?.ruling_time
  }));

  const columns = getColumnsData();
  const dataWithFormattedDates = formatEntriesTimestamps(tableData);
  const dataWithResultTags = addResultTags(
    dataWithFormattedDates,
    cleanProceedWithCautionFlagEnabled
  );
  return { columns, data: dataWithResultTags };
};
