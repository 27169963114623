import { ComponentPropsWithoutRef } from 'react';
import { ThemeAttributes } from 'src/ts/interfaces/styled-components';
import styled from 'styled-components';
import { ResultMessageType } from './ResultMessage';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const getColor = (theme: ThemeAttributes, type: ResultMessageType) => {
  let color = theme.color.black.light;
  switch (type) {
    case ResultMessageType.error:
      color = theme.color.red[700];
      break;
    case ResultMessageType.success:
      color = theme.color.green[700];
      break;
    case ResultMessageType.warning:
      color = theme.color.yellow[700];
      break;

    default:
      break;
  }
  return color;
};

interface ContainerProps extends ComponentPropsWithoutRef<'div'> {
  type: ResultMessageType;
}
export const ContentIcon = styled.div<ContainerProps>`
  svg {
    width: 3rem;
    height: 3rem;
    path {
      fill: ${({ theme, type }) => getColor(theme, type)};
    }
  }
`;
