import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

import Button, { ButtonColor, ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import { Card } from 'src/components/atoms/Card';
import Grid from 'src/components/atoms/Grid';
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { ControlledCheckboxGroup } from 'src/components/molecules/CheckboxGroup';

export const Container = styled.div`
  padding-top: 0.5rem;
  position: absolute;
  right: 0;
  z-index: 1;

  .CBody {
    padding: 1rem;
  }
`;

interface FilterPanelProps {
  enabledApply?: boolean;
  onApply: () => void;
  onCancel: () => void;
  onClear: () => void;
}

const UserFilterPanel: FC<FilterPanelProps> = ({ enabledApply, onApply, onCancel, onClear }) => {
  const { watch } = useFormContext();
  const { checkboxGroup } = watch();

  return (
    <Container>
      <Card border rounded alignContent="center">
        <Row direction="column" gap={0.75}>
          <Row.Col size={1}>
            <Grid justify="space-between" alignItems="center">
              <Text color={TextColor.initial}>Filter</Text>
              <Button
                name="button-clear-all-filter"
                variant={ButtonVariant.ghost}
                onClick={onClear}
              >
                Clear all filters
              </Button>
            </Grid>
          </Row.Col>
          <Line />
          <Row direction="column" gap={1}>
            <Row.Col size={1}>
              <Row justify="space-between" gap={1}>
                {Boolean(checkboxGroup?.length) && (
                  <Row.Col size={0.6}>
                    <Row direction="column" gap={1}>
                      <Text color={TextColor.gray700} variant={TextVariant.small}>
                        Results
                      </Text>
                      <ControlledCheckboxGroup name="checkboxGroup" />
                    </Row>
                  </Row.Col>
                )}
              </Row>
            </Row.Col>
          </Row>

          <Line />
          <Row gap={1}>
            <Button
              name="button-apply-filter"
              onClick={onApply}
              size={ButtonSize.medium}
              color={ButtonColor.primary}
              variant={ButtonVariant.contained}
              isDisabled={!enabledApply}
            >
              Apply
            </Button>
            <Button
              name="button-cancel-filter"
              onClick={onCancel}
              size={ButtonSize.medium}
              color={ButtonColor.primary}
              variant={ButtonVariant.outline}
            >
              Cancel
            </Button>
          </Row>
        </Row>
      </Card>
    </Container>
  );
};

export default UserFilterPanel;
