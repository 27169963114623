import styled from 'styled-components/macro';

export const Container = styled.div`
  .Skeleton___Box {
    > div {
      flex-basis: content;
      padding-left: 0rem;
      padding-right: 0rem;
      &:last-of-type {
        padding-right: 0.8rem;
      }
    }

    @media ${({ theme }) => theme.device.mobile} {
      flex-direction: row !important;
      padding-top: 0.8rem;
      padding-left: 0.8rem;
    }
  }
`;
