import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { NAME } from './constants';

export const fetchServerInfo = createAsyncThunk(`${NAME}/fetchServerInfo`, async () => {
  try {
    const response = await api.fetchServerInfo();
    return (await response.json())?.data;
  } catch (error) {
    console.error(`${NAME}/fetchServerInfo: ${error}`);
    throw new Error('Error fetching the Server info.');
  }
});
