import styled from 'styled-components/macro';
import TableRow from 'src/components/atoms/TableRow';
import Text from 'src/components/atoms/Text';

export const StyledText = styled(Text)`
  margin-right: 1.3125rem;
`;

export const StyledTableRow = styled(TableRow)`
  height: 2.75rem;
`;
