import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { actions as authActions, selectors as authSelectors } from 'src/features/auth/authSlice';
import { fetchCurrentUser } from 'src/features/auth/services';
import { useIdleTimerCallback } from 'src/hooks';
import { useAppDispatch } from 'src/store';
import { authApi } from '../api';
import Modal, { ModalVariant } from '../components/organisms/Modal';

const DEBOUNCE_TIME = 5 * 1000; // 5 seconds in mills
const OFFSET = 2 * 60000; // 2 minutes in mills
const TEN_SECOND = 10 * 1000;
// Prevents showing the modal on the unauthenticated DUP
const ROUTES_WHITELIST = ['/application/apply/'];

export const LogOutSessionModal = () => {
  const authenticatedUser = useSelector(authSelectors.user);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isWhitelistedRoute = ROUTES_WHITELIST.some((route) =>
    (location?.pathname || '').includes(route)
  );

  /** This callback will be called followed by a debounce timeout on any dom events like mouseMove */
  const onActionCallback = useCallback(async () => {
    if (authenticatedUser) {
      // Awaiting the refresh session so that when I made a request for the current session, I have the latest one
      await authApi.refreshSession();
      dispatch(fetchCurrentUser({ isRefreshSessionCaller: true }));
    }
  }, [authenticatedUser, dispatch]);
  const onIdleCallback = () => !isWhitelistedRoute && navigate('/sessionExpired');

  // Default to 20 minutes
  const idle = useIdleTimerCallback(
    (authenticatedUser?.exp || 20 * 60 * 1000) - OFFSET,
    onActionCallback,
    onIdleCallback,
    DEBOUNCE_TIME
  );

  // start and pause timer if we are on authenticated / unauthenticated route
  if (!authenticatedUser) {
    idle.pause();
  }
  // Don't start the timer on every render only on the first ten second after mounted.
  // 10 second if an arbitrary number
  if (authenticatedUser && idle.getTotalElapsedTime() < TEN_SECOND) {
    idle.start();
  }

  if (authenticatedUser && !isWhitelistedRoute) {
    return (
      <>
        <Modal
          title="Inactive session"
          text={`You will be logged out in ${Math.floor(idle.getRemainingTime() / 1000)} minutes`}
          labelButtonCancel="Log out"
          labelButtonConfirm={`I'm still here`}
          showModal={idle.isPrompted()}
          variant={ModalVariant.none}
          onCancel={() => {
            dispatch(authActions.logout());
          }}
          onConfirm={() => {
            idle.reset();
          }}
        />
        <Modal
          isBackClosable={false}
          showCancelButton={false}
          blurBackground={true}
          title="Session expired"
          text={`You session has expired because of ${Math.floor(
            idle.getTotalIdleTime() / 60000
          )} minutes of inactivity`}
          labelButtonConfirm={`Log in`}
          showModal={idle.isIdle()}
          variant={ModalVariant.none}
          onConfirm={() => navigate('/login')}
        />
      </>
    );
  }
  return null;
};

export default LogOutSessionModal;
