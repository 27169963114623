import PropTypes from 'prop-types';
import Button, { ButtonVariant } from 'src/components/atoms/Button';
import ButtonLink, { ButtonLinkTarget, ButtonLinkVariant } from 'src/components/atoms/ButtonLink';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import Link, { LinkTarget } from 'src/components/atoms/Link';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { cleanFileName, downloadUrlToPdf } from 'src/helpers';
import Row from '../../atoms/Row/Row';
import { Container, Content } from './styles';

export type RowResourceListProps = {
  title?: string;
  date?: string;
  description?: string;
  file?: string;
  toPreview?: string;
  isDownloadable: boolean;
};

const RowResourceList: React.FC<RowResourceListProps> = (props) => {
  const { title, date, description, file, toPreview, isDownloadable } = props;

  if (!file || !toPreview) return null;
  return (
    <Container>
      <Row direction="column" justify="space-between">
        <Content>
          <Text variant={TextVariant.small} color={TextColor.gray600}>
            {date}
          </Text>
          <Gap height={1} />
          <Link
            to={isDownloadable ? toPreview : file}
            target={isDownloadable ? LinkTarget.self : LinkTarget.blank}
            isExternal={!isDownloadable}
          >
            <Text variant={TextVariant.h3} className="resource__title">
              {title}
            </Text>
          </Link>
          <Gap height={1} />
          <Text variant={TextVariant.small}>{description}</Text>
          <Gap height={1} />
        </Content>
        <Row className="resource__buttons">
          <ButtonLink
            variant={ButtonLinkVariant.ghost}
            to={isDownloadable ? toPreview : file}
            name="preview_button"
            target={isDownloadable ? ButtonLinkTarget.self : ButtonLinkTarget.blank}
            isExternal={!isDownloadable}
          >
            <Icon color="primary" icon="preview" />
          </ButtonLink>
          {isDownloadable && (
            <Button
              variant={ButtonVariant.ghost}
              name="download_button"
              onClick={() => downloadUrlToPdf(file, `${cleanFileName(title)}.pdf`)}
            >
              <Icon color="primary" icon="download" />
            </Button>
          )}
        </Row>
      </Row>
    </Container>
  );
};

RowResourceList.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired,
  toPreview: PropTypes.string.isRequired,
  isDownloadable: PropTypes.bool.isRequired
};

RowResourceList.defaultProps = {
  title: 'Title',
  date: '13 Sep, 2020',
  description: 'Lorem',
  file: './',
  toPreview: './',
  isDownloadable: true
};

export default RowResourceList;
