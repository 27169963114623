import { useSelector } from 'react-redux';
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import { Pagination } from 'src/components/molecules/Pagination';
import useProperty from 'src/features/property/hooks/useProperty';
import { selectors as propertySelectors } from 'src/features/property/propertySlice';

const PropertyPagination: React.FC = () => {
  const { onGetAll } = useProperty();

  const getPropertiesData = useSelector(propertySelectors.getAll.data);
  const getPropertiesError = useSelector(propertySelectors.getAll.error);
  const getPropertiesFilter = useSelector(propertySelectors.getAll.filter);
  const getPropertiesCount = useSelector(propertySelectors.getAll.count);

  if (getPropertiesError) return null;
  if (!getPropertiesData?.length) return null;

  return (
    <>
      <Line height="tiny" />
      <Row direction="column" className="paginator__ListView">
        <Pagination
          name="PropertyPagination"
          rowsPerPage={[5, 10, 20, 50, 100, 250, 500]}
          listPosition="top"
          pageInit={getPropertiesFilter.page}
          rowsPerPageInit={getPropertiesFilter.rowsPerPage}
          totalRows={getPropertiesCount}
          onNext={(page, rowsPerPage) => onGetAll(page, rowsPerPage)}
          onPrev={(page, rowsPerPage) => onGetAll(page, rowsPerPage)}
          onRowsPerPage={(page, rowsPerPage) => onGetAll(page, rowsPerPage)}
        />
      </Row>
    </>
  );
};

export default PropertyPagination;
