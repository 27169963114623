/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import Button, { ButtonDisabledStyle, ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Section from 'src/components/atoms/Section/Section';

// Redux
import { selectors as identitySelectors } from 'src/features/identityVerification/identityVerificationSlice';

// Hooks
import useIdentityVerificationReport from 'src/features/identityVerification/hooks/useIdentityVerificationReport';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';

import { downloadDocumentPDF } from 'src/helpers';
import { IdentityApplicantsIdVerificationGetResponseExtended } from 'src/ts/interfaces';
import useUserRoles from 'src/features/auth/hooks/useUserRoles';

const IdentityVerificationPrints: React.FC = () => {
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();
  const {
    onDownloadFcraIdentityReport,
    onResetGetDownloadFcraIdentityReport,
    onDownloadIdentityReport,
    onResetGetDownloadIdentityReport
  } = useIdentityVerificationReport();

  const applicant = useSelector(identitySelectors.getApplicantByVerificationId.data);

  const { isAdminOrAccountRepresentative } = useUserRoles();

  const fcraIdentityReportIsLoading = useSelector(
    identitySelectors.getDownloadFcraIdentityReport.isLoading
  );
  const fcraIdentityReportError = useSelector(
    identitySelectors.getDownloadFcraIdentityReport.error
  );
  const fcraIdentityReportFile = useSelector(identitySelectors.getDownloadFcraIdentityReport.file);
  const fcraIdentityReportFileName = useSelector(
    identitySelectors.getDownloadFcraIdentityReport.fileName
  );

  const identityReportIsLoading = useSelector(
    identitySelectors.getDownloadIdentityReport.isLoading
  );
  const identityReportError = useSelector(identitySelectors.getDownloadIdentityReport.error);
  const identityReportFile = useSelector(identitySelectors.getDownloadIdentityReport.file);
  const identityReportFileName = useSelector(identitySelectors.getDownloadIdentityReport.fileName);

  useEffect(() => {
    if (
      !fcraIdentityReportIsLoading &&
      fcraIdentityReportError === null &&
      fcraIdentityReportFile
    ) {
      downloadDocumentPDF(fcraIdentityReportFile, fcraIdentityReportFileName);
      onResetGetDownloadFcraIdentityReport();
    }
  }, [
    fcraIdentityReportIsLoading,
    fcraIdentityReportError,
    fcraIdentityReportFile,
    onResetGetDownloadFcraIdentityReport
  ]);

  useEffect(() => {
    if (fcraIdentityReportError !== null) {
      showSnackbar(VariantType.error, fcraIdentityReportError);
      onResetGetDownloadIdentityReport();
    }
  }, [
    fcraIdentityReportError,
    onResetGetDownloadFcraIdentityReport,
    showSnackbar,
    VariantType,
    SnackTypes
  ]);

  useEffect(() => {
    if (!identityReportIsLoading && identityReportError === null && identityReportFile) {
      downloadDocumentPDF(identityReportFile, identityReportFileName);
      onResetGetDownloadIdentityReport();
    }
  }, [
    identityReportIsLoading,
    identityReportError,
    identityReportFile,
    onResetGetDownloadIdentityReport
  ]);

  useEffect(() => {
    if (identityReportError !== null) {
      showSnackbar(VariantType.error, identityReportError);
      onResetGetDownloadIdentityReport();
    }
  }, [
    identityReportError,
    onResetGetDownloadIdentityReport,
    showSnackbar,
    VariantType,
    SnackTypes
  ]);

  return (
    <Section padding="3rem 3rem 0 3rem">
      <Row justify="space-between" alignItems="center">
        <Row.Col>
          {applicant?.companyLogoUrl ? (
            <img
              src={applicant?.companyLogoUrl}
              alt={applicant?.companyName ? applicant?.companyName : ''}
              style={{ maxWidth: '250px' }}
            />
          ) : (
            <></>
          )}
        </Row.Col>
        <Row.Col className="hiddenPrint">
          <Row justify="space-between">
            {isAdminOrAccountRepresentative && (
              <Button
                name={'download_fcra_report_identity_button'}
                variant={ButtonVariant.ghost}
                isDisabled={fcraIdentityReportIsLoading}
                isLoading={fcraIdentityReportIsLoading}
                disabledStyle={ButtonDisabledStyle.white}
                onClick={() =>
                  onDownloadFcraIdentityReport(
                    applicant as IdentityApplicantsIdVerificationGetResponseExtended
                  )
                }
              >
                <Icon icon="download" />
                &nbsp;Download FCRA report
              </Button>
            )}

            <Button
              name={'download_report_identity_button'}
              variant={ButtonVariant.ghost}
              isDisabled={identityReportIsLoading}
              isLoading={identityReportIsLoading}
              disabledStyle={ButtonDisabledStyle.white}
              onClick={() =>
                onDownloadIdentityReport(
                  applicant as IdentityApplicantsIdVerificationGetResponseExtended
                )
              }
            >
              <Icon icon="download" />
              &nbsp;Download report
            </Button>
            <Button
              name={'print_report_button'}
              variant={ButtonVariant.ghost}
              onClick={() => window.print()}
            >
              <Icon icon="print" />
              &nbsp;Print report
            </Button>
          </Row>
        </Row.Col>
      </Row>
    </Section>
  );
};

export default IdentityVerificationPrints;
