/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { Status as StatusTypes } from 'src/ts/enums';
import { IRejectedAction } from 'src/ts/interfaces';
import {
  changeProofType,
  createProofPreURL,
  getProofs,
  removeProof,
  reUploadProof
} from './services';
import { IProofDUP } from './ts/interfaces';

export type StateDataProofs = {
  process: {
    getProofs: {
      status: StatusTypes;
      error: string | null;
      data: IProofDUP[];
    };
    createProofPreURL: {
      status: StatusTypes;
      error: string | null;
      data: IProofDUP | null;
    };
    removeProof: {
      status: StatusTypes;
      error: string | null;
      data?: any;
    };
    changeProofType: {
      status: StatusTypes;
      error: string | null;
      data?: any;
    };
    reUploadProof: {
      status: StatusTypes;
      error: string | null;
      data?: any;
    };
  };
};

export const initialStateProofs: StateDataProofs = {
  process: {
    getProofs: {
      status: StatusTypes.IDLE,
      data: [],
      error: null
    },
    createProofPreURL: {
      status: StatusTypes.IDLE,
      data: null,
      error: null
    },
    removeProof: {
      status: StatusTypes.IDLE,
      data: {},
      error: null
    },
    changeProofType: {
      status: StatusTypes.IDLE,
      data: {},
      error: null
    },
    reUploadProof: {
      status: StatusTypes.IDLE,
      data: {},
      error: null
    }
  }
};

export const getProofsReducer = (builder: ActionReducerMapBuilder<StateDataProofs>) => {
  builder.addCase(getProofs.pending, (state: StateDataProofs) => {
    state.process.getProofs.status = StatusTypes.LOADING;
    state.process.getProofs.error = null;
  });
  builder.addCase(getProofs.rejected, (state: StateDataProofs, action: IRejectedAction) => {
    state.process.getProofs.status = StatusTypes.ERROR;
    state.process.getProofs.error = action.error.message || null;
  });
  builder.addCase(getProofs.fulfilled, (state: StateDataProofs, action: PayloadAction<any>) => {
    state.process.getProofs.status = StatusTypes.SUCCESS;
    state.process.getProofs.data = action.payload.data;
  });
};

export const createProofPreURLReducer = (builder: ActionReducerMapBuilder<StateDataProofs>) => {
  builder.addCase(createProofPreURL.pending, (state: StateDataProofs) => {
    state.process.createProofPreURL.status = StatusTypes.LOADING;
    state.process.createProofPreURL.error = null;
  });
  builder.addCase(createProofPreURL.rejected, (state: StateDataProofs, action: IRejectedAction) => {
    state.process.createProofPreURL.status = StatusTypes.ERROR;
    state.process.createProofPreURL.error = action.error.message || null;
  });
  builder.addCase(
    createProofPreURL.fulfilled,
    (state: StateDataProofs, action: PayloadAction<any>) => {
      const { data: proof, tempId } = action.payload;
      state.process.createProofPreURL.status = StatusTypes.SUCCESS;
      state.process.createProofPreURL.data = proof;

      const proofUpdated = state.process.getProofs.data.map((item) =>
        item.id === tempId ? proof : item
      );
      state.process.getProofs.data = proofUpdated;
    }
  );
};

export const removeProofReducer = (builder: ActionReducerMapBuilder<StateDataProofs>) => {
  builder.addCase(removeProof.pending, (state: StateDataProofs) => {
    state.process.removeProof.status = StatusTypes.LOADING;
    state.process.removeProof.error = null;
  });
  builder.addCase(removeProof.rejected, (state: StateDataProofs, action: IRejectedAction) => {
    state.process.removeProof.status = StatusTypes.ERROR;
    state.process.removeProof.error = action.error.message || null;
  });
  builder.addCase(removeProof.fulfilled, (state: StateDataProofs, action) => {
    const id = action.meta.arg;
    state.process.removeProof.status = StatusTypes.SUCCESS;
    state.process.removeProof.data = { id };

    const proofsUpdated = state.process.getProofs.data.filter((proof) => proof.id !== id);
    state.process.getProofs.data = proofsUpdated;
  });
};

export const changeProofTypeReducer = (builder: ActionReducerMapBuilder<StateDataProofs>) => {
  builder.addCase(changeProofType.pending, (state: StateDataProofs) => {
    state.process.changeProofType.status = StatusTypes.LOADING;
    state.process.changeProofType.error = null;
  });
  builder.addCase(changeProofType.rejected, (state: StateDataProofs, action: IRejectedAction) => {
    state.process.changeProofType.status = StatusTypes.ERROR;
    state.process.changeProofType.error = action.error.message || null;
  });
  builder.addCase(
    changeProofType.fulfilled,
    (state: StateDataProofs, action: PayloadAction<any>) => {
      const proof = action.payload.data;
      state.process.changeProofType.status = StatusTypes.SUCCESS;
      state.process.changeProofType.data = proof;

      const proofUpdated = state.process.getProofs.data.map((item) =>
        item.id === proof.id ? { ...item, type: proof.type } : item
      );
      state.process.getProofs.data = proofUpdated;
    }
  );
};

export const reUploadProofReducer = (builder: ActionReducerMapBuilder<StateDataProofs>) => {
  builder.addCase(reUploadProof.pending, (state: StateDataProofs) => {
    state.process.reUploadProof.status = StatusTypes.LOADING;
    state.process.reUploadProof.error = null;
  });
  builder.addCase(reUploadProof.rejected, (state: StateDataProofs, action: IRejectedAction) => {
    state.process.reUploadProof.status = StatusTypes.ERROR;
    state.process.reUploadProof.error = action.error.message || null;
  });
  builder.addCase(reUploadProof.fulfilled, (state: StateDataProofs, action: PayloadAction<any>) => {
    const { data: proof, tempId } = action.payload;
    state.process.reUploadProof.status = StatusTypes.SUCCESS;
    state.process.reUploadProof.data = proof;

    const proofUpdated = state.process.getProofs.data.map((item) =>
      item.id === tempId ? proof : item
    );
    state.process.getProofs.data = proofUpdated;
  });
};
