import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { ReactSVG } from 'react-svg';
import { InputText, InputTextProps, InputTextType } from 'src/components/atoms/InputText';
import { IValueLabel } from 'src/ts/interfaces';
import { Container, OptionContainer, StateMessage } from './styles';

const PUBLIC_URL = process.env.PUBLIC_URL;
const MIN_SEARCH_LENGTH = 1;

export interface AutoSuggestProps extends Omit<InputTextProps, 'onSelect' | 'type'> {
  onSelect?: (obj: IValueLabel) => void;
  onClear?: () => void;
  data: IValueLabel[];
  initialLabel?: string;
  isLoading: boolean;
  hasError: boolean;
  config?: RegisterOptions;
}

const AutoSuggest: React.FC<AutoSuggestProps> = (props) => {
  const {
    name,
    placeholder,
    data,
    onChange,
    onSelect,
    onClear,
    isLoading,
    hasError,
    initialLabel,
    isDisabled,
    config
  } = props;

  const [cleaned, setCleaned] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [label, setLabel] = useState(initialLabel);
  const [showSearchIsDisabledLabel, setShowSearchIsDisabledLabel] = useState(true);
  const inputRef = React.createRef<HTMLInputElement>();

  const onItemSelected = (item: IValueLabel) => {
    setIsOpen(false);
    setLabel(item.label);

    if (onSelect) {
      onSelect(item);
    }
  };

  useEffect(() => {
    if (inputRef.current && label) {
      inputRef.current.value = label;
    }
  }, [inputRef, label]);

  const renderEmptyState = () => {
    if (hasError || isLoading || data?.length > 0) return;

    const getMessage = () => {
      if (showSearchIsDisabledLabel) {
        return `You should type in more than ${MIN_SEARCH_LENGTH} characters to search for results`;
      }

      return 'No options found';
    };

    return <StateMessage>{getMessage()}</StateMessage>;
  };

  return (
    <Container>
      <InputText
        name={name}
        className={`${name}__autoSuggest`}
        key={`${name}__autoSuggest__cleaned_${cleaned}`}
        type={InputTextType.text}
        placeholder={label && label?.length > 0 ? placeholder : `Find a ${placeholder}`}
        defaultValue={label}
        showOptionalLabel={false}
        isDisabled={isDisabled}
        isReadonly={isDisabled}
        onChange={(obj) => {
          const isValueShorterThanMinLength = obj?.value?.toString()?.length < MIN_SEARCH_LENGTH;

          if (onChange) {
            onChange(obj);
            setLabel(obj.value as string);
            setIsOpen(obj.value !== '');
            if (
              (showSearchIsDisabledLabel && isValueShorterThanMinLength) ||
              (!showSearchIsDisabledLabel && !isValueShorterThanMinLength)
            ) {
              return;
            }
            setShowSearchIsDisabledLabel(!showSearchIsDisabledLabel);
          }
        }}
        onClick={(e: any) => {
          if (!isDisabled && e.target && e.target.value !== '') {
            setIsOpen(true);
          }
        }}
        endAndorment={
          !isDisabled &&
          label && (
            <ReactSVG
              onClick={() => {
                setLabel(undefined);
                if (inputRef.current) {
                  inputRef.current.value = '';
                  inputRef.current.focus();
                }
                setCleaned(!cleaned);
                onClear && onClear();
              }}
              className="end-andorment"
              src={`${PUBLIC_URL}/assets/images/icon-close.svg`}
            />
          )
        }
        refInput={inputRef}
        config={{
          validate: () => {
            return !data.length ? 'Make sure the information provided is correct' : undefined;
          },
          ...config
        }}
      />
      {isOpen && (
        <OptionContainer
          rows={data.length}
          onMouseLeave={() => {
            setIsOpen(false);
          }}
        >
          {renderEmptyState()}
          {isLoading && <StateMessage>Loading...</StateMessage>}
          {hasError && <StateMessage>Something went wrong</StateMessage>}
          {!hasError && !isLoading && data.length > 0 && (
            <ul>
              {data.map((item) => {
                return (
                  <li key={item.value}>
                    <button
                      type="button"
                      id={item.value.toString()}
                      onClick={() => onItemSelected(item)}
                      role="option"
                      aria-selected={true}
                    >
                      {item.label}
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </OptionContainer>
      )}
    </Container>
  );
};

AutoSuggest.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired
};

AutoSuggest.defaultProps = {
  name: 'AutoSuggest',
  placeholder: 'Search',
  data: [],
  isLoading: false,
  hasError: false
};

export default AutoSuggest;
