import React, { useEffect, useState } from 'react';

// Vendor
import { useSelector } from 'react-redux';

// Components
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import Section from 'src/components/atoms/Section/Section';
import Text, { TextVariant } from 'src/components/atoms/Text';
import BoxStatus from 'src/components/molecules/BoxStatus';

// Redux
import { selectors as identitySelectors } from 'src/features/identityVerification/identityVerificationSlice';
import { IdentityApplicantsGet200ResponseInnerStatusEnum } from 'src/lib/internal/epa/gen';

// Enums
import { FeatureFlagKey } from 'src/ts/enums';

// Hooks
import { useFeatureFlag } from 'src/hooks';

export const IdentityVerificationResults: React.FC = () => {
  const [idvReportImageBlobUrls, setIdvReportImageBlobUrls] = useState([{ key: '', value: '' }]);

  const useBlobUrl = (imageUrls: { [key: string]: string } | undefined) => {
    useEffect(() => {
      async function fetchData() {
        if (imageUrls) {
          const urls = [];
          for (const key in identityImages?.identity_verification_report_images) {
            const response = await fetch(
              identityImages?.identity_verification_report_images[key] || ''
            );
            const blob = await response.blob();
            urls.push({ key, value: URL.createObjectURL(blob) });
          }
          setIdvReportImageBlobUrls(urls); // set in state
        }
      }

      fetchData();
    }, [imageUrls]); // only execute if imageUrl changes
    return () => {
      for (const url in idvReportImageBlobUrls) {
        URL.revokeObjectURL(url);
      }
    };
  };

  const identityReportData = useSelector(identitySelectors.getApplicantByVerificationId.data);
  const cleanProceedWithCautionFlagEnabled = useFeatureFlag(
    FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION
  );

  const identityImages = useSelector(identitySelectors.getIdentityImages.data);
  useBlobUrl(identityImages?.identity_verification_report_images);
  return (
    <Section>
      <Row direction={'column'} gap={1}>
        <strong>ID Verification Summary</strong>

        <Text variant={TextVariant.h2}>Identification Results</Text>
        <Gap height={1} />
        <Row gap={1}>
          {identityReportData?.status === IdentityApplicantsGet200ResponseInnerStatusEnum.Pass && (
            <BoxStatus
              key="passed-status"
              type="success"
              title="Passed"
              subTitle=""
              cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
            />
          )}
          {identityReportData?.status === IdentityApplicantsGet200ResponseInnerStatusEnum.Fail && (
            <BoxStatus
              key="failed-status"
              type="fail"
              title="Failed"
              subTitle=""
              cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
            />
          )}
          {identityReportData?.status ===
            IdentityApplicantsGet200ResponseInnerStatusEnum.NeedsReview && (
            <BoxStatus
              key="needs-review-status"
              type="warning"
              title="Needs Review"
              subTitle=""
              cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
            />
          )}
          {identityReportData?.status ===
            IdentityApplicantsGet200ResponseInnerStatusEnum.Reviewing && (
            <BoxStatus
              key="reviewing-status"
              type="warning"
              title="Reviewing"
              subTitle=""
              cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
            />
          )}
          {identityReportData?.licenseExpiresAt !== undefined &&
            new Date(identityReportData?.licenseExpiresAt) < new Date() && (
              <BoxStatus
                key="expired-identification-status"
                type="warning"
                title="Expired Identification"
                subTitle=""
                cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
              />
            )}
        </Row>

        <Gap height={1} />
        <Text variant={TextVariant.h2}>Identification Result Details</Text>
        {identityReportData?.idVerificationData?.score !== undefined && (
          <Row gap={1}>
            <BoxStatus
              key="id-verification-details"
              type={
                identityReportData?.idVerificationData?.score?.idValidation?.overall?.status ===
                'OK'
                  ? 'success'
                  : 'fail'
              }
              title="ID Verification"
              subTitle=""
              cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
            />
            <BoxStatus
              key="liveness-details"
              type={
                identityReportData?.idVerificationData?.score?.liveness?.overall?.status === 'OK'
                  ? 'success'
                  : 'fail'
              }
              title="Liveness"
              subTitle=""
              cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
            />
            <BoxStatus
              key="face-recognition-details"
              type={
                identityReportData?.idVerificationData?.score?.faceRecognition?.overall?.status ===
                'OK'
                  ? 'success'
                  : 'fail'
              }
              title="Face Recognition"
              subTitle=""
              cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
            />
          </Row>
        )}
      </Row>
      <Gap height={2} />
      {idvReportImageBlobUrls.map((idvReportImageBlobUrl) => (
        <Row key={idvReportImageBlobUrl.value}>
          <img
            data-testid="idv-report-image"
            src={idvReportImageBlobUrl.value}
            alt={idvReportImageBlobUrl.key}
            style={{ maxHeight: '250px' }}
          />
        </Row>
      ))}
    </Section>
  );
};
