/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

// Components
import Button, { ButtonColor, ButtonVariant } from 'src/components/atoms/Button';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import Modal, { ModalVariant } from 'src/components/organisms/Modal';
import ShowContent from './ShowContent';
import {
  Body,
  Buttons,
  Container,
  EditButtonContainer,
  Footer,
  Selector,
  SelectorContainer,
  SelectorItem
} from './styles';

// Constants
import { DOCUMENT_TYPES } from 'src/features/DUP/constants';
import { POLLING, STATUS_PROCESSING_PROOF } from 'src/features/DUP/proofs/constants';

// Hooks
import useLanguage from 'src/context/Language/useLanguage';
import useProofs from 'src/features/DUP/proofs/hooks/useProofs';
import { useGetProofQuery } from 'src/features/DUP/proofs/queryProof';

// Types
import {
  ConfigPollingType,
  IProofDUP,
  UnauthenticateSessionProofType
} from 'src/features/DUP/proofs/ts/interfaces';

// Enums
import { DupTypes } from 'src/features/DUP/ts/enums';

export type BoxProofProps = {
  proof: IProofDUP;
  num: number;
  type: DupTypes;
};

const initPolling = {
  pollingInterval: POLLING.INTERVAL
};
const BoxProof: React.FC<BoxProofProps> = (props) => {
  const { proof: data, num, type } = props;
  const { translate: t } = useLanguage();
  const {
    findProofState,
    onChangeProofType,
    onRemove,
    onReUploadFile,
    setProofState,
    getNameType,
    isPolling,
    setMaxAttemptState,
    isRemoving
  } = useProofs();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShowSelector, setIsShowSelector] = useState(false);
  const [configPolling, setConfigPolling] = useState<ConfigPollingType>(initPolling);
  const [itemProof, setItemProof] = useState(data);
  const [docType, setDocType] = useState<UnauthenticateSessionProofType>(data.type);
  const [retry, setRetry] = useState(1);

  const documentTypesFiltered = DOCUMENT_TYPES.filter(
    (documentType) => documentType.value === 'PAYSTUB' || documentType.value === 'BANK_STATEMENT'
  );

  const {
    data: proof,
    isLoading,
    isFetching,
    error
  } = useGetProofQuery(
    { proof_id: data?.id },
    {
      ...configPolling
    }
  );

  /**
   * Show Proof's information
   */
  useEffect(() => {
    if (!isLoading && configPolling && proof && !isPolling(proof)) {
      setProofState(proof);
      setItemProof(proof);
      StopPolling();
    }
  }, [isLoading, proof, configPolling]);

  /**
   * Detect Frontend errors
   */
  useEffect(() => {
    if ((!isLoading && error) || data?.isCustom) {
      setProofState(data);
      setItemProof(data);
      StopPolling();
    }
  }, [isLoading, data, error, configPolling]);

  /**
   * Updating Proof's type
   */
  useEffect(() => {
    if (!isLoading && itemProof?.type !== docType) {
      const proof = findProofState(itemProof?.id);
      if (proof) {
        setItemProof({ ...proof });
      }
    }
  }, [isLoading, type, itemProof, docType]);

  /**
   * Attempt validation
   */
  useEffect(() => {
    if (!isLoading && isFetching && itemProof) {
      if (retry < POLLING.MAX_ATTEMPTS) {
        setRetry(retry + 1);
      } else {
        setMaxAttemptState(itemProof.id, itemProof.type);
        const proof = findProofState(itemProof?.id);
        if (proof) {
          setItemProof({ ...proof });
        }
        StopPolling();
      }
    }
  }, [isLoading, isFetching, itemProof]);

  const StopPolling = () => setConfigPolling(undefined);

  const handleDeleteClick = (itemProof: IProofDUP) => {
    return itemProof?.jobs_error?.length ? onRemove(itemProof) : setIsModalOpen(true);
  };

  const TypeSelector = useCallback(() => {
    if (!isShowSelector) return null;

    return (
      <SelectorContainer>
        <Selector onMouseLeave={() => setIsShowSelector(false)}>
          {documentTypesFiltered.map((doc) => {
            return (
              <SelectorItem key={doc.value}>
                <Button
                  color={ButtonColor.primary}
                  variant={ButtonVariant.ghost}
                  name={t('dup_proof_button_edit_type')}
                  onClick={() => {
                    setTimeout(() => {
                      setIsShowSelector(false);
                    }, 100);
                    onChangeProofType(itemProof?.id, doc.value as UnauthenticateSessionProofType);
                    setDocType(doc.value as UnauthenticateSessionProofType);
                  }}
                >
                  {doc.label}
                </Button>
              </SelectorItem>
            );
          })}
        </Selector>
      </SelectorContainer>
    );
  }, [isShowSelector]);

  return (
    <Container key={`proof_${itemProof?.id}`}>
      <Body hasError={!!itemProof?.jobs_error?.length}>
        <ShowContent proof={itemProof} isPolling={isPolling(itemProof)} />
      </Body>
      <Footer>
        <Text color={TextColor.initial} variant={TextVariant.normal}>{`${t(
          'dup_proof_name_doc'
        )} #${num}`}</Text>
        <Text color={TextColor.initial} variant={TextVariant.normal}>{`${getNameType(
          itemProof?.type
        )}`}</Text>
      </Footer>
      <Buttons type={type}>
        <Button
          onClick={() => handleDeleteClick(itemProof)}
          color={ButtonColor.primary}
          variant={ButtonVariant.ghost}
          name={t('dup_proof_button_delete')}
          isDisabled={isPolling(itemProof)}
        >
          {t('dup_proof_button_delete')}
        </Button>
        <EditButtonContainer>
          <Button
            onClick={() => setIsShowSelector(true)}
            color={ButtonColor.primary}
            variant={ButtonVariant.ghost}
            name={t('dup_proof_button_edit_type')}
            isDisabled={isPolling(itemProof) || itemProof.isCustom}
          >
            {t('dup_proof_button_edit_type')}
          </Button>
          <TypeSelector />
        </EditButtonContainer>
        <input
          type="file"
          name={`file_reupload_${itemProof?.id}`}
          id={`file_reupload_${itemProof?.id}`}
          className="inputFile"
          accept={'.pdf'}
          disabled={isPolling(itemProof)}
          onChange={(e) => {
            onReUploadFile(e, itemProof);
            setItemProof({
              ...itemProof,
              process_status: STATUS_PROCESSING_PROOF.IN_QUEUE,
              jobs_error: []
            });
          }}
        />
        <label htmlFor={`file_reupload_${itemProof?.id}`} className="inputFileButton">
          <Text color={TextColor.primary}>{t('dup_proof_button_reupload')}</Text>
        </label>
      </Buttons>
      {isModalOpen && (
        <Modal
          title={t('dup_proof_delete_title')}
          labelButtonCancel={t('dup_proof_delete_button_cancel')}
          labelButtonConfirm={
            isRemoving ? t('dup_proof_delete_is_removing') : t('dup_proof_delete_button_confirm')
          }
          showModal={isModalOpen}
          setShowModal={setIsModalOpen}
          variant={ModalVariant.danger}
          isLoadingButtonConfirm={isRemoving}
          isDisabledButtonConfirm={isRemoving}
          isDisabledButtonCancel={isRemoving}
          isBackClosable={false}
          isEscapeClosable={false}
          onConfirm={() => {
            onRemove(itemProof);
          }}
          onCancel={() => {
            setIsModalOpen(false);
          }}
        >
          <Text>{t('dup_proof_delete_message')}</Text>
        </Modal>
      )}
    </Container>
  );
};

BoxProof.propTypes = {
  proof: PropTypes.any.isRequired,
  num: PropTypes.number.isRequired,
  type: PropTypes.oneOf<DupTypes>(Object.values(DupTypes)).isRequired
};

export default BoxProof;
