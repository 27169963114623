import { EntryResultEnum } from 'src/ts/enums';
import { EntryResultType } from 'src/ts/interfaces';

const getEntryStatusIcon = (
  result: EntryResultType,
  cleanProceedWithCautionFlagEnabled?: boolean
) => {
  switch (result) {
    case EntryResultEnum.Clean:
      return 'check';
    case EntryResultEnum.Edited:
      return 'clear';
    case EntryResultEnum.Undetermined:
      return cleanProceedWithCautionFlagEnabled ? 'unavailable' : 'warning';
    case EntryResultEnum.CleanProceedWithCaution:
      return 'warning';
    case EntryResultEnum.Pending:
    default:
      return 'hourglass';
  }
};
export default getEntryStatusIcon;

export const getAttrEntryResult = (
  result: EntryResultType,
  cleanProceedWithCautionFlagEnabled?: boolean
) => {
  switch (result) {
    case EntryResultEnum.Clean:
      return { icon: 'cardSelectDoc_clean', name: 'Clean' };
    case EntryResultEnum.Edited:
      return { icon: 'cardSelectDoc_edited', name: 'Edited' };
    case EntryResultEnum.Undetermined: {
      return {
        icon: cleanProceedWithCautionFlagEnabled
          ? 'cardSelectDoc_insufficient_documentation'
          : 'cardSelectDoc_insufficient',
        name: 'Insufficient Documentation'
      };
    }
    case EntryResultEnum.CleanProceedWithCaution:
      return {
        icon: 'cardSelectDoc_clean_proceed_with_caution',
        name: 'Clean - Proceed with Caution'
      };
    case EntryResultEnum.Pending:
    default:
      return { icon: 'cardSelectDoc_pending', name: 'Pending' };
  }
};
