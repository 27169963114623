import { FC } from 'react';

import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import Tree from 'src/components/molecules/Tree';
import { Row } from 'src/components/atoms/Row';
import { IProof } from 'src/ts/interfaces';
import { sliceArray } from 'src/helpers';

import { Body, Head, StyledText } from './styles';

const checkWhiteList = (
  index: number,
  proof: Pick<IProof, 'test_meta_data_flags' | 'test_extracted_meta'>
) => {
  if (!proof?.test_meta_data_flags?.metadata || !proof?.test_extracted_meta?.whitelistedProfiles)
    return `No whitelist available`;

  const bankName = proof.test_meta_data_flags.metadata
    .filter((test) => test.status === 'Pass')
    .map((test) => test.profile);

  const bankNameValue = bankName.length ? bankName[bankName.length - 1] : '';

  if (!bankNameValue) return `No whitelist available`;

  const whiteProfile = proof.test_extracted_meta.whitelistedProfiles;

  if (!whiteProfile[bankNameValue]) return `No whitelist available`;

  if (!whiteProfile[bankNameValue]['PDF:TextBreakups']) return `No whitelist available`;

  const whiteListCount = whiteProfile[bankNameValue]['PDF:TextBreakups'][index];

  if (!whiteListCount) return `No whitelist available`;

  return (
    <StyledText variant={TextVariant.normal} color="secondary">
      <b>{whiteListCount}</b>
      Elements in Whitelist
    </StyledText>
  );
};

const HeaderBox = ({
  index,
  breakup,
  proof
}: {
  index: number;
  breakup: string[];
  proof: Pick<IProof, 'test_meta_data_flags' | 'test_extracted_meta'>;
}) => {
  return (
    <Row justify="space-between" alignItems="center">
      <Row.Col>
        <Head>
          <Text color={TextColor.secondary} variant={TextVariant.small}>{`Page ${index + 1}`}</Text>
        </Head>
      </Row.Col>

      <Row.Col>
        <Row gap={1.25}>
          <StyledText color="secondary" variant={TextVariant.small}>
            <b>{breakup.length}</b>
            Elements in this page
          </StyledText>

          <Text color="secondary" variant={TextVariant.small}>
            {checkWhiteList(index, proof)}
          </Text>
        </Row>
      </Row.Col>
    </Row>
  );
};

const BodyBox = ({ breakup, slicesGroup }: { breakup: string[]; slicesGroup: number }) => {
  const items = sliceArray(breakup, slicesGroup);

  return (
    <Body>
      <pre>
        {items?.map((rows, index) => {
          const to = slicesGroup * (index + 1);
          const from = rows.length * index + 1;
          const sliceTo = to > breakup.length ? to - (to - breakup.length) : to;
          const sliceFrom = sliceTo === breakup.length ? breakup.length - rows.length + 1 : from;

          return (
            <Tree
              key={`slice_ ${sliceFrom}_${sliceTo}`}
              isOpen={true}
              rows={rows}
              titleShow={`Show ${sliceFrom} to ${sliceTo}`}
              titleHide={`Hide ${sliceFrom} to ${sliceTo}`}
            />
          );
        })}
      </pre>
    </Body>
  );
};

export interface TextInsertionBlockProps {
  proof: Pick<IProof, 'test_meta_data_flags' | 'test_extracted_meta'>;
  breakup: string[];
  index: number;
  slicesGroup?: number;
}

const TextInsertionBlock: FC<TextInsertionBlockProps> = ({
  breakup,
  index = 0,
  slicesGroup = 10,
  proof
}) => {
  return (
    <Row direction="column" key={index}>
      <HeaderBox index={index} breakup={breakup} proof={proof} />
      <BodyBox breakup={breakup} slicesGroup={slicesGroup} />
    </Row>
  );
};

export default TextInsertionBlock;
