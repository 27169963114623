import { Row } from 'src/components/atoms/Row';
import styled from 'styled-components';
export const Container = styled.div`
  > div {
    align-items: self-start;

    button {
      &:disabled {
        background: ${({ theme }) => theme.color.white};
        svg {
          path {
            fill: ${({ theme }) => theme.color.gray[300]};
          }
        }
      }
    }

    .title_box {
      margin-bottom: 0.5rem;
    }

    .buttons {
      align-self: center;
    }
  }
`;

export const Box = styled.div`
  min-height: 25rem;
  padding: 1rem;
  display: inline-grid;
  width: 100%;
  ${({ theme }) => theme.font.normal};
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray[100]};
`;

export const ChoicesContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  width: 100%;
  button {
    width: auto;
  }
`;
export const ListContainer = styled.div`
  overflow-y: auto;
  height: 14rem;

  .ShowState {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    h3 {
      ${({ theme }) => theme.font.normal}
    }
  }
`;

export const SearchContainer = styled(Row)`
  > div {
    width: 100%;
  }

  .input-form {
    border-right-width: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-bottom: 0px;
  }

  button {
    &.end-andorment {
      &:hover {
        svg {
          path {
            fill: ${({ theme }) => theme.color.blue[700]};
          }
        }
      }
    }
    &.search_button {
      height: 48px;
      border: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding-left: 1rem;
      padding-right: 1rem;
      &:disabled {
        background-color: ${({ theme }) => theme.color.gray[300]};
        svg {
          path {
            fill: ${({ theme }) => theme.color.white};
          }
        }
      }
    }
  }
`;
