import styled from 'styled-components/macro';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .message__text {
    text-align: center;
    ${({ theme }) => theme.font.normal}
  }
`;
