import { createContext, FC, ReactNode, useContext } from 'react';

import { CollapsibleTableRowProps } from 'src/components/folders/ProofListTable';
import { ModalProps } from 'src/components/organisms/Modal';

import { IEntry } from 'src/ts/interfaces';
import { useFolderPageProvider } from './useFolderPageProvider';

interface FolderPageContextState {
  onChangeSelectedEntry?: (id: string) => void;
  handleDownload?: CollapsibleTableRowProps['onDownload'];
  handleTextFields?: CollapsibleTableRowProps['onTextFields'];
  handleVisibility?: CollapsibleTableRowProps['onVisibility'];
  showMetadataModal: ModalProps['showModal'];
  showRequestDocumentsModal: ModalProps['showModal'];
  showSendReportModal: ModalProps['showModal'];
  showSplitEntryModal: ModalProps['showModal'];
  showEntryMetadataModal: ModalProps['showModal'];
  setShowMetadataModal?: ModalProps['setShowModal'];
  setShowRequestDocumentsModal?: ModalProps['setShowModal'];
  setShowSendReportModal?: ModalProps['setShowModal'];
  setShowSplitEntryModal?: ModalProps['setShowModal'];
  setShowEntryMetadataModal?: ModalProps['setShowModal'];
  selectedEntryId?: string;
  selectedProofId?: string;
}

const defaultValue: FolderPageContextState = {
  showMetadataModal: false,
  showRequestDocumentsModal: false,
  showSendReportModal: false,
  showSplitEntryModal: false,
  showEntryMetadataModal: false
};

export const FolderPageContext = createContext(defaultValue);

export const FolderPageProvider: FC<{ children: ReactNode; assignedEntry?: IEntry | null }> = ({
  children,
  assignedEntry
}) => {
  const data = useFolderPageProvider({ assignedEntry });

  return <FolderPageContext.Provider value={data}>{children}</FolderPageContext.Provider>;
};

export const useFolderPage = () => {
  return useContext(FolderPageContext);
};
