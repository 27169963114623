import { Card } from 'src/components/atoms/Card';
import SkeletonPiece from 'src/components/atoms/SkeletonPiece';
import styled from 'styled-components/macro';
import { LoaderMatchingTileProps } from './LoaderMatchingTile';

export const StyledCard = styled(Card)<Pick<LoaderMatchingTileProps, 'isModal'>>`
  padding-top: 2rem;
  width: ${({ isModal }) => !isModal && '29.25rem'};
  max-height: ${({ isModal }) => (isModal ? '20.3125rem' : '26.25rem')};

  & > .CBody {
    max-height: ${({ isModal }) => (isModal ? '20.3125rem' : '26.25rem')};
    padding: ${({ isModal }) => isModal && '0rem'};
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`;

export const StyledSkeletonPiece = styled(SkeletonPiece)`
  padding: 0;
`;

export const Divider = styled.div<{ marginBottom: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
`;
