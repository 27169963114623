// Vendor
import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';

// Types
import { ContextProps, ILanguage, ITextKeys, LangStateProps } from './types';

// Reducer
import { LangActionType, langReducer } from './reducer';

// Configuration
import { DEFAULT, LANGUAGES } from './config';

const localStorageLang = localStorage.getItem('language');
const initialState = {
  language: (localStorageLang ? localStorageLang : DEFAULT) as ILanguage
};

export const LangContext = createContext({} as ContextProps);

const LanguageProvider: React.FC<LangStateProps> = ({ children }) => {
  const [state, dispatch] = useReducer(langReducer, initialState);

  /**
   * `setLanguage` is a function that takes a language as a parameter and sets the language in local
   * storage and dispatches an action to the reducer
   * @param {ILanguage} lang - ILanguage - this is the language that we want to set.
   * @example
   * setLanguage('ES');
   * ...
   */
  const setLanguage = (lang: ILanguage) => {
    localStorage.setItem('language', lang);
    dispatch({
      type: LangActionType.SET_LANGUAGE,
      payload: lang
    });
  };

  /**
   * It takes a key and some arguments, and returns a string
   * @param {ITextKeys} key - The key of the text to be translated.
   * @param {string[]} args - string[] - This is an array of strings that will be used to replace the %1,
   * %2, %3, etc. in the translation using the "Rest param" functionality.
   * @returns A function that takes a key and an array of strings and returns a string.
   * @example
   * "text_to_translate": "Text translated" // EN
   * "text_to_translate": "Texto traducido" // ES
   * translate("text_to_translate"); // return: Texto traducido
   * @example
   * "text_to_translate_with_#_param": "The population in the USA is %1 millions" // EN
   * "text_to_translate_with_#_param": "La población en USA es de %1 millones" // ES
   * translate("text_to_translate_with_#_param", "331,9"); // return: La población en USA es de 331,9 millones
   * @example
   * "text_to_translate_with_param_#_and_#": 'The population in %1 in the USA was %2 millions' // EN
   * "text_to_translate_with_param_#_and_#": 'La población en %1 en USA fué de %2 millones' // ES
   * translate('text_to_translate_with_param_#_and_#', '1980', '226,5'); // return: La población en 1980 en USA fué de 226,5 millones
   * @example
   * "text_to_translate": "Text translated" // EN
   * "text_to_translate": "Texto traducido" // ES
   * translate("info_translate"); // return: Undefined
   */
  const translate = (key: ITextKeys, ...args: string[]): string => {
    const language = localStorage.getItem('language') as ILanguage;

    const langData = LANGUAGES[language] || LANGUAGES[DEFAULT];

    if (langData[key]) {
      if (args) {
        return langData[key].replace(/%(\d+)/g, (c: string, n: number) => args[+n - 1]);
      }
      return langData[key];
    }

    // If a language key is left blank on purpose ('') just doesn't print it.
    if (langData[key] !== undefined) {
      return '';
    }

    return 'Undefined';
  };

  return (
    <LangContext.Provider value={{ state, dispatch: { setLanguage, translate } }}>
      {children}
    </LangContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default LanguageProvider;
