import PropTypes from 'prop-types';
import { useState } from 'react';
import Button, { ButtonColor, ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import ChangeLoginForm from '../ChangeLoginForm';

export type LoginInformationProps = {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  handleOnSubmit: (data: object) => void;
  handleChangePassword: () => void;
  canEdit: boolean;
};

const LoginInformation: React.FC<LoginInformationProps> = (props: LoginInformationProps) => {
  const { firstName, lastName, email, password, handleOnSubmit, handleChangePassword, canEdit } =
    props;

  const [isOpenForm, setIsOpenForm] = useState(false);

  const onSubmit = (data: object) => {
    setIsOpenForm(!isOpenForm);
    handleOnSubmit(data);
  };

  const onCancel = () => {
    setIsOpenForm(!isOpenForm);
  };

  return (
    <>
      {isOpenForm && (
        <ChangeLoginForm
          firstName={firstName}
          lastName={lastName}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
      {!isOpenForm && (
        <>
          <Row gap={1}>
            <Row.Col size="50%">
              <Text color={TextColor.initial} variant={TextVariant.normal} isBold={true}>
                Name:
              </Text>
              <Text color={TextColor.initial} variant={TextVariant.normal}>
                {firstName} {lastName}
              </Text>
            </Row.Col>
            {canEdit && (
              <Row.Col size="50%">
                <Button
                  name={'change-info-login'}
                  color={ButtonColor.primary}
                  variant={ButtonVariant.contained}
                  size={ButtonSize.normal}
                  onClick={() => setIsOpenForm(!isOpenForm)}
                >
                  Change name
                </Button>
              </Row.Col>
            )}
          </Row>
          <Gap height={1} />
        </>
      )}
      <Row gap={1}>
        <Row.Col>
          <Text color={TextColor.initial} variant={TextVariant.normal} isBold={true}>
            Login:
          </Text>
          <Text color={TextColor.initial} variant={TextVariant.normal}>
            {email}
          </Text>
        </Row.Col>
      </Row>
      <Gap height={1} />
      <Row gap={1}>
        <Row.Col size="50%">
          <Text color={TextColor.initial} variant={TextVariant.normal} isBold={true}>
            Password:
          </Text>
          <Text color={TextColor.initial} variant={TextVariant.normal}>
            {password}
          </Text>
        </Row.Col>
        <Row.Col size="50%">
          <Button
            name={'change-info-password'}
            color={ButtonColor.primary}
            variant={ButtonVariant.contained}
            size={ButtonSize.normal}
            onClick={handleChangePassword}
          >
            Change password
          </Button>
        </Row.Col>
      </Row>
    </>
  );
};

LoginInformation.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string,
  handleOnSubmit: PropTypes.func.isRequired,
  handleChangePassword: PropTypes.func.isRequired
};

LoginInformation.defaultProps = {
  password: '(hidden)'
};

export default LoginInformation;
