// Components
import { Row } from 'src/components/atoms/Row';
import { TextColor } from 'src/components/atoms/Text';
import Modal, { ModalVariant, ModalWidth } from 'src/components/organisms/Modal';
import Field from 'src/components/folders/MetadataModal/components/Field';

// Styles
import { StyledCard } from 'src/components/folders/FolderTileEdit/styles';

// Context
import { useFolderPage } from 'src/components/folders/FolderPageContext';

// Types
import { IEntry } from 'src/ts/interfaces';

const EntryMetadataModal = ({ entryData }: { entryData: IEntry }): JSX.Element => {
  const { showEntryMetadataModal, setShowEntryMetadataModal } = useFolderPage();

  const entryMetadata = {
    'Full Name': entryData.applicant.full_name,
    Email: entryData.applicant.email,
    Phone: entryData.applicant.phone,
    Property: entryData.folder.property?.name
  };

  return (
    <Modal
      className="EntryMetadataModal"
      title="Entry Metadata"
      variant={ModalVariant.none}
      width={ModalWidth.extraLarge}
      showModal={showEntryMetadataModal}
      setShowModal={setShowEntryMetadataModal}
      isBackClosable={false}
      isEscapeClosable
      showCloseButton
      showModalActions={false}
    >
      <Row direction="column" gap={1}>
        <StyledCard border style={{ minWidth: '480px' }}>
          <Row direction="column" gap={0.5}>
            {Object.entries(entryMetadata).map(([key, value]) => (
              <Field
                key={key}
                title={key}
                content={(value as string) || 'No information submitted'}
                contentColor={value ? undefined : TextColor.gray300}
              />
            ))}
          </Row>
        </StyledCard>
      </Row>
    </Modal>
  );
};

export default EntryMetadataModal;
