import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import { useAppDispatch } from 'src/store';
import { IEntry } from 'src/ts/interfaces';
import { splitEntryThunk } from '../services';

export const useSplitEntry = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { SnackTypes, showSnackbar, VariantType } = useSnackbarProvider();

  const onSplitEntry = useCallback(
    async (entryId?: string) => {
      if (!entryId) {
        showSnackbar(
          VariantType.error,
          'Is missing the Entry Id to split the entry',
          SnackTypes.none
        );

        return;
      }

      try {
        const action = await dispatch(splitEntryThunk(entryId));
        const response = unwrapResult(action);
        const entry = response?.data as IEntry;

        showSnackbar(VariantType.success, 'The entry has been splitted', SnackTypes.none);

        const redirect =
          location.pathname.indexOf('/my-work/') >= 0 ? '/my-work' : `/folder/${entry.folder_id}`;
        navigate(redirect);
      } catch (error) {
        showSnackbar(VariantType.error, 'Failed to split the entry', SnackTypes.none);
      }
    },
    [
      SnackTypes.none,
      VariantType.error,
      VariantType.success,
      dispatch,
      location.pathname,
      navigate,
      showSnackbar
    ]
  );

  return {
    onSplitEntry
  };
};
