import { envVars } from 'src/constants/config';
import { IConstants } from 'src/ts/interfaces';

export const overrideUrls = (constants: IConstants, host: string): IConstants => {
  // Drop the first subdomain, if present, so it works for interpolation
  const hostParts = host.split('.');
  let hostBase = hostParts.slice(hostParts.length > 2 ? 1 : 0).join('.');
  let docPortalHost = 'documentportal.info';
  if (/\.dev\./.test(host)) docPortalHost = `dev.${docPortalHost}`;
  if (/\.test\./.test(host)) docPortalHost = `test.${docPortalHost}`;
  if (/\.uat\./.test(host)) docPortalHost = `uat.${docPortalHost}`;
  if (/\.demo\./.test(host)) docPortalHost = `demo.${docPortalHost}`;

  // If this host _is_ a docportal address, we've gotta replace hostBase with
  // the snappt.com equivalent (e.g. dev.documentportal.info > dev.snappt.com)
  if (docPortalHost === hostBase) {
    hostBase = host.replace('documentportal.info', 'snappt.com');
  }

  const urls = {
    document_portal: `https://${docPortalHost}`,
    server_url: `https://fraud-api.${hostBase}`,
    frontend_url: `https://fraud.${hostBase}`,
    api_url: `https://fraud-api.${hostBase}/v2`,
    epa_url: `https://enterprise-api.${hostBase}`,
    feature_flag_api_url: `https://feature-flags.${hostBase}`
  };

  // Handle envs where structuredClone isn't available (e.g. when running tests)
  const g = typeof window !== 'undefined' ? window : global;
  const cloneMethod =
    g.structuredClone !== undefined
      ? g.structuredClone
      : (c: IConstants) => JSON.parse(JSON.stringify(c));

  // Clone and override
  const clone = cloneMethod(constants);
  delete clone.bucket_url; // Not used, just delete it.
  return Object.assign({}, clone, urls);
};

export const getConfigVars = (hostname: string): IConstants => {
  switch (hostname) {
    case 'wintheknifefight':
      return envVars.wintheknifefightDev;
    case 'fraud.snappt.com':
    case 'documentportal.info':
    case 'client.snappt.com':
      return envVars.production;
    case 'fraud-sandbox.snappt.com':
    case 'sandbox.documentportal.info':
      return envVars.sandbox;
    case 'fraud-ralph.snappt.com':
    case 'ralph.documentportal.info':
      return envVars.ralph;
    case 'fraud-demo.snappt.com':
    case 'demo.documentportal.info':
      return envVars.demo;
    case 'dev.documentportal.info':
    case 'fraud.dev.snappt.com':
    case 'fraud.dev.wintheknifefight.com':
      return overrideUrls(envVars.ralph, hostname);
    case 'test.documentportal.info':
    case 'fraud.test.snappt.com':
    case 'fraud.test.wintheknifefight.com':
      return overrideUrls(envVars.sandbox, hostname);
    // Note: same as test right now, but separating in case we want them to be
    // different in the future.
    case 'uat.documentportal.info':
    case 'fraud.uat.snappt.com':
    case 'fraud.uat.wintheknifefight.com':
      return overrideUrls(envVars.sandbox, hostname);
    case 'fraud.demo.snappt.com':
    case 'fraud.demo.wintheknifefight.com':
      return overrideUrls(envVars.demo, hostname);
    // Non-overlapping prod domains only. Must update base config after cutover.
    case 'fraud.wintheknifefight.com':
    case 'fraud.prod.wintheknifefight.com':
      return overrideUrls(envVars.production, hostname);
    default:
      return envVars.development;
  }
};

// Handle test environments where window might not be available
const hostname = typeof window !== 'undefined' ? window.location.hostname : 'development';

export default getConfigVars(hostname);
