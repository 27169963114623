import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/store';
import { Status } from 'src/ts/enums';

import { selectors } from '../entriesSlice';
import { getEntryByIdThunk } from '../services';

export const useEntryById = (entryId: string) => {
  const dispatch = useDispatch();
  const entry = useSelector((state: RootState) => selectors.selectById(state, entryId));
  const status = useSelector(selectors.status);

  useEffect(() => {
    if (entryId && !entry) {
      dispatch(getEntryByIdThunk(entryId));
    }
  }, [entryId, entry, dispatch]);

  const isUninitialized = status === Status.IDLE;
  const isLoading = status === Status.LOADING;
  const isError = status === Status.ERROR;
  const isSuccess = status === Status.SUCCESS;

  return {
    entry,
    isUninitialized,
    isLoading,
    isError,
    isSuccess
  };
};
