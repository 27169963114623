import styled from 'styled-components';

export const Radio = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
    .label {
      color: ${({ theme }) => theme.color.gray[300]};
    }
    svg {
      path {
        fill: ${({ theme }) => theme.color.gray[300]};
      }
    }
  }

  .label {
    ${({ theme }) => theme.font.small};
    color: ${({ theme }) => theme.color.gray[900]};
    margin-left: 0.375rem;
  }
`;
