// Vendor
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Hooks
import useCompany from 'src/features/company/hooks/useCompany';

// Redux
import { selectors as companySelector } from 'src/features/company/companySlice';

// Components
import { ShowState } from 'src/components/molecules/ShowState';
import CompanyListLoader from './CompanyListLoader';
import CompanyListRow from './CompanyListRow';

const CompanyList = (): JSX.Element => {
  const { onGetAll: onGetCompanies } = useCompany();

  const companiesData = useSelector(companySelector.getAll.data);
  const companiesIdle = useSelector(companySelector.getAll.isIdle);
  const companiesIsLoading = useSelector(companySelector.getAll.isLoading);
  const companiesError = useSelector(companySelector.getAll.error);
  const companiesFilter = useSelector(companySelector.getAll.filter);

  useEffect(() => {
    if (companiesIdle) {
      onGetCompanies({});
    }
  }, [companiesIdle, onGetCompanies]);

  if (companiesIsLoading) {
    return <CompanyListLoader rows={10} />;
  }

  if (companiesError) {
    return (
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Please try again"
        message="Something went wrong"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (companiesData.length === 0) {
    if (companiesFilter.q) {
      return (
        <ShowState
          message="Try adjusting your search or filter to find<br/> what you are looking for"
          searchText={decodeURIComponent(companiesFilter.q)}
          variant="search"
          type="searchEmpty"
        />
      );
    }
    return <ShowState message="There are no companies yet" type="information" variant="empty" />;
  }

  return (
    <>
      {companiesData.map((company) => (
        <CompanyListRow key={company.id} company={company} />
      ))}
    </>
  );
};

export default CompanyList;
