// Vendor
import { createAsyncThunk } from '@reduxjs/toolkit';

// Api
import api from './api';

// Constants
import { NAME } from './constants';

export const getLastThunk = createAsyncThunk(`${NAME}/getLastThunk`, async () => {
  try {
    return await api.getLast();
  } catch (error) {
    console.error(`${NAME}/getLastThunk`, error);
    throw new Error('Error fetching the last announcement');
  }
});

export const markAsShownThunk = createAsyncThunk(
  `${NAME}/markAsShownThunk`,
  async (announcementId: number) => {
    try {
      return await api.markAsShown(announcementId);
    } catch (error) {
      console.error(`${NAME}/markAsShownThunk`, error);
      throw new Error(`Error to mark the announcement ${announcementId} as shown`);
    }
  }
);
