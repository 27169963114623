import { differenceInDays, formatDistanceToNow, isValid, subDays } from 'date-fns';
import { format, formatInTimeZone } from 'date-fns-tz';
import { getUTCTime } from '../getUTCTime';

const DATE_DEFAULT_TZ = 'America/Los_Angeles';

export const dateFormat = (date?: string, dateFormat?: string) => {
  if (!date || !isValid(new Date(date))) {
    return 'Invalid date format';
  }

  return format(new Date(date), dateFormat || 'MM/dd/yyyy');
};

export const dateFormatWithDots = (date?: string) => {
  if (!date || !isValid(new Date(date))) {
    return 'Invalid date format';
  }

  return format(new Date(date), 'MM.dd.yyyy');
};

export const dateFormatWithHourAndTimeZone = (date: string) => {
  if (!date || !isValid(new Date(date))) {
    return 'Invalid date format';
  }

  return formatInTimeZone(getUTCTime(new Date(date)), DATE_DEFAULT_TZ, "MM.dd.yyyy 'at' HH:mm zzz");
};

export const todayLessDays = (days: number) => {
  return format(subDays(new Date(), days), 'MM/dd/yyyy');
};

export const today = format(new Date(), 'MM/dd/yyyy');

export const getDateByLastDays = (range?: string | 'last_30_days' | 'last_60_days') => {
  switch (range) {
    case 'last_30_days':
      return todayLessDays(30);
    case 'last_60_days':
      return todayLessDays(60);
    default:
      return today;
  }
};

export const parseTimeDistance = (date: string) => {
  const time = new Date(date);
  // If date hasn't passed 1 day or more, use formatDistance utility
  // otherwise use simple format
  if (differenceInDays(time, new Date()) === 0) {
    const distance = formatDistanceToNow(time, {
      addSuffix: true
    });
    return `${distance.charAt(0).toUpperCase()}${distance.substring(1)}`;
  }

  return format(time, 'MM/dd/yyyy');
};

export const parseTimeToYear = (date: string) => {
  const time = new Date(date);

  return format(time, 'yyyy');
};

export const parseTimeToDecimalFormat = (date: string) => {
  const time = new Date(date);

  return format(time, 'MM.dd.yyyy');
};
