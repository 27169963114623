// Vendor
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

// Components
import Button, {
  ButtonColor,
  ButtonDisabledStyle,
  ButtonVariant
} from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Text, { TextAlignItems } from 'src/components/atoms/Text';
import Dropdown from 'src/components/molecules/Dropdown/Dropdown';
import { StyledRow } from 'src/components/molecules/Pagination/styles';

// Types
import { DropdownListPosition } from 'src/components/atoms/DropdownButton/types';
import { IValueLabel } from 'src/ts/interfaces/valueLabel';

export type PaginationProps = {
  name: string;
  pageInit?: number;
  rowsPerPage: number[];
  rowsPerPageInit?: number;
  totalRows?: number;
  rows?: number;
  onNext?: (page: number, rowsPerPage: number) => void;
  isDisabledOnNext?: boolean;
  onPrev?: (page: number, rowsPerPage: number) => void;
  isDisabledOnPrev?: boolean;
  onRowsPerPage?: (page: number, rowsPerPage: number) => void;
  information?: (content: unknown) => null;
  listPosition?: DropdownListPosition;
  hideRowsPerPage?: boolean;
};

type PaginationState = {
  page: number;
  rowsPerPage: number;
  totalRows: number;
};

type FormValues = {
  rowsPerPage: number;
};

const Pagination: React.FC<PaginationProps> = (props) => {
  const {
    pageInit = 0,
    rowsPerPage,
    rowsPerPageInit = 20,
    totalRows,
    rows,
    onPrev,
    isDisabledOnPrev,
    onNext,
    isDisabledOnNext,
    onRowsPerPage,
    name,
    information,
    listPosition,
    hideRowsPerPage
  } = props;

  if (rowsPerPage.length > 0 && !rowsPerPage.includes(rowsPerPageInit))
    throw new Error('The init page number needs to be included in the "RowsPerPage" prop');

  if (rows === 0 && totalRows !== 0)
    throw new Error('The prop "rows" is only used with the prop "totalRows" empty');

  if (totalRows === 0 && rows === 0) throw new Error('Is missing the prop "rows"');

  if (hideRowsPerPage && rowsPerPage.length > 0)
    throw new Error(
      'Is not possible to use the prop "hideRowsPerPage" with values into the prop "rowsPerPage"'
    );

  const methods = useForm<FormValues>({
    defaultValues: {
      rowsPerPage: rowsPerPageInit
    }
  });

  const [result, setResult] = useState<PaginationState>({
    page: pageInit,
    rowsPerPage: rowsPerPageInit,
    totalRows: totalRows || 0
  });

  useEffect(
    () => setResult((state) => ({ ...state, page: pageInit, totalRows: totalRows || 0 })),
    [pageInit, totalRows]
  );

  useEffect(() => {
    changePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const numPages = () => {
    return Math.ceil(result.totalRows / result.rowsPerPage);
  };

  const onNextPage = () => {
    setResult({ ...result, page: result.page + 1 });
    if (onNext) {
      onNext(result.page + 1, result.rowsPerPage);
    }
  };

  const onPrevPage = () => {
    if (result.page >= 1) {
      setResult({ ...result, page: result.page - 1 });
      if (onPrev) {
        onPrev(result.page - 1, result.rowsPerPage);
      }
    }
  };

  const changePage = () => {
    let currentPage = result.page;
    if (currentPage < 1) currentPage = 0;
    if (currentPage > numPages()) currentPage = numPages();
    if (information) {
      information({ [name]: { ...result, totalRows } });
    }
  };

  const isNextPageDisabled = () => {
    if (isDisabledOnNext === undefined) {
      if (totalRows) {
        return result.page + 1 === numPages() || numPages() === 0;
      }
      return rows !== result.rowsPerPage;
    }
    return isDisabledOnNext;
  };

  const isPrevPageDisabled = () => {
    return isDisabledOnPrev || result.page === 0;
  };

  return (
    <StyledRow justify="space-between" alignItems="center" wrap="wrap">
      <Row alignItems="center" gap={1}>
        {!hideRowsPerPage && (
          <>
            <Text alignItems={TextAlignItems.center}>Rows per page:</Text>
            <FormProvider {...methods}>
              <form>
                <Controller
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      name="rowsPerPage"
                      data={
                        rowsPerPage.map((item) => {
                          return { value: item.toString(), label: item.toString() };
                        }) as IValueLabel[]
                      }
                      onChange={(content) => {
                        if (onRowsPerPage) {
                          const value = Number(content.rowsPerPage.selected.value);
                          onRowsPerPage(0, value);
                          methods.setValue('rowsPerPage', value);
                          setResult({
                            ...result,
                            rowsPerPage: value,
                            page: 0
                          });
                        }
                      }}
                      listPosition={listPosition}
                      valueInitial={result.rowsPerPage.toString()}
                      showLabel={false}
                      size="sm"
                      isDisabled={totalRows ? totalRows === 0 : false}
                    />
                  )}
                  name="rowsPerPage"
                  control={methods.control}
                  defaultValue={rowsPerPageInit}
                />
              </form>
            </FormProvider>
          </>
        )}
      </Row>

      <Row alignItems="center" gap={1}>
        <Text alignItems={TextAlignItems.center}>Page {result.page + 1}</Text>
        <Row gap={1}>
          <Button
            color={ButtonColor.primary}
            variant={ButtonVariant.ghost}
            onClick={onPrevPage}
            name="onPrev"
            isDisabled={isPrevPageDisabled()}
            disabledStyle={ButtonDisabledStyle.white}
          >
            <Icon icon="icon-arrow-left" />
          </Button>
          <Button
            color={ButtonColor.primary}
            variant={ButtonVariant.ghost}
            onClick={onNextPage}
            name="onNext"
            isDisabled={isNextPageDisabled()}
            disabledStyle={ButtonDisabledStyle.white}
          >
            <Icon icon="icon-arrow-right" />
          </Button>
        </Row>
      </Row>
    </StyledRow>
  );
};

Pagination.propTypes = {
  rowsPerPage: PropTypes.array.isRequired,
  rowsPerPageInit: PropTypes.number.isRequired,
  totalRows: PropTypes.number,
  rows: PropTypes.number,
  name: PropTypes.string.isRequired,
  pageInit: PropTypes.number,
  onNext: PropTypes.func,
  isDisabledOnNext: PropTypes.bool,
  onPrev: PropTypes.func,
  isDisabledOnPrev: PropTypes.bool,
  onRowsPerPage: PropTypes.func,
  information: PropTypes.func,
  listPosition: PropTypes.oneOf(['top', 'bottom'] as const),
  hideRowsPerPage: PropTypes.bool
};

Pagination.defaultProps = {
  pageInit: 0,
  rowsPerPage: [5, 10, 20, 50, 100, 250, 500],
  rowsPerPageInit: 25,
  totalRows: 0,
  name: 'pagination',
  onNext: () => null,
  onPrev: () => null,
  onRowsPerPage: () => null,
  information: (content) => null,
  listPosition: 'bottom',
  hideRowsPerPage: false
};

export default Pagination;
