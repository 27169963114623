import Text from 'src/components/atoms/Text';
import styled from 'styled-components/macro';

export const TextName = styled(Text)`
  overflow-wrap: anywhere;
`;

export const Container = styled.div`
  padding: 1rem;
  .row__icon_text {
    svg {
      width: 1rem;
      margin-right: 0.6rem;
      color: ${({ theme }) => theme.color.gray[600]};
    }

    @media ${({ theme }) => theme.device.mobile} {
      flex-direction: row !important;
    }
  }

  .row__text_property {
    small {
      margin-top: 0.2rem;
    }
  }

  .buttons {
    button {
      height: 2.5rem;
    }
    svg {
      width: 1rem;
    }
    > div {
      flex-basis: content;
      padding-left: 0rem;
      padding-right: 0rem;
      &:last-of-type {
        padding-right: 0.8rem;
      }
    }

    @media ${({ theme }) => theme.device.mobile} {
      flex-direction: row !important;
      margin-top: 0.5rem;
    }
  }
`;
