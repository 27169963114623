import { EntryApi } from '@snapptinc/fraud-platform';
import { fdeEntryApi } from 'src/api';
import { configInstancePDF } from 'src/api/instancePDF';
import { applicantsApi } from 'src/lib/internal/epa';
import { ApplicantReportPreset } from 'src/ts/enums/applicant';

const api = {
  getReportByEntryId: async (entryId: string) => {
    return await fdeEntryApi.getReportData(entryId);
  },
  getFcraReportPDF: async (entryId: string, fileName: string) => {
    const fdeEntryReportApi = new EntryApi(...configInstancePDF);
    const result = await fdeEntryReportApi.getFCRAReport(entryId);
    return { data: result, fileName };
  },
  getPaginatedReportPDF: async (entryId: string, fileName: string) => {
    const fdeEntryReportApi = new EntryApi(...configInstancePDF);
    const result = await fdeEntryReportApi.getPaginatedReport(entryId);
    return { data: result, fileName };
  },
  getReportPDF: async (entryId: string, fileName: string) => {
    const fdeEntryReportApi = new EntryApi(...configInstancePDF);
    const result = await fdeEntryReportApi.getReport(entryId);
    return { data: result, fileName };
  },
  getReports(entryId: string) {
    return fdeEntryApi.getReports(entryId);
  },
  getApplicantReportPDF: async (
    applicantId: string,
    preset: ApplicantReportPreset,
    fileName: string
  ) => {
    const result = await applicantsApi.applicantsApplicantIdReportGet(
      applicantId,
      undefined,
      undefined,
      preset,
      {
        responseType: 'blob'
      }
    );
    return { data: result, fileName };
  }
};

export default api;
