import PropTypes from 'prop-types';
import Col from './Col';
import { Container } from './styles';

type DirectionType = 'row' | 'row-reverse' | 'column' | 'column-reverse';
type WrapType = 'nowrap' | 'wrap' | 'wrap-reverse';
type JustifyType = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
type AlignType = 'baseline' | 'unset' | 'flex-start' | 'flex-end' | 'center' | 'stretch';

export type RowProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  gap?: number;
  direction?: DirectionType;
  wrap?: WrapType;
  justify?: JustifyType;
  alignItems?: AlignType;
  alignContent?: AlignType;
  columns?: number;
};

const Row = (props: RowProps) => {
  return <Container {...props}>{props.children}</Container>;
};

Row.propTypes = {
  children: PropTypes.node.isRequired,
  gap: PropTypes.number,
  columns: PropTypes.number
};

Row.Col = Col;
export default Row;
