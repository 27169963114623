// Vendor
import { useSelector } from 'react-redux';

// Components
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import Section from 'src/components/atoms/Section/Section';
import Text, { TextVariant } from 'src/components/atoms/Text';
import BoxStatus from 'src/components/molecules/BoxStatus';

// Constants
import { PROOF_INFO } from 'src/features/fde-report/constants';

// Redux
import { selectors as entryReportSelectors } from 'src/features/fde-report/fdeReportSlice';

// Enums
import { EntryResultEnum, FeatureFlagKey } from 'src/ts/enums';

// Hooks
import { useFeatureFlag } from 'src/hooks';

const DocumentResults: React.FC = () => {
  const entryReport = useSelector(entryReportSelectors.getEntryReport.data);
  const { proofs } = entryReport || {};

  const cleanProceedWithCautionFlagEnabled = useFeatureFlag(
    FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION
  );

  const resultTypesFiltered = cleanProceedWithCautionFlagEnabled
    ? PROOF_INFO
    : PROOF_INFO.filter(
        (resultType) => resultType.status !== EntryResultEnum.CleanProceedWithCaution
      );

  const quantityBy = (status: string) => {
    if (status === 'Uploaded') return proofs?.length;

    return proofs?.filter((proof) => proof.result === status).length;
  };

  return (
    <Section padding="0 3rem 3rem 3rem">
      <Text variant={TextVariant.h2}>Document Results</Text>
      <Gap height={1} />
      <Row className="document_results" gap={1}>
        {resultTypesFiltered.map(({ title, status, type }) => {
          const quantity = quantityBy(status) || 0;
          return (
            <BoxStatus
              key={status}
              type={type}
              title={title}
              subTitle={
                quantity > 0 ? `${quantity} document${quantity > 1 ? 's' : ''}` : 'No documents'
              }
              cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
            />
          );
        })}
      </Row>
    </Section>
  );
};

export default DocumentResults;
