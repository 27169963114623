import { IncomeCalculationDocument } from '@snapptinc/fraud-platform';
import { IncomeVerification } from '../../ts/interfaces/incomeVerification';

/**
 * Converts the shape of the income verification document object from the shape the API returns
 * to the shape the client expects.
 *
 * @param {IncomeCalculationDocument} objectToConvert
 * @returns {IncomeVerification}
 */
export function convertToIncomeVerificationType(
  objectToConvert: IncomeCalculationDocument
): IncomeVerification {
  const incomingObject: IncomeCalculationDocument = { ...objectToConvert };
  // This map helps with the conversion because we can't check the type keys at runtime...
  const mapFromApiToClient: Record<keyof IncomeCalculationDocument, keyof IncomeVerification> = {
    applicant_name: 'recipientName',
    document_end_date: 'payPeriodEndDate',
    document_id: 'id',
    document_start_date: 'payPeriodStartDate',
    gross_income: 'grossPay',
    income_source: 'incomeSource'
  };
  const result = {};

  for (const key in mapFromApiToClient) {
    const castKey = key as keyof IncomeCalculationDocument;
    if (mapFromApiToClient[castKey]) {
      const mappedKey = mapFromApiToClient[castKey];
      Object.assign(result, {
        [mappedKey]: incomingObject[castKey]
      });
    }
  }
  return result as IncomeVerification;
}
