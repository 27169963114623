import { Row } from 'src/components/atoms/Row';
import styled, { css } from 'styled-components/macro';

export const BorderBox = css`
  padding: 24px 24px;
  border: 2px solid ${({ theme }) => theme.color.gray[200]};
  border-radius: 4px;
  align-self: stretch;
  > div {
    height: 100%;
  }
`;

export const BorderContainer = styled.div`
  ${BorderBox};
`;

export const CallBox = styled(Row.Col).attrs(() => ({
  size: '70%'
}))`
  ${BorderBox};
`;

export const TeamBox = styled(Row.Col).attrs(() => ({
  size: '30%'
}))``;
