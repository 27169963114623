import { ThemeAttributes } from 'src/ts/interfaces/styled-components';
import styled from 'styled-components';
import { CardProps } from './Card';

const getBoxShadow = (elevation: number) => {
  const rem = 0.0625;
  return `0 ${elevation * rem}rem ${elevation * rem}rem rgba(0, 0, 0, .38)`;
};

const getBackground = (theme: ThemeAttributes, background?: string, elevation?: number) => {
  if (elevation) return theme.color.white;

  switch (background) {
    case 'white':
      return theme.color.white;

    case 'blue':
      return theme.color.blue[100];

    default:
      return 'transparent';
  }
};

export const Card = styled.div<CardProps>`
  border: 0.125rem solid ${({ theme, border }) => (border ? theme.color.gray[200] : 'transparent')};
  background: ${({ theme, background, elevation }) => getBackground(theme, background, elevation)};
  box-shadow: ${({ elevation }) => elevation && getBoxShadow(elevation)};
  border-radius: ${({ rounded }) => (rounded ? '0.35rem' : '0rem')};
  display: flex;
  div {
    text-align: ${({ alignContent }) => alignContent};
  }
`;

export const Body = styled.div`
  padding: 2rem;
  align-self: center;
  width: 100%;
  ${({ theme }) => theme.font.normal}
`;
