import PropTypes from 'prop-types';
import { Tab, TabList, TabPanel, TabProps, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Container, Content } from './styles';

export const TabContent = TabPanel;
export const TabTitle = Tab;
export const TabHeader = TabList;

export const TabBody = ({ children }: { children: React.ReactNode }) => {
  return <Content>{children}</Content>;
};

export type MultiTabProps = Omit<TabProps, 'onSelect'> & {
  name: string;
  onSelect?: (index: number) => void;
  children: React.ReactNode;
  selectedIndex?: number;
  hasSpacing?: boolean;
};

/**
 * Based on `react-tabs`
 * https://github.com/reactjs/react-tabs
 */
const MultiTab: React.FC<MultiTabProps> = ({
  name,
  selectedIndex,
  onSelect,
  children,
  hasSpacing
}) => {
  const onSelecting = (indexSelected: number) => {
    onSelect && onSelect(indexSelected);
  };

  return (
    <Container>
      <Tabs
        selectedIndex={selectedIndex}
        id={name}
        className={`MultiTab header_spacing_option_${hasSpacing}`}
        onSelect={(index) => onSelecting(index)}
        role="tab"
        aria-selected="true"
        aria-label={name}
      >
        {children}
      </Tabs>
    </Container>
  );
};

MultiTab.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  selectedIndex: PropTypes.number,
  onSelect: PropTypes.func,
  hasSpacing: PropTypes.bool
};

MultiTab.defaultProps = {
  name: 'MultiTab'
};

export default MultiTab;
