import styled from 'styled-components/macro';

export const StyledTableBodyCollapsable = styled.tbody`
  display: table-row-group;
  width: 100%;

  & > tr {
    :nth-child(2n-1) {
      background: ${({ theme }) => theme.color.gray[50]};
    }

    :nth-child(4n-1) {
      background: ${({ theme }) => theme.color.gray[100]};
    }

    :nth-child(2n-1),
    :nth-child(4n-1) {
      :hover {
        background: ${({ theme }) => theme.color.blue[100]};
      }
    }

    & > td {
      padding: 0.5rem 1rem;
    }
  }
`;
