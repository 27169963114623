// Vendor
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import Button, { ButtonColor, ButtonVariant } from 'src/components/atoms/Button';
import { Row } from 'src/components/atoms/Row';
import Text, { TextVariant } from 'src/components/atoms/Text';
import TeamCard from 'src/components/molecules/TeamCard/TeamCard';
import { EntryLogsHistory } from 'src/pages/MyWorkPage/components/EntryLogsHistory';

import { BorderContainer, CallBox, TeamBox } from '../styles';
import { LoaderCallBox } from './LoaderCallBox';
import { LoaderTeamCard } from './LoaderTeamCard';

// Redux
import { selectors } from 'src/features/auth/authSlice';
import useRole from 'src/features/auth/hooks/useUserRoles';
import { selectors as myWorkSelectors, useEntryAssignment } from 'src/features/myWork';

const ReviewTab = ({ isLoading }: { isLoading: boolean }): JSX.Element => {
  const navigate = useNavigate();
  const user = useSelector(selectors.user);
  const isLoadingSubmissionRequest = useSelector(myWorkSelectors.isLoadingSubmissionRequest);
  const assignedEntry = useSelector(myWorkSelectors.assignedEntry);

  const { isAnalyst } = useRole();

  const userFdeManagerName =
    user?.team?.fde_manager &&
    `${user.team.fde_manager?.first_name} ${user.team.fde_manager?.last_name}`;

  const { requestEntry } = useEntryAssignment();

  if (user && !user.team?.id) {
    return (
      <BorderContainer>
        <Row gap={1} direction="column">
          <Text variant={TextVariant.h2}>You haven’t been assigned to a Team</Text>
          <Text variant={TextVariant.h4}>Please reach out the FDE Manager to begin work.</Text>
        </Row>
      </BorderContainer>
    );
  }

  if (assignedEntry) {
    return (
      <div>
        <Row justify="space-between" gap={1} alignItems="center">
          <CallBox>
            {isLoading ? (
              <LoaderCallBox />
            ) : (
              <Row alignItems="center" justify="space-between">
                <Row gap={1} direction="column" justify="center">
                  <Text variant={TextVariant.h2}>Submission pending</Text>
                  <Text variant={TextVariant.normal}>
                    You have an active submission, please continue to complete it.
                  </Text>
                </Row>
                <Button
                  name="continue-reviewing"
                  variant={ButtonVariant.contained}
                  color={ButtonColor.primary}
                  onClick={() => {
                    if (assignedEntry?.workflow === 'income_verification') {
                      navigate(
                        `/my-work/incomeVerification/${assignedEntry.income_verification_review_id}/${assignedEntry.id}`
                      );
                    } else {
                      navigate(`/my-work/${assignedEntry.folder_id}`);
                    }
                  }}
                >
                  Continue Reviewing
                </Button>
              </Row>
            )}
          </CallBox>
          <TeamBox>
            {isLoading ? (
              <LoaderTeamCard />
            ) : (
              <TeamCard
                teamName={user?.team?.name}
                timezone={user?.team?.timezone}
                fdeManagerName={userFdeManagerName}
              />
            )}
          </TeamBox>
        </Row>
        {isAnalyst && <EntryLogsHistory />}
      </div>
    );
  }

  return (
    <div>
      <Row justify="space-between" gap={1} alignItems="center">
        <CallBox>
          {isLoading ? (
            <LoaderCallBox />
          ) : (
            <Row alignItems="center" justify="space-between">
              <Row gap={1} direction="column">
                <Text variant={TextVariant.h2}>You are up to date!</Text>
                <Text variant={TextVariant.normal}>
                  Ask for a new submission to continue reviewing.
                </Text>
              </Row>
              <Button
                name="ask-for-submission"
                variant={ButtonVariant.contained}
                color={ButtonColor.primary}
                onClick={() => requestEntry()}
                isDisabled={isLoadingSubmissionRequest}
                isLoading={isLoadingSubmissionRequest}
              >
                Ask for submission
              </Button>
            </Row>
          )}
        </CallBox>
        <TeamBox>
          {isLoading ? (
            <LoaderTeamCard />
          ) : (
            <TeamCard
              teamName={user?.team?.name}
              timezone={user?.team?.timezone}
              fdeManagerName={userFdeManagerName}
            />
          )}
        </TeamBox>
      </Row>
      {isAnalyst && <EntryLogsHistory />}
    </div>
  );
};

export default ReviewTab;
