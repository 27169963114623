import PropTypes from 'prop-types';
import Gap from 'src/components/atoms/Gap';
import { Line } from 'src/components/atoms/Line';
import Row from '../../atoms/Row/Row';
import SkeletonPiece, { SkeletonPieceColor } from '../../atoms/SkeletonPiece';
import { Container } from './styles';

export type LoaderInvitationListProps = {
  rows?: number;
};

const LoaderInvitationList: React.FC<LoaderInvitationListProps> = (props) => {
  const { rows } = props;
  return (
    <div data-testid="LoaderInvitationList">
      {Array(rows)
        .fill(0)
        .map((_, i) => {
          return (
            <Container key={i}>
              <Line height="tiny" />
              <Gap height={0.9375} />
              <Row justify="space-between">
                <Row.Col size={'80%'}>
                  <SkeletonPiece width={185} height={20} color={SkeletonPieceColor.gray200} />
                  <Gap height={0.5} />
                  <SkeletonPiece width={339} height={15} color={SkeletonPieceColor.gray50} />
                  <Gap height={0.9375} />
                  <SkeletonPiece width={245} height={15} color={SkeletonPieceColor.gray100} />
                </Row.Col>
                <Row.Col size={'auto'} alignSelf="flex-start">
                  <Row gap={1} className={'Skeleton___Box'} justify={'flex-end'}>
                    <SkeletonPiece width={20} height={20} color={SkeletonPieceColor.gray200} />
                    <SkeletonPiece width={20} height={20} color={SkeletonPieceColor.gray200} />
                    <SkeletonPiece width={20} height={20} color={SkeletonPieceColor.gray200} />
                  </Row>
                </Row.Col>
              </Row>
              <Gap height={0.9375} />
            </Container>
          );
        })}
    </div>
  );
};
LoaderInvitationList.propTypes = {
  rows: PropTypes.number
};

LoaderInvitationList.defaultProps = {
  rows: 10
};

export default LoaderInvitationList;
