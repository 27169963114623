// Vendor
import { useEffect, useMemo } from 'react';

// Components
import FolderRowLoader from 'src/components/molecules/FolderRowLoader';
import { ShowState } from 'src/components/molecules/ShowState';
import IdentityVerificationTable from 'src/components/organisms/IdentityVerificationTable';

// Hooks
import { useGetIdentityApplicants } from 'src/features/identityVerification/hooks';
import useUserRoles from 'src/features/auth/hooks/useUserRoles';
import { useSelector } from 'react-redux';

//Redux
import { selectors } from 'src/features/identityVerification/identityVerificationSlice';

const IdentityVerificationList: React.FC = () => {
  const { role } = useUserRoles();
  const { onGetIdentityApplicantsResults } = useGetIdentityApplicants();
  const data = useSelector(selectors.getIdentityApplicants.data);
  const filters = useSelector(selectors.getIdentityApplicants.filters);
  const isError = useSelector(selectors.getIdentityApplicants.isError);
  const isLoading = useSelector(selectors.getIdentityApplicants.isLoading);
  const memoizedFilters = useMemo(() => ({ ...filters }), [filters]);

  useEffect(() => {
    onGetIdentityApplicantsResults(memoizedFilters);
  }, [onGetIdentityApplicantsResults, memoizedFilters]);

  if (isError) {
    return (
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Please try again"
        message="Something went wrong"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (isLoading) {
    return <FolderRowLoader />;
  }

  if (data?.length === 0) {
    if (filters.q) {
      return (
        <ShowState
          message="Try adjusting your search or filter to find<br/> what you are looking for"
          searchText={filters.q}
          variant="search"
          type="searchEmpty"
        />
      );
    }
    return (
      <ShowState
        message="There are no results <br /> available to show"
        type="application"
        variant="empty"
      />
    );
  }

  if (!role) return null;

  return (
    <>
      {data?.map((identityVerificationResult) => {
        return (
          <IdentityVerificationTable
            key={identityVerificationResult.id}
            result={identityVerificationResult}
            isLoading={isLoading}
            role={role}
          />
        );
      })}
    </>
  );
};

export default IdentityVerificationList;
