/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Components
import Avatar from 'src/components/atoms/Avatar';
import Button, { ButtonColor, ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import { ButtonLinkColor } from 'src/components/atoms/ButtonLink';
import { Icon } from 'src/components/atoms/Icon';
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import Text, { TextAlignItems, TextVariant } from 'src/components/atoms/Text';
import Toggle from 'src/components/atoms/Toggle';
import ToolTip, { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import CopyButton from 'src/components/molecules/CopyButton';
import { YardiLogo } from 'src/components/molecules/YardiLogo';
import { ButtonIconStyle, Container, StyledContainer, StyledDiv, StyledGrid } from './styles';

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';

// Helpers
import { getPrivateLink, getPublicLink } from 'src/features/DUP/helpers';

// Types
import { IPropertyForList } from 'src/ts/interfaces';

export type PropertyProps = Pick<
  IPropertyForList,
  | 'name'
  | 'company_name'
  | 'unit'
  | 'status'
  | 'address'
  | 'city'
  | 'state'
  | 'zip'
  | 'id'
  | 'short_id'
  | 'company_short_id'
  | 'logo'
  | 'is_linked_to_yardi'
>;

const PropertyRow: React.FC<
  PropertyProps & { onUpdateStatus: () => void; isDupDisabled?: boolean }
> = (props) => {
  const navigate = useNavigate();
  const {
    status,
    id: propertyId,
    company_short_id,
    company_name,
    short_id: propertyShortId,
    logo,
    is_linked_to_yardi,
    onUpdateStatus,
    isDupDisabled
  } = props;

  const { isBuildingManager, isCompanyAdmin } = useRole();

  const isToggleActive = status === 'ACTIVE';
  const isPropertyDisabled = status === 'DISABLED' || status === 'PENDING';

  return (
    <>
      <Line height="tiny" />
      <Container>
        <StyledContainer justify="space-between">
          <Row>
            <Avatar
              src={logo || 'assets/images/property-logo.svg'}
              isDisabled={isPropertyDisabled}
            />
            <StyledGrid direction="column">
              <Text
                className="small-margin"
                isBold
                alignItems={TextAlignItems.center}
                color={isPropertyDisabled ? 'gray600' : 'initial'}
              >
                {props.name}
                <Text
                  variant={TextVariant.small}
                  color={isPropertyDisabled ? 'gray600' : 'gray700'}
                >
                  &nbsp; {props.unit} units
                </Text>
              </Text>
              <Text className="big-margin" color={isPropertyDisabled ? 'gray600' : 'gray700'}>
                {`${props.address}, ${props.city}, ${props.state} ${props.zip}`}
              </Text>
              <Text className="big-margin" color="gray600">
                Company: {company_name}
              </Text>
              {isDupDisabled ? (
                <YardiLogo isYardiActivated={Boolean(is_linked_to_yardi)} />
              ) : (
                <>
                  <Text className="big-margin" color="gray600">
                    Document Upload Portal links below
                  </Text>
                  <Row gap={1.5}>
                    <CopyButton
                      to={getPublicLink(propertyShortId || '', company_short_id || '')}
                      label="Applicants Link"
                      isDisabled={isPropertyDisabled}
                      showCopyButton
                    />
                    <CopyButton
                      to={getPrivateLink(propertyShortId || '', company_short_id || '')}
                      label="Leasing Team Link"
                      colorLink={ButtonLinkColor.secondary}
                      colorCopy={ButtonColor.secondary}
                      isDisabled={isPropertyDisabled}
                    />
                    <YardiLogo isYardiActivated={Boolean(is_linked_to_yardi)} />
                  </Row>
                </>
              )}
            </StyledGrid>
          </Row>
          <Row gap={1}>
            <ButtonIconStyle>
              <Button
                name="gear icon"
                variant={ButtonVariant.ghost}
                size={ButtonSize.medium}
                onClick={() => navigate(`/properties/${propertyId}/edit`)}
              >
                <Icon icon="gear" />
              </Button>
            </ButtonIconStyle>
            {!isBuildingManager && !isCompanyAdmin && (
              <StyledDiv>
                <ToolTip
                  content={isToggleActive ? 'Disable this property' : 'Enable this property'}
                  direction={ToolTipDirection.bottom_fit}
                >
                  <Toggle name="disabled" isActive={isToggleActive} onClick={onUpdateStatus} />
                </ToolTip>
              </StyledDiv>
            )}
          </Row>
        </StyledContainer>
      </Container>
    </>
  );
};

PropertyRow.propTypes = {
  status: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  unit: PropTypes.number.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  short_id: PropTypes.string.isRequired,
  company_name: PropTypes.string.isRequired,
  company_short_id: PropTypes.string.isRequired,
  logo: PropTypes.string,
  is_linked_to_yardi: PropTypes.bool,
  onUpdateStatus: PropTypes.func.isRequired,
  isDupDisabled: PropTypes.bool
};

export default PropertyRow;
