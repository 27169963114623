import { BrowserRouter } from 'react-router-dom';

import UnauthenticatedRoutes from './UnauthenticatedRoutes';
import AuthenticatedRoutes from './AuthenticatedRoutes';

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <UnauthenticatedRoutes />
      <AuthenticatedRoutes />
    </BrowserRouter>
  );
};

export default Router;
