import { TypeIcon } from 'src/components/molecules/BoxStatus';
import { ProofResultEnum } from 'src/ts/enums';

export const NAME = 'fdeReport';

export type IPROOF_INFO = {
  title: string;
  status: string;
  type: TypeIcon;
  note: string;
};
export const PROOF_INFO: IPROOF_INFO[] = [
  {
    title: 'Uploaded',
    status: 'Uploaded',
    type: 'upload',
    note: ''
  },
  {
    title: 'Clean',
    status: ProofResultEnum.Clean,
    type: 'success',
    note: 'No indication of editing has been detected.'
  },
  {
    title: 'Edited',
    status: ProofResultEnum.Edited,
    type: 'fail',
    note: 'Snappt has found issues with one or more of the following: any modifications to the document, or differences between known authentic documents of this type.'
  },
  {
    title: 'Clean - Proceed with Caution',
    status: ProofResultEnum.CleanProceedWithCaution,
    type: 'caution',
    note: ''
  },
  {
    title: 'Insufficient Documentation',
    status: ProofResultEnum.Undetermined,
    type: 'warning',
    note: ''
  }
];
