/* tslint:disable */
/* eslint-disable */
/**
 * Snappt API
 * Welcome to the Snappt API! API endpoints require a Snappt partner API key passed in the HTTP Authorization header.
 *
 * The version of the OpenAPI document: 0.11.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * A 400 error
 * @export
 * @interface ApplicantsApplicantIdReportGet400Response
 */
export interface ApplicantsApplicantIdReportGet400Response {
    /**
     * 
     * @type {string}
     * @memberof ApplicantsApplicantIdReportGet400Response
     */
    'error': string;
}
/**
 * A 401 error
 * @export
 * @interface ApplicantsApplicantIdReportGet401Response
 */
export interface ApplicantsApplicantIdReportGet401Response {
    /**
     * 
     * @type {string}
     * @memberof ApplicantsApplicantIdReportGet401Response
     */
    'error': string;
}
/**
 * A 404 error
 * @export
 * @interface ApplicantsApplicantIdReportGet404Response
 */
export interface ApplicantsApplicantIdReportGet404Response {
    /**
     * 
     * @type {string}
     * @memberof ApplicantsApplicantIdReportGet404Response
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsGet200ResponseInner
 */
export interface IdentityApplicantsGet200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsGet200ResponseInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsGet200ResponseInner
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsGet200ResponseInner
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsGet200ResponseInner
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsGet200ResponseInner
     */
    'status': IdentityApplicantsGet200ResponseInnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsGet200ResponseInner
     */
    'score': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsGet200ResponseInner
     */
    'scoreReason': string | null;
    /**
     * An ISO-formattted datetime
     * @type {string}
     * @memberof IdentityApplicantsGet200ResponseInner
     */
    'sessionCreatedAt': string;
    /**
     * An ISO-formattted datetime
     * @type {string}
     * @memberof IdentityApplicantsGet200ResponseInner
     */
    'sessionUpdatedAt': string;
    /**
     * An ISO-formattted datetime
     * @type {string}
     * @memberof IdentityApplicantsGet200ResponseInner
     */
    'sessionClosedAt': string;
    /**
     * An ISO-formattted datetime
     * @type {string}
     * @memberof IdentityApplicantsGet200ResponseInner
     */
    'licenseExpiresAt': string;
    /**
     * 
     * @type {IdentityGenerateApplicantLinkPost200ResponseProperty}
     * @memberof IdentityApplicantsGet200ResponseInner
     */
    'property': IdentityGenerateApplicantLinkPost200ResponseProperty;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsGet200ResponseInner
     */
    'name': string | null;
}

export const IdentityApplicantsGet200ResponseInnerStatusEnum = {
    Pass: 'PASS',
    Fail: 'FAIL',
    Pending: 'PENDING',
    NeedsReview: 'NEEDS REVIEW',
    Reviewing: 'REVIEWING'
} as const;

export type IdentityApplicantsGet200ResponseInnerStatusEnum = typeof IdentityApplicantsGet200ResponseInnerStatusEnum[keyof typeof IdentityApplicantsGet200ResponseInnerStatusEnum];

/**
 * 200
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200Response
 */
export interface IdentityApplicantsIdVerificationIdGet200Response {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'error': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'failedChecks'?: Array<string>;
    /**
     * An ISO-formattted datetime
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'sessionCreatedAt': string;
    /**
     * An ISO-formattted datetime
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'sessionUpdatedAt': string;
    /**
     * An ISO-formattted datetime
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'sessionClosedAt': string;
    /**
     * An ISO-formattted datetime
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'licenseExpiresAt': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'score': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'status': IdentityApplicantsIdVerificationIdGet200ResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'scoreReason': string | null;
    /**
     * 
     * @type {IdentityGenerateApplicantLinkPost200ResponseProperty}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'property': IdentityGenerateApplicantLinkPost200ResponseProperty;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'propertyLogoUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'companyName': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'companyLogoUrl': string | null;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationData}
     * @memberof IdentityApplicantsIdVerificationIdGet200Response
     */
    'idVerificationData': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationData;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseStatusEnum = {
    Pass: 'PASS',
    Fail: 'FAIL',
    Pending: 'PENDING',
    NeedsReview: 'NEEDS REVIEW',
    Reviewing: 'REVIEWING'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseStatusEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseStatusEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseStatusEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf
     */
    'error': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf
     */
    'failedChecks'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1 {
    /**
     * An ISO-formattted datetime
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'sessionCreatedAt': string;
    /**
     * An ISO-formattted datetime
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'sessionUpdatedAt': string;
    /**
     * An ISO-formattted datetime
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'sessionClosedAt': string;
    /**
     * An ISO-formattted datetime
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'licenseExpiresAt': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'score': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'status': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'scoreReason': string | null;
    /**
     * 
     * @type {IdentityGenerateApplicantLinkPost200ResponseProperty}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'property': IdentityGenerateApplicantLinkPost200ResponseProperty;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'propertyLogoUrl': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'companyName': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'companyLogoUrl': string | null;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationData}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1
     */
    'idVerificationData': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationData;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1StatusEnum = {
    Pass: 'PASS',
    Fail: 'FAIL',
    Pending: 'PENDING',
    NeedsReview: 'NEEDS REVIEW',
    Reviewing: 'REVIEWING'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1StatusEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1StatusEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1StatusEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationData
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationData {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationData
     */
    'incodeId': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationData
     */
    'incodeUrl': string;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationData
     */
    'ocr': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr | null;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationData
     */
    'score': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore | null;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr {
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrName}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'name': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrName;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'address': string;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressFields}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'addressFields': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressFields;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'fullAddress': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'invalidAddress': boolean;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'checkedAddress': string;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrCheckedAddressBean}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'checkedAddressBean': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrCheckedAddressBean;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'exteriorNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'interiorNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'addressFromStatement': string;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressFields}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'addressFieldsFromStatement': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressFields;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'invalidAddressFromStatement': boolean;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'addressStatementEmissionDate': number;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'documentType': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrDocumentTypeEnum;
    /**
     * 
     * @type {Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressStatementTimestampsInner>}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'addressStatementTimestamps'?: Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressStatementTimestampsInner>;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'poaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'typeOfId': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrTypeOfIdEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'documentFrontSubtype'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'documentBackSubtype'?: string;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'birthDate': number;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'gender': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'claveDeElector'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'curp'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'numeroEmisionCredencial'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'cic'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'ocr'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'documentNumber': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'personalNumber': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'refNumber': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'taxIdNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'nue'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'externalId': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'issuedAt': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'expireAt': string;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'issueDate': number;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'expirationDate': number;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'registrationDate': number;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'issuingCountry': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'issuingState': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'birthPlace': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'issuingAuthority': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'height': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'weight': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'eyeColor': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'hairColor': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'bloodType': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'maritalStatus': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'nationality': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'nationalityMrz'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'nationalityAlpha3'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'race': string;
    /**
     * 
     * @type {Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAdditionalTimestampsInner>}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'additionalTimestamps'?: Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAdditionalTimestampsInner>;
    /**
     * 
     * @type {Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrDlClassDetailsInner>}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'dlClassDetails'?: Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrDlClassDetailsInner>;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGovernmentComparisonResults}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'governmentComparisonResults'?: IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGovernmentComparisonResults;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'notExtracted'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'notExtractedDetails'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'classes'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'cond'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'mentions'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'restrictions'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'mrz1'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'mrz2'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'mrz3'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'fullNameMrz'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'documentNumberCheckDigit'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'dateOfBirthCheckDigit'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'expirationDateCheckDigit'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'barcodeRawData'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'fathersName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'mothersName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'federalRevenueNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'originDocumentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'driversLicenseCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'controlNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'renach'?: string;
    /**
     * 
     * @type {object}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcr
     */
    'ocrDataConfidence'?: object;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrDocumentTypeEnum = {
    Liverpool: 'liverpool',
    Citibanamex: 'citibanamex',
    Cfe: 'cfe',
    Telcel: 'telcel',
    Izzi: 'izzi',
    Axtel: 'axtel',
    Telmex: 'telmex',
    Oapas: 'oapas',
    Sacmex: 'sacmex',
    Opdm: 'opdm',
    Naturgy: 'naturgy',
    Drenaje: 'drenaje',
    Totalplay: 'totalplay',
    Dhc: 'dhc',
    Att: 'att',
    Cea: 'cea',
    Smapa: 'smapa',
    Megacable: 'megacable',
    Jmas: 'jmas',
    Amicsa: 'amicsa',
    Caev: 'caev',
    Cre: 'cre',
    Ute: 'ute',
    OtherPoa: 'otherPoa'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrDocumentTypeEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrDocumentTypeEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrDocumentTypeEnum];
export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrTypeOfIdEnum = {
    Unknown: 'Unknown',
    Passport: 'Passport',
    Visa: 'Visa',
    DriversLicense: 'DriversLicense',
    IdentificationCard: 'IdentificationCard',
    Permit: 'Permit',
    Currency: 'Currency',
    ResidenceDocument: 'ResidenceDocument',
    TravelDocument: 'TravelDocument',
    BirthCertificate: 'BirthCertificate',
    VehicleRegistration: 'VehicleRegistration',
    Other: 'Other',
    WeaponLicense: 'WeaponLicense',
    TribalIdentification: 'TribalIdentification',
    VoterIdentification: 'VoterIdentification',
    Military: 'Military',
    TaxIdentification: 'TaxIdentification',
    FederalId: 'FederalID',
    MedicalCard: 'MedicalCard'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrTypeOfIdEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrTypeOfIdEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrTypeOfIdEnum];
export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGenderEnum = {
    M: 'M',
    F: 'F'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGenderEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGenderEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGenderEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAdditionalTimestampsInner
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAdditionalTimestampsInner {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAdditionalTimestampsInner
     */
    'dateType': string;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAdditionalTimestampsInner
     */
    'timestamp': number;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressFields
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressFields {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressFields
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressFields
     */
    'colony'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressFields
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressFields
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressFields
     */
    'state'?: string;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressStatementTimestampsInner
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressStatementTimestampsInner {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressStatementTimestampsInner
     */
    'dateType': string;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrAddressStatementTimestampsInner
     */
    'addressStatementTimestamp': number;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrCheckedAddressBean
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrCheckedAddressBean {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrCheckedAddressBean
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrCheckedAddressBean
     */
    'colony'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrCheckedAddressBean
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrCheckedAddressBean
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrCheckedAddressBean
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrCheckedAddressBean
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrDlClassDetailsInner
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrDlClassDetailsInner {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrDlClassDetailsInner
     */
    'dlClass': string;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrDlClassDetailsInner
     */
    'validFromDate': number;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrDlClassDetailsInner
     */
    'validToDate': number;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrDlClassDetailsInner
     */
    'additionalCodes': string;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGovernmentComparisonResults
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGovernmentComparisonResults {
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGovernmentComparisonResults
     */
    'paternalLastNameValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGovernmentComparisonResults
     */
    'maternalLastNameValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGovernmentComparisonResults
     */
    'firstNameValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGovernmentComparisonResults
     */
    'curpValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGovernmentComparisonResults
     */
    'ocrValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGovernmentComparisonResults
     */
    'claveDeElectorValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGovernmentComparisonResults
     */
    'numeroEmisionCredencialValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGovernmentComparisonResults
     */
    'registrationDateValid': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrGovernmentComparisonResults
     */
    'issueDateValid': boolean;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrName
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrName {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrName
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrName
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrName
     */
    'paternalLastName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrName
     */
    'maternalLastName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrName
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrName
     */
    'middleName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrName
     */
    'nameSuffix': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrName
     */
    'machineReadableFullName': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrName
     */
    'givenNameMrz': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataOcrName
     */
    'lastNameMrz': string;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'reasonMsg': string;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidation}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'idValidation': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidation;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidation}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'secondIdValidation': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidation;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognition}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'faceRecognition': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognition;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognition}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'antifraud': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognition;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreLiveness}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'liveness': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreLiveness;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidation}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'governmentValidation': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidation;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreExternalVerification}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'externalVerification': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreExternalVerification;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdOcrConfidence}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'idOcrConfidence': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdOcrConfidence;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdOcrConfidence}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'idOcrConfidenceSecondId'?: IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdOcrConfidence;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreRetryInfo}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'retryInfo'?: IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreRetryInfo;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRule}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'appliedRule': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRule;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'needsReviewReason'?: IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreNeedsReviewReasonEnum;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIncodeWatchlistScore}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'incodeWatchlistScore': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIncodeWatchlistScore;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationOverall}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScore
     */
    'overall': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationOverall;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreNeedsReviewReasonEnum = {
    ManualCapture: 'MANUAL_CAPTURE',
    RuleApplied: 'RULE_APPLIED',
    OldId: 'OLD_ID'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreNeedsReviewReasonEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreNeedsReviewReasonEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreNeedsReviewReasonEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRule
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRule {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRule
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRule
     */
    'expression': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRule
     */
    'ruleType': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRuleRuleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRule
     */
    'status': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRuleStatusEnum;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRuleRuleTypeEnum = {
    Total: 'total'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRuleRuleTypeEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRuleRuleTypeEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRuleRuleTypeEnum];
export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRuleStatusEnum = {
    Ok: 'OK',
    Warn: 'WARN',
    Fail: 'FAIL',
    Unknown: 'UNKNOWN'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRuleStatusEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRuleStatusEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreAppliedRuleStatusEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreExternalVerification
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreExternalVerification {
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreExternalVerification
     */
    'nameAddressCorrelation': number;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreExternalVerification
     */
    'nameEmailCorrelation': number;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreExternalVerification
     */
    'namePhoneCorrelation': number;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreExternalVerification
     */
    'addressRisk': number;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreExternalVerification
     */
    'emailRisk': number;
    /**
     * 
     * @type {number}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreExternalVerification
     */
    'phoneRisk': number;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognition
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognition {
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverall}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognition
     */
    'overall': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverall;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverall
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverall {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverall
     */
    'status': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverallStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverall
     */
    'value': string;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverallStatusEnum = {
    Ok: 'OK',
    Warn: 'WARN',
    Fail: 'FAIL',
    Unknown: 'UNKNOWN'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverallStatusEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverallStatusEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverallStatusEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidation
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidation {
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationRecognitionConfidence}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidation
     */
    'recognitionConfidence'?: IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationRecognitionConfidence;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatus}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidation
     */
    'validationStatus': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatus;
    /**
     * 
     * @type {Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInner>}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidation
     */
    'ocrValidation': Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInner>;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationOverall}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidation
     */
    'ocrValidationOverall': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationOverall;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOverall}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidation
     */
    'overall': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOverall;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInner
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInner {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInner
     */
    'status': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInner
     */
    'key': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInnerKeyEnum;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInnerStatusEnum = {
    Ok: 'OK',
    Fail: 'FAIL',
    Unknown: 'UNKNOWN'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInnerStatusEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInnerStatusEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInnerStatusEnum];
export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInnerKeyEnum = {
    IssueDate: 'issueDate',
    FirstName: 'firstName',
    MaternalLastName: 'maternalLastName',
    PaternalLastName: 'paternalLastName',
    Ocr: 'ocr',
    PersonalId: 'personalId',
    ElectorsKey: 'electorsKey',
    EmissionNumber: 'emissionNumber',
    RegistrationDate: 'registrationDate'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInnerKeyEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInnerKeyEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationInnerKeyEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationOverall
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationOverall {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationOverall
     */
    'status': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationOverallStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationOverall
     */
    'value': string;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationOverallStatusEnum = {
    Ok: 'OK',
    Fail: 'FAIL'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationOverallStatusEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationOverallStatusEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationOverallStatusEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOverall
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOverall {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOverall
     */
    'status': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOverallStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOverall
     */
    'value': string;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOverallStatusEnum = {
    Ok: 'OK',
    Fail: 'FAIL',
    Unknown: 'UNKNOWN'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOverallStatusEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOverallStatusEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOverallStatusEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationRecognitionConfidence
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationRecognitionConfidence {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationRecognitionConfidence
     */
    'status': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationRecognitionConfidenceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationRecognitionConfidence
     */
    'value': string;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationRecognitionConfidenceStatusEnum = {
    Ok: 'OK',
    Warn: 'WARN',
    Fail: 'FAIL'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationRecognitionConfidenceStatusEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationRecognitionConfidenceStatusEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationRecognitionConfidenceStatusEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatus
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatus {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatus
     */
    'status': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatusStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatus
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatus
     */
    'key': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatusKeyEnum;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatusStatusEnum = {
    Ok: 'OK',
    Fail: 'FAIL',
    Unknown: 'UNKNOWN'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatusStatusEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatusStatusEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatusStatusEnum];
export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatusKeyEnum = {
    ProcessingIne: 'processingIne',
    Ok: 'ok',
    ValidationError: 'validationError',
    IneConnectionError: 'ineConnectionError',
    IneInfrastructureError: 'ineInfrastructureError',
    ModuleNotSupported: 'moduleNotSupported',
    MissingDocumentId: 'missingDocumentId',
    MissingSelfie: 'missingSelfie',
    UserNotFound: 'userNotFound',
    UserNotFoundInIneDb: 'userNotFoundInIneDb',
    NotEnoughData: 'notEnoughData',
    LivenessFail: 'livenessFail',
    IneNotCurrent: 'ineNotCurrent',
    IneReportedLost: 'ineReportedLost',
    IneReportedStolen: 'ineReportedStolen',
    IneSignaturError: 'ineSignaturError',
    GeographicRegionNotSupported: 'geographicRegionNotSupported'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatusKeyEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatusKeyEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationValidationStatusKeyEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdOcrConfidence
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdOcrConfidence {
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationOverall}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdOcrConfidence
     */
    'overallConfidence': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreGovernmentValidationOcrValidationOverall;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidation
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidation {
    /**
     * 
     * @type {Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInner>}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidation
     */
    'photoSecurityAndQuality': Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInner>;
    /**
     * 
     * @type {Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInner>}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidation
     */
    'idSpecific': Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInner>;
    /**
     * 
     * @type {Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInner>}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidation
     */
    'customFields'?: Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInner>;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRule}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidation
     */
    'appliedRule': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRule;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationOverall}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidation
     */
    'overall': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationOverall;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRule
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRule {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRule
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRule
     */
    'expression': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRule
     */
    'ruleType': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRuleRuleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRule
     */
    'status': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRuleStatusEnum;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRuleRuleTypeEnum = {
    IdValidation: 'idValidation'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRuleRuleTypeEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRuleRuleTypeEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRuleRuleTypeEnum];
export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRuleStatusEnum = {
    Ok: 'OK',
    Warn: 'WARN',
    Fail: 'FAIL',
    Unknown: 'UNKNOWN'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRuleStatusEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRuleStatusEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationAppliedRuleStatusEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInner
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInner {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInner
     */
    'status': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInner
     */
    'key': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInnerKeyEnum;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInnerStatusEnum = {
    Ok: 'OK',
    Warn: 'WARN',
    Fail: 'FAIL',
    Unknown: 'UNKNOWN'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInnerStatusEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInnerStatusEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInnerStatusEnum];
export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInnerKeyEnum = {
    FirstNameMatch: 'firstNameMatch',
    LastNameMatch: 'lastNameMatch'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInnerKeyEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInnerKeyEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationCustomFieldsInnerKeyEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInner
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInner {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInner
     */
    'status': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInner
     */
    'key': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInnerKeyEnum;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInnerStatusEnum = {
    Ok: 'OK',
    Warn: 'WARN',
    Fail: 'FAIL',
    Unknown: 'UNKNOWN'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInnerStatusEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInnerStatusEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInnerStatusEnum];
export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInnerKeyEnum = {
    DocumentClassification: 'documentClassification',
    VisiblePhotoFeatures: 'visiblePhotoFeatures',
    IssueDateValidity: 'issueDateValidity',
    DocumentExpired: 'documentExpired',
    DocumentSeriesExpired: 'documentSeriesExpired',
    BirthDateValidity: 'birthDateValidity',
    ExpirationDateValidity: 'expirationDateValidity',
    IssuingStateValidity: 'issuingStateValidity',
    QrScan: 'qrScan',
    DocumentNumberCheckDigit: 'documentNumberCheckDigit',
    BirthDateCheckDigit: 'birthDateCheckDigit',
    ExpirationDateCheckDigit: 'expirationDateCheckDigit',
    CompositeCheckDigit: 'compositeCheckDigit',
    IliterationCheck: 'iliterationCheck',
    BirthDateCrosscheck: 'birthDateCrosscheck',
    ExpirationDateCrosscheck: 'expirationDateCrosscheck',
    SexCrosscheck: 'sexCrosscheck',
    FullNameCrosscheck: 'fullNameCrosscheck',
    EmissionNumberCrosscheck: 'emissionNumberCrosscheck',
    DocumentNumberCrosscheck: 'documentNumberCrosscheck',
    PersonalNumberCrosscheck: 'personalNumberCrosscheck',
    _2DBarcodeContent: '2DBarcodeContent',
    Barcode2DDetected: 'barcode2DDetected',
    DocumentTypeSideCrosscheck: 'documentTypeSideCrosscheck',
    DDReferenceNumberCrosscheck: 'dD/ReferenceNumberCrosscheck',
    UnderageCheck: 'underageCheck'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInnerKeyEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInnerKeyEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationIdSpecificInnerKeyEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationOverall
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationOverall {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationOverall
     */
    'status': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationOverallStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationOverall
     */
    'value': string;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationOverallStatusEnum = {
    Ok: 'OK',
    Warn: 'WARN',
    Fail: 'FAIL',
    Unknown: 'UNKNOWN',
    Manual: 'MANUAL',
    ManualOk: 'MANUAL_OK',
    ManualFail: 'MANUAL_FAIL',
    ManualPending: 'MANUAL_PENDING'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationOverallStatusEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationOverallStatusEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationOverallStatusEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInner
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInner {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInner
     */
    'status': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInner
     */
    'key': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInnerKeyEnum;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInnerStatusEnum = {
    Ok: 'OK',
    Warn: 'WARN',
    Fail: 'FAIL',
    Unknown: 'UNKNOWN',
    Manual: 'MANUAL'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInnerStatusEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInnerStatusEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInnerStatusEnum];
export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInnerKeyEnum = {
    TamperCheck: 'tamperCheck',
    PostitCheck: 'postitCheck',
    Alignment: 'alignment',
    FakeCheck: 'fakeCheck',
    OcrIdentityCheck: 'ocrIdentityCheck',
    ScreenIdLiveness: 'screenIdLiveness',
    PaperIdLiveness: 'paperIdLiveness',
    ReadabilityCheck: 'readabilityCheck',
    BalancedLightFront: 'balancedLightFront',
    BalancedLightBack: 'balancedLightBack',
    SharpnessFront: 'sharpnessFront',
    SharpnessBack: 'sharpnessBack'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInnerKeyEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInnerKeyEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIdValidationPhotoSecurityAndQualityInnerKeyEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIncodeWatchlistScore
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIncodeWatchlistScore {
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreLivenessPhotoQuality}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIncodeWatchlistScore
     */
    'watchlistScore': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreLivenessPhotoQuality;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIncodeWatchlistScore
     */
    'dataMatches': Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIncodeWatchlistScoreDataMatchesEnum>;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIncodeWatchlistScoreDataMatchesEnum = {
    Face: 'face',
    BirthDate: 'birthDate',
    Name: 'name',
    IdNumber: 'idNumber',
    Phone: 'phone',
    Email: 'email'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIncodeWatchlistScoreDataMatchesEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIncodeWatchlistScoreDataMatchesEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreIncodeWatchlistScoreDataMatchesEnum];

/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreLiveness
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreLiveness {
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverall}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreLiveness
     */
    'livenessScore': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverall;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreLivenessPhotoQuality}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreLiveness
     */
    'photoQuality': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreLivenessPhotoQuality;
    /**
     * 
     * @type {IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverall}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreLiveness
     */
    'overall': IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreFaceRecognitionOverall;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreLivenessPhotoQuality
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreLivenessPhotoQuality {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreLivenessPhotoQuality
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreRetryInfo
 */
export interface IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreRetryInfo {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreRetryInfo
     */
    'failedAttemptsCounter': { [key: string]: number; };
    /**
     * 
     * @type {Array<string>}
     * @memberof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreRetryInfo
     */
    'stepsToRetry': Array<IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreRetryInfoStepsToRetryEnum>;
}

export const IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreRetryInfoStepsToRetryEnum = {
    FrontId: 'frontId',
    BackId: 'backId',
    Selfie: 'selfie',
    Poa: 'poa',
    GovernmentValidation: 'governmentValidation'
} as const;

export type IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreRetryInfoStepsToRetryEnum = typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreRetryInfoStepsToRetryEnum[keyof typeof IdentityApplicantsIdVerificationIdGet200ResponseAnyOf1IdVerificationDataScoreRetryInfoStepsToRetryEnum];

/**
 * 200
 * @export
 * @interface IdentityApplicantsIdVerificationIdImagesGet200Response
 */
export interface IdentityApplicantsIdVerificationIdImagesGet200Response {
    /**
     * 
     * @type {string}
     * @memberof IdentityApplicantsIdVerificationIdImagesGet200Response
     */
    'error': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IdentityApplicantsIdVerificationIdImagesGet200Response
     */
    'failedChecks'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof IdentityApplicantsIdVerificationIdImagesGet200Response
     */
    'identity_verification_report_images': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface IdentityApplicantsIdVerificationIdImagesGet200ResponseAnyOf
 */
export interface IdentityApplicantsIdVerificationIdImagesGet200ResponseAnyOf {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof IdentityApplicantsIdVerificationIdImagesGet200ResponseAnyOf
     */
    'identity_verification_report_images': { [key: string]: string; };
}
/**
 * 200
 * @export
 * @interface IdentityGenerateApplicantLinkPost200Response
 */
export interface IdentityGenerateApplicantLinkPost200Response {
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200Response
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200Response
     */
    'verificationLink': string;
    /**
     * 
     * @type {IdentityGenerateApplicantLinkPost200ResponseProperty}
     * @memberof IdentityGenerateApplicantLinkPost200Response
     */
    'property': IdentityGenerateApplicantLinkPost200ResponseProperty;
}
/**
 * 
 * @export
 * @interface IdentityGenerateApplicantLinkPost200ResponseProperty
 */
export interface IdentityGenerateApplicantLinkPost200ResponseProperty {
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'shortId': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'entityName': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'logo': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'phone': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'phoneIsRequired': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'website': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'unit': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'unitIsRequired': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'state': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'status': IdentityGenerateApplicantLinkPost200ResponsePropertyStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'bankStatement': number | null;
    /**
     * 
     * @type {number}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'paystub': number | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'companyId': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'companyShortId': string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'applicantLink': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'leasingTeamLink': string;
    /**
     * An ISO-formattted datetime
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'insertedAt': string;
    /**
     * An ISO-formattted datetime
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPost200ResponseProperty
     */
    'updatedAt': string;
}

export const IdentityGenerateApplicantLinkPost200ResponsePropertyStatusEnum = {
    Active: 'ACTIVE',
    Disabled: 'DISABLED'
} as const;

export type IdentityGenerateApplicantLinkPost200ResponsePropertyStatusEnum = typeof IdentityGenerateApplicantLinkPost200ResponsePropertyStatusEnum[keyof typeof IdentityGenerateApplicantLinkPost200ResponsePropertyStatusEnum];

/**
 * 
 * @export
 * @interface IdentityGenerateApplicantLinkPostRequest
 */
export interface IdentityGenerateApplicantLinkPostRequest {
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPostRequest
     */
    'companyShortId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPostRequest
     */
    'propertyShortId'?: string;
    /**
     * Optional, will be auto-filled from the applicant\'s provided ID if left blank.
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPostRequest
     */
    'firstName'?: string;
    /**
     * Optional, will be auto-filled from the applicant\'s provided ID if left blank.
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPostRequest
     */
    'lastName'?: string;
    /**
     * Optional
     * @type {string}
     * @memberof IdentityGenerateApplicantLinkPostRequest
     */
    'email'?: string;
}

/**
 * ApplicantsApi - axios parameter creator
 * @export
 */
export const ApplicantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve an applicant\'s report for an entry
         * @param {string} applicantId 
         * @param {boolean} [includeDocuments] 
         * @param {boolean} [showThumbnails] 
         * @param {ApplicantsApplicantIdReportGetPresetEnum} [preset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicantsApplicantIdReportGet: async (applicantId: string, includeDocuments?: boolean, showThumbnails?: boolean, preset?: ApplicantsApplicantIdReportGetPresetEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicantId' is not null or undefined
            assertParamExists('applicantsApplicantIdReportGet', 'applicantId', applicantId)
            const localVarPath = `/applicants/{applicantId}/report`
                .replace(`{${"applicantId"}}`, encodeURIComponent(String(applicantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeDocuments !== undefined) {
                localVarQueryParameter['includeDocuments'] = includeDocuments;
            }

            if (showThumbnails !== undefined) {
                localVarQueryParameter['showThumbnails'] = showThumbnails;
            }

            if (preset !== undefined) {
                localVarQueryParameter['preset'] = preset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicantsApi - functional programming interface
 * @export
 */
export const ApplicantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve an applicant\'s report for an entry
         * @param {string} applicantId 
         * @param {boolean} [includeDocuments] 
         * @param {boolean} [showThumbnails] 
         * @param {ApplicantsApplicantIdReportGetPresetEnum} [preset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicantsApplicantIdReportGet(applicantId: string, includeDocuments?: boolean, showThumbnails?: boolean, preset?: ApplicantsApplicantIdReportGetPresetEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicantsApplicantIdReportGet(applicantId, includeDocuments, showThumbnails, preset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicantsApi - factory interface
 * @export
 */
export const ApplicantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicantsApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve an applicant\'s report for an entry
         * @param {string} applicantId 
         * @param {boolean} [includeDocuments] 
         * @param {boolean} [showThumbnails] 
         * @param {ApplicantsApplicantIdReportGetPresetEnum} [preset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicantsApplicantIdReportGet(applicantId: string, includeDocuments?: boolean, showThumbnails?: boolean, preset?: ApplicantsApplicantIdReportGetPresetEnum, options?: any): AxiosPromise<any> {
            return localVarFp.applicantsApplicantIdReportGet(applicantId, includeDocuments, showThumbnails, preset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicantsApi - object-oriented interface
 * @export
 * @class ApplicantsApi
 * @extends {BaseAPI}
 */
export class ApplicantsApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve an applicant\'s report for an entry
     * @param {string} applicantId 
     * @param {boolean} [includeDocuments] 
     * @param {boolean} [showThumbnails] 
     * @param {ApplicantsApplicantIdReportGetPresetEnum} [preset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicantsApi
     */
    public applicantsApplicantIdReportGet(applicantId: string, includeDocuments?: boolean, showThumbnails?: boolean, preset?: ApplicantsApplicantIdReportGetPresetEnum, options?: AxiosRequestConfig) {
        return ApplicantsApiFp(this.configuration).applicantsApplicantIdReportGet(applicantId, includeDocuments, showThumbnails, preset, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApplicantsApplicantIdReportGetPresetEnum = {
    Fcra: 'fcra',
    SummaryAndDocuments: 'summary-and-documents',
    Summary: 'summary'
} as const;
export type ApplicantsApplicantIdReportGetPresetEnum = typeof ApplicantsApplicantIdReportGetPresetEnum[keyof typeof ApplicantsApplicantIdReportGetPresetEnum];


/**
 * IDVerificationApi - axios parameter creator
 * @export
 */
export const IDVerificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve all ID verification records
         * @param {number} [rowsPerPage] 
         * @param {number} [page] 
         * @param {string} [sort] 
         * @param {string} [filter] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityApplicantsGet: async (rowsPerPage?: number, page?: number, sort?: string, filter?: string, q?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/identity/applicants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rowsPerPage !== undefined) {
                localVarQueryParameter['rowsPerPage'] = rowsPerPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve an ID verification record
         * @param {string} idVerificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityApplicantsIdVerificationIdGet: async (idVerificationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idVerificationId' is not null or undefined
            assertParamExists('identityApplicantsIdVerificationIdGet', 'idVerificationId', idVerificationId)
            const localVarPath = `/identity/applicants/{idVerificationId}`
                .replace(`{${"idVerificationId"}}`, encodeURIComponent(String(idVerificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch available image urls for Identity Verification applicant
         * @param {string} idVerificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityApplicantsIdVerificationIdImagesGet: async (idVerificationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idVerificationId' is not null or undefined
            assertParamExists('identityApplicantsIdVerificationIdImagesGet', 'idVerificationId', idVerificationId)
            const localVarPath = `/identity/applicants/{idVerificationId}/images`
                .replace(`{${"idVerificationId"}}`, encodeURIComponent(String(idVerificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a PDF report of the results of an ID verification session
         * @param {string} idVerificationId 
         * @param {IdentityApplicantsIdVerificationIdReportGetPresetEnum} [preset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityApplicantsIdVerificationIdReportGet: async (idVerificationId: string, preset?: IdentityApplicantsIdVerificationIdReportGetPresetEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idVerificationId' is not null or undefined
            assertParamExists('identityApplicantsIdVerificationIdReportGet', 'idVerificationId', idVerificationId)
            const localVarPath = `/identity/applicants/{idVerificationId}/report`
                .replace(`{${"idVerificationId"}}`, encodeURIComponent(String(idVerificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (preset !== undefined) {
                localVarQueryParameter['preset'] = preset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Make a request to this API to begin a new ID verification session.
         * @summary Create an ID Verification Link
         * @param {IdentityGenerateApplicantLinkPostRequest} identityGenerateApplicantLinkPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityGenerateApplicantLinkPost: async (identityGenerateApplicantLinkPostRequest: IdentityGenerateApplicantLinkPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identityGenerateApplicantLinkPostRequest' is not null or undefined
            assertParamExists('identityGenerateApplicantLinkPost', 'identityGenerateApplicantLinkPostRequest', identityGenerateApplicantLinkPostRequest)
            const localVarPath = `/identity/generateApplicantLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityGenerateApplicantLinkPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IDVerificationApi - functional programming interface
 * @export
 */
export const IDVerificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IDVerificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve all ID verification records
         * @param {number} [rowsPerPage] 
         * @param {number} [page] 
         * @param {string} [sort] 
         * @param {string} [filter] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityApplicantsGet(rowsPerPage?: number, page?: number, sort?: string, filter?: string, q?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdentityApplicantsGet200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityApplicantsGet(rowsPerPage, page, sort, filter, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve an ID verification record
         * @param {string} idVerificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityApplicantsIdVerificationIdGet(idVerificationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityApplicantsIdVerificationIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityApplicantsIdVerificationIdGet(idVerificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch available image urls for Identity Verification applicant
         * @param {string} idVerificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityApplicantsIdVerificationIdImagesGet(idVerificationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityApplicantsIdVerificationIdImagesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityApplicantsIdVerificationIdImagesGet(idVerificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a PDF report of the results of an ID verification session
         * @param {string} idVerificationId 
         * @param {IdentityApplicantsIdVerificationIdReportGetPresetEnum} [preset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityApplicantsIdVerificationIdReportGet(idVerificationId: string, preset?: IdentityApplicantsIdVerificationIdReportGetPresetEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityApplicantsIdVerificationIdReportGet(idVerificationId, preset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Make a request to this API to begin a new ID verification session.
         * @summary Create an ID Verification Link
         * @param {IdentityGenerateApplicantLinkPostRequest} identityGenerateApplicantLinkPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityGenerateApplicantLinkPost(identityGenerateApplicantLinkPostRequest: IdentityGenerateApplicantLinkPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityGenerateApplicantLinkPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityGenerateApplicantLinkPost(identityGenerateApplicantLinkPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IDVerificationApi - factory interface
 * @export
 */
export const IDVerificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IDVerificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve all ID verification records
         * @param {number} [rowsPerPage] 
         * @param {number} [page] 
         * @param {string} [sort] 
         * @param {string} [filter] 
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityApplicantsGet(rowsPerPage?: number, page?: number, sort?: string, filter?: string, q?: string, options?: any): AxiosPromise<Array<IdentityApplicantsGet200ResponseInner>> {
            return localVarFp.identityApplicantsGet(rowsPerPage, page, sort, filter, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve an ID verification record
         * @param {string} idVerificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityApplicantsIdVerificationIdGet(idVerificationId: string, options?: any): AxiosPromise<IdentityApplicantsIdVerificationIdGet200Response> {
            return localVarFp.identityApplicantsIdVerificationIdGet(idVerificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch available image urls for Identity Verification applicant
         * @param {string} idVerificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityApplicantsIdVerificationIdImagesGet(idVerificationId: string, options?: any): AxiosPromise<IdentityApplicantsIdVerificationIdImagesGet200Response> {
            return localVarFp.identityApplicantsIdVerificationIdImagesGet(idVerificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a PDF report of the results of an ID verification session
         * @param {string} idVerificationId 
         * @param {IdentityApplicantsIdVerificationIdReportGetPresetEnum} [preset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityApplicantsIdVerificationIdReportGet(idVerificationId: string, preset?: IdentityApplicantsIdVerificationIdReportGetPresetEnum, options?: any): AxiosPromise<any> {
            return localVarFp.identityApplicantsIdVerificationIdReportGet(idVerificationId, preset, options).then((request) => request(axios, basePath));
        },
        /**
         * Make a request to this API to begin a new ID verification session.
         * @summary Create an ID Verification Link
         * @param {IdentityGenerateApplicantLinkPostRequest} identityGenerateApplicantLinkPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityGenerateApplicantLinkPost(identityGenerateApplicantLinkPostRequest: IdentityGenerateApplicantLinkPostRequest, options?: any): AxiosPromise<IdentityGenerateApplicantLinkPost200Response> {
            return localVarFp.identityGenerateApplicantLinkPost(identityGenerateApplicantLinkPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IDVerificationApi - object-oriented interface
 * @export
 * @class IDVerificationApi
 * @extends {BaseAPI}
 */
export class IDVerificationApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve all ID verification records
     * @param {number} [rowsPerPage] 
     * @param {number} [page] 
     * @param {string} [sort] 
     * @param {string} [filter] 
     * @param {string} [q] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IDVerificationApi
     */
    public identityApplicantsGet(rowsPerPage?: number, page?: number, sort?: string, filter?: string, q?: string, options?: AxiosRequestConfig) {
        return IDVerificationApiFp(this.configuration).identityApplicantsGet(rowsPerPage, page, sort, filter, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve an ID verification record
     * @param {string} idVerificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IDVerificationApi
     */
    public identityApplicantsIdVerificationIdGet(idVerificationId: string, options?: AxiosRequestConfig) {
        return IDVerificationApiFp(this.configuration).identityApplicantsIdVerificationIdGet(idVerificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch available image urls for Identity Verification applicant
     * @param {string} idVerificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IDVerificationApi
     */
    public identityApplicantsIdVerificationIdImagesGet(idVerificationId: string, options?: AxiosRequestConfig) {
        return IDVerificationApiFp(this.configuration).identityApplicantsIdVerificationIdImagesGet(idVerificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a PDF report of the results of an ID verification session
     * @param {string} idVerificationId 
     * @param {IdentityApplicantsIdVerificationIdReportGetPresetEnum} [preset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IDVerificationApi
     */
    public identityApplicantsIdVerificationIdReportGet(idVerificationId: string, preset?: IdentityApplicantsIdVerificationIdReportGetPresetEnum, options?: AxiosRequestConfig) {
        return IDVerificationApiFp(this.configuration).identityApplicantsIdVerificationIdReportGet(idVerificationId, preset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Make a request to this API to begin a new ID verification session.
     * @summary Create an ID Verification Link
     * @param {IdentityGenerateApplicantLinkPostRequest} identityGenerateApplicantLinkPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IDVerificationApi
     */
    public identityGenerateApplicantLinkPost(identityGenerateApplicantLinkPostRequest: IdentityGenerateApplicantLinkPostRequest, options?: AxiosRequestConfig) {
        return IDVerificationApiFp(this.configuration).identityGenerateApplicantLinkPost(identityGenerateApplicantLinkPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const IdentityApplicantsIdVerificationIdReportGetPresetEnum = {
    Fcra: 'fcra',
    SummaryAndDocuments: 'summary-and-documents'
} as const;
export type IdentityApplicantsIdVerificationIdReportGetPresetEnum = typeof IdentityApplicantsIdVerificationIdReportGetPresetEnum[keyof typeof IdentityApplicantsIdVerificationIdReportGetPresetEnum];


