/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import Button, { ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import Table from 'src/components/atoms/Table';
import TableBody from 'src/components/atoms/TableBody';
import TableCell from 'src/components/atoms/TableCell';
import TableContainer from 'src/components/atoms/TableContainer';
import TableHead from 'src/components/atoms/TableHead';
import { TextVariant } from 'src/components/atoms/Text';
import ToolTip, { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import ButtonIcon from 'src/components/molecules/ButtonIcon';
import { ShowState } from 'src/components/molecules/ShowState';
import RemoveUserModal, { ModalVariant, ModalWidth } from 'src/components/organisms/Modal';
import { StyledTableRow, StyledText } from './styles';

// Redux
import { selectors as authSelectors } from 'src/features/auth/authSlice';
import { selectors as invitationSelectors, useInvitations } from 'src/features/invitation';
import { selectors as propertySelectors } from 'src/features/property/propertySlice';
import { selectors as userSelectors } from 'src/features/users/usersSlice';

// Helpers
import { displayRole } from 'src/helpers';

// Hooks
import { UserTabLoader } from 'src/components/molecules/UserTabLoader';
import useProperty from 'src/features/property/hooks/useProperty';
import useUsers from 'src/features/users/hooks/useUsers';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import AssignUsersModal from 'src/pages/PropertyUsersPage/components/AssignUsersModal';

// Constants
import { propertyAgentsColumns } from 'src/pages/PropertyUsersPage/constants';

// Types
import { Agent, AgentWithChecked } from 'src/ts/interfaces';

export type PropertyUsersPageProps = {
  id?: string;
  shortId?: string;
  companyId?: string;
  isDisabled: boolean;
};

const PropertyUsersPage: React.FC<PropertyUsersPageProps> = (props: PropertyUsersPageProps) => {
  const { id, shortId, companyId, isDisabled } = props;
  const currentUser = useSelector(authSelectors.user);

  const { showSnackbar, VariantType } = useSnackbarProvider();

  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [showAssignUsersModal, setShowAssignUsersModal] = useState(false);

  const [userSelected, setUserSelected] = useState<Agent>();
  const [agentsList, setAgentsList] = useState<Agent[]>([]);

  const {
    onGetPropertyAgents,
    onGetUnassignedUsers,
    onGetSortedAgentsArray,
    onRemoveUser,
    onResetRemoveUser,
    onResetCreateAgent
  } = useProperty();

  const { onGetAll: getUsers, onResetGetAll: onResetGetUsers } = useUsers();
  const usersData = useSelector(userSelectors.getAll.data);
  const usersIsLoading = useSelector(userSelectors.getAll.isLoading);

  const { onGetAll: getInvitations, onResetGetAll: onResetGetInvitations } = useInvitations();
  const invitationsData = useSelector(invitationSelectors.getAll.data);
  const invitationsIsLoading = useSelector(invitationSelectors.getAll.isLoading);

  const agents = useSelector(propertySelectors.getOne.agents.getAll.data);
  const agentsIsLoading = useSelector(propertySelectors.getOne.agents.getAll.isLoading);
  const agentsError = useSelector(propertySelectors.getOne.agents.getAll.error);

  const removeAgentIsLoading = useSelector(propertySelectors.getOne.agents.remove.isLoading);
  const removeAgentIsRemoved = useSelector(propertySelectors.getOne.agents.remove.isRemoved);
  const removeAgentError = useSelector(propertySelectors.getOne.agents.remove.error);

  const createAgentIsLoading = useSelector(propertySelectors.getOne.agents.create.isLoading);
  const createAgentIsSuccessful = useSelector(propertySelectors.getOne.agents.create.isCreated);
  const createAgentError = useSelector(propertySelectors.getOne.agents.create.error);

  useEffect(() => {
    return () => {
      onResetGetInvitations();
      onResetGetUsers();
    };
  }, [onResetGetUsers]);

  const handleAssignUsersModal = () => {
    setShowAssignUsersModal(!showAssignUsersModal);
  };

  const handleRemoveUserRelation = (user: Agent) => {
    if (!user.id) return null;
    setUserSelected(user);
    setShowRemoveUserModal(true);
  };

  const closeRemoveUserModal = () => {
    setUserSelected(undefined);
    setShowRemoveUserModal(false);
  };

  useEffect(() => {
    if (removeAgentIsRemoved && !removeAgentIsLoading) {
      callUsers();
      closeRemoveUserModal();
      showSnackbar(VariantType.success, 'The user has been removed successfully.');
      onResetRemoveUser();
    }
  }, [onRemoveUser, removeAgentIsRemoved, removeAgentIsLoading, showSnackbar, VariantType]);

  useEffect(() => {
    if (removeAgentError) {
      closeRemoveUserModal();
      showSnackbar(VariantType.error, "The user couldn't be removed. Please try again later.");
      onResetRemoveUser();
    }
  }, [onRemoveUser, removeAgentError, showSnackbar, VariantType]);

  useEffect(() => {
    if (createAgentIsSuccessful) {
      callUsers();
      setShowAssignUsersModal(false);
      showSnackbar(VariantType.success, 'The users have been added successfully');
      onResetCreateAgent();
    }
  }, [VariantType, showSnackbar, createAgentIsSuccessful]);

  useEffect(() => {
    if (createAgentError) {
      setShowAssignUsersModal(false);
      showSnackbar(VariantType.error, 'There was an error adding the users');
      onResetCreateAgent();
    }
  }, [VariantType, showSnackbar, createAgentError]);

  const callUsers = () => {
    onGetPropertyAgents(id);
    getUsers(0, 500, `company_id=${companyId},role!=COMPANY_ADMIN`);
    getInvitations(0, 500, `company_id=${companyId},role!=COMPANY_ADMIN`);
  };

  useEffect(() => {
    if (companyId) {
      callUsers();
    }
  }, [companyId]);

  useEffect(() => {
    if (agents && !agentsIsLoading) {
      onGetSortedAgentsArray(agents, setAgentsList);
    }
  }, [agents, agentsIsLoading]);

  const unassignedUsers = onGetUnassignedUsers(agents, usersData, invitationsData);

  if (agentsIsLoading || invitationsIsLoading || usersIsLoading) {
    return <UserTabLoader />;
  }

  if (agentsError) {
    return (
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Please try again"
        message="Something went wrong fetching the agents"
        onClick={() => window.location.reload()}
      />
    );
  }

  return (
    <>
      <Gap height={1.5} />
      {isDisabled ? (
        <ToolTip
          direction={ToolTipDirection.right_center}
          content={'Please, activate the property to assign users'}
        >
          <Button
            name="assign-users"
            variant={ButtonVariant.contained}
            onClick={handleAssignUsersModal}
            isDisabled={unassignedUsers?.length === 0 || isDisabled}
          >
            <StyledText color="white" variant={TextVariant.normal}>
              Assign Users
            </StyledText>
            <Icon icon="add" />
          </Button>
        </ToolTip>
      ) : (
        <Button
          name="assign-users"
          variant={ButtonVariant.contained}
          onClick={handleAssignUsersModal}
          isDisabled={unassignedUsers?.length === 0}
        >
          <StyledText color="white" variant={TextVariant.normal}>
            Assign Users
          </StyledText>
          <Icon icon="add" />
        </Button>
      )}
      <Gap height={1.5} />

      {agentsList?.length === 0 ? (
        <>
          <Gap height={3.8125} />
          <ShowState variant="empty" type="user" message="There are no users assigned yet" />
        </>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <StyledTableRow>
                {propertyAgentsColumns.map(({ label }, i) => (
                  <TableCell key={i}>{label}</TableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {agentsList?.map((agent) => {
                return (
                  <StyledTableRow key={agent.id}>
                    <TableCell>
                      {agent.first_name} {agent.last_name}
                    </TableCell>
                    <TableCell>{agent.email}</TableCell>
                    <TableCell>{displayRole(agent?.role)}</TableCell>
                    <TableCell>
                      <ButtonIcon
                        icon={'delete'}
                        name="delete-agent"
                        iconColor={'gray'}
                        onClick={() => handleRemoveUserRelation(agent)}
                        background="none"
                        disabled={agent?.id === currentUser?.id}
                      />
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <AssignUsersModal
        showModal={showAssignUsersModal}
        setShowModal={setShowAssignUsersModal}
        unassignedAgentsList={unassignedUsers as AgentWithChecked[]}
        propertyId={id}
        shortId={shortId}
        isAgentsLoading={createAgentIsLoading}
      />
      <RemoveUserModal
        showModal={showRemoveUserModal}
        setShowModal={setShowRemoveUserModal}
        title="Remove User"
        labelButtonCancel="Cancel"
        labelButtonConfirm="Remove"
        isLoadingButtonConfirm={removeAgentIsLoading}
        isDisabledButtonConfirm={removeAgentIsLoading}
        isDisabledButtonCancel={removeAgentIsLoading}
        variant={ModalVariant.danger}
        width={ModalWidth.small}
        isBackClosable={false}
        isEscapeClosable={false}
        onConfirm={() => onRemoveUser(id, userSelected)}
        onCancel={closeRemoveUserModal}
        text={`Are you sure you want to remove "${userSelected?.email}” from this property?
        
        They will lose access to the folders and DUP links.`}
      />
    </>
  );
};

export default PropertyUsersPage;
