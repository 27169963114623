import { FC } from 'react';
import SkeletonPiece, { SkeletonPieceColor } from 'src/components/atoms/SkeletonPiece';
import { Row } from 'src/components/atoms/Row';

import { StyledCard } from './styles';

const FolderTileLoader: FC = () => {
  return (
    <StyledCard border rounded>
      <Row direction="column" gap={2.6875}>
        <Row direction="column" gap={1}>
          <Row justify="space-between">
            <SkeletonPiece height={20} width={113} color={SkeletonPieceColor.gray200} />
            <SkeletonPiece height={20} width={60} color={SkeletonPieceColor.gray200} />
          </Row>
          <SkeletonPiece height={16} width={200} color={SkeletonPieceColor.gray100} />
        </Row>

        <Row direction="column" gap={1}>
          <Row justify="space-between">
            <SkeletonPiece height={16} width={135} color={SkeletonPieceColor.gray100} />
            <SkeletonPiece height={16} width={135} color={SkeletonPieceColor.gray100} />
          </Row>
          <Row justify="space-between">
            <SkeletonPiece height={29} width={80} color={SkeletonPieceColor.gray200} />
            <SkeletonPiece height={29} width={80} color={SkeletonPieceColor.gray200} />
          </Row>
        </Row>
      </Row>
    </StyledCard>
  );
};

export default FolderTileLoader;
