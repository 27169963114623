/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { Loader } from 'src/components/atoms/Loader';
import { ShowState } from 'src/components/molecules/ShowState';
import { DetailView } from 'src/components/templates/DetailView';
import UserDetailForm from './components/UserDetailForm';

// Hooks
import useUsers from 'src/features/users/hooks/useUsers';

// Redux
import { selectors } from 'src/features/users/usersSlice';

// Provider
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';

// Enums
import { RoleEnum } from 'src/ts/enums/roles';

// Types
import { IUser, IValueLabel } from 'src/ts/interfaces';

// TODO: Move this into a state or reference variable to prevent it from being initialized on build.
let roles: IValueLabel[] = [{ value: '', label: '' }];
const UserDetailPage: React.FC = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();

  const { onGetUser, onSave, onResetUpdate, ListRoles, onResetGetUser } = useUsers();
  const getUserData = useSelector(selectors.getUser.data);
  const getUserIsLoading = useSelector(selectors.getUser.isLoading);
  const getUserError = useSelector(selectors.getUser.error);

  const updateUserIsSaved = useSelector(selectors.update.isSaved);
  const updateUserIsLoading = useSelector(selectors.update.isLoading);
  const updateUserError = useSelector(selectors.update.error);

  useEffect(() => {
    onGetUser(userId);
    roles = ListRoles();

    return () => {
      onResetGetUser();
    };
  }, [userId]);

  useEffect(() => {
    if (updateUserIsSaved) {
      onResetUpdate();
      navigate('/users');
      showSnackbar(
        VariantType.success,
        "The user's information was updated successfully",
        SnackTypes.none
      );
    }
  }, [updateUserIsSaved]);

  if (getUserError) {
    return (
      <ShowState
        type="information"
        variant="error"
        message={getUserError}
        buttonLabel="Reload"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (updateUserError) {
    return (
      <ShowState
        type="information"
        variant="error"
        message={updateUserError}
        buttonLabel="Reload"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (getUserIsLoading) {
    return <Loader />;
  }

  const defaultValues: IUser = {
    id: getUserData?.id || '',
    first_name: getUserData?.first_name || '',
    last_name: getUserData?.last_name || '',
    email: getUserData?.email || '',
    auth_id: getUserData?.auth_id || '',
    company_id: getUserData?.company_id || '',
    properties: getUserData?.properties || [],
    role: getUserData?.role || RoleEnum.Admin,
    company: getUserData?.company,
    team_id: getUserData?.team_id
  };

  return (
    <DetailView back={{ to: '/users', label: 'Go Back' }} title="Edit Information">
      <UserDetailForm
        defaultValues={defaultValues}
        onSave={onSave}
        isSaving={updateUserIsLoading}
        roles={roles}
      />
    </DetailView>
  );
};

export default UserDetailPage;
