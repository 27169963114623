/* eslint-disable react-hooks/exhaustive-deps */
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from 'src/components/atoms/Button';
import ButtonLink, {
  ButtonLinkColor,
  ButtonLinkSize,
  ButtonLinkVariant
} from 'src/components/atoms/ButtonLink';
import { Fieldset } from 'src/components/atoms/Fieldset';
import Gap from 'src/components/atoms/Gap';
import InputText, { InputTextType } from 'src/components/atoms/InputText/InputText';
import { Row } from 'src/components/atoms/Row';
import Tag, { TagColor } from 'src/components/atoms/Tag';
import Text from 'src/components/atoms/Text';
import Dropdown from 'src/components/molecules/Dropdown/Dropdown';
import { selectors } from 'src/features/invitation';
import { IUser, IValueLabel } from 'src/ts/interfaces';

type ConfirmFormProps = {
  defaultValues: Partial<IUser & { team_name: string }>;
  roles: IValueLabel[] | [];
  onSave: (data: Partial<IUser>) => void;
};

const ConfirmForm: React.FC<ConfirmFormProps> = ({
  defaultValues,
  roles,
  onSave
}: ConfirmFormProps) => {
  const createInvitationIsCreated = useSelector(selectors.create.isCreated);
  const createInvitationIsLoading = useSelector(selectors.create.isLoading);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSave)}>
        <Text>To edit this information you can click any of previous steps</Text>
        <Gap height={2} />
        <Controller
          name="role"
          control={methods.control}
          rules={{ required: 'Complete this field' }}
          defaultValue={defaultValues.role}
          render={({ field: { name, value } }) => (
            <Dropdown
              valueInitial={value}
              data={roles}
              name={name}
              placeholder="Role"
              size="md"
              isDisabled
            />
          )}
        />
        <Gap height={1.5} />

        {defaultValues.team_id && (
          <InputText
            placeholder="Team"
            name="team_name"
            type={InputTextType.text}
            defaultValue={defaultValues.team_name}
            isReadonly
          />
        )}

        {defaultValues.company_id && (
          <>
            <Controller
              name="company_id"
              control={methods.control}
              defaultValue={defaultValues.company_id}
              rules={{ required: 'Complete this field' }}
              render={({ field: { name, value } }) => (
                <Dropdown
                  valueInitial={value}
                  labelInitial={defaultValues.company?.name}
                  data={roles}
                  name={name}
                  placeholder="Company"
                  size="md"
                  isDisabled
                />
              )}
            />
            <Gap height={1} />
          </>
        )}

        {!defaultValues.properties?.length ? (
          <Gap height={0.7} />
        ) : (
          <Fieldset legend="Properties" name="Properties" height={'auto'}>
            <Row gap={0.5} wrap="wrap">
              {defaultValues.properties?.map((property) => {
                if (!property?.name) return null;
                return (
                  <Tag
                    key={property.id}
                    color={TagColor.gray}
                    label={property.name}
                    showBackground
                  />
                );
              })}
            </Row>
          </Fieldset>
        )}

        <InputText
          placeholder="First Name"
          name="first_name"
          type={InputTextType.text}
          defaultValue={defaultValues.first_name}
          isReadonly
        />

        <InputText
          placeholder="Last Name"
          name="last_name"
          type={InputTextType.text}
          defaultValue={defaultValues.last_name}
          isReadonly
        />

        <InputText
          placeholder="Email"
          name="email"
          type={InputTextType.email}
          defaultValue={defaultValues.email}
          isReadonly
        />

        <Row gap={1}>
          <Button
            name="continue_button"
            variant={ButtonVariant.contained}
            color={ButtonColor.primary}
            size={ButtonSize.medium}
            type={ButtonType.submit}
            onClick={() => onSave}
            isLoading={createInvitationIsLoading}
            isDisabled={createInvitationIsCreated}
          >
            {createInvitationIsLoading ? 'Sending' : 'Send Invite'}
          </Button>
          <ButtonLink
            name="cancel_button"
            variant={ButtonLinkVariant.outline}
            color={ButtonLinkColor.primary}
            size={ButtonLinkSize.medium}
            to={'../users'}
          >
            Cancel
          </ButtonLink>
        </Row>
      </form>
    </FormProvider>
  );
};

export default ConfirmForm;
