import PropTypes from 'prop-types';
import { FC, HTMLAttributes } from 'react';
import { Row } from 'src/components/atoms/Row';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { Container } from './styles';

export type ListViewProps = {
  children: React.ReactNode;
  title: string;
  filters?: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const ListView: FC<ListViewProps> = (props) => {
  const { title, filters, children, ...htmlAttrs } = props;

  return (
    <Container {...htmlAttrs}>
      <Row justify="space-between" className="heading">
        <Row.Col size={'20%'} alignSelf="center">
          <Text variant={TextVariant.h3}>{title}</Text>
        </Row.Col>
        {filters && <Row.Col size="auto">{filters}</Row.Col>}
      </Row>
      <Row>
        <Row.Col size={1} className="listContainer">
          {children}
        </Row.Col>
      </Row>
    </Container>
  );
};

ListView.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  filters: PropTypes.node
};

export default ListView;
