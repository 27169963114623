import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { defaultInstance as axios } from 'src/api/instance';

export const getUrlContent = async (url: string): Promise<Blob> => {
  const responseBlob = await axios.get<Blob>(url, {
    responseType: 'blob'
  });

  return responseBlob as unknown as Blob;
};

const getExtensionAsLowerCase = (str: string) => {
  const splitted = str.split('.');

  return (splitted.pop() as string).toLowerCase();
};

export const downloadUrlsToZip = async (urls: string[], fileName: string) => {
  const zip = new JSZip();

  // name every file with filename, with index attach in final
  for (const indexUrl in urls) {
    const url = urls[indexUrl];
    // file extension is fixed as .pdf because the presigned url does not provide the file type.
    const name = `${fileName}-${+indexUrl + 1}.pdf`;

    zip.file(name, getUrlContent(url), { binary: true });
  }

  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, fileName);
};

export const downloadUrlToZip = async (url: string, fileName: string) => {
  const zip = new JSZip();

  const extension = getExtensionAsLowerCase(url);
  const name = `${fileName}.${extension}`;
  zip.file(name, getUrlContent(url), { binary: true });

  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, fileName);
};

export const downloadDocumentPDF = (blob: string, fileName: string) => {
  const newBlob = new Blob([blob], { type: 'application/pdf;charset=utf-8' });
  const blobUrl = window.URL.createObjectURL(newBlob);

  const a = document.createElement('a');
  a.download = fileName;
  a.href = blobUrl;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const downloadUrlToPdf = async (url: string, fileName: string) => {
  const content = await getUrlContent(url);
  saveAs(content, fileName);
};
