import styled from 'styled-components';

export const ChoicesContainer = styled.div`
  padding: 0.5rem;
  border-bottom: 0.063rem solid ${({ theme }) => theme.color.gray[200]};

  display: flex;
  gap: 1rem;
  align-items: center;

  width: 100%;
  button {
    width: auto;
  }
`;
