// Vendor
import { useEffect } from 'react';

// Components
import FolderRowLoader from 'src/components/molecules/FolderRowLoader';
import { ShowState } from 'src/components/molecules/ShowState';
import FolderTable from 'src/components/organisms/FolderTable';

// Hooks
import useUserRoles from 'src/features/auth/hooks/useUserRoles';
import { useFdeInfo, useGetFdeInbox } from 'src/features/fdeInbox';

const FolderList: React.FC = () => {
  const {
    role,
    isAdminOrReviewerOrFraudAnalyst,
    isFDEManager,
    isSeniorFraudAnalyst,
    isAccountRepresentative
  } = useUserRoles();
  const { onGetFolders } = useGetFdeInbox();
  const { data, filters, isLoading, isError } = useFdeInfo();

  useEffect(() => {
    onGetFolders(filters);
  }, [onGetFolders, filters]);

  if (isError) {
    return (
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Please try again"
        message="Something went wrong"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (isLoading) {
    return <FolderRowLoader />;
  }

  if (data?.length === 0) {
    if (filters.q) {
      return (
        <ShowState
          message="Try adjusting your search or filter to find<br/> what you are looking for"
          searchText={filters.q}
          variant="search"
          type="searchEmpty"
        />
      );
    }
    return (
      <ShowState
        message="There are no applications <br /> available to show"
        type="application"
        variant="empty"
      />
    );
  }

  if (!role) return null;

  return (
    <>
      {data?.map((folder) => {
        return (
          <FolderTable
            key={folder.folder_id}
            folder={folder}
            isLoading={isLoading}
            role={role}
            showReviewButton={
              isAdminOrReviewerOrFraudAnalyst ||
              isFDEManager ||
              isSeniorFraudAnalyst ||
              isAccountRepresentative
            }
          />
        );
      })}
    </>
  );
};

export default FolderList;
