// Vendor
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import {
  generateApplicantLinkThunk,
  actions as identityActions
} from 'src/features/identityVerification';
import { selectors as identitySelectors } from 'src/features/identityVerification/identityVerificationSlice';
import { useCopyToClipboard } from 'src/hooks';

// Hooks
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';

// Enums
import { LinkAction, Status as StatusTypes } from 'src/ts/enums';

export const useGenerateApplicantLink = () => {
  const dispatch = useDispatch();

  const { SnackTypes, showSnackbar, VariantType } = useSnackbarProvider();
  const { copy } = useCopyToClipboard();

  const [selectedPropertyShortId, setSelectedPropertyShortId] = useState<string | undefined>(
    undefined
  );

  const generateApplicantLinkStatus = useSelector(identitySelectors.generateApplicantLink.status);
  const generateApplicantLinkAction = useSelector(
    identitySelectors.generateApplicantLink.linkAction
  );
  const data = useSelector(identitySelectors.generateApplicantLink.data);

  const onGenerateApplicantLink = (
    propertyShortId: string,
    companyShortId: string,
    linkAction: LinkAction
  ) => {
    setSelectedPropertyShortId(propertyShortId);

    if (!navigator?.clipboard && linkAction === LinkAction.COPY) {
      console.warn('Clipboard is not supported in this browser');
      showSnackbar(
        VariantType.error,
        'Copying tool is not supported in this browser',
        SnackTypes.none
      );
      return false;
    }

    try {
      dispatch(
        generateApplicantLinkThunk({
          data: { propertyShortId, companyShortId },
          linkAction
        })
      );
    } catch (error) {
      showSnackbar(VariantType.error, 'Failed to generate applicant ID link');
    }
  };

  useEffect(() => {
    if (!selectedPropertyShortId) {
      return;
    }

    if (generateApplicantLinkStatus === StatusTypes.SUCCESS) {
      if (data?.verificationLink) {
        if (generateApplicantLinkAction === LinkAction.COPY) {
          copy(
            data.verificationLink,
            'Applicant link generated and copied to the clipboard',
            'Applicant link could not be copied to the clipboard',
            VariantType.success
          );
        } else if (generateApplicantLinkAction === LinkAction.NEW_TAB) {
          window.open(data.verificationLink, '_blank');
        } else {
          showSnackbar(
            VariantType.error,
            'Something went wrong trying to generate an applicant link'
          );
        }
      } else {
        showSnackbar(
          VariantType.error,
          'Something went wrong trying to generate an applicant link'
        );
      }
      setSelectedPropertyShortId(undefined);
      dispatch(identityActions.reset());
    }
    if (generateApplicantLinkStatus === StatusTypes.ERROR) {
      showSnackbar(
        VariantType.error,
        'Something went wrong trying to generate an applicant ID link'
      );
    }
  }, [
    SnackTypes.none,
    VariantType.success,
    generateApplicantLinkStatus,
    generateApplicantLinkAction,
    showSnackbar,
    selectedPropertyShortId,
    data?.verificationLink,
    dispatch,
    copy,
    VariantType.error
  ]);

  return {
    onGenerateApplicantLink,
    selectedPropertyShortId
  };
};
