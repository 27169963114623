import PropTypes from 'prop-types';
import { Dispatch, SetStateAction, useCallback, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import InputSearch from 'src/components/organisms/InputSearch';
import { SelectedSortData, Sort } from 'src/components/organisms/Sort';
import { LeasingTeam, SnapptTeam } from 'src/constants/roles';
import { default as useRole } from 'src/features/auth/hooks/useUserRoles';
import useUsers from 'src/features/users/hooks/useUsers';

import { selectors } from 'src/features/users/usersSlice';
import ModalCreate from '../ModalCreate';
import Filter from './Filter';
import filterReducer, { filterData, initiateFilters } from './filterReducer';
import { StyledButton } from './styles';

// Constants

const StyledInputSearch = styled(InputSearch)`
  input {
    margin-right: 28px;
  }
`;

const sortData = {
  dropdownItems: [
    {
      label: 'Name',
      value: 'name',
      isSelected: true
    },
    {
      label: 'Role',
      value: 'role',
      isSelected: false
    }
  ],
  radioGroup: [
    {
      value: 'asc',
      label: 'Ascending',
      isChecked: true
    },
    {
      value: 'desc',
      label: 'Descending',
      isChecked: false
    }
  ]
};

const sortInitial = {
  field: sortData.dropdownItems[0].value,
  order: sortData.radioGroup[0].value
};

type UsersFiltersProps = {
  label?: string;
  team?: 'leasing' | 'snappt';
  filter?: string;
  setUserFilters: Dispatch<SetStateAction<string | undefined>>;
};

const UsersFilters: React.FC<UsersFiltersProps> = ({
  team,
  label,
  filter,
  setUserFilters
}: UsersFiltersProps) => {
  const navigate = useNavigate();
  const { onSearch, onSort, allowAddUser, onGetAll } = useUsers();
  const { isAdmin, role } = useRole();
  const getUsersFilter = useSelector(selectors.getAll.filter);
  const getUsersIsToolbarDisabled = useSelector(selectors.getAll.isToolbarDisabled);
  const getUsersIsLoading = useSelector(selectors.getAll.isLoading);
  const getUsersError = useSelector(selectors.getAll.error);

  const [selectedSort, setSelectedSort] = useState<SelectedSortData>(sortInitial);
  const [currentTeam, setCurrentTeam] = useState(team);
  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);

  const [filterPanelData, dispatch] = useReducer(filterReducer, filterData, initiateFilters(role));

  useEffect(() => {
    // Adjusts role filtering options based on the tab selected.
    dispatch({ type: 'LOAD_TEAM', team, filter, role });
  }, [filter, team, role]);

  const onFiltering = useCallback(
    (data: string) => {
      dispatch({ type: 'SET_FILTER', filterData: data, team });

      if (data) {
        const queryfilter = `role@>${data}`;
        setUserFilters(queryfilter);
        onGetAll(undefined, undefined, queryfilter);
      } else {
        const queryfilter = team
          ? `role@>${(team === 'snappt' ? SnapptTeam : LeasingTeam).join('|')}`
          : undefined;
        setUserFilters(queryfilter);
        onGetAll(undefined, undefined, queryfilter);
      }
    },
    [onGetAll, setUserFilters, team]
  );

  const onSorting = useCallback(
    (selected: SelectedSortData) => {
      // When the team is defined, the string `selectedField` must be formed to filter and sort the roles
      const selectedField =
        selected?.field === 'role' && currentTeam ? `role_tab_${currentTeam}_team` : selected.field;
      const query = selected?.field
        ? `${selectedField}:${selected?.order}`
        : `${sortInitial.field}:${sortInitial.order}`;
      setSelectedSort(selected);
      onSort(query, filter);
    },
    [currentTeam, filter, onSort]
  );

  const onSortingClear = useCallback(() => {
    onSorting(sortInitial);
  }, [onSorting]);

  // If currentTeam is changed, reset the sorting
  useEffect(() => {
    if (currentTeam !== team) {
      setCurrentTeam(team);
      setTimeout(() => onSortingClear(), 0);
    }
  }, [team, currentTeam, onSortingClear]);

  const handleAddUser = useCallback(() => {
    if (isAdmin) {
      setIsModalCreateOpen(true);
    } else {
      navigate('/users/invitation/create');
    }
  }, [isAdmin, navigate]);

  return (
    <>
      <Row gap={1} justify="flex-end">
        <StyledInputSearch
          onSearch={({ value }) => onSearch(value, filter)}
          onClear={() => onSearch('', filter)}
          name="usersSearch"
          value={(getUsersFilter.q && decodeURIComponent(getUsersFilter?.q)) || ''}
          placeholder={`Search in ${label}`}
          isDisabled={getUsersIsToolbarDisabled}
        />

        <Filter
          data={filterPanelData}
          selected={filter || ''}
          onSelect={onFiltering}
          isDisabled={getUsersIsToolbarDisabled}
        />

        <Sort
          data={sortData}
          selected={selectedSort}
          onSelect={onSorting}
          onClear={onSortingClear}
          isDisabled={getUsersIsToolbarDisabled}
        />

        {allowAddUser() && (
          <StyledButton
            name="add_user_button_users"
            variant={ButtonVariant.contained}
            onClick={handleAddUser}
            isDisabled={getUsersIsLoading || getUsersError !== null}
          >
            Add User&nbsp;&nbsp;
            <Icon icon="plus" />
          </StyledButton>
        )}
      </Row>
      <ModalCreate
        setShowModal={setIsModalCreateOpen}
        showModal={isModalCreateOpen}
        key={team}
        team={team}
      />
    </>
  );
};

UsersFilters.propTypes = {
  label: PropTypes.string,
  team: PropTypes.oneOf(['leasing', 'snappt', undefined]),
  filter: PropTypes.string
};

UsersFilters.defaultProps = {
  label: 'Users'
};

export default UsersFilters;
