import styled from 'styled-components/macro';

export const Container = styled.div`
  border: 2px solid ${({ theme }) => theme.color.gray[200]};
  padding: 1rem;
  border-radius: 4px;
  > div {
    min-height: 255px;
  }
  .resource__title {
    color: ${({ theme }) => theme.color.blue[700]};
  }
  .resource__buttons {
    a,
    button {
      width: 2.5rem;
      justify-content: center;
    }
  }
`;
export const Content = styled.div``;
