import { featureFlagApi, unauthenticatedFeatureFlagApi } from 'src/lib/internal/feature-flag-api';
import { FeatureFlagProjectName } from 'src/ts/enums';

const api = {
  getFeatureFlag: async (featureFlagKey: string, filter: string) => {
    const result = await featureFlagApi.featureFlagProjectNameGet(
      FeatureFlagProjectName.FRAUD_PLATFORM,
      featureFlagKey,
      filter
    );
    return { result, featureFlagKey };
  },

  getUnauthenticatedFeatureFlag: async (featureFlagKey: string, filter: string) => {
    const result = await unauthenticatedFeatureFlagApi.unauthenticatedFeatureFlagProjectNameGet(
      FeatureFlagProjectName.FRAUD_PLATFORM,
      featureFlagKey,
      filter
    );
    return { result, featureFlagKey };
  }
};

export default api;
