import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import { Pagination } from 'src/components/molecules/Pagination';
import useUsers from 'src/features/users/hooks/useUsers';
import { selectors } from 'src/features/users/usersSlice';

type UsersPaginationProps = {
  filter?: string;
};

const UsersPagination: React.FC<UsersPaginationProps> = ({ filter }) => {
  const { onGetAll: onGetAllUsers } = useUsers();
  const getUsersData = useSelector(selectors.getAll.data);
  const getUsersError = useSelector(selectors.getAll.error);
  const getUsersFilter = useSelector(selectors.getAll.filter);
  const getUsersCount = useSelector(selectors.getAll.count);

  if (getUsersError) return null;
  if (!getUsersData?.length) return null;

  return (
    <>
      <Line height="tiny" />
      <Row direction="column" className="paginator__ListView">
        <Pagination
          name="UsersPagination"
          rowsPerPage={[5, 10, 20, 50, 100, 250, 500]}
          listPosition="top"
          pageInit={getUsersFilter.page}
          rowsPerPageInit={getUsersFilter.rowsPerPage}
          totalRows={getUsersCount}
          onNext={(page, rowsPerPage) => onGetAllUsers(page, rowsPerPage, filter)}
          onPrev={(page, rowsPerPage) => onGetAllUsers(page, rowsPerPage, filter)}
          onRowsPerPage={(page, rowsPerPage) => onGetAllUsers(page, rowsPerPage, filter)}
        />
      </Row>
    </>
  );
};

UsersPagination.propTypes = {
  filter: PropTypes.string
};

export default UsersPagination;
