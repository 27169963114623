import { useMemo } from 'react';

import Modal, { ModalProps, ModalVariant } from 'src/components/organisms/Modal';
import { useSendReport } from 'src/features/incomeVerification/hooks';

type SendReportModalProps = {
  onConfirm?: () => void;
  selectedEntryId: string;
  showSendReportModal: ModalProps['showModal'];
  setShowSendReportModal: ModalProps['setShowModal'];
};

const SendReportModal = ({
  onConfirm,
  selectedEntryId,
  showSendReportModal,
  setShowSendReportModal
}: SendReportModalProps): JSX.Element => {
  const { onSendReport } = useSendReport();

  const sendReportModalProps = useMemo(
    () => ({
      title: 'Send Report',
      labelButtonConfirm: 'Send Report',
      text: 'Are you sure you want to send this report?',
      showModal: showSendReportModal,
      setShowModal: setShowSendReportModal,
      onConfirm: async () => {
        setShowSendReportModal?.(false);

        const success = await onSendReport(selectedEntryId);
        if (success && onConfirm) {
          await onConfirm();
        }
      }
    }),
    [onSendReport, selectedEntryId, setShowSendReportModal, showSendReportModal, onConfirm]
  );

  return <Modal {...sendReportModalProps} variant={ModalVariant.none} labelButtonCancel="Cancel" />;
};

export default SendReportModal;
