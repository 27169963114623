//merge 2 arrays of objects and sort them alphabetically (A-Z)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mergeAndSort = (array1: any, array2: any, key: string) => {
  if (array1 && array2) {
    const mergedArray = [...array1, ...array2];
    const sortedArray = mergedArray.sort((a, b) => {
      const x = a[key].toLowerCase();
      const y = b[key].toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
    return sortedArray;
  }
};

export default mergeAndSort;
