import styled, { css } from 'styled-components/macro';
import { DropdownButtonProps, DropdownButtonSize } from './types';

const getSize = (size?: DropdownButtonSize) => {
  switch (size) {
    case 'sm':
      return css`
        min-width: 5rem;
        padding: 0.5rem 0.625rem;
      `;
    case 'md':
    default:
      return css`
        padding: 0.75rem 0.875rem;
        padding-left: 0.4rem;
      `;
  }
};

const getColor = (isDisabled?: boolean) => {
  if (isDisabled) {
    return css`
      background: ${({ theme }) => theme.color.gray[50]};
      color: ${({ theme }) => theme.color.gray[300]};
    `;
  }

  return css`
    background: ${({ theme }) => theme.color.white};
  `;
};

const getBorder = (hasError?: boolean) => {
  if (hasError) {
    return css`
      border: 0.125rem solid ${({ theme }) => theme.color.red[700]};
    `;
  }

  return css`
    border: 0.125rem solid ${({ theme }) => theme.color.gray[200]};
  `;
};

export const Container = styled.div<any>`
  ${({ hasError }) => getBorder(hasError)};
  border-radius: 0.188rem;
  position: relative;

  span[role='spinner'] {
    display: flex;
    align-items: center;
  }
`;

export const SubContainer = styled.div<Pick<DropdownButtonProps, 'isDisabled' | 'size'>>`
  ${({ theme }) => theme.font.normal};
  ${({ isDisabled }) => getColor(isDisabled)};
  cursor: ${({ isDisabled }) => !isDisabled && 'pointer'};
  ${({ size }) => getSize(size)}
  @media ${({ theme }) => theme.device.mobile} {
    > div {
      display: flex;
      flex-direction: row !important;
    }
  }
  > div {
    width: 100%;
  }
`;

export const FLoatLabel = styled.div`
  position: absolute;
  top: -0.7rem;
  left: 0.26rem;
  background: #fff;
  padding-left: 0.15rem;
  padding-right: 0.18rem;
  ${({ theme }) => theme.font.small};
  color: ${({ theme }) => theme.color.gray[700]};

  &[aria-invalid='true'] {
    small {
      color: ${({ theme }) => theme.color.red[700]};
    }
  }
`;

export const Content = styled.div`
  display: flex;
`;
