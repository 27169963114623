import { createAsyncThunk } from '@reduxjs/toolkit';
import { IFolder } from 'src/ts/interfaces';

import api from './api';
import { NAME } from './constants';

export const getFolderByIdThunk = createAsyncThunk(
  `${NAME}/getFolderById`,
  async (folderId: string) => {
    try {
      return api.getFolderById(folderId);
    } catch (error) {
      console.error(`${NAME}/getFolderById`, error);
      throw new Error('Failed to get folder');
    }
  }
);

export const updateFolderThunk = createAsyncThunk(
  `${NAME}/updateFolder`,
  async ({ id, ...folder }: { id: string } & Partial<IFolder>) => {
    try {
      return api.updateFolder(id, folder);
    } catch (error) {
      console.error(`${NAME}/updateFolder`, error);
      throw new Error('Failed to update folder');
    }
  }
);
