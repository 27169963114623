import { Card } from 'src/components/atoms/Card';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Tag, { TagColor } from 'src/components/atoms/Tag';
import styled from 'styled-components/macro';

export const StyledCard = styled(Card)`
  max-width: 40rem;
  & > div {
    padding: 1.5rem;
    max-height: 26.25rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`;

export const StyleRow = styled(Row)`
  margin-bottom: 0.5rem;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  height: 100%;
  padding-right: 0.5rem;
  overflow-y: auto;

  & > div:first-child {
    margin-top: 0;
  }

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.color.gray[200]};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.gray[600]};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.color.gray[700]};
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  & > div:first-child {
    max-width: 32%;
  }
`;

const getPercentageColorTag = (percent: number) => {
  switch (true) {
    case percent >= 80:
      return TagColor.green;
    case percent >= 40:
      return TagColor.yellow;
    default:
      return TagColor.red;
  }
};

export const StyledTag = styled(Tag).attrs((attrs) => ({
  label: `${attrs.label}% match` as string,
  color: getPercentageColorTag(Number(attrs.label)),
  showBackground: true
}))`
  margin: 0;
  border-radius: 0;
  width: 100%;
  text-align: center;
`;

export const PersonIcon = styled(Icon).attrs({
  icon: 'person-icon',
  color: 'primary'
})``;
