import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectors as authSelectors } from 'src/features/auth/authSlice';
import { useQueryParams } from 'src/hooks';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import { useAppDispatch } from 'src/store';
import { IEntry } from 'src/ts/interfaces';
import { mergeEntryThunk } from '../services';

export const useMergeEntry = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector(authSelectors.user);
  const queryParam = useQueryParams('redirect_uri');

  const { SnackTypes, showSnackbar, VariantType } = useSnackbarProvider();

  const onMergeEntry = useCallback(
    async (matchingEntryId?: string, entryId?: string) => {
      if (!entryId || !matchingEntryId) {
        showSnackbar(VariantType.error, 'Is missing the Entry Ids to merge it', SnackTypes.none);

        return;
      }

      try {
        const action = await dispatch(
          mergeEntryThunk({
            matchingEntryId,
            entryId
          })
        );
        const response = unwrapResult(action);
        const entry = response?.data as IEntry;

        const assigned_uri =
          entry?.review_assigned_to_id === user?.id ? `/my-work/${entry.folder_id}` : `/my-work/`;

        showSnackbar(VariantType.success, 'The entry has been merged', SnackTypes.none);

        const redirect_uri = queryParam.isValid ? assigned_uri : `/folder/${entry.folder_id}`;
        navigate(redirect_uri);
      } catch (error) {
        showSnackbar(VariantType.error, 'Failed to merge the entry', SnackTypes.none);
      }
    },
    [
      SnackTypes.none,
      VariantType.error,
      VariantType.success,
      dispatch,
      navigate,
      queryParam.isValid,
      showSnackbar,
      user?.id
    ]
  );

  return {
    onMergeEntry
  };
};
