import { Row } from 'src/components/atoms/Row';
import styled from 'styled-components';

interface ICarouselSlide {
  active?: boolean;
}

interface ICarouselProps {
  currentSlide: number;
}
export const ButtonRow = styled(Row)`
  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.5rem;
  }

  .carousel-buttons {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    border-color: #1d4ed8;
    justify-content: center;
  }

  .carousel-right-button {
    float: right;
  }
`;

export const CarouselWrapper = styled.div`
  display: flex;
  overflow-x: hidden;
`;

export const CarouselSlides = styled.div<ICarouselProps>`
  display: flex;
  width: 100%;
`;

export const CarouselSlide = styled.div<ICarouselSlide>`
  display: ${(props) => (props.active ? 'block' : 'none')};
  width: 100%;
`;
