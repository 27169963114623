import Button from 'src/components/atoms/Button';
import styled from 'styled-components/macro';

export const StyledCopyButtonContainer = styled.div`
  display: flex;
  @media ${({ theme }) => theme.device.mobile} {
    > div {
      margin-bottom: 0.625rem;
    }
  }
`;

export const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 0.2rem;
  a {
    height: 2.25rem;
  }
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

export const StyledCopyContainer = styled.div`
  position: relative;
  margin-left: 0.5rem;
  .toolTip {
    .toolTipDirection {
      top: -1px;
      &::after {
        left: 40%;
      }
    }
  }
`;

export const StyledCopyWithLabelContainer = styled(StyledCopyContainer)`
  margin-left: 0;
`;

export const StyledButtonCopy = styled(Button)`
  height: 2.25rem;
`;
