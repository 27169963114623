// Vendor
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Provider
import { FolderPageProvider as EntriesDataProvider } from 'src/components/folders/FolderPageContext';

// Components
import { Loader } from 'src/components/atoms/Loader';
import { ListView as ViewPage } from 'src/components/templates';
import EntriesManager from './components/EntriesManager';
import FolderDetails from './components/FolderDetails';
import { StyledContainer } from './styles';

// Redux
import { getPendingReviewEntryThunk, selectors as myWorkSelectors } from 'src/features/myWork';

// Hooks
import { useEntriesByFolderId } from 'src/features/entries/hooks';
import { useFolderById } from 'src/features/folders/hooks';

// Types
import { IFolder } from 'src/ts/interfaces';

const ReviewPageContent = ({ folder }: { folder?: IFolder }) => {
  const { folderId } = useParams();
  const { isLoading: isLoadingEntries } = useEntriesByFolderId(folderId as string);

  if (isLoadingEntries) {
    return <Loader />;
  }

  return (
    <ViewPage title="Review Application">
      <StyledContainer>
        <FolderDetails id={folder?.id} name={folder?.name} propertyName={folder?.property?.name} />
        <EntriesManager folderId={folderId as string} />
      </StyledContainer>
    </ViewPage>
  );
};

// Nesting components to prevent data fetching conflicts between folder and entries. This because
// of the use of a provider component and to maintain standard with the FolderReviewPage
const ReviewPage = () => {
  const dispatch = useDispatch();
  const { folderId } = useParams();
  const { isLoading: isLoadingFolder, folder } = useFolderById(folderId as string);
  const assignedEntry = useSelector(myWorkSelectors.assignedEntry);

  const isLoadingPendingSubmission = useSelector(myWorkSelectors.isLoadingPendingSubmission);

  useEffect(() => {
    dispatch(getPendingReviewEntryThunk());
  }, [dispatch]);

  if (isLoadingFolder || isLoadingPendingSubmission) {
    return <Loader />;
  }

  return (
    <EntriesDataProvider assignedEntry={assignedEntry}>
      <ReviewPageContent folder={folder} />
    </EntriesDataProvider>
  );
};

export default ReviewPage;
