import { useCallback } from 'react';

import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import { useAppDispatch } from 'src/store';
import { updateProofThunk } from '../services';

export const useProofUpdateNote = () => {
  const dispatch = useAppDispatch();

  const { SnackTypes, showSnackbar, VariantType } = useSnackbarProvider();

  const onUpdateNote = useCallback(
    async (proofId?: string, note?: string) => {
      if (!proofId) {
        showSnackbar(
          VariantType.error,
          'Is missing the Proof Id to update the Note',
          SnackTypes.none
        );

        return;
      }

      try {
        await dispatch(updateProofThunk({ id: proofId, note }));

        showSnackbar(VariantType.success, 'The note has been updated', SnackTypes.none);
      } catch (error) {
        showSnackbar(VariantType.error, 'Failed to update note', SnackTypes.none);
      }
    },
    [SnackTypes.none, VariantType.error, VariantType.success, dispatch, showSnackbar]
  );

  return {
    onUpdateNote
  };
};
