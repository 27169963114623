// Vendor
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Redux
import { RootState } from 'src/store';
import {
  getAllReducer,
  getCompaniesDDownReducer,
  getOneReducer,
  initialState,
  StateData,
  updateReducer
} from './reducers';

// Enums
import { Status as StatusEnum } from 'src/ts/enums';

// Types
import { getPaginationRequest } from 'src/ts/interfaces';

// Constants
import { NAME } from './constants';

const companySlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetSave: (state: StateData) => {
      state.update = initialState.update;
    },
    search: (state: StateData, action: PayloadAction<getPaginationRequest>) => {
      state.getAll.filter.q = action.payload.q;
    },
    resetGetAll: (state: StateData) => {
      state.getAll = initialState.getAll;
    },
    resetGetCompaniesDDown: (state: StateData) => {
      state.getCompaniesDDown = initialState.getCompaniesDDown;
    }
  },
  extraReducers: (builder) => {
    getAllReducer(builder);
    getOneReducer(builder);
    updateReducer(builder);
    getCompaniesDDownReducer(builder);
  }
});

export const selectors = {
  getCompaniesDDown: (state: RootState) => state[NAME].getCompaniesDDown,
  getAll: {
    isLoading: (state: RootState) => state[NAME].getAll.status === StatusEnum.LOADING,
    isIdle: (state: RootState) => state[NAME].getAll.status === StatusEnum.IDLE,
    isError: (state: RootState) => state[NAME].getAll.status === StatusEnum.ERROR,
    error: (state: RootState) => state[NAME].getAll.error,
    data: (state: RootState) => state[NAME].getAll.data || [],
    filter: (state: RootState) => state[NAME].getAll.filter,
    count: (state: RootState) => state[NAME].getAll.count || 0
  },
  getOne: {
    isLoading: (state: RootState) => state[NAME].getOne.status === StatusEnum.LOADING,
    isIdle: (state: RootState) => state[NAME].getOne.status === StatusEnum.IDLE,
    isError: (state: RootState) => state[NAME].getOne.status === StatusEnum.ERROR,
    error: (state: RootState) => state[NAME].getOne.error,
    data: (state: RootState) => state[NAME].getOne.data
  },
  update: {
    isLoading: (state: RootState) => state[NAME].update.status === StatusEnum.LOADING,
    isSaved: (state: RootState) => state[NAME].update.status === StatusEnum.SUCCESS,
    error: (state: RootState) => state[NAME].update.error
  }
};

export const { reducer, actions } = companySlice;
