import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getPaginationRequest,
  IdentityGenerateApplicantLinkPostRequestType
} from 'src/ts/interfaces';

import { IdentityReportPreset, LinkAction } from 'src/ts/enums';
import api from './api';
import { NAME } from './constants';

export const generateApplicantLinkThunk = createAsyncThunk(
  `${NAME}/generateApplicantLink`,
  async ({
    data,
    linkAction
  }: {
    data: IdentityGenerateApplicantLinkPostRequestType;
    linkAction: LinkAction;
  }) => {
    try {
      return api.generateApplicantLink(data, linkAction);
    } catch (error) {
      console.error(`${NAME}/generateApplicantLink`, error);
      throw new Error('Failed to generate the identity verification applicant link');
    }
  }
);

export const getApplicantsThunk = createAsyncThunk(
  `${NAME}/getApplicants`,
  async (payload: getPaginationRequest) => {
    try {
      return api.getApplicants(payload);
    } catch (error) {
      console.error(`${NAME}/getApplicants`, error);
      throw new Error('Failed to get applicants');
    }
  }
);

export const getApplicantByVerificationIdThunk = createAsyncThunk(
  `${NAME}/getApplicantByVerificationID`,
  async (verificationId: string) => {
    try {
      return await api.getApplicantByVerificationID(verificationId);
    } catch (error) {
      console.error(`${NAME}/getApplicantByVerificationID`, error);
      throw new Error('Failed to get applicant by verification id');
    }
  }
);

export const getFcraIdentityReportPDFThunk = createAsyncThunk(
  `${NAME}/getFcraIdentityReportPDFThunk`,
  async ({ verificationId, fileName }: { verificationId: string; fileName: string }) => {
    try {
      return await api.getIdentityReportPDF(verificationId, IdentityReportPreset.Fcra, fileName);
    } catch (error) {
      console.error(`${NAME}/getFcraIdentityReportPDFThunk`, error);
      throw new Error('Error getting the FCRA Identity PDF Report');
    }
  }
);

export const getIdentityReportPDFThunk = createAsyncThunk(
  `${NAME}/getIdentityReportPDFThunk`,
  async ({ verificationId, fileName }: { verificationId: string; fileName: string }) => {
    try {
      return await api.getIdentityReportPDF(
        verificationId,
        IdentityReportPreset.SummaryAndDocuments,
        fileName
      );
    } catch (error) {
      console.error(`${NAME}/getIdentityReportPDFThunk`, error);
      throw new Error('Error getting the Identity PDF Report');
    }
  }
);

export const getIdentityImagesThunk = createAsyncThunk(
  `${NAME}/getIdentityImagesThunk`,
  async (verificationId: string) => {
    try {
      return api.getIdentityImages(verificationId);
    } catch (error) {
      console.error(`${NAME}/getIdentityImagesThunk`, error);
      throw new Error('Error getting the Identity Images');
    }
  }
);
