import { FC, useState } from 'react';

import { IFolder } from 'src/ts/interfaces';
import { useFolderUpdateName } from 'src/features/folders/hooks';
import { FolderTileEdit, FolderTileInfo } from 'src/components/folders';

interface FolderTileProps {
  folder: Pick<IFolder, 'id' | 'name' | 'status' | 'result' | 'property'>;
}

const FolderTile: FC<FolderTileProps> = ({ folder }) => {
  const [isEditable, setIsEditable] = useState(false);
  const { onUpdateName } = useFolderUpdateName();

  if (isEditable) {
    return (
      <FolderTileEdit
        defaultValues={{ folderName: folder.name }}
        onCancel={() => setIsEditable(false)}
        onConfirm={({ folderName }) => {
          setIsEditable(false);

          return onUpdateName({ id: folder.id, name: folderName });
        }}
        propertyName={folder?.property?.name as string}
      />
    );
  }

  return (
    <FolderTileInfo
      onClickEdit={() => setIsEditable(true)}
      folderName={folder.name}
      propertyName={folder?.property?.name as string}
      lastEntryStatus={folder.status}
      lastEntryResult={folder.result}
    />
  );
};

export default FolderTile;
