// Vendor
import { useEffect } from 'react';

// Components
import { Line } from 'src/components/atoms/Line';
import { Loader } from 'src/components/atoms/Loader';
import Section from 'src/components/atoms/Section/Section';
import { Footer } from 'src/components/DUP/molecules/Footer';
import { FormApplicant } from 'src/components/DUP/molecules/FormApplicant';
import { UploadFiles } from 'src/components/DUP/molecules/UploadFiles';
import { UploadInstructions } from 'src/components/DUP/molecules/UploadInstructions';
import { DUP } from 'src/components/DUP/templates';
import { ResultMessage } from 'src/components/molecules/ResultMessage';
import { ResultMessageType } from 'src/components/molecules/ResultMessage/ResultMessage';

// Hooks
import useLanguage from 'src/context/Language/useLanguage';
import useForm from 'src/features/DUP/form/hooks/useForm';
import useDUP from 'src/features/DUP/hooks/useDUP';
import useProofs from 'src/features/DUP/proofs/hooks/useProofs';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';

const DUPPage: React.FC = () => {
  const { translate: t } = useLanguage();

  const {
    type,
    information,
    isLoading,
    error,
    isErrorUnauthorized,
    dataForm,
    proofs,
    submitError,
    isSubmitted,
    isSubmitting,
    errorOfSubmit,
    onResetErrorOfSubmit
  } = useDUP();
  const { isAbleToSubmit, isProofsIsProcessing, isProofsHasErrors } = useProofs();
  const { onUpdateCurrentApplication, isFormValid, setIsFormValid } = useForm();
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();

  if (isErrorUnauthorized) {
    window.location.href = '/login';
  }

  useEffect(() => {
    if (submitError && !isErrorUnauthorized) {
      showSnackbar(
        VariantType.error,
        errorOfSubmit || t('dup_submission_error_title'),
        SnackTypes.none
      );
      onResetErrorOfSubmit();
    }
  }, [
    submitError,
    isErrorUnauthorized,
    errorOfSubmit,
    onResetErrorOfSubmit,
    showSnackbar,
    VariantType,
    SnackTypes,
    t
  ]);

  if (error && !isErrorUnauthorized) {
    return (
      <Section align="center">
        <ResultMessage
          type={ResultMessageType.error}
          title={t('dup_submission_error_title')}
          message={t('dup_submission_error_message')}
        />
      </Section>
    );
  }

  if (isSubmitted) {
    return (
      <Section align="center">
        <ResultMessage
          type={ResultMessageType.success}
          title={t('dup_submission_success_title')}
          message={t('dup_submission_success_message')}
        />
      </Section>
    );
  }

  if (isLoading) {
    return <Loader isFixed />;
  }

  return (
    <DUP>
      <FormApplicant
        type={type}
        information={information}
        dataForm={dataForm}
        onValidation={setIsFormValid}
      />
      <Line />
      <UploadInstructions type={type} information={information} dataForm={dataForm} />
      <UploadFiles type={type} proofs={proofs} />
      <Footer
        information={information}
        isSubmittable={isFormValid && isAbleToSubmit}
        isProofsIsProcessing={isProofsIsProcessing}
        isProofsHasErrors={isProofsHasErrors}
        isSubmitting={isSubmitting}
        onSubmit={onUpdateCurrentApplication}
      />
    </DUP>
  );
};

export default DUPPage;
