import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { datadogInit } from './adapters/datadog';
import App from './App';
import SnackbarProvider from './components/molecules/SnackbarProvider';
import { ErrorBoundary } from './helpers';
import reportWebVitals from './reportWebVitals';
import ReduxProvider from './store/reduxProvider';
import theme from './theme';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ReduxProvider>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={1} autoHideDuration={2000}>
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </ReduxProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
datadogInit();
