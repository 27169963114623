import { RoleEnum } from 'src/ts/enums';

export const NAME = 'auth';

export const ROLE_DESCRIPTION: { [key in RoleEnum]: string } = {
  [RoleEnum.Admin]: 'Snappt Admin',
  [RoleEnum.CompanyAdmin]: 'Company Admin',
  [RoleEnum.PropertyAdmin]: 'Leasing Agent',
  [RoleEnum.BuildingManager]: 'Building Manager',
  [RoleEnum.Reviewer]: 'Reviewer',
  [RoleEnum.OnSiteRepresentative]: 'On-site Representative',
  [RoleEnum.FdeManager]: 'FDE Manager',
  [RoleEnum.SeniorFraudAnalyst]: 'Senior Fraud Analyst',
  [RoleEnum.FraudAnalyst]: 'Fraud Analyst',
  [RoleEnum.AccountRepresentative]: 'Account Representative',
  // Leave the values empty to not show those roles
  [RoleEnum.ExternalClient]: ''
};
