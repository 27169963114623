// Vendor
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import MultiTab, {
  TabBody,
  TabContent,
  TabHeader,
  TabTitle
} from 'src/components/molecules/MultiTab/MultiTab';
import { ListView as ViewPage } from 'src/components/templates';
import { MyWorkPerformancePage } from '../MyWorkPerformancePage';
import ReviewTab from './components/ReviewTab';

// Redux
import useRole from 'src/features/auth/hooks/useUserRoles';
import { getPendingReviewEntryThunk, selectors } from 'src/features/myWork';

const MyWorkPage = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState(0);
  const { isAnalyst } = useRole();

  const isLoadingPendingSubmission = useSelector(selectors.isLoadingPendingSubmission);
  const isIdlePendingSubmission = useSelector(selectors.isIdlePendingSubmission);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPendingReviewEntryThunk());
  }, [dispatch]);

  return (
    <ViewPage title="My Work">
      <div className="Container">
        <MultiTab
          name="MyWorkPageTab"
          selectedIndex={activeTab}
          onSelect={(index) => setActiveTab(index as number)}
        >
          <TabHeader aria-label="My Work Tab">
            <TabTitle>Review</TabTitle>
            {isAnalyst && <TabTitle>Performance</TabTitle>}
          </TabHeader>
          <TabBody>
            <TabContent>
              <ReviewTab isLoading={isIdlePendingSubmission || isLoadingPendingSubmission} />
            </TabContent>
            {isAnalyst && (
              <TabContent>
                <MyWorkPerformancePage />
              </TabContent>
            )}
          </TabBody>
        </MultiTab>
      </div>
    </ViewPage>
  );
};

export default MyWorkPage;
