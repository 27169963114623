// Vendor
import { FC, HTMLAttributes } from 'react';

// Components
import FolderRow from 'src/components/organisms/FolderRow';
import { Container } from './styles';

// Types
import { IFDEInboxEntry, RoleType } from 'src/ts/interfaces';

export interface FolderTableProps extends HTMLAttributes<HTMLDivElement> {
  folder: IFDEInboxEntry | null;
  isLoading: boolean;
  showReviewButton?: boolean;
  role: RoleType;
}

const FolderTable: FC<FolderTableProps> = (props) => {
  const { role, folder, showReviewButton } = props;

  return (
    <Container>
      <FolderRow folder={folder} role={role} showReviewButton={Boolean(showReviewButton)} />
    </Container>
  );
};

export default FolderTable;
