import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';

import { queryProofApi } from 'src/features/DUP/proofs/queryProof';

import { NAME as FEATURE_FLAGS_NAME } from 'src/features/serverInfo/constants';
import { fetchServerInfo } from 'src/features/serverInfo/services';

const persistConfig = { key: 'feature-flags', storage, whitelist: [FEATURE_FLAGS_NAME] };

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(queryProofApi.middleware)
});

const initObservers = () => {
  store.dispatch(fetchServerInfo());
};

initObservers();
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);

export default store;
