import { formatLabelValue } from '../formatLabelValue';

const formatCheckboxData = ({
  data,
  value,
  label
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any[];
  value: string;
  label: string;
}) => {
  if (!data) return [];

  const formated = formatLabelValue({
    data,
    value,
    label
  });

  return formated
    .map((e) => {
      return {
        ...e,
        isChecked: false
      };
    })
    .sort((a, b) => {
      const x = a.label.toLowerCase();
      const y = b.label.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
};

export default formatCheckboxData;
