import { StepProps, StepState } from 'src/components/atoms/Step/Step';

enum Steps {
  teamInformation = 'teamInformation',
  assignMembers = 'assignMembers',
  confirm = 'confirm'
}

export type IStep = {
  id: Steps;
  state: StepState;
  label: string;
};

const initialSteps: IStep[] = [
  {
    id: Steps.teamInformation,
    state: StepState.current,
    label: 'Team Information'
  },
  {
    id: Steps.assignMembers,
    state: StepState.disabled,
    label: 'Assign Members'
  },
  {
    id: Steps.confirm,
    state: StepState.disabled,
    label: 'Confirm'
  }
];

const useTeamsSteps = () => {
  const isStepDisabled = (step: keyof typeof Steps, steps: StepProps[]) => {
    return !!steps.find((el) => el.id === step && el.state === StepState.disabled);
  };

  const stepsUpdated = (step: keyof typeof Steps, steps: typeof initialSteps | []) =>
    steps.map((item) => {
      if (item.state === StepState.current) {
        return {
          ...item,
          state: StepState.completed
        };
      }
      if (step.includes(item.id)) {
        return {
          ...item,
          state: StepState.current
        };
      }

      return item;
    });

  return {
    initialSteps,
    Steps,
    isStepDisabled,
    stepsUpdated
  };
};

export default useTeamsSteps;
