/* eslint-disable react-hooks/exhaustive-deps */
import Button, { ButtonColor, ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import InputText, { InputTextType } from 'src/components/atoms/InputText/InputText';
import { Row } from 'src/components/atoms/Row';
import { StepProps } from 'src/components/atoms/Step/Step';
import Text from 'src/components/atoms/Text';
import { StepsFlow } from 'src/components/molecules/StepsFlow';
import { IStep } from 'src/features/teams/hooks/useTeamsSteps';
import { ITeam } from 'src/ts/interfaces';

type TeamsCreateFormConfirmProps = {
  defaultValues: ITeam;
  onSave: () => void;
  onBack: () => void;
  isSaving: boolean;
  steps: IStep[];
  handleStepClick: (step: StepProps) => void;
};

const TeamsCreateFormConfirm: React.FC<TeamsCreateFormConfirmProps> = ({
  defaultValues,
  onSave,
  onBack,
  isSaving,
  steps,
  handleStepClick
}: TeamsCreateFormConfirmProps) => {
  return (
    <>
      <StepsFlow steps={steps} onClickStep={handleStepClick} />
      <Gap height={2} />
      <Text>To edit this information you can click any of previous steps</Text>
      <Gap height={2} />
      <Text isBold>Information</Text>
      <Gap height={1} />
      <InputText
        placeholder="Team Alias"
        name="name"
        defaultValue={defaultValues.name}
        type={InputTextType.text}
        isReadonly
      />
      <InputText
        placeholder="Country"
        name="country.label"
        defaultValue={defaultValues.country?.label}
        type={InputTextType.text}
        isReadonly
      />
      <InputText
        placeholder="Timezone"
        name="timezones.label"
        defaultValue={defaultValues.timezones?.label}
        type={InputTextType.text}
        isReadonly
      />

      <Text isBold>Members</Text>
      <Gap height={1} />
      {defaultValues.fdeManager?.label && (
        <InputText
          placeholder="FDE Manager"
          name="fdeManager.label"
          defaultValue={defaultValues.fdeManager?.label}
          type={InputTextType.text}
          isReadonly
        />
      )}
      <InputText
        placeholder="Senior Fraud Analyst"
        name="membersSeniorFraudAnalyst.count"
        defaultValue={defaultValues.membersSeniorFraudAnalyst?.count}
        type={InputTextType.text}
        isReadonly
      />
      <InputText
        placeholder="Fraud Analyst"
        name="membersFraudAnalyst.count"
        defaultValue={defaultValues.membersFraudAnalyst?.count}
        type={InputTextType.text}
        isReadonly
      />
      <Row gap={1}>
        <Button
          name="confirm_button"
          variant={ButtonVariant.contained}
          color={ButtonColor.primary}
          size={ButtonSize.medium}
          onClick={onSave}
          isDisabled={isSaving}
          isLoading={isSaving}
        >
          Confirm
        </Button>
        <Button
          name="gobBack_to_assigning_button"
          variant={ButtonVariant.outline}
          color={ButtonColor.primary}
          size={ButtonSize.medium}
          onClick={onBack}
          isDisabled={isSaving}
        >
          Cancel
        </Button>
      </Row>
    </>
  );
};

export default TeamsCreateFormConfirm;
