import { useState } from 'react';
import Button, { ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import Text, { TextColor } from 'src/components/atoms/Text';
import { Buttons, Container } from './styles';

type DataValues = {
  value: string;
  label: string;
  isSelected?: boolean;
};

export type ButtonGroupProps = {
  buttons: DataValues[];
  onClick?: (selected: DataValues) => void;
  label?: string;
  showLabel?: boolean;
  isDisabled?: boolean;
};

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  buttons,
  onClick = () => null,
  label,
  showLabel = true,
  isDisabled = false
}: ButtonGroupProps) => {
  const [selected, setSelected] = useState(
    buttons.find((button) => button.isSelected) ||
      Object.assign({ ...buttons[0], isSelected: true })
  );

  const handleOnClick = (selected: DataValues) => {
    const dataSelected = { value: selected.value, label: selected.label, isSelected: true };
    setSelected(dataSelected);
    onClick(dataSelected);
  };

  return (
    <Container className="ButtonGroup">
      {label && showLabel && (
        <Text color={TextColor.initial} isStretched={false}>
          {label}
        </Text>
      )}

      <Buttons>
        {buttons.map((button) => (
          <Button
            name={button.value}
            key={button.value}
            isDisabled={isDisabled}
            variant={
              button.value === selected.value && selected.isSelected
                ? ButtonVariant.contained
                : ButtonVariant.ghost
            }
            onClick={() => handleOnClick(button)}
            size={ButtonSize.medium}
            className={button.value === selected.value && selected.isSelected ? 'selected' : ''}
          >
            {button.label}
          </Button>
        ))}
      </Buttons>
    </Container>
  );
};

export default ButtonGroup;
