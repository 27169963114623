// Vendor
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import Gap from 'src/components/atoms/Gap';
import { Loader } from 'src/components/atoms/Loader';
import { Row } from 'src/components/atoms/Row';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { CardTagList } from 'src/components/molecules/CardTagList';
import { ShowState } from 'src/components/molecules/ShowState';
import { IFrame } from 'src/components/organisms/IFrame';
import { DetailView } from 'src/components/templates/DetailView';

// Types
import { IReportArg } from 'src/pages/ReportingPage/ReportingPage';

// Helpers
import { configVars, decode } from 'src/helpers';
import generateURLQuery from 'src/helpers/generateURLQuery';

// Hooks
import { useAsync } from 'src/hooks';
import useReporting from '../ReportingPage/hooks/useReporting';

const ReportingReportPage: React.FC = () => {
  const navigate = useNavigate();
  const { reportURL } = useParams();
  const reportParams: IReportArg = JSON.parse(decode(reportURL));

  const goBack = () => navigate('/reporting', { state: { from: 'ReportingReportPage' } });

  const { fetchIframeUrl } = useReporting();

  const generateReport = async () => {
    const reportConfig = configVars.metabase?.dashboards?.reporting_tab;

    if (reportConfig) {
      const { id, report_type } = reportConfig;
      const { date_from, date_to, company, property } = reportParams;
      const result = await fetchIframeUrl({
        id,
        payload: {
          filter: generateURLQuery({ date_from, date_to, company, property }),
          report_type
        },
        isFiltered: true
      });
      return result;
    }
  };

  const [{ result, error }, callGenerateReport] = useAsync(generateReport, '');

  useEffect(() => {
    callGenerateReport();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [reportURL]);

  if (error) {
    return (
      <ShowState
        type="information"
        variant="error"
        message={error}
        buttonLabel="Reload"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (!reportParams?.date_from && !reportParams?.date_to && reportParams?.company === '') {
    return (
      <ShowState
        type="information"
        variant="error"
        message="Impossible to make the show the report"
        buttonLabel="Go to Reporting"
        onClick={() => goBack}
      />
    );
  }

  if (!result.data?.iframe_url) {
    return <Loader />;
  }

  return (
    <DetailView
      back={{
        onClick: () => goBack(),
        label: 'Go Back'
      }}
      title="Reporting"
    >
      <>
        <Text variant={TextVariant.normal}>
          Report generated from {reportParams.date_from} to {reportParams.date_to} for the following
          items:
        </Text>
        <Gap />
        <Row columns={2} justify="space-between" alignItems="stretch" gap={1}>
          <Row.Col size={6}>
            <CardTagList
              title="Company"
              icon="company"
              items={reportParams.company_names.split('|')}
            />
          </Row.Col>
          <Row.Col size={6}>
            <CardTagList
              title="Property"
              icon="property"
              items={reportParams.property_names.split('|')}
            />
          </Row.Col>
        </Row>
        <Gap height={2} />
      </>
      <IFrame url={result.data?.iframe_url} title="Reporting" />
    </DetailView>
  );
};

export default ReportingReportPage;
