import styled from 'styled-components/macro';

export const Container = styled.div`
  div[role='dropdown'],
  div[role='dropdownMulticheck'] {
    min-width: 220px;
    max-width: 350px;

    .DDContent,
    span,
    ul li button {
      font-size: 0.95rem;
    }
  }
`;

export const ContentCheck = styled.div`
  > div {
    height: 40px;
  }
`;
