/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Loader } from 'src/components/atoms/Loader';
import { Row } from 'src/components/atoms/Row';
import Text from 'src/components/atoms/Text';
import { ShowState } from 'src/components/molecules/ShowState';
import { DetailView } from 'src/components/templates/DetailView';
import useCountry from 'src/features/country/hooks/useCountry';
import useTeams from 'src/features/teams/hooks/useTeams';
import { selectors as teamSelectors } from 'src/features/teams/teamsSlice';
import useTimezone from 'src/features/timezone/hooks/useTimezone';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import { ITeam } from 'src/ts/interfaces';
import TeamsEditFormAssigning from '../TeamsCreatePage/components/TeamsCreateFormAssigning';
import TeamsEditFormInformation from '../TeamsCreatePage/components/TeamsCreateFormInformation';

const defaultValues: ITeam = {
  name: '',
  country: {
    id: undefined,
    label: undefined
  },
  timezones: {
    id: undefined,
    label: undefined
  },
  fdeManager: {
    id: undefined,
    label: undefined
  },
  membersSeniorFraudAnalyst: { data: [], count: undefined },
  membersFraudAnalyst: { data: [], count: undefined }
};

const TeamsEditPage: React.FC = () => {
  const { teamId } = useParams();
  const navigate = useNavigate();
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();

  const {
    onUpdate,
    onResetUpdate,
    onGetAll: onGetTeams,
    onGetOne: onGetTeam,
    onResetGetOne,
    onResetGetAllFDEManagers
  } = useTeams();

  const getTeamData = useSelector(teamSelectors.getOne.data);
  const getTeamIsIdle = useSelector(teamSelectors.getOne.isIdle);
  const getTeamIsLoading = useSelector(teamSelectors.getOne.isLoading);
  const getTeamError = useSelector(teamSelectors.getOne.error);

  const updateIsUpdated = useSelector(teamSelectors.update.isUpdated);
  const updateIsLoading = useSelector(teamSelectors.update.isLoading);
  const updateError = useSelector(teamSelectors.update.error);

  const { onResetGetAll: onResetGetAllTimezone } = useTimezone();
  const { onResetGetAll: onResetGetAllCountries } = useCountry();

  const [formValues, setFormValues] = useState<ITeam | undefined>(undefined);

  useEffect(() => {
    if (teamId && getTeamIsIdle) {
      onGetTeam(teamId);
    }
  }, [teamId, getTeamIsIdle]);

  useEffect(() => {
    if (!getTeamIsLoading && getTeamData && getTeamError === null) {
      setFormValues(getTeamData);
    }
  }, [getTeamIsLoading, getTeamData, getTeamError]);

  useEffect(() => {
    if (updateIsUpdated) {
      onGetTeams();
      navigate('/teams');
      showSnackbar(VariantType.success, 'Team has been edited successfully', SnackTypes.none);
    }
  }, [showSnackbar, VariantType, SnackTypes, updateIsUpdated]);

  useEffect(() => {
    return () => {
      onResetUpdate();
      onResetGetOne();
      onResetGetAllFDEManagers();
      onResetGetAllTimezone();
      onResetGetAllCountries();
    };
  }, []);

  useEffect(() => {
    if (updateError !== null) {
      showSnackbar(VariantType.error, updateError as string, SnackTypes.none);
    }
  }, [showSnackbar, VariantType, SnackTypes, updateError]);

  const onSetForm = (formData: ITeam) => {
    onUpdate({ ...formData, id: teamId });
  };

  const methods = useForm<ITeam>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues
  });

  if (getTeamError) {
    return (
      <ShowState
        type="information"
        variant="error"
        message={getTeamError}
        buttonLabel="Reload"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (getTeamIsLoading || !formValues) {
    return <Loader />;
  }

  return (
    <DetailView back={{ to: '/teams', label: 'Go Back' }} title="Team Configuration">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSetForm)}>
          <Text isBold>Information</Text>
          <Gap height={1} />
          <TeamsEditFormInformation
            methods={methods}
            defaultValues={formValues}
            showButtons={false}
          />
          <Text isBold>Members</Text>
          <Gap height={1} />
          <TeamsEditFormAssigning
            methods={methods}
            defaultValues={formValues}
            showButtons={false}
            process="edit"
          />
          <Row gap={1}>
            <Button
              name="update_button"
              variant={ButtonVariant.contained}
              color={ButtonColor.primary}
              size={ButtonSize.medium}
              type={ButtonType.submit}
              isDisabled={updateIsLoading || !methods.formState.isDirty}
            >
              Save
            </Button>
            <Button
              name="gobBack_button"
              variant={ButtonVariant.outline}
              color={ButtonColor.primary}
              size={ButtonSize.medium}
              isDisabled={updateIsLoading}
              onClick={() => navigate('/teams')}
            >
              Cancel
            </Button>
          </Row>
        </form>
      </FormProvider>
    </DetailView>
  );
};

export default TeamsEditPage;
