export const reasons = [
  {
    value:
      'The documents you submitted have been scanned and cannot be accepted. Please go directly to your financial institution or payroll provider’s website to download the original PDF version of these documents. Do not rename the file.',
    label: 'Scanned Documents',
    isChecked: false
  },
  {
    value:
      'The documents you submitted cannot be accepted because they have been downloaded using a process called “Print to PDF”. Please go directly to your financial institution or payroll provider’s website to download the original PDF version of these documents. Do not rename the file.',
    label: 'Print to PDF Documents',
    isChecked: false
  },
  {
    value:
      'The documents you submitted cannot be accepted because they are not an acceptable document type. The acceptable document types are Bank Statements (checking and/or saving account) and Payroll Statements. Please go directly to your financial institution or payroll provider’s website to download the original PDF version of these documents. Do not rename the file.',
    label: 'Invalid Document Type',
    isChecked: false
  },
  {
    value:
      'The documents you submitted cannot be accepted because they are merged amongst two or more statement periods. Please submit documents that contain a single period only, downloaded directly from your financial institution or payroll provider’s website. If you have multiple statement periods you would like to submit, include them as separate documents in your submission.',
    label: 'Merged Document',
    isChecked: false
  },
  {
    value:
      'You have uploaded a Microsoft Word document. Only PDF documents downloaded directly from your financial institution or payroll provider will be accepted. Please go directly to your financial institution or payroll providers website to download the PDF version of these documents.',
    label: 'Microsoft Word Document',
    isChecked: false
  },
  {
    value:
      'These documents are issued in a foreign country. Please resubmit documents issued in the US.',
    label: 'Foreign Issued Document',
    isChecked: false
  },
  {
    value:
      'The documents you submitted cannot be accepted. Please go directly to your financial institution or payroll provider’s website to download the original PDF version of these documents. Do not rename the file.',
    label: 'Other/Unknown',
    isChecked: false
  },
  {
    value: 'Custom',
    label: 'Custom',
    isChecked: false
  }
];
