import React from 'react';
import PropTypes from 'prop-types';
import { Circle as StyledCircle, Container as StyledContainer } from './styles';

export type ToggleProps = {
  name: string;
  onClick: () => void;
  isDisabled?: boolean;
  isActive: boolean;
};

const Toggle: React.FC<ToggleProps> = (props: ToggleProps) => {
  const { onClick, name, isActive, isDisabled = false } = props;

  const onChanging = () => {
    if (isDisabled) return null;
    onClick();
  };

  return (
    <StyledContainer name={name} onClick={onChanging} isActive={isActive} isDisabled={isDisabled}>
      <StyledCircle isActive={isActive} isDisabled={isDisabled} />
    </StyledContainer>
  );
};

Toggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

Toggle.defaultProps = {
  name: 'Toggle',
  isActive: false,
  isDisabled: false
};

export default Toggle;
