import ButtonLink from 'src/components/atoms/ButtonLink';
import Text from 'src/components/atoms/Text';
import styled from 'styled-components/macro';
import { getIdentityVerificationResultStatusColorTag } from 'src/features/identityVerification/helpers';
import Tag from 'src/components/atoms/Tag';
import { IdentityApplicantsGet200ResponseInnerStatusEnum } from 'lib/internal/epa/gen';

export const Header = styled.div`
  display: flex;
  padding-left: 5px;

  & > div {
    margin-right: 6rem;
    margin-bottom: 0.75rem;
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  width: 180px;

  & > div {
    margin-right: 1rem;
  }

  & > h4 {
    color: ${({ theme }) => theme.color.gray[900]};
  }
`;

export const StyledText = styled(Text)`
  min-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline;
`;

export const StyledButton = styled(ButtonLink)`
  justify-content: center;
  font-weight: 500;
  font-size: 0.875rem;
  gap: 0.75rem;
`;

export const Content = styled.div`
  width: 100%;
  padding: 0 30px;
`;

export const StyledTag = styled(Tag).attrs((attrs) => ({
  label: attrs.label,
  color: getIdentityVerificationResultStatusColorTag(
    attrs.label as IdentityApplicantsGet200ResponseInnerStatusEnum
  ),
  showBackground: true
}))``;
