import { Row } from 'src/components/atoms/Row';
import styled from 'styled-components';

export const SearchContainer = styled(Row)`
  padding: 0.5rem;
  padding-bottom: 0;

  > div {
    width: 100%;
  }

  .input-form {
    border-right-width: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-bottom: 0px;
  }

  button {
    &.end-andorment {
      &:hover {
        svg {
          path {
            fill: ${({ theme }) => theme.color.blue[700]};
          }
        }
      }
    }
    &.search_button {
      height: 48px;
      border: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding-left: 1rem;
      padding-right: 1rem;
      &:disabled {
        background-color: ${({ theme }) => theme.color.gray[300]};
        svg {
          path {
            fill: ${({ theme }) => theme.color.white};
          }
        }
      }
    }
  }
`;
