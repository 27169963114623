import { FC } from 'react';

import { Row } from 'src/components/atoms/Row';
import Text, { TextAlign, TextColor, TextVariant } from 'src/components/atoms/Text';

const Field: FC<{
  title: string;
  content: string;
  contentColor?: TextColor;
}> = ({ title, content, contentColor }) => {
  return (
    <Row gap={1}>
      <Row.Col size={3}>
        <Text align={TextAlign.right} color={TextColor.gray700} variant={TextVariant.small} isBold>
          {title}
        </Text>
      </Row.Col>
      <Row.Col size={9}>
        <Text color={contentColor || TextColor.gray700} variant={TextVariant.small}>
          {content}
        </Text>
      </Row.Col>
    </Row>
  );
};

export default Field;
