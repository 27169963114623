import styled from 'styled-components';

export const Container = styled.div`
  border-left: 8px solid ${({ theme }) => theme.color.gray[300]};
  border-radius: 4px 0 0 4px;
  background: #fff;
  display: inline-block !important;
  width: 263px;
  @media (max-width: 1367px) {
    width: 302px;
  }
`;

export const Content = styled.div`
  align-items: start;
  flex-direction: row;
  padding: 15px 17px 24px 17px;
  border-radius: 0px 4px 4px 0px;
  background-color: ${({ theme }) => theme.color.gray[50]};
  border-left: none;
  height: 100%;

  .SkeletonPiece {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Thumbnail = styled.div`
  margin-top: 1.23rem;
  margin-bottom: 1.7rem;
  svg {
    height: 144px;
  }
`;
