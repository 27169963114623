import { Row } from 'src/components/atoms/Row';
import styled from 'styled-components';

export const PdfViewerWrapper = styled(Row.Col)`
  div {
    border: 11px solid ${({ theme }) => theme.color.blue[200]};
  }
`;

export const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.5rem;
  }

  .income-verification-tooltip {
    padding-left: 10px;
  }

  .income-verification-date-picker {
    width: 100%;
  }

  & .raise {
    z-index: 9999999;
    position: relative;
  }
`;
