import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row } from 'src/components/atoms/Row';
import { LoaderTeamList } from 'src/components/molecules/LoaderTeamList';
import { ShowState } from 'src/components/molecules/ShowState';
import { RowTeamList } from 'src/components/molecules/TeamCard';
import { selectors } from 'src/features/teams';
import useTeam from 'src/features/teams/hooks/useTeams';
import { ITeam } from 'src/ts/interfaces';

const TeamList: React.FC = () => {
  const navigate = useNavigate();
  const { onGetAll: onGetTeams } = useTeam();

  const getTeamsData = useSelector(selectors.getAll.data);
  const getTeamsIsIdle = useSelector(selectors.getAll.isIdle);
  const getTeamsIsLoading = useSelector(selectors.getAll.isLoading);
  const getTeamsError = useSelector(selectors.getAll.error);

  useEffect(() => {
    if (getTeamsIsIdle) {
      onGetTeams();
    }
  }, [onGetTeams, getTeamsIsIdle]);

  if (getTeamsError) {
    return (
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Please try again"
        message="Something went wrong"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (getTeamsIsLoading) {
    return (
      <div className="Container">
        <LoaderTeamList />
      </div>
    );
  }

  if (!getTeamsIsLoading && getTeamsData?.length === 0) {
    return <ShowState message="There are no teams yet" type="team" variant="empty" />;
  }

  return (
    <div className="Container">
      <Row columns={2} gap={1.5} wrap={'wrap'} justify="center">
        {getTeamsData?.map((team: ITeam) => {
          return (
            <RowTeamList
              key={team.id}
              teamName={team.name || 'None'}
              fdeManagerName={team.fdeManager?.label}
              timezone={team.timezones?.label || 'None'}
              numMembers={team.numMembers || 0}
              onEdit={() => {
                navigate(`/teams/edit/${team.id}`);
              }}
            />
          );
        })}
      </Row>
    </div>
  );
};

export default TeamList;
