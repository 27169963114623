import styled from 'styled-components/macro';

import Button from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';

export const Container = styled.div``;

export const Item = styled.div`
  padding-left: 1.313rem;
`;

export const StyledIcon = styled(Icon)`
  & > div > svg {
    width: 20px;
    margin-right: 7px;
  }
`;

export const Content = styled.div`
  display: none;
  margin-bottom: 0.313rem;

  &.active {
    display: block;
  }
`;

export const StyledButton = styled(Button)`
  ${({ theme }) => theme.font.small};
  height: 1.875rem;
`;
