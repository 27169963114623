import Grid from 'src/components/atoms/Grid';
import styled from 'styled-components/macro';

export const StyledGrid = styled(Grid)`
  overflow-y: auto;
  overflow-x: hidden;
  height: fit-content;
`;

export const StyledInstructionContainer = styled(Grid)`
  position: sticky;
  width: 40%;
`;

export const StyledPaginationContainer = styled(Grid)`
  width: 60%;
  padding: 0rem 2rem;
`;
