import { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import Checkbox from './Checkbox';
import { CheckboxData, CheckboxProps } from './types';

interface ControlledCheckboxProps
  extends UseControllerProps,
    Omit<CheckboxProps, 'name' | 'value'> {}

const ControlledCheckbox: FC<ControlledCheckboxProps> = (props) => {
  const { name, control, rules, shouldUnregister, onClick, ...checkboxProps } = props;

  const {
    field: { value, onChange: onChangeController }
  } = useController({ name, control, rules, shouldUnregister });

  return (
    <Checkbox
      {...checkboxProps}
      isChecked={value}
      value={name}
      name={name}
      onClick={(data: CheckboxData) => {
        onChangeController(data.isChecked);
        onClick?.(data);
      }}
    />
  );
};

ControlledCheckbox.displayName = 'ControlledCheckbox';

export default ControlledCheckbox;
