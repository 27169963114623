// Vendor
import PropTypes from 'prop-types';
import React, { memo, useEffect, useReducer, useState } from 'react';
import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form';

// Reducer
import { DDownMulticheckReducer, initialState } from './reducer';

// Components
import Button, { ButtonType, ButtonVariant } from 'src/components/atoms/Button';
import { Checkbox, CheckboxType } from 'src/components/atoms/Checkbox';
import { DropdownButton, DropdownButtonSize } from 'src/components/atoms/DropdownButton';
import { Icon } from 'src/components/atoms/Icon';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import OutsideDetector from '../OutsideDetector/OutsideDetector';
import { ChoicesBox } from './components/ChoicesBox';
import { SearchBox } from './components/SearchBox';
import {
  Container,
  Content,
  HelperText,
  ItemCheck,
  ItemRemove,
  ItemsContainer,
  OptionContainer,
  OptionsContainer,
  SearchBoxContainer,
  StateMessage
} from './styles';

// Helper
import { generateUUID } from 'src/helpers/generateUUID';

export type DropdownMulticheckProps = {
  data: CheckboxType[];
  onChange?: ({
    ids,
    data,
    isCheckAll,
    q
  }: {
    ids: string;
    data: CheckboxType[];
    isCheckAll: boolean;
    q?: string;
  }) => void;
  name: string;
  totalRows?: number;
  isDisabled?: boolean;
  isLoading?: boolean;
  showSearch?: boolean;
  onSearch?: (q: string) => void;
  placeholder?: string;
  size?: DropdownButtonSize;
  config?: RegisterOptions;
  textSelectAll: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemRef?: any;
};

export const DropdownMulticheck: React.FC<DropdownMulticheckProps> = (props) => {
  const {
    data,
    onChange,
    name,
    totalRows,
    isLoading = false,
    placeholder,
    size,
    config,
    itemRef,
    isDisabled,
    onSearch,
    showSearch = false,
    textSelectAll
  } = props;

  if (showSearch && !onSearch) {
    throw new Error('You must use the props showSearch, onSearch to enable the search-box');
  }

  const [{ items, q, itemsSelected, checkAll }, dispatch] = useReducer(
    DDownMulticheckReducer,
    initialState
  );

  const {
    register,
    control,
    setValue,
    formState: { errors },
    clearErrors
  } = useFormContext();

  useWatch({
    control,
    name
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (data) {
      dispatch({
        type: 'load',
        payload: { items: data, q }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    register(name, config);
  }, [config, name, register]);

  useEffect(() => {
    if (isChecked) {
      // Data string set to empty if all items are selected to prevent sending all company ids in the metabase URL
      // Metabase query will select all companies if the parameter is empty
      const dataString = checkAll
        ? ''
        : itemsSelected.map((item: CheckboxType) => item.value).join(',');
      onChange?.({ ids: dataString, data: itemsSelected, isCheckAll: Boolean(checkAll) });
      setIsChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  const onChanging = (check: CheckboxType) => {
    dispatch({ type: 'selected', payload: { item: check } });

    setValue(name, check.value);
    setIsChecked(true);
  };

  const remove = (value: string) => {
    dispatch({ type: 'remove', payload: { value } });
    setIsChecked(true);
  };

  const removeAll = () => {
    dispatch({ type: 'removeAll' });
    setIsChecked(true);
  };

  const getContent = () => {
    return itemsSelected.length === 0 ? placeholder : Items();
  };

  const onItemSelected = (item: CheckboxType) => {
    clearErrors(name);
    onChanging(item);
  };

  const getError = () => {
    const message = errors[name]?.message;
    return message !== '' ? message : config?.required;
  };

  const hasError = Object.prototype.hasOwnProperty.call(errors, name);

  const Items = () => {
    return (
      <Content>
        {checkAll && !q ? (
          // All records selected design
          <ItemsContainer>
            <ItemCheck>
              {textSelectAll}
              <ItemRemove
                onClick={(e) => {
                  e.stopPropagation();
                  removeAll();
                }}
              >
                <Icon icon="removeItem" />
              </ItemRemove>
            </ItemCheck>
          </ItemsContainer>
        ) : (
          <ItemsContainer>
            {itemsSelected.map(({ value, label }: CheckboxType) => {
              return (
                <ItemCheck key={value}>
                  {label}
                  {!isDisabled && (
                    <ItemRemove
                      onClick={(e) => {
                        e.stopPropagation();
                        remove(value);
                      }}
                    >
                      <Icon icon="removeItem" />
                    </ItemRemove>
                  )}
                </ItemCheck>
              );
            })}
          </ItemsContainer>
        )}

        {!isDisabled && itemsSelected.length > 0 && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              removeAll();
            }}
            className="end-andorment"
            variant={ButtonVariant.ghost}
            name={`${name}__close_button`}
            type={ButtonType.button}
            isDisabled={isDisabled}
          >
            <Icon icon="icon-close" />
          </Button>
        )}
      </Content>
    );
  };

  const Item = ({
    value,
    label,
    isChecked
  }: {
    value: string | null;
    label: string;
    isChecked: boolean;
  }) => {
    const valueItem = value ? value.toString() : generateUUID();
    return (
      <Checkbox
        isChecked={value ? isChecked : false}
        isDisabled={value === null}
        value={valueItem}
        label={label}
        name={valueItem}
        onClick={onItemSelected}
      />
    );
  };

  const errorLabel = hasError && getError();

  const hasResult = items.some((item) => item.isVisible);

  return (
    <OutsideDetector onClickOutside={() => setIsOpen(false)}>
      <Container role="dropdownMulticheck" data-populated={itemsSelected.length > 0}>
        <DropdownButton
          {...props}
          showLabel
          hasError={!isDisabled && hasError}
          isOpen={isOpen}
          label={placeholder}
          onOpened={() => setIsOpen(false)}
          onClosed={() => setIsOpen(true)}
          size={size}
        >
          {getContent()}
        </DropdownButton>

        {isOpen && !isDisabled && items.length > 0 && (
          <OptionContainer rows={items.length}>
            {showSearch && onSearch && (
              <SearchBoxContainer>
                <SearchBox
                  name={name}
                  data={data}
                  defaultValue={q}
                  onSearch={(q) => {
                    dispatch({ type: 'search', payload: { q } });
                    onSearch(q);
                  }}
                />
                <ChoicesBox
                  name={name}
                  data={data}
                  totalRows={totalRows}
                  isLoading={isLoading}
                  dataSelected={itemsSelected}
                  onSelected={(check) => {
                    dispatch({ type: 'selectAll', payload: { check } });

                    if (q) {
                      const selectedItems = items
                        .filter((item) => check && item.isVisible)
                        .map((item) => ({ ...item, isChecked: true }));

                      const isCheckAll = data.length === selectedItems.length;

                      const dataString = isCheckAll
                        ? ''
                        : selectedItems.map((item) => item.value).join(',');
                      onChange?.({
                        ids: dataString,
                        data: selectedItems,
                        isCheckAll
                      });
                    } else {
                      onChange?.({ ids: '', data: [], isCheckAll: check });
                    }
                  }}
                />
              </SearchBoxContainer>
            )}
            {!hasResult && <StateMessage>No options found</StateMessage>}
            {hasResult && (
              <OptionsContainer rows={items.length}>
                <ul>
                  {items?.map(({ value, label, isVisible }, index) => {
                    if (!isVisible) return null;

                    const checked =
                      itemsSelected.find((item: CheckboxType) => item.value === value)?.isChecked ||
                      false;
                    const config = items.length === index + 1 ? { ref: itemRef } : null;
                    const selected = checked ? 'selected' : '';

                    return (
                      <li {...config} key={value} className={`${selected}`}>
                        <Item value={value} label={label} isChecked={checked} />
                      </li>
                    );
                  })}
                </ul>
              </OptionsContainer>
            )}
          </OptionContainer>
        )}

        {isOpen && !isDisabled && items.length === 0 && (
          <OptionContainer rows={0}>
            <StateMessage>No options found</StateMessage>
          </OptionContainer>
        )}

        {typeof errorLabel === 'string' && !isDisabled && (
          <HelperText>
            <Text variant={TextVariant.small} color={TextColor.error}>
              <>{getError()}</>
            </Text>
          </HelperText>
        )}
      </Container>
    </OutsideDetector>
  );
};

DropdownMulticheck.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      isChecked: PropTypes.bool.isRequired,
      isDisabled: PropTypes.bool
    }).isRequired
  ).isRequired,
  name: PropTypes.string.isRequired,
  totalRows: PropTypes.number,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  showSearch: PropTypes.bool,
  onSearch: PropTypes.func,
  textSelectAll: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md'] as const)
};

DropdownMulticheck.defaultProps = {
  name: 'DropdownMulticheck',
  size: 'sm'
};

const AdapterDropdown = React.forwardRef((props: DropdownMulticheckProps, ref) => (
  <DropdownMulticheck {...props} />
));

export default memo(AdapterDropdown);
