import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/store';
import { Status } from 'src/ts/enums';
import { selectors } from '../resourcesSlice';
import { getAll, getOne } from '../services';

const useResources = () => {
  const dispatch = useAppDispatch();

  const getAllResponse = useSelector(selectors.getAll);
  const getOneResponse = useSelector(selectors.getOne);

  const onGetResources = () => {
    dispatch(getAll());
  };

  const onGetResource = (id?: string) => {
    if (!id) throw new Error("Is missing the user identifier to get the resource's information");
    dispatch(getOne(id));
  };

  return {
    onGetResources,
    getResources: {
      isLoading: getAllResponse.status === Status.LOADING,
      isIdle: getAllResponse.status === Status.IDLE,
      error: getAllResponse.error,
      data: getAllResponse.data || []
    },
    onGetResource,
    getResource: {
      isLoading: getOneResponse.status === Status.LOADING,
      error: getOneResponse.error,
      data: getOneResponse.data
    }
  };
};

export default useResources;
