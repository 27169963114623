// Vendor
import { createSlice } from '@reduxjs/toolkit';

// Redux
import { RootState } from 'src/store';
import { getLastReducer, initialState, markAsShownReducer } from './reducers';

// Enums
import { Status as StatusTypes } from 'src/ts/enums';

// Constants
import { NAME } from './constants';

const announcementSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetGetLast: (state) => {
      state.getLast = initialState.getLast;
    },
    resetMarkAsShown: (state) => {
      state.markAsShown = initialState.markAsShown;
    }
  },
  extraReducers: (builder) => {
    getLastReducer(builder);
    markAsShownReducer(builder);
  }
});

export const selectors = {
  getLast: {
    isLoading: (state: RootState) => state[NAME].getLast.status === StatusTypes.LOADING,
    isIdle: (state: RootState) => state[NAME].getLast.status === StatusTypes.IDLE,
    error: (state: RootState) => state[NAME].getLast.error,
    data: (state: RootState) => state[NAME].getLast.data
  },
  markAsShown: {
    isLoading: (state: RootState) => state[NAME].markAsShown.status === StatusTypes.LOADING,
    isSaved: (state: RootState) => state[NAME].markAsShown.status === StatusTypes.SUCCESS,
    error: (state: RootState) => state[NAME].markAsShown.error
  }
};

export const { reducer, actions } = announcementSlice;
