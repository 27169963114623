import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Framework } from 'src/components/templates/Framework';
import { selectors as appVersionSelectors } from 'src/features/appVersion/appVersionSlice';
import { selectors as authSelectors } from 'src/features/auth/authSlice';
import { selectors as propertySelectors } from 'src/features/property/propertySlice';
import { useFeatureFlag } from 'src/hooks';
import { FeatureFlagKey } from 'src/ts/enums';

import { getActiveRouteOptions } from 'src/router/navOptions';

type LayoutProps = {
  children: React.ReactNode;
  isWideRoute?: boolean;
};

const PUBLIC_URL = process.env.PUBLIC_URL;

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const { children, isWideRoute } = props;
  const user = useSelector(authSelectors.user);
  const appVersion = useSelector(appVersionSelectors.appVersion);
  const navigate = useNavigate();

  const isDocumentLinksTabEnabled = !user?.properties?.every((property) => property.disable_dup_fd);
  const isHelpAndResourcesUpdateEnabled = useFeatureFlag(FeatureFlagKey.HELP_AND_RESOURCES_UPDATE);

  const hasIdentityVerificationPropertyEnabled =
    useSelector(propertySelectors.hasIdentityVerificationPropertyEnabled.data) ?? false;
  const args = useMemo(
    () => ({
      version: appVersion?.frontend || 'Undefined',
      user: {
        name: user?.first_name || 'Undefined',
        logo: user?.company?.logo || `${PUBLIC_URL}/assets/images/snappt.png`
      },
      menu: getActiveRouteOptions(
        hasIdentityVerificationPropertyEnabled,
        isDocumentLinksTabEnabled,
        isHelpAndResourcesUpdateEnabled
      )
    }),
    [
      appVersion?.frontend,
      user?.first_name,
      user?.company?.logo,
      hasIdentityVerificationPropertyEnabled,
      isDocumentLinksTabEnabled,
      isHelpAndResourcesUpdateEnabled
    ]
  );

  return (
    <Framework
      menu={args.menu}
      version={args.version}
      user={args.user}
      onLogout={() => navigate('/logout')}
      isWide={isWideRoute}
    >
      {children}
    </Framework>
  );
};

export default Layout;
