// Vendor
import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Redux
import { RootState } from 'src/store';
import {
  generateApplicantLinkThunk,
  getApplicantByVerificationIdThunk,
  getApplicantsThunk,
  getFcraIdentityReportPDFThunk,
  getIdentityImagesThunk,
  getIdentityReportPDFThunk
} from './services';

// Types
import {
  IdentityApplicantsIdVerificationGetResponseExtended,
  IdentityGenerateApplicantLinkResponse,
  IdentityVerificationImagesResponse,
  IdentityVerificationResult,
  IPaginationRequest,
  IRejectedAction,
  StatusType
} from 'src/ts/interfaces';

// Constants
import { NAME } from './constants';

// Enums
import { LinkAction, Status, Status as StatusTypes } from 'src/ts/enums';

type StateData = {
  generateApplicantLinkState: {
    data: IdentityGenerateApplicantLinkResponse | null;
    status: StatusType;
    linkAction: LinkAction;
    error?: string | null;
  };
  identityApplicantState: {
    data: IdentityVerificationResult[] | null;
    count: number;
    filters: IPaginationRequest;
    status: StatusTypes;
    error?: string | null;
  };
  getApplicantByVerificationIdState: {
    status: StatusTypes;
    error?: string | null;
    data?: IdentityApplicantsIdVerificationGetResponseExtended | null;
  };
  getFcraIdentityReportPDF: {
    status: StatusTypes;
    error?: string | null;
    data?: void;
    fileName?: string;
  };
  getIdentityReportPDF: {
    status: StatusTypes;
    error?: string | null;
    data?: void;
    fileName?: string;
  };
  getIdentityImages: {
    status: StatusTypes;
    error?: string | null;
    data?: IdentityVerificationImagesResponse | null;
  };
};

const initialState: StateData = {
  generateApplicantLinkState: {
    data: null,
    status: StatusTypes.IDLE,
    linkAction: LinkAction.COPY,
    error: null
  },
  identityApplicantState: {
    data: null,
    count: 0,
    filters: {
      q: '',
      page: 0,
      rowsPerPage: 50
    },
    status: StatusTypes.IDLE,
    error: null
  },
  getApplicantByVerificationIdState: {
    status: StatusTypes.IDLE,
    data: null,
    error: null
  },
  getFcraIdentityReportPDF: {
    status: StatusTypes.IDLE,
    error: null
  },
  getIdentityReportPDF: {
    status: StatusTypes.IDLE,
    error: null
  },
  getIdentityImages: {
    status: StatusTypes.IDLE,
    data: null,
    error: null
  }
};

export const identityVerificationSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    reset: () => {
      return {
        ...initialState
      };
    },
    resetApplicantByVerificationId: (state: StateData) => {
      state.getApplicantByVerificationIdState = initialState.getApplicantByVerificationIdState;
    },
    resetGetFcraIdentityReportPDF: (state: StateData) => {
      state.getFcraIdentityReportPDF = initialState.getFcraIdentityReportPDF;
    },
    resetGetIdentityReportPDF: (state: StateData) => {
      state.getIdentityReportPDF = initialState.getIdentityReportPDF;
    },
    identityVerificationFiltering: (state: StateData, action) => {
      state.identityApplicantState.filters.filter = action.payload.filter;
      state.identityApplicantState.filters.page = action?.payload?.page;
      state.identityApplicantState.filters.rowsPerPage = action?.payload?.rowsPerPage;
    },
    identityVerificationPagination: (state: StateData, action) => {
      state.identityApplicantState.filters.page = action?.payload?.page;
      state.identityApplicantState.filters.rowsPerPage = action?.payload?.rowsPerPage;
    },
    identityVerificationSearch: (state: StateData, action) => {
      state.identityApplicantState.filters.q = action.payload.q;
      state.identityApplicantState.filters.page = action?.payload?.page;
      state.identityApplicantState.filters.rowsPerPage = action?.payload?.rowsPerPage;
    },
    identityVerificationSorting: (state: StateData, action) => {
      state.identityApplicantState.filters.sort = action.payload.sort;
      state.identityApplicantState.filters.page = action?.payload?.page;
      state.identityApplicantState.filters.rowsPerPage = action?.payload?.rowsPerPage;
    }
  },
  extraReducers: (builder) => {
    generateApplicantLinkReducer(builder);
    getIdentityApplicantsReducer(builder);
    getApplicantByVerificationIdReducer(builder);
    getFcraIdentityReportPDFReducer(builder);
    getIdentityReportPDFReducer(builder);
    getIdentityImagesReducer(builder);
  }
});

const generateApplicantLinkReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(generateApplicantLinkThunk.pending, (state: StateData) => {
    state.generateApplicantLinkState.status = StatusTypes.LOADING;
    state.generateApplicantLinkState.error = null;
  });
  builder.addCase(
    generateApplicantLinkThunk.fulfilled,
    (state: StateData, action: PayloadAction<any>) => {
      state.generateApplicantLinkState.status = StatusTypes.SUCCESS;
      state.generateApplicantLinkState.data = action.payload.data;
      state.generateApplicantLinkState.linkAction = action.payload.linkAction;
    }
  );
  builder.addCase(
    generateApplicantLinkThunk.rejected,
    (state: StateData, action: IRejectedAction) => {
      state.generateApplicantLinkState.status = StatusTypes.ERROR;
      state.generateApplicantLinkState.error = action.error?.message;
    }
  );
};

const getIdentityApplicantsReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getApplicantsThunk.pending, (state) => {
    state.identityApplicantState.status = StatusTypes.LOADING;
    state.identityApplicantState.error = null;
  });
  builder.addCase(getApplicantsThunk.fulfilled, (state, action: PayloadAction<any>) => {
    state.identityApplicantState.status = StatusTypes.SUCCESS;
    state.identityApplicantState.data = action.payload;
    state.identityApplicantState.count = action.payload?.count || 0;
  });
  builder.addCase(getApplicantsThunk.rejected, (state, action) => {
    state.identityApplicantState.status = StatusTypes.ERROR;
    state.identityApplicantState.error = action.error?.message;
  });
};

export const getApplicantByVerificationIdReducer = (
  builder: ActionReducerMapBuilder<StateData>
) => {
  builder.addCase(getApplicantByVerificationIdThunk.pending, (state: StateData) => {
    state.getApplicantByVerificationIdState.status = StatusTypes.LOADING;
    state.getApplicantByVerificationIdState.error = null;
  });
  builder.addCase(
    getApplicantByVerificationIdThunk.fulfilled,
    (state: StateData, action: PayloadAction<any>) => {
      state.getApplicantByVerificationIdState.status = StatusTypes.SUCCESS;
      state.getApplicantByVerificationIdState.data = action.payload;
    }
  );
  builder.addCase(
    getApplicantByVerificationIdThunk.rejected,
    (state: StateData, action: IRejectedAction) => {
      state.getApplicantByVerificationIdState.status = StatusTypes.ERROR;
      state.getApplicantByVerificationIdState.error = action.error?.message;
    }
  );
};

export const getFcraIdentityReportPDFReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getFcraIdentityReportPDFThunk.pending, (state: StateData) => {
    state.getFcraIdentityReportPDF.status = StatusTypes.LOADING;
    state.getFcraIdentityReportPDF.error = null;
  });
  builder.addCase(
    getFcraIdentityReportPDFThunk.rejected,
    (state: StateData, action: IRejectedAction) => {
      state.getFcraIdentityReportPDF.status = StatusTypes.ERROR;
      state.getFcraIdentityReportPDF.error = action.error?.message;
    }
  );
  builder.addCase(
    getFcraIdentityReportPDFThunk.fulfilled,
    (state: StateData, action: PayloadAction<any>) => {
      state.getFcraIdentityReportPDF.status = StatusTypes.SUCCESS;
      state.getFcraIdentityReportPDF.data = action.payload.data;
      state.getFcraIdentityReportPDF.fileName = action.payload.fileName;
    }
  );
};

export const getIdentityReportPDFReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getIdentityReportPDFThunk.pending, (state: StateData) => {
    state.getIdentityReportPDF.status = StatusTypes.LOADING;
    state.getIdentityReportPDF.error = null;
  });
  builder.addCase(
    getIdentityReportPDFThunk.rejected,
    (state: StateData, action: IRejectedAction) => {
      state.getIdentityReportPDF.status = StatusTypes.ERROR;
      state.getIdentityReportPDF.error = action.error?.message;
    }
  );
  builder.addCase(
    getIdentityReportPDFThunk.fulfilled,
    (state: StateData, action: PayloadAction<any>) => {
      state.getIdentityReportPDF.status = StatusTypes.SUCCESS;
      state.getIdentityReportPDF.data = action.payload.data;
      state.getIdentityReportPDF.fileName = action.payload.fileName;
    }
  );
};
export const getIdentityImagesReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getIdentityImagesThunk.pending, (state: StateData) => {
    state.getIdentityImages.status = StatusTypes.LOADING;
    state.getIdentityImages.error = null;
  });
  builder.addCase(getIdentityImagesThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.getIdentityImages.status = StatusTypes.ERROR;
    state.getIdentityImages.error = action.error?.message;
  });
  builder.addCase(
    getIdentityImagesThunk.fulfilled,
    (state: StateData, action: PayloadAction<any>) => {
      state.getIdentityImages.status = StatusTypes.SUCCESS;
      state.getIdentityImages.data = action.payload;
    }
  );
};

export const selectors = {
  generateApplicantLink: {
    data: (state: RootState) => state[NAME].generateApplicantLinkState.data,
    status: (state: RootState) => state[NAME].generateApplicantLinkState.status,
    linkAction: (state: RootState) => state[NAME].generateApplicantLinkState.linkAction,
    error: (state: RootState) => state[NAME].generateApplicantLinkState.error,
    isLoading: (state: RootState) =>
      state[NAME].generateApplicantLinkState.status === StatusTypes.LOADING,
    isIdle: (state: RootState) => state[NAME].generateApplicantLinkState.status === StatusTypes.IDLE
  },
  getIdentityApplicants: {
    data: (state: RootState) => state[NAME].identityApplicantState.data,
    count: (state: RootState) => state[NAME].identityApplicantState.count,
    filters: (state: RootState) => state[NAME].identityApplicantState.filters,
    status: (state: RootState) => state[NAME].identityApplicantState.status,
    isError: (state: RootState) => state[NAME].identityApplicantState.error,
    isLoading: (state: RootState) =>
      state[NAME].identityApplicantState.status === StatusTypes.LOADING,
    isIdle: (state: RootState) => state[NAME].identityApplicantState.status === StatusTypes.IDLE
  },
  getApplicantByVerificationId: {
    isLoading: (state: RootState) =>
      state[NAME].getApplicantByVerificationIdState.status === StatusTypes.LOADING,
    isIdle: (state: RootState) =>
      state[NAME].getApplicantByVerificationIdState.status === StatusTypes.IDLE,
    error: (state: RootState) => state[NAME].getApplicantByVerificationIdState.error,
    data: (state: RootState) => state[NAME].getApplicantByVerificationIdState.data
  },
  getDownloadFcraIdentityReport: {
    isLoading: (state: RootState) => state[NAME].getFcraIdentityReportPDF.status === Status.LOADING,
    error: (state: RootState) => state[NAME].getFcraIdentityReportPDF.error || null,
    file: (state: RootState) => state[NAME].getFcraIdentityReportPDF.data,
    fileName: (state: RootState) => state[NAME].getFcraIdentityReportPDF.fileName || ''
  },
  getDownloadIdentityReport: {
    isLoading: (state: RootState) => state[NAME].getIdentityReportPDF.status === Status.LOADING,
    error: (state: RootState) => state[NAME].getIdentityReportPDF.error || null,
    file: (state: RootState) => state[NAME].getIdentityReportPDF.data,
    fileName: (state: RootState) => state[NAME].getIdentityReportPDF.fileName || ''
  },
  getIdentityImages: {
    isLoading: (state: RootState) => state[NAME].getIdentityImages.status === Status.LOADING,
    error: (state: RootState) => state[NAME].getIdentityImages.error || null,
    data: (state: RootState) => state[NAME].getIdentityImages.data
  }
};

export const { reducer, actions } = identityVerificationSlice;
