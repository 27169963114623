import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Status } from 'src/ts/enums';
import { selectors } from '../index';

const useFdeInfo = () => {
  const { status, data, count, filters } = useSelector(selectors);

  const isUninitialized = status === Status.IDLE;
  const isLoading = status === Status.LOADING;
  const isError = status === Status.ERROR;
  const isSuccess = status === Status.SUCCESS;

  const memoizedFilters = useMemo(() => ({ ...filters }), [filters]);

  return {
    data,
    count,
    filters: memoizedFilters,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    isToolbarDisabled: isLoading || isError
  };
};

export default useFdeInfo;
