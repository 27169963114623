import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, isAxiosError } from 'src/api/instance';
import { NAME } from '../constants';
import api from './api';
import {
  ICreateApplicationRequest,
  ISubmitUnauthenticatedApplicationRequest,
  IUpdateApplicationRequest
} from './ts/interfaces';

export const createApplication = createAsyncThunk(
  `${NAME}/createApplication`,
  async ({ company_short_id, property_short_id, application_type }: ICreateApplicationRequest) => {
    try {
      return await api.createApplication({ company_short_id, property_short_id, application_type });
    } catch (error) {
      console.error(`${NAME}/createApplication: ${error}`);
      throw new Error('Error throw createApplication');
    }
  }
);

export const getCurrentApplication = createAsyncThunk(`${NAME}/getCurrentApplication`, async () => {
  try {
    return await api.getCurrentApplication();
  } catch (error) {
    console.error(`${NAME}/getCurrentApplication: ${error}`);
    throw new Error('Error throw getCurrentApplication');
  }
});

export const getCurrentProperty = createAsyncThunk(`${NAME}/getCurrentProperty`, async () => {
  try {
    return await api.getCurrentProperty();
  } catch (error) {
    console.error(`${NAME}/getCurrentProperty: ${error}`);
    throw new Error('Error throw getCurrentProperty');
  }
});

export const updateCurrentApplication = createAsyncThunk(
  `${NAME}/updateCurrentApplication`,
  async (data: IUpdateApplicationRequest) => {
    try {
      return await api.updateCurrentApplication(data);
    } catch (error) {
      console.error(`${NAME}/updateCurrentApplication: ${error}`);
      throw new Error('Error throw updateCurrentApplication');
    }
  }
);

export const submitApplication = createAsyncThunk(
  `${NAME}/submitApplication`,
  async (data: ISubmitUnauthenticatedApplicationRequest, thunkApi) => {
    try {
      return await api.submitApplication(data);
    } catch (error) {
      console.error(`${NAME}/submitApplication: ${error}`);
      if (isAxiosError(error)) {
        return thunkApi.rejectWithValue(error as AxiosError);
      }
      throw error;
    }
  }
);
