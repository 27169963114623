import copy from 'copy-to-clipboard';
import { useState } from 'react';

import { VariantType } from 'src/components/molecules/SnackbarProvider';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';

type ValueToCopy = string | null;
type CopyToClipboardFn = (
  text: string,
  successText?: string,
  errorText?: string,
  successSnackbarVariant?: VariantType
) => boolean;

const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const useCopyToClipboard = () => {
  const [copiedText, setCopiedText] = useState<ValueToCopy>(null);
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();

  const copyToClipboard: CopyToClipboardFn = (
    text,
    successText = 'Link copied to clipboard',
    errorText = 'Error when copying text',
    successSnackbarVariant = VariantType.default
  ) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard is not supported in this browser');
      showSnackbar(
        VariantType.error,
        'Copying tool is not supported in this browser',
        SnackTypes.none
      );
      return false;
    }
    try {
      copy(text);
      setCopiedText(text);
      if (!isSafari()) {
        showSnackbar(successSnackbarVariant, successText, SnackTypes.none);
      }
      return true;
    } catch (error) {
      console.error('Copy to clipboard failed', error);
      setCopiedText(null);
      showSnackbar(VariantType.error, errorText, SnackTypes.none);
      return false;
    }
  };

  return { copiedText, copy: copyToClipboard };
};

export default useCopyToClipboard;
