import { useState } from 'react';
import Button, {
  ButtonDisabledStyle,
  ButtonSize,
  ButtonVariant
} from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { ToolTip } from 'src/components/atoms/ToolTip';
import { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import { HistoryModal } from './HistoryModal';

type ButtonHistoryModalProps = {
  isDisabled: boolean;
  entryId: string;
};

const ButtonHistoryModal: React.FC<ButtonHistoryModalProps> = ({
  isDisabled,
  entryId
}: ButtonHistoryModalProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ToolTip direction={ToolTipDirection.top} content="Report History">
        <Button
          onClick={() => setShowModal(true)}
          name="ReportHistory_button"
          variant={ButtonVariant.ghost}
          size={ButtonSize.medium}
          isDisabled={isDisabled}
          disabledStyle={ButtonDisabledStyle.transparent}
        >
          <Icon icon="time-history-icon" color="primary" />
        </Button>
      </ToolTip>
      {showModal && (
        <HistoryModal
          title="Report History"
          entryId={entryId}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};
export default ButtonHistoryModal;
