import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { NAME } from './constants';
import { settingPayload } from './ts/interface';

export const getSetting = createAsyncThunk(`${NAME}/get`, async (setting: string) => {
  try {
    return await api.getSetting(setting);
  } catch (error) {
    console.error(`${NAME}/get`, error);
    throw new Error('Error fetching the settings');
  }
});

export const updateSetting = createAsyncThunk(`${NAME}/update`, async (payload: settingPayload) => {
  try {
    const { settingName, settingRequest } = payload;
    return await api.updateSetting(settingName, settingRequest);
  } catch (error) {
    console.error(`${NAME}/update`, error);
    throw new Error('Error updating settings');
  }
});
