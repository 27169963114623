import PropTypes from 'prop-types';
import Gap from 'src/components/atoms/Gap';
import { Line } from 'src/components/atoms/Line';
import Grid from '../../atoms/Grid';
import SkeletonPiece, { SkeletonPieceColor } from '../../atoms/SkeletonPiece';
import { StyledRow, StyledContainer } from './styles';

export type DocumentLinkLoaderProps = {
  rows?: number;
};

const DocumentLinkLoader: React.FC<DocumentLinkLoaderProps> = (props: DocumentLinkLoaderProps) => {
  const { rows } = props;
  return (
    <StyledContainer id="documentLinkLoader">
      {Array(rows)
        .fill(0)
        .map((_, i) => {
          return (
            <div key={i} id="doc-links">
              <Line height="tiny" />
              <StyledRow>
                <Grid direction="column">
                  <SkeletonPiece width={185} height={20} color={SkeletonPieceColor.gray200} />
                  <Gap height={0.5} />
                  <SkeletonPiece width={339} height={15} color={SkeletonPieceColor.gray50} />
                  <Gap height={0.9375} />
                  <Grid>
                    <Grid>
                      <SkeletonPiece
                        className="small-margin"
                        width={127}
                        height={36}
                        color={SkeletonPieceColor.gray50}
                      />
                      <SkeletonPiece width={50} height={36} color={SkeletonPieceColor.gray200} />
                    </Grid>
                    <Grid>
                      <SkeletonPiece
                        className="small-margin"
                        width={127}
                        height={36}
                        color={SkeletonPieceColor.gray50}
                      />
                      <SkeletonPiece width={50} height={36} color={SkeletonPieceColor.gray200} />
                    </Grid>
                  </Grid>
                </Grid>
              </StyledRow>
            </div>
          );
        })}
    </StyledContainer>
  );
};
DocumentLinkLoader.propTypes = {
  rows: PropTypes.number
};

DocumentLinkLoader.defaultProps = {
  rows: 10
};

export default DocumentLinkLoader;
