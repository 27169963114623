export const NAME = 'entriesLogs';

export const defaultValues = {
  filterPanel: {
    startDate: '',
    endDate: '',
    radioGroup: [],
    checkboxGroup: [
      {
        value: 'CLEAN',
        label: 'Clean',
        isChecked: false
      },
      {
        value: 'CLEAN_PROCEED_WITH_CAUTION',
        label: 'Clean - Proceed with Caution',
        isChecked: false
      },
      {
        value: 'EDITED',
        label: 'Edited',
        isChecked: false
      },
      {
        value: 'UNDETERMINED',
        label: 'Insufficient Documentation',
        isChecked: false
      }
    ]
  }
};

export const filterQueryOptions: Record<string, { field: string; operator: string }> = {
  results: {
    field: 'entry_result',
    operator: '@>'
  },
  startDate: {
    field: 'inserted_at',
    operator: '>'
  },
  endDate: {
    field: 'inserted_at',
    operator: '<'
  }
};

export const selectedSort = {
  field: undefined,
  order: 'asc'
};

export const selectedFilter = {
  results: undefined,
  status: undefined,
  startDate: undefined,
  endDate: undefined
};
