import {
  IncomeCalculationErrorResponse,
  IncomeCalculationStatusDetail,
  IncomeCalculationWarningResponseResults
} from '@snapptinc/fraud-platform';

export type ReasonCodes =
  | IncomeCalculationErrorResponse['reason_codes']
  | IncomeCalculationWarningResponseResults['reason_codes'];

type IconColors = 'black' | 'yellow';

const getIconColor = (
  statusDetails: IncomeCalculationStatusDetail[],
  codes: number[]
): IconColors => {
  if (!statusDetails || statusDetails.length === 0) {
    return 'black';
  }

  if (statusDetails.some((reason) => codes.includes(reason?.code))) {
    return 'yellow';
  }
  return 'black';
};

export const getCalendarIconColor = (statusDetails: IncomeCalculationStatusDetail[]) =>
  getIconColor(statusDetails, [2006, 3004, 3005, 3007]);

export const getPersonIconColor = (statusDetails: IncomeCalculationStatusDetail[]) =>
  getIconColor(statusDetails, [3002, 3003]);

export const statusCodeExists = (
  statusDetails: IncomeCalculationStatusDetail[],
  codes: number[]
) => {
  return statusDetails?.some((statusDetail) => codes.includes(statusDetail.code));
};

export const dateCodeExists = (statusDetails: IncomeCalculationStatusDetail[]) =>
  statusCodeExists(statusDetails, [2006, 3004, 3005, 3007]);

export const recipientNameCodeExists = (statusDetails: IncomeCalculationStatusDetail[]) =>
  statusCodeExists(statusDetails, [3002, 3003]);
