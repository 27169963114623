import { propertyApi } from 'src/api';
import {
  deleteInvitationRequest,
  deleteUserRequest,
  getPaginationRequest,
  idRequest,
  IPropertiesResponseWithPage,
  IPropertyAgentsRequest,
  IPropertyAgentsResponse,
  IPropertyRequest,
  IPropertyResponse,
  IPropertyStatusChangeRequest,
  ISaveFileRequest,
  updateByIdRequest
} from 'src/ts/interfaces';
import { IPropertyGetPresignedUrlResponse } from '../property/ts/interfaces';

const api = {
  getAll: async (payload: getPaginationRequest) => {
    const { page, rowsPerPage, filter, sort, q } = payload;
    const pageNum = page?.toString();
    const rowsPerPageNum = rowsPerPage?.toString();
    const response = await propertyApi.getProperties(pageNum, rowsPerPageNum, filter, sort, q);
    return response as unknown as IPropertiesResponseWithPage;
  },

  getPropertiesSearch: async (payload: getPaginationRequest) => {
    const { page, rowsPerPage, filter, sort, q } = payload;
    const pageNum = page?.toString();
    const rowsPerPageNum = rowsPerPage?.toString();
    const response = await propertyApi.getPropertiesSearch(
      { filter, q, sort },
      pageNum,
      rowsPerPageNum
    );
    return response as unknown as IPropertiesResponseWithPage;
  },

  getIdentityVerificationPropertyEnabled: async () => {
    return await propertyApi.getIDVEnabled();
  },

  getById(id: string) {
    return propertyApi.getProperty(id);
  },

  getPropertyLogoURL: async (id: string) => {
    const response = await propertyApi.getPropertyLogoUrl(id || '');
    return { ...(response.data || {}) } as IPropertyGetPresignedUrlResponse;
  },

  setPropertyLogoURL: async (id: string) => {
    const response = await propertyApi.setPropertyLogoUrl(id || '');
    if (!response) {
      return {};
    }
    console.error(`getPropertyURL: ${response}`);
    throw new Error('Error setting the property url');
  },
  create: async (payload: IPropertyRequest) => {
    const response = await propertyApi.createProperty(payload);
    return response as unknown as IPropertyResponse;
  },
  update(payload: updateByIdRequest<IPropertyRequest>) {
    const { id, request } = payload;
    const response = propertyApi.updateProperty(id, request);
    return response as unknown as IPropertyResponse;
  },
  updateStatus(payload: updateByIdRequest<IPropertyStatusChangeRequest>) {
    const { id, request } = payload;
    const response = propertyApi.updatePropertyStatus(id, request as IPropertyStatusChangeRequest);
    return response as unknown as IPropertyResponse;
  },
  delete(payload: idRequest) {
    const { id, options } = payload;
    return propertyApi.deleteProperty(id, options);
  },
  getAgents: async (id: string) => {
    const response = await propertyApi.getPropertyAgents(id);
    return response as unknown as IPropertyAgentsResponse;
  },
  createPropertyAgents: async (id: string, payload: IPropertyAgentsRequest) => {
    return await propertyApi.createPropertyAgents(id, payload);
  },
  deletePropertyAgents(payload: idRequest) {
    const { id, options } = payload;
    return propertyApi.deletePropertyAgents(id, options);
  },
  deleteUserRelationship(payload: deleteUserRequest) {
    const { id, userId, options } = payload;
    return propertyApi.deleteUserRelationship(id, userId, options);
  },
  deleteInvitationRelationship(payload: deleteInvitationRequest) {
    const { id, invitationId, options } = payload;
    return propertyApi.deleteInvitationRelationship(id, invitationId, options);
  },
  savePropertyFile: async ({ method, url, fields, type, file }: ISaveFileRequest) => {
    try {
      if (!file) throw new Error('File was not given or file was undefined');

      let body: FormData | File = file;
      if (method === 'POST') {
        body = new FormData();
        body.append('Content-Type', type);
        Object.entries(fields).forEach(([key, value]) => {
          (body as FormData).append(key, value);
        });
        body.append('file', file);
      }

      const response = await fetch(url, {
        method: method,
        body: body
      });

      if (response?.ok && response.status >= 200 && response.status < 300) {
        return response;
      }

      console.error(`savePropertyFile: ${response}`);
      throw new Error(response.statusText);
    } catch (e) {
      console.error(e);
      throw new Error(
        'Something went wrong trying to upload the file. Please try again or contact Support.'
      );
    }
  }
};

export default api;
