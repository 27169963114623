// Vendor
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

// Components
import { Loader } from 'src/components/atoms/Loader';
import {
  FormValues,
  InvitationDetailsForm
} from 'src/components/molecules/Invitation/InvitationDetailsForm';
import { InvitationErrorMessage } from 'src/components/molecules/Invitation/InvitationErrorMessage';
import { Invitation } from 'src/components/templates';

// Redux
import { actions as authActions } from 'src/features/auth/authSlice';
import { invitationCreateUserThunk, useInvitation } from 'src/features/invitation';
import { useAppDispatch } from 'src/store';

// Constants
import { ROLE_DESCRIPTION } from 'src/features/auth/constants';

const InvitationPage: FunctionComponent = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { data, isError, isLoading } = useInvitation(id);

  const defaultValues = {
    firstName: data?.first_name ?? '',
    lastName: data?.last_name ?? '',
    role: data?.role ? ROLE_DESCRIPTION[data.role] : '',
    email: data?.email ?? '',
    password: '',
    confirmPassword: ''
  };

  const handleSubmit = ({ password }: Pick<FormValues, 'password'>) => {
    if (id) {
      dispatch(invitationCreateUserThunk({ id, input: { password } }));
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Invitation>
      {isError ? (
        <InvitationErrorMessage onClick={() => dispatch(authActions.login())} />
      ) : (
        <InvitationDetailsForm defaultValues={defaultValues} onSubmit={handleSubmit} />
      )}
    </Invitation>
  );
};

export default InvitationPage;
