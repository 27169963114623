import { useParams } from 'react-router-dom';

import { FolderTile, FolderTileLoader } from 'src/components/folders';
import { useFolderById } from 'src/features/folders/hooks';

export const FolderTileContainer = () => {
  const { folderId } = useParams();
  const { folder, isLoading } = useFolderById(folderId as string);

  if (isLoading) {
    return <FolderTileLoader />;
  }

  // TODO: Handle folder error
  if (!folder) {
    return null;
  }

  return <FolderTile folder={folder} />;
};
