// Vendor
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

// Redux
import { actions, selectors as companySelectors } from 'src/features/company/companySlice';
import { useAppDispatch } from 'src/store';
import { getCompaniesDDown } from '../services';

// Enums
import { Status as StatusEnum } from 'src/ts/enums';

// Types
import { getPaginationRequest } from 'src/ts/interfaces';

const useCompaniesDDown = () => {
  const dispatch = useAppDispatch();
  const getCompaniesDDownResponse = useSelector(companySelectors.getCompaniesDDown);

  const onGetCompaniesDDown = useCallback(
    (args: getPaginationRequest) => {
      dispatch(getCompaniesDDown(args));
    },
    [dispatch]
  );

  const onResetGetCompaniesDDown = () => {
    dispatch(actions.resetGetCompaniesDDown());
  };

  return {
    onGetCompaniesDDown,
    onResetGetCompaniesDDown,
    getCompaniesDDown: {
      isLoading: getCompaniesDDownResponse.status === StatusEnum.LOADING,
      isIdle: getCompaniesDDownResponse.status === StatusEnum.IDLE,
      isError: getCompaniesDDownResponse.status === StatusEnum.ERROR,
      error: getCompaniesDDownResponse.error,
      data: getCompaniesDDownResponse.data || [],
      filter: getCompaniesDDownResponse.filter,
      count: getCompaniesDDownResponse.count || 0
    }
  };
};

export default useCompaniesDDown;
