import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { NAME } from './constants';
import {
  createApplicationReducer,
  getCurrentApplicationReducer,
  getCurrentPropertyReducer,
  initialStateForm,
  StateDataForm,
  submitApplicationReducer,
  updateApplicationReducer
} from './form/reducers';
import { IApplication, IInformation } from './form/ts/interfaces';
import {
  changeProofTypeReducer,
  createProofPreURLReducer,
  getProofsReducer,
  initialStateProofs,
  removeProofReducer,
  reUploadProofReducer,
  StateDataProofs
} from './proofs/reducers';
import { IProofDUP } from './proofs/ts/interfaces';
import { DupTypes } from './ts/enums';

const initialState = {
  type: initialStateForm.type,
  information: initialStateForm.information,
  process: {
    ...initialStateForm.process,
    ...initialStateProofs.process
  }
};

const DUPSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setType: (state: StateDataForm, action: PayloadAction<DupTypes>) => {
      state.type = action.payload;
    },
    setInformation: (state: StateDataForm, action: PayloadAction<IInformation>) => {
      state.information = action.payload;
    },
    setFormApplication: (state: StateDataForm, action: PayloadAction<IApplication>) => {
      Object.assign(state.process.getCurrentApplication.data, action.payload);
    },
    setFormProperty: (state: StateDataForm, action) => {
      Object.assign(state.process.getCurrentProperty.data, action.payload);
    },
    addProofFailed: (state: StateDataProofs, action) => {
      state.process.getProofs.data.push(action.payload);
    },
    addProofTemp: (state: StateDataProofs, action) => {
      state.process.getProofs.data.push(action.payload);
    },
    removeProof: (state: StateDataProofs, action) => {
      const proofsUpdated = state.process.getProofs.data.filter(
        (proof) => proof.id !== action.payload
      );
      state.process.getProofs.data = proofsUpdated;
    },
    changingProofType: (state: StateDataProofs, action) => {
      const proofUpdated = state.process.getProofs.data.map((item) =>
        item.id === action.payload ? { ...item, type: 'changing...' } : item
      );
      state.process.getProofs.data = proofUpdated as IProofDUP[];
    },
    reUploadProofTemp: (state: StateDataProofs, action) => {
      const proofUpdated = state.process.getProofs.data.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      state.process.getProofs.data = proofUpdated;
    },
    reUploadProofFailed: (state: StateDataProofs, action) => {
      const proofUpdated = state.process.getProofs.data.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      state.process.getProofs.data = proofUpdated;
    },
    removeSubmit: (state: StateDataForm) => {
      state.process.submitApplication = initialState.process.submitApplication;
    }
  },
  extraReducers: (builder) => {
    createApplicationReducer(builder);
    getCurrentApplicationReducer(builder);
    getCurrentPropertyReducer(builder);
    updateApplicationReducer(builder);
    submitApplicationReducer(builder);
    getProofsReducer(builder);
    createProofPreURLReducer(builder);
    removeProofReducer(builder);
    changeProofTypeReducer(builder);
    reUploadProofReducer(builder);
  }
});

export const selectors = {
  type: (state: RootState) => state.DUP.type,
  information: (state: RootState) => state.DUP.information,
  createApplication: (state: RootState) => state.DUP.process.createApplication,
  getCurrentApplication: (state: RootState) => state.DUP.process.getCurrentApplication,
  getCurrentProperty: (state: RootState) => state.DUP.process.getCurrentProperty,
  updateApplication: (state: RootState) => state.DUP.process.updateCurrentApplication,
  submitApplication: (state: RootState) => state.DUP.process.submitApplication,
  getProofs: (state: RootState) => state.DUP.process.getProofs,
  createProofPreURL: (state: RootState) => state.DUP.process.createProofPreURL,
  removeProof: (state: RootState) => state.DUP.process.removeProof,
  changeProofType: (state: RootState) => state.DUP.process.changeProofType,
  reUploadProof: (state: RootState) => state.DUP.process.reUploadProof
};

export const { reducer, actions } = DUPSlice;
