import { FC, HTMLAttributes } from 'react';
import { StyledTableContainer } from './styles';

type TableContainerProps = HTMLAttributes<HTMLDivElement>;

const TableContainer: FC<TableContainerProps> = (props) => {
  return <StyledTableContainer {...props} />;
};

export default TableContainer;
