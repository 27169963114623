/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ViewSDKClient from 'src/pdfViewer/ViewSDKClient';

// Components
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import { Loader } from 'src/components/atoms/Loader';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { MetadataModal } from 'src/components/folders/MetadataModal';
import { DetailView } from 'src/components/templates/DetailView';
import NotesModal, {
  IFormValues as IFormValuesNotes
} from 'src/pages/ReviewPage/components/NotesModal';
import AsideProofActions, { IFormValues as IFormValuesAside } from './components/AsideProofActions';

// Hooks
import { useEntryById } from 'src/features/entries/hooks';
import { useFolderById } from 'src/features/folders/hooks';
import { useEntryAssignedToAnother } from 'src/features/myWork/hooks/useEntryAssignedToAnother';
import {
  useProofGetNote,
  useProofsFilteredByEntryId,
  useProofUpdateAside,
  useProofUpdateNote
} from 'src/features/proofs/hooks';
import { useScript } from 'src/hooks/useScript';

// Helpers
import {
  getProofSuggestedRulingIcon,
  transformProofSuggestedRulingToReadable
} from 'src/features/fdeInbox/helpers';
import { cleanFileName, configVars } from 'src/helpers';

// Redux
import { getProofTextBreakupThunk } from 'src/features/proofs';
import { selectors as proofSelectors } from 'src/features/proofs/proofsSlice';
import { RootState } from 'src/store';

// Types
import { EntryReviewStatusEnum } from 'src/ts/enums';

const MyWorkProofDetailPage: React.FC = () => {
  useScript('https://documentservices.adobe.com/view-sdk/viewer.js');

  const { folderId, entryId, proofId } = useParams();
  const dispatch = useDispatch();

  const { folder, isSuccess: isSuccessFolder } = useFolderById(folderId as string);
  const { entry, isSuccess: isSuccessEntry } = useEntryById(entryId as string);
  const { checkIsAssignedToAnotherUser } = useEntryAssignedToAnother();
  const {
    proofs,
    isLoading: isLoadingProofs,
    isUninitialized: isIdleProofs
  } = useProofsFilteredByEntryId(entryId as string);
  const proof = useSelector((state: RootState) => proofSelectors.selectById(state, proofId || ''));

  const { onUpdateProof } = useProofUpdateAside();
  const { onUpdateNote } = useProofUpdateNote();
  const { onGetNote } = useProofGetNote();

  const [showModalNotes, setShowModalNotes] = useState(false);
  const [showModalMetadata, setShowModalMetadata] = useState(false);

  const isAssignedToAnotherUser = checkIsAssignedToAnotherUser(entry?.review_assigned_to_id);
  const entryIsNotAssigned = entry?.review_status !== EntryReviewStatusEnum.Assigned;

  const onGeneratePDF = (
    file?: string,
    fullName = '',
    fileName = '',
    idContainer = '',
    embedMode = 'SIZED_CONTAINER'
  ) => {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(
        idContainer,
        {
          embedMode
        },
        file,
        cleanFileName(fullName + '_' + fileName),
        proofId,
        configVars.adobe.pdf_token
      );
    });
  };

  useEffect(() => {
    if (folder && entry && proofs && proof) {
      onGeneratePDF(
        proof.file,
        entry?.applicant.full_name,
        proof.fileName,
        'pdf-viewer-container',
        'SIZED_CONTAINER'
      );
    }
  }, [folder, entry, proofs, proof]);

  const onSaveNote = (dataForm: IFormValuesNotes) => {
    onUpdateNote(proof?.id, dataForm.note);
  };

  const onSave = (dataForm: IFormValuesAside) => {
    onUpdateProof(proof?.id, dataForm);
  };

  if (!isSuccessFolder || !isSuccessEntry || isLoadingProofs || isIdleProofs || !proof?.fileName)
    return <Loader />;

  const SuggestedRuling = () => {
    if (!proof?.suggested_ruling) return null;

    return (
      <Row gap={0.5}>
        <Text variant={TextVariant.small} color={TextColor.gray700}>
          Steve Suggested Ruling:
        </Text>

        <Row gap={0.5}>
          <Text variant={TextVariant.small} color={TextColor.gray700} className="underline" isBold>
            {transformProofSuggestedRulingToReadable(proof?.suggested_ruling)}
          </Text>
          <Icon icon={getProofSuggestedRulingIcon(proof?.suggested_ruling)} />
        </Row>
      </Row>
    );
  };

  const handleOnShowMetadata = () => {
    if (!proof.text_breakups) {
      dispatch(
        getProofTextBreakupThunk({
          id: proof.id,
          text_breakups_file: proof.text_breakups_file
        })
      );
    }
    setShowModalMetadata(true);
  };

  return (
    <DetailView
      back={{ to: `/my-work/${folderId}`, label: 'Go Back' }}
      title={proof?.fileName}
      rightComponent={<SuggestedRuling />}
    >
      <Row direction="column" gap={1}>
        <Row gap={0.5}>
          <Icon icon="folder-icon-grey" color="muted" />
          <Text variant={TextVariant.span} color={TextColor.gray700}>
            {folder?.name}
          </Text>
        </Row>
        <Row gap={0.5}>
          <Icon icon="company-model-icon" color="muted" />
          <Text variant={TextVariant.span} color={TextColor.gray700}>
            {folder?.property?.name}
          </Text>
        </Row>
      </Row>
      <Gap height={1} />
      <Row gap={1}>
        <Row.Col size={8}>
          <div id="pdf-viewer-container" style={{ height: '100vh' }} />
          <div id="pdf-viewer-fullscreen" />
        </Row.Col>
        <Row.Col size={4}>
          <AsideProofActions
            proof={proof}
            fileName={proof?.fileName}
            onFullscreen={() => {
              onGeneratePDF(
                proof?.file,
                entry?.applicant.full_name,
                proof?.fileName,
                'pdf-viewer-fullscreen',
                'LIGHT_BOX'
              );
            }}
            showMetadata
            isDisabledShowMetadata={false}
            onShowMetadata={() => handleOnShowMetadata()}
            showNotes
            onShowNotes={() => {
              setShowModalNotes(true);
            }}
            isDisabledShowNotes={isAssignedToAnotherUser}
            showDownload
            isDisabledShowDownload={false}
            isDisabled={isAssignedToAnotherUser || entryIsNotAssigned}
            onSave={onSave}
            isDisabledOnSave={isAssignedToAnotherUser}
          />
        </Row.Col>
      </Row>
      {showModalMetadata && proof && (
        <MetadataModal
          title="Document Extractions"
          labelExtractMetadata="Document Metadata"
          labelAutomatedTest="Steve Automated Tests"
          showTextInsertion={true}
          showModal={showModalMetadata}
          setShowModal={setShowModalMetadata}
          proof={proof}
          extracted_meta={proof?.extracted_meta}
          test_meta_data_flags={proof?.test_meta_data_flags}
        />
      )}
      {showModalNotes && proof && (
        <NotesModal
          title="Public Document Notes"
          showModal={showModalNotes}
          setShowModal={setShowModalNotes}
          onSaveNote={onSaveNote}
          defaultNote={onGetNote(proof.result, proof?.note) || ''}
        />
      )}
    </DetailView>
  );
};

export default MyWorkProofDetailPage;
