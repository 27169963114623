// Vendor
import React, { useEffect, useState } from 'react';

// Components
import { Checkbox } from 'src/components/atoms/Checkbox';
import Table from 'src/components/atoms/Table';
import TableBody from 'src/components/atoms/TableBody';
import TableCell from 'src/components/atoms/TableCell';
import TableHead from 'src/components/atoms/TableHead';
import TableRow from 'src/components/atoms/TableRow';
import Modal, { ModalVariant, ModalWidth } from 'src/components/organisms/Modal';
import { StyledTableContainer } from './styles';

// Hooks
import useProperty from 'src/features/property/hooks/useProperty';

// Helpers
import { displayRole } from 'src/helpers';

// Types
import {
  AgentWithChecked,
  IProperty,
  IPropertyAgentsRequest,
  IPropertyId
} from 'src/ts/interfaces';

// Constants
import { unassignedAgentsColumns } from '../constants';

export type AssignUsersModalProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  unassignedAgentsList: AgentWithChecked[];
  propertyId?: string;
  shortId?: string;
  isAgentsLoading: boolean;
};

const AssignUsersModal: React.FC<AssignUsersModalProps> = (props) => {
  const { showModal, setShowModal, unassignedAgentsList, propertyId, shortId, isAgentsLoading } =
    props;
  const [list, setList] = useState<AgentWithChecked[]>(unassignedAgentsList);
  const { onCreatePropertyAgents } = useProperty();
  const [listSelected, setListSelected] = useState<AgentWithChecked[]>([]);
  const [selected, setSelected] = useState<AgentWithChecked | null>();

  useEffect(() => {
    // map through original agents list and add `isChecked` property to agent item
    // if it belongs to listSelected
    const listWithCheckedItems = list?.map((agent) => {
      return {
        ...agent,
        isChecked: listSelected.findIndex((agentSelected) => agentSelected.id === agent.id) >= 0
      };
    });
    setList(listWithCheckedItems);
  }, [list, listSelected]);

  useEffect(() => {
    setList(unassignedAgentsList);
    if (listSelected.length) setList(list);
  }, [listSelected, unassignedAgentsList, list]);

  useEffect(() => {
    if (selected) {
      const data = list?.map((item) => {
        if (item?.id === selected.id) {
          return { ...item, isChecked: selected.isChecked } as AgentWithChecked;
        }
        return item as AgentWithChecked;
      });
      setList(data);
      setSelected(null);
      onSetItemsSelected(data);
    }
  }, [selected, list]);

  const onSetItemsSelected = (data: AgentWithChecked[]) => {
    const selectedData = data.filter((item) => {
      return item.isChecked;
    });
    setListSelected(selectedData);
  };

  const createAgentsList = () => {
    const payload = listSelected.reduce(
      // TODO: Refactor types. ListSelected is used for the checkboxes,
      // The problem lies in the checkbox type and API request type (accumulator and current value have different type)
      (acc: IPropertyAgentsRequest, agent) => {
        //TODO: solve the hack below: https://opticpwr.atlassian.net/browse/SNPT-1233
        // Users and invitations are merged into the same list on a property
        // so this is a hack to check its type
        const isInvitation = Object.keys(agent).includes('skip_email_sending');
        if (isInvitation) {
          return {
            ...acc,
            agents: {
              invitations: [
                ...(acc.agents?.invitations || []),
                { id: agent.id, property_short_id: shortId }
              ],
              users: [...(acc.agents?.users || [])]
            }
          } as IPropertyAgentsRequest;
        } else {
          return {
            ...acc,
            agents: {
              invitations: [...(acc.agents?.invitations || [])],
              users: [...(acc.agents?.users || []), { id: agent.id, property_short_id: shortId }]
            }
          } as IPropertyAgentsRequest;
        }
      },
      { agents: { invitations: [], users: [] } }
    );
    onCreatePropertyAgents(propertyId, payload);
    setListSelected([]);
  };

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title="Unassigned Company Users"
      labelButtonCancel="Cancel"
      labelButtonConfirm={isAgentsLoading ? 'Saving' : 'Save'}
      variant={ModalVariant.primary}
      width={ModalWidth.extraLarge}
      onConfirm={createAgentsList}
      onCancel={() => setListSelected([])}
      isLoadingButtonConfirm={isAgentsLoading}
      isDisabledButtonConfirm={isAgentsLoading || listSelected.length === 0}
      isDisabledButtonCancel={isAgentsLoading}
    >
      <StyledTableContainer>
        <div id="scrollable-container">
          <Table>
            <TableHead id="header">
              <TableRow>
                {unassignedAgentsColumns.map(({ label }, i) => (
                  <TableCell key={i}>
                    {label === 'checkbox' ? (
                      <Checkbox
                        key={`selectAll_${listSelected.length}`}
                        name="selectAll"
                        value=""
                        label=""
                        isNull={listSelected.length > 0 && listSelected.length < list?.length}
                        isChecked={listSelected.length === list?.length}
                        onClick={(obj) => {
                          const data = list?.map((item) => {
                            return { ...item, isChecked: obj.isChecked };
                          });
                          setList(data);
                          onSetItemsSelected(data);
                        }}
                      />
                    ) : (
                      label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {list?.map((agent, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        <Checkbox
                          key={`Checkbox_${listSelected?.length}`}
                          name={`checkbox.${agent?.id}`}
                          onClick={(obj) => {
                            setSelected({ ...agent, isChecked: obj.isChecked });
                          }}
                          value={agent?.id || ''}
                          showLabel={false}
                          label={agent?.first_name || ''}
                          isChecked={agent?.isChecked || false}
                        />
                      </TableCell>
                      <TableCell>
                        {agent?.first_name} {agent?.last_name}
                      </TableCell>
                      <TableCell>{agent?.email}</TableCell>
                      <TableCell>
                        {agent?.properties.map((property: IPropertyId | IProperty, i: number) => {
                          return (
                            <span key={i}>
                              {property?.name}
                              <br />
                            </span>
                          );
                        })}
                      </TableCell>
                      <TableCell>{displayRole(agent?.role)}</TableCell>
                    </TableRow>
                  );
                })}
              </>
            </TableBody>
          </Table>
        </div>
      </StyledTableContainer>
    </Modal>
  );
};

export default AssignUsersModal;
