import PropTypes from 'prop-types';
import Gap from 'src/components/atoms/Gap';
import SkeletonPiece, { SkeletonPieceColor } from '../../atoms/SkeletonPiece';

export type InstructionLoaderProps = {
  rows?: number;
};

const InstructionLoader: React.FC<InstructionLoaderProps> = (props: InstructionLoaderProps) => {
  const { rows } = props;
  return (
    <div id="instructionLoader">
      {Array(rows)
        .fill(0)
        .map((_, i) => {
          return (
            <div key={i} id="instructions">
              <Gap height={0.5} />
              <SkeletonPiece width={315} height={15} color={SkeletonPieceColor.gray50} />{' '}
              <Gap height={0.5} />
              <SkeletonPiece width={270} height={15} color={SkeletonPieceColor.gray50} />
            </div>
          );
        })}
    </div>
  );
};
InstructionLoader.propTypes = {
  rows: PropTypes.number
};

InstructionLoader.defaultProps = {
  rows: 5
};

export default InstructionLoader;
