import { CheckboxType } from 'src/components/atoms/Checkbox/types';

export type DDownMulticheckType = {
  items: CheckboxType[] | [];
  itemsSelected: CheckboxType[] | [];
  q: string;
  checkAll?: boolean;
};

export const initialState: DDownMulticheckType = {
  items: [],
  itemsSelected: [],
  q: '',
  checkAll: false
};

export type ActionType =
  | { type: 'load'; payload: Omit<DDownMulticheckType, 'itemsSelected'> }
  | { type: 'selected'; payload: { item: CheckboxType } }
  | { type: 'selectAll'; payload: { check: boolean } }
  | { type: 'remove'; payload: { value: string } }
  | { type: 'removeAll' }
  | { type: 'search'; payload: { q: string } };

const search = (q: string, where: string) => {
  const re = new RegExp(q, 'ig');
  return where.search(re) !== -1;
};

export const DDownMulticheckReducer = (state: DDownMulticheckType, action: ActionType) => {
  switch (action.type) {
    case 'load': {
      const { items } = action.payload;
      const updatedItems = items.map((item) => {
        const isVisible = search(state.q, item.label);
        return {
          ...item,
          isVisible,
          isChecked: state.checkAll || state.itemsSelected.some((k) => k.value === item.value)
        };
      });

      return {
        ...state,
        items: updatedItems,
        itemsSelected: updatedItems.filter((item) => item.isChecked),
        checkAll:
          items.length > 0 &&
          state.itemsSelected.length > 0 &&
          state.itemsSelected.length === items.length,
        q: state.q || ''
      };
    }
    case 'selected': {
      if (action.payload.item.isChecked) {
        const items = [...state.itemsSelected, action.payload.item];
        return {
          ...state,
          checkAll: items.length === state.items.length,
          itemsSelected: items
        };
      }
      return {
        ...state,
        checkAll: false,
        itemsSelected: state.itemsSelected.filter(
          (item) => item.value !== action.payload.item.value
        )
      };
    }
    case 'remove':
      return {
        ...state,
        checkAll: false,
        itemsSelected: state.itemsSelected.filter((item) => item.value !== action.payload.value)
      };
    case 'removeAll':
      return {
        ...state,
        checkAll: false,
        itemsSelected: []
      };
    case 'selectAll': {
      const isChecked = action.payload.check;
      return {
        ...state,
        checkAll: isChecked,
        itemsSelected: state.items
          .filter((item) => isChecked && state.q && item.isVisible)
          .map((item) => ({ ...item, isChecked: true }))
      };
    }
    case 'search':
      return {
        ...state,
        q: action.payload.q,
        checkAll: false,
        items: state.items.map((item) => {
          return { ...item, isVisible: search(action.payload.q, item.label) };
        })
      };
    default:
      return state;
  }
};
