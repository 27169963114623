import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { NAME } from './constants';
import { COUNTRY } from './data/country';
import { getAllReducer, initialState, StateData } from './reducers';

const countrySlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetGetAll: (state: StateData) => {
      state = initialState;
    },
    search: (state: StateData, action: PayloadAction<{ q: string }>) => {
      state.data = [
        ...COUNTRY.filter((e) => {
          const textClean = action.payload.q.replace(/[()]/g, '');
          const re = new RegExp(textClean, 'ig');
          return e.label.search(re) !== -1;
        })
      ];
    }
  },
  extraReducers: (builder) => {
    getAllReducer(builder);
  }
});

export const selectors = {
  isLoading: (state: RootState) => state[NAME].status === StatusTypes.LOADING,
  isIdle: (state: RootState) => state[NAME].status === StatusTypes.IDLE,
  error: (state: RootState) => state[NAME].error,
  data: (state: RootState) => state[NAME].data || initialState.data
};

export const { reducer, actions } = countrySlice;
