const readPdf = (file: File) => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = (e) => {
      resolve(e.target?.result);
    };
    fr.readAsText(file);
  });
};

export default readPdf;
