import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { NAME } from './constants';

export const fetchCurrentUser = createAsyncThunk(
  `${NAME}/fetchCurrentUser`,
  async (meta?: Record<string, any>) => {
    try {
      return api.fetchCurrentUser();
    } catch (error) {
      console.error(`${NAME}/fetchCurrentUser: ${error}`);
      throw new Error('Error fetching user');
    }
  }
);

export const refreshUserSession = createAsyncThunk(`${NAME}/refreshUserSession`, async () => {
  try {
    return api.refreshSession;
  } catch (error) {
    console.error(`${NAME}/refreshUserSession: ${error}`);
    throw new Error('Error refreshing the session');
  }
});
