import { ThemeAttributes } from 'src/ts/interfaces/styled-components';
import styled, { css } from 'styled-components/macro';
import { ToggleProps } from './';

const getColor = (theme: ThemeAttributes, isActive: boolean, isDisabled = false) => {
  if (isDisabled) {
    return theme.color.gray[90];
  }
  return isActive ? theme.color.blue[700] : theme.color.gray[600];
};

export const Container = styled.div<ToggleProps>`
  border: none;
  background: ${({ theme, isActive, isDisabled }) => {
    return getColor(theme, isActive, isDisabled);
  }};
  border-radius: 0.875rem;
  width: 2.063rem;
  height: 0.875rem;
  cursor: ${({ isDisabled }) => (isDisabled ? '' : 'pointer')};
  position: relative;
`;

const toggleCircle = (isActive: boolean) => {
  return isActive
    ? css`
        right: -0.063rem;
      `
    : css`
        left: -0.063rem;
      `;
};

export const Circle = styled.div<Pick<ToggleProps, 'isActive' | 'isDisabled'>>`
  all: revert;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.125rem solid
    ${({ theme, isActive, isDisabled }) => getColor(theme, isActive, isDisabled)};
  position: absolute;
  background: ${({ theme }) => theme.color.white};
  top: -0.188rem;
  box-shadow: 0 0.063rem 0.25rem ${({ theme }) => theme.color.gray[600]};
  ${({ isActive }) => toggleCircle(isActive)};
`;
