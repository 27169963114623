// Vendor
import styled, { css } from 'styled-components';
import { ReactSVG } from 'react-svg';

// Components
import Text from 'src/components/atoms/Text';
import SkeletonPiece from 'src/components/atoms/SkeletonPiece';

export const StyledCard = styled.div`
  height: 105px; // or ~7rem if needed
  padding: 1rem 2rem;
`;

export const StyledCompanyAddress = styled(Text)`
  margin: 0.25rem 0;
`;

export const StyledPropertyCount = styled(Text)`
  align-self: center;
`;

export const StyledReactSVG = styled(ReactSVG)`
  ${({ theme }) => {
    return css`
      color: ${theme.color.blue[600]};
      margin-right: 0.5rem;

      svg {
        height: 1rem;
        width: 1rem;
        path {
          fill: ${theme.color.blue[600]};
        }
      }
    `;
  }}
`;

export const SkeletonPieceWithMargin = styled(SkeletonPiece)`
  margin: 0.125rem 0;
`;
