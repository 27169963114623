import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUserCompanyRequest, IUserRequest } from 'src/ts/interfaces';
import { uploadCompanyLogo } from '../company/utils';
import api from './api';
import { NAME } from './constants';

// TODO: Remove this thunk when the New Announcement is implemented
const updateAnnouncementThunkPath = `${NAME}/updateAnnouncement`;
export const updateAnnouncementThunk = createAsyncThunk(
  updateAnnouncementThunkPath,
  async (payload: IUserRequest) => {
    try {
      return api.updateMyUser(payload);
    } catch (error) {
      console.error(updateAnnouncementThunkPath, error);
      throw new Error(`Thunk error updating the user's announcement`);
    }
  }
);

const updateMyUserThunkPath = `${NAME}/updateMyUser`;
export const updateMyUserThunk = createAsyncThunk(
  updateMyUserThunkPath,
  async (payload: IUserRequest) => {
    try {
      return api.updateMyUser(payload);
    } catch (error) {
      console.error(updateMyUserThunkPath, error);
      throw new Error('Thunk error updating user information');
    }
  }
);

const createUserAndCompanyThunkPath = `${NAME}/createUserAndCompany`;
export const createUserAndCompanyThunk = createAsyncThunk(
  createUserAndCompanyThunkPath,
  async (payload: IUserCompanyRequest) => {
    try {
      const createCompanyPreURLResponse = await api.createUserAndCompany(payload);

      if (!createCompanyPreURLResponse?.company_id) {
        throw new Error('Failed to create a company');
      }

      await uploadCompanyLogo(createCompanyPreURLResponse, payload);

      return createCompanyPreURLResponse;
    } catch (error) {
      console.error(createUserAndCompanyThunkPath, error);
      const emailErrorMessage = (
        error as {
          // Creating the expected schema to prevent using any type
          response: Response & {
            data: { errors: { detail: { 'user.email': [{ message: string }] } } };
          };
        }
      )?.response?.data?.errors?.detail?.['user.email']?.[0]?.message;

      throw new Error(emailErrorMessage || 'Error while creating user and company.');
    }
  }
);

const resetPasswordThunkPath = `${NAME}/resetPassword`;
export const resetPasswordThunk = createAsyncThunk(resetPasswordThunkPath, async () => {
  try {
    return api.resetPassword();
  } catch (error) {
    console.error(resetPasswordThunkPath, error);
    throw new Error('Thunk error resetting password');
  }
});
