// Vendor
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import Gap from 'src/components/atoms/Gap';
import { StepProps, StepState } from 'src/components/atoms/Step/Step';
import { StepsFlow } from 'src/components/molecules/StepsFlow';
import { DetailView } from 'src/components/templates/DetailView';
import AddTeamForm from './components/AddTeamForm';
import AssignPropertiesForm from './components/AssignPropertiesForm';
import ConfirmForm from './components/ConfirmForm';
import SelectCompanyForm from './components/SelectCompanyForm';
import UserInfoForm from './components/UserInfoForm';

// Hooks
import useUserRoles from 'src/features/auth/hooks/useUserRoles';
import useCompany from 'src/features/company/hooks/useCompany';
import useInvitationsSteps, { StepType } from 'src/features/invitation/hooks/useInvitationsSteps';
import useSearchCompany from 'src/features/invitation/hooks/useSearchCompany';
import useUsers from 'src/features/users/hooks/useUsers';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import useInvitations from '../../features/invitation/hooks/useInvitations';

// Redux
import { selectors as companySelector } from 'src/features/company/companySlice';
import { selectors } from 'src/features/invitation';

// Enums
import { RoleEnum } from 'src/ts/enums';

// Constants
import { LeasingTeam, SnapptTeam } from 'src/constants/roles';

// Types
import useRole from 'src/features/auth/hooks/useUserRoles';
import { IUser, IValueLabel, RoleType } from 'src/ts/interfaces';

export type PartialUser = Partial<IUser>;

type InvitationFlowPageProps = {
  team?: 'leasing' | 'snappt';
};

const InvitationFlowPage: React.FC<InvitationFlowPageProps> = ({
  team
}: InvitationFlowPageProps) => {
  const { ListRoles, userResponse } = useUsers();
  const { onGetOne: onGetCompany } = useCompany();
  const { isAdminOrFdeManager, isAdminOrAccountRepresentative } = useRole();

  const companyData = useSelector(companySelector.getOne.data);
  const companyIsLoading = useSelector(companySelector.getOne.isLoading);
  const companyError = useSelector(companySelector.getOne.error);

  const { onCreate: onCreateInvitation } = useInvitations();
  const { initialSteps, Steps, isStepDisabled } = useInvitationsSteps();
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();
  const { isAdmin, isCompanyAdminOrBuildingManager, isLeasingAgent, isLeasingTeam } =
    useUserRoles();

  const createInvitationIsLoading = useSelector(selectors.create.isLoading);
  const createInvitationIsCreated = useSelector(selectors.create.isCreated);
  const createInvitationError = useSelector(selectors.create.error);

  const { onResetGetAll: onResetSearchCompany } = useSearchCompany();

  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState<StepType>(Steps.userInfo);
  const [steps, setSteps] = useState<typeof initialSteps | []>([]);
  const [roles, setRoles] = useState<IValueLabel[]>([]);
  const [roleSelected, setRoleSelected] = useState<RoleType>(RoleEnum.Admin);
  const [newUserData, setNewUserData] = useState<PartialUser>({});

  useEffect(() => {
    return () => {
      onResetSearchCompany();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (steps.length === 0) {
      const allowedRoles = ListRoles();

      // Filter allowedRoles based on the team
      const filteredRoles = allowedRoles.filter((role) => {
        if (team === 'leasing') return LeasingTeam.includes(role.value as RoleType);
        if (team === 'snappt') return SnapptTeam.includes(role.value as RoleType);

        // Default option to maintain backward compatibility (e.g. with feature flag disabled)
        return true;
      });

      setRoles(isAdminOrAccountRepresentative ? filteredRoles : allowedRoles);
      setSteps(initialSteps);

      if (userResponse?.company_id) {
        onGetCompany();

        setNewUserData({
          ...newUserData,
          company_id: userResponse?.company_id
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, steps, userResponse]);

  useEffect(() => {
    if (createInvitationError) {
      showSnackbar(VariantType.error, createInvitationError, SnackTypes.none);
      navigate('/users', { state: { from: 'InvitationFlowPage' } });
    } else {
      if (!createInvitationIsLoading && createInvitationIsCreated) {
        showSnackbar(VariantType.success, 'Invitation has been sent successfully', SnackTypes.none);
        navigate('/users', { state: { from: 'InvitationFlowPage' } });
      }
    }
  }, [
    createInvitationIsLoading,
    createInvitationError,
    createInvitationIsCreated,
    navigate,
    showSnackbar,
    VariantType,
    SnackTypes
  ]);

  useEffect(() => {
    if (!companyIsLoading && !companyError && companyData) {
      setNewUserData({
        ...newUserData,
        company: { id: companyData.id || '', name: companyData.name }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData, companyError, companyIsLoading]);

  const enableStep = (steps: typeof initialSteps, stepsEnabled: StepType[]) => {
    const stepsUpdated = steps.map((step) => {
      if (stepsEnabled.includes(step.id)) {
        return {
          ...step,
          state: StepState.uncompleted
        };
      }

      let helpText = '';
      if (step.id === Steps.addTeam) {
        helpText = 'Does not apply for this user role.';
      }
      if (step.id === Steps.selectCompany) {
        helpText = 'Select Company is not <br/>available for this role.';
      }
      if (step.id === Steps.assignProperties) {
        helpText = 'Assign Properties is not <br/>available for this role.';
      }

      return {
        ...step,
        helpText
      };
    });
    setSteps(stepsUpdated);
  };

  const updateStep = (nextStep: StepType, goingBack = false) => {
    const tmpSteps = steps.map((step) => {
      if (step.id === currentStep)
        step.state = goingBack ? StepState.uncompleted : StepState.completed;
      if (step.id === nextStep && step.state !== StepState.disabled) step.state = StepState.current;
      return step;
    });
    setSteps(tmpSteps);
  };

  const handleNextStep = (
    data: PartialUser,
    currentStep: StepType,
    nextStep: StepType,
    goingBack = false
  ) => {
    let moveStep = nextStep;

    if (
      [RoleEnum.Admin, RoleEnum.AccountRepresentative, RoleEnum.Reviewer].includes(roleSelected)
    ) {
      if (currentStep === Steps.userInfo) {
        moveStep = Steps.confirm;
      }
    }

    if (roleSelected === RoleEnum.CompanyAdmin) {
      if (currentStep === Steps.selectCompany) {
        moveStep = Steps.confirm;
      }
    }

    setNewUserData({
      ...newUserData,
      ...data
    });
    updateStep(moveStep, goingBack);
    setCurrentStep(moveStep);
  };

  const handleBackStep = (
    data: PartialUser,
    currentStep: StepType,
    nextStep: StepType,
    goingBack = false
  ) => {
    let moveStep = nextStep;

    if (
      [
        RoleEnum.Admin,
        RoleEnum.AccountRepresentative,
        RoleEnum.Reviewer,
        RoleEnum.FraudAnalyst
      ].includes(roleSelected)
    ) {
      if (currentStep === Steps.confirm) {
        moveStep = Steps.userInfo;
      }
    }

    if (roleSelected === RoleEnum.CompanyAdmin) {
      if (currentStep === Steps.selectCompany) {
        moveStep = Steps.userInfo;
      }
      if (currentStep === Steps.confirm) {
        moveStep = Steps.selectCompany;
      }
    }

    setNewUserData({
      ...newUserData,
      ...data
    });
    updateStep(moveStep, goingBack);
    setCurrentStep(moveStep);
  };

  const handleStepClick = (step: StepProps) => {
    if (!['completed', 'current'].includes(step.state)) {
      return null;
    }
    setCurrentStep(step.id as StepType);
  };

  const getNextEnabledStep = useCallback(() => {
    const currentStepIndex = steps.findIndex((step) => step.id === currentStep);
    const nextEnabledStep = steps.find((step, index) => {
      return index > currentStepIndex && step.state !== StepState.disabled;
    });
    return nextEnabledStep?.id || Steps.userInfo;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, steps]);

  const getPreviousEnabledStep = useCallback(() => {
    const currentStepIndex = steps.findIndex((step) => step.id === currentStep);
    const previousEnabledStep = steps
      .slice(0, currentStepIndex)
      .reverse()
      .find((step) => {
        return step.state !== StepState.disabled;
      });
    return previousEnabledStep?.id || Steps.userInfo;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, steps]);

  if (currentStep === Steps.userInfo) {
    return (
      <DetailView back={{ to: '/users', label: 'Go Back' }} title="Invite User">
        <StepsFlow steps={steps} onClickStep={handleStepClick} />
        <Gap height={2} />
        <UserInfoForm
          onSave={(data: PartialUser) => {
            handleNextStep(data, Steps.userInfo, getNextEnabledStep());
          }}
          defaultValues={newUserData}
          roles={roles}
          onGetRoleSelected={(role) => {
            setRoleSelected(role);

            if (
              [RoleEnum.Admin, RoleEnum.Reviewer, RoleEnum.AccountRepresentative].includes(role)
            ) {
              enableStep(initialSteps, [Steps.confirm]);
            }

            if (
              [RoleEnum.FdeManager, RoleEnum.SeniorFraudAnalyst, RoleEnum.FraudAnalyst].includes(
                role
              )
            ) {
              enableStep(initialSteps, [
                ...(isAdminOrFdeManager ? [Steps.addTeam] : []),
                Steps.confirm
              ]);
            }

            if (
              [
                RoleEnum.BuildingManager,
                RoleEnum.OnSiteRepresentative,
                RoleEnum.PropertyAdmin
              ].includes(role)
            ) {
              enableStep(initialSteps, [
                Steps.selectCompany,
                Steps.assignProperties,
                Steps.confirm
              ]);
            }

            if ([RoleEnum.CompanyAdmin].includes(role)) {
              enableStep(initialSteps, [Steps.selectCompany, Steps.confirm]);
            }
          }}
        />
        <Gap height={2} />
      </DetailView>
    );
  }

  if (currentStep === Steps.addTeam) {
    return (
      <DetailView
        back={{
          onClick: () => handleBackStep(newUserData, Steps.addTeam, getPreviousEnabledStep(), true),
          label: 'Previous Step'
        }}
        title="Invite User"
      >
        <StepsFlow steps={steps} onClickStep={handleStepClick} />
        <Gap height={2} />
        <AddTeamForm
          onSave={(data: PartialUser) => handleNextStep(data, Steps.addTeam, getNextEnabledStep())}
          onCancel={(data: PartialUser) => {
            handleBackStep(data, Steps.addTeam, getPreviousEnabledStep(), true);
          }}
          defaultValues={newUserData}
          isDisabled={
            isStepDisabled(currentStep, steps) ||
            isAdmin ||
            isCompanyAdminOrBuildingManager ||
            isLeasingAgent ||
            isLeasingTeam
          }
        />
      </DetailView>
    );
  }

  if (currentStep === Steps.selectCompany) {
    return (
      <DetailView
        back={{
          onClick: () =>
            handleBackStep(newUserData, Steps.selectCompany, getPreviousEnabledStep(), true),
          label: 'Previous Step'
        }}
        title="Invite User"
      >
        <StepsFlow steps={steps} onClickStep={handleStepClick} />
        <Gap height={2} />
        <SelectCompanyForm
          onSave={(data: PartialUser) =>
            handleNextStep(data, Steps.selectCompany, getNextEnabledStep())
          }
          onCancel={(data: PartialUser) => {
            handleBackStep(data, Steps.selectCompany, getPreviousEnabledStep(), true);
          }}
          defaultValues={newUserData}
          isDisabled={isStepDisabled(currentStep, steps) || isCompanyAdminOrBuildingManager}
        />
      </DetailView>
    );
  }

  if (currentStep === Steps.assignProperties) {
    return (
      <DetailView
        back={{
          onClick: () =>
            handleBackStep(newUserData, Steps.assignProperties, getPreviousEnabledStep(), true),
          label: 'Previous Step'
        }}
        title="Invite User"
      >
        <StepsFlow steps={steps} onClickStep={handleStepClick} />
        <Gap height={2} />
        <AssignPropertiesForm
          onSave={(data: PartialUser) =>
            handleNextStep(data, Steps.assignProperties, getNextEnabledStep())
          }
          onCancel={(data: PartialUser) =>
            handleBackStep(data, Steps.assignProperties, getPreviousEnabledStep(), true)
          }
          defaultValues={newUserData}
        />
        <Gap height={2} />
      </DetailView>
    );
  }

  if (currentStep === Steps.confirm) {
    return (
      <DetailView
        back={{
          onClick: () => handleBackStep(newUserData, Steps.confirm, getPreviousEnabledStep(), true),
          label: 'Previous Step'
        }}
        title="Invite User"
      >
        <StepsFlow steps={steps} onClickStep={handleStepClick} />
        <Gap height={2} />
        <ConfirmForm
          onSave={(data: PartialUser) => onCreateInvitation(data)}
          defaultValues={newUserData}
          roles={roles}
        />
        <Gap height={2} />
      </DetailView>
    );
  }

  return null;
};

InvitationFlowPage.propTypes = {
  team: PropTypes.oneOf(['leasing', 'snappt', undefined])
};

export default InvitationFlowPage;
