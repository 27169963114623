// Vendor
import PropTypes from 'prop-types';
import React from 'react';

// Components
import Button, {
  ButtonDisabledStyle,
  ButtonSize,
  ButtonVariant
} from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import Grid from 'src/components/atoms/Grid';
import { Icon } from 'src/components/atoms/Icon';
import { Line } from 'src/components/atoms/Line';
import Text, { TextAlignItems, TextVariant } from 'src/components/atoms/Text';
import { StyledButtonContainer, StyledContainer, StyledGrid } from './styles';

//Hooks
import { useSelector } from 'react-redux';
import { useGenerateApplicantLink } from 'src/features/identityVerification/hooks/useGenerateApplicantLink';

// Types
import { IProperty } from 'src/ts/interfaces';

//Redux
import { selectors } from 'src/features/identityVerification/identityVerificationSlice';
import { LinkAction } from 'src/ts/enums';

export type IdentityVerificationLinkRowProps = Pick<
  IProperty,
  | 'name'
  | 'address'
  | 'city'
  | 'state'
  | 'zip'
  | 'short_id'
  | 'company_short_id'
  | 'identity_verification_enabled'
>;

const IdentityVerificationLinkRow: React.FC<IdentityVerificationLinkRowProps> = (props) => {
  const { company_short_id, short_id: propertyShortId } = props;

  const { onGenerateApplicantLink, selectedPropertyShortId } = useGenerateApplicantLink();

  const isApplicantLinkGenerating =
    useSelector(selectors.generateApplicantLink.isLoading) &&
    propertyShortId === selectedPropertyShortId;

  return (
    <StyledContainer>
      <Line height="tiny" />
      <Gap height={1} />
      <StyledGrid direction="column">
        <Grid justify="space-between">
          <Text isBold alignItems={TextAlignItems.center} color="initial">
            {props.name}
          </Text>
        </Grid>
        <Gap height={0.1875} />
        <Text variant={TextVariant.small} color={'gray700'}>
          {`${props.address}, ${props.city}, ${props.state} ${props.zip}`}
        </Text>
        <Gap height={0.5} />
        <StyledButtonContainer>
          <Button
            name="button-idv-applicant-generate-link"
            variant={ButtonVariant.outline}
            size={ButtonSize.normal}
            onClick={() =>
              onGenerateApplicantLink(
                propertyShortId || '',
                company_short_id || '',
                LinkAction.COPY
              )
            }
            isLoading={isApplicantLinkGenerating}
            isDisabled={isApplicantLinkGenerating}
            disabledStyle={ButtonDisabledStyle.whiteWithBorder}
          >
            Applicants Link &nbsp;&nbsp;
            <Icon icon="filterNone" />
          </Button>
          <Button
            name="button-idv-leasing-team-generate-link"
            variant={ButtonVariant.outline}
            size={ButtonSize.normal}
            onClick={() =>
              onGenerateApplicantLink(
                propertyShortId || '',
                company_short_id || '',
                LinkAction.NEW_TAB
              )
            }
            isLoading={isApplicantLinkGenerating}
            isDisabled={isApplicantLinkGenerating}
            disabledStyle={ButtonDisabledStyle.whiteWithBorder}
          >
            Leasing Team Link &nbsp;&nbsp;
            <Icon icon="launch" />
          </Button>
        </StyledButtonContainer>
        {/* <Gap height={0.5} /> */}
      </StyledGrid>
    </StyledContainer>
  );
};

IdentityVerificationLinkRow.propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  short_id: PropTypes.string.isRequired,
  company_short_id: PropTypes.string.isRequired,
  identity_verification_enabled: PropTypes.bool.isRequired
};

export default IdentityVerificationLinkRow;
