import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1280px;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.5rem;
  }

  .income-verification-icons {
    svg {
      width: 37px !important;
      height: 37px !important;
    }
    width: 37px;
  }
`;
