// Vendor
import { FC, HTMLAttributes } from 'react';

// Components
import { ButtonColor, ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import Tag from 'src/components/atoms/Tag';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import {
  CompanyIcon,
  EditActionBox,
  EditIcon,
  HeaderTitleBox,
  PersonIcon,
  StyledButton,
  StyledCard,
  StyledText
} from './styles';

// Helpers
import {
  getEntryStatusColorTag,
  getResultStatusColorTag,
  startCase,
  transformResultToReadable
} from 'src/features/fdeInbox/helpers';

// Enums
import { FeatureFlagKey } from 'src/ts/enums';

// Types
import {
  EntryResultType,
  EntryStatusType,
  FolderResultType,
  FolderStatusType
} from 'src/ts/interfaces';

//Hooks
import useUserRoles from 'src/features/auth/hooks/useUserRoles';
import { useFeatureFlag } from 'src/hooks';

export interface FolderTileInfoProps extends HTMLAttributes<HTMLDivElement> {
  onClickEdit: () => void;
  folderName: string;
  propertyName: string;
  lastEntryStatus: EntryStatusType | FolderStatusType;
  lastEntryResult: EntryResultType | FolderResultType;
}

const FolderTileInfo: FC<FolderTileInfoProps> = (props) => {
  const { onClickEdit, propertyName, folderName, lastEntryStatus, lastEntryResult, ...htmlAttrs } =
    props;

  const { isAccountRepresentative } = useUserRoles();

  const cleanProceedWithCautionFlagEnabled = useFeatureFlag(
    FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION
  );

  return (
    <StyledCard rounded border {...htmlAttrs}>
      <Row>
        <HeaderTitleBox>
          <Row>
            <PersonIcon />
            <StyledText variant={TextVariant.span} color={TextColor.gray700}>
              {folderName}
            </StyledText>
          </Row>

          <Row>
            <CompanyIcon />
            <StyledText variant={TextVariant.span} color={TextColor.gray700}>
              {propertyName}
            </StyledText>
          </Row>
        </HeaderTitleBox>

        {!isAccountRepresentative && (
          <EditActionBox>
            <StyledButton
              onClick={onClickEdit}
              name="Edit"
              variant={ButtonVariant.outline}
              color={ButtonColor.disabled}
            >
              Edit <EditIcon />
            </StyledButton>
          </EditActionBox>
        )}
      </Row>

      <Gap height={2} />

      <Row data-testid="LastReportEntryRow" justify="space-between">
        <Row direction="column" gap={0.5}>
          <Row.Col>
            <Text color={TextColor.gray600} variant={TextVariant.small}>
              Last Report Entry Status
            </Text>
          </Row.Col>
          <Row.Col>
            <Tag
              label={startCase(lastEntryStatus as EntryStatusType)}
              color={getEntryStatusColorTag(lastEntryStatus as EntryStatusType)}
              showBackground
            />
          </Row.Col>
        </Row>

        <Row direction="column" gap={0.5}>
          <Row.Col>
            <Text color={TextColor.gray600} variant={TextVariant.small}>
              Last Report Entry Result
            </Text>
          </Row.Col>
          <Row.Col alignSelf="flex-end">
            <Tag
              label={transformResultToReadable(lastEntryResult as EntryResultType)}
              color={getResultStatusColorTag(
                lastEntryResult as EntryResultType,
                cleanProceedWithCautionFlagEnabled
              )}
              showBackground
            />
          </Row.Col>
        </Row>
      </Row>
    </StyledCard>
  );
};

export default FolderTileInfo;
