import PropTypes from 'prop-types';
import Button, { ButtonDisabledStyle, ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import Tag, { TagColor } from 'src/components/atoms/Tag';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import Row from '../../atoms/Row/Row';
import { Container, TextName } from './styles';

export type TeamCardProps = {
  id?: string;
  teamName?: string;
  fdeManagerName?: string;
  timezone?: string;
  numMembers?: number;
  onEdit?: () => void;
};

const TeamCard: React.FC<TeamCardProps> = (props) => {
  const { teamName, fdeManagerName, timezone, numMembers, onEdit } = props;

  return (
    <Container>
      <Row justify="space-between">
        <Row.Col size={'50%'}>
          <>
            <TextName isBold variant={TextVariant.h4}>
              {teamName}
            </TextName>
            <Gap height={0.5} />
            <Text variant={TextVariant.normal}>{timezone}</Text>
            {numMembers !== undefined && (
              <>
                <Gap height={0.5} />
                <Text variant={TextVariant.normal} color={TextColor.gray300}>
                  {numMembers === 1 ? `1 Member` : `${numMembers} Members`}
                </Text>
              </>
            )}
          </>
        </Row.Col>
        {onEdit && (
          <Row.Col size={'auto'} alignSelf="flex-start">
            <Row className={'buttons'} justify={'flex-end'}>
              <Button
                variant={ButtonVariant.ghost}
                onClick={onEdit}
                name="edit_button"
                disabledStyle={ButtonDisabledStyle.white}
              >
                <Icon color="primary" icon="gear" className="edit_button_icon" />
              </Button>
            </Row>
          </Row.Col>
        )}
      </Row>
      <>
        <Gap height={0.5} />
        <Text variant={TextVariant.normal} isBold>
          FDE Manager
        </Text>
        <Gap height={0.5} />
        <Tag
          color={!fdeManagerName ? TagColor.gray : TagColor.blue}
          label={!fdeManagerName ? 'No manager assigned' : fdeManagerName}
          showBackground
        />
      </>
    </Container>
  );
};

TeamCard.propTypes = {
  teamName: PropTypes.string,
  fdeManagerName: PropTypes.string,
  timezone: PropTypes.string,
  numMembers: PropTypes.number,
  onEdit: PropTypes.func
};

TeamCard.defaultProps = {
  teamName: 'Team Alias 1',
  timezone: 'California, EE. UU. (GMT-7)',
  numMembers: undefined,
  onEdit: undefined
};

export default TeamCard;
