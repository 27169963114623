import React from 'react';

// Vendor
import { useSelector } from 'react-redux';

// Components
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Section from 'src/components/atoms/Section/Section';

import Text, { TextVariant } from 'src/components/atoms/Text';

// Redux
import { selectors as identitySelectors } from 'src/features/identityVerification/identityVerificationSlice';
import { parseTimeToDecimalFormat } from 'src/helpers';

export const IdentityVerificationApplicantDetail: React.FC = () => {
  const identityReportData = useSelector(identitySelectors.getApplicantByVerificationId.data);

  return (
    <Section>
      <Row justify="space-between" gap={1}>
        <Row direction="column" gap={1}>
          <Row gap={1}>
            <Text isBold>Report Date</Text>
            {identityReportData?.sessionClosedAt !== undefined && (
              <Text>{parseTimeToDecimalFormat(identityReportData?.sessionClosedAt as string)}</Text>
            )}
          </Row>
          <Gap height={1} />
          <Row direction="column" gap={1}>
            <Text isBold>Applicant</Text>
            <Text>
              {identityReportData?.firstName} {identityReportData?.lastName}
            </Text>
          </Row>
          <Row direction="column" gap={1}>
            <Text isBold>Property Name</Text>
            <Text>{identityReportData?.property.name}</Text>
          </Row>
          <Row direction="column" gap={1}>
            <Text isBold>Property Address</Text>
            <Text>
              {identityReportData?.property.address} {identityReportData?.property.city},&nbsp;
              {identityReportData?.property.state}
            </Text>
          </Row>
        </Row>
        <Row.Col>
          <Row direction="column" gap={0.5} style={{ paddingLeft: 10 }}>
            <Text variant={TextVariant.small}>Powered by</Text>
            <Icon icon="logo-report" />
          </Row>
          <Gap height={2} />
          <Row direction="column" gap={1}>
            <Text isBold>Contact Information</Text>
            {identityReportData?.phone !== undefined && identityReportData?.phone !== '' && (
              <Text isBold variant={TextVariant.span}>
                P:&nbsp;
                <Text variant={TextVariant.span}>{identityReportData?.phone}</Text>
              </Text>
            )}
            {identityReportData?.email !== '' && (
              <Text isBold variant={TextVariant.span}>
                E:&nbsp;
                <Text variant={TextVariant.span}>{identityReportData?.email}</Text>
              </Text>
            )}
            {(identityReportData?.phone === undefined || identityReportData?.phone === '') &&
              identityReportData?.email === '' && <Text>Not provided</Text>}
          </Row>
        </Row.Col>
      </Row>
    </Section>
  );
};
