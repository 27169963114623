import { userApi } from 'src/api';
import { getPaginationRequest, IUsersResponseWithPage } from 'src/ts/interfaces';
import { IUserRequest } from './ts/interfaces';

const api = {
  getAll: async ({ page, rowsPerPage, filter, sort, q }: getPaginationRequest) => {
    // TODO: Shouldn't return the current user
    // TODO: page and rowsPerPage should comes as number
    const pageNum = page?.toString();
    const rowsPerPageNum = rowsPerPage?.toString();

    const response = await userApi.getUsers(pageNum, rowsPerPageNum, filter, sort, q);
    return { ...(response || {}) } as unknown as IUsersResponseWithPage;
  },
  getOne: async (userId: string) => {
    return await userApi.getUser(userId);
  },
  create: async (data: IUserRequest) => {
    return await userApi.createUser(data);
  },
  delete: async (userId: string) => {
    // TODO: Should return something
    return await userApi.deleteUser(userId);
  },
  update: async (userId: string, data: IUserRequest) => {
    return await userApi.updateUser2(userId, data);
  }
};

export default api;
