// Vendor
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Redux
import { RootState } from 'src/store';
import { getAll, propertyFeatureUpdateThunk } from './services';

// Enums
import { Status as StatusTypes } from 'src/ts/enums';

// Types
import {
  IPaginationRequest,
  IPropertyFeature,
  IRejectedAction,
  ISerializedError
} from 'src/ts/interfaces';

const NAME = 'propertyFeature';
interface StateData {
  getAll: {
    status: StatusTypes;
    data?: IPropertyFeature[] | null;
    error?: ISerializedError | AxiosError | null;
    count?: number;
    filter: IPaginationRequest;
  };
  update: {
    status: StatusTypes;
    error?: ISerializedError | AxiosError | null;
  };
}

const initialState: StateData = {
  getAll: {
    status: StatusTypes.IDLE,
    data: [],
    error: null,
    count: 0,
    filter: {
      q: '',
      page: 0,
      rowsPerPage: 20,
      sort: 'name:asc',
      filter: 'status!=ARCHIVED'
    }
  },
  update: {
    status: StatusTypes.IDLE,
    error: null
  }
};

const getAllReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getAll.pending, (state: StateData) => {
    state.getAll.status = StatusTypes.LOADING;
    state.getAll.error = null;
  });
  builder.addCase(getAll.rejected, (state: StateData, action: IRejectedAction) => {
    state.getAll.status = StatusTypes.ERROR;
    state.getAll.error = (action.payload as AxiosError) || action.error;
  });
  builder.addCase(getAll.fulfilled, (state: StateData, action: any) => {
    const { data, count } = action.payload;
    state.getAll.status = StatusTypes.SUCCESS;
    state.getAll.data = data;
    state.getAll.count = count;
  });
};

const updateReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(propertyFeatureUpdateThunk.pending, (state: StateData) => {
    state.update.status = StatusTypes.LOADING;
    state.update.error = null;
  });
  builder.addCase(
    propertyFeatureUpdateThunk.rejected,
    (state: StateData, action: IRejectedAction) => {
      state.update.status = StatusTypes.ERROR;
      state.update.error = (action.payload as AxiosError) || action.error;
    }
  );
  builder.addCase(propertyFeatureUpdateThunk.fulfilled, (state, action) => {
    state.update.status = StatusTypes.SUCCESS;
    state.getAll.status = StatusTypes.REFRESHING;
  });
};

const propertySlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetGetAll: (state: StateData) => {
      state.getAll = initialState.getAll;
    }
  },
  extraReducers: (builder) => {
    getAllReducer(builder);
    updateReducer(builder);
  }
});

export const selectors = {
  getAll: {
    isLoading: (state: RootState) => state[NAME].getAll.status === StatusTypes.LOADING,
    isFulfilled: (state: RootState) => state[NAME].getAll.status === StatusTypes.SUCCESS,
    isRefreshing: (state: RootState) => state[NAME].getAll.status === StatusTypes.REFRESHING,
    isIdle: (state: RootState) => state[NAME].getAll.status === StatusTypes.IDLE,
    error: (state: RootState) => state[NAME].getAll.error,
    data: (state: RootState) => state[NAME].getAll.data || initialState.getAll.data,
    filter: (state: RootState) => state[NAME].getAll.filter || initialState.getAll.filter,
    count: (state: RootState) => state[NAME].getAll.count || 0,
    isToolbarDisabled: (state: RootState) => {
      const stateGetAll = state[NAME].getAll;
      return (
        stateGetAll.status === StatusTypes.LOADING ||
        stateGetAll.error !== null ||
        (stateGetAll.count === 0 && stateGetAll.filter.q === '')
      );
    }
  },
  update: {
    isUpdated: (state: RootState) => state[NAME].update.status === StatusTypes.SUCCESS
  }
};

export const { reducer, actions } = propertySlice;
