import Grid from 'src/components/atoms/Grid';
import { StyledCopyButtonContainer } from 'src/components/molecules/CopyButton/styles';
import styled from 'styled-components/macro';

export const StyledGrid = styled(Grid)`
  padding: 0rem 1.5rem;
`;

export const StyledButtonContainer = styled(Grid)`
  & > {
    ${StyledCopyButtonContainer}:first-child {
      margin-right: 1rem;
    }
  }
`;

export const StyledContainer = styled.div`
  padding: 1rem 2rem;
`;
