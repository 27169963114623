/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { memo, useEffect, useReducer, useState } from 'react';

// Components
import Button, { ButtonSize, ButtonType, ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import ListBox from './components/ListBox';
import { Container } from './styles';

// Reducer
import { initialState, TransferReducer } from './reducer';

// Types
import { ITransferList } from './types';

const TransferList: React.FC<ITransferList> = (props: ITransferList) => {
  const {
    name,
    onGetData,
    onGetChecksLeft,
    onGetChecksRight,
    firstList,
    secondList,
    showSelectChoices,
    showSearch,
    searchPlaceholder,
    onMoveLeft,
    onMoveRight
  } = props;

  if (name === firstList.name || name === secondList.name) {
    throw new Error('The component must have a different name from the lists.');
  }

  if (firstList.name === secondList.name) {
    throw new Error('The lists must have different names between them.');
  }

  const [transferState, dispatch] = useReducer(TransferReducer, initialState);

  const [isCheckedLeft, setIsCheckedLeft] = useState(false);
  const [isCheckedRight, setIsCheckedRight] = useState(false);

  const [isMoved, setMoved] = useState(false);

  const load = () => {
    dispatch({
      type: 'load',
      payload: {
        firstList: {
          data: firstList.data,
          selected: [],
          q: transferState.firstList.q || ''
        },
        secondList: {
          data: secondList.data,
          selected: [],
          q: transferState.secondList.q || ''
        }
      }
    });
  };

  const onMovingToLeft = () => {
    dispatch({ type: 'toLeft' });
    setMoved(true);
  };

  const onMovingToRight = () => {
    dispatch({ type: 'toRight' });
    setMoved(true);
  };

  useEffect(() => {
    if (!firstList.isLoading && !secondList.isLoading) {
      load();
    }
  }, [firstList, secondList]);

  useEffect(() => {
    if ((onGetData || onMoveRight || onMoveLeft) && isMoved) {
      onMoveRight?.(transferState.secondList.data);
      onMoveLeft?.(transferState.firstList.data);
      onGetData?.(transferState);
      setMoved(false);
    }
  }, [onGetData, onMoveRight, onMoveLeft, transferState, isMoved]);

  useEffect(() => {
    if (isCheckedLeft) {
      onGetChecksLeft?.(transferState.firstList.selected);
      setIsCheckedLeft(false);
    }
  }, [isCheckedLeft, onGetChecksLeft]);

  useEffect(() => {
    if (isCheckedRight) {
      onGetChecksRight?.(transferState.secondList.selected);
      setIsCheckedRight(false);
    }
  }, [isCheckedRight, onGetChecksRight]);

  return (
    <Container>
      <Row gap={1} justify="center" alignItems="center">
        <Row.Col size="46%">
          <ListBox
            list={firstList}
            state={transferState.firstList}
            showSelectChoices={showSelectChoices}
            showSearch={showSearch}
            searchPlaceholder={searchPlaceholder}
            onSearch={(q) => {
              dispatch({
                type: 'searchInLeft',
                payload: {
                  q
                }
              });
            }}
            onSelectAll={(isChecked) => {
              dispatch({ type: 'selectAllLeft', payload: isChecked });
            }}
            onSelect={(content) => {
              dispatch({
                type: 'selectedLeft',
                payload: { selected: content.filter((item) => item.isChecked) }
              });
            }}
          />
        </Row.Col>
        <Row.Col size="auto" className="buttons">
          <Button
            name="moveToLeft_button"
            variant={ButtonVariant.outline}
            type={ButtonType.button}
            onClick={onMovingToLeft}
            size={ButtonSize.medium}
            isDisabled={
              firstList.hasError ||
              secondList.hasError ||
              transferState.secondList.selected.length === 0
            }
          >
            <Icon icon="icon-transferToLeft" />
          </Button>
          <Gap height={1} />
          <Button
            name="moveToRight_button"
            variant={ButtonVariant.outline}
            type={ButtonType.button}
            onClick={onMovingToRight}
            size={ButtonSize.medium}
            isDisabled={
              firstList.hasError ||
              secondList.hasError ||
              transferState.firstList.selected.length === 0
            }
          >
            <Icon icon="icon-transferToRight" />
          </Button>
        </Row.Col>
        <Row.Col size="46%">
          <ListBox
            list={secondList}
            state={transferState.secondList}
            showSelectChoices={showSelectChoices}
            showSearch={showSearch}
            searchPlaceholder={searchPlaceholder}
            onSearch={(q) => {
              dispatch({
                type: 'searchInRight',
                payload: {
                  q
                }
              });
            }}
            onSelectAll={(isChecked) => {
              dispatch({ type: 'selectAllRight', payload: isChecked });
            }}
            onSelect={(content) => {
              dispatch({
                type: 'selectedRight',
                payload: { selected: content.filter((item) => item.isChecked) }
              });
            }}
          />
        </Row.Col>
      </Row>
    </Container>
  );
};

export default memo(TransferList);
