/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import { TransferList } from 'src/components/molecules/TransferList';
import Modal, { ModalVariant, ModalWidth } from 'src/components/organisms/Modal';

// Hooks
import useTeams from 'src/features/teams/hooks/useTeams';

// Redux
import { selectors as teamSelectors } from 'src/features/teams/teamsSlice';

// Types
import { CheckboxData } from 'src/components/atoms/Checkbox/types';
import { ITransferListContent } from 'src/components/molecules/TransferList/types';

export interface TeamsAssigningSFAModalProps {
  onSave: (data: CheckboxData[] | []) => void;
  assigned: CheckboxData[];
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const TeamsAssigningSFAModal: FC<TeamsAssigningSFAModalProps> = (props) => {
  const { onSave, showModal, setShowModal, assigned: assignedSFA } = props;

  const { onGetAllSFA, onResetGetAllSFA } = useTeams();

  const getAllSFAData = useSelector(teamSelectors.getAllSFA.data);
  const getAllSFAIsIdle = useSelector(teamSelectors.getAllSFA.isIdle);
  const getAllSFAIsLoading = useSelector(teamSelectors.getAllSFA.isLoading);
  const getAllSFAError = useSelector(teamSelectors.getAllSFA.error);

  const [assigned, setAssigned] = useState<CheckboxData[]>(assignedSFA);
  const [isTransferring, setIsTransferring] = useState(false);

  const getData = (assigned: CheckboxData[]) => {
    setAssigned(assigned);
    onGetAllSFA(assigned);
  };

  useEffect(() => {
    if (getAllSFAIsIdle && showModal) {
      getData(assigned);
    }
  }, [getAllSFAIsIdle, showModal]);

  const onClose = () => {
    onResetGetAllSFA();
    setIsTransferring(false);
  };

  const onSetLists = (data: ITransferListContent) => {
    setAssigned(data?.secondList.data || []);
  };

  const onConfirm = () => {
    onSave(assigned);
    onClose();
    setShowModal(false);
  };

  return (
    <Modal
      title="Assign Senior Fraud Analysts"
      variant={ModalVariant.none}
      showModal={showModal}
      setShowModal={setShowModal}
      isBackClosable={false}
      isEscapeClosable={false}
      width={ModalWidth.large}
      labelButtonConfirm="Save"
      isDisabledButtonConfirm={!isTransferring}
      onConfirm={onConfirm}
      onCancel={onClose}
    >
      <TransferList
        name="AssigningSFAList"
        showSearch
        showSelectChoices
        searchPlaceholder="Search"
        onGetData={onSetLists}
        onMoveLeft={() => {
          onResetGetAllSFA();
          setIsTransferring(true);
        }}
        onMoveRight={() => {
          onResetGetAllSFA();
          setIsTransferring(true);
        }}
        firstList={{
          name: 'UnassignedUsersList',
          title: 'Unassigned Users',
          messageEmpty: 'There are no<br/>users to show',
          isLoading: getAllSFAIsLoading,
          hasError: getAllSFAError !== null,
          data: getAllSFAData || []
        }}
        secondList={{
          name: 'AssignedUsersList',
          title: 'Assigned Users',
          messageEmpty: 'There are no<br/>users to show',
          isLoading: getAllSFAIsLoading,
          hasError: getAllSFAError !== null,
          data: assigned
        }}
      />
    </Modal>
  );
};

export default TeamsAssigningSFAModal;
