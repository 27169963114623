import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 8rem;
`;
export const Input = styled.input`
  width: 3rem;
  height: 3rem;
  border-radius: 0.25rem;
  border: 0.125rem solid ${({ theme }) => theme.color.gray[10]};
  background: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.colors.primary};
  font-size: ${({ theme }) => theme.font.small};
  color: ${({ theme }) => theme.color.black.normal};
  padding: 0;
  text-align: center;
  -moz-appearance: textfield;
  &:focus {
    outline: none;
  }
`;
