import styled from 'styled-components';
import { TypeCard } from '.';

export const Container = styled.div<{ type: TypeCard; color: string }>`
  border-left: 8px solid ${({ color }) => color};
  background: #fff;
`;

export const Content = styled.div<{ type: TypeCard; color: string }>`
  display: flex;
  align-items: start;
  flex-direction: row;
  padding: 15px 17px 15px 17px;
  border-radius: 0px 4px 4px 0px;
  border: 2px solid ${({ theme }) => theme.color.gray[200]};
  border-left: none;
  height: 100%;
`;

export const Thumbnail = styled.div`
  border: 1px solid ${({ theme }) => theme.color.gray[200]};
  margin-right: 1rem;
  img {
    width: 240px;
  }
`;

export const Description = styled.div`
  margin-top: 0.5rem;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
