import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectors as incomeVerificationSelectors } from 'src/features/incomeVerification';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import { useAppDispatch } from 'src/store';
import {
  DisputeCalculationResponse,
  IncomeVerification
} from 'src/ts/interfaces/incomeVerification';
import {
  disputeCalculationThunk,
  getPreviousDocumentsForAppeal,
  sendReportThunk
} from './services';

export const useSendReport = () => {
  const dispatch = useAppDispatch();
  const { SnackTypes, showSnackbar, VariantType } = useSnackbarProvider();

  const onSendReport = useCallback(
    async (entryId?: string) => {
      if (!entryId) {
        showSnackbar(
          VariantType.error,
          'Is missing the Entry Id to send the report',
          SnackTypes.none
        );

        return false;
      }

      try {
        await dispatch(sendReportThunk(entryId)).unwrap();

        showSnackbar(VariantType.success, 'The report has been sent', SnackTypes.none);
        return true;
      } catch (error) {
        showSnackbar(
          VariantType.error,
          'An error has occurred, please refresh and try again',
          SnackTypes.none
        );
        return false;
      }
    },
    [SnackTypes.none, VariantType.error, VariantType.success, dispatch, showSnackbar]
  );

  return {
    onSendReport
  };
};

export const useCalculationDispute = (folderId?: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { SnackTypes, showSnackbar, VariantType } = useSnackbarProvider();
  const isDisputeLoading = useSelector(incomeVerificationSelectors.isDisputeLoading);

  const onDisputeCalculation = useCallback(
    async (entryId?: string) => {
      if (!entryId) {
        showSnackbar(VariantType.error, 'Unable to dispute. Entry id is missing', SnackTypes.none);

        return;
      }

      try {
        const { data: disputeData } = (await dispatch(
          disputeCalculationThunk(entryId)
        ).unwrap()) as unknown as DisputeCalculationResponse;

        navigate(
          `/my-work/incomeVerification/${disputeData.income_verification_review_id}/${disputeData.entry_id}?redirectPath=/folder/${folderId}`
        );

        showSnackbar(VariantType.success, 'Income Calculation has been disputed', SnackTypes.none);
      } catch (error) {
        showSnackbar(
          VariantType.error,
          'An error has occurred, please refresh and try again',
          SnackTypes.none
        );
      }
    },
    [
      SnackTypes.none,
      VariantType.error,
      VariantType.success,
      dispatch,
      showSnackbar,
      navigate,
      folderId
    ]
  );

  return {
    isDisputeLoading,
    onDisputeCalculation
  };
};

/**
 * Hook for retrieving the previous income verification documents for an appeal/dispute
 */
export const useDisputeDocuments = ({
  shouldFetchDocuments = true,
  entryId
}: {
  shouldFetchDocuments?: boolean;
  entryId: string;
}): [disputeDocuments: IncomeVerification[] | undefined, isLoadingDisputeDocuments: boolean] => {
  const [isLoadingAppeal, setAppealLoading] = useState(true);
  const [disputeDocuments, setDisputeDocuments] = useState<IncomeVerification[] | undefined>();

  useEffect(() => {
    const getDocs = async () => {
      setAppealLoading(true);
      const result = await getPreviousDocumentsForAppeal(entryId);
      setDisputeDocuments(result);

      setAppealLoading(false);
    };
    if (shouldFetchDocuments) {
      getDocs();
    } else {
      setAppealLoading(false);
    }
  }, [entryId, shouldFetchDocuments]);

  return [disputeDocuments, isLoadingAppeal];
};
