import { companyApi } from 'src/api';
import {
  getPaginationRequest,
  ICompaniesResponseWithPage,
  ICompanyRequest,
  ICompanyResponse,
  ISaveFileRequest
} from 'src/ts/interfaces';
import { ICompanyGetPresignedUrlResponse } from './ts/interfaces';

const api = {
  getAll: async ({ page, rowsPerPage, filter, sort = 'name:asc', q }: getPaginationRequest) => {
    // TODO: page and rowsPerPage should comes as number
    const pageNum = page?.toString();
    const rowsPerPageNum = rowsPerPage?.toString();
    const response = await companyApi.getCompanies(pageNum, rowsPerPageNum, filter, sort, q);
    return { ...(response || {}) } as unknown as ICompaniesResponseWithPage;
  },

  getCompanyURL: async (id: string) => {
    const response = await companyApi.getCompanyLogoUrl(id);
    return { ...(response.data || {}) } as ICompanyGetPresignedUrlResponse;
  },

  setCompanyURL: async (id: string) => {
    const response = await companyApi.setCompanyLogoUrl(id);
    if (!response) {
      return {};
    }
    console.error(`getCompanyURL: ${response}`);
    throw new Error('Error setting the company url');
  },

  getCompany: async (id: string) => {
    const response = await companyApi.getCompany(id);
    return response as unknown as ICompanyResponse;
  },

  updateCompany: async (id: string, companyRequest: ICompanyRequest) => {
    const response = await companyApi.updateCompany(id, companyRequest);
    return response as unknown as ICompanyResponse;
  },

  saveCompanyFile: async ({ method, url, fields, type, file }: ISaveFileRequest) => {
    try {
      if (!file) throw new Error('File was not given or file was undefined');

      let body: FormData | File = file;
      if (method === 'POST') {
        body = new FormData();
        body.append('Content-Type', type);
        Object.entries(fields).forEach(([key, value]) => {
          (<FormData>body).append(key, value);
        });
        body.append('file', file);
      }

      const response = await fetch(url, {
        method: method,
        body: body
      });

      if (response?.ok && response.status >= 200 && response.status < 300) {
        return response;
      }

      throw new Error(response.statusText);
    } catch (e) {
      console.error(e);
      throw new Error(
        'Something went wrong trying to upload the file. Please try again or contact to Support.'
      );
    }
  }
};

export default api;
