import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/store';
import { Status } from 'src/ts/enums';

import { selectors } from '../proofsSlice';
import { getProofByIdThunk, getProofTextBreakupThunk } from '../services';

export const useProofById = (proofId: string) => {
  const dispatch = useDispatch();
  const proof = useSelector((state: RootState) => selectors.selectById(state, proofId));
  const status = useSelector(selectors.status);

  useEffect(() => {
    if (proofId && !proof) {
      dispatch(getProofByIdThunk(proofId));
    }
  }, [proofId, proof, dispatch]);

  // Effect to download text breakup file and populate the store if needed.
  useEffect(() => {
    if (proof) {
      const { text_breakups_file, text_breakups } = proof;
      const shouldDownloadTextBreakups =
        text_breakups_file && (!text_breakups || text_breakups.length === 0);

      if (shouldDownloadTextBreakups) {
        dispatch(getProofTextBreakupThunk({ id: proofId, text_breakups_file }));
      }
    }
    // eslint-disable-next-line
  }, [dispatch, proof?.text_breakups_file, proofId]);

  const isUninitialized = status === Status.IDLE;
  const isLoading = status === Status.LOADING;
  const isError = status === Status.ERROR;
  const isSuccess = status === Status.SUCCESS;

  return {
    proof,
    isUninitialized,
    isLoading,
    isError,
    isSuccess
  };
};
