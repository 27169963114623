// Components
import { Icon } from 'src/components/atoms/Icon';
import { Loader } from 'src/components/atoms/Loader';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { Loading, Thumbnail } from './styles';

// Types
import { IProofDUP } from 'src/features/DUP/proofs/ts/interfaces';

// Helpers
import getProofErrorMessage, {
  PROOF_ERROR_KEYS
} from 'src/features/DUP/helpers/getProofErrorMessage';

// Hooks
import useLanguage from 'src/context/Language/useLanguage';

export type ShowContentProps = {
  proof?: IProofDUP;
  isPolling: boolean;
};

const ShowContent: React.FC<ShowContentProps> = (props: ShowContentProps) => {
  const { proof, isPolling } = props;

  const { translate: t } = useLanguage();

  if (isPolling) {
    return (
      <Loading>
        <Loader />
      </Loading>
    );
  }

  if (proof?.jobs_error && proof?.jobs_error.length > 0) {
    const error = proof?.jobs_error[0];
    return (
      <>
        <Icon icon="icon-warning" color="red" />
        <Text color={TextColor.initial} variant={TextVariant.small}>
          <div
            dangerouslySetInnerHTML={{
              __html:
                getProofErrorMessage(error?.name as keyof typeof PROOF_ERROR_KEYS, t) ||
                error?.message
            }}
          />
        </Text>
      </>
    );
  }

  return <Thumbnail src={proof?.thumb} alt={`thumb_${proof?.id}`} />;
};

export default ShowContent;
