import { format, isValid } from 'date-fns';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { ReactSVG } from 'react-svg';

import 'react-datepicker/dist/react-datepicker.css';
import { StyledContainer } from './styles';
import { DatePickerProps } from './types';

const PUBLIC_URL = process.env.PUBLIC_URL;
const formatDate = (date: Date | null) => (!date ? null : format(date, 'MM/dd/yyyy'));

const TypeableDatePicker = ({
  className,
  name,
  value,
  onChange,
  selectsEnd,
  startDate,
  endDate,
  minDate,
  selectsStart,
  disabled,
  placeholderText
}: DatePickerProps & {
  onChange: (date: string | null) => void;
}) => {
  const isDateValid = value && isValid(new Date(value));
  const selected = isDateValid ? new Date(value) : null;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <StyledContainer className={className}>
      <ReactDatePicker
        name={name}
        dateFormat="MM/dd/yyyy"
        onChange={(date) => onChange(formatDate(date))}
        selected={selected}
        showPopperArrow={false}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        selectsStart={selectsStart}
        disabled={disabled}
        placeholderText={placeholderText}
        onCalendarOpen={() => setIsOpened(true)}
        onCalendarClose={() => setIsOpened(false)}
      />
      <ReactSVG
        className="floating-icon"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (!isOpened) {
            const inputs = document.getElementsByName(name);
            inputs?.[0].click();
          }
        }}
        role="button"
        aria-pressed="true"
        aria-label="choose date"
        src={`${PUBLIC_URL}/assets/images/calendar-today.svg`}
      />
    </StyledContainer>
  );
};

export default TypeableDatePicker;
