import styled from 'styled-components/macro';

export const StyledContainer = styled.div`
  width: 50%;
`;

export const StyledRow = styled.div`
  padding: 1rem 1.2rem;
  & .small-margin {
    padding-right: 0rem;
  }
`;
