import { FC } from 'react';

import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import Button, { ButtonColor, ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';

import { InvitationErrorMessageProps } from './types';

const InvitationErrorMessage: FC<InvitationErrorMessageProps> = ({ onClick }) => {
  return (
    <>
      <Text color={TextColor.initial} variant={TextVariant.h3}>
        Oh no!
      </Text>
      <Gap height={1} />
      <Text color={TextColor.initial} variant={TextVariant.small} isStretched>
        This invitation is no longer active. If you have already accepted this user invitation,
        please go to <a href="https://client.snappt.com/"> https://client.snappt.com/</a> and log
        into the Snappt platform.
      </Text>
      <Gap height={1.5} />
      <Text color={TextColor.initial} variant={TextVariant.small}>
        If you have not yet accepted the user invitation and believe you reached this message in
        error, please ask your building manager to re-invite you to the property.
      </Text>
      <Gap height={2} />
      <Button
        name="GoToLogin"
        variant={ButtonVariant.contained}
        color={ButtonColor.primary}
        size={ButtonSize.normal}
        onClick={onClick}
      >
        Go to login
      </Button>
    </>
  );
};

export default InvitationErrorMessage;
