import { FC } from 'react';
import Table from 'src/components/atoms/Table';
import TableBody from 'src/components/atoms/TableBody';
import TableCell from 'src/components/atoms/TableCell';
import TableContainer from 'src/components/atoms/TableContainer';
import TableHead from 'src/components/atoms/TableHead';
import TableRow from 'src/components/atoms/TableRow';

// Check later to extends to infer column names on data properties names.
export interface TableProps {
  columns: { label: string; field: string; width?: number }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { [test: string]: any }[];
  rowStyles?: Record<string, unknown>;
  getRowStyle?: (rowData: any) => React.CSSProperties;
  cellStyles?: Record<string, unknown>;
}

const DataTable: FC<TableProps> = (props) => {
  const { columns, data, rowStyles, getRowStyle, cellStyles } = props;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow style={rowStyles}>
            {columns.map(({ label, width }, idx) => (
              <TableCell key={`${label}-${idx}`} width={width} style={cellStyles}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, idx) => (
            <TableRow key={`row-${idx}`} style={getRowStyle?.(row) || rowStyles}>
              {columns.map(({ field }) => (
                <TableCell key={`${field}-${row.id}` || `${field}_${idx}`} style={cellStyles}>
                  {row[field]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
