import { Card } from 'src/components/atoms/Card';
import Gap from 'src/components/atoms/Gap';
import Text, { TextVariant } from 'src/components/atoms/Text';
import { ListView } from 'src/components/templates';

// Helpers
import { configVars } from 'src/helpers';

//Components
import { IFrame } from 'src/components/organisms/IFrame';

// Hooks
import { useAsync } from 'src/hooks';
import useReporting from '../ReportingPage/hooks/useReporting';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Redux
import { selectors as apiUserSelectors } from 'src/features/auth/authSlice';
import { ShowState } from 'src/components/molecules/ShowState';
import { Loader } from 'src/components/atoms/Loader';
import { CenteredContainer } from './styles';

const SLAPage: React.FC = () => {
  const userIsIdle = useSelector(apiUserSelectors.isIdle);
  const userIsLoading = useSelector(apiUserSelectors.isLoading);

  const { fetchIframeUrl } = useReporting();

  const generateReport = async () => {
    const reportParams = configVars.metabase?.dashboards?.reporting_sla;

    if (reportParams) {
      const { id, report_type } = reportParams;

      const result = await fetchIframeUrl({
        id,
        payload: {
          report_type
        },
        isFiltered: false
      });

      return result;
    }
  };

  const [{ result, error }, callGenerateReport] = useAsync(generateReport, '');

  useEffect(() => {
    if (!userIsIdle && !userIsLoading) callGenerateReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsIdle]);

  if (error) {
    return (
      <ShowState
        type="information"
        variant="error"
        message={error as string}
        buttonLabel="Reload"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (!result.data?.iframe_url) {
    return (
      <CenteredContainer>
        <Loader />
      </CenteredContainer>
    );
  }

  return (
    <ListView title="Statistics">
      <Card>
        <Text variant={TextVariant.normal}>Application Processing Time</Text>
        <Gap height={1} />
        <IFrame url={result.data?.iframe_url} title="Average Report Time" />
      </Card>
    </ListView>
  );
};

export default SLAPage;
