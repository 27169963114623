import { Card } from 'src/components/atoms/Card';
import Grid from 'src/components/atoms/Grid';
import styled from 'styled-components/macro';

export const StyledGrid = styled(Grid)`
  overflow-y: auto;
  overflow-x: hidden;
  height: fit-content;
  width: 60%;
`;

export const StyledPaginationContainer = styled(Grid)`
  width: 60%;
  padding: 0rem 2rem;
`;

export const StyledInstructionContainer = styled(Grid)`
  position: sticky;
  margin-top: 2rem;
  margin-right: 3rem;
  width: 40%;
  align-self: flex-start;
`;

export const StyledInstructionCard = styled(Card)`
  height: 20rem;
`;
