import PropTypes from 'prop-types';
import Gap from 'src/components/atoms/Gap';
import Row from '../../atoms/Row/Row';
import SkeletonPiece, { SkeletonPieceColor } from '../../atoms/SkeletonPiece';
import { Container } from './styles';

export type LoaderTransferListProps = {
  rows?: number;
};

const LoaderTransferList: React.FC<LoaderTransferListProps> = (props) => {
  const { rows } = props;
  return (
    <div data-testid="LoaderTransferList">
      {Array(rows)
        .fill(0)
        .map((_, i) => {
          return (
            <Container key={i}>
              <Gap height={0.5} />
              <Row
                gap={0.8}
                wrap="nowrap"
                justify="flex-start"
                alignItems="center"
                alignContent="stretch"
                className="Skeleton___Box"
              >
                <SkeletonPiece width={20} height={20} color={SkeletonPieceColor.gray200} />
                <SkeletonPiece width={200} height={12} color={SkeletonPieceColor.gray100} />
              </Row>
              <Gap height={0.5} />
            </Container>
          );
        })}
    </div>
  );
};
LoaderTransferList.propTypes = {
  rows: PropTypes.number
};

LoaderTransferList.defaultProps = {
  rows: 6
};

export default LoaderTransferList;
