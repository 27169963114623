import { fdeEntryApi } from 'src/api';
import { EntryRequestDocumentsRequestType, EntryRequestType, IEntry } from 'src/ts/interfaces';

const api = {
  getEntriesByFolderId(folderId: string) {
    return fdeEntryApi.getEntries(`folder_id=${folderId}`);
  },
  getEntryById(entryId: string) {
    return fdeEntryApi.getEntry(entryId);
  },
  updateEntry(entryId: string, entry: Partial<IEntry>) {
    return fdeEntryApi.updateEntry(entryId, { entry } as EntryRequestType);
  },
  mergeEntry(matchingEntryId: string, entryId: string) {
    return fdeEntryApi.mergeEntry(matchingEntryId, entryId);
  },
  splitEntry(entryId: string) {
    return fdeEntryApi.splitEntry(entryId);
  },
  sendReport(entryId: string) {
    return fdeEntryApi.sendReport(entryId);
  },
  requestDocuments(entryId: string, message: string) {
    return fdeEntryApi.requestDocuments(entryId, { message } as EntryRequestDocumentsRequestType);
  }
};

export default api;
