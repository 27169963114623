// Vendor
import { useParams } from 'react-router-dom';

// Components
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import {
  FolderTileContainer,
  PotentialMatchingTileContainer,
  SubmissionsTabContainer
} from 'src/components/folders/containers';
import { ShowState } from 'src/components/molecules/ShowState';

// Provider
import { FolderPageProvider } from 'src/components/folders/FolderPageContext';

// Hooks
import { useFolderById } from 'src/features/folders/hooks';

const FolderPageContent: React.FC = () => {
  const { folderId } = useParams();
  const { folder, isLoading } = useFolderById(folderId as string);

  if (!folder && !isLoading) {
    return (
      <ShowState
        type="application"
        variant="empty"
        message="The application is no longer on the system"
      />
    );
  }

  return (
    <FolderPageProvider>
      <Row columns={2} justify="space-between" alignItems="stretch" gap={1}>
        <Row.Col size={6}>
          <FolderTileContainer />
        </Row.Col>
        <Row.Col size={6}>
          <PotentialMatchingTileContainer />
        </Row.Col>
      </Row>
      <Gap height={2.5} />
      <Row>
        <Row.Col size="100%">
          <SubmissionsTabContainer propertyId={folder?.property_id} />
        </Row.Col>
      </Row>
    </FolderPageProvider>
  );
};

export default FolderPageContent;
