import { ReactNode } from 'react';
import Header from 'src/components/atoms/Header';
import { Container, Content, Wrapper } from './styles';

type InvitationProps = { children: ReactNode };
const Invitation: React.FC<InvitationProps> = (props: InvitationProps) => {
  const { children } = props;

  return (
    <Container data-testid="InvitationTemplate">
      <Wrapper>
        <Header />
        <Content>{children}</Content>
      </Wrapper>
    </Container>
  );
};

export default Invitation;
