import Grid from 'src/components/atoms/Grid';
import Text from 'src/components/atoms/Text';
import styled from 'styled-components/macro';

export const StyledGrid = styled(Grid)`
  margin-left: 22px;
`;

export const StyledText = styled(Text)`
  margin-left: 0.5rem;
`;
