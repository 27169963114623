// Vendor
import { FC, useEffect, useState } from 'react';

// Components
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { AutoSavingNoteForm } from 'src/components/molecules/AutoSavingNoteForm';
import { ContentContainer, StyledImg, StyledContainer } from '../styles';

// Hooks
import { useProofGetNote } from 'src/features/proofs/hooks';

// Helpers
import checkIfImageExists from 'src/helpers/checkIfImageExists';

// Types
import { CollapsibleTableRowProps } from '../types';

const tempImage = `${process.env.PUBLIC_URL}/assets/images/notPreviewDocument.svg`;

type IImageState = {
  url: string;
  isLoading: boolean;
  hasError: undefined | boolean;
};

const CollapsibleTableRowContent: FC<Pick<CollapsibleTableRowProps, 'proof' | 'onSaveNote'>> = ({
  proof,
  onSaveNote
}) => {
  const { onGetNote } = useProofGetNote();

  const [thumbnail, setThumbnail] = useState<IImageState>({
    url: tempImage,
    isLoading: true,
    hasError: undefined
  });

  const getImage = (image: string) => {
    checkIfImageExists(image, (exists) => {
      if (exists) {
        setThumbnail({ url: image, hasError: false, isLoading: false });
      } else {
        setThumbnail({
          url: tempImage,
          hasError: true,
          isLoading: false
        });
      }
    });
  };

  useEffect(() => {
    if (proof.thumb && thumbnail.isLoading) {
      getImage(proof.thumb);
    }
  }, [proof, thumbnail.isLoading]);

  return (
    <ContentContainer>
      <Row justify="space-between" gap={1.5}>
        <Row.Col size={8}>
          {proof.thumb ? (
            <StyledImg src={thumbnail.url} alt={proof.id} />
          ) : (
            <Text color={TextColor.gray700} variant={TextVariant.h4}>
              Document is not available to view
            </Text>
          )}
        </Row.Col>
        <Row.Col size={4}>
          <StyledContainer>
            <AutoSavingNoteForm
              name="note"
              placeholder="Document Notes"
              defaultValue={onGetNote(proof.result, proof.note as string)}
              onSubmit={({ note }) => onSaveNote?.(proof.id, note)}
              isReadonly={true}
            />
          </StyledContainer>
        </Row.Col>
      </Row>
    </ContentContainer>
  );
};

export default CollapsibleTableRowContent;
