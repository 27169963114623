import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import useDebounce from 'src/hooks/useDebounce';
import { InputText, InputTextType } from 'src/components/atoms/InputText';
import { AutoSavingNoteFormProps } from './types';

const AutoSavingNoteForm: FC<AutoSavingNoteFormProps> = ({
  name,
  placeholder,
  defaultValue = '',
  onSubmit,
  isReadonly
}) => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      [name]: defaultValue
    }
  });
  const { [name]: value } = methods.watch();
  const debouncedValue = useDebounce(value, 800);

  useEffect(() => {
    if (debouncedValue !== defaultValue) {
      methods.handleSubmit(onSubmit)();
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (defaultValue) {
      methods.reset({ [name]: defaultValue });
    }
  }, [defaultValue]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputText
          name={name}
          placeholder={placeholder}
          isReadonly={isReadonly}
          type={InputTextType.text}
          showOptionalLabel={false}
          multiline={true}
        />
      </form>
    </FormProvider>
  );
};

export default AutoSavingNoteForm;
