import PropTypes from 'prop-types';
import { Icon } from '../Icon';
import Text, { TextAlign, TextColor, TextVariant } from '../Text';
import ToolTip, { ToolTipDirection } from '../ToolTip/ToolTip';
import { HelpTextIcon, StepContainer } from './styles';

export enum StepState {
  completed = 'completed',
  current = 'current',
  disabled = 'disabled',
  uncompleted = 'uncompleted'
}

export type StepProps = React.HTMLAttributes<HTMLDivElement> & {
  state: StepState;
  label: string;
  helpText?: string | null;
  className?: string;
};

const getStepTextColor = (state: StepState) => {
  switch (state) {
    case StepState.completed:
    case StepState.current:
      return TextColor.primary;
    case StepState.disabled:
      return TextColor.gray300;
    case StepState.uncompleted:
      return TextColor.gray200;
    default:
      return TextColor.primary;
  }
};

const Step: React.FC<StepProps> = (props) => {
  const { state, label, helpText, onClick } = props;

  const cls = `Step__container Step__container_${state} ` + (props.className || '');

  return (
    <StepContainer data-disabled={state === StepState.disabled} className={cls} onClick={onClick}>
      <div className="Step__circle">
        <Icon icon={`step-${state}`} />
      </div>
      <div className="Step__label">
        <Text variant={TextVariant.small} align={TextAlign.center} color={getStepTextColor(state)}>
          {label}
        </Text>
        {state === StepState.disabled && helpText && (
          <div className="Step__helpText">
            <ToolTip
              content={<div dangerouslySetInnerHTML={{ __html: helpText }}></div>}
              direction={ToolTipDirection.bottom}
            >
              <HelpTextIcon />
            </ToolTip>
          </div>
        )}
      </div>
    </StepContainer>
  );
};

Step.propTypes = {
  state: PropTypes.oneOf<StepState>(Object.values(StepState)).isRequired,
  label: PropTypes.string.isRequired,
  helpText: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func
};

Step.defaultProps = {
  state: StepState.uncompleted
};

export default Step;
