import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import SkeletonPiece, { SkeletonPieceColor } from 'src/components/atoms/SkeletonPiece';
import { StyledCard, SkeletonPieceWithMargin } from '../styles';

export type CompanyListLoaderProps = {
  rows?: number;
};

const CompanyListLoader = ({ rows = 5 }: CompanyListLoaderProps): JSX.Element => {
  return (
    <div data-testid="CompanyListLoader">
      {Array(rows)
        .fill(0)
        .map((_, i) => {
          return (
            <div key={`CompanyListLoader-row-${i}`}>
              <Line height="tiny" />
              <StyledCard>
                <Row>
                  <Row.Col>
                    <SkeletonPiece
                      isRounded
                      height={40}
                      width={40}
                      color={SkeletonPieceColor.gray200}
                    />
                  </Row.Col>
                  <Row.Col style={{ flex: 1, marginLeft: 16 }}>
                    <SkeletonPieceWithMargin
                      height={16}
                      width={200}
                      color={SkeletonPieceColor.gray200}
                    />
                    <SkeletonPieceWithMargin
                      height={16}
                      width={400}
                      color={SkeletonPieceColor.gray200}
                    />
                    <SkeletonPieceWithMargin
                      height={28}
                      width={100}
                      color={SkeletonPieceColor.gray200}
                    />
                  </Row.Col>
                </Row>
              </StyledCard>
            </div>
          );
        })}
    </div>
  );
};

export default CompanyListLoader;
