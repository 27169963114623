import { IdentityApplicantsIdVerificationIdReportGetPresetEnum } from 'src/lib/internal/epa/gen';

export { IdentityApplicantsIdVerificationIdReportGetPresetEnum as IdentityReportPreset };

export enum fdeInboxTabs {
  DOCUMENTS = 0,
  IDENTIFICATION = 1
}

export enum LinkAction {
  COPY = 0,
  NEW_TAB = 1
}
