// Vendor
import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

// Redux
import { RootState } from 'src/store';

// Types

// Constants
import { NAME } from './constants';

// Enums
import { Status as StatusTypes } from 'src/ts/enums';
import { getFeatureFlagThunk, getUnauthenticatedFeatureFlagThunk } from './services';

type StateData = {
  featureFlagState: {
    status: StatusTypes;
    error?: string | null;
    data?: { [k: string]: string };
  };
  unauthenticatedFeatureFlagState: {
    status: StatusTypes;
    error?: string | null;
    data?: { [k: string]: string };
  };
};

const initialState: StateData = {
  featureFlagState: {
    status: StatusTypes.IDLE,
    data: {},
    error: null
  },
  unauthenticatedFeatureFlagState: {
    status: StatusTypes.IDLE,
    data: {},
    error: null
  }
};

export const featureFlagSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    reset: () => {
      return {
        ...initialState
      };
    },
    resetApplicantByVerificationId: (state: StateData) => {
      state.featureFlagState = initialState.featureFlagState;
    }
  },
  extraReducers: (builder) => {
    getFeatureFlagReducer(builder);
    getUnauthenticatedFeatureFlagReducer(builder);
  }
});

const getFeatureFlagReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getFeatureFlagThunk.pending, (state) => {
    state.featureFlagState.status = StatusTypes.LOADING;
    state.featureFlagState.error = null;
  });
  builder.addCase(getFeatureFlagThunk.fulfilled, (state, action: PayloadAction<any>) => {
    state.featureFlagState.status = StatusTypes.SUCCESS;
    if (state.featureFlagState.data && typeof action.payload.result === 'boolean')
      state.featureFlagState.data[action.payload.featureFlagKey] = action.payload.result;
  });
  builder.addCase(getFeatureFlagThunk.rejected, (state, action) => {
    state.featureFlagState.status = StatusTypes.ERROR;
    state.featureFlagState.error = action.error?.message;
  });
};

const getUnauthenticatedFeatureFlagReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getUnauthenticatedFeatureFlagThunk.pending, (state) => {
    state.unauthenticatedFeatureFlagState.status = StatusTypes.LOADING;
    state.unauthenticatedFeatureFlagState.error = null;
  });
  builder.addCase(
    getUnauthenticatedFeatureFlagThunk.fulfilled,
    (state, action: PayloadAction<any>) => {
      state.unauthenticatedFeatureFlagState.status = StatusTypes.SUCCESS;
      if (state.unauthenticatedFeatureFlagState.data && typeof action.payload.result === 'boolean')
        state.unauthenticatedFeatureFlagState.data[action.payload.featureFlagKey] =
          action.payload.result;
    }
  );
  builder.addCase(getUnauthenticatedFeatureFlagThunk.rejected, (state, action) => {
    state.unauthenticatedFeatureFlagState.status = StatusTypes.ERROR;
    state.unauthenticatedFeatureFlagState.error = action.error?.message;
  });
};

export const getFeatureFlagIsIdle = (state: RootState) =>
  state[NAME].featureFlagState.status === StatusTypes.IDLE;

export const getFeatureFlagIsLoading = (state: RootState) =>
  state[NAME].featureFlagState.status === StatusTypes.LOADING;

const getFeatureFlagState = (state: RootState) => state[NAME].featureFlagState;

const getFeatureFlagKey = (_: RootState, featureFlagKey: string) => featureFlagKey;

export const getFeatureFlagValue = createSelector(
  getFeatureFlagState,
  getFeatureFlagKey,
  (featureFlagState, featureFlagKey) => {
    return featureFlagState.data ? featureFlagState.data[featureFlagKey] : false;
  }
);

export const getUnauthenticatedFeatureFlagIsLoading = (state: RootState) =>
  state[NAME].featureFlagState.status === StatusTypes.LOADING;

const getUnauthenticatedFeatureFlagState = (state: RootState) =>
  state[NAME].unauthenticatedFeatureFlagState;

export const getUnauthenticatedFeatureFlagValue = createSelector(
  getUnauthenticatedFeatureFlagState,
  getFeatureFlagKey,
  (featureFlagState, featureFlagKey) => {
    return featureFlagState.data ? featureFlagState.data[featureFlagKey] : false;
  }
);

export const { reducer, actions } = featureFlagSlice;
