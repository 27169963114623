/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { FC, useEffect, useMemo, useState } from 'react';

// Components
import Button, { ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import { Card } from 'src/components/atoms/Card';
import { DropdownButton } from 'src/components/atoms/DropdownButton';
import Grid from 'src/components/atoms/Grid';
import { Icon } from 'src/components/atoms/Icon';
import { Line } from 'src/components/atoms/Line';
import { RadioButtonData } from 'src/components/atoms/RadioButton';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { RadioButtonGroup } from 'src/components/molecules/RadioButtonGroup';
import { Container, DDButtonContainer, Item, List, Wrapper } from './styles';

// Types
import { DropdownItemData, SelectedSortData, SortData, SortProps } from './types';

const Sort: FC<SortProps> = ({ data, selected, onSelect, onClear, isDisabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState('normal');
  const [value, setValue] = useState<SortData>(data);

  const localSelected = useMemo<SelectedSortData>(() => {
    const field = value?.dropdownItems?.find((item) => item?.isSelected)?.value;
    const order = value?.radioGroup?.find((item) => item?.isChecked)?.value;

    return { field, order };
  }, [value]);

  const label = useMemo(
    () => value?.dropdownItems?.find((item) => item.value === selected?.field)?.label,
    [value, selected]
  );

  const enabled = useMemo(() => {
    const { field, order } = localSelected;

    if ((!selected?.field && !field) || !order) return false;

    return !(selected?.field === field && selected?.order === order);
  }, [value, selected]);

  useEffect(() => {
    if (isOpen) {
      setStatus(label ? 'full' : 'selected');
    } else {
      setStatus(label ? 'active' : 'normal');
    }
  }, [isOpen, label]);

  useEffect(() => {
    const dropdownItems = value?.dropdownItems?.map((item) => ({
      ...item,
      isSelected: item.value === selected?.field
    }));
    const radioGroup = value?.radioGroup?.map((item) => ({
      ...item,
      isChecked: item.value === selected?.order
    }));

    setValue({ dropdownItems, radioGroup });
  }, [isOpen, selected]);

  const handleDropdownItem = (selected: DropdownItemData) => {
    const newData = value?.dropdownItems?.map((item) => {
      const isSelected = item.value === selected.value;

      return { ...item, isSelected };
    });

    setValue((prevState) => ({ ...prevState, dropdownItems: newData }));
  };

  const handleRadioGroup = (value: RadioButtonData[]) => {
    setValue((prevState) => ({ ...prevState, radioGroup: value }));
  };

  const handleApply = () => {
    onSelect(localSelected);
    setIsOpen(false);
  };

  const handleClear = () => {
    onSelect({ field: undefined, order: 'asc' });
    setIsOpen(false);
    onClear && onClear();
  };

  const handleCancel = () => {
    onSelect({ ...selected });
    setIsOpen(false);
  };

  return (
    <Wrapper className="Sort">
      <Row alignItems="center" gap={0.625}>
        <Text color={TextColor.gray600}>Sort by</Text>
        <DDButtonContainer className={status}>
          <DropdownButton
            name="dropdown-button-sort"
            isOpen={isOpen}
            onClosed={() => setIsOpen(true)}
            onOpened={() => setIsOpen(false)}
            showLabel={false}
            isDisabled={isDisabled}
          >
            <Row gap={0.5}>
              <Text color="inherit" variant={TextVariant.small}>
                {label ?? 'Select'}
              </Text>
              {label && selected?.order && (
                <Icon icon={`arrow-${selected?.order === 'asc' ? 'up' : 'down'}`} />
              )}
            </Row>
          </DropdownButton>
        </DDButtonContainer>
      </Row>
      {isOpen && (
        <Container>
          <Card border rounded alignContent="left">
            <Row direction="column" gap={0.75}>
              <Row.Col size={1}>
                <Grid justify="space-between" alignItems="center">
                  <Text color={TextColor.initial}>Sorting</Text>
                  <Button
                    name="button-clear-sorting"
                    variant={ButtonVariant.ghost}
                    onClick={handleClear}
                  >
                    Clear sorting
                  </Button>
                </Grid>
              </Row.Col>
              <Line />
              <List>
                {value?.dropdownItems?.map((option) => (
                  <Item
                    key={option.value}
                    className={option?.isSelected ? 'selected' : ''}
                    onClick={() => handleDropdownItem(option)}
                  >
                    {option.label}
                    {option?.isSelected && <Icon icon="icon-success-clean" />}
                  </Item>
                ))}
              </List>
              <Line />
              <RadioButtonGroup
                data-id="options"
                name="sortRadioGroup"
                data={value?.radioGroup}
                onChange={handleRadioGroup}
              />
              <Line />
              <Row gap={1}>
                <Button
                  data-id="applyButton"
                  name="button-apply-sort"
                  onClick={handleApply}
                  size={ButtonSize.medium}
                  variant={ButtonVariant.contained}
                  isDisabled={!enabled}
                >
                  Apply
                </Button>
                <Button
                  data-id="cancelButton"
                  name="button-cancel-sort"
                  onClick={handleCancel}
                  size={ButtonSize.medium}
                  variant={ButtonVariant.outline}
                >
                  Cancel
                </Button>
              </Row>
            </Row>
          </Card>
        </Container>
      )}
    </Wrapper>
  );
};

export default Sort;
