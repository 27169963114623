import styled from 'styled-components/macro';

import { Card } from 'src/components/atoms/Card';
import { Icon } from 'src/components/atoms/Icon';
import Button from 'src/components/atoms/Button';
import { Row } from 'src/components/atoms/Row';
import { InputText, InputTextType } from 'src/components/atoms/InputText';

export const StyledCard = styled(Card)`
  & > div {
    padding: 1.5rem;
  }
`;

export const StyledRowInfoSection = styled(Row).attrs({
  direction: 'column',
  gap: 0.625
})`
  margin-bottom: 1rem;
`;

export const ActionSection = styled.div`
  display: flex;
  gap: 0.625rem;
  border-top: 0.125rem solid ${({ theme }) => theme.color.gray[200]};
  padding-top: 1rem;
`;

export const StyledButton = styled(Button)`
  height: 2.25rem;
  width: 5rem;
  display: flex;
  justify-content: center;
`;

export const StyledInputText = styled(InputText).attrs((attrs) => ({
  type: InputTextType.text,
  showLabel: false,
  showOptionalLabel: false
}))`
  & > div {
    margin-bottom: 0;

    & > input {
      height: 3.125rem;
      width: 17.8125rem;
    }
  }
`;

// ICONS

export const CompanyIcon = styled(Icon).attrs((attrs) => ({
  icon: 'company-model-icon',
  color: 'muted'
}))`
  width: 1.875rem;
`;

export const PersonIcon = styled(Icon).attrs((attrs) => ({
  icon: 'person-icon',
  color: 'muted'
}))`
  width: 1.875rem;
`;
