// Vendor
import moment from 'moment';

import {
  IncomeCalculationDocument,
  IncomeCalculationStatusDetail
} from '@snapptinc/fraud-platform';
import { useSelector } from 'react-redux';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import { selectors as entryReportSelectors } from 'src/features/fde-report/fdeReportSlice';
import { currencyFormat } from 'src/helpers/currencyFormat';
import {
  dateCodeExists,
  getCalendarIconColor,
  getPersonIconColor,
  recipientNameCodeExists
} from 'src/pages/ReportPage/helpers';
import { Container } from './styles';

const IncomeVerificationProofResultDetail = ({
  document,
  statusDetails
}: {
  document: IncomeCalculationDocument;
  statusDetails: IncomeCalculationStatusDetail[];
}) => {
  const entryReport = useSelector(entryReportSelectors.getEntryReport.data);
  const { applicant } = entryReport || {};

  const { gross_income, applicant_name, document_start_date, document_end_date, income_source } =
    document;

  const grossIncomeAvailable = gross_income > 0;
  const grossIncomeText = grossIncomeAvailable ? currencyFormat(+gross_income) : 'Not Available';
  const grossIncomeIconColor = grossIncomeAvailable ? 'black' : 'yellow';

  const applicantNameMatches =
    applicant_name.includes(applicant?.first_name ?? '') &&
    applicant_name.includes(applicant?.last_name ?? '');

  const recipientNameErrorMessage = () => {
    if (recipientNameCodeExists(statusDetails)) {
      return 'Does not match across documents';
    } else if (!applicantNameMatches) {
      return 'Does not match applicant name';
    }
  };
  return (
    <Container>
      <Row gap={1.5} className="proof-result-detail-row">
        <Row.Col>
          <Icon
            strokeColor={grossIncomeIconColor}
            className="income-verification-icons"
            icon="money"
          ></Icon>
        </Row.Col>
        <Row.Col className="text-detail">{grossIncomeText}</Row.Col>
        {!grossIncomeAvailable && <Row.Col>Document does not contain gross pay</Row.Col>}
      </Row>
      <Gap height={1.5} />
      <Row gap={1.5} className="proof-result-detail-row">
        <Row.Col>
          <Icon className="income-verification-icons" icon="income-source"></Icon>
        </Row.Col>
        <Row.Col>{income_source}</Row.Col>
      </Row>
      <Gap height={1.5} />
      <Row gap={1.5} className="proof-result-detail-row">
        <Row.Col>
          <Icon
            color={getPersonIconColor(statusDetails)}
            className="income-verification-icons"
            icon="person"
          ></Icon>
        </Row.Col>
        <Row.Col>{applicant_name}</Row.Col>
        <Row.Col>{recipientNameErrorMessage()}</Row.Col>
      </Row>
      <Gap height={1.5} />
      <Row gap={1.5} className="proof-result-detail-row">
        <Row.Col>
          <Icon
            color={getCalendarIconColor(statusDetails)}
            className="income-verification-icons"
            icon="calendar"
          ></Icon>
        </Row.Col>
        <Row.Col>
          {moment.utc(document_start_date).format('MMM. D, yyyy')} -{' '}
          {moment.utc(document_end_date).format('MMM. D, yyy')}
        </Row.Col>
        {dateCodeExists(statusDetails) && <Row.Col>Does not meet date requirements</Row.Col>}
      </Row>
    </Container>
  );
};

export default IncomeVerificationProofResultDetail;
