// Vendor
import React from 'react';
import { useSelector } from 'react-redux';

// Components
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import { Pagination } from 'src/components/molecules/Pagination';

// Hooks
import useCompany from 'src/features/company/hooks/useCompany';

// Redux
import { selectors as companySelector } from 'src/features/company/companySlice';

const CompanyListPagination: React.FC = () => {
  const { onGetAll } = useCompany();

  const companiesData = useSelector(companySelector.getAll.data);
  const companiesIsLoading = useSelector(companySelector.getAll.isLoading);
  const companiesError = useSelector(companySelector.getAll.error);
  const companiesFilter = useSelector(companySelector.getAll.filter);
  const companiesCount = useSelector(companySelector.getAll.count);

  if (companiesError || !companiesData.length || companiesIsLoading) return null;

  return (
    <>
      <Line height="tiny" />
      <Row direction="column" className="paginator__ListView">
        <Pagination
          name="CompanyListPagination"
          rowsPerPage={[5, 10, 20, 50, 100, 250, 500]}
          listPosition="top"
          pageInit={companiesFilter.page}
          rowsPerPageInit={companiesFilter.rowsPerPage}
          totalRows={companiesCount}
          onNext={(page, rowsPerPage) => onGetAll({ page, rowsPerPage })}
          onPrev={(page, rowsPerPage) => onGetAll({ page, rowsPerPage })}
          onRowsPerPage={(page, rowsPerPage) => onGetAll({ page, rowsPerPage })}
        />
      </Row>
    </>
  );
};

export default CompanyListPagination;
