/* eslint-disable @typescript-eslint/no-explicit-any */
import { NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

export const ButtonItemComponent = styled.button<any>`
  cursor: pointer;
  border: none;
  width: 100%;

  div {
    align-items: center;
    display: flex;
  }
  ${({ theme }) => theme.navigation}

  :hover {
    ${({ theme }) => theme.navigation.hover}
  }

  &.active {
    ${({ theme }) => theme.navigation.active}
  }
`;

export const NavItemComponent = styled(NavLink)<any>`
  div {
    align-items: center;
    display: flex;
  }

  ${({ theme }) => theme.navigation}

  :hover {
    ${({ theme }) => theme.navigation.hover}
  }

  &.active {
    ${({ theme }) => theme.navigation.active}
  }

  @media (max-height: 440px) {
    object {
      height: 1rem;
      width: 1rem;
      margin-left: 0.1rem;
    }
  }
`;

export const IconComponent = styled(ReactSVG)`
  width: 2rem;
  svg {
    margin-right: 0.8rem;
  }
  @media (max-height: 440px) {
    svg {
      height: 0.8rem;
      margin: 0rem;
    }
  }
`;

export const AvatarComponent = styled.img`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.white};
`;

export const LogoWrapper = styled.div`
  width: 1.8rem !important;
  height: 1.8rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.white};
  margin-right: 0.55rem;
  margin-left: -0.3rem;
`;

export const LabelComponent = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
