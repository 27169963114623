import PropTypes from 'prop-types';
import { Row } from 'src/components/atoms/Row';
import SkeletonPiece, { SkeletonPieceColor } from 'src/components/atoms/SkeletonPiece';
import { Container } from './styles';

export type FolderRowLoaderProps = {
  rows?: number;
};

const FolderRowLoader: React.FC<FolderRowLoaderProps> = (props) => {
  const { rows } = props;

  return (
    <div data-testid="FolderRowLoader">
      {Array(rows)
        .fill(0)
        .map((_, i) => {
          return (
            <Container key={i}>
              <Row direction="column" gap={1.5} key={i}>
                <Row>
                  <SkeletonPiece height={20} width={247} color={SkeletonPieceColor.gray200} />
                  <SkeletonPiece height={20} width={114} color={SkeletonPieceColor.gray200} />
                </Row>
                <Row.Col>
                  <SkeletonPiece height={40} width={943} color={SkeletonPieceColor.gray100} />
                </Row.Col>
              </Row>
            </Container>
          );
        })}
    </div>
  );
};

FolderRowLoader.propTypes = {
  rows: PropTypes.number
};

FolderRowLoader.defaultProps = {
  rows: 5
};
export default FolderRowLoader;
