import { Fetch } from 'src/adapters';
import { authApi } from 'src/api';
import { configVars } from 'src/helpers';
import { IAuthenticatedUser } from 'src/ts/interfaces';

const api = {
  login: (): void => {
    //Session added to cookies from server
    window.location.href = `${configVars.server_url}/auth`;
  },

  fetchCurrentUser: async () => {
    const response = await authApi.currentAuthenticatedUser();
    if (!response) {
      // This will be caught by the thunk and wont break the app
      throw new Error('Error fetching current user');
    }

    return { ...(response.data || {}) } as IAuthenticatedUser;
  },

  refreshSession: async (): Promise<Response> => Fetch.put(`${configVars.server_url}/auth/refresh`),

  logout: (): void => {
    window.location.href = `${configVars.server_url}/auth/logout`;
  }
};

export default api;
