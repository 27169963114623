/* eslint-disable no-useless-escape */
const cleanFileName = (name?: string) => {
  return (
    name
      ?.trim()
      .replace(/ /g, '_')
      ?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') ?? 'NoName'
  );
};

export default cleanFileName;
