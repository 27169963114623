import Button from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { InputText, InputTextType } from 'src/components/atoms/InputText';
import { Row } from 'src/components/atoms/Row';
import Text from 'src/components/atoms/Text';
import ButtonIcon from 'src/components/molecules/ButtonIcon';
import styled from 'styled-components/macro';

export const StyledContainer = styled.div`
  padding: 0rem 2rem;
`;

export const CenteredContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline;
  max-width: 420px;
  line-height: 24px;
  margin-right: 8px;
`;

export const StyledRow = styled(Row)`
  margin-bottom: 8px;
`;

export const SpacedRow = styled(Row)`
  margin-top: 20px;
`;

export const FolderIcon = styled(Icon).attrs(() => ({
  icon: 'folder-icon-grey',
  color: 'muted'
}))`
  width: 1.875rem;
`;

export const CompanyIcon = styled(Icon).attrs(() => ({
  icon: 'company-model-icon',
  color: 'muted'
}))`
  width: 1.875rem;
  height: 1.4rem;
`;

export const EditIcon = styled(Icon).attrs(() => ({
  icon: 'edit-icon-small',
  color: 'primary'
}))``;

export const GroupIcon = styled(Icon).attrs(() => ({
  icon: 'group-icon',
  color: 'primary'
}))`
  margin-left: 12px;
`;

export const StyledButton = styled(Button)`
  height: 32px;
  width: 32px;
  color: ${({ theme }) => theme.color.blue[700]};
  border: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  &:hover {
    background: ${({ theme }) => theme.color.gray[200]};
    color: ${({ theme }) => theme.color.blue[700]};
  }
`;

export const StyledInputText = styled(InputText).attrs((attrs) => ({
  type: InputTextType.text,
  showLabel: false,
  showOptionalLabel: false
}))`
  & > div {
    margin-bottom: 0px;
    margin-right: 8px;

    & > input {
      height: 1.5rem;
      width: 13rem;
    }
  }
`;

export const StyledIconButtonBlue = styled(ButtonIcon)`
  border: 1px solid ${({ theme }) => theme.color.blue[700]};
  width: 48px;
  height: 36px;
  padding: 8px 14px;
  margin-left 8px;
`;

export const StyledIconButtonRed = styled(StyledIconButtonBlue)`
  border: 1px solid ${({ theme }) => theme.color.red[700]};
`;

export const Spacer = styled.div`
  height: 16px;
  width: 16px;
`;
