export const defaultValues = {
  filterPanel: {
    startDate: '',
    endDate: '',
    radioGroup: [
      {
        value: 'READY',
        label: 'Ready',
        isChecked: false
      },
      {
        value: 'VIEWED',
        label: 'Viewed',
        isChecked: false
      },
      {
        value: 'PENDING',
        label: 'Pending',
        isChecked: false
      }
    ],
    checkboxGroup: [
      {
        value: 'CLEAN',
        label: 'Clean',
        isChecked: false
      },
      {
        value: 'EDITED',
        label: 'Edited',
        isChecked: false
      },
      {
        value: 'CLEAN_PROCEED_WITH_CAUTION',
        label: 'Clean - Proceed with Caution',
        isChecked: false
      },
      {
        value: 'UNDETERMINED',
        label: 'Insufficient Documentation',
        isChecked: false
      },
      {
        value: 'PENDING',
        label: 'Pending',
        isChecked: false
      }
    ]
  },
  sortPanel: {
    dropdownItems: [
      {
        label: 'Applicant',
        value: 'folder_name',
        isSelected: false
      },
      {
        label: 'Property',
        value: 'folder_property_name',
        isSelected: false
      },
      {
        label: 'Last entry status',
        value: 'last_entry_status',
        isSelected: false
      },
      {
        label: 'Last entry result',
        value: 'last_entry_result',
        isSelected: false
      },
      {
        label: 'Received',
        value: 'last_entry_submission_time',
        isSelected: false
      }
    ],
    radioGroup: [
      {
        value: 'asc',
        label: 'Ascending',
        isChecked: true
      },
      {
        value: 'desc',
        label: 'Descending',
        isChecked: false
      }
    ]
  }
};

export const selectedSort = {
  field: undefined,
  order: 'asc'
};

export const selectedFilter = {
  results: undefined,
  status: undefined,
  startDate: undefined,
  endDate: undefined
};

export const filterQueryOptions: Record<string, { field: string; operator: string }> = {
  results: {
    field: 'last_entry_result',
    operator: '@>'
  },
  status: {
    field: 'last_entry_status',
    operator: '='
  },
  startDate: {
    field: 'last_entry_submission_time',
    operator: '>'
  },
  endDate: {
    field: 'last_entry_submission_time',
    operator: '<'
  }
};
