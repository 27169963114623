import React, { useEffect, useRef } from 'react';

interface OutsideDetectorProps {
  children: React.ReactNode;
  onClickOutside: (event: MouseEvent) => void;
}

const OutsideDetector: React.FC<OutsideDetectorProps> = ({ children, onClickOutside }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (event.target instanceof HTMLElement) {
          const hasCheckbox = event.target.className.indexOf('checkbox');
          const hasCheckboxLabel = event.target.className.indexOf('label-checkbox');
          if (hasCheckbox < 0 && hasCheckboxLabel > 0) {
            onClickOutside(event);
          }
          if (hasCheckbox === hasCheckboxLabel) {
            onClickOutside(event);
          }
        }
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [onClickOutside]);

  return <div ref={ref}>{children}</div>;
};

export default OutsideDetector;
