import { FC } from 'react';

import { Dot } from 'src/components/atoms/Dot';
import { Row } from 'src/components/atoms/Row';
import Text, { TextAlign, TextColor, TextVariant } from 'src/components/atoms/Text';
import { IProof } from 'src/ts/interfaces';
import {
  calculateWhitelistTestResult,
  createDocumentData,
  createWhitelistData,
  getDotColor
} from '../documentAndTestMetada';
import Field from './Field';

const AutomatedMetadata: FC<Pick<IProof, 'test_meta_data_flags'>> = ({ test_meta_data_flags }) => {
  const whitelistData = createWhitelistData(test_meta_data_flags);
  const documentData = createDocumentData(test_meta_data_flags);

  const whiteListTestContent = () => {
    if (!whitelistData?.length) {
      return (
        <Text align={TextAlign.center} variant={TextVariant.small}>
          No Whitelist Found
        </Text>
      );
    }

    return whitelistData?.map((whitelist, index) => (
      <Row key={index} direction="column" gap={1}>
        <Text align={TextAlign.center} variant={TextVariant.small} isBold>
          {whitelist.source}
        </Text>
        <Row direction="column" gap={0.5}>
          <Field title="Metadata" content={whitelist.metadata} />
          <Field title="Fonts" content={whitelist.fonts} />
        </Row>
      </Row>
    ));
  };

  return (
    <Row direction="column" gap={1}>
      <Row direction="column" gap={1}>
        <Row justify="center" alignItems="center" gap={1}>
          <Text isBold>Whitelist Tests</Text>
          <Dot color={getDotColor(calculateWhitelistTestResult(whitelistData))} />
        </Row>
        <Row.Col>
          <Row direction="column" gap={0.5}>
            {whiteListTestContent()}
          </Row>
        </Row.Col>
      </Row>
      <Row direction="column" gap={1}>
        <Text align={TextAlign.center} isBold>
          Document Tests
        </Text>
        <Row direction="column" gap={0.5}>
          {documentData?.map((flag, index) => (
            <Row key={index} gap={1}>
              <Row.Col size={3}>
                <Row justify="flex-end" alignItems="center" gap={0.5}>
                  <Text color={TextColor.gray700} variant={TextVariant.small} isBold>
                    {flag.field}
                  </Text>
                  <Dot color={getDotColor(flag.suggestedRuling)} />
                </Row>
              </Row.Col>
              <Row.Col size={9}>
                <Text color={TextColor.gray700} variant={TextVariant.small}>
                  {flag.value}
                </Text>
              </Row.Col>
            </Row>
          ))}
        </Row>
      </Row>
    </Row>
  );
};

export default AutomatedMetadata;
