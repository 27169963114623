import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { Status } from 'src/ts/enums/status';
import { NAME } from './constants';
import {
  getApplicantReportPDFReducer,
  getEntryReportByIdReducer,
  getFcraReportPDFReducer,
  getPaginatedReportPDFReducer,
  getReportPDFReducer,
  getReportsByEntryIdReducer,
  initialState,
  StateData
} from './reducers';

const fdeReportSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetEntryReport: (state: StateData) => {
      state.getEntryReportById = initialState.getEntryReportById;
    },
    resetGetPaginatedReportPDF: (state: StateData) => {
      state.getPaginatedReportPDF = initialState.getPaginatedReportPDF;
    },
    resetGetReportPDF: (state: StateData) => {
      state.getReportPDF = initialState.getReportPDF;
    },
    resetGetFcraReportPDF: (state: StateData) => {
      state.getFcraReportPDF = initialState.getFcraReportPDF;
    },
    resetGetApplicantReportPDF: (state: StateData) => {
      state.getApplicantReportPDF = initialState.getApplicantReportPDF;
    },
    resetGetReportsByEntryId: (state: StateData) => {
      state.getReportsByEntryId = initialState.getReportsByEntryId;
    }
  },
  extraReducers: (builder) => {
    getEntryReportByIdReducer(builder);
    getPaginatedReportPDFReducer(builder);
    getReportPDFReducer(builder);
    getFcraReportPDFReducer(builder);
    getApplicantReportPDFReducer(builder);
    getReportsByEntryIdReducer(builder);
  }
});

export const selectors = {
  getEntryReport: {
    isLoading: (state: RootState) => state[NAME].getEntryReportById.status === Status.LOADING,
    isIdle: (state: RootState) => state[NAME].getEntryReportById.status === Status.IDLE,
    error: (state: RootState) => state[NAME].getEntryReportById.error,
    data: (state: RootState) => state[NAME].getEntryReportById.data
  },
  getDownloadPaginatedReport: {
    isLoading: (state: RootState) => state[NAME].getPaginatedReportPDF.status === Status.LOADING,
    error: (state: RootState) => state[NAME].getPaginatedReportPDF.error || null,
    file: (state: RootState) => state[NAME].getPaginatedReportPDF.data,
    fileName: (state: RootState) => state[NAME].getPaginatedReportPDF.fileName || ''
  },
  getDownloadReport: {
    isLoading: (state: RootState) => state[NAME].getReportPDF.status === Status.LOADING,
    error: (state: RootState) => state[NAME].getReportPDF.error || null,
    file: (state: RootState) => state[NAME].getReportPDF.data,
    fileName: (state: RootState) => state[NAME].getReportPDF.fileName || ''
  },
  getDownloadFcraReport: {
    isLoading: (state: RootState) => state[NAME].getFcraReportPDF.status === Status.LOADING,
    error: (state: RootState) => state[NAME].getFcraReportPDF.error || null,
    file: (state: RootState) => state[NAME].getFcraReportPDF.data,
    fileName: (state: RootState) => state[NAME].getFcraReportPDF.fileName || ''
  },
  getDownloadApplicantReport: {
    isLoading: (state: RootState) => state[NAME].getApplicantReportPDF.status === Status.LOADING,
    error: (state: RootState) => state[NAME].getApplicantReportPDF.error || null,
    file: (state: RootState) => state[NAME].getApplicantReportPDF.data,
    fileName: (state: RootState) => state[NAME].getApplicantReportPDF.fileName || '',
    preset: (state: RootState) => state[NAME].getApplicantReportPDF.preset || ''
  },
  getReportsByEntryId: {
    isLoading: (state: RootState) => state[NAME].getReportsByEntryId.status === Status.LOADING,
    isIdle: (state: RootState) => state[NAME].getReportsByEntryId.status === Status.IDLE,
    error: (state: RootState) => state[NAME].getReportsByEntryId.error,
    data: (state: RootState) => state[NAME].getReportsByEntryId.data
  }
};

export const { reducer, actions } = fdeReportSlice;
