// Vendor
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

// Redux
import { RootState } from 'src/store';
import { escalateThunk, getPendingReviewEntryThunk, requestReviewEntryThunk } from './services';

// Types
import { IEntry, IRejectedAction, StatusType } from 'src/ts/interfaces';

// Constants
import { NAME } from './constants';

// Enums
import { Status as StatusEnum } from 'src/ts/enums';

type StateData = {
  assignedEntry: IEntry | null;
  getPendingReviewState: {
    status: StatusType;
    error?: string | null;
  };
  requestReviewState: {
    status: StatusType;
    error?: string | null;
  };
  escalate: { status: StatusType; error?: string | null };
};

const initialState: StateData = {
  assignedEntry: null,
  getPendingReviewState: {
    status: StatusEnum.IDLE,
    error: null
  },
  requestReviewState: {
    status: StatusEnum.IDLE,
    error: null
  },
  escalate: { status: StatusEnum.IDLE, error: null }
};

export const myWorkSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    reset: () => {
      return {
        ...initialState
      };
    }
  },
  extraReducers: (builder) => {
    getPendingReviewEntryReducer(builder);
    requestReviewEntryReducer(builder);
    escalateReducer(builder);
  }
});

const requestReviewEntryReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getPendingReviewEntryThunk.pending, (state: StateData) => {
    state.getPendingReviewState = {
      status: StatusEnum.LOADING,
      error: null
    };
  });

  builder.addCase(getPendingReviewEntryThunk.fulfilled, (state: StateData, action) => {
    const response = action.payload;

    state.getPendingReviewState.status = StatusEnum.SUCCESS;
    state.assignedEntry = response as IEntry;
  });

  builder.addCase(getPendingReviewEntryThunk.rejected, (state: StateData, action) => {
    if (action.error.stack?.includes('404')) {
      // If no entry was found adress it on state
      state.getPendingReviewState.status = StatusEnum.SUCCESS;

      state.assignedEntry = null;
    } else {
      state.getPendingReviewState = {
        status: StatusEnum.ERROR,
        error: action.error?.message
      };
    }
  });
};

const getPendingReviewEntryReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(requestReviewEntryThunk.pending, (state: StateData) => {
    state.requestReviewState = {
      status: StatusEnum.LOADING,
      error: null
    };
  });

  builder.addCase(requestReviewEntryThunk.fulfilled, (state: StateData, action) => {
    const response = action.payload;

    state.requestReviewState.status = StatusEnum.SUCCESS;
    state.assignedEntry = response as IEntry;
  });

  builder.addCase(requestReviewEntryThunk.rejected, (state: StateData, action) => {
    state.requestReviewState = {
      status: StatusEnum.ERROR,
      error: action.error?.message
    };
  });
};

const escalateReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(escalateThunk.pending, (state: StateData) => {
    state.escalate.status = StatusEnum.LOADING;
    state.escalate.error = null;
  });
  builder.addCase(escalateThunk.fulfilled, (state: StateData, action) => {
    state.escalate.status = StatusEnum.SUCCESS;
  });
  builder.addCase(escalateThunk.rejected, (state: StateData, action: IRejectedAction) => {
    state.escalate.status = StatusEnum.ERROR;
    state.escalate.error = action.error?.message;
  });
};

export const selectors = {
  isLoadingPendingSubmission: (state: RootState) =>
    state[NAME].getPendingReviewState.status === StatusEnum.LOADING,
  isIdlePendingSubmission: (state: RootState) =>
    state[NAME].getPendingReviewState.status === StatusEnum.IDLE,
  isLoadingSubmissionRequest: (state: RootState) =>
    state[NAME].requestReviewState.status === StatusEnum.LOADING,
  assignedEntry: (state: RootState) => state[NAME].assignedEntry,
  escalate: {
    status: (state: RootState) => state[NAME].escalate.status,
    error: (state: RootState) => state[NAME].escalate.error
  }
};

export const { reducer, actions } = myWorkSlice;
