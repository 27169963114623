import PropTypes from 'prop-types';
import { FC } from 'react';
import { Icon } from '../Icon';

import { Radio } from './styles';
import { RadioButtonProps } from './types';

const RadioButton: FC<RadioButtonProps> = ({ label, name, isChecked, isDisabled, ...props }) => {
  const getState = () => (isChecked ? 'checked' : 'unchecked');
  return (
    <Radio
      {...props}
      disabled={!!isDisabled}
      data-testid="RadioButton"
      role="radio"
      aria-checked={isChecked}
      aria-labelledby={label}
    >
      <Icon icon={`radio-button-${getState()}`} />
      <span className="label">{label}</span>
    </Radio>
  );
};

export const RadioButtonPropTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool
};

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  ...RadioButtonPropTypes
};

RadioButton.defaultProps = {
  name: 'RadioButton',
  isDisabled: false
};

export default RadioButton;
