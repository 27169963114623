// Vendor
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

// Components
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Row } from 'src/components/atoms/Row';
import { AutoSuggest } from 'src/components/molecules/AutoSuggest';

// Hooks
import useSearchCompany from 'src/features/invitation/hooks/useSearchCompany';
import useDebounce from 'src/hooks/useDebounce';

// Helpers
import { formatLabelValue } from 'src/helpers';

// Types
import { IValueLabel } from 'src/ts/interfaces';
import { PartialUser } from '../InvitationFlowPage';

type SelectCompanyFormProps = {
  onSave: (data: PartialUser) => void;
  onCancel: (data: PartialUser) => void;
  defaultValues: PartialUser;
  isDisabled?: boolean;
};

const SelectCompanyForm: React.FC<SelectCompanyFormProps> = ({
  onSave,
  onCancel,
  defaultValues,
  isDisabled
}: SelectCompanyFormProps) => {
  const [searchCompany, setSearchCompany] = useState('');
  const [companySelected, setCompanySelected] = useState(defaultValues.company);
  const {
    onSearch,
    getAll: searchResults,
    onResetGetAll: onResetSearchCompany
  } = useSearchCompany();

  const debouncedSearchCompany = useDebounce(searchCompany, 800);
  useEffect(() => {
    if (debouncedSearchCompany.length) {
      onSearch(debouncedSearchCompany.trim());
    }
  }, [debouncedSearchCompany]);

  const methods = useForm({
    reValidateMode: 'onSubmit',
    defaultValues
  });

  useEffect(() => {
    if (companySelected) {
      methods.setValue('company.id', companySelected.id);
      methods.setValue('company.name', companySelected.name);
    }
  }, [companySelected]);

  const onFormSubmit = (data: PartialUser) => {
    const updatedData = { ...data };
    if (companySelected) {
      updatedData.company_id = companySelected.id;
      updatedData.company = companySelected;
    }
    onSave(updatedData);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onFormSubmit)}>
        <Controller
          name="company.name"
          control={methods.control}
          defaultValue={companySelected?.name}
          render={({ field: { name } }) => (
            <AutoSuggest
              name={name}
              data={
                formatLabelValue({
                  data: searchResults.data,
                  value: 'id',
                  label: 'name'
                }) as IValueLabel[]
              }
              placeholder="Company"
              initialLabel={companySelected?.name}
              onChange={({ value }) => {
                setSearchCompany(value.toString());
              }}
              onSelect={({ value: id, label: name }) => {
                setCompanySelected({ id, name });

                methods.setValue('company.id', id);
                methods.setValue('company.name', name);
                methods.setValue('company_id', id);
                methods.setValue('properties', undefined);
              }}
              onClear={() => {
                setCompanySelected(undefined);

                methods.setValue('company.id', '');
                methods.setValue('company.name', '');
                methods.setValue('company_id', undefined);
                methods.setValue('properties', undefined);

                onResetSearchCompany();
              }}
              isLoading={searchResults.isLoading}
              hasError={searchResults.error !== null}
              isDisabled={isDisabled || searchResults.error !== null}
            />
          )}
        />
        <Gap height={1} />
        <Row gap={1}>
          <Button
            name="continue_button"
            variant={ButtonVariant.contained}
            color={ButtonColor.primary}
            size={ButtonSize.medium}
            type={ButtonType.submit}
            isDisabled={searchResults.error !== null}
          >
            Continue
          </Button>
          <Button
            name="cancel_button"
            variant={ButtonVariant.outline}
            color={ButtonColor.primary}
            size={ButtonSize.medium}
            type={ButtonType.button}
            onClick={() => onCancel(searchResults)}
          >
            Cancel
          </Button>
        </Row>
      </form>
    </FormProvider>
  );
};

export default SelectCompanyForm;
