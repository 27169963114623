/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Components
import Button, { ButtonDisabledStyle, ButtonVariant } from 'src/components/atoms/Button';
import { CheckboxData } from 'src/components/atoms/Checkbox';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import { RadioButton } from 'src/components/atoms/RadioButton';
import { Row } from 'src/components/atoms/Row';
import Text from 'src/components/atoms/Text';
import ToolTip, { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import { CheckboxGroup } from 'src/components/molecules/CheckboxGroup';
import { RadioButtonGroup } from 'src/components/molecules/RadioButtonGroup';
import { Container, SubContainer } from './styles';

// Helpers
import { canSelectDocType } from 'src/features/myWork/helpers';
import { downloadUrlToPdf, formatCheckboxData } from 'src/helpers';

// Types
import {
  IProof,
  ProofResultInsufficientReasonType,
  ProofResultType,
  ProofType
} from 'src/ts/interfaces';

// Enums
import { ProofResultEnum } from 'src/ts/enums';

// Constants
import { DOCUMENT_TYPES as documentTypesData } from 'src/features/DUP/constants';
import { RESULT_INSUFFICIENT_REASON_LABEL } from 'src/features/proofs';
import {
  reasons as reasonTypesData,
  reasonsEdited as reasonsEditedTypeData,
  results as resultTypesData
} from 'src/pages/ReviewPage/components/BulkSelectDoc/constants';

export type IFormValues = {
  result?: ProofResultType;
  document?: ProofType;
  note?: IProof['note'];
  resultEditedReason?: IProof['result_edited_reason'];
  resultInsufficientReason?: IProof['result_insufficient_reason'];
};

export type AsideProofActionsProps = {
  proof?: IProof;
  fileName: string;
  onFullscreen?: () => void;
  showMetadata: boolean;
  onShowMetadata?: () => void;
  isDisabledShowMetadata?: boolean;
  showNotes: boolean;
  onShowNotes?: () => void;
  isDisabledShowNotes?: boolean;
  showDownload: boolean;
  isDisabledShowDownload?: boolean;
  isDisabled: boolean;
  onSave: (formValues: IFormValues) => void;
  isDisabledOnSave?: boolean;
};

const AsideProofActions: React.FC<AsideProofActionsProps> = (props: AsideProofActionsProps) => {
  const {
    proof,
    fileName,
    onFullscreen,
    isDisabled,
    showMetadata,
    onShowMetadata,
    isDisabledShowMetadata,
    showNotes,
    onShowNotes,
    isDisabledShowNotes,
    showDownload,
    isDisabledShowDownload,
    onSave,
    isDisabledOnSave
  } = props;

  const [resultData, setResultData] = useState(
    formatCheckboxData({
      data: resultTypesData,
      value: 'value',
      label: 'label'
    })
  );

  const [documentTypes, setDocumentTypes] = useState<CheckboxData[]>(
    formatCheckboxData({ data: documentTypesData, value: 'value', label: 'label' })
  );

  const [reasonEditedNotes, setReasonEditedNotes] = useState<CheckboxData[]>(
    formatCheckboxData({
      data: reasonsEditedTypeData,
      value: 'value',
      label: 'label'
    })
  );

  const [reasonUndeterminedNotes, setReasonUndeterminedNotes] = useState<CheckboxData[]>(
    formatCheckboxData({
      data: reasonTypesData,
      value: 'value',
      label: 'label'
    })
  );

  useEffect(() => {
    if (proof) setSelectedData(proof);
  }, [proof]);

  // Function to hydrate the result options based on the retrieved proof data
  const setSelectedData = (proof: IProof) => {
    setResultData(
      resultData.map((item) => {
        return { ...item, isChecked: proof?.result === item.value, isDisabled };
      })
    );

    setDocumentTypes(
      documentTypes.map((item) => {
        return { ...item, isChecked: proof?.type === item.value, isDisabled };
      })
    );

    if (result === ProofResultEnum.Edited) {
      const editedReasons = proof.result_edited_reason;

      setReasonEditedNotes(
        reasonEditedNotes.map((item) => {
          return {
            ...item,
            isChecked:
              editedReasons?.includes(item.value as IProof['result_edited_reason'][number]) ||
              false,
            isDisabled
          };
        })
      );
    }

    if (result === ProofResultEnum.Undetermined) {
      setReasonUndeterminedNotes(
        reasonUndeterminedNotes.map((item) => {
          return {
            ...item,
            isChecked: proof.result_insufficient_reason === item.value,
            isDisabled
          };
        })
      );
    }
  };

  const onChangingResult = (selected: CheckboxData) => {
    methods.setValue('resultEditedReason', []);
    methods.setValue('resultInsufficientReason', 'NONE' as ProofResultInsufficientReasonType);

    setReasonEditedNotes(
      reasonEditedNotes.map((item) => {
        return { ...item, isChecked: false };
      })
    );

    setReasonUndeterminedNotes(
      reasonUndeterminedNotes.map((item) => {
        return { ...item, isChecked: false };
      })
    );

    const newData = resultData.map((item) => {
      const isChecked = item.value === selected.value;

      return { ...item, isChecked };
    });

    setResultData(newData);
    methods.setValue('result', selected.value as ProofResultType);
  };

  const methods = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues: {
      result: (proof?.result as ProofResultType) || '',
      document: (proof?.type as ProofType) || '',
      note: proof?.note || '',
      resultEditedReason: proof?.result_edited_reason,
      resultInsufficientReason: proof?.result_insufficient_reason
    }
  });

  const { result, resultEditedReason, resultInsufficientReason } = methods.watch();

  const shouldDisableButton = (): boolean => {
    // ----- Clean state -----
    // We should enable the button when:
    //   - The result selected is Clean
    //   - No reasons selected
    //   - The current result in the proof is not Clean
    const cleanSelected = result === ProofResultEnum.Clean;
    const hasReason = Boolean(resultEditedReason?.length) || resultInsufficientReason !== 'NONE';
    const currentProofResultIsNotClean = proof?.result !== ProofResultEnum.Clean;
    const shouldEnableForClean = cleanSelected && !hasReason && currentProofResultIsNotClean;

    if (shouldEnableForClean) return false;

    // ----- Edited and Insufficient states -----
    // We should enable the button when:
    //   - The result selected is Edited or Insufficient
    //   - Reasons were selected for each option
    //   - The result and the reasons are different from the current ones in the proof
    const editedOrInsufficientSelected =
      result === ProofResultEnum.Edited || result === ProofResultEnum.Undetermined;

    const areSameReasons =
      resultEditedReason?.every((reason) => proof?.result_edited_reason.includes(reason)) &&
      proof?.result_edited_reason.every((reason) => resultEditedReason.includes(reason)) &&
      resultInsufficientReason === proof?.result_insufficient_reason;

    const shouldEnableForEditedOrInsufficient =
      editedOrInsufficientSelected && hasReason && !areSameReasons;

    if (shouldEnableForEditedOrInsufficient) return false;

    // Otherwise, we should disable the button
    return true;
  };

  const onUpdate = (dataForm: IFormValues) => {
    onSave(dataForm);
  };

  return (
    <Container data-testid="AsideProofActions">
      <Row justify="space-between">
        <Row>
          <Button
            name="expand_doc_button"
            variant={ButtonVariant.outline}
            onClick={onFullscreen}
            disabledStyle={ButtonDisabledStyle.whiteWithBorder}
          >
            <Row gap={0.5}>
              <>Full View</> <Icon icon="arrow-expand" />
            </Row>
          </Button>
        </Row>
        <Row>
          {showMetadata && onShowMetadata && (
            <ToolTip direction={ToolTipDirection.top} content="Document Extractions">
              <Button
                name="metadata_button"
                variant={ButtonVariant.ghost}
                onClick={onShowMetadata}
                isDisabled={isDisabledShowMetadata}
                disabledStyle={ButtonDisabledStyle.transparent}
              >
                <Icon icon="cardSelectDoc_metadata" />
              </Button>
            </ToolTip>
          )}
          {showNotes && onShowNotes && (
            <ToolTip direction={ToolTipDirection.top} content="Public Document Notes">
              <Button
                name="note_button"
                variant={ButtonVariant.ghost}
                onClick={onShowNotes}
                isDisabled={isDisabledShowNotes}
                disabledStyle={ButtonDisabledStyle.transparent}
              >
                <Icon icon="cardSelectDoc_note" />
              </Button>
            </ToolTip>
          )}
          {showDownload && proof?.file && fileName && (
            <ToolTip direction={ToolTipDirection.top} content="Download">
              <Button
                name={`download_${proof?.file}_${fileName}_button`}
                variant={ButtonVariant.ghost}
                onClick={() => downloadUrlToPdf(proof?.file || '', fileName)}
                isDisabled={isDisabledShowDownload}
                disabledStyle={ButtonDisabledStyle.transparent}
              >
                <Icon icon="cardSelectDoc_download" />
              </Button>
            </ToolTip>
          )}
        </Row>
      </Row>
      <Gap height={3} />
      <FormProvider {...methods}>
        {canSelectDocType(result, resultInsufficientReason) && (
          <>
            <Row gap={1} direction="column">
              <Text>Document Type</Text>
              <RadioButtonGroup
                name="document"
                data={documentTypes}
                onClick={({ data }) => {
                  const selected = data.find((item) => item.isChecked);
                  methods.setValue('document', selected?.value as ProofType);
                }}
              />
            </Row>
            <Gap height={1.5} />
          </>
        )}
        <Row gap={1} direction="column">
          <Text>Result</Text>
          {resultData?.map((item) => {
            const { value, label, isChecked } = item;
            return (
              <div key={value}>
                <RadioButton
                  name={value}
                  value={value}
                  label={label}
                  isChecked={isChecked}
                  isDisabled={isDisabled}
                  onClick={() => onChangingResult(item)}
                />

                {/* Conditional rendering of undetermined (aka insufficient documentation) reasons options */}
                {value === ProofResultEnum.Undetermined && isChecked && (
                  <SubContainer>
                    <RadioButtonGroup
                      name="reasonsInsufficient"
                      data={reasonUndeterminedNotes}
                      onClick={({ data }) => {
                        const selected = data.find((item) => item.isChecked);
                        const value = selected?.value as IFormValues['resultInsufficientReason'];
                        if (value) {
                          methods.setValue('resultInsufficientReason', value);
                          methods.setValue('note', RESULT_INSUFFICIENT_REASON_LABEL[value]);
                        }
                      }}
                    />
                  </SubContainer>
                )}

                {/* Conditional rendering of edited reasons options */}
                {value === ProofResultEnum.Edited && isChecked && (
                  <SubContainer>
                    <CheckboxGroup
                      name="reasonsEdited"
                      data={reasonEditedNotes}
                      onClick={({ data }) => {
                        const selected = data
                          .filter((item) => item.isChecked)
                          .map((item) => item.value);
                        methods.setValue(
                          'resultEditedReason',
                          (selected as IFormValues['resultEditedReason']) || []
                        );
                      }}
                    />
                  </SubContainer>
                )}
              </div>
            );
          })}
        </Row>

        <Gap height={1.5} />
        <Button
          name="update_selection_button"
          variant={ButtonVariant.contained}
          isDisabled={isDisabled || isDisabledOnSave || shouldDisableButton()}
          onClick={methods.handleSubmit(onUpdate)}
        >
          Update Selection
        </Button>
      </FormProvider>
    </Container>
  );
};

export default AsideProofActions;
