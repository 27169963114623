import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoaderInvitationList } from 'src/components/molecules/LoaderInvitationList';
import { RowInvitationList } from 'src/components/molecules/RowInvitationList';
import { ShowState } from 'src/components/molecules/ShowState';
import Modal, { ModalVariant, ModalWidth } from 'src/components/organisms/Modal';
import { selectors } from 'src/features/invitation';
import { useInvitations } from 'src/features/invitation/hooks';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';
import { IInvitation } from 'src/ts/interfaces';

const InvitationsList: React.FC<{ filter?: string }> = ({ filter }) => {
  const {
    onGetAll: onGetInvitations,
    onRemove: onRemoveInvitation,
    onResetRemove: onResetRemoveInvitation,
    onHandleSendInvitation: onHandleReSendInvitation,
    showCompanyInformation,
    showPropertyInformation,
    extractPropertyNames
  } = useInvitations();

  const getInvitationsData = useSelector(selectors.getAll.data);
  const getInvitationsIsLoading = useSelector(selectors.getAll.isLoading);
  const getInvitationsFilter = useSelector(selectors.getAll.filter);
  const getInvitationsError = useSelector(selectors.getAll.error);

  const removeInvitationError = useSelector(selectors.delete.error);
  const removeInvitationIsRemoved = useSelector(selectors.delete.isRemoved);
  const removeInvitationIsLoading = useSelector(selectors.delete.isLoading);

  const [showModalRemoveInvitation, setShowModalRemoveInvitation] = useState(false);
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();
  const [invitationSelected, setInvitationSelected] = useState({ invitationId: '' });
  const [invitationsLoaded, setInvitationsLoaded] = useState(false);

  useEffect(() => {
    if (!invitationsLoaded) {
      onGetInvitations(undefined, undefined, filter);
      setInvitationsLoaded(true);
    }
  }, [onGetInvitations, invitationsLoaded, filter]);

  useEffect(() => {
    if (removeInvitationError) {
      closeRemoveInvitation();
      showSnackbar(VariantType.error, removeInvitationError, SnackTypes.none);
      onResetRemoveInvitation();
    } else {
      if (removeInvitationIsRemoved && !removeInvitationIsLoading) {
        closeRemoveInvitation();
        showSnackbar(
          VariantType.success,
          'The invitation has been removed successfully',
          SnackTypes.none
        );
        onResetRemoveInvitation();
      }
    }
  }, [
    onRemoveInvitation,
    removeInvitationError,
    removeInvitationIsLoading,
    onResetRemoveInvitation,
    showSnackbar,
    VariantType,
    SnackTypes,
    removeInvitationIsRemoved
  ]);

  if (getInvitationsError) {
    return (
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Please try again"
        message="Something went wrong"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (getInvitationsIsLoading) {
    return <LoaderInvitationList />;
  }

  if (getInvitationsData?.length === 0) {
    if (getInvitationsFilter.q) {
      return (
        <ShowState
          message="Try adjusting your search or filter to find<br/> what you are looking for"
          searchText={decodeURIComponent(getInvitationsFilter.q || '')}
          variant="search"
          type="searchEmpty"
        />
      );
    }
    return <ShowState message="There are no invitations yet" type="user" variant="empty" />;
  }

  const handleRemoveInvitation = (invitationId?: string) => {
    if (!invitationId) return null;
    setInvitationSelected({ invitationId });
    setShowModalRemoveInvitation(true);
  };

  const closeRemoveInvitation = () => {
    setInvitationSelected({ invitationId: '' });
    setShowModalRemoveInvitation(false);
  };

  return (
    <div>
      {getInvitationsData?.map((invitation: IInvitation) => (
        <RowInvitationList
          key={invitation.id}
          id={invitation.id}
          fullName={invitation.first_name + ' ' + invitation.last_name}
          email={invitation.email}
          role={invitation.role}
          onSendInvitation={() => onHandleReSendInvitation(invitation?.id)}
          onDelete={() => handleRemoveInvitation(invitation.id)}
          companyName={invitation?.company?.name}
          createdAt={invitation?.inserted_at}
          properties={extractPropertyNames(invitation?.properties)}
          showCompanyInformation={showCompanyInformation(invitation.role)}
          showPropertyInformation={showPropertyInformation(invitation.role)}
          isLoadingInvitationSent={invitation.isLoadingInvitationSent}
          isInvitationSent={invitation.isInvitationSent}
          isLoading={invitation.isLoading}
        />
      ))}

      <Modal
        showModal={showModalRemoveInvitation}
        setShowModal={setShowModalRemoveInvitation}
        title="Remove Invitation"
        labelButtonCancel="Cancel"
        labelButtonConfirm="Yes, remove"
        isLoadingButtonConfirm={removeInvitationIsLoading}
        isDisabledButtonConfirm={removeInvitationIsLoading}
        isDisabledButtonCancel={removeInvitationIsLoading}
        variant={ModalVariant.danger}
        width={ModalWidth.small}
        isBackClosable={false}
        isEscapeClosable={false}
        onConfirm={() => onRemoveInvitation(invitationSelected.invitationId)}
        onCancel={closeRemoveInvitation}
        text="Are you sure you want to remove this invitation? They will not be create an account in Snappt and their invitation will disappear from the invitation tab."
      />
    </div>
  );
};

InvitationsList.propTypes = {
  filter: PropTypes.string
};

export default InvitationsList;
