export const COUNTRY = [
  { value: 1, label: 'Afghanistan' },
  { value: 2, label: 'Åland Islands' },
  { value: 3, label: 'Albania' },
  { value: 4, label: 'Germany' },
  { value: 5, label: 'Andorra' },
  { value: 6, label: 'Angola' },
  { value: 7, label: 'Anguilla' },
  { value: 8, label: 'Antarctica' },
  { value: 9, label: 'Antigua and Barbuda' },
  { value: 10, label: 'Saudi Arabia' },
  { value: 11, label: 'Algeria' },
  { value: 12, label: 'Argentina' },
  { value: 13, label: 'Armenia' },
  { value: 14, label: 'Aruba' },
  { value: 15, label: 'Australia' },
  { value: 16, label: 'Austria' },
  { value: 17, label: 'Azerbaijan' },
  { value: 18, label: 'Bahamas (the)' },
  { value: 19, label: 'Bangladesh' },
  { value: 20, label: 'Barbados' },
  { value: 21, label: 'Bahrain' },
  { value: 22, label: 'Belgium' },
  { value: 23, label: 'Belize' },
  { value: 24, label: 'Benin' },
  { value: 25, label: 'Bermuda' },
  { value: 26, label: 'Belarus' },
  { value: 27, label: 'Myanmar' },
  { value: 28, label: 'Bolivia (Plurinational State of)' },
  { value: 29, label: 'Bosnia and Herzegovina' },
  { value: 30, label: 'Botswana' },
  { value: 31, label: 'Brazil' },
  { value: 32, label: 'Brunei Darussalam' },
  { value: 33, label: 'Bulgaria' },
  { value: 34, label: 'Burkina Faso' },
  { value: 35, label: 'Burundi' },
  { value: 36, label: 'Bhutan' },
  { value: 37, label: 'Cabo Verde' },
  { value: 38, label: 'Cambodia' },
  { value: 39, label: 'Cameroon' },
  { value: 40, label: 'Canada' },
  { value: 41, label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 42, label: 'Qatar' },
  { value: 43, label: 'Chad' },
  { value: 44, label: 'Chile' },
  { value: 45, label: 'China' },
  { value: 46, label: 'Cyprus' },
  { value: 47, label: 'Holy See (the)' },
  { value: 48, label: 'Colombia' },
  { value: 49, label: 'Comoros (the)' },
  { value: 50, label: 'Korea (the Democratic Peoples Republic of)' },
  { value: 51, label: 'Korea (the Republic of)' },
  { value: 52, label: 'Côte dIvoire' },
  { value: 53, label: 'Costa Rica' },
  { value: 54, label: 'Croatia' },
  { value: 55, label: 'Cuba' },
  { value: 56, label: 'Curaçao' },
  { value: 57, label: 'Denmark' },
  { value: 58, label: 'Dominica' },
  { value: 59, label: 'Ecuador' },
  { value: 60, label: 'Egypt' },
  { value: 61, label: 'El Salvador' },
  { value: 62, label: 'United Arab Emirates (the)' },
  { value: 63, label: 'Eritrea' },
  { value: 64, label: 'Slovakia' },
  { value: 65, label: 'Slovenia' },
  { value: 66, label: 'Spain' },
  { value: 67, label: 'Palestine, State of' },
  { value: 68, label: 'United States of America (the)' },
  { value: 69, label: 'Estonia' },
  { value: 70, label: 'Ethiopia' },
  { value: 71, label: 'Philippines (the)' },
  { value: 72, label: 'Finland' },
  { value: 73, label: 'Fiji' },
  { value: 74, label: 'France' },
  { value: 75, label: 'Gabon' },
  { value: 76, label: 'Gambia (the)' },
  { value: 77, label: 'Georgia' },
  { value: 78, label: 'Ghana' },
  { value: 79, label: 'Gibraltar' },
  { value: 80, label: 'Grenada' },
  { value: 81, label: 'Greece' },
  { value: 82, label: 'Greenland' },
  { value: 83, label: 'Guadeloupe' },
  { value: 84, label: 'Guam' },
  { value: 85, label: 'Guatemala' },
  { value: 86, label: 'French Guiana' },
  { value: 87, label: 'Guernsey' },
  { value: 88, label: 'Guinea' },
  { value: 89, label: 'Equatorial Guinea' },
  { value: 90, label: 'Guinea-Bissau' },
  { value: 91, label: 'Guyana' },
  { value: 92, label: 'Haiti' },
  { value: 93, label: 'Honduras' },
  { value: 94, label: 'Hong Kong' },
  { value: 95, label: 'Hungary' },
  { value: 96, label: 'India' },
  { value: 97, label: 'Indonesia' },
  { value: 98, label: 'Iraq' },
  { value: 99, label: 'Iran (Islamic Republic of)' },
  { value: 100, label: 'Ireland' },
  { value: 101, label: 'Bouvet Island' },
  { value: 102, label: 'Isle of Man' },
  { value: 103, label: 'Christmas Island' },
  { value: 104, label: 'Iceland' },
  { value: 105, label: 'Cayman Islands (the)' },
  { value: 106, label: 'Cocos (Keeling) Islands (the)' },
  { value: 107, label: 'Cook Islands (the)' },
  { value: 108, label: 'Faroe Islands (the)' },
  { value: 109, label: 'South Georgia and the South Sandwich Islands' },
  { value: 110, label: 'Heard Island and McDonald Islands' },
  { value: 111, label: 'Falkland Islands (the) [Malvinas]' },
  { value: 112, label: 'Northern Mariana Islands (the)' },
  { value: 113, label: 'Marshall Islands (the)' },
  { value: 114, label: 'Pitcairn' },
  { value: 115, label: 'Solomon Islands' },
  { value: 116, label: 'Turks and Caicos Islands (the)' },
  { value: 117, label: 'United States Minor Outlying Islands (the)' },
  { value: 118, label: 'Virgin Islands (British)' },
  { value: 119, label: 'Virgin Islands (U.S.)' },
  { value: 120, label: 'Israel' },
  { value: 121, label: 'Italy' },
  { value: 122, label: 'Jamaica' },
  { value: 123, label: 'Japan' },
  { value: 124, label: 'Jersey' },
  { value: 125, label: 'Jordan' },
  { value: 126, label: 'Kazakhstan' },
  { value: 127, label: 'Kenya' },
  { value: 128, label: 'Kyrgyzstan' },
  { value: 129, label: 'Kiribati' },
  { value: 130, label: 'Kuwait' },
  { value: 131, label: 'Lao Peoples Democratic Republic (the)' },
  { value: 132, label: 'Lesotho' },
  { value: 133, label: 'Latvia' },
  { value: 134, label: 'Lebanon' },
  { value: 135, label: 'Liberia' },
  { value: 136, label: 'Libya' },
  { value: 137, label: 'Liechtenstein' },
  { value: 138, label: 'Lithuania' },
  { value: 139, label: 'Luxembourg' },
  { value: 140, label: 'Macao' },
  { value: 141, label: 'Madagascar' },
  { value: 142, label: 'Malaysia' },
  { value: 143, label: 'Malawi' },
  { value: 144, label: 'Maldives' },
  { value: 145, label: 'Mali' },
  { value: 146, label: 'Malta' },
  { value: 147, label: 'Morocco' },
  { value: 148, label: 'Martinique' },
  { value: 149, label: 'Mauritius' },
  { value: 150, label: 'Mauritania' },
  { value: 151, label: 'Mayotte' },
  { value: 152, label: 'Mexico' },
  { value: 153, label: 'Micronesia (Federated States of)' },
  { value: 154, label: 'Moldova (the Republic of)' },
  { value: 155, label: 'Monaco' },
  { value: 156, label: 'Mongolia' },
  { value: 157, label: 'Montenegro' },
  { value: 158, label: 'Montserrat' },
  { value: 159, label: 'Mozambique' },
  { value: 160, label: 'Namibia' },
  { value: 161, label: 'Nauru' },
  { value: 162, label: 'Nepal' },
  { value: 163, label: 'Nicaragua' },
  { value: 164, label: 'Niger (the)' },
  { value: 165, label: 'Nigeria' },
  { value: 166, label: 'Niue' },
  { value: 167, label: 'Norfolk Island' },
  { value: 168, label: 'Norway' },
  { value: 169, label: 'New Caledonia' },
  { value: 170, label: 'New Zealand' },
  { value: 171, label: 'Oman' },
  { value: 172, label: 'Netherlands (the)' },
  { value: 173, label: 'Pakistan' },
  { value: 174, label: 'Palau' },
  { value: 175, label: 'Panama' },
  { value: 176, label: 'Papua New Guinea' },
  { value: 177, label: 'Paraguay' },
  { value: 178, label: 'Peru' },
  { value: 179, label: 'French Polynesia' },
  { value: 180, label: 'Poland' },
  { value: 181, label: 'Portugal' },
  { value: 182, label: 'Puerto Rico' },
  { value: 183, label: 'United Kingdom of Great Britain and Northern Ireland (the)' },
  { value: 184, label: 'Central African Republic (the)' },
  { value: 185, label: 'Czechia' },
  { value: 186, label: 'North Macedonia' },
  { value: 187, label: 'Congo (the)' },
  { value: 188, label: 'Congo (the Democratic Republic of the)' },
  { value: 189, label: 'Dominican Republic (the)' },
  { value: 190, label: 'Réunion' },
  { value: 191, label: 'Rwanda' },
  { value: 192, label: 'Romania' },
  { value: 193, label: 'Russian Federation (the)' },
  { value: 194, label: 'Western Sahara*' },
  { value: 195, label: 'Samoa' },
  { value: 196, label: 'American Samoa' },
  { value: 197, label: 'Saint Barthélemy' },
  { value: 198, label: 'Saint Kitts and Nevis' },
  { value: 199, label: 'San Marino' },
  { value: 200, label: 'Saint Martin (French part)' },
  { value: 201, label: 'Saint Pierre and Miquelon' },
  { value: 202, label: 'Saint Vincent and the Grenadines' },
  { value: 203, label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 204, label: 'Saint Lucia' },
  { value: 205, label: 'Sao Tome and Principe' },
  { value: 206, label: 'Senegal' },
  { value: 207, label: 'Serbia' },
  { value: 208, label: 'Seychelles' },
  { value: 209, label: 'Sierra Leone' },
  { value: 210, label: 'Singapore' },
  { value: 211, label: 'Sint Maarten (Dutch part)' },
  { value: 212, label: 'Syrian Arab Republic (the)' },
  { value: 213, label: 'Somalia' },
  { value: 214, label: 'Sri Lanka' },
  { value: 215, label: 'Eswatini' },
  { value: 216, label: 'South Africa' },
  { value: 217, label: 'Sudan (the)' },
  { value: 218, label: 'South Sudan' },
  { value: 219, label: 'Sweden' },
  { value: 220, label: 'Switzerland' },
  { value: 221, label: 'Suriname' },
  { value: 222, label: 'Svalbard and Jan Mayen' },
  { value: 223, label: 'Thailand' },
  { value: 224, label: 'Taiwan (Province of China)' },
  { value: 225, label: 'Tanzania, the United Republic of' },
  { value: 226, label: 'Tajikistan' },
  { value: 227, label: 'British Indian Ocean Territory (the)' },
  { value: 228, label: 'French Southern Territories (the)' },
  { value: 229, label: 'Timor-Leste' },
  { value: 230, label: 'Togo' },
  { value: 231, label: 'Tokelau' },
  { value: 232, label: 'Tonga' },
  { value: 233, label: 'Trinidad and Tobago' },
  { value: 234, label: 'Tunisia' },
  { value: 235, label: 'Turkmenistan' },
  { value: 236, label: 'Turkey' },
  { value: 237, label: 'Tuvalu' },
  { value: 238, label: 'Ukraine' },
  { value: 239, label: 'Uganda' },
  { value: 240, label: 'Uruguay' },
  { value: 241, label: 'Uzbekistan' },
  { value: 242, label: 'Vanuatu' },
  { value: 243, label: 'Venezuela (Bolivarian Republic of)' },
  { value: 244, label: 'Viet Nam' },
  { value: 245, label: 'Wallis and Futuna' },
  { value: 246, label: 'Yemen' },
  { value: 247, label: 'Djibouti' },
  { value: 248, label: 'Zambia' },
  { value: 249, label: 'Zimbabwe' }
];
