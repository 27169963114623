import { FC, TdHTMLAttributes } from 'react';
import { StyledTableCell } from './styles';

type TableCellNativeProps = TdHTMLAttributes<HTMLTableCellElement>;

type TableCellProps = TableCellNativeProps & { textAlign?: string; name?: string };

const TableCell: FC<TableCellProps> = (props) => {
  return <StyledTableCell {...props} />;
};

export default TableCell;
