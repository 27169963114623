import { hasManyEmptySpaces, onlyNumbersRegex, onlySomeSpecialCharactersRegex } from '../formRegex';

export const validateName = (value: string) => {
  if (onlyNumbersRegex.test(value)) {
    return 'Should not contain only numbers';
  }
  if (onlySomeSpecialCharactersRegex.test(value)) {
    return 'Should not contain special characters';
  }
  if (hasManyEmptySpaces.test(value)) {
    return 'Should not contain many empty spaces';
  }
};

export const onlyRequestedSymbols = (value: string) => {
  if (onlyNumbersRegex.test(value)) {
    return 'Should not contain only numbers';
  }

  // Special chars allowed: @ & - / , .
  const badChars = /[!"^§£$[\]*\\#=;:><+$~?¿|%)(_]+/gm;
  if (badChars.test(value)) {
    return 'Should not contain special characters';
  }
  if (hasManyEmptySpaces.test(value)) {
    return 'Should not contain many empty spaces';
  }
};
