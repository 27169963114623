import Modal from 'src/components/organisms/Modal';
import styled from 'styled-components/macro';

export const ModalStyle = styled(Modal)`
  .Buttons {
    padding-top: 0;
  }

  & .input-form {
    min-height: 160px;
    display: flex;
  }

  & .input-form > textarea {
    flex: 1;
  }
`;
