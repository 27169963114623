import Col from 'src/components/atoms/Row/Col';
import styled from 'styled-components/macro';

export const StyledCol = styled(Col)`
  ul {
    padding: 0;
    padding-top: 0.5rem;
    padding-left: 1.2rem;
    margin: 0;
  }
`;
