import { useSelector } from 'react-redux';
import { actions, selectors } from 'src/features/invitation/invitationSlice';
import { useAppDispatch } from 'src/store';
import { Status } from 'src/ts/enums';
import { searchGetAllCompany } from '../services';

const useSearchCompany = () => {
  const dispatch = useAppDispatch();
  const searchGetAllCompanyResponse = useSelector(selectors.searchGetAllCompany);

  const onGetAll = () => dispatch(searchGetAllCompany({ ...searchGetAllCompanyResponse.filter }));

  const onResetGetAll = () => {
    dispatch(actions.resetSearchCompany());
  };

  const onSearch = (q: string) => {
    const search = decodeURIComponent(q);
    dispatch(actions.searchCompany({ q: search }));
    dispatch(searchGetAllCompany({ ...searchGetAllCompanyResponse.filter, q: search }));
  };

  return {
    onGetAll,
    getAll: {
      isLoading: searchGetAllCompanyResponse.status === Status.LOADING,
      isIdle: searchGetAllCompanyResponse.status === Status.IDLE,
      error: searchGetAllCompanyResponse.error,
      data: searchGetAllCompanyResponse.data || [],
      filter: searchGetAllCompanyResponse.filter,
      count: searchGetAllCompanyResponse.count || 0
    },
    onResetGetAll,
    onSearch
  };
};

export default useSearchCompany;
