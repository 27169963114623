import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1280px;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.5rem;
  }

  .income-verification-icons {
    svg {
      width: 36px !important;
      height: 36px !important;
    }
    width: 36px;
  }

  .proof-result-detail-row {
    align-items: center;
  }
`;
