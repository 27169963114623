import { appUnauthenticatedApi } from 'src/api';
import {
  ICreateApplicationRequest,
  ISubmitUnauthenticatedApplicationRequest,
  IUpdateApplicationRequest
} from './ts/interfaces';

const api = {
  createApplication: async ({
    company_short_id,
    property_short_id,
    application_type
  }: ICreateApplicationRequest) => {
    const response = await appUnauthenticatedApi.createUnauthenticateApplication(application_type, {
      company_short_id,
      property_short_id
    });
    return response;
  },
  getCurrentApplication: async () => {
    const response = await appUnauthenticatedApi.getUnauthenticateCurrentApplication();
    if (!response) {
      console.error(`getCurrentApplication: ${response}`);
      throw new Error('Error fetching the current application');
    }
    return response;
  },
  getCurrentProperty: async () => {
    const response = await appUnauthenticatedApi.getUnauthenticateCurrentProperty();
    if (!response) {
      console.error(`getCurrentProperty: ${response}`);
      throw new Error('Error fetching the current property');
    }
    return response;
  },
  updateCurrentApplication: async (data: IUpdateApplicationRequest) => {
    const response = await appUnauthenticatedApi.updateUnauthenticateCurrentApplication(data);
    if (!response) {
      console.error(`updateCurrentApplication: ${response}`);
      throw new Error('Error updating the current application');
    }
    return { ...(response.data || {}) };
  },
  submitApplication: async (data: ISubmitUnauthenticatedApplicationRequest) => {
    const response = await appUnauthenticatedApi.submitUnauthenticateApplication(data);
    return { ...(response || {}) };
  }
};

export default api;
