import styled from 'styled-components/macro';

export const StyledTableCell = styled.td<{ textAlign?: string; name?: string }>`
  display: table-cell;
  vertical-align: inherit;
  padding: 0.1rem 1rem;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  ${({ theme }) => theme.font.small};
  color: ${({ theme }) => theme.color.gray[700]};
  ${({ width }) => width && `width: ${width}px;`}
`;
