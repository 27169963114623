import styled from 'styled-components/macro';

const withButton = '3rem';
export const StyledComponent = styled.div`
  display: flex;

  input {
    height: 1.875rem;
  }

  button {
    &.end-andorment {
      top: 7px;
      &:hover {
        svg {
          path {
            fill: ${({ theme }) => theme.color.blue[700]};
          }
        }
      }
      &:disabled {
        background-color: ${({ theme }) => theme.color.gray[50]};
      }
    }
  }
`;

export const StyledInputComponent = styled.div`
  width: calc(100% - ${withButton});

  & div > div {
    margin-bottom: 0;
    border-radius: 0.125rem 0 0 0.125rem;
    border-right: none;
    padding-right: 0;
  }
`;

export const StyledButtonComponent = styled.div`
  & > button {
    border-radius: 0 0.125rem 0.125rem 0;
    width: ${withButton};
    height: 2.75rem;
    justify-content: center;
  }
`;
