import PropTypes from 'prop-types';
import { FC, useEffect, useState } from 'react';

import {
  RadioButton,
  RadioButtonData,
  RadioButtonPropTypes
} from 'src/components/atoms/RadioButton';

import { Wrapper } from './styles';
import { RadioButtonGroupProps } from './types';

const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
  name,
  data,
  onClick,
  onChange,
  className
}) => {
  const [dataUpdated, setDataUpdated] = useState<RadioButtonData[]>(data);

  useEffect(() => {
    setDataUpdated(data);
  }, [data]);

  const onChanging = (selected: RadioButtonData) => {
    const newData = dataUpdated.map((item) => {
      const isChecked = item.value === selected.value;

      return { ...item, isChecked };
    });

    setDataUpdated(newData);

    onClick?.({ name, data: newData });
    onChange?.(newData);
  };

  return (
    <Wrapper className={className} data-testid="RadioButtonGroup" role="radiogroup">
      {dataUpdated?.map((item: RadioButtonData) => {
        return (
          <div key={item.value} className="RadioButtonItem">
            <RadioButton
              name={item.value}
              value={item.value}
              label={item.label}
              isChecked={item.isChecked}
              isDisabled={item?.isDisabled}
              onClick={(e) => {
                e.preventDefault();
                if (item?.isDisabled) return;

                onChanging(item);
              }}
            />
          </div>
        );
      })}
    </Wrapper>
  );
};

RadioButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(RadioButtonPropTypes).isRequired).isRequired,
  onClick: PropTypes.func
};

RadioButtonGroup.defaultProps = {
  name: 'RadioButtonGroup',
  data: []
};

export default RadioButtonGroup;
