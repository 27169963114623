// Vendor
import { memo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// Components
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import InputText, { InputTextType } from 'src/components/atoms/InputText/InputText';
import { SearchContainer } from './styles';

// Types
import { CheckboxType } from 'src/components/atoms/Checkbox/types';

// Hooks
import usePrevious from 'src/hooks/usePrevious';

const isDisabledInputSearch = (
  data: CheckboxType[],
  searchText: string,
  prevSearchText: string
) => {
  if (data.length === 0) {
    if (searchText.length === 0 && prevSearchText.length > 0) return false;
    return searchText.length === 0;
  }
};

const isDisabledButtonSearch = (
  data: CheckboxType[],
  searchText: string,
  prevSearchText: string,
  minLength: number
) => {
  if (searchText.length > 0 && data.length === 0) return false;
  if (searchText.length === 0 && prevSearchText.length > 0) return false;
  if (searchText.length < minLength) return true;
};

const SearchBox = ({
  name,
  data,
  placeholder,
  onSearch,
  defaultValue,
  minLength = 1
}: {
  name: string;
  data: CheckboxType[];
  placeholder?: string;
  onSearch: (content: string) => void;
  defaultValue: string;
  minLength?: number;
}) => {
  const inputName = `inputSearch_${name}`;

  const methods = useForm({
    mode: 'onSubmit'
  });

  const [refresh, setRefresh] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue);

  const prevInputValue = usePrevious(inputValue);

  const onSubmit = (data: { [x: string]: string }) => {
    onSearch(data[inputName]);
  };

  const handleKeyDown = (event: { key: string }) => {
    if (
      event.key === 'Enter' &&
      !isDisabledButtonSearch(data, inputValue, prevInputValue || '', minLength)
    ) {
      methods.handleSubmit(onSubmit)();
    }
  };

  return (
    <FormProvider {...methods} key={`${name}__${refresh}`}>
      <SearchContainer>
        <InputText
          name={`inputSearch_${name}`}
          placeholder={placeholder || 'Search'}
          showLabel={false}
          defaultValue={defaultValue}
          showOptionalLabel={false}
          type={InputTextType.text}
          isDisabled={isDisabledInputSearch(data, inputValue, prevInputValue || '')}
          onChange={(e) => {
            setInputValue(e.value.toString());
          }}
          onKeyDown={handleKeyDown}
          endAndorment={
            inputValue && (
              <Button
                onClick={() => {
                  onSearch('');
                  setInputValue('');

                  methods.setValue(inputName, '');
                  methods.setFocus(inputName);

                  setRefresh(!refresh);
                }}
                label={`remove search of ${name}`}
                className="end-andorment"
                name={`close_button__${name}`}
                variant={ButtonVariant.ghost}
                type={ButtonType.button}
              >
                <Icon icon="icon-close" />
              </Button>
            )
          }
        />
        <Button
          name={`search_button__${name}`}
          className="search_button"
          color={ButtonColor.primary}
          variant={ButtonVariant.contained}
          size={ButtonSize.medium}
          isLoading={false}
          isDisabled={isDisabledButtonSearch(data, inputValue, prevInputValue || '', minLength)}
          type={ButtonType.button}
          onClick={methods.handleSubmit(onSubmit)}
        >
          <Icon icon="icon-search" />
        </Button>
      </SearchContainer>
    </FormProvider>
  );
};

export default memo(SearchBox);
