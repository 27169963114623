export const NAME = 'getStarted';

export const COMPANY_INFO = {
  name: '',
  title: 'Customer Onboarding Page',
  title_form: 'Customer Onboarding',
  company_section_title: 'Create Company',
  user_account_title: 'Create Company Admin Account',
  create_company_user_title: 'Create Company & User'
};
