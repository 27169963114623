// Vendor
import moment from 'moment';

// Components
import Button, {
  ButtonDisabledStyle,
  ButtonSize,
  ButtonVariant
} from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import { Line } from 'src/components/atoms/Line';
import Row from 'src/components/atoms/Row/Row';
import Tag, { TagColor } from 'src/components/atoms/Tag';
import Text, { TextAlignItems, TextColor, TextVariant } from 'src/components/atoms/Text';
import ToolTip, { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import { Container, TextName } from './styles';

// Constants
import { ROLE_DESCRIPTION } from 'src/features/auth/constants';

// Helpers
import { configVars } from 'src/helpers';

// Hooks
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';

// Enums
import { RoleEnum } from 'src/ts/enums';

// Types
import { RoleType } from 'src/ts/interfaces';

export type RowInvitationListProps = {
  id?: string;
  fullName: string;
  email: string;
  role: RoleType;
  companyName?: string;
  properties?: string;
  showCompanyInformation?: boolean;
  showPropertyInformation?: boolean;
  onSendInvitation: () => void;
  onDelete: () => void;
  showOnSendInvitation?: boolean;
  createdAt: string;
  isLoadingInvitationSent?: boolean;
  isInvitationSent?: boolean;
  isLoading?: boolean;
};

const RowInvitationList: React.FC<RowInvitationListProps> = (props: RowInvitationListProps) => {
  const {
    id,
    fullName,
    email,
    role,
    companyName,
    properties,
    showCompanyInformation,
    showPropertyInformation,
    onSendInvitation,
    onDelete,
    showOnSendInvitation,
    createdAt,
    isLoadingInvitationSent,
    isInvitationSent,
    isLoading
  } = props;
  const { copy } = useCopyToClipboard();

  const CompanyInformation = () => {
    if (!companyName || !showCompanyInformation) return null;

    return (
      <>
        <Gap height={0.5} />
        <Row alignItems="flex-start" className="row__icon_text">
          <Icon color="primary" icon="company" />
          <Text variant={TextVariant.small}>{companyName}</Text>
        </Row>
      </>
    );
  };

  const PropertyInformation = () => {
    if (!showPropertyInformation) return null;

    return (
      <>
        <Gap height={0.5} />
        <Row alignItems="flex-start" className="row__icon_text row__text_property">
          <Icon color="primary" icon="property" />
          {properties !== '' ? (
            <Text variant={TextVariant.small}>{properties}</Text>
          ) : (
            <Text variant={TextVariant.small} color={TextColor.gray300}>
              No properties assigned
            </Text>
          )}
        </Row>
      </>
    );
  };

  const handleCopyInvitationLink = () => {
    const url = `${configVars.frontend_url}/invitation/${id}`;
    copy(
      url,
      'Invitation link copied to the clipboard',
      'Invitation link could not be copied to the clipboard'
    );
  };

  return (
    <>
      <Line height="tiny" />
      <Container>
        <Row justify="space-between">
          <Row.Col size={'50%'}>
            <>
              <TextName isBold variant={TextVariant.normal}>
                {fullName}
              </TextName>
              <Gap height={0.5} />
              <Text variant={TextVariant.normal}>{email}</Text>
            </>
          </Row.Col>
          <Row.Col size={'auto'} alignSelf="flex-start">
            <Row className={'buttons'} justify={'flex-end'}>
              <Text variant={TextVariant.small} alignItems={TextAlignItems.center}>
                {moment(createdAt).startOf('minutes').fromNow()}
              </Text>
              <ToolTip content="Copy" direction={ToolTipDirection.top} className="tooltip_copy">
                <Button
                  variant={ButtonVariant.ghost}
                  onClick={handleCopyInvitationLink}
                  name="notification_button"
                  size={ButtonSize.medium}
                  isDisabled={isLoading}
                  disabledStyle={ButtonDisabledStyle.white}
                >
                  <Icon color="primary" icon="copy" />
                </Button>
              </ToolTip>
              {showOnSendInvitation && (
                <ToolTip
                  content={
                    !isInvitationSent
                      ? `Resend invitation`
                      : `Invitation was resent. If this user did not receive the email, copy  the invitation link and provide it to the user.`
                  }
                  direction={ToolTipDirection.top}
                >
                  <Button
                    variant={ButtonVariant.ghost}
                    onClick={onSendInvitation}
                    name="notification_button"
                    size={ButtonSize.medium}
                    isDisabled={isLoadingInvitationSent || isInvitationSent}
                    disabledStyle={ButtonDisabledStyle.white}
                    isLoading={isLoadingInvitationSent}
                  >
                    {!isLoadingInvitationSent && <Icon color="primary" icon="send" />}
                  </Button>
                </ToolTip>
              )}
              <Button
                variant={ButtonVariant.ghost}
                onClick={onDelete}
                name="remove_button"
                isDisabled={isLoading}
                disabledStyle={ButtonDisabledStyle.white}
              >
                <Icon color="primary" icon="delete" />
              </Button>
            </Row>
          </Row.Col>
        </Row>
        <>
          <Gap height={1} />
          <Tag color={TagColor.blue} label={ROLE_DESCRIPTION[role] || 'Not found'} showBackground />
          <Gap height={0.5} />
          {CompanyInformation()}
          {PropertyInformation()}
        </>
      </Container>
    </>
  );
};

RowInvitationList.defaultProps = {
  fullName: 'John Doe',
  email: 'john.doe@domain.com',
  role: RoleEnum.Admin,
  onSendInvitation: () => null,
  onDelete: () => null,
  showOnSendInvitation: true,
  createdAt: '',
  isInvitationSent: false,
  isLoadingInvitationSent: false,
  isLoading: false
};

export default RowInvitationList;
