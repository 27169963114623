import styled from 'styled-components/macro';

export const Container = styled.div`
  border: 2px solid ${({ theme }) => theme.color.gray[200]};
  padding: 1.5rem 0.5rem;
  border-radius: 4px;

  .Skeleton___Buttons {
    div {
      &:first-of-type {
        padding-right: 0rem;
      }
      &:last-of-type {
        padding-left: 0rem;
      }
    }
  }
`;
