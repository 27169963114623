// Vendor
import { useSelector } from 'react-redux';

// Components
import { Row } from 'src/components/atoms/Row';
import Section from 'src/components/atoms/Section/Section';
import Text, { TextVariant } from 'src/components/atoms/Text';
import CardPreview from 'src/components/molecules/CardPreview';

// Redux
import { selectors as entryReportSelectors } from 'src/features/fde-report/fdeReportSlice';

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';
import useFDEReport from 'src/features/fde-report/hooks/useFDEReport';

// Enums
import { EntryStatusEnum, FeatureFlagKey } from 'src/ts/enums';

// Types
import { ProofResultType } from 'src/ts/interfaces';

// Hooks
import { useFeatureFlag } from 'src/hooks';

const ProofsResults: React.FC = () => {
  const { isOnSiteRepresentative } = useRole();
  const { getProofNote, getProofTypeStatus, findProofInfo, findDocumentType } = useFDEReport();

  const entryReportData = useSelector(entryReportSelectors.getEntryReport.data);
  const { proofs, entry, applicant } = entryReportData || {};

  const cleanProceedWithCautionFlagEnabled = useFeatureFlag(
    FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION
  );

  const printResult = () => {
    if (entry?.status === EntryStatusEnum.Pending || proofs?.length === 0) {
      return <Text variant={TextVariant.h2}>No documents reviewed yet</Text>;
    }

    return (
      <Row columns={2} gap={2} wrap="wrap">
        {proofs?.map((proof, index) => {
          const result = proof.result as unknown as ProofResultType;
          const status = findProofInfo(result);
          const type = findDocumentType(proof.type);
          return (
            <Row.Col key={proof.id}>
              <CardPreview
                type={getProofTypeStatus(result)}
                fileName={applicant?.full_name}
                numDocument={index + 1}
                tag={status?.title}
                title={type}
                detail={getProofNote(result, proof.note || '', entry?.note || '')}
                thumbnailURL={isOnSiteRepresentative ? null : proof?.thumb}
                fileURL={proof?.file}
                cleanProceedWithCautionFlagEnabled={cleanProceedWithCautionFlagEnabled}
              />
            </Row.Col>
          );
        })}
        <></>
      </Row>
    );
  };

  return <Section padding="0 3rem 3rem 3rem">{printResult()}</Section>;
};

export default ProofsResults;
