// Vendor
import { createAsyncThunk } from '@reduxjs/toolkit';

// Types
import { getPaginationRequest, IUser } from 'src/ts/interfaces';
import { IUserRequest } from './ts/interfaces';

// Redux
import apiProperty from '../property/api';
import api from './api';

// Constants
import { NAME } from './constants';

export const getAll = createAsyncThunk(
  `${NAME}/getAll`,
  async (data: getPaginationRequest & { isDDown?: boolean }) => {
    try {
      return await api.getAll(data);
    } catch (error) {
      console.error(`${NAME}/getAll`, error);
      throw new Error('Error fetching the users');
    }
  }
);

export const getOne = createAsyncThunk(`${NAME}/getOne`, async (userId: string) => {
  try {
    return await api.getOne(userId);
  } catch (error) {
    console.error(`${NAME}/getOne`, error);
    throw new Error('Error getting the user information');
  }
});

export const getByEmail = createAsyncThunk(
  `${NAME}/getByEmail`,
  async (data: getPaginationRequest) => {
    try {
      return await api.getAll(data);
    } catch (error) {
      console.error(`${NAME}/getByEmail`, error);
      throw new Error('Error fetching the user by email');
    }
  }
);

export const create = createAsyncThunk(`${NAME}/create`, async (data: IUserRequest) => {
  try {
    return await api.create(data);
  } catch (error) {
    console.error(`${NAME}/create`, error);
    throw new Error('Error creating the user');
  }
});

export const remove = createAsyncThunk(`${NAME}/delete`, async (userId: string) => {
  try {
    return await api.delete(userId);
  } catch (error) {
    console.error(`${NAME}/delete`, error);
    throw new Error('Error deleting the user');
  }
});

export const update = createAsyncThunk(
  `${NAME}/update`,
  async ({ userId, data }: { userId: string; data: IUser }) => {
    try {
      return await api.update(userId, { user: data });
    } catch (error) {
      console.error(`${NAME}/update`, error);
      throw new Error('Error updating the user');
    }
  }
);

export const changeSettingNotification = createAsyncThunk(
  `${NAME}/changeSettingNotification`,
  async (user: IUser) => {
    try {
      return await api.update(user.id as string, { user: user });
    } catch (error) {
      console.error(`${NAME}/changeSettingNotification`, error);
      throw new Error('Error changing the settings notification to the user');
    }
  }
);

export const getUnassignedProperties = createAsyncThunk(
  `${NAME}/getUnassignedProperties`,
  async (data: getPaginationRequest) => {
    try {
      return await apiProperty.getPropertiesSearch(data);
    } catch (error) {
      console.error(`${NAME}/getUnassignedProperties`, error);
      throw new Error('Error fetching the unassigned properties');
    }
  }
);
