import { ReactSVG } from 'react-svg';
import styled from 'styled-components/macro';
import Text from '../../atoms/Text';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

export const Logo = styled(ReactSVG)`
  svg {
    height: 3rem;
  }
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 0rem;
  }
`;

export const Col = styled.div`
  flex: 1;
  display: flex;
`;

export const Content = styled(Col)`
  padding: 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.5rem;
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledText = styled(Text)`
  @media ${({ theme }) => theme.device.laptop} {
    @media (max-height: 700px) {
      line-height: 50px;
      font-size: 2.7rem;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  padding-bottom: 2rem;
`;

export const LandingImage = styled.img`
  object-position: center;
  background: linear-gradient(250deg, #003f9f, #012238 55.71%);

  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }

  @media ${({ theme }) => theme.device.laptop} {
    object-fit: contain;
    min-width: 500px;
    min-height: 700px;
  }
`;
