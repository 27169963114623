import { format, isValid } from 'date-fns';
import { FC, forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactSVG } from 'react-svg';

import { InputText, InputTextType } from 'src/components/atoms/InputText';
import { DATE_PICKER_FORMAT } from '.';
import { Container } from './styles';
import { CustomInputProps, DatePickerProps } from './types';

const PUBLIC_URL = process.env.PUBLIC_URL;

const DatePicker: FC<DatePickerProps> = ({ name, value, onChange, ...props }) => {
  const {
    className,
    selectsEnd,
    startDate,
    endDate,
    minDate,
    selectsStart,
    disabled,
    placeholderText
  } = props;

  const CustomInput = forwardRef(({ onChange, onClick, ...props }: CustomInputProps, _ref) => (
    <InputText
      {...props}
      type={InputTextType.date}
      placeholder={placeholderText || 'MM/DD/YYYY'}
      showLabel={false}
      showOptionalLabel={false}
      endAndorment={
        <ReactSVG
          className="end-andorment"
          onClick={onClick}
          role="button"
          aria-pressed="true"
          aria-label="choose date"
          src={`${PUBLIC_URL}/assets/images/calendar-today.svg`}
        />
      }
      isDisabled={disabled}
    />
  ));

  CustomInput.displayName = 'CustomInput';

  const formatDate = (date: Date | null) => (!date ? null : format(date, DATE_PICKER_FORMAT));

  const isDateValid = value && isValid(new Date(value));
  const selected = isDateValid ? new Date(value) : null;

  return (
    <Container className={className}>
      <ReactDatePicker
        name={name}
        customInput={<CustomInput />}
        dateFormat={DATE_PICKER_FORMAT}
        onChange={(date) => onChange && onChange(formatDate(date))}
        selected={selected}
        showPopperArrow={false}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        selectsStart={selectsStart}
        disabled={disabled}
        placeholderText={placeholderText}
      />
    </Container>
  );
};

export default DatePicker;
