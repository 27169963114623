import styled from 'styled-components/macro';

export const Container = styled.div`
  position: relative;

  &[data-populated='true'] {
    .DDSubContainer {
      padding-top: 7px;
      padding-bottom: 5px;
      max-height: 7rem;
      overflow-y: auto;
      overflow-wrap: anywhere;
    }
  }

  .DropdownMulticheckSearch {
    position: absolute;
    width: 100%;
    margin-top: -1px;
    z-index: 1;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  button {
    padding: 5px;
    height: 1.5rem;
    margin-left: 8px;
  }
`;

export const SearchBoxContainer = styled.div`
  position: relative;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const OptionsContainer = styled.div<{ rows: number }>`
  height: ${({ rows }) => (rows > 6 ? '12.5rem' : 'auto')};
  overflow-y: ${({ rows }) => (rows > 6 ? 'scroll' : 'hidden')};

  ul {
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
      list-style: none;
      border-bottom: 0.063rem solid ${({ theme }) => theme.color.gray[100]};

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background: ${({ theme }) => theme.color.blue[100]};

        button {
          svg {
            color: ${({ theme }) => theme.color.blue[700]};
          }
        }
      }

      button {
        padding: 0.25rem;
        padding-top: 0.5rem;
        text-decoration: none;
        width: 100%;
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-align: left;
        ${({ theme }) => theme.font.normal};
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          margin-left: 0.25rem;
        }

        > span.label {
          margin-top: -3px;
        }
      }

      &.selected {
        background: ${({ theme }) => theme.color.blue[300]};
      }
    }
  }
`;

export const OptionContainer = styled.div<{ rows: number }>`
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  background: #fff;
  margin: 0;
  margin-top: -0.1rem;
  border: 0.063rem solid ${({ theme }) => theme.color.gray[200]};
  border-radius: 0 0 0.188rem 0.188rem;
`;

export const HelperText = styled.div`
  margin-top: 0.195rem;
  margin-left: 0.5rem;

  span {
    line-height: 0.8rem;
    padding-top: 0.5rem;
  }
`;

export const ItemCheck = styled.span`
  ${({ theme }) => theme.font.small};
  margin-top: 0.15rem;
  margin-bottom: 0.15rem;
  margin-right: 0.5rem;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  background: ${({ theme }) => theme.color.gray[100]};
`;

export const ItemRemove = styled.span`
  padding: 2px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 20px;
  text-align: center;
  line-height: 1;
  margin-left: 5px;
  margin-right: -5px;
  background: ${({ theme }) => theme.color.gray[200]};
  &:hover {
    background: ${({ theme }) => theme.color.gray[90]};
  }
  svg {
    margin-top: 3px;
  }
`;

export const StateMessage = styled.div`
  padding: 1rem 0.5rem;
  ${({ theme }) => theme.font.small};
`;
