import { ProofResultEnum } from 'src/ts/enums';
import { ProofResultType } from 'src/ts/interfaces';

export const useProofGetNote = () => {
  const onGetNote = (result: ProofResultType, note?: string) => {
    switch (result) {
      case ProofResultEnum.Edited:
        return (
          note ||
          'Snappt has found issues with one or more of the following: any modifications to the document, or differences between known authentic documents of this type.'
        );
      case ProofResultEnum.Clean:
        return note || 'No indication of editing has been detected.';
      case ProofResultEnum.Undetermined:
      default:
        return note;
    }
  };

  return {
    onGetNote
  };
};
