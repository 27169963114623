import { Route, Routes } from 'react-router-dom';

import {
  DUPPageProvider,
  InvitationPage,
  LoginPage,
  PartnerTermsPage,
  PrivacyPolicyPage,
  TermsPage
} from 'src/pages';
import { LogoutPage } from 'src/pages/LogoutPage';
import { SessionExpiredPage } from 'src/pages/SessionExpiredPage';

const UnauthenticatedRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/sessionExpired" element={<SessionExpiredPage />} />
      <Route path="/application/apply/:companyId/:propertyId" element={<DUPPageProvider />} />
      <Route path="/invitation/:id" element={<InvitationPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/partner-terms" element={<PartnerTermsPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/logout" element={<LogoutPage />} />
    </Routes>
  );
};

export default UnauthenticatedRoutes;
