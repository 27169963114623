// Vendor
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Redux
import { selectors as authSelectors } from 'src/features/auth/authSlice';
import { actions as entryActions } from 'src/features/entries';
import { actions as myWorkActions, escalateThunk } from 'src/features/myWork';
import { selectors as entrySelectors } from 'src/features/myWork/myWorkSlice';

// Hooks
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';

// Enums
import { EntryReviewEscalationReasonEnum, Status as StatusEnum } from 'src/ts/enums';

export const useEscalate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { SnackTypes, showSnackbar, VariantType } = useSnackbarProvider();

  const user = useSelector(authSelectors.user);
  const escalatedStatus = useSelector(entrySelectors.escalate.status);

  const onEscalate = (entryId: string) => {
    try {
      if (user?.id) {
        dispatch(
          escalateThunk({
            entryId,
            data: {
              entry_review_escalation: {
                reason: EntryReviewEscalationReasonEnum.ReviewNeeded,
                escalated_by_id: user?.id
              }
            }
          })
        );
      } else {
        showSnackbar(
          VariantType.error,
          'Is missing the user information to escalate the entry',
          SnackTypes.none
        );
      }
    } catch (error) {
      showSnackbar(VariantType.error, 'Failed to escalate the entry');
    }
  };

  useEffect(() => {
    if (escalatedStatus === StatusEnum.SUCCESS) {
      showSnackbar(VariantType.success, 'The entry has been escalated');

      navigate('/my-work');
      dispatch(entryActions.reset());
      dispatch(myWorkActions.reset());
    }
    if (escalatedStatus === StatusEnum.ERROR) {
      showSnackbar(VariantType.error, 'Something went wrong trying to escalate the entry');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SnackTypes.none, VariantType.success, escalatedStatus, showSnackbar]);

  return {
    onEscalate,
    isLoading: escalatedStatus === StatusEnum.LOADING
  };
};
