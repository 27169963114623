import { RoleEnum } from 'src/ts/enums';
import { FilterData } from './types';

// Constants
import { LeasingTeam, SnapptTeam } from 'src/constants/roles';

import { CheckboxData } from 'src/components/atoms/Checkbox/types';

const USER_ROLE_VALUE_MAP: Record<RoleEnum, RoleEnum[]> = {
  [RoleEnum.Admin]: [...LeasingTeam, ...SnapptTeam],
  [RoleEnum.CompanyAdmin]: LeasingTeam,
  [RoleEnum.BuildingManager]: LeasingTeam.filter((role) => role !== RoleEnum.CompanyAdmin),
  [RoleEnum.AccountRepresentative]: LeasingTeam,
  [RoleEnum.Reviewer]: [],
  [RoleEnum.PropertyAdmin]: [],
  [RoleEnum.OnSiteRepresentative]: [],
  [RoleEnum.FraudAnalyst]: [],
  [RoleEnum.FdeManager]: [],
  [RoleEnum.SeniorFraudAnalyst]: [],
  [RoleEnum.ExternalClient]: []
};

export const initiateFilters = (role: RoleEnum | undefined) => (initialState: FilterData) => {
  if (!role) return initialState;

  const availableFilterRoles = USER_ROLE_VALUE_MAP[role];

  return {
    ...initialState,
    checkboxGroup: initialState.checkboxGroup.filter((checkbox) =>
      availableFilterRoles.includes(checkbox.value as RoleEnum)
    )
  };
};

export const filterData = {
  checkboxGroup: [
    {
      value: RoleEnum.Admin,
      label: 'Admin',
      isChecked: false
    },

    {
      value: RoleEnum.FraudAnalyst,
      label: 'Fraud Analyst',
      isChecked: false
    },
    {
      value: RoleEnum.AccountRepresentative,
      label: 'Account Representative',
      isChecked: false
    },
    {
      value: RoleEnum.SeniorFraudAnalyst,
      label: 'Senior Fraud Analyst',
      isChecked: false
    },
    {
      value: RoleEnum.FdeManager,
      label: 'FDE Manager',
      isChecked: false
    },
    {
      value: RoleEnum.Reviewer,
      label: 'Reviewer',
      isChecked: false
    },
    {
      value: RoleEnum.CompanyAdmin,
      label: 'Company Admin',
      isChecked: false
    },
    {
      value: RoleEnum.PropertyAdmin,
      label: 'Leasing Agent',
      isChecked: false
    },
    {
      value: RoleEnum.BuildingManager,
      label: 'Building Manager',
      isChecked: false
    },
    {
      value: RoleEnum.OnSiteRepresentative,
      label: 'On Site Representative',
      isChecked: false
    }
  ]
};

const allTeamRolesAvailable = (team: string | undefined, filter: string | undefined) => {
  return (team === 'snappt' ? SnapptTeam : LeasingTeam).every(
    (role) => filter?.includes(role) || false
  );
};

const getCheckedValue = (filter: string | undefined, role: string, team: string | undefined) => {
  if (team && allTeamRolesAvailable(team, filter)) {
    return false;
  }
  return filter?.includes(role) || false;
};

const isTeamRole = (team: string, value: RoleEnum) =>
  (team === 'snappt' ? SnapptTeam : LeasingTeam).some((role) => role === value);

const reducer = (
  state: FilterData,
  action: { type?: string; team?: string; filter?: string; filterData?: string; role?: RoleEnum }
) => {
  switch (action.type) {
    case 'LOAD_TEAM': {
      const { team, filter, role } = action;

      const availableFilterRoles = (role && USER_ROLE_VALUE_MAP[role]) || [];

      return {
        checkboxGroup: filterData.checkboxGroup.reduce((acum, checkbox) => {
          // If the role is not available for the specified team (Snappt/Leasing), filter it.
          if (team && !isTeamRole(team, checkbox.value)) {
            return acum;
          }

          // If the logged user doesn't have access to the role, filter the option out
          if (!availableFilterRoles.includes(checkbox.value as RoleEnum)) {
            return acum;
          }

          return [
            ...acum,
            { ...checkbox, isChecked: getCheckedValue(filter, checkbox.value, team) }
          ];
        }, [] as CheckboxData[])
      };
    }

    case 'SET_FILTER': {
      const { filterData, team } = action;
      return {
        checkboxGroup: state.checkboxGroup.map((checkbox) => ({
          ...checkbox,
          isChecked: getCheckedValue(filterData, checkbox.value, team)
        }))
      };
    }
  }
  throw Error('Unknown action: ' + action.type);
};

export default reducer;
