import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Card } from 'src/components/atoms/Card';
import { Row } from 'src/components/atoms/Row';
import { Line } from 'src/components/atoms/Line';
import Grid from 'src/components/atoms/Grid';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import Button, { ButtonColor, ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import { ControlledCheckboxGroup } from 'src/components/molecules/CheckboxGroup';
import { ControlledRadioButtonGroup } from 'src/components/molecules/RadioButtonGroup';
import { ControlledDatePicker } from 'src/components/molecules/DatePicker';

import { Container } from './styles';
import { FilterPanelProps } from './types';

const FilterPanel: FC<FilterPanelProps> = ({
  filterText,
  dateFilterTitle = 'By Date',
  enabledApply,
  onApply,
  onCancel,
  onClear
}) => {
  const { watch } = useFormContext();
  const { radioGroup, checkboxGroup, endDate, startDate } = watch();

  return (
    <Container>
      <Card border rounded alignContent="center">
        <Row direction="column" gap={0.75}>
          <Row.Col size={1}>
            <Grid justify="space-between" alignItems="center">
              <Text color={TextColor.initial}>Filter</Text>
              <Button
                name="button-clear-all-filter"
                variant={ButtonVariant.ghost}
                onClick={onClear}
              >
                Clear all filters
              </Button>
            </Grid>
          </Row.Col>
          {filterText && (
            <Row.Col size={1}>
              <Text color={TextColor.gray600} variant={TextVariant.small}>
                {filterText}
              </Text>
            </Row.Col>
          )}
          <Line />
          <Row direction="column" gap={1}>
            <Text color={TextColor.gray700} variant={TextVariant.small} isBold>
              Report
            </Text>
            <Row.Col size={1}>
              <Row justify="space-between" gap={1}>
                {Boolean(radioGroup?.length) && (
                  <Row.Col size={0.4}>
                    <Row direction="column" gap={1}>
                      <Text color={TextColor.gray700} variant={TextVariant.small}>
                        Status
                      </Text>
                      <ControlledRadioButtonGroup name="radioGroup" />
                    </Row>
                  </Row.Col>
                )}
                {Boolean(checkboxGroup?.length) && (
                  <Row.Col size={0.6}>
                    <Row direction="column" gap={1}>
                      <Text color={TextColor.gray700} variant={TextVariant.small}>
                        Results
                      </Text>
                      <ControlledCheckboxGroup name="checkboxGroup" />
                    </Row>
                  </Row.Col>
                )}
              </Row>
            </Row.Col>
          </Row>
          <Line />
          <Row direction="column" gap={1}>
            <Text color={TextColor.gray700} variant={TextVariant.small} isBold>
              {dateFilterTitle}
            </Text>
            <Row gap={1}>
              <Row.Col size={1}>
                <Row alignItems="baseline" gap={0.5}>
                  <Text color={TextColor.gray700} variant={TextVariant.small}>
                    From
                  </Text>
                  <ControlledDatePicker name="startDate" />
                </Row>
              </Row.Col>
              <Row.Col size={1}>
                <Row alignItems="baseline" gap={0.5}>
                  <Text color={TextColor.gray700} variant={TextVariant.small}>
                    To
                  </Text>
                  <ControlledDatePicker
                    name="endDate"
                    selectsEnd={true}
                    startDate={new Date(startDate) ?? null}
                    endDate={new Date(endDate) ?? null}
                    minDate={new Date(startDate) ?? null}
                    disabled={!startDate?.length}
                  />
                </Row>
              </Row.Col>
            </Row>
          </Row>
          <Line />
          <Row gap={1}>
            <Button
              name="button-apply-filter"
              onClick={onApply}
              size={ButtonSize.medium}
              color={ButtonColor.primary}
              variant={ButtonVariant.contained}
              isDisabled={!enabledApply}
            >
              Apply
            </Button>
            <Button
              name="button-cancel-filter"
              onClick={onCancel}
              size={ButtonSize.medium}
              color={ButtonColor.primary}
              variant={ButtonVariant.outline}
            >
              Cancel
            </Button>
          </Row>
        </Row>
      </Card>
    </Container>
  );
};

export default FilterPanel;
