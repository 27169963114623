import * as language from 'src/i18n/en';

export const NAME = 'DUP';

export const AOA_PROPERTY_SLUG = 'AOA';

export const UNAUTHENTICATED_SESSION_TOKEN_KEY = 'unauthenticated_session_token';

export const URLS_SLUG = {
  leasing_team: {
    name: 'leasing_team',
    path: '/applyonbehalf/',
    link: '/application/applyonbehalf/'
  },
  applicant: { name: 'unauthenticated_user', path: '', link: '/application/apply/' }
};

export const DOCUMENT_TYPES = [
  { key: 'paystub', value: 'PAYSTUB', label: language.default.dup_document_type_paystub },
  {
    key: 'bank_statement',
    value: 'BANK_STATEMENT',
    label: language.default.dup_document_type_bank_statement
  },
  {
    key: 'cash_app_statement',
    value: 'CASH_APP_STATEMENT',
    label: language.default.dup_document_type_cash_app_statement
  },
  {
    key: 'credit_debit_card_statement',
    value: 'CREDIT_DEBIT_CARD_STATEMENT',
    label: language.default.dup_document_type_credit_debit_card_statement
  },
  {
    key: 'department_of_veterans_affairs_benefit_letter',
    value: 'DEPARTMENT_OF_VETERANS_AFFAIRS_BENEFIT_LETTER',
    label: language.default.dup_document_type_department_of_veterans_affairs_benefit_letter
  },
  {
    key: 'investment_account',
    value: 'INVESTMENT_ACCOUNT',
    label: language.default.dup_document_type_investment_account
  },
  {
    key: 'social_security_benefits_letter',
    value: 'SOCIAL_SECURITY_BENEFITS_LETTER',
    label: language.default.dup_document_type_social_security_benefits_letter
  },
  {
    key: 'social_security_statement',
    value: 'SOCIAL_SECURITY_STATEMENT',
    label: language.default.dup_document_type_social_security_statement
  },
  {
    key: 'tax_transcript',
    value: 'TAX_TRANSCRIPT',
    label: language.default.dup_document_type_tax_transcript
  },
  {
    key: 'utility_bill',
    value: 'UTILITY_BILL',
    label: language.default.dup_document_type_utility_bill
  },
  {
    key: 'student_visa',
    value: 'STUDENT_VISA',
    label: language.default.dup_document_type_student_visa
  },
  {
    key: 'other',
    value: 'OTHER',
    label: language.default.dup_document_type_other
  }
];
