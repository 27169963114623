import Gap from 'src/components/atoms/Gap';
import Row from 'src/components/atoms/Row/Row';
import SkeletonPiece, { SkeletonPieceColor } from 'src/components/atoms/SkeletonPiece';
import { Container } from './styles';

const LoaderTeamCard: React.FC = () => {
  return (
    <Container>
      <Row direction="column" data-testid="LoaderTeamCard">
        <SkeletonPiece width={140} height={24} color={SkeletonPieceColor.gray50} />
        <Gap height={0.5} />
        <SkeletonPiece width={172} height={16} color={SkeletonPieceColor.gray100} />
        <Gap height={0.9} />
        <SkeletonPiece width={86} height={16} color={SkeletonPieceColor.gray50} />
        <Gap height={0.5} />
        <SkeletonPiece width={103} height={24} color={SkeletonPieceColor.gray200} />
      </Row>
    </Container>
  );
};

export default LoaderTeamCard;
