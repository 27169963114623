/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { createUserAndCompanyThunk } from '../../services';
import { IUserCompanyRequest } from 'src/ts/interfaces';
import { actions as userActions } from 'src/features/user/userSlice';
import { selectors } from '../../index';
import { useEffect } from 'react';
import useSnackbarProvider from 'src/hooks/useSnackbarProvider';

const useCompanyAndUser = () => {
  const dispatch = useAppDispatch();
  const userSelectors = useSelector(selectors);

  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();

  const onCreateUserAndCompany = (payload: IUserCompanyRequest) =>
    dispatch(createUserAndCompanyThunk(payload));

  const isCreatingUserAndCompany =
    userSelectors.createUserAndCompany.status === StatusTypes.LOADING;
  const isCreated = userSelectors.createUserAndCompany.status === StatusTypes.SUCCESS;
  const hasError = userSelectors.createUserAndCompany.status === StatusTypes.ERROR;

  const onResetCompanyAndUserState = () => {
    dispatch(userActions.resetCompanyAndUserState());
  };

  useEffect(() => {
    if (isCreated) {
      onResetCompanyAndUserState();
      showSnackbar(
        VariantType.success,
        'Company and User account have been created successfully',
        SnackTypes.none
      );
    }
  }, [isCreated]);

  useEffect(() => {
    if (hasError) {
      onResetCompanyAndUserState();
      showSnackbar(
        VariantType.error,
        `${userSelectors.createUserAndCompany.error} Please refresh and try again`,
        SnackTypes.none
      );
    }
  }, [hasError]);

  return {
    isCreatingUserAndCompany,
    onCreateUserAndCompany
  };
};

export default useCompanyAndUser;
