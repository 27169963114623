// Components
import { Header } from 'src/components/DUP/atoms/Header';
import { Body } from '../atoms/Body';
import { Container } from './styles';

// Hooks
import useForm from 'src/features/DUP/form/hooks/useForm';

export type DUPProps = {
  children: React.ReactNode;
};

const DUP: React.FC<DUPProps> = (props: DUPProps) => {
  const { children } = props;
  const { type, information } = useForm();

  return (
    <Container>
      <Header information={information} type={type} />
      <Body>{children}</Body>
    </Container>
  );
};

export default DUP;
