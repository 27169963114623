import { createAsyncThunk } from '@reduxjs/toolkit';
import { EntryReviewEscalationRequestType } from 'src/ts/interfaces';

import api from './api';
import { NAME, REQUEST_REVIEW_ENTRY_IDENTIIFIER } from './constants';

export const getPendingReviewEntryThunk = createAsyncThunk(
  `${NAME}/getPendingReviewEntry`,
  async () => {
    const response = await api.getPendingReviewEntry();
    return response.data;
  }
);

export const requestReviewEntryThunk = createAsyncThunk(`${NAME}/requestReviewEntry`, async () => {
  try {
    const response = await api.assignEntryRequest();
    return response.data;
  } catch (error) {
    console.error(REQUEST_REVIEW_ENTRY_IDENTIIFIER, error);
    throw new Error('Failed to request entry for review');
  }
});

export const escalateThunk = createAsyncThunk(
  `${NAME}/escalateThunk`,
  async ({ entryId, data }: { entryId: string; data: EntryReviewEscalationRequestType }) => {
    try {
      return api.escalate(entryId, data);
    } catch (error) {
      console.error(`${NAME}/escalateThunk`, error);
      throw new Error('Failed to escalate the Entry');
    }
  }
);
