import { useSelector } from 'react-redux';
import { LeasingTeam, SnapptTeam } from 'src/constants/roles';
import { selectors as authSelectors } from 'src/features/auth/authSlice';
import { RoleEnum } from 'src/ts/enums';
import { RoleType } from 'src/ts/interfaces';

const {
  Admin,
  Reviewer,
  FraudAnalyst,
  CompanyAdmin,
  BuildingManager,
  PropertyAdmin,
  OnSiteRepresentative,
  FdeManager,
  SeniorFraudAnalyst,
  AccountRepresentative
} = RoleEnum;

const useRole = () => {
  const user = useSelector(authSelectors.user);
  const role = user?.role;

  const getRole = (role?: RoleType) => {
    if (!role) return undefined;

    const isAdmin = role === Admin;
    const isReviewer = role === Reviewer;
    const isFraudAnalyst = role === FraudAnalyst;
    const isSeniorFraudAnalyst = role === SeniorFraudAnalyst;
    const isAnalyst = [FraudAnalyst, SeniorFraudAnalyst].includes(role);
    const isFDEManager = role === FdeManager;
    const isCompanyAdmin = role === CompanyAdmin;
    const isBuildingManager = role === BuildingManager;
    const isPropertyAdmin = role === PropertyAdmin;
    const isOnSiteRepresentative = role === OnSiteRepresentative;
    const isAdminOrReviewerOrFraudAnalyst = [Admin, Reviewer, FraudAnalyst].includes(role);
    const isLeasingTeam = LeasingTeam.includes(role);
    const isLeasingAgent = [BuildingManager, PropertyAdmin].includes(role);
    const isSnapptTeam = SnapptTeam.includes(role);
    const isCompanyAdminOrBuildingManager = [CompanyAdmin, BuildingManager].includes(role);
    const isAccountRepresentative = role === AccountRepresentative;
    const isAdminOrAccountRepresentative = [Admin, AccountRepresentative].includes(role);
    const isAdminOrFdeManager = [Admin, FdeManager].includes(role);
    const isCompanyAdminOrOnSiteRepresentativeOrPropertyAdminOrBuildingManager = [
      CompanyAdmin,
      OnSiteRepresentative,
      PropertyAdmin,
      BuildingManager
    ].includes(role);

    // Permissions
    const canSeeTeams = [Admin, FdeManager].includes(role);

    return {
      isAdmin,
      isReviewer,
      isFraudAnalyst,
      isSeniorFraudAnalyst,
      isAnalyst,
      isFDEManager,
      isCompanyAdmin,
      isBuildingManager,
      isPropertyAdmin,
      isOnSiteRepresentative,
      isAdminOrReviewerOrFraudAnalyst,
      isLeasingTeam,
      isLeasingAgent,
      isSnapptTeam,
      isCompanyAdminOrBuildingManager,
      isAccountRepresentative,
      isAdminOrAccountRepresentative,
      isAdminOrFdeManager,
      canSeeTeams,
      isCompanyAdminOrOnSiteRepresentativeOrPropertyAdminOrBuildingManager
    };
  };

  return {
    user,
    role,
    ...getRole(role)
  };
};

export default useRole;
