import { actions } from 'src/features/timezone/timezoneSlice';
import { useAppDispatch } from 'src/store';
import { TIME_ZONE } from '../data/timezone';
import { getAll } from '../services';

export const onGetLabel = (id?: string) => {
  if (id) return TIME_ZONE.find((timezone) => timezone.value === id)?.label;
};

const useTimezone = () => {
  const dispatch = useAppDispatch();

  const onGetAll = () => {
    dispatch(getAll());
  };

  const onResetGetAll = () => {
    dispatch(actions.resetGetAll());
  };

  const onSearch = (q: string) => {
    const search = decodeURIComponent(q);
    if (search.length >= 3) dispatch(actions.search({ q: search }));
  };

  return {
    onGetAll,
    onResetGetAll,
    onSearch,
    onGetLabel
  };
};

export default useTimezone;
