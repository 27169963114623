import styled from 'styled-components/macro';

export const StyledTableHead = styled.thead`
  display: table-header-group;
  width: 100%;
  background: transparent;

  & > tr > td {
    color: ${({ theme }) => theme.color.gray[600]};
    padding: 0.5rem 1rem;
  }

  & > thead {
    margin-bottom: 0.5rem;
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.gray[100]};
  }
`;
