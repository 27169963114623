export const defaultValues = {
  filterPanel: {
    startDate: '',
    endDate: '',
    radioGroup: [],
    checkboxGroup: [
      {
        value: 'PASS',
        label: 'PASS',
        isChecked: false
      },
      {
        value: 'FAIL',
        label: 'FAIL',
        isChecked: false
      }
    ]
  },
  sortPanel: {
    dropdownItems: [
      {
        label: 'Applicant',
        value: 'name',
        isSelected: false
      },
      {
        label: 'Property',
        value: 'propertyName',
        isSelected: false
      },
      {
        label: 'Link Created',
        value: 'insertedAt',
        isSelected: false
      },
      {
        label: 'Results Received',
        value: 'sessionClosedAt',
        isSelected: false
      },
      {
        label: 'Result',
        value: 'status',
        isSelected: false
      }
    ],
    radioGroup: [
      {
        value: 'asc',
        label: 'Ascending',
        isChecked: true
      },
      {
        value: 'desc',
        label: 'Descending',
        isChecked: false
      }
    ]
  }
};

export const selectedSort = {
  field: undefined,
  order: 'asc'
};

export const selectedFilter = {
  results: undefined,
  status: undefined,
  startDate: undefined,
  endDate: undefined
};

export const filterQueryOptions: Record<string, { field: string; operator: string }> = {
  results: {
    field: 'status',
    operator: '@>'
  },
  startDate: {
    field: 'sessionClosedAt',
    operator: '>'
  },
  endDate: {
    field: 'sessionClosedAt',
    operator: '<'
  }
};
