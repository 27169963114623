import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Status } from 'src/ts/enums';
import { selectors } from '../index';

const useEntriesLogsData = () => {
  const { status, data, count, filters } = useSelector(selectors);

  const isUninitialized = status === Status.IDLE;
  const isLoading = status === Status.LOADING;
  const isError = status === Status.ERROR;
  const isSuccess = status === Status.SUCCESS;

  const memoizedFilters = useMemo(() => ({ ...filters }), [filters]);

  const isToolbarDisabled = isLoading || isError || (!data.length && !filters.filter && !filters.q);

  return {
    data,
    count,
    filters: memoizedFilters,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    isToolbarDisabled
  };
};

export default useEntriesLogsData;
