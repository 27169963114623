// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// Vendor
import {
  SnackbarKey,
  SnackbarProvider as CustomSnackbarProvider,
  useSnackbar as useSnackbarFC
} from 'notistack';
import PropTypes from 'prop-types';
import React, { ReactNode, useEffect } from 'react';
import { ReactSVG } from 'react-svg';

// Components
import Button, { ButtonColor, ButtonSize, ButtonVariant } from '../../atoms/Button';
import './style.scss';

const PUBLIC_URL = process.env.PUBLIC_URL;
export const useSnackbar = useSnackbarFC;

export enum SnackTypes {
  go = 'go',
  retry = 'retry',
  none = 'none'
}

export enum VariantType {
  error = 'error',
  success = 'success',
  warning = 'warning',
  info = 'info',
  default = 'default'
}

export type VariantTypes = (typeof VariantType)[keyof typeof VariantType];

export type SnackbarProviderProps = {
  key?: string;
  children: ReactNode;
  autoHideDuration: number;
  maxSnack: number;
};

export type SnackbarInnerComponentProps = {
  key: SnackbarKey;
  variant: VariantTypes;
  closeSnackbar: (key: SnackbarKey) => void;
  type?: SnackTypes;
  callback?: () => void;
};

const iconVariant = {
  error: <ReactSVG className="svg" src={`${PUBLIC_URL}/assets/images/icon-error.svg`} />,
  success: <ReactSVG className="svg" src={`${PUBLIC_URL}/assets/images/icon-success.svg`} />,
  info: <ReactSVG className="svg" src={`${PUBLIC_URL}/assets/images/icon-info.svg`} />,
  warning: <ReactSVG className="svg" src={`${PUBLIC_URL}/assets/images/icon-warning.svg`} />,
  notification: <ReactSVG className="svg" src={`${PUBLIC_URL}/assets/images/icon-info.svg`} />
};

/**
 * Allow return the custom snackbar message
 * @param {number} key Value autogenerated by enqueueSnackbar
 * @param {('error' | 'info' | 'success')} variant Style of the message
 * @param {Function} [closeSnackbar] Allow close the message
 * @param {('go' | 'retry' , 'none')} [type] Allow show additional buttons (optional)
 * @param {Function} [callback] Function with your custom action (optional)
 */

export const SnackbarInnerComponent: React.FC<SnackbarInnerComponentProps> = (
  props: SnackbarInnerComponentProps
) => {
  const { key, closeSnackbar, variant, type, callback } = props;

  useEffect(() => {
    const obj = document.getElementsByClassName('notistack-CollapseWrapper')[0];
    if (obj) {
      obj.setAttribute('data-id', `Snackbar-${variant}`);
    }
  }, [variant]);

  return (
    <>
      {type === SnackTypes.go && (
        <Button
          name="Go through Snackbar"
          dataId="Snackbar-Go-Button"
          variant={ButtonVariant.contained}
          color={ButtonColor.primary}
          size={ButtonSize.normal}
          onClick={callback}
        >
          GO
        </Button>
      )}
      {type === SnackTypes.retry && (
        <Button
          name="Retry through Snackbar"
          dataId="Snackbar-Retry-Button"
          variant={ButtonVariant.contained}
          color={ButtonColor.primary}
          size={ButtonSize.normal}
          onClick={callback}
        >
          RETRY
        </Button>
      )}
      <Button
        name="Close Snackbar"
        dataId="Snackbar-Close-Button"
        variant={ButtonVariant.contained}
        color={ButtonColor.primary}
        size={ButtonSize.normal}
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        <ReactSVG className="svg" src={`${PUBLIC_URL}/assets/images/close.svg`} />
      </Button>
    </>
  );
};

/**
 * Based on `notistack`
 * https://github.com/iamhosseindhv/notistack
 */
const SnackbarProvider: React.FC<SnackbarProviderProps> = (props: SnackbarProviderProps) => {
  const { children, autoHideDuration, maxSnack } = props;
  return (
    <CustomSnackbarProvider
      iconVariant={iconVariant}
      maxSnack={maxSnack}
      autoHideDuration={autoHideDuration}
    >
      {children}
    </CustomSnackbarProvider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
  autoHideDuration: PropTypes.number.isRequired,
  maxSnack: PropTypes.number.isRequired
};

SnackbarProvider.defaultProps = {
  children: 'Test',
  autoHideDuration: 200000,
  maxSnack: 1
};

export default SnackbarProvider;
