import styled from 'styled-components/macro';

export const StyledTableBody = styled.tbody`
  display: table-row-group;
  width: 100%;

  & > tr {
    &:nth-child(even) {
      background: ${({ theme }) => theme.color.gray[100]};
    }

    &:nth-child(odd) {
      background: ${({ theme }) => theme.color.gray[50]};
    }

    & > td:first-child {
      border-left: 1px solid ${({ theme }) => theme.color.gray[200]};
    }

    & > td:last-child {
      border-right: 1px solid ${({ theme }) => theme.color.gray[200]};
    }
  }

  & > tr:first-child > td {
    border-top: 1px solid ${({ theme }) => theme.color.gray[200]};

    &:first-child {
      border-top-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
    }
  }

  & > tr:last-child > td {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};

    &:first-child {
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-bottom-right-radius: 6px;
    }
  }

  & > tr:hover > td {
    background: ${({ theme }) => theme.color.blue[100]};
  }
`;
