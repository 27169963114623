// Vendor
import React from 'react';

// Components
import { Icon } from 'src/components/atoms/Icon';
import Tag, { TagColor } from 'src/components/atoms/Tag';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { ToolTip } from 'src/components/atoms/ToolTip';
import { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import {
  CardContainer,
  CardTagListContainer,
  Container,
  StyledTitle,
  StyledTitleNoData
} from './styles';

export interface CardTagListProps {
  title: string;
  icon: string;
  items: string[];
  numItems?: number;
}

const CardTagList: React.FC<CardTagListProps> = ({
  items,
  title,
  icon,
  numItems = 3
}: CardTagListProps) => {
  const hasData = items.length > 0 && items[0] !== '';

  const TagList = () => {
    return (
      <Container>
        <CardTagListContainer>
          {items.slice(0, numItems).map((item) => (
            <Tag key={item} label={item} color={TagColor.blue} showBackground className="tag" />
          ))}
        </CardTagListContainer>
        {items.length > numItems && (
          <ToolTip
            content={
              <>
                {items.slice(numItems).map((item, i) => (
                  <React.Fragment key={i}>
                    {item} <br />
                  </React.Fragment>
                ))}
              </>
            }
            direction={ToolTipDirection.bottom_fit}
          >
            <Tag label={`+ ${items.length - numItems} more`} color={TagColor.blue} showBackground />
          </ToolTip>
        )}
      </Container>
    );
  };

  const TitleNoData = () => {
    return (
      <StyledTitleNoData>
        <Icon icon={icon} color="muted" className="icon" />
        <Text variant={TextVariant.h4} color={TextColor.gray300} isBold>
          {`There are no ${title} to show.`}
        </Text>
      </StyledTitleNoData>
    );
  };

  const Title = () => {
    return (
      <StyledTitle>
        <Icon icon={icon} color="gray700" className="icon" />
        <Text variant={TextVariant.h4} isBold>
          {title}
        </Text>
      </StyledTitle>
    );
  };

  return (
    <CardContainer border rounded data-hasdata={hasData} data-testid="card-container">
      {hasData ? (
        <>
          <Title />
          <TagList />
        </>
      ) : (
        <TitleNoData />
      )}
    </CardContainer>
  );
};

export default CardTagList;
