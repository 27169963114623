import { COUNTRY } from './data/country';

const api = {
  getAll: () => {
    // In case we want to use an endpoint to get the Countries just update the source
    return COUNTRY;
  }
};

export default api;
