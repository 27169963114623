import PropTypes from 'prop-types';
import React from 'react';
import {
  AvatarComponent,
  ButtonItemComponent,
  IconComponent,
  LabelComponent,
  LogoWrapper,
  NavItemComponent
} from './styles';

// Icons format <icon-[name]-white.svg>
export enum NavItemIcons {
  none = 'none',
  company = 'company',
  inbox = 'inbox',
  property = 'property',
  resource = 'resource',
  tracking = 'tracking',
  user = 'user',
  logout = 'logout',
  report = 'report',
  getStarted = 'gear',
  folder = 'icon-folder-open',
  onboarding = 'onboarding',
  identityVerification = 'identityVerification',
  briefcase = 'briefcase',
  teams = 'teams',
  programmaticReport = 'programmatic'
}

export enum NavItemTarget {
  blank = '_blank',
  self = '_self'
}

export type NavItemProps = {
  name: string;
  icon: NavItemIcons;
  label: string;
  onClick?: () => void;
  to?: string;
  target?: NavItemTarget;
  customIcon?: string;
};

const PUBLIC_URL = process.env.PUBLIC_URL;

const NavItem: React.FC<NavItemProps> = (props: NavItemProps) => {
  const { name, to, target, label, icon, customIcon, onClick } = props;
  const snapptLogoUrl = `${PUBLIC_URL}/assets/images/snappt.png`;

  const Content = () => {
    return (
      <>
        {icon !== NavItemIcons.none && (
          <IconComponent src={`${PUBLIC_URL}/assets/images/icon-${icon}-white.svg`} />
        )}
        {customIcon && icon === NavItemIcons.none && (
          <LogoWrapper>
            <AvatarComponent src={customIcon ? customIcon : snapptLogoUrl} alt={label} />
          </LogoWrapper>
        )}
        <LabelComponent>{label}</LabelComponent>
      </>
    );
  };

  if (!onClick) {
    return (
      <NavItemComponent
        name={name}
        id={name}
        aria-label={label}
        aria-pressed={true}
        role="NavItem"
        to={to}
        target={target}
      >
        <Content />
      </NavItemComponent>
    );
  }

  const onSubmit = () => {
    onClick();
  };

  return (
    <ButtonItemComponent
      name={name}
      id={name}
      aria-label={label}
      aria-pressed={true}
      onClick={onSubmit}
    >
      <Content />
    </ButtonItemComponent>
  );
};

NavItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
  target: PropTypes.oneOf<NavItemTarget>(Object.values(NavItemTarget)),

  /**
   * Image URL
   */
  customIcon: PropTypes.string
};

NavItem.defaultProps = {
  name: 'NavItem',
  label: 'Item',
  icon: NavItemIcons.inbox,
  to: '/url-path',
  target: NavItemTarget.self
};

export default NavItem;
