import { useCallback } from 'react';
import { useSelector } from 'react-redux';

// Redux
import { selectors as authSelector } from 'src/features/auth/authSlice';
import { actions, selectors as userSelectors } from 'src/features/users/usersSlice';
import store, { useAppDispatch } from 'src/store';
import { changeSettingNotification, getAll, getOne, remove, update } from '../services';

// Hooks
import useRole from 'src/features/auth/hooks/useUserRoles';

// Enums
import { RoleEnum } from 'src/ts/enums';

// Constants
import { LeasingTeam, RolesValueLabel, SnapptTeam } from 'src/constants/roles';

// Types
import { IProperty, IUser, RoleType } from 'src/ts/interfaces';

const useUsers = () => {
  const dispatch = useAppDispatch();
  const {
    isBuildingManager,
    isCompanyAdmin,
    isAdmin,
    isAccountRepresentative,
    isAdminOrAccountRepresentative
  } = useRole();
  const userResponse = useSelector(authSelector.user);

  const currentFilter = userSelectors.getAll.filter(store.getState());

  const activeRolesValueLabel = RolesValueLabel.filter((role) => {
    if (!isAdminOrAccountRepresentative) {
      if (
        [RoleEnum.SeniorFraudAnalyst, RoleEnum.FraudAnalyst, RoleEnum.FdeManager].includes(
          role.value as RoleType
        )
      ) {
        return false;
      }
    }

    if (!isAdminOrAccountRepresentative && role.value === RoleEnum.AccountRepresentative) {
      return false;
    }
    return true;
  });

  const onGetAll = (page?: number, rowsPerPage?: number, filter?: string, isDDown?: boolean) => {
    const filters = filter === undefined ? currentFilter.filter : filter;
    const pages = page === undefined ? currentFilter.page : page;
    const rowsPerPages = rowsPerPage === undefined ? currentFilter.rowsPerPage : rowsPerPage;

    dispatch(
      getAll({ ...currentFilter, filter: filters, page: pages, rowsPerPage: rowsPerPages, isDDown })
    );
  };

  const onGetUser = (id?: string) => {
    if (!id) throw new Error("Is missing the user identifier to get the user's information");
    dispatch(getOne(id));
  };

  const onSave = (dataForm: IUser) => {
    if (dataForm && dataForm.id) {
      dispatch(update({ userId: dataForm.id, data: dataForm }));
    }
  };

  const onResetUpdate = () => {
    dispatch(actions.resetSave());
  };

  const onRemove = (id?: string) => {
    if (!id) throw new Error('Is missing the user identifier to remove the user');
    dispatch(remove(id));
  };

  const onHandleSettingNotifications = (user: IUser) => {
    if (!user.id)
      throw new Error('Is missing the user identifier to change the setting notification');
    dispatch(
      changeSettingNotification({ ...user, settings_notification: !user.settings_notification })
    );
  };

  const onResetRemove = (page?: number, rowsPerPage?: number, filter?: string) => {
    dispatch(actions.resetRemove());
    onGetAll(page, rowsPerPage, filter);
  };

  const extractPropertyNames = (properties: IProperty[]) => {
    return properties.map((property: IProperty) => property.name).join(', ');
  };

  const ListRoles = () => {
    switch (userResponse?.role) {
      case RoleEnum.Admin:
        return activeRolesValueLabel;

      case RoleEnum.AccountRepresentative:
        return activeRolesValueLabel.filter((item) => LeasingTeam.includes(item.value as RoleType));

      case RoleEnum.CompanyAdmin:
      case RoleEnum.BuildingManager:
        return activeRolesValueLabel.filter((item) =>
          [
            RoleEnum.BuildingManager,
            RoleEnum.PropertyAdmin,
            RoleEnum.OnSiteRepresentative
          ].includes(item.value as RoleType)
        );

      default:
        return [];
    }
  };

  const showCompanySelectorFromRole = (role: RoleType) => {
    if (SnapptTeam.includes(role)) return false;
    return true;
  };

  const showPropertySelectorFromRole = (role: RoleType) => {
    if (!showCompanySelectorFromRole(role) || [RoleEnum.CompanyAdmin].includes(role)) return false;
    return true;
  };

  const showRemoveFromRole = (role: RoleType, company_id?: string) => {
    if (isBuildingManager)
      return role === RoleEnum.PropertyAdmin || role === RoleEnum.OnSiteRepresentative;
    if (isCompanyAdmin) return company_id === userResponse?.company_id;
    if (isAdmin || isAccountRepresentative) return true;
    return false;
  };

  const showCompanyInformation = (role: RoleType) => {
    if (SnapptTeam.includes(role)) return false;
    return true;
  };

  const showPropertyInformation = (role: RoleType) => {
    if ([...SnapptTeam, RoleEnum.CompanyAdmin].includes(role)) return false;
    return true;
  };

  const showTeamSelector = (role: RoleType) => {
    return [
      RoleEnum.FraudAnalyst,
      RoleEnum.FdeManager,
      RoleEnum.SeniorFraudAnalyst,
      RoleEnum.AccountRepresentative
    ].includes(role);
  };

  const allowAddUser = () => {
    if (!userResponse?.role) return false;
    if (
      [
        RoleEnum.Admin,
        RoleEnum.BuildingManager,
        RoleEnum.CompanyAdmin,
        RoleEnum.AccountRepresentative
      ].includes(userResponse?.role)
    )
      return true;
    return false;
  };

  const onSearch = (q: string, filter?: string) => {
    const currentFilter = userSelectors.getAll.filter(store.getState());
    const search = encodeURIComponent(q);
    dispatch(getAll({ ...currentFilter, filter, page: 0, q: search }));
  };

  const onSort = (query: string, filter?: string) => {
    dispatch(getAll({ ...currentFilter, filter, page: 0, sort: query }));
  };

  const onResetGetUser = useCallback(() => {
    dispatch(actions.resetGetOne());
  }, [dispatch]);

  const onResetGetAll = useCallback(() => {
    dispatch(actions.resetGetAll());
  }, [dispatch]);

  return {
    onGetAll,
    onResetGetAll,
    onGetUser,
    onSave,
    onResetUpdate,
    ListRoles,
    onRemove,
    onHandleSettingNotifications,
    onResetRemove,
    showCompanySelectorFromRole,
    showPropertySelectorFromRole,
    showRemoveFromRole,
    showCompanyInformation,
    showPropertyInformation,
    showTeamSelector,
    allowAddUser,
    extractPropertyNames,
    onSearch,
    onSort,
    userResponse,
    onResetGetUser,
    currentFilter
  };
};

export default useUsers;
