import {
  ActionReducerMapBuilder,
  createEntityAdapter,
  createSlice,
  Draft,
  EntityState,
  PayloadAction
} from '@reduxjs/toolkit';
import { EntrySendReportResponse } from '@snapptinc/fraud-platform';
import { EntrySendReport } from '@snapptinc/fraud-platform/api';
import { AxiosResponse } from 'axios';

import { requestDocumentsThunk, sendReportThunk } from 'src/features/entries/services';
import { RootState } from 'src/store';
import { Status as StatusTypes } from 'src/ts/enums';
import { IFolder } from 'src/ts/interfaces';
import { NAME } from './constants';
import { getFolderByIdThunk, updateFolderThunk } from './services';

export const foldersAdapter = createEntityAdapter<IFolder>({
  selectId: (folder) => folder.id
});

interface StateData extends EntityState<IFolder> {
  status: StatusTypes;
  error?: string | null;
}

const foldersSlice = createSlice({
  name: NAME,
  initialState: foldersAdapter.getInitialState({
    status: StatusTypes.IDLE,
    error: null
  }),
  reducers: {},
  extraReducers: (builder) => {
    getFolderByIdReducer(builder);
    updateFolderReducer(builder);
    reportReducer(builder);
  }
});

const getFolderByIdReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getFolderByIdThunk.pending, (state) => {
    state.status = StatusTypes.LOADING;
    state.error = null;
  });
  builder.addCase(getFolderByIdThunk.fulfilled, (state, action) => {
    const response = action.payload;
    const folder = response?.data as IFolder;

    state.status = StatusTypes.SUCCESS;
    foldersAdapter.upsertOne(state, folder || {});
  });
  builder.addCase(getFolderByIdThunk.rejected, (state, action) => {
    state.status = StatusTypes.ERROR;
    state.error = action.error?.message;
  });
};

const updateFolderReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(updateFolderThunk.pending, (state) => {
    state.status = StatusTypes.LOADING;
    state.error = null;
  });
  builder.addCase(updateFolderThunk.fulfilled, (state, action) => {
    const response = action.payload;
    const folder = response?.data as IFolder;

    state.status = StatusTypes.SUCCESS;
    foldersAdapter.upsertOne(state, folder || {});
  });
  builder.addCase(updateFolderThunk.rejected, (state, action) => {
    state.status = StatusTypes.ERROR;
    state.error = action.error?.message;
  });
};

const reportReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  const upsertFolder = (
    state: Draft<StateData>,
    action: PayloadAction<AxiosResponse<EntrySendReportResponse>>
  ) => {
    const response = action.payload;
    const { folder } = response?.data as EntrySendReport;

    foldersAdapter.upsertOne(state, (folder as IFolder) || {});
  };

  builder
    .addCase(sendReportThunk.fulfilled, upsertFolder)
    .addCase(requestDocumentsThunk.fulfilled, upsertFolder);
};

export const selectors = {
  ...foldersAdapter.getSelectors((state: RootState) => state[NAME]),
  status: (state: RootState) => state[NAME].status,
  isLoading: (state: RootState) => state[NAME].status === StatusTypes.LOADING
};

export const { reducer, actions } = foldersSlice;
