import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 9.375rem;
  z-index: 9999;

  .react-datepicker__tab-loop {
    position: absolute;
    z-index: 1;

    .react-datepicker-popper {
      position: unset !important;
      transform: none !important;
      padding: 0;
    }
  }

  .react-datepicker {
    border: 0.0625rem solid ${({ theme }) => theme.color.gray[200]};
    border-radius: 0.25rem;
    top: -1rem;

    .react-datepicker__navigation {
      margin-top: 0.8rem;
    }

    .react-datepicker__month-container {
      .react-datepicker__header {
        background: ${({ theme }) => theme.color.blue[600]};
        border: none;
        padding-top: 1rem;

        select {
          border: 0.0625rem solid ${({ theme }) => theme.color.blue[600]};
          padding: 0.25rem;
          background: ${({ theme }) => theme.color.white};
          ${({ theme }) => theme.font.small};
          ${({ theme }) => theme.font.bold};
          ${({ theme }) => theme.font.colors.secondary};
        }

        .react-datepicker__current-month {
          display: none;
        }

        .react-datepicker__header__dropdown {
          ${({ theme }) => theme.font.small};
          line-height: 1.5rem;
        }

        .react-datepicker__day-names {
          padding: 0.5rem 0;

          .react-datepicker__day-name {
            ${({ theme }) => theme.font.small};
            color: ${({ theme }) => theme.color.white};
            line-height: 1.5rem;
            text-transform: uppercase;
          }
        }
      }

      .react-datepicker__month {
        margin: 0;

        .react-datepicker__week {
          .react-datepicker__day {
            ${({ theme }) => theme.font.small};
            line-height: 1.5rem;

            &.react-datepicker__day--selected,
            &.react-datepicker__day--keyboard-selected,
            &.react-datepicker__day--in-selecting-range,
            &.react-datepicker__day--in-range {
              color: ${({ theme }) => theme.color.white};
              background: ${({ theme }) => theme.color.blue[700]};
            }

            &.react-datepicker__day--today {
              font-weight: 600;
            }

            &.react-datepicker__day--disabled {
              background: ${({ theme }) => theme.color.gray[50]};
              border-radius: 0.25rem;
            }
          }
        }
      }
    }
  }
`;

export const StyledContainer = styled(Container)`
  position: relative;
  margin-bottom: 1.7rem;

  & .react-datepicker {
    margin-top: 1.65rem;
  }

  & input {
    ${({ theme }) => theme.font.small};
    ${({ theme }) => theme.font.colors.secondary};
    line-height: 1.5rem;
    font-size: 1rem;
    padding: 0.625rem 0.5rem;
    border: 0.125rem solid ${({ theme }) => theme.color.gray[200]};
    border-radius: 0.25rem;
    width: 100%;
    cursor: pointer;
  }

  & .floating-icon {
    position: absolute;
    right: 0.5rem;
    top: 1rem;
    cursor: pointer;
    user-select: none;
  }

  & svg {
    width: 1.5rem;
    height: 1rem;
  }
`;
