/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

/**
 * References:
 * - https://developer.adobe.com/document-services/docs/overview/pdf-embed-api/
 * - https://developer.adobe.com/document-services/apis/pdf-embed/
 * - https://github.com/adobe/pdf-embed-api-samples
 */

const defaultConfig = {
  embedMode: 'SIZED_CONTAINER',
  exitPDFViewerType: 'CLOSE',
  defaultViewMode: 'FIT_WIDTH',
  locale: 'en-US',
  showAnnotationTools: false,
  showBookmarks: false,
  enableFormFilling: false
};

class ViewSDKClient {
  constructor() {
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve();
      } else {
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve();
        });
      }
    });
    this.adobeDCView = undefined;
    this.viewAPI = undefined;
  }
  ready() {
    return this.readyPromise;
  }
  async previewFile(
    divId: string,
    viewerConfig: object,
    url = '',
    fileName = 'None',
    id = '',
    clientId: string
  ) {
    const config = {
      clientId
    };
    if (divId) {
      config.divId = divId;
    }
    this.adobeDCView = new window.AdobeDC.View(config);
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        content: {
          location: {
            url
          }
        },
        metaData: {
          fileName: fileName + '.pdf',
          id
        }
      },
      { ...defaultConfig, ...viewerConfig }
    );

    const adobeViewer = await previewFilePromise;
    this.viewAPI = await adobeViewer.getAPIs();
    return this.viewAPI;
  }

  async zoomIn() {
    await this.viewAPI.getZoomAPIs().zoomIn();
  }

  async zoomOut() {
    await this.viewAPI.getZoomAPIs().zoomOut();
  }

  async setZoomLevel(zoomLevel: number) {
    const api = await this.ready();
    api.getZoomAPIs().setZoomLevel(zoomLevel);
  }

  previewFileUsingFilePromise(divId: string, filePromise, fileName: string, clientId: string) {
    this.adobeDCView = new window.AdobeDC.View({
      clientId: clientId,
      divId
    });
    this.adobeDCView.previewFile(
      {
        content: {
          promise: filePromise
        },
        metaData: {
          fileName: fileName + '.pdf'
        }
      },
      {}
    );
  }
  registerSaveApiHandler() {
    const saveApiHandler = (metaData, content, options) => {
      console.log(metaData, content, options);
      return new Promise((resolve) => {
        setTimeout(() => {
          const response = {
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              metaData: Object.assign(metaData, {
                updatedAt: new Date().getTime()
              })
            }
          };
          resolve(response);
        }, 2000);
      });
    };
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.SAVE_API,
      saveApiHandler,
      {}
    );
  }
  registerEventsHandler() {
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      (event) => {
        console.log(event);
      },
      {
        enablePDFAnalytics: true
      }
    );
  }
}
export default ViewSDKClient;
