import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { fdeInboxTabs, Status as StatusTypes } from 'src/ts/enums';
import { IFDEInboxEntry, IPaginationRequest } from 'src/ts/interfaces';
import { NAME } from './constants';
import { fdeInboxGetAllThunk } from './services';

export type StateData = {
  data: IFDEInboxEntry[] | null;
  count: number;
  filters: IPaginationRequest;
  status: StatusTypes;
  error?: string | null;
  tabSelection: fdeInboxTabs;
};

export const initialState: StateData = {
  data: null,
  count: 0,
  filters: {
    q: '',
    page: 0,
    rowsPerPage: 50
  },
  status: StatusTypes.IDLE,
  error: null,
  tabSelection: fdeInboxTabs.DOCUMENTS
};

const fdeInboxSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    filtering: (state: StateData, action) => {
      state.filters.filter = action.payload.filter;
      state.filters.page = action?.payload?.page;
      state.filters.rowsPerPage = action?.payload?.rowsPerPage;
    },
    pagination: (state: StateData, action) => {
      state.filters.page = action?.payload?.page;
      state.filters.rowsPerPage = action?.payload?.rowsPerPage;
    },
    search: (state: StateData, action) => {
      state.filters.q = action.payload.q;
      state.filters.page = action?.payload?.page;
      state.filters.rowsPerPage = action?.payload?.rowsPerPage;
    },
    sorting: (state: StateData, action) => {
      state.filters.sort = action.payload.sort;
      state.filters.page = action?.payload?.page;
      state.filters.rowsPerPage = action?.payload?.rowsPerPage;
    },
    setSelectedTab: (state: StateData, action) => {
      state.tabSelection = action.payload;
    }
  },
  extraReducers: (builder) => {
    fdeInboxGetAllReducer(builder);
  }
});

const fdeInboxGetAllReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(fdeInboxGetAllThunk.pending, (state) => {
    state.status = StatusTypes.LOADING;
    state.error = null;
  });
  builder.addCase(fdeInboxGetAllThunk.fulfilled, (state, action: PayloadAction<any>) => {
    state.status = StatusTypes.SUCCESS;
    state.data = action.payload?.data;
    state.count = action.payload?.count || 0;
  });
  builder.addCase(fdeInboxGetAllThunk.rejected, (state, action) => {
    state.status = StatusTypes.ERROR;
    state.error = action.error?.message;
  });
};

export const selectors = (state: RootState) => ({
  data: state[NAME].data,
  count: state[NAME].count,
  filters: state[NAME].filters,
  status: state[NAME].status,
  error: state[NAME].error,
  selectedFdeInboxTab: state[NAME].tabSelection
});
export const { reducer, actions } = fdeInboxSlice;
