import styled from 'styled-components/macro';

import Text from 'src/components/atoms/Text';
import { Icon } from 'src/components/atoms/Icon';

export const StyledTextDescription = styled(Text)`
  margin-bottom: 1.5625rem;
`;

export const LaunchIcon = styled(Icon).attrs((attrs) => ({
  icon: 'launch',
  color: 'primary'
}))`
  margin-left: 0.5rem;
`;
