import { useCallback } from 'react';

import { useAppDispatch } from 'src/store';
import { getPaginationRequest } from 'src/ts/interfaces';
import { fdeInboxGetAllThunk } from '../index';

const useGetFdeInbox = () => {
  const dispatch = useAppDispatch();

  const onGetFolders = useCallback(
    (filters: getPaginationRequest) => dispatch(fdeInboxGetAllThunk(filters)),
    [dispatch]
  );

  return {
    onGetFolders
  };
};
export default useGetFdeInbox;
