import PropTypes from 'prop-types';

import type { Company } from '@snapptinc/fraud-platform';
import { ICompany } from 'src/ts/interfaces';
import CompanyDetailsForm from '../CompanyDetailsForm';
import CompanyDetailsLoader from '../CompanyDetailsLoader';
import { StyledContainer } from './styles';

export type CompanyDetailsProps = {
  name?: string;
  company: Company | any;
  avatarUrl?: string;
  isLoading?: boolean;
  onSave: (data: ICompany) => void;
  isSaving?: boolean;
};

const CompanyDetails: React.FC<CompanyDetailsProps> = (props: CompanyDetailsProps) => {
  const { company, isLoading, isSaving, onSave } = props;

  if (isLoading) return <CompanyDetailsLoader />;

  return (
    <StyledContainer>
      <CompanyDetailsForm isSaving={isSaving} handleOnSubmit={onSave} company={company} />
    </StyledContainer>
  );
};

CompanyDetails.propTypes = {
  name: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool
};

CompanyDetails.defaultProps = {
  name: 'edit',
  isLoading: true,
  onSave: () => {
    console.log('Saved');
  },
  isSaving: false
};

export default CompanyDetails;
