import { useNavigate } from 'react-router-dom';
import Button, { ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import useTeams from 'src/features/teams/hooks/useTeams';

const TeamsFilters: React.FC = () => {
  const navigate = useNavigate();
  const { allowCreateTeam } = useTeams();

  return (
    <Row gap={1} justify="flex-end">
      {allowCreateTeam() && (
        <Button
          name="create_team_button"
          variant={ButtonVariant.contained}
          onClick={() => navigate('/teams/create')}
        >
          Create Team&nbsp;&nbsp;
          <Icon icon="plus" />
        </Button>
      )}
    </Row>
  );
};

export default TeamsFilters;
