// Vendor
import { useEffect, useState } from 'react';

// Components
import Button, {
  ButtonDisabledStyle,
  ButtonSize,
  ButtonVariant
} from 'src/components/atoms/Button';
import PotentialMatchingModal from 'src/components/folders/PotentialMatchingModal';
import { GroupIcon } from '../styles';

// Provider
import { useFolderPage } from 'src/components/folders/FolderPageContext';

// Hooks
import { useMatchingEntriesByEntryId } from 'src/features/matchingEntries/hooks';

const MatchingApplicantButton = ({ folderId }: { folderId: string }) => {
  const [isMatchModalOpen, setIsMatchModalOpen] = useState(false);
  const { selectedEntryId } = useFolderPage();
  const {
    onGetMatchingEntriesByEntryId,
    matches,
    isLoading: isFetchingMatchingApplicants
  } = useMatchingEntriesByEntryId(selectedEntryId as string);

  const isMatchingButtonActive = !isFetchingMatchingApplicants && Boolean(matches?.length);

  // The folderId dependency forces re-fetch when the FRP changes after merging or splitting an entry.
  useEffect(() => {
    if (selectedEntryId) {
      onGetMatchingEntriesByEntryId(selectedEntryId);
    }
  }, [folderId, selectedEntryId, onGetMatchingEntriesByEntryId]);

  return (
    <>
      <Button
        name="button-matching-applicants"
        variant={ButtonVariant.outline}
        size={ButtonSize.medium}
        onClick={() => setIsMatchModalOpen(true)}
        isDisabled={!isMatchingButtonActive}
        disabledStyle={ButtonDisabledStyle.whiteWithBorder}
      >
        {matches?.length} Potential Matching <GroupIcon />
      </Button>
      <PotentialMatchingModal
        isLoading={isFetchingMatchingApplicants}
        folderId={folderId}
        entryId={selectedEntryId}
        matches={matches}
        showModal={isMatchModalOpen}
        setShowModal={setIsMatchModalOpen}
      />
    </>
  );
};

export default MatchingApplicantButton;
