import { getDateByLastDays } from 'src/helpers';

export const NAME = 'teams';

export const TIMEFRAME_OPTIONS = [
  { value: 'last_60_days', label: 'Last 60 days' },
  { value: 'last_30_days', label: 'Last 30 days', isSelected: true },
  { value: 'custom', label: 'Custom' }
];

export const defaultDate = getDateByLastDays(
  TIMEFRAME_OPTIONS.find((date) => date.isSelected)?.value
);
