import { teamApi } from 'src/api';
import { getPaginationRequest, ITeam, ITeamRequest } from 'src/ts/interfaces';

const api = {
  getAll: async ({ page, rowsPerPage, filter, sort, q }: getPaginationRequest) => {
    const pageNum = page?.toString();
    const rowsPerPageNum = rowsPerPage?.toString();
    return await teamApi.getTeams(pageNum, rowsPerPageNum, filter, sort, q);
  },
  getOne: async (teamId: string) => {
    return await teamApi.getTeam(teamId);
  },
  create: async (data: ITeam) => {
    const SFAMembers = data.membersSeniorFraudAnalyst?.data.map((user) => {
      return { id: user.value };
    });
    const FAMembers = data.membersFraudAnalyst?.data.map((user) => {
      return { id: user.value };
    });

    const team = {
      name: data.name,
      country_id: data.country?.id,
      timezone: data.timezones.id,
      fde_manager_id: data.fdeManager?.id,
      members: SFAMembers?.concat(FAMembers || [])
    };
    return await teamApi.createTeam({ team } as ITeamRequest);
  },
  update: async (data: ITeam) => {
    const SFAMembers = data.membersSeniorFraudAnalyst?.data.map((user) => {
      return { id: user.value };
    });
    const FAMembers = data.membersFraudAnalyst?.data.map((user) => {
      return { id: user.value };
    });

    const team = {
      name: data.name,
      country_id: data.country?.id,
      timezone: data.timezones.id,
      fde_manager_id: data.fdeManager?.id,
      members: SFAMembers?.concat(FAMembers || [])
    };
    return await teamApi.updateTeam(data.id as string, { team } as ITeamRequest);
  }
};

export default api;
