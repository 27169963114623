import styled from 'styled-components/macro';
import { ReactSVG } from 'react-svg';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 7.5rem;
`;

export const StyledUploadContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
`;

export const StyledUploadLabel = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.color.blue[700]};
`;

export const StyledIconExternal = styled(ReactSVG)`
  margin-left: 0.5rem;
  svg {
    path {
      fill: ${({ theme }) => theme.color.blue[700]};
    }
  }
`;

export const StyledImage = styled.img<any>`
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
  box-shadow: 0rem 0rem 0.25rem 0rem rgba(0, 0, 0, 0.15) inset;
  border: ${({ theme }) => `0.0625rem solid ${theme.color.gray[300]}`};
`;

export const StyledImageContainer = styled.div`
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
`;

export const HelperTextOptional = styled.div`
  margin-bottom: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
