import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'src/store';
import { actions, selectors } from '../index';

const useSetEntriesLogs = () => {
  const dispatch = useAppDispatch();
  const { filters } = useSelector(selectors);

  const filtering = useCallback(
    (filter?: string) => {
      dispatch(actions.filtering({ filter, page: 0, rowsPerPage: filters?.rowsPerPage }));
    },
    [dispatch, filters?.rowsPerPage]
  );

  const sorting = useCallback(
    (sort?: string) => {
      dispatch(actions.sorting({ sort, page: 0, rowsPerPage: filters?.rowsPerPage }));
    },
    [dispatch, filters?.rowsPerPage]
  );

  const searching = useCallback(
    (q?: string) => {
      dispatch(actions.search({ q, page: 0, rowsPerPage: filters?.rowsPerPage }));
    },
    [dispatch, filters?.rowsPerPage]
  );

  const paginating = useCallback(
    (page: number, rowsPerPage: number) => {
      dispatch(actions.pagination({ page, rowsPerPage }));
    },
    [dispatch]
  );

  return {
    filtering,
    sorting,
    searching,
    paginating
  };
};

export default useSetEntriesLogs;
