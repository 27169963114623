import { createAsyncThunk } from '@reduxjs/toolkit';
import { IncomeCalculationDocument } from '@snapptinc/fraud-platform';
import { format } from 'date-fns';
import { DATE_PICKER_FORMAT } from 'src/components/molecules/DatePicker';
import { IncomeVerification } from 'src/ts/interfaces/incomeVerification';
import api from './api';
import { DISPUTE_PATH, INCOME_CALCULATION_PATH, SEND_REPORT_PATH } from './constants';
import { IncomeCalculationRequest } from './ts/interfaces';
import { convertToIncomeVerificationType } from './utils';

export const calculateIncomeThunk = createAsyncThunk(
  INCOME_CALCULATION_PATH,
  async ({ submissionId, documents }: IncomeCalculationRequest, { getState }) => {
    try {
      const response = await api.calculateIncome(submissionId, documents);
      return response;
    } catch (error: any) {
      if (error.response?.data) {
        return error.response.data;
      } else {
        console.error(INCOME_CALCULATION_PATH, error);
        throw new Error('Error calculating income');
      }
    }
  }
);

export const sendReportThunk = createAsyncThunk(
  `${INCOME_CALCULATION_PATH}/sendReport`,
  async (entryId: string) => {
    try {
      return api.sendReport(entryId);
    } catch (error) {
      console.error(`${SEND_REPORT_PATH}/sendReport`, error);
      throw new Error('Failed to send report');
    }
  }
);

export const disputeCalculationThunk = createAsyncThunk(DISPUTE_PATH, async (entryId: string) => {
  try {
    return api.appealCalculation(entryId);
  } catch (error) {
    console.error(DISPUTE_PATH, error);
    throw new Error(`Failed to dispute income calculation for entry: ${entryId}`);
  }
});

/**
 * Handles fetching and converting the information for the documents previously submitted for an appeal.
 *
 * @param entryId
 * @returns {Promise<IncomeVerification[]>}
 */
export async function getPreviousDocumentsForAppeal(
  entryId: string | undefined
): Promise<IncomeVerification[] | []> {
  let docs: IncomeVerification[] = [];
  if (!entryId) {
    return docs;
  }
  try {
    const result = await api.getAppealDocuments(entryId);
    const documents = result?.data || [];
    docs = documents.map((doc: IncomeCalculationDocument, index: number) => {
      const result = convertToIncomeVerificationType(doc);
      return {
        ...result,
        // Convert the dates to the expected format
        payPeriodStartDate: format(
          new Date(removeUtcTimeInString(result.payPeriodStartDate)),
          DATE_PICKER_FORMAT
        ),
        payPeriodEndDate: format(
          new Date(removeUtcTimeInString(result.payPeriodEndDate)),
          DATE_PICKER_FORMAT
        ),
        index: index
      };
    });
  } catch (error) {
    console.error('Failed to get data for appeal:', entryId);
    console.error(error);
  }

  return docs;
}

const removeUtcTimeInString = (dateString: string) => {
  //Removes the T as well as replaces the - with / which allows the time to not go back a day
  return dateString.replace(/-/g, '/').replace(/T.+/, '');
};
