import { IUser, IUserCompanyRequest } from 'src/ts/interfaces';
import api from '../api';

export const uploadCompanyLogo = async (
  createCompanyPreURLResponse: IUser,
  payload: IUserCompanyRequest
) => {
  if (!createCompanyPreURLResponse?.company_id) {
    throw new Error('Failed to create a company');
  }
  try {
    if (!payload.logo) {
      throw new Error('Could not get logo for the company');
    }
    const companyId = createCompanyPreURLResponse?.company_id;
    const getCompanyURLResponse = await api.getCompanyURL(companyId);

    if (!getCompanyURLResponse?.presigned_url) {
      throw new Error('Could not get the presigned url for the company');
    }

    const { url, fields, method } = getCompanyURLResponse.presigned_url;

    if (!url || !fields || !method) {
      throw new Error('Could not get url, fields, method or metadata');
    }

    await api.saveCompanyFile({
      method,
      url,
      fields,
      type: 'image/png',
      file: payload.logo
    });
    await api.setCompanyURL(companyId);
    return await api.getCompany(companyId);
  } catch (error: any) {
    console.error(error?.message);
  }
};
