import { IProperty, IPropertyRequest } from 'src/ts/interfaces';
import api from '../api';

export const uploadPropertyLogo = async (
  property: IProperty,
  propertyResponse: IPropertyRequest
) => {
  try {
    if (!propertyResponse?.property.logo) {
      throw new Error('Could not get logo for the property');
    }
    if (!((propertyResponse?.property?.logo as any) instanceof File)) {
      return;
    }
    const getPropertyURLResponse = await api.getPropertyLogoURL(property?.id || '');
    if (!getPropertyURLResponse) {
      throw new Error('Could not get the presigned url for the property');
    }
    const { url, fields, method } = getPropertyURLResponse.presigned_url || {};
    if (!url || !fields || !method) {
      throw new Error('Could not get url, fields, method or metadata');
    }
    await api.savePropertyFile({
      method,
      url,
      fields,
      type: 'image/png',
      file: propertyResponse?.property?.logo as unknown as File
    });
    await api.setPropertyLogoURL(property?.id || '');
    return await api.getById(property?.id || '');
  } catch (error: any) {
    console.error(error?.message);
  }
};
