import { useSelector } from 'react-redux';
import Gap from 'src/components/atoms/Gap';
import { Line } from 'src/components/atoms/Line';
import { Pagination } from 'src/components/molecules/Pagination';
import useProperty from 'src/features/property/hooks/useProperty';
import { selectors as propertySelectors } from 'src/features/property/propertySlice';
import { StyledPaginationContainer } from './styles';

import { IDENTITY_VERIFICATION_FILTER } from 'src/features/property';

const filter = IDENTITY_VERIFICATION_FILTER;

const IdentityVerficationLinkPagination: React.FC = () => {
  const { onGetAllIdentityVerificationEnabled } = useProperty();

  const getPropertiesData = useSelector(propertySelectors.getAllIdentityVerificationEnabled.data);
  const getPropertiesError = useSelector(propertySelectors.getAllIdentityVerificationEnabled.error);
  const getPropertiesFilter = useSelector(
    propertySelectors.getAllIdentityVerificationEnabled.filter
  );
  const getPropertiesCount = useSelector(propertySelectors.getAllIdentityVerificationEnabled.count);

  if (getPropertiesError) return null;
  if (!getPropertiesData?.length) return null;

  return (
    <StyledPaginationContainer direction="column">
      <Line height="tiny" />
      <Gap height={1} />
      <Pagination
        name="DocumentLinkPagination"
        rowsPerPage={[5, 10, 20, 50, 100, 250, 500]}
        listPosition="top"
        pageInit={getPropertiesFilter.page}
        rowsPerPageInit={getPropertiesFilter.rowsPerPage || 5}
        totalRows={getPropertiesCount}
        onNext={(page, rowsPerPage) =>
          onGetAllIdentityVerificationEnabled(page, rowsPerPage, filter)
        }
        onPrev={(page, rowsPerPage) =>
          onGetAllIdentityVerificationEnabled(page, rowsPerPage, filter)
        }
        onRowsPerPage={(page, rowsPerPage) =>
          onGetAllIdentityVerificationEnabled(page, rowsPerPage)
        }
      />
    </StyledPaginationContainer>
  );
};

export default IdentityVerficationLinkPagination;
