import { FC, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonType,
  ButtonVariant
} from 'src/components/atoms/Button';
import { Checkbox } from 'src/components/atoms/Checkbox';
import Gap from 'src/components/atoms/Gap';
import { InputText } from 'src/components/atoms/InputText';
import { InputTextType } from 'src/components/atoms/InputText/InputText';
import { Row } from 'src/components/atoms/Row';
import Text, { TextAlign, TextColor, TextVariant } from 'src/components/atoms/Text';
import { ToolTip } from 'src/components/atoms/ToolTip';
import { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import { Constraints, TOSPolicyText } from './styles';
import { FormValues, InvitationDetailsFormProps } from './types';

const InvitationDetailsForm: FC<InvitationDetailsFormProps> = ({ defaultValues, onSubmit }) => {
  const [isTOSChecked, setIsTOSChecked] = useState(false);
  const password = useRef({});
  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'all'
  });
  const {
    formState: { isValid }
  } = methods;

  password.current = methods.watch('password', '');

  return (
    <>
      <Text color={TextColor.initial} variant={TextVariant.h3}>
        Create Account
      </Text>
      <Gap height={1} />
      <Text color={TextColor.initial} variant={TextVariant.normal}>
        To finish creating an account please enter a password.
      </Text>
      <Gap height={1.5} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <InputText
            name="role"
            type={InputTextType.text}
            placeholder="Role"
            isDisabled={true}
            config={{
              required: 'Role is required'
            }}
          />
          <InputText
            name="firstName"
            type={InputTextType.text}
            placeholder="First Name"
            isDisabled={true}
            config={{
              required: 'First name is required',
              maxLength: 120
            }}
          />
          <InputText
            name="lastName"
            type={InputTextType.text}
            placeholder="Last Name"
            isDisabled={true}
            config={{
              required: 'Last name is required'
            }}
          />
          <InputText
            name="email"
            type={InputTextType.email}
            placeholder="Email"
            isDisabled={true}
            config={{
              required: 'Email is required'
            }}
          />
          <InputText
            name="password"
            type={InputTextType.password}
            placeholder="Password"
            isDisabled={false}
            config={{
              required: 'Password is required',
              validate: {
                between8And256CharacterLength: (value) =>
                  /^(?=\S{8,256}$)/.test(value) || 'Must be between 8 and 256 characters in length',
                bothUpperAndLowerCaseCharacters: (value) =>
                  /^(?=.*?[A-Z])(?=.*?[a-z])/.test(value) ||
                  'Must contain both upper and lower case characters',
                leastOneSpecialCharacter: (value) =>
                  /^(?=(.*[\W]){1,})/.test(value) ||
                  'Must contain at least one non-alphanumeric character',
                leastOneNumber: (value) =>
                  /^(?=(.*[\d]){1,})/.test(value) || 'Must contain at least one number'
              }
            }}
          />
          <InputText
            name="confirmPassword"
            type={InputTextType.password}
            placeholder="Confirm Password"
            isDisabled={false}
            config={{
              required: 'Confirm password is required',
              validate: (value) => value === password.current || 'Passwords do not match'
            }}
          />
          <Text color={TextColor.initial} variant={TextVariant.small}>
            Password must meet the following constraints:
          </Text>
          <Constraints>
            {[
              'Must be between 8 and 256 characters in length',
              'Must contain both upper and lower case characters',
              'Must contain at least one non-alphanumeric character',
              'Must contain at least one number',
              'Must not match the previous 5 passwords'
            ].map((constraint, index) => (
              <li key={index}>
                <Text color={TextColor.initial} variant={TextVariant.small}>
                  {constraint}
                </Text>
              </li>
            ))}
          </Constraints>
          <ToolTip
            content="To proceed with registration, please check the box agreeing to the privacy policy and terms."
            direction={ToolTipDirection.top}
            hidden={isTOSChecked}
          >
            <Row className="chkTOSPolicy">
              <Checkbox
                key="chkbox_tos_policies"
                onClick={({ isChecked }) => setIsTOSChecked(isChecked)}
                name="tosAndPP"
                showLabel={false}
                value="yes"
                label=""
                isChecked={isTOSChecked}
              />

              <TOSPolicyText>
                <Text
                  align={TextAlign.center}
                  color={TextColor.initial}
                  variant={TextVariant.small}
                  isBold={false}
                  isStretched
                >
                  I agree to the{' '}
                  <a
                    href="https://snappt.com/terms-conditions/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://snappt.com/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{' '}
                </Text>
              </TOSPolicyText>
            </Row>
          </ToolTip>
          <Button
            name="SubmitInvitationDetails"
            variant={ButtonVariant.contained}
            color={ButtonColor.primary}
            size={ButtonSize.normal}
            type={ButtonType.submit}
            isDisabled={!isTOSChecked || !isValid}
          >
            Submit
          </Button>
        </form>
      </FormProvider>
    </>
  );
};

export default InvitationDetailsForm;
