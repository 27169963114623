import { useSelector } from 'react-redux';

import { RootState } from 'src/store';
import { Status } from 'src/ts/enums';

import { selectors } from '../entriesSlice';

export const useEntriesByFolderId = (folderId: string) => {
  const entries = useSelector((state: RootState) => selectors.selectAllByFolderId(state, folderId));
  const status = useSelector(selectors.status);

  const isUninitialized = status === Status.IDLE;
  const isLoading = status === Status.LOADING;
  const isError = status === Status.ERROR;
  const isSuccess = status === Status.SUCCESS;

  return {
    entries,
    isUninitialized,
    isLoading,
    isError,
    isSuccess
  };
};
