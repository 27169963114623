// Vendor
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import Grid from 'src/components/atoms/Grid';
import DocumentLinkLoader from 'src/components/molecules/DocumentLinkLoader/DocumentListLoader';
import { InstructionLoader } from 'src/components/molecules/InstructionLoader';
import { ShowState } from 'src/components/molecules/ShowState';
import { IdentityVerificationLinkRow } from 'src/components/organisms/IdentityVerificationLinkRow';
import { StyledGrid, StyledInstructionContainer } from './styles';

// Hooks
import useProperty from 'src/features/property/hooks/useProperty';

// Redux
import { selectors as propertySelectors } from 'src/features/property/propertySlice';

// Types
import { IProperty } from 'src/ts/interfaces';

import { IDENTITY_VERIFICATION_FILTER } from 'src/features/property';
import IDVLinkInstruction from './IDVLinkInstruction';

const filter = IDENTITY_VERIFICATION_FILTER;

const IdentityVerificationLinkList: React.FC = () => {
  const { onGetAllIdentityVerificationEnabled: onGetIdentityVerificationProperties } =
    useProperty();

  const getPropertiesData = useSelector(propertySelectors.getAllIdentityVerificationEnabled.data);
  const getPropertiesIsIdle = useSelector(
    propertySelectors.getAllIdentityVerificationEnabled.isIdle
  );
  const getPropertiesIsLoading = useSelector(
    propertySelectors.getAllIdentityVerificationEnabled.isLoading
  );
  const getPropertiesError = useSelector(propertySelectors.getAllIdentityVerificationEnabled.error);

  useEffect(() => {
    if (getPropertiesIsIdle) {
      onGetIdentityVerificationProperties(0, 5, filter);
    }
  }, [onGetIdentityVerificationProperties, getPropertiesIsIdle]);

  if (getPropertiesError) {
    return (
      <ShowState
        variant="error"
        type="information"
        buttonLabel="Please, try again"
        message="Something went wrong"
        onClick={() => window.location.reload()}
      />
    );
  }

  if (getPropertiesIsLoading) {
    return (
      <Grid justify="space-between">
        <DocumentLinkLoader />
        <InstructionLoader />
      </Grid>
    );
  }

  if (getPropertiesData?.length === 0) {
    return (
      <ShowState message="There are no properties <br/> to show" type="property" variant="empty" />
    );
  }
  return (
    <Grid>
      <StyledGrid direction="column">
        {getPropertiesData?.map((property: IProperty) => {
          return (
            <IdentityVerificationLinkRow
              key={property.id}
              name={property.name}
              address={property.address}
              city={property.city}
              company_short_id={property.company_short_id}
              short_id={property.short_id}
              state={property.state}
              zip={property.zip}
              identity_verification_enabled={property.identity_verification_enabled}
            />
          );
        })}
      </StyledGrid>
      <StyledInstructionContainer>
        <IDVLinkInstruction />
      </StyledInstructionContainer>
    </Grid>
  );
};
export default IdentityVerificationLinkList;
