import { useSelector } from 'react-redux';
import { actions, selectors } from 'src/features/frameworkUI/frameworkUISlice';
import { useAppDispatch } from 'src/store';

const useFrameworkUI = () => {
  const dispatch = useAppDispatch();
  const frameworkUI = useSelector(selectors.ui);
  const isOpenSidebar = frameworkUI.isOpenSidebar;

  const onSetIsOpenSidebar = () => {
    dispatch(actions.ui({ isOpenSidebar }));
  };

  return {
    isOpenSidebar,
    onSetIsOpenSidebar
  };
};

export default useFrameworkUI;
