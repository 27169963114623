import { format, isValid } from 'date-fns';

import { ProofSuggestedRulingEnum } from 'src/ts/enums';
import {
  ExtractedMetadata,
  ProfiledTestResult,
  TestMetadataFlags,
  TestResult
} from 'src/ts/interfaces';

const transformDate = (date?: string) => {
  if (!date) {
    return 'No date found.';
  }

  if (!isValid(new Date(date))) {
    return 'Invalid edited/created date format.';
  }

  return format(new Date(date), 'yyyy/MM/dd');
};

const transformFonts = (fontNames?: string[]) => {
  if (!fontNames || fontNames?.length === 0) {
    return 'No font names found.';
  }

  return fontNames.join(', ');
};

// Calculate test for Metadata & fonts
const calculateWhitelistRuling = (tests?: ProfiledTestResult[]) => {
  switch (true) {
    case tests?.some((test) => test.type === 'Type II' && test.profile !== 'None'):
      return ProofSuggestedRulingEnum.Edited;
    case tests?.some((test) => test.type === 'Type I' || test.type === 'Type II'):
      return ProofSuggestedRulingEnum.Flagged;
    case tests?.some((test) => test.status === 'Fail'):
      return ProofSuggestedRulingEnum.Edited;
    case tests?.every((test) => test.status === 'Pass'):
      return ProofSuggestedRulingEnum.Clean;
    default:
      return ProofSuggestedRulingEnum.Flagged;
  }
};

export const calculateWhitelistTestResult = (
  tests: {
    metadata: string;
    fonts: string;
    source: string;
    suggestedRuling: string;
  }[]
) => {
  switch (true) {
    case tests?.some((test) => test.suggestedRuling === ProofSuggestedRulingEnum.Clean):
      return ProofSuggestedRulingEnum.Clean;
    case tests?.some((test) => test.suggestedRuling === ProofSuggestedRulingEnum.Edited):
      return ProofSuggestedRulingEnum.Edited;
    default:
      return ProofSuggestedRulingEnum.Flagged;
  }
};

// If any of these test are undefined flagged will be returned.
// e.g if we don't have a submission date test flagged will be returned
const calculateRulingForTextInsertion = (test?: TestResult) => {
  switch (true) {
    case test?.type === 'Type I':
      return ProofSuggestedRulingEnum.Flagged;
    case test?.status === 'Fail':
      return ProofSuggestedRulingEnum.Edited;
    case test?.status === 'Pass':
      return ProofSuggestedRulingEnum.Clean;
    default:
      return ProofSuggestedRulingEnum.Flagged;
  }
};

const calculateRulingForDateTest = (test?: TestResult) => {
  switch (true) {
    case test?.type === 'Type I' || test?.type === 'Type II':
      return ProofSuggestedRulingEnum.Flagged;
    case test?.status === 'Fail':
      return ProofSuggestedRulingEnum.Edited;
    case test?.status === 'Pass':
      return ProofSuggestedRulingEnum.Clean;
    default:
      return ProofSuggestedRulingEnum.Flagged;
  }
};

export const createExtractedData = (extracted_meta?: ExtractedMetadata) => ({
  'File type': extracted_meta?.['File:MIMEType'] || 'No file type found.',
  'PDF producer': extracted_meta?.['PDF:Producer'] || 'No producer found.',
  Application: extracted_meta?.['PDF:Creator'] || 'No application found.',
  Author: extracted_meta?.['PDF:Author'] || 'No author found.',
  'Created date': transformDate(extracted_meta?.['PDF:CreateDate'] as string),
  'Edit date': transformDate(extracted_meta?.['PDF:ModifyDate'] as string),
  Fonts: transformFonts(extracted_meta?.['PDF:Fonts'] as string[])
});

const calculateSourceLabel = (source: string) => {
  return source === 'None' ? 'No Whitelist found' : source;
};

export const createWhitelistData = (testMetadataFlags?: TestMetadataFlags) => {
  const groupByProfile = (tests: ProfiledTestResult[]) =>
    tests.reduce<Record<string, ProfiledTestResult>>(
      (result, value) => ({
        ...result,
        [value.profile]: value
      }),
      {}
    );

  const metadataTestResultByProfile = groupByProfile(testMetadataFlags?.metadata ?? []);
  const fontTestResultByProfile = groupByProfile(testMetadataFlags?.fonts ?? []);

  const testResults = [...(testMetadataFlags?.metadata ?? []), ...(testMetadataFlags?.fonts ?? [])];

  const testResultByProfile = testResults.reduce<
    Record<string, { source: string; metadata: string; fonts: string; suggestedRuling: string }>
  >((result, { profile }) => {
    if (!result[profile]) {
      const tests = [fontTestResultByProfile[profile], metadataTestResultByProfile[profile]].filter(
        (test) => Boolean(test)
      );

      result[profile] = {
        source: calculateSourceLabel(profile),
        metadata: metadataTestResultByProfile[profile]?.message || 'N/A',
        fonts: fontTestResultByProfile[profile]?.message || 'N/A',
        suggestedRuling: calculateWhitelistRuling(tests)
      };

      return result;
    }

    return result;
  }, {});

  return Object.values(testResultByProfile);
};

export const createDocumentData = (testMetadataFlags?: TestMetadataFlags) => [
  {
    field: 'Date Test 1',
    value: testMetadataFlags?.creationDate?.message || 'N/A',
    suggestedRuling: calculateRulingForDateTest(testMetadataFlags?.creationDate)
  },
  {
    field: 'Date Test 2',
    value: testMetadataFlags?.submissionDate?.message || 'N/A',
    suggestedRuling: calculateRulingForDateTest(testMetadataFlags?.submissionDate)
  },
  {
    field: 'Text Insertion',
    value: testMetadataFlags?.textInsertion?.message || 'N/A',
    suggestedRuling: calculateRulingForTextInsertion(testMetadataFlags?.textInsertion)
  }
];

export const getDotColor = (suggestedRuling: string) => {
  if (suggestedRuling === ProofSuggestedRulingEnum.Clean) return 'success';
  if (suggestedRuling === ProofSuggestedRulingEnum.Edited) return 'error';

  return 'warning';
};
