// Vendor
import { FC } from 'react';

// Components
import TableCell from 'src/components/atoms/TableCell';
import TableRow from 'src/components/atoms/TableRow';
import Text, { TextAlign, TextVariant } from 'src/components/atoms/Text';

// Helpers

// Constants

// Types
import Tag from 'src/components/atoms/Tag';
import { ToolTip } from 'src/components/atoms/ToolTip';
import { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import {
  getDocumentReviewResultStatusColorTag,
  transformResultToReadable
} from 'src/features/fdeInbox/helpers';
import { getShortenedGuidForDisplay } from 'src/helpers';
import { DocumentReviewTableRowProps } from '../types';

const DocumentReviewTableRow: FC<DocumentReviewTableRowProps> = ({ documentReview }) => {
  return (
    <>
      <TableRow>
        <TableCell />
        <TableCell>
          <Text variant={TextVariant.small}>
            <ToolTip content={documentReview.document_id} direction={ToolTipDirection.right}>
              {getShortenedGuidForDisplay(documentReview.document_id, 13)}
            </ToolTip>
          </Text>
        </TableCell>
        <TableCell textAlign="center">
          <Tag
            label={transformResultToReadable(documentReview.result)}
            color={getDocumentReviewResultStatusColorTag(documentReview.result)}
            showBackground
          />
        </TableCell>
        <TableCell textAlign="center">
          <Text variant={TextVariant.small} align={TextAlign.center}>
            Connected Payroll
          </Text>
        </TableCell>
        <TableCell />
      </TableRow>
    </>
  );
};

export default DocumentReviewTableRow;
