import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { Status as StatusTypes } from 'src/ts/enums';
import { IRejectedAction } from 'src/ts/interfaces';
import { IResource } from 'src/ts/interfaces/resource';
import { URL_FILE_P2PDF } from './constants';
import { getAll, getOne } from './services';

export type ResourcesState = {
  getAll: {
    status: StatusTypes;
    data?: IResource[] | null;
    error?: string | null;
  };
  getOne: {
    status: StatusTypes;
    error?: string | null;
    data?: IResource | null;
  };
};

export const initialState: ResourcesState = {
  getAll: {
    status: StatusTypes.IDLE,
    data: null,
    error: null
  },
  getOne: {
    status: StatusTypes.IDLE,
    data: null,
    error: null
  }
};

const replaceResource = (item: IResource) => {
  if (item.id === 'Print_To_Pdf_Instructions') {
    item.link = URL_FILE_P2PDF;
  }
  return item;
};

export const getAllReducer = (builder: ActionReducerMapBuilder<ResourcesState>) => {
  builder.addCase(getAll.pending, (state: ResourcesState) => {
    state.getAll.status = StatusTypes.LOADING;
    state.getAll.error = null;
  });
  builder.addCase(getAll.rejected, (state: ResourcesState, action: IRejectedAction) => {
    state.getAll.status = StatusTypes.ERROR;
    state.getAll.error = action.error?.message || null;
  });
  builder.addCase(getAll.fulfilled, (state: ResourcesState, action: PayloadAction<any>) => {
    state.getAll.status = StatusTypes.SUCCESS;
    state.getAll.data = (action.payload.data || []).map((item: IResource) => {
      return replaceResource(item);
    });
  });
};

export const getOneReducer = (builder: ActionReducerMapBuilder<ResourcesState>) => {
  builder.addCase(getOne.pending, (state: ResourcesState) => {
    state.getOne.status = StatusTypes.LOADING;
    state.getOne.error = null;
  });
  builder.addCase(getOne.rejected, (state: ResourcesState, action: IRejectedAction) => {
    state.getOne.status = StatusTypes.ERROR;
    state.getOne.error = action.error.message;
  });
  builder.addCase(getOne.fulfilled, (state: ResourcesState, action: PayloadAction<any>) => {
    state.getOne.status = StatusTypes.SUCCESS;
    state.getOne.data = replaceResource(action.payload.data);
  });
};
