import { ListView } from 'src/components/templates';
import ResourceList from './components/ResourceList';

const ResourcesPage: React.FC = () => {
  return (
    <ListView title="Help & Resources">
      <div className="Container">
        <ResourceList />
      </div>
    </ListView>
  );
};

export default ResourcesPage;
