import TableContainer from 'src/components/atoms/TableContainer';
import styled from 'styled-components/macro';

export const StyledTableContainer = styled(TableContainer)`
  & #scrollable-container {
    width: 62.5rem;
    overflow: auto;
    overflow-x: hidden;
    max-height: calc(600px - 154px);
    min-height: calc(400px - 154px);

    @media ${({ theme }) => theme.device.xs} {
      width: 100%;
    }
  }
  & #header {
    position: sticky;
    top: 0;
    background-color: ${({ theme }) => theme.color.white};
  }
`;
