import { Row } from 'src/components/atoms/Row';
import Modal from 'src/components/organisms/Modal';
import styled from 'styled-components/macro';

export const ModalStyle = styled(Modal)`
  .modal__container > div {
    min-width: 30rem;
  }
`;

export const RowTable = styled.div`
  border: 1px solid ${({ theme }) => theme.color.gray[100]};
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 0.5rem;
  margin-right: 6px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const RowItem = styled(Row).attrs(() => ({
  gap: 0.5
}))`
  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const RowHeader = styled(Row.Col).attrs(() => ({
  size: '40%'
}))`
  ${({ theme }) => theme.font.normal};
  ${({ theme }) => theme.font.bold};
  text-align: right;
`;

export const RowData = styled(Row.Col).attrs(() => ({
  size: '60%'
}))`
  ${({ theme }) => theme.font.normal};
`;

export const CenteredContainer = styled.div`
  height: 300px;
  margin-top: 50px;
`;

export const Container = styled.div`
  max-height: 300px;
  overflow: auto;
`;
