type FormatLabelValueArgs<T> = { data?: T[] | null; value: keyof T; label: keyof T };
const formatLabelValue = <T>(args: FormatLabelValueArgs<T>) => {
  if (!args.data || !args.data[0]) return [];

  return args.data.map((item: T) => ({
    value: item[args.value],
    label: item[args.label]
  }));
};

export default formatLabelValue;
