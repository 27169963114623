import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { SettingResponse } from '@snapptinc/fraud-platform';
import { RootState } from 'src/store';
import { Status } from 'src/ts/enums';
import { NAME } from './constants';
import { getSetting, updateSetting } from './services';

interface StateData {
  data: SettingResponse;
  status: string;
  error: string | null;
}

const initialState: StateData = {
  data: { data: {} },
  status: Status.LOADING,
  error: null
};

const getReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(getSetting.pending, (state: StateData) => {
    state.status = Status.LOADING;
    state.error = null;
  });
  builder.addCase(getSetting.rejected, (state: StateData, action) => {
    state.status = Status.ERROR;
    state.error = action.error?.message || null;
  });
  builder.addCase(getSetting.fulfilled, (state, action) => {
    state.status = Status.SUCCESS;
    state.data = action.payload.data;
  });
};

const updateReducer = (builder: ActionReducerMapBuilder<StateData>) => {
  builder.addCase(updateSetting.pending, (state: StateData) => {
    state.status = Status.LOADING;
    state.error = null;
  });
  builder.addCase(updateSetting.rejected, (state: StateData, action) => {
    state.status = Status.ERROR;
    state.error = action.error?.message || null;
    state.data = initialState.data;
  });
  builder.addCase(updateSetting.fulfilled, (state, action) => {
    state.status = Status.SUCCESS;
    state.data = action.payload.data;
  });
};

const settingSlice = createSlice({
  name: NAME,
  initialState,
  extraReducers: (builder) => {
    getReducer(builder);
    updateReducer(builder);
  },
  reducers: {}
});

export const selectors = {
  data: (state: RootState) => state[NAME].data,
  error: (state: RootState) => state[NAME].error,
  status: (state: RootState) => state[NAME].status
};

export const { reducer, actions } = settingSlice;
