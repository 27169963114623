import { EntryResultEnum, EntrySuggestedRulingEnum, ProofSuggestedRulingEnum } from 'src/ts/enums';
import { EntrySuggestedRulingType, ProofSuggestedRulingType } from 'src/ts/interfaces';

export const startCase = (str: string) =>
  (str || '')
    .split('_')
    .map((t) => `${t.charAt(0)}${t.substring(1).toLowerCase()}`)
    .join(' ');
export const transformResultToReadable = (result?: string) => {
  if (!result) return '';
  // Special case to transform "result" field
  if (EntryResultEnum.Undetermined === result) {
    return 'Insufficient Documentation';
  }
  if (EntryResultEnum.CleanProceedWithCaution === result) {
    return 'Clean - Proceed with Caution';
  }

  return startCase(result);
};

export const transformDocumentTypeToReadable = (documentType?: string) => {
  if (!documentType) return '';

  switch (documentType) {
    case 'CREDIT_DEBIT_CARD_STATEMENT':
      return 'Credit/Debit Card Statement';
    case 'DEPARTMENT_OF_VETERANS_AFFAIRS_BENEFIT_LETTER':
      return 'Dept. of VA Benefit Letter';
    default:
      return startCase(documentType);
  }
};

export const transformSuggestedRulingToReadable = (suggestedRuling?: EntrySuggestedRulingType) => {
  if (!suggestedRuling) return '';
  if (
    EntrySuggestedRulingEnum.NotRun === suggestedRuling ||
    EntrySuggestedRulingEnum.NoDocuments === suggestedRuling ||
    EntrySuggestedRulingEnum.Flagged === suggestedRuling
  ) {
    return 'Flagged for Manual Review';
  }

  return startCase(suggestedRuling);
};

export const transformProofSuggestedRulingToReadable = (
  suggestedRuling?: ProofSuggestedRulingType
) => {
  if (!suggestedRuling) return '';
  if (
    ProofSuggestedRulingEnum.NotRun === suggestedRuling ||
    ProofSuggestedRulingEnum.Flagged === suggestedRuling
  ) {
    return 'Flagged for Manual Review';
  }

  return startCase(suggestedRuling);
};
