import { Row } from 'src/components/atoms/Row';
import styled from 'styled-components/macro';

export const StyledGrid = styled(Row)`
  padding-left: 1rem;
  @media ${({ theme }) => theme.device.laptop} {
    padding-left: 0.2rem;
  }
  .small-margin {
    margin-bottom: 0.1875rem;
  }
  .big-margin {
    margin-bottom: 0.5rem;
  }
`;
export const StyledDiv = styled.div`
  margin-top: 0.9rem;
`;

export const ButtonIconStyle = styled.div`
  @media ${({ theme }) => theme.device.tablet} {
    width: 1rem;
  }
`;

export const Container = styled.div`
  padding: 1rem 2rem;
  min-width: 20.625rem;
`;

export const StyledContainer = styled(Row)`
  display: flex;
  justify-content: space-between;
  @media ${({ theme }) => theme.device.tablet} {
    justify-content: flex-start;
    flex-direction: column;
  }
`;
