import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.5rem;
    & [name~='SubmitPropertyDetails'] {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    & [name~='Cancel'] {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .identityVerificationReportImageTypesRadioButtonGroup {
    flex-direction: row;
  }
`;

export const CompanyDropdown = styled.div`
  margin-bottom: 1.8rem;
`;
