/* eslint-disable react-hooks/exhaustive-deps */
// Vendor
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import { TransferList } from 'src/components/molecules/TransferList';
import Modal, { ModalVariant, ModalWidth } from 'src/components/organisms/Modal';

// Hooks
import useTeams from 'src/features/teams/hooks/useTeams';

// Redux
import { selectors as teamSelectors } from 'src/features/teams/teamsSlice';

// Types
import { CheckboxData } from 'src/components/atoms/Checkbox/types';
import { ITransferListContent } from 'src/components/molecules/TransferList/types';

export interface TeamsAssigningFAModalProps {
  onSave: (data: CheckboxData[] | []) => void;
  assigned: CheckboxData[];
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const TeamsAssigningFAModal: FC<TeamsAssigningFAModalProps> = (props) => {
  const { onSave, showModal, setShowModal, assigned: assignedFA } = props;

  const { onGetAllFA, onResetGetAllFA } = useTeams();

  const getAllFAData = useSelector(teamSelectors.getAllFA.data);
  const getAllFAIsIdle = useSelector(teamSelectors.getAllFA.isIdle);
  const getAllFAIsLoading = useSelector(teamSelectors.getAllFA.isLoading);
  const getAllFAError = useSelector(teamSelectors.getAllFA.error);

  const [assigned, setAssigned] = useState<CheckboxData[]>(assignedFA);
  const [isTransferring, setIsTransferring] = useState(false);

  const getData = (assigned: CheckboxData[]) => {
    setAssigned(assigned);
    onGetAllFA(assigned);
  };

  useEffect(() => {
    if (getAllFAIsIdle && showModal) {
      getData(assigned);
    }
  }, [getAllFAIsIdle, showModal]);

  const onClose = () => {
    onResetGetAllFA();
    setIsTransferring(false);
  };

  const onSetLists = (data: ITransferListContent) => {
    setAssigned(data?.secondList.data || []);
  };

  const onConfirm = () => {
    onSave(assigned);
    onClose();
    setShowModal(false);
  };

  return (
    <Modal
      title="Assign Fraud Analysts"
      variant={ModalVariant.none}
      showModal={showModal}
      setShowModal={setShowModal}
      isBackClosable={false}
      isEscapeClosable={false}
      width={ModalWidth.large}
      labelButtonConfirm="Save"
      isDisabledButtonConfirm={getAllFAIsLoading || !isTransferring}
      onConfirm={onConfirm}
      onCancel={onClose}
    >
      <TransferList
        name="AssigningFAList"
        showSearch
        showSelectChoices
        searchPlaceholder="Search"
        onGetData={onSetLists}
        onMoveLeft={() => {
          onResetGetAllFA();
          setIsTransferring(true);
        }}
        onMoveRight={() => {
          onResetGetAllFA();
          setIsTransferring(true);
        }}
        firstList={{
          name: 'UnassignedUsersList',
          title: 'Unassigned Users',
          messageEmpty: 'There are no<br/>users to show',
          isLoading: getAllFAIsLoading,
          hasError: getAllFAError !== null,
          data: getAllFAData || []
        }}
        secondList={{
          name: 'AssignedUsersList',
          title: 'Assigned Users',
          messageEmpty: 'There are no<br/>users to show',
          isLoading: getAllFAIsLoading,
          hasError: getAllFAError !== null,
          data: assigned
        }}
      />
    </Modal>
  );
};

export default TeamsAssigningFAModal;
