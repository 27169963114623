// Vendor
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Hooks
import useLanguage from 'src/context/Language/useLanguage';

// Redux
import { actions, selectors } from 'src/features/DUP/DUPSlice';
import { useAppDispatch } from 'src/store';
import { createApplication, updateCurrentApplication } from '../services';

// Helpers
import getInfo from 'src/features/DUP/helpers/getInfo';
import { formatPhone } from 'src/helpers/formatPhone';

// Constants
import { AOA_PROPERTY_SLUG, URLS_SLUG } from '../../constants';

// Enums
import { DupTypes } from '../../ts/enums';

// Types
import { ILanguage } from 'src/context/Language/types';
import { IApplication, IProperty } from '../ts/interfaces';

export type FormValues = {
  application?: IApplication;
  property?: IProperty;
};

const useForm = () => {
  const dispatch = useAppDispatch();

  const { translate: t, setLanguage } = useLanguage();
  const { pathname, search } = useLocation();

  // for some reason URLSearchParam or useSearchParam does not want to work.
  // This is why I'm doing it manually
  const searchQueryArray = search.slice(1).split('&');
  const queryArray = searchQueryArray.map((query) => query.split('='));
  const queryParams = queryArray.reduce((acc, query) => {
    return { ...acc, [query[0]]: query[1] };
  }, {} as Record<string, string>);

  const [isFormValid, setIsFormValid] = useState(false);

  const type = useSelector(selectors.type);
  const information = useSelector(selectors.information);
  const application = useSelector(selectors.getCurrentApplication);

  const onCreateApplication = (company_short_id: string, property_short_id: string) => {
    const application_type = pathname.includes(URLS_SLUG.leasing_team.path)
      ? URLS_SLUG.leasing_team.name
      : URLS_SLUG.applicant.name;

    dispatch(
      createApplication({
        company_short_id,
        property_short_id,
        application_type
      })
    );
  };

  const onUpdateCurrentApplication = () => {
    dispatch(
      updateCurrentApplication({
        application: {
          ...application.data,
          phone: formatPhone(application?.data),
          metadata: queryParams
        }
      })
    );
  };

  const onSetLanguage = (lang: ILanguage, type: DupTypes) => {
    setLanguage(lang);

    const info = getInfo(type, t);
    dispatch(actions.setInformation(info));
  };

  const onSetType = (property: IProperty) => {
    const type = getTypeFromPath(property);
    dispatch(actions.setType(type));
    dispatch(actions.setInformation(getInfo(type, t)));
  };

  const getTypeFromPath = (property: Pick<IProperty, 'name'>) => {
    if (pathname.includes(URLS_SLUG.leasing_team.path)) return DupTypes.LEASING_TEAM;
    if (property?.name?.search(AOA_PROPERTY_SLUG) !== -1) return DupTypes.AOA;
    return DupTypes.APPLICANT;
  };

  return {
    onCreateApplication,
    onUpdateCurrentApplication,
    type,
    information,
    onSetType,
    isFormValid,
    setIsFormValid,
    onSetLanguage
  };
};

export default useForm;
